import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { TextFieldTypes } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { ListingService } from '@app/core/listings';

import { I18nService, ImageService, LanguageService } from '@app/core';
import { MatSidenav, MatDialog, MatDialogRef } from '@angular/material';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { ContactDialogService } from '@app/shared/contact-dialog/contact-dialog.service';
import { LoginFormDialogService } from '@app/shared/login';
import { CommonGenericService } from '@app/core/common.service';
import { AuthenticationService } from '../core/auth/auth.service';
import { AccountService } from '@app/core/auth';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { MyListingMenuComponent } from '@app/shared/my-listing-menu/my-listing-menu.component';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { SwUpdate } from '@angular/service-worker';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import * as CartActions from '@app/store/actions';
import { PrivacyPolicyService } from '@app/shared/privacy-policy-dialog';
import { AppAcceptCancelModalService } from '@app/shared/accept-cancel-modal/accept-cancel-modal.service';
import { AppAcceptCancelModalComponent } from '@app/shared/accept-cancel-modal/app-accept-cancel-modal.component';
import { SubscribeModalComponent } from '@app/shared/subscribe/subscribe-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { RegisterFormComponent } from '@app/shared/register';
import { HomePageAppService } from '../shared/services/homepageapp.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { AppRouteNamesService } from '@app/core/helpers/app-route-names.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: false, read: MatSidenav }) drawer: MatSidenav;

  dialogRef: any;
  imagePlaceholder: string = 'assets/images/default-profile-photo.png';
  userAccountSubscription: Subscription;
  spotNearMeFilterSubscription: Subscription;
  userAccount: any = {};
  activeRoute: string = '';
  regularSearchActiveRoute: string = '';
  nearbySpotActiveRoute: string = '';
  faMapMarkerAlt: any;
  isLoggedIn$: any;
  selectedLanguage: 'en-US';
  cookieAcceptanceName: string = 'SIILA-cookie-usage-acceptance';
  cookieAcceptanceAccepted: boolean = true;
  links: any = {
    searchListings: {
      translationKey: 'global.headline'
    },
    home: {
      translationKey: 'global.home'
    },
    register: {
      translationKey: 'global.register.signup'
    },
    listYourSpot: {
      translationKey: 'global.listyour'
    },
    listPropertyNearby: {
      translationKey: 'global.list-property.listPropertyNearby'
    },
    contactUs: {
      translationKey: 'global.menu.options.contactUs'
    },
    aboutUs: {
      translationKey: 'global.menu.options.silBr'
    },
    blog: {
      translationKey: 'global.menu.options.blog'
    },
    subscribe: {
      translationKey: 'global.newsForm.subscribe'
    },
    userProfile: {
      translationKey: 'global.user.myAccount'
    }
  };

  user: any;
  isAdmin = false;
  isMobile: boolean;

  //refactor dollar sign after for observers
  $currentUserExists: Observable<boolean>;
  routerObserver: any;
  newSwUpdateAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private enLangDesc: string = null;
  private ptLangDesc: string = null;
  private customLoginText: string;
  private personalDataModalInstance: MatDialogRef<AppAcceptCancelModalComponent>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private platform: Platform,
    private alertController: AlertController,
    private i18nService: I18nService,
    private contactDialogService: ContactDialogService,
    private loginFormDialog: LoginFormDialogService,
    private privacyPolicyService: PrivacyPolicyService,
    private commonService: CommonGenericService,
    private auth: AuthenticationService,
    private listingService: ListingService,
    private accountService: AccountService,
    private imageService: ImageService,
    private dialog: MatDialog,
    private myMessageService: MyMessageService,
    private swUpdate: SwUpdate,
    private nearbyListingSvc: NearbyListingSvc,
    private alerter: AlertController,
    private gaService$: GoogleAnalyticsService,
    private store: Store<any>,
    private location: Location,
    private langService: LanguageService,
    private acceptCancelModal: AppAcceptCancelModalService,
    private cookieSvc: CookieService,
    private homepageAppSrv: HomePageAppService,
    private routingHelperSrv: RoutingHelperService,
    private routeNamesSrv: AppRouteNamesService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.$currentUserExists = this.accountService.currentUser$;

    this.enLangDesc = this.i18nService.get('navLinks.english');
    this.ptLangDesc = this.i18nService.get('navLinks.portuguese');
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  async ngOnInit() {
    this.isAdmin = this.checkSpotAdmin();
    this.validateUpdate();
    this.checkCookiesUsageAcceptance();
    this.faMapMarkerAlt = faMapMarkerAlt;
    this.customLoginText = this.i18nService.get('global.form.defaultSignInMessage');
    this.isLoggedIn$ = this.auth.$isLoginSubject;

    this.spotNearMeSubScription();
    this.openLoginDialogFirstLoad(false);

    if (this.location.path().includes('activate-user')) {
      this.goToHomepage();
      this.callHashAPI(this.activatedRoute.snapshot.queryParamMap.get('hash'));
    }

    this.routerObserver = this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(event => {
        this.activeRoute = event.url;
        this.regularSearchActiveRoute = this.nearbyListingSvc.resetActiveRoute(
          this.activeRoute,
          this.regularSearchActiveRoute
        );
        this.nearbySpotActiveRoute = this.nearbyListingSvc.resetActiveRoute(
          this.activeRoute,
          this.nearbySpotActiveRoute
        );
        this.drawer.close();
      });

    this.userAccountSubscription = this.accountService.currentUser$.subscribe(userAccount => {
      if (userAccount) {
        this.acceptPrivacyPolicy(userAccount);
        if (!this.accountService.getPersonalDataFlag) {
          this.openPersonalDataDialog(userAccount);
        }

        if (!userAccount.profileImage) {
          this.getUserProfile(userAccount);
        }
      } else {
        this.userAccount = userAccount;
      }
    });

    //this.newVersionSpot();
  }

  newVersionSpot() {
    if (!this.cookieSvc.get('modalNewVersion')) {
      this.cookieSvc.set('modalNewVersion', 'newVersion');
      this.alerter
        .create({
          header: '',
          message: `<div class="tittle-new-version"> ${this.i18nService.get('global.newVersionTittle')}</div>
                    <div class="row row-border2"></div>
                    <div class="content-text-align">
                    ${this.i18nService.get('global.newVersionModal')}
                    </div>
          `,
          buttons: [
            {
              text: this.i18nService.get('global.form.sendMsgToAgent.ok'),
              handler: () => {}
            }
          ]
        })
        .then(alert => {
          alert.present();
        });
    }
  }

  public async validateUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(evt => {
        localStorage.setItem('isDataAvailableReload', 'true');
      });
    }

    this.swUpdate
      .checkForUpdate()
      .then(() => {})
      .catch(() => {});
  }

  openPersonalDataDialog(account: any) {
    if (account && !account.personalDataAccepted) {
      const personalDataModalInstance = this.acceptCancelModal.openDialog({
        title: this.i18nService.get('personalDataModal.title'),
        body: this.i18nService.get('personalDataModal.description'),
        acceptText: this.i18nService.get('personalDataModal.accept'),
        cancelText: this.i18nService.get('personalDataModal.review')
      });

      personalDataModalInstance.beforeClosed().subscribe(() => {
        localStorage.setItem(this.accountService.seenPersonalDataFlagKey, JSON.stringify(true));
      });

      personalDataModalInstance.afterClosed().subscribe((result: string) => {
        switch (result) {
          case 'accept':
            const currentDate: string = this.commonService.getFormatedDate(new Date());
            this.accountService.acceptPersonalData(currentDate);
            break;
          case 'cancel':
            this.router.navigateByUrl('/user-home/my-account');
            break;
          case 'close':
          default:
            break;
        }
      });
    }
  }

  checkCookiesUsageAcceptance() {
    let cookieAcceptance: boolean = false;
    try {
      cookieAcceptance = !!window.localStorage.getItem(this.cookieAcceptanceName);
    } catch (error) {
      console.error('No localStorage available');
    }

    if (!cookieAcceptance) {
      setTimeout(() => (this.cookieAcceptanceAccepted = false), 1000);
    }
  }

  acceptCookieUsage() {
    this.cookieAcceptanceAccepted = true;
    try {
      localStorage.setItem(this.cookieAcceptanceName, 'Accepted');
    } catch (error) {
      console.error('No localStorage available');
    }
  }

  async callHashAPI(hash: String) {
    await this.accountService
      .activateUserWithHash(hash)
      .then(result => {
        this.showActivationMessageOK();
      })
      .catch(error => {
        this.showActivationMessageFail();
      });
  }

  ngOnDestroy() {
    this.userAccountSubscription.unsubscribe();
    this.spotNearMeFilterSubscription.unsubscribe();
    this.routerObserver.unsubscribe();
  }

  async acceptPrivacyPolicy(account: { ppAcceptedVersion: string }) {
    const isLoggedIn = this.isLoggedIn$.value;
    const hasAcceptedPolicy = this.privacyPolicyService.isUserPolicyUpdated(account.ppAcceptedVersion);
    if (isLoggedIn && !hasAcceptedPolicy && !this.privacyPolicyService.isPpDialogOpened) {
      this.openPrivacyPolicyModal();
    }
  }

  async openPrivacyPolicyModal() {
    this.privacyPolicyService.openPPDialog();
  }

  async presentAlertConfirm() {
    const heading = this.i18nService.get('global.menu.newVersion.heading');
    const message = this.i18nService.get('global.menu.newVersion.message');
    const launch = this.i18nService.get('global.menu.newVersion.launch');
    const dismiss = this.i18nService.get('global.menu.newVersion.dismiss');
    const alert = await this.alerter.create({
      header: heading,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: launch,
          handler: () => {
            /* localStorage.removeItem('lastVisitDate'); */
            window.location.reload();
          }
        },
        {
          text: dismiss,
          handler: () => {
            this.alerter.dismiss();
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {
      /* localStorage.removeItem('lastVisitDate'); */
      this.openLoginDialogFirstLoad(false);
    });
    await alert.present();
  }

  openLoginDialogFirstLoad(validateListing: boolean) {
    let countNavigation;
    if (!validateListing) {
      localStorage.setItem('sell', 'true');
    }

    let isLoggedIn: boolean;
    this.isLoggedIn$.pipe(take(1)).subscribe((val: any) => (isLoggedIn = val));
    const lastVisitDate = localStorage.getItem('lastVisitDate');
    let hasLoggedInToday = lastVisitDate && this.isToday(new Date(lastVisitDate));

    if (hasLoggedInToday) {
      return;
    }

    if (!isLoggedIn && !hasLoggedInToday) {
      localStorage.setItem('lastVisitDate', new Date().toString());
      //setTimeout(() => this.openLoginDialog(), 500);
    }
  }

  private spotNearMeSubScription() {
    this.spotNearMeFilterSubscription = this.listingService.spotNearMeFilter.subscribe((selected: boolean) => {
      this.regularSearchActiveRoute = this.nearbyListingSvc.setRegularSearchActiveRoute(this.activeRoute, selected);
      this.nearbySpotActiveRoute = this.nearbyListingSvc.setSpotNearMeActiveRoute(this.activeRoute, selected);
    });
  }

  private async getUserProfile(userAccount: any) {
    const userPhotos: any = await this.accountService.getUserPhotoList().toPromise();
    const image = this.accountService.getUploadedImageId(userPhotos);
    this.userAccount = {
      profile: userAccount,
      profileImage: this.getMediumImg(image.profileImageId),
      originalImage: this.getMediumImg(image.originalImageId),
      name: userAccount.firstName + ' ' + userAccount.lastName
    };
    //localStorage.setItem('user', this.userAccount);
    // this.accountService.currentUser$.next(this.userAccount);
  }

  private openListingMenu() {
    this.dialogRef = this.dialog.open(MyListingMenuComponent, {
      height: 'auto',
      width: '550px'
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  private clearBuySpotCart() {
    this.store.dispatch(CartActions.ClearCart({ payload: { items: [] } }));
  }

  public get isWeb(): boolean {
    if (this.platform) {
      return !this.platform.is('cordova');
    }
  }

  public get hasToken(): boolean {
    return !!localStorage.getItem('token');
  }

  public hasSearchDTO(searchDTO: any): boolean {
    return [searchDTO.buildingTypes, searchDTO.listingLeaseOrSale, searchDTO.keyword !== '' || searchDTO.keyword].some(
      condition => condition
    );
  }

  public loadNewAppVersion() {
    window.location.reload();
  }

  getSearchUrl() {
    return '/search';
  }

  async changeLanguage() {
    const alertController = await this.alertController.create({
      header: this.translateService.instant('Change language'),
      inputs: this.i18nService.supportedLanguages.map(language => ({
        type: 'radio' as TextFieldTypes,
        name: language,
        label: language,
        value: language,
        checked: language === this.i18nService.language
      })),
      buttons: [
        {
          text: this.translateService.instant('Cancel'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('Ok'),
          handler: language => {
            this.i18nService.language = language;
          }
        }
      ]
    });
    alertController.present();
  }

  toggleSideMenu(event: any) {
    this.drawer.toggle();
  }

  presentContactDialog() {
    this.contactDialogService.openDialog();
  }

  openLoginDialog() {
    const loginData = {
      url: this.router.url,
      customText: this.customLoginText
    };
    this.loginFormDialog.openDialog(loginData);
  }

  private isToday(someDate: Date): boolean {
    const today: Date = new Date();
    /* localStorage.setItem('currentDay', today.toString());
    console.log('currentDay ', today.toString()); */
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }

  goToSiiLaHomePage() {
    this.commonService.navgiateToSiilaHomePage();
  }

  goToSiiLaBlog() {
    let externalBlogRoute = `https://siila.com.br/blog`;
    window.open(externalBlogRoute, '_blank');
  }

  goToSiilaProductsPage() {
    window.open(this.homepageAppSrv.getProductsURL(), '_system');
  }

  logOut(event: any) {
    // Clear search on landing page
    this.listingService.updateModel({
      propertyType: null,
      listingType: null,
      stateId: null,
      cityId: null,
      keywordText: ''
    });
    this.auth.logout();
    this.clearBuySpotCart();
    if (this.router.url === '/') {
      this.toggleSideMenu(event);
    }
  }

  navigateToListProperty() {
    this.$currentUserExists.pipe(take(1)).subscribe(val => {
      if (val) {
        this.router.navigate([`/list-property`]);
      } else {
        this.loginFormDialog.openDialog('/list-property');
      }
    });
  }

  getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  goToUserHome() {
    return this.router.navigateByUrl('/user-home');
  }

  goToHomepage() {
    if (this.drawer) {
      this.drawer.close();
    }

    return this.router.navigateByUrl(this.i18nService.getHomeSPOTURL());
  }

  goToSearch() {
    return this.router.navigateByUrl(this.getSearchUrl());
  }

  openListYourSpotDialogue() {
    this.isLoggedIn$.pipe(take(1)).subscribe((val: any) => {
      if (val) {
        this.openListingMenu();
      } else {
        this.loginFormDialog.openDialog('my-listing');
      }
    });
  }

  getMyMessagesPage() {
    return '/user-home/my-messages';
  }

  goToMyMessages() {
    return this.router.navigateByUrl(this.getMyMessagesPage());
  }

  getUnreadMessages() {
    return this.myMessageService.getUnreadMessages();
  }

  getCurrentLanguageDesc() {
    const currentLanguage = this.i18nService.language;
    if (currentLanguage === 'en-US') {
      return this.enLangDesc;
    }
    return this.ptLangDesc;
  }

  public isItemActive(language: string) {
    return this.i18nService.language === language ? 'active-route' : '';
  }

  public isCurrentRegion(language: string) {
    return true;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
    this.langService.registerCulture(language);
    const currentRoute = this.router.url;

    const isLoggedIn = this.isLoggedIn$.value;
    if (isLoggedIn) {
      this.accountService.changeLanguage(this.i18nService.getCurrentLanguage());
    }

    const url = this.routeNamesSrv.findRouteAlternative(currentRoute);
    this.router.navigateByUrl(url);
  }

  async showActivationMessageOK() {
    const headerTxt = this.i18nService.get('global.register.accountActivation');
    const subHeaderTxt = this.i18nService.get('global.register.accountActivationMsgOK');
    const okText = this.i18nService.get('global.OK');

    const alert = await this.alertController.create({
      header: headerTxt,
      subHeader: subHeaderTxt,
      buttons: [okText]
    });
    alert.onDidDismiss().then(() => {
      setTimeout(() => this.openLoginDialog(), 500);
    });
    await alert.present();
  }

  async showActivationMessageFail() {
    const headerTxt = this.i18nService.get('global.register.accountActivation');
    const subHeaderTxt = this.i18nService.get('global.register.accountActivationMsgFail');
    const okText = this.i18nService.get('global.OK');

    const alert = await this.alertController.create({
      header: headerTxt,
      subHeader: subHeaderTxt,
      buttons: [okText]
    });
    await alert.present();
  }

  goToSiilaNews() {
    this.commonService.navgiateToSiilaNews();
  }

  goToSiilaSpot() {
    window.open(this.homepageAppSrv.getSpotURL(), '_blank');
  }

  goToFavorites() {
    this.routingHelperSrv.navigateFavoritesPage();
  }

  navigateToRegister() {
    this.dialog.open(RegisterFormComponent, {
      height: 'auto',
      width: '350px',
      maxWidth: 'none'
    });
  }

  goToMyAccount() {
    return this.router.navigateByUrl('/user-home/my-account');
  }

  checkSpotAdmin() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user && this.user.roles.includes('ROLE_SPOT_ADMIN')) {
      return true;
    } else {
      return false;
    }
  }
}
