import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { SpotBuildingDTO } from '../../models/spot-buildings/spot-buildings.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginFormDialogService } from '../login';
import { AuthenticationService } from '@app/core/auth';
import { resolve } from 'dns';
import { SpotSetBody } from '@app/models/spot-set-list.model';
import { EditTourBook } from '@app/models/edit-tour-book.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';

@Injectable()
export class FavoriteSpotService {
  public favoriteListing: string = 'api/spot/favorites/';
  private spotSetAPI = '/api/spotset/';
  public favoritesCountSubject = new BehaviorSubject<number>(0);
  private tourBook = 'api/tourbookdashboard/';
  private savedSearchesAPI = 'api/savedsearchesdashboard/';

  loginSubscription: Subscription;
  customLoginText: Array<string>;
  translateSubscription: Subscription;
  isLoggedIn: boolean;

  constructor(
    private http: HttpClient,
    private loginFormDialog: LoginFormDialogService,
    private userActivityService: UserActivityService,
    private _ts: TranslateService,
    private router: Router,
    private auth: AuthenticationService
  ) {
    this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe((res: Array<string>) => {
      this.customLoginText = res;
    });
    if (this.auth) {
      this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => {
        this.isLoggedIn = val;
      });
    }
  }

  toggleFavoriteListing(listing: SpotBuildingDTO, isFavorite: boolean) {
    listing.favorite = isFavorite;
    let currentFavoritesCount = this.favoritesCountSubject.getValue();
    if (isFavorite) {
      return this.http.delete(`${this.favoriteListing}${listing.id}/unmark`).pipe(
        tap(() => {
          listing.favorite = false;
          if (this.isActiveListing(listing)) {
            this.favoritesCountSubject.next(currentFavoritesCount - 1);
          }
        })
      );
    } else {
      this.favoriteActivityLog(listing);
      return this.http.put(`${this.favoriteListing}${listing.id}/mark`, {}).pipe(
        tap(() => {
          listing.favorite = true;
          if (this.isActiveListing(listing)) {
            this.favoritesCountSubject.next(currentFavoritesCount + 1);
          }
        })
      );
    }
  }

  getFavoriteListings() {
    return this.http.get(this.favoriteListing);
  }

  isActiveListing(listing: SpotBuildingDTO) {
    return listing;
  }

  favoriteActivityLog(listing: SpotBuildingDTO) {
    this.userActivityService.trackSpotBuildingFavorite(listing.id);
  }

  updateFavoritesCount() {
    this.getFavoriteListings().subscribe((val: Array<any>) => {
      this.favoritesCountSubject.next(val.filter(l => l.listingStatus.id == 4).length);
    });
  }

  openLoginDialog() {
    const currentRoute: string = this.router.url;
    return this.loginFormDialog.openDialog({
      url: currentRoute,
      customText: this.customLoginText
    });
  }

  async setFavoriteSpot(event: any, spot: SpotBuildingDTO) {
    event.stopPropagation();

    let promise = new Promise((resolve, reject) => {
      if (!this.isLoggedIn) {
        this.openLoginDialog()
          .afterClosed()
          .subscribe(() => {
            if (this.isLoggedIn) {
              this.toggleFavoriteListing(spot, spot.favorite || false).subscribe((res: any) => {
                resolve(res);
              });
            }
          });
      } else {
        this.toggleFavoriteListing(spot, spot.favorite || false).subscribe((res: any) => {
          resolve(res);
        });
      }
    });

    return promise;
  }

  getSpotSetFavorite(page: number, lang: string, bodySpotSet: SpotSetBody) {
    return this.http.post(`${this.spotSetAPI}search?page=${page}&per_page=10&lang=${lang}`, bodySpotSet).toPromise();
  }

  deleteAction(id: number, apiName: string) {
    return this.http.delete(`${apiName}${id}`).toPromise();
  }

  getTourBook(page: number, lang: string, body: SpotSetBody) {
    return this.http.post(`${this.tourBook}search?page=${page}&per_page=10&lang=${lang}`, body).toPromise();
  }

  sendEmail(id: number, email: any, lang: string) {
    return this.http.post(`${this.tourBook}share/${id}/${lang}`, email).toPromise();
  }

  editTitleTourBook(editTitle: EditTourBook) {
    return this.http
      .patch(this.tourBook, editTitle, {
        observe: 'response'
      })
      .pipe(
        map(val => {
          return val.status;
        })
      )
      .toPromise()
      .catch(val => {
        return val.status;
      });
  }

  getSavedSearches(page: number, lang: string, body: SpotSetBody) {
    return this.http.post(`${this.savedSearchesAPI}search?page=${page}&per_page=10&lang=${lang}`, body).toPromise();
  }
}
