import { Component, Input, OnInit } from '@angular/core';
import { AgentDTO } from '../../models/spot-buildings/agent-broker.model';
import { ImageService } from '../../core/image.service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserActivity, UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';

@Component({
  selector: 'app-company-agent-card',
  templateUrl: './company-agent-card.component.html',
  styleUrls: ['./company-agent-card.component.scss']
})
export class CompanyAgentCardComponent implements OnInit {
  showEmail: boolean = true;
  showWhatsapp: boolean = true;
  currentDevice: string;
  isDesktop: string;
  isMobilePhone: string;
  isTablet: string;

  constructor(
    private imageService: ImageService,
    private analyticsService: AnalyticsService,
    private userActivityService: UserActivityService,
    private deviceDetector: DeviceDetectorService
  ) {}

  @Input('agentDTO') agentDTO: AgentDTO;

  ngOnInit() {
    this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
    this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
    this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
    if (this.isDesktop.length) {
      this.currentDevice = this.isDesktop;
    } else if (this.isMobilePhone.length) {
      this.currentDevice = this.isMobilePhone;
    } else if (this.isTablet.length) {
      this.currentDevice = this.isTablet;
    }
  }

  public getLargeImg(id: any) {
    return this.imageService.largeThumbnail(id);
  }

  openEmail(event: any) {
    event.stopImmediatePropagation();
    if (!this.showEmail) {
      window.location.href = `mailto:${this.agentDTO.userEmail}`;
    }
    this.showEmail = false;
    this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL, this.agentDTO.brokerId, this.agentDTO.companyId);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
    return false;
  }

  openWhatasapp(event: any) {
    event.stopImmediatePropagation();
    this.showWhatsapp = false;
    this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP, this.agentDTO.brokerId, this.agentDTO.companyId);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
    return false;
  }

  registerUserActivity(userActivityType: UserActivityType, brokerId: number, companyId: number) {
    const userActivity: UserActivity = {
      module: userActivityType,
      brokerId: brokerId,
      companyId: companyId
    };
    this.userActivityService.saveUserActivity(userActivity);
  }
}
