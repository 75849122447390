import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class MatomoService {
  constructor() {}

  public init() {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        var user = JSON.parse(localStorage.getItem('user'));
        var userId = !user || user == undefined ? 'ANONYMOUS ' + data.ip : user.email + ' ' + data.ip;
        this.configTrackingID(environment.matomoSiteId, userId);
      })
      .catch(error => {
        console.error('Couldnt get user ip');
      });
  }

  public addUserIdToGA() {
    //(window as any).gtag('set', { 'user_id': userId });
    //this.gaService.event('User', 'Set User ID', userId);
  }

  private configTrackingID(siteId: string, userId: string) {
    try {
      const matomoScript = document.createElement('script'); //  _paq.push(['trackPageView']);
      matomoScript.innerHTML = `
        var _paq = window._paq = window._paq || [];
        _paq.push(['setUserId', '${userId}']);
        _paq.push(['enableLinkTracking']);
        (function() {
          var u = "https://piwik.siila.com/";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '${siteId}']);
          var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
          g.async = true; g.src = u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
      `;
      document.head.appendChild(matomoScript);
      //console.log(`${siteId} configuration: OK`);
    } catch (ex) {
      console.error(`Error appending Matomo script: ${siteId}`);
      console.error(ex);
    }
  }

  public trackPageView(): void {
    try {
      const matomoScript = document.createElement('script');
      matomoScript.innerHTML = `
        var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']);
      `;
      document.head.appendChild(matomoScript);
      //console.log(`Track page view: OK`);
    } catch (ex) {
      console.error(`Error appending Matomo script: ${ex}`);
    }
  }

  public trackPageView2(pageTitle: string): void {
    try {
      const currentUrl = location.href;
      const matomoScript = document.createElement('script');
      matomoScript.innerHTML = `
        var _paq = window._paq = window._paq || [];
        _paq.push(['setCustomUrl', '${currentUrl}']);
        _paq.push(['trackPageView']);
      `;
      document.head.appendChild(matomoScript);
      //console.log(`Track page view: OK: ${currentUrl} ${pageTitle} `);
    } catch (ex) {
      console.error(`Error appending Matomo script: ${ex}`);
    }
  }
}
