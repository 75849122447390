import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
import * as i0 from "@angular/core";
export class LanguageService {
    set locale(value) {
        this._locale = value;
    }
    get locale() {
        return this._locale || 'en-US';
    }
    registerCulture(culture) {
        if (!culture) {
            return;
        }
        // Register locale data since only the en-US locale data comes with Angular
        switch (culture) {
            case 'pt-BR': {
                registerLocaleData(localePT);
                break;
            }
        }
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(); }, token: LanguageService, providedIn: "root" });
