<div class="my-listing-filter">
  <ion-button
    [ngClass]="overrideColor(1200)"
    class="review-filter-btn"
    [color]="setButtonColor(1200)"
    (click)="openMyListingFilterModal()"
  >
    <span [ngClass]="overrideColor(1200)" translate="global.myListingFilter.listingReviewFilters"></span>
  </ion-button>
</div>
