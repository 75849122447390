/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./news.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i6 from "@ionic/angular";
import * as i7 from "./news-card/news-card.component.ngfactory";
import * as i8 from "./news-card/news-card.component";
import * as i9 from "../../core/image.service";
import * as i10 from "@angular/common";
import * as i11 from "../../core/i18n.service";
import * as i12 from "@angular/cdk/layout";
import * as i13 from "../../core/helpers/platform-helper.service";
import * as i14 from "@ngx-translate/core";
import * as i15 from "./news.component";
import * as i16 from "../services/news.service";
var styles_NewsComponent = [i0.styles];
var RenderType_NewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsComponent, data: {} });
export { RenderType_NewsComponent as RenderType_NewsComponent };
function View_NewsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "back"], ["ion-button", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fa-icon", [["class", "arrow-icon  ng-fa-icon"], ["size", "lg"]], [[1, "title", 0], [8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prevSlide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(3, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.leftArrow; var currVal_3 = "lg"; _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).title; var currVal_1 = i1.ɵnov(_v, 3).renderedIconHTML; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_NewsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-slide", [["class", "lazy-slides"]], null, null, null, i5.View_IonSlide_0, i5.RenderType_IonSlide)), i1.ɵdid(1, 49152, null, 0, i6.IonSlide, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n          "])), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "news-card", [], null, null, null, i7.View_NewsCardComponent_0, i7.RenderType_NewsCardComponent)), i1.ɵdid(4, 114688, null, 0, i8.NewsCardComponent, [i9.ImageService, i10.DatePipe, i11.I18nService, i12.BreakpointObserver, i13.PlatformHelperService], { article: [0, "article"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 4, 0, currVal_0); }, null); }
function View_NewsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsComponent_3)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["style", "float: right;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "button", [["class", "next"], ["ion-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextSlide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "fa-icon", [["class", "arrow-icon  ng-fa-icon"], ["size", "lg"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(13, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(17, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(19, 0, null, null, 5, "ion-slides", [["class", "m-slid"], ["pager", "false"]], null, [[null, "ionSlideReachEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionSlideReachEnd" === en)) {
        var pd_0 = (_co.slideReachEnd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonSlides_0, i5.RenderType_IonSlides)), i1.ɵdid(20, 49152, [[1, 4], ["slider", 4]], 0, i6.IonSlides, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { options: [0, "options"], pager: [1, "pager"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NewsComponent_4)), i1.ɵdid(23, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.slideOpts.isBeginningSlide; _ck(_v, 5, 0, currVal_0); var currVal_3 = _co.rightArrow; var currVal_4 = "lg"; _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_5 = _co.slideOpts; var currVal_6 = "false"; _ck(_v, 20, 0, currVal_5, currVal_6); var currVal_7 = _co.articles.content; _ck(_v, 23, 0, currVal_7); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 13).title; var currVal_2 = i1.ɵnov(_v, 13).renderedIconHTML; _ck(_v, 12, 0, currVal_1, currVal_2); }); }
function View_NewsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "industry-news-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openHomeNews() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsComponent_2)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(12, 0, null, null, 9, "div", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 6, "ion-button", [["class", "btn-color-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openHomeNews() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonButton_0, i5.RenderType_IonButton)), i1.ɵdid(15, 49152, null, 0, i6.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "span", [["style", "color: black;font-weight:bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.articles == null) ? null : _co.articles.content.length); _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("newsHeader")); _ck(_v, 5, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("readMore")); _ck(_v, 18, 0, currVal_2); }); }
export function View_NewsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { slider: 0 }), i1.ɵqud(671088640, 2, { slideWithNav3: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.articles == null) ? null : _co.articles.content.length); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "industry-news", [], null, null, null, View_NewsComponent_0, RenderType_NewsComponent)), i1.ɵdid(1, 114688, null, 0, i15.NewsComponent, [i16.NewsService, i12.BreakpointObserver, i11.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsComponentNgFactory = i1.ɵccf("industry-news", i15.NewsComponent, View_NewsComponent_Host_0, { companyId: "companyId" }, {}, []);
export { NewsComponentNgFactory as NewsComponentNgFactory };
