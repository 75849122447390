import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ImageService, PlatformHelperService, I18nService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { AppListingSliderService } from '@app/search-navigation/components/listing-slider';
import { DetailPositionService } from '@app/search-navigation/services/detail-position.service';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
import { SpotBuildingSpaceService } from '@app/spot-buildings/services/spaces.service';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { IonSlides } from '@ionic/angular';
import { PropertyTypeHelperService } from '../../../core/helpers/property-type-helper.service';
import { SizeIcon } from '../../../models/shared/shared-enums.model';
export class SpotCard {
    constructor(imageService, router, detailPositionService, platformConfigHelper, dialog, commonService, i18nService, spotBuildingsService, spacesService, listingSliderService, breakpointObserver, propertyHelperService) {
        this.imageService = imageService;
        this.router = router;
        this.detailPositionService = detailPositionService;
        this.platformConfigHelper = platformConfigHelper;
        this.dialog = dialog;
        this.commonService = commonService;
        this.i18nService = i18nService;
        this.spotBuildingsService = spotBuildingsService;
        this.spacesService = spacesService;
        this.listingSliderService = listingSliderService;
        this.breakpointObserver = breakpointObserver;
        this.propertyHelperService = propertyHelperService;
        this.favoriteChangeEvent = new EventEmitter();
        this.isMobile = false;
        this.currentPhotoIndex = 0;
        this.count = 0;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.noPhotos = true;
        this.favIconSize = SizeIcon.LG;
        this.favoriteContainerClass = 'favorite__floating';
        this.screenWidth = window.innerWidth;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    ngOnInit() {
        if (!this.isPromoted) {
            const isOffice = this.commonService.isPropertyType(2001, this.listing);
            if (this.customContainerClass) {
                this.favoriteContainerClass = `${this.favoriteContainerClass} ${this.customContainerClass}`;
            }
            this.spotName = this.i18nService.getTranslation(this.listing.buildingTitle);
            this.hasPointer = window.matchMedia('(pointer: fine)').matches;
            this.noPhotos = !this.listing.buildingListingPhotos || this.listing.buildingListingPhotos.length == 0;
            this.propertyType = this.propertyHelperService.getPropertyTypeTranslationName(this.listing.buildingTypeId);
            this.spotType = this.propertyHelperService.getSpotBuildingTypeTranslation(this.listing.type);
        }
    }
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
    }
    changeSlide(event) {
        let swipeDirection = event.srcElement.dom7ElementDataStorage.swiper.swipeDirection;
        this.listingSliderService.setSlide(swipeDirection);
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    getLargeImg(id) {
        return this.imageService.largeThumbnail(id);
    }
    getCompanyLogoImg(id) {
        return id ? this.getMediumImg(id) : '../../../assets/no-company-logo.png';
    }
    openPreviewComponent($event) {
        $event.stopImmediatePropagation();
        this.dialogRef = this.dialog.open(PreviewImagesComponent, {
            height: '78%',
            width: '60%',
            data: this.listing,
            panelClass: 'custom-modalbox'
        });
        this.dialogRef.afterClosed().subscribe((result) => { });
    }
    openCard(id, $event, externalLink = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const state = {
                localStorageKey: this.localStorageKey,
                searchCriteria: this.searchCriteria,
                searchTags: this.searchTags,
                checkBoxValues: this.checkBoxValues,
                openUrl: true
            };
            $event.stopImmediatePropagation();
            let lang = this.i18nService.getCurrentLanguage();
            if (window.location.href.includes('/pt-br')) {
                lang = 'pt-br';
            }
            else if (window.location.href.includes('/en')) {
                lang = 'en';
            }
            let res = yield this.spotBuildingsService.getSpotBuildingDetailURLFormat(id, lang).toPromise();
            if (res) {
                let detailsURL = res.detailsURL;
                if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                    detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                }
                if (externalLink) {
                    if (window.matchMedia('(display-mode: standalone)').matches) {
                        this.router.navigateByUrl(detailsURL);
                    }
                    else {
                        window.open(detailsURL, '_blank');
                    }
                    return;
                }
                let spacesFilter = Object.assign({}, this.searchCriteria);
                this.spacesService.saveSpacesFilter(spacesFilter);
                if (this.isMobile) {
                    this.router.navigateByUrl(detailsURL, { state: state });
                }
                else {
                    if (window.matchMedia('(display-mode: standalone)').matches) {
                        this.router.navigateByUrl(detailsURL);
                    }
                    else {
                        window.open(detailsURL, '_blank');
                    }
                }
                this.detailPositionService.setPositionData(this.activeListingIds);
            }
        });
    }
    getNextPhoto(id, $event) {
        this.photoSlider.getActiveIndex().then(index => {
            if (index < this.currentPhotoIndex) {
                this.currentPhotoIndex = index;
            }
            else {
                if (this.currentPhotoIndex === this.listing.buildingListingPhotos.length - 1 && this.count == 0) {
                    if (this.openCardFromLastImg) {
                        this.openCard(id, $event);
                    }
                }
                else {
                    if ($event.type === 'click') {
                        this.photoSlider.slideNext();
                        this.updatePhotoIndex();
                        this.count = 1;
                    }
                    else {
                        if (this.count == 1) {
                            this.count = 0;
                        }
                        else {
                            this.updatePhotoIndex();
                        }
                    }
                }
            }
        });
    }
    getPreviousPhoto() {
        if (this.currentPhotoIndex === 0) {
            this.photoSlider.slideTo(this.listing.buildingListingPhotos.length);
        }
        else {
            this.photoSlider.slidePrev();
        }
        this.updatePhotoIndex();
    }
    showPrevNextNav() {
        return [this.hasPointer, this.listing.buildingListingPhotos && this.listing.buildingListingPhotos.length > 1].every(condtion => condtion);
    }
    getCroppedOrOriginalImage(listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    }
    updatePhotoIndex() {
        this.photoSlider.getActiveIndex().then(index => {
            this.currentPhotoIndex = index;
        });
    }
    slideToIndex(event, index) {
        event.stopPropagation();
        this.photoSlider.slideTo(index);
    }
    getCityState() {
        return `${this.i18nService.getTranslation(this.listing.cityName)} - ${this.i18nService.getTranslation(this.listing.stateName)}, Brasil`;
    }
    getSubHeaderCardInfo() {
        var subHeaderInfo = `${this.i18nService.getTranslation(this.listing.buildingAddress)} `;
        return this.truncate(subHeaderInfo);
    }
    truncate(value) {
        var limit = 90;
        if (value && value.length > limit) {
            value = value.substring(0, limit) + '...';
        }
        return value;
    }
    getTypeText() {
        return this.commonService.getTypeText(this.listing.type);
    }
    getPropertyTypeText() {
        return this.commonService.getPropertyTypeText(this.listing.buildingTypeId);
    }
    getAvailableAreaText() {
        return this.commonService.getAvailableAreaText(this.listing.spaceRangesDTO, this.listing.type);
    }
    getAvgAskingRent() {
        return this.commonService.getAvgAskingRent(this.listing.spaceRangesDTO, this.listing.type);
    }
    favoriteChange(changedFavorite) {
        this.favoriteChangeEvent.emit(changedFavorite);
    }
}
