import { P } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { I18nService, PurchaseCreditsService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { ListingService } from '@app/core/listings';
import { BuildingDTO } from '@app/models/building.model';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { SpotBuildingDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { ListingOfferType, ListingType } from '@app/models/transaction-type.model';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { AlertController, NavController, PopoverController } from '@ionic/angular';
import * as Fuse from 'fuse.js';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'spot-buildings-detail',
  templateUrl: './spot-buildings-detail.component.html',
  styleUrls: ['./spot-buildings-detail.component.scss']
})
export class SpotBuildingsDetailComponent implements OnInit {
  actualBuilding: any;
  @Input('spotBuildings') spotBuildings: CreateSpotBuildingsRequest;
  @Input('propertyType') propertyType: number[];
  @Input('buildingDetail') buildingDetail: SpotBuildingDTO;
  building = new BuildingDTO();
  buildingNames: any;
  buildings: any;
  fuse: Fuse<any, any>;
  fuzzyName: any;
  industrialBuildings: any;
  officeBuildings: any;
  retailBuildings: any;
  public inputBuilding: string = '';
  stopFuzzy = false;
  propertyNameFieldOptions: any;
  mapOptions: any = {
    singleMarker: true,
    ignoreZoom: false,
    zoom: 15
  };
  marker: any;
  changePropertyType = false;
  propertyName: any;
  count = 0;

  formData: FormGroup;
  isDesktop: string;
  isUpdate: boolean = false;

  constructor(
    private purchaseService: PurchaseCreditsService,
    private listingService: ListingService,
    private i18NService: I18nService,
    private createSpotBuildingsService: CreateSpotBuildingsService,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private deviceDetector: DeviceDetectorService,
    private router: Router,
    private navCtrl: NavController
  ) {}

  ngOnInit(): void {
    this.checkBuildingType(2001);
    this.multiLangInit();
    this.checkBuildingOffer(1, 1);
    this.checkListingType(1);
    this.checkBuildingTypeSale(false);
    this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
  }

  ngOnChanges(changes: any) {
    if (this.buildingDetail) {
      this.isUpdate = true;
      this.checkBuildingType(this.buildingDetail.building.buildingType.id);
      this.checkBuildingOffer(this.buildingDetail.forLease ? 1 : this.buildingDetail.forSale ? 3 : 2, 1);
      this.checkListingType(
        this.buildingDetail.listingType == ListingType.Featured
          ? 1
          : this.buildingDetail.listingType == ListingType.Premium
          ? 2
          : this.buildingDetail.listingType == ListingType.Promoted
          ? 3
          : 4
      );
      this.setProperty(this.buildingDetail.building, true);
      this.checkBuildingTypeSale(this.buildingDetail.entireSale);
      if (this.buildingDetail.offMarket) {
        this.spotBuildings.offMarket = this.buildingDetail.offMarket;
      }
      if (this.buildingDetail.featured) {
        this.spotBuildings.featured = this.buildingDetail.featured;
      }

      if (this.buildingDetail.enabled) {
        this.spotBuildings.enabled = this.buildingDetail.enabled;
      }
    }
  }

  clearInput() {
    this.inputBuilding = '';

    this.building.id = null;
    this.spotBuildings.building = this.building;
    this.spotBuildings.propertyName = '';

    this.stopFuzzy = true;
  }

  async initBuildingType() {
    const options: Fuse.FuseOptions<any> = {
      keys: ['name'],
      shouldSort: true,
      caseSensitive: false,
      threshold: 0.5,
      findAllMatches: true
    };

    this.fuse = new Fuse(this.buildings, options);
  }

  async checkBuildingOffer(typeOffer: number, init: number = 0) {
    this.spotBuildings.type = '';
    let isValid =
      init != 0
        ? true
        : await this.isValidProperty(
            this.spotBuildings.building ? this.spotBuildings.building : null,
            typeOffer == 1
              ? ListingOfferType.Lease
              : typeOffer == 2
              ? ListingOfferType.Coworking
              : ListingOfferType.Sale,
            1
          );

    if (isValid) {
      if (typeOffer == 1) {
        this.spotBuildings.type = ListingOfferType.Lease;
        this.spotBuildings.entireSale = false;
      }

      if (typeOffer == 2) {
        this.spotBuildings.type = ListingOfferType.Coworking;
        this.spotBuildings.entireSale = false;
        if (this.propertyType[0] != 2001) {
          this.checkBuildingType(2001);
        }
      }

      if (typeOffer == 3) {
        this.spotBuildings.type = ListingOfferType.Sale;
      }
    }
  }

  checkListingType(listingType: number) {
    if (listingType == 1) {
      this.spotBuildings.listingType = ListingType.Featured;
      return;
    }

    if (listingType == 2) {
      this.spotBuildings.listingType = ListingType.Premium;
      return;
    }
    if (listingType == 3) {
      this.spotBuildings.listingType = ListingType.Promoted;
      return;
    }

    if (listingType == 4) {
      this.spotBuildings.listingType = ListingType.Basic;
      return;
    }
  }

  async checkBuildingType(buildingType: any) {
    this.clearInput();
    if (buildingType === 1001) {
      if (!this.industrialBuildings) {
        this.industrialBuildings = await this.purchaseService.getBuildingNamesByPropertyType(1001);
        this.buildingNames = this.industrialBuildings;
        this.buildings = this.industrialBuildings.values;
      } else {
        this.buildingNames = this.industrialBuildings;
        this.buildings = this.industrialBuildings.values;
      }
      this.propertyType[0] = 1001;
      this.stopFuzzy = false;
    }

    if (buildingType === 2001) {
      if (!this.officeBuildings) {
        this.officeBuildings = await this.purchaseService.getBuildingNamesByPropertyType(2001);
        this.buildingNames = this.officeBuildings;
        this.buildings = this.officeBuildings.values;
      } else {
        this.buildingNames = this.officeBuildings;
        this.buildings = this.officeBuildings.values;
      }
      this.propertyType[0] = 2001;
      this.stopFuzzy = false;
    }

    if (buildingType === 3001) {
      if (!this.retailBuildings) {
        this.retailBuildings = await this.purchaseService.getBuildingNamesByPropertyType(3001);
        this.buildingNames = this.retailBuildings;
        this.buildings = this.retailBuildings.values;
      } else {
        this.buildingNames = this.retailBuildings;
        this.buildings = this.retailBuildings.values;
      }
      this.propertyType[0] = 3001;
      this.stopFuzzy = false;
    }

    this.initBuildingType();
  }

  checkBuildingTypeSale(isTypeOfSale: boolean) {
    this.spotBuildings.entireSale = isTypeOfSale;
  }

  suggest_property(term: any) {
    if (term) {
      var q = term.toLowerCase().trim();
      return this.fuse
        .search(q)
        .slice(0, 10)
        .map((element: any) => {
          return element;
        });
    }
  }

  async changeFuzzySearch() {
    if (!this.changePropertyType) {
      this.stopFuzzy = this.inputBuilding == '' ? false : true;
      this.buildingNames = this.suggest_property(this.inputBuilding);
    } else {
      this.changePropertyType = false;
      this.stopFuzzy = false;
    }
  }

  async setProperty(building: any, update?: boolean) {
    let isValid = await this.isValidProperty(building, this.spotBuildings.type, 2, update);

    if (isValid) {
      this.stopFuzzy = true;
      this.inputBuilding = update ? this.i18NService.getTranslation(this.buildingDetail.title) : building.name;

      this.building.id = building.id;
      this.spotBuildings.building = this.building;
      this.propertyName = update
        ? this.buildingDetail.title
        : `{"en":"${building.name.replace(/"/g, '\\"')}","pt-br":"${building.name.replace(/"/g, '\\"')}"}`;
      this.buildingNames = null;
      this.listingService.getBuilding(building.id).then(resp => {
        this.actualBuilding = resp;
        this.marker = { lat: this.actualBuilding.latitude, lng: this.actualBuilding.longitude };
      });

      this.changePropertyType = true;

      if (!update) {
        this.listingService.getBuilding(building.id).then((resp: any) => {
          this.checkFeaturesRequest(resp);
        });
      }
    }
  }

  async isValidProperty(building: any, type: string, opt: number, update?: boolean) {
    let resp: any;
    if (update) {
      return true;
    }

    if (building.id && type) {
      resp = await this.createSpotBuildingsService
        .validateProperty(building.id, type, this.i18NService.getCurrentLanguage())
        .then((resp: any) => {
          if (resp == null) {
            return true;
          } else {
            this.alertCtrl
              .create({
                header: this.i18NService.get('global.form.sendMsgToAgent.notification'),
                message: `<span>${this.i18NService.get('buildinsDetail.validateProperty')} 
                <p class="color-p">${resp.detailsURL}</p></span>`,
                buttons: [
                  {
                    text: this.i18NService.get('global.form.sendMsgToAgent.ok'),
                    handler: () => {
                      /* this.popoverCtrl.dismiss(); */

                      this.initBuilding(opt);
                    }
                  },
                  {
                    text: 'Go to Building',
                    handler: () => {
                      this.initBuilding(opt);
                      this.openUrlBuilding(resp.detailsURL);
                    }
                  }
                ]
              })
              .then(alert => {
                alert.present();
              });

            return false;
          }
        });
    } else {
      resp = true;
    }

    return resp;
  }

  openUrlBuilding(url: string) {
    if (this.isDesktop.length) {
      window.open(url, '_blank');
    } else {
      this.router.navigateByUrl(url);
    }
  }

  initBuilding(opt: number) {
    if (this.buildingDetail) {
      if (opt == 1) {
        this.checkBuildingOffer(this.buildingDetail.forLease ? 1 : this.buildingDetail.forSale ? 3 : 2, 1);
      } else {
        this.setProperty(this.buildingDetail.building, true);
      }
    } else {
      this.clearInput();
      this.checkBuildingOffer(1, 1);
    }
  }

  checkFeaturesRequest(features: any) {
    this.spotBuildings.twentyFourHourSecurity = features.twentyFourHourSecurity;
    this.spotBuildings.electricGenerator = features.electricGenerator;
    this.spotBuildings.heliport = features.heliport;
    this.spotBuildings.sprinklers = features.sprinklers;
    this.spotBuildings.leedStatus = features.leedStatus;
    this.spotBuildings.singleTenant = features.singleTenant;
    this.spotBuildings.multiTenant = features.multiTenant;
    this.spotBuildings.bicycleRack = features.bicycleRack;
    this.spotBuildings.fiberOptic = features.fiberOptic;
    this.spotBuildings.centralAirConditioning = features.centralAirConditioning;
    this.spotBuildings.petFriendly = features.petFriendly;
    this.spotBuildings.truckYard = features.truckYard;
    this.spotBuildings.crossDocking = features.crossDocking;
    this.spotBuildings.sluiceGate = features.sluiceGate;
    this.spotBuildings.blockAndSteelConstruction = features.blockAndSteelConstruction;
    this.spotBuildings.blockConstruction = features.blockConstruction;
    this.spotBuildings.steelConstruction = features.steelConstruction;
    this.spotBuildings.rentableArea = features.rentableArea;
    this.spotBuildings.ceilingHeight = features.ceilingHeight;
    this.spotBuildings.parkingSpaces = features.parkingSpaces;
    this.spotBuildings.industrialStatus = features.industrialStatus;
    this.spotBuildings.yearBuilt = features.yearBuilt;
    this.spotBuildings.carParkingSpaces = features.carParkingSpaces;
    this.spotBuildings.truckParkingSpaces = features.industrialParkingSpaces;
    this.spotBuildings.landArea = features.landArea;
    this.spotBuildings.numberStories = features.numberStories;
    this.spotBuildings.averageFloorPrivateArea = features.averageFloorPrivateArea;
    this.spotBuildings.address = this.validateAddress(features.address);
  }

  validateAddress(BuildingAddress: string): string {
    let address: any[] = JSON.parse(BuildingAddress);
    return address['pt-br'] && address['en']
      ? BuildingAddress
      : address['pt-br']
      ? `{"en":"${address['pt-br']}","pt-br":"${address['pt-br']}"}`
      : `{"en":"${address['en']}","pt-br":"${address['en']}"}`;
  }

  multiLangInit() {
    this.propertyNameFieldOptions = {
      columnLabel: 'global.list-your-property.propertyNameDisplay',
      element: { input: true },
      formArrayName: 'property',
      required: true
    };
  }

  propertyNameChange(value: any) {
    if (value.property.length > 0) {
      let mergedObject: any = {};
      let nullValues: boolean = false;
      Object.keys(value.property).forEach(async (key, idx) => {
        let obj: any = value.property[key];
        if (obj['en'] === '' || obj['pt-br'] === '') {
          nullValues = true;
        } else {
          mergedObject = Object.assign(mergedObject, obj);
        }
      });
      nullValues
        ? (this.spotBuildings.propertyName = '')
        : (this.spotBuildings.propertyName = JSON.stringify(mergedObject));
    } else {
      this.spotBuildings.propertyName = '';
    }
  }
}
