import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { I18nService, ImageService, PlatformHelperService } from '@app/core';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IonSlides } from '@ionic/angular';
export class ServicesComponent {
    constructor(spotBuildingsService, i18NService, platformConfigHelper, imgService, userActivityService) {
        this.spotBuildingsService = spotBuildingsService;
        this.i18NService = i18NService;
        this.platformConfigHelper = platformConfigHelper;
        this.imgService = imgService;
        this.userActivityService = userActivityService;
        this.services = [];
        this.faChevronRight = faChevronRight;
        this.faChevronLeft = faChevronLeft;
    }
    ngOnInit() {
        this.getServices();
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    }
    getServices() {
        this.spotBuildingsService.getServiceProvider(this.spotBuildingId).then((response) => {
            this.services = response;
            this.services.forEach((item) => {
                item.serviceCategory = this.i18NService.getTranslation(item.serviceCategory);
                item.services = this.removeHTMLCode(this.i18NService.getTranslation(item.services));
            });
        });
    }
    removeHTMLCode(htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    }
    getMediumImg(id) {
        return this.imgService.mediumThumbnail(id);
    }
    getCroppedOrOriginalImage(buildinListingPhoto) {
        if (buildinListingPhoto) {
            if (buildinListingPhoto.croppedImage && buildinListingPhoto.croppedImage.id) {
                return buildinListingPhoto.croppedImage.id;
            }
            return buildinListingPhoto.image.id;
        }
    }
    getPreviousPhoto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.photoSlider.slidePrev();
        });
    }
    getNextPhoto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.photoSlider.slideNext();
        });
    }
    cssImgServiceMobile() {
        return {
            width: window.screen.width - 80 + 'px',
            height: ((window.screen.width - 80) / 4) * 3 + 'px'
        };
    }
    goToService(service) {
        window.open(service.website, '_blank');
        this.registerUserActivity(service.id, UserActivityType.SERVICE_PROVIDER_DETAIL);
    }
    registerUserActivity(adCompanyId, module) {
        const userActivity = {
            module: module,
            brokerId: null,
            buildingListingId: this.spotBuildingId,
            adCompanyId: adCompanyId
        };
        this.userActivityService.saveUserActivity(userActivity);
    }
}
