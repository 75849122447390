import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Form } from '@angular/forms';
import { RegisterService } from '@app/core/register.service';
import { LanguageService } from '@app/core';
import { ListingPriceService, EventCheckerService } from '@app/core/listings';

@Component({
  selector: 'app-listing-features',
  templateUrl: './listing-features.component.html',
  styleUrls: ['./listing-features.component.scss']
})
export class ListingFeaturesComponent implements OnInit {
  @Input('form') listingFeaturesForm: FormGroup;
  @Input('listingTypeForm') listingTypeForm: FormGroup;
  @Input('industrialFilters') industrialFilters: any;
  @Input('officeFilters') officeFilters: any;
  @Input('listing') listing: any;

  constructor(
    private registerService: RegisterService,
    private langService: LanguageService,
    private listingPriceService: ListingPriceService,
    private _messageService: EventCheckerService
  ) {}

  ngOnInit(): void {
    this.listing ? this.setExistingFeatures() : null;
    this.detectFeatures();
  }

  detectFeatures() {
    this._messageService.updateOfficeFilters$.subscribe((pOfficeFilters: any) => {
      if (pOfficeFilters) {
        this.officeFilters = pOfficeFilters;
      }
    });

    this._messageService.updateIndustrialFilters$.subscribe((pFilters: any) => {
      if (pFilters) {
        this.industrialFilters = pFilters;
      }
    });
  }

  async setExistingFeatures() {
    if (this.listing.building.buildingType.id === 1001) {
      this.industrialFilters = await this.registerService.selectExistingFeatures(
        this.listing,
        null,
        this.industrialFilters
      );
      let res = await this.setFormFeatures('industrial', this.listing);
    } else {
      this.officeFilters = await this.registerService.selectExistingFeatures(this.listing, this.officeFilters, null);
      let res = await this.setFormFeatures('office', this.listing);
    }
  }

  setOfficeTypes(index: any) {
    if (this.officeFilters[index].selected) {
      this.officeFilters[index].selected = false;
    } else {
      this.officeFilters[index].selected = true;
    }
    this.listingFeaturesForm.controls['tags'].setValue(this.officeFilters);
  }

  setIndustrialTypes(index: any) {
    if (this.industrialFilters[index].selected) {
      this.industrialFilters[index].selected = false;
    } else {
      this.industrialFilters[index].selected = true;
    }
    this.listingFeaturesForm.controls['tags'].setValue(this.industrialFilters);
  }

  formatHeight() {
    let height: any = this.listingFeaturesForm.value.ceilingHeight;
    height = this.listingPriceService.convertForNumber(height);
    if (height > 0) {
      height = this.listingPriceService.convertForDisplay(height);
      this.listingFeaturesForm.controls['ceilingHeight'].setValue(height);
    }
  }

  formatBaySize() {
    let baySize: any = this.listingFeaturesForm.value.baySize;
    baySize = this.listingPriceService.convertForNumber(baySize);
    if (baySize > 0) {
      baySize = this.listingPriceService.convertForDisplay(baySize);
      this.listingFeaturesForm.controls['baySize'].setValue(baySize);
    }
  }

  formatDockRatio() {
    let dockRatio: any = this.listingFeaturesForm.value.dockRatio;
    dockRatio = this.listingPriceService.convertForNumber(dockRatio);
    if (dockRatio > 0) {
      dockRatio = this.listingPriceService.convertForDisplay(dockRatio);
      this.listingFeaturesForm.controls['dockRatio'].setValue(dockRatio);
    }
  }

  checkCharacters(event: any, dec: boolean) {
    let num = event.detail.value;
    let regexp: any;
    if (num) {
      let lastChar: any = num.substr(num.length - 1);
      if (dec) {
        if (this.langService.locale === 'en-US') {
          regexp = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
        } else {
          regexp = /^-?(?:\d+|\d{1,3}(?:.\d{3})+)(?:(\.|,)\d+)?$/;
        }
      } else {
        regexp = /^[0-9]*$/;
      }

      let tempValue = num.substring(0, num.length - 1);
      if (
        (!regexp.test(num) && lastChar !== '.' && lastChar !== ',') ||
        num.substr(0) === '.' ||
        num.substr(0) === ',' ||
        (!dec && !regexp.test(num))
      ) {
        event.srcElement.value = tempValue;
      }

      // Validating maximum value for integer
      if (!dec && tempValue > 2147483647) {
        event.srcElement.value = 2147483647;
      }
    }
  }

  async setFormFeatures(type: any, building: any) {
    Object.keys(building).forEach(key => {
      if (type === 'industrial') {
        if (key === 'baySize') {
          this.listingFeaturesForm.controls['baySize'].setValue(
            this.langService.locale === 'en-US'
              ? building[key].toFixed(2)
              : building[key].toFixed(2).replace(/\./g, ',')
          );
        }
        if (key === 'dockRatio') {
          this.listingFeaturesForm.controls['dockRatio'].setValue(
            this.langService.locale === 'en-US'
              ? building[key].toFixed(2)
              : building[key].toFixed(2).replace(/\./g, ',')
          );
        }
        if (key === 'truckParkingSpaces' && typeof building[key] !== 'boolean') {
          this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(building[key].toFixed(2));
        }
        if (key === 'loadCapacity') {
          this.listingFeaturesForm.controls['loadCapacity'].setValue(building[key].toFixed(2));
        }
        if (key === 'ceilingHeight') {
          this.listingFeaturesForm.controls['ceilingHeight'].setValue(
            this.langService.locale === 'en-US'
              ? building[key].toFixed(2)
              : building[key].toFixed(2).replace(/\./g, ',')
          );
        }
        if (key === 'carParkingSpaces' && typeof building[key] !== 'boolean') {
          this.listingFeaturesForm.controls['carParkingSpaces'].setValue(building[key].toFixed(2));
        }
      } else if (type === 'office') {
        if (key === 'parkingSpaces' && typeof building[key] !== 'boolean') {
          this.listingFeaturesForm.controls['parkingSpaces'].setValue(building[key].toFixed(2));
        }
        /*if (key === 'restrooms') {
          this.listingFeaturesForm.controls['restrooms'].setValue(building[key]);
        }*/
        if (key === 'ceilingHeight') {
          this.listingFeaturesForm.controls['ceilingHeight'].setValue(
            this.langService.locale === 'en-US'
              ? building[key].toFixed(2)
              : building[key].toFixed(2).replace(/\./g, ',')
          );
        }
      }
    });
  }
}
