import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import * as MyListingModel from '@app/models/my-listing.model';
import { RegisterService } from '@app/core/register.service';
import { StatusHelperService } from '@app/core/helpers/status-helper.service';
import { ClassHelperService } from '@app/core/helpers/classes-helper.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'listing-filter-modal',
  templateUrl: './listing-filter-modal.component.html',
  styleUrls: ['./listing-filter-modal.component.scss']
})
export class ListingFilterModalComponent implements OnInit {
  public selectClass: Array<MyListingModel.MyListingClass>;
  public selectStatus: Array<MyListingModel.MyListingStatus>;
  public isMobile: boolean = false;

  @Input() screenWidth: number;
  @Input() listingCompanies: Array<MyListingModel.MyListingCompany>;
  @Input() isListingAdmin: boolean;
  @Input() isSpotAdmin: boolean;
  @Input() selectCompany: MyListingModel.MyListingCompany;
  @Input() selectAgent: MyListingModel.MyListingAgent;
  @Input() agents: any;
  @ViewChild('agentComponent', { static: false }) agentComponent: IonicSelectableComponent;

  constructor(
    public breakpointObserver: BreakpointObserver,
    public classHelperSvc: ClassHelperService,
    private popoverCtrl: PopoverController,
    private registerSvc: RegisterService,
    public statusHelperSvc: StatusHelperService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  public ngOnInit() {
    this.statusHelperSvc.getStatuses();
    this.initMyListingFilter();
  }

  private async getUserByCompany(companyId: number) {
    return this.registerSvc.getUsersByCompany(companyId).toPromise();
  }

  private initMyListingFilter() {
    this.selectStatus = this.statusHelperSvc.selectStatus;
    this.selectClass = this.classHelperSvc.selectClass;
  }

  public cancel() {
    this.popoverCtrl.dismiss();
  }

  public async changeCompany($event: any) {
    this.agents = await this.getUserByCompany($event.value.id);
    this.agentComponent.clear();
  }

  public changeStatus($event: any) {
    this.selectStatus = $event.value;
  }

  public changeClass($event: any) {
    this.selectClass = $event.value;
  }

  public updateMyListingFilter() {
    this.popoverCtrl.dismiss({
      agents: this.agents,
      selectAgent: this.selectAgent,
      selectCompany: this.selectCompany,
      selectStatus: this.selectStatus,
      selectClass: this.selectClass
    });
  }

  public setModalContainerWidth() {
    return this.isMobile ? 'filter-selection-mobile' : 'filter-selection';
  }

  public setFieldWidth() {
    return this.isMobile ? 'field-width-mobile' : '';
  }

  public setLabelColSize() {
    return this.isMobile ? 4 : 3;
  }

  public setFilterColSize() {
    return this.isMobile ? 8 : 9;
  }
}
