<ion-content>
  <div class="detail-block-background">
    <div style="max-width: 800px; background: white; margin: 0 auto; height: 100%;">
      <app-navigation-bar title="global.purchase-options.buyYour" [standardBackButton]="true"></app-navigation-bar>
      <ion-row style="padding-top: 0px;">
        <ion-col size="12">
          <h5 translate="global.purchase-options.youSelected"></h5>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <span class="listings-for">{{ selection?.numOfListings }} </span>
          <span translate="global.purchase-options.listingsfor"></span>
        </ion-col>
      </ion-row>

      <ion-row class="margin-even pointer" (click)="navigateToCart(false)">
        <ion-col class="selected-plan" size="12">
          R$ {{ selection?.listingPricePerMonth }}
          <span class="currency-each" translate="global.purchase-options.currencyEach"></span>
        </ion-col>
        <ion-col size="12" class="value-time">
          <span translate="global.purchase-options.valueTime"></span>
        </ion-col>
      </ion-row>

      <ion-row style="margin-top: 40px;">
        <ion-col size="12">
          <span class="premium-text" translate="global.purchase-options.becomePrem"></span>
        </ion-col>
      </ion-row>

      <ion-row class="margin-even-premium pointer" (click)="navigateToCart(true)">
        <ion-col class="selected-plan-text selected-plan" size="12">
          R$ {{ selection?.premiumPrice }}
          <span class="currency-each" translate="global.purchase-options.currencyEach"></span>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <span class="premium-text" translate="global.purchase-options.premInfo"></span>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6" class="text-left upgrade-btn">
          <ion-button (click)="goBack()" translate="global.purchase-options.back" color="secondary"> </ion-button>
        </ion-col>
        <ion-col size="6" class="text-right upgrade-btn">
          <ion-button (click)="navigateToCart(true)" translate="global.purchase-options.yesUpgrade" color="primary">
          </ion-button>
        </ion-col>
        <ion-col size="12" class="text-margin">
          <ion-button (click)="navigateToCart(false)" class="float-right neg-margin" color="dark" fill="clear">
            <span class="add-underline" translate="global.purchase-options.continue"></span>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>
