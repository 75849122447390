<ion-content>
  <app-breadcrumb inViewport [inViewportOptions]="{ thredhold: [0] }" [options]="breadcrumbOptions"> </app-breadcrumb>
  <div class="user-home centered" style="height: calc(100vh - 140px);" #homeContentBlock>
    <div style="max-width: 800px; background: white; margin: 0 auto;">
      <ion-grid class="padding-bottom zoom-60 flex-row">
        <ion-col size="6" class="display-col-flex">
          <ion-row>
            <ion-col class="flex-col">
              <img class="image-loaded" [src]="userAccountInfo?.profileImage" default="{{ imagePlaceholder }}" />
              <div class="company-info">
                <ion-text color="secondary">
                  <h6 class="name-title">
                    {{ userAccountInfo?.profile?.firstName }} {{ userAccountInfo?.profile?.lastName }}
                  </h6>
                </ion-text>

                <ion-text color="secondary">
                  <h6>{{ userAccountInfo?.profile?.company?.name }}</h6>
                </ion-text>

                <ion-text color="secondary">
                  <h6>{{ userAccountInfo?.profile?.jobTitle }}</h6>
                </ion-text>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>

        <ion-col size="6" class="display-col-flex">
          <ion-row>
            <ion-col>
              <ion-button class="user-home-btn" (click)="goToUserPage('user-home/my-account')">
                <ion-label class="btn-label" [translate]="'global.user.myInfo'"></ion-label>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-button class="user-home-btn" (click)="goToUserPage('user-home/my-messages')">
                <ion-label class="btn-label message-label" [translate]="'global.user.myMessages'"></ion-label>
                <ion-badge class="message-badge" *ngIf="getUnreadMessages() > 0">{{ getUnreadMessages() }}</ion-badge>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="spotAdmin">
            <ion-col>
              <ion-button class="user-home-btn" (click)="navigateToRegister()">
                <ion-label class="btn-label" [translate]="'global.my-list-menu.registerSPOTAccountPage'"></ion-label>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-button class="user-home-btn" (click)="openListingMenu()">
                <ion-label class="btn-label" [translate]="'global.user.reviewMyListings'"></ion-label>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="spotAdmin">
            <ion-col>
              <ion-button class="user-home-btn" (click)="navigateToPurchase()">
                <ion-label class="btn-label" [translate]="'global.my-list-menu.buyYourSpotAccountPage'"></ion-label>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-button class="user-home-btn" (click)="goToUserPage('user-home/my-payments')">
                <ion-label class="btn-label" [translate]="'global.user.reviewMyPayments'"></ion-label>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-button
                class="user-home-btn disable-text-transform"
                (click)="goToUserPage('user-home/listing-analytics')"
              >
                <ion-label class="btn-label" [translate]="'global.user.listingAnalytics'"></ion-label>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-grid>
    </div>
  </div>
</ion-content>
