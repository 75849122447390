<ion-content #contentBlock>
  <div class="header">
    <div class="bg-white">
      <div class="d-flex flex-column flex-md-row w-100 pt-2 pb-0 pb-md-2 align-items-center">
        <div
          class="d-flex flex-grow-1 filters-row align-items-center mb-2 mb-md-0 mr-0 mr-md-2 pl-3 pr-3 pr-md-0 w-100"
        >
          <!-- Keyword Search-->
          <div class="flex-grow-1 d-flex align-items-center filter">
            <ion-toolbar class="keyword-toolbar filter d-flex" style="border-radius: 4px; border: 1px solid #CED4DA;">
              <div class="keyword-search align-center">
                <div class="keyword-input">
                  <img
                    (click)="initSpotsNearmeSearch()"
                    style="width: 25px;height: 23px; cursor: pointer;"
                    src="assets/images/icons/location.png"
                    alt=""
                  />
                  <ion-input
                    class="filter"
                    type="text"
                    [(ngModel)]="searchModel.keywordText"
                    (keyup.enter)="searchSpotBuildingsChangeListener()"
                    placeholder="{{ 'global.menu.searchPlaceholder' | translate }}"
                    spellcheck="false"
                  >
                  </ion-input>
                  <button ion-button class="browse-button" (click)="searchSpotBuildingsChangeListener()">
                    <img style="width: 18px; margin: 1px 11px 0px 9px;" src="assets/images/icons/search.png" alt="" />
                  </button>
                </div>
              </div>
            </ion-toolbar>
          </div>

          <!-- Clear Search -->
          <div class="d-flex d-md-none align-items-center ml-2 px-4">
            <span (click)="clearSearchAction()" style="cursor: pointer;">
              <div class="location-label text-nowrap" style="color: #0D6EFD;">
                {{ 'ClearSearch' | translate }}
              </div>
            </span>
          </div>
        </div>

        <div class="divider d-block d-md-none"></div>

        <div class="d-flex filters-row align-items-center w-100 pr-3 pl-3 pl-md-0 my-3 my-md-0">
          <!-- Listing Type -->
          <div class="flex-grow-1 mr-2 filter filter--dropdown">
            <div ngbDropdown class="d-inline-block full-width h-100">
              <button
                class="form-control btn_type h-100"
                id="dropdownBasic1"
                ngbDropdownToggle
                style="text-align: left;"
              >
                <span *ngIf="searchModel.listingType == 'S'">{{
                  'global.list-your-property.forSale' | translate
                }}</span>
                <span *ngIf="searchModel.listingType == 'L' || searchModel.listingType == 'C'">{{
                  'global.list-your-property.forLease' | translate
                }}</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button class="form-control btn_type" (click)="searchByListingType('S')" ngbDropdownItem>
                  {{ 'global.list-your-property.forSale' | translate }}
                </button>
                <button
                  class="form-control btn_type"
                  style="color: #212529;font-weight: 400;"
                  (click)="searchByListingType('L')"
                  ngbDropdownItem
                >
                  {{ 'global.list-your-property.forLease' | translate }}
                </button>
              </div>
            </div>
          </div>

          <!-- Property Type -->
          <div class="flex-grow-1 mr-2 filter filter--dropdown">
            <div ngbDropdown class="d-inline-block full-width h-100">
              <button
                class="form-control btn_type h-100"
                id="dropdownBasic1"
                ngbDropdownToggle
                style="text-align: left;"
              >
                <span
                  *ngIf="
                    searchModel.propertyType && searchModel.propertyType == '1001' && searchModel.listingType != 'C'
                  "
                  >{{ 'global.industrial' | translate }}</span
                >
                <span
                  *ngIf="
                    searchModel.propertyType && searchModel.propertyType == '2001' && searchModel.listingType != 'C'
                  "
                  >{{ 'global.office' | translate }}</span
                >
                <span *ngIf="searchModel.propertyType && searchModel.listingType == 'C'">{{
                  'global.list-your-property.coworking' | translate
                }}</span>
                <span style="color: #8e8e8e8c;" *ngIf="!searchModel.propertyType">{{
                  'global.menu.entities.buildingType' | translate
                }}</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button class="form-control btn_type" (click)="searchByPropertyType(1001)" ngbDropdownItem>
                  {{ 'global.industrial' | translate }}
                </button>
                <button class="form-control btn_type" (click)="searchByPropertyType(2001)" ngbDropdownItem>
                  {{ 'global.office' | translate }}
                </button>
                <button
                  *ngIf="searchModel.listingType == 'L' || searchModel.listingType == 'C'"
                  class="form-control btn_type"
                  (click)="searchByPropertyType(2001, 'C')"
                  ngbDropdownItem
                >
                  {{ 'global.list-your-property.coworking' | translate }}
                </button>
              </div>
            </div>
          </div>

          <!-- More Filters -->
          <div class="mr-2 filter">
            <ion-button
              class="listing-type-select filter filter--more-filters m-0"
              (click)="openMorePopover($event)"
              color="light"
              size="large"
              id="filter"
            >
              <div class="d-flex align-items-center px-0 px-md-1">
                <span class="location-label d-none d-md-block">
                  {{ 'global.search.moreFilters' | translate }}
                </span>
                <img class="ml-0 ml-md-2" style="height: 18px;" src="assets/images/icons/moreFilter.png" alt="" />
              </div>
            </ion-button>
          </div>

          <!-- Sort -->
          <div class="filter">
            <div class="btn-group full-width" ngbDropdown role="group" aria-label="Button group with nested dropdown">
              <ion-button
                style="border-radius: 4px"
                class="btn-white sort-btn full-width noIconSort filter m-0 filter--sort"
                size="large"
                color="light"
                ngbDropdownToggle
              >
                <div class="location-label d-flex align-items-center px-0 px-md-1">
                  <span class="d-none d-md-block">{{ 'global.search.sort' | translate }}</span>
                  <img style="width: 18px;" class="m-0 m-md-2" src="assets/images/icons/sort.png" alt="" />
                </div>
              </ion-button>
              <div class="dropdown-menu sort-group widthSor" ngbDropdownMenu>
                <button
                  ngbDropdownItem
                  [ngClass]="{ 'active-sort-item': isActiveSort('price', 'ASC') }"
                  class="sort-item"
                  (click)="applySortBy('price', 'ASC')"
                >
                  {{ 'global.search.lowest' | translate }}
                </button>
                <button
                  ngbDropdownItem
                  class="sort-item"
                  [ngClass]="{ 'active-sort-item': isActiveSort('price', 'DESC') }"
                  (click)="applySortBy('price', 'DESC')"
                >
                  {{ 'global.search.highest' | translate }}
                </button>
                <button
                  ngbDropdownItem
                  class="sort-item"
                  [ngClass]="{ 'active-sort-item': isActiveSort('date', 'DESC') }"
                  (click)="applySortBy('date', 'DESC')"
                >
                  {{ 'global.search.newest' | translate }}
                </button>
                <button
                  ngbDropdownItem
                  class="sort-item"
                  [ngClass]="{ 'active-sort-item': isActiveSort('date', 'ASC') }"
                  (click)="applySortBy('date', 'ASC')"
                >
                  {{ 'global.search.oldest' | translate }}
                </button>
                <div class="dropdown-divider"></div>
                <button class="clear-selection" (click)="clearSortSelection()" ngbDropdownItem>
                  {{ 'global.search.clearSelection' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="divider d-block d-md-none"></div>

        <!-- Clear Search -->
        <div class="d-none d-md-block mx-2">
          <span (click)="clearSearchAction()" style="cursor: pointer;">
            <div class="location-label text-nowrap" style="color: #0D6EFD;">
              {{ 'ClearSearch' | translate }}
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="input-container tags-section d-none d-md-block" style="border-top: 1px solid #b5b5b6;">
      <ion-row class="full-width">
        <ion-header>
          <ion-toolbar class="align-center webkit-center" style="--background: #F8F9FA;">
            <ion-row class="search-tags" *ngIf="!searching">
              <div class="align__tags" *ngFor="let searchTag of getAllCurrentTags(); let i = index">
                <ion-chip class="search-tag" *ngIf="searchTag">
                  <ion-text
                    *ngIf="
                      !searchTag.max &&
                      searchTag.fieldName != 'yearBuilt' &&
                      searchTag.fieldName != 'yearRenovated' &&
                      searchTag.fieldName != 'spotTitlesOrAddresses'
                    "
                    style="font-size: 1em;"
                    >{{ searchTag.name | translate }}</ion-text
                  >
                  <ion-text *ngIf="searchTag.fieldName == 'spotTitlesOrAddresses'">
                    {{ 'buildinsDetail.properties' | translate }}{{ searchTag.name }}
                  </ion-text>

                  <ion-text *ngIf="searchTag.min || searchTag.max" style="font-size: 1em;">
                    {{ searchTag.name | translate }} :
                    {{
                      searchTag.fieldName == 'yearBuilt'
                        ? searchTag.min
                        : searchTag.fieldName == 'yearRenovated'
                        ? searchTag.min
                        : (searchTag.min
                          | localNumber: (!checkTagNames(searchTag) || searchTag.min === 0 ? '1.0-0' : '1.2-2'))
                    }}
                    -
                    {{
                      searchTag.fieldName == 'yearBuilt'
                        ? searchTag.max
                        : searchTag.fieldName == 'yearRenovated'
                        ? searchTag.max
                        : (searchTag.max | localNumber: (!checkTagNames(searchTag) ? '1.0-0' : '1.2-2'))
                    }}
                    <span *ngIf="searchTag.showPlus">+</span>
                  </ion-text>
                  <ion-icon
                    *ngIf="
                      searchTag.fieldName != 'propertyType' &&
                      searchTag.fieldName != 'officeType' &&
                      searchTag.fieldName != 'lease' &&
                      searchTag.fieldName != 'sale' &&
                      searchTag.fieldName != 'coworking'
                    "
                    name="close"
                    (click)="removeTag(searchTag.fieldName, i)"
                    style="color: black;"
                  >
                  </ion-icon>
                </ion-chip>
              </div>
              <ion-button
                class="clearSearch"
                *ngIf="getAllCurrentTags().length > 0"
                fill="outline"
                (click)="clearSearchAction()"
              >
                <span>{{ 'global.search.clearSearch' | translate }}</span>
              </ion-button>

              <div class="save-load-searches align__tags">
                <ion-button (click)="saveSearchAction($event)" color="light" class="filter-button filters-row">
                  <div class="search-icon d-flex d-md-block justify-content-center location-label">
                    <span translate="SaveSearch"></span>
                  </div>
                </ion-button>

                <ion-button (click)="loadSearchAction($event)" color="light" class="filter-button  filters-row">
                  <div class="search-icon d-flex d-md-block justify-content-center location-label">
                    <span translate="LoadSearch"></span>
                  </div>
                </ion-button>
              </div>
            </ion-row>
            <!-- Spinner Loading Animation -->
            <div
              style="display: flex; flex-direction: row; justify-content: center;"
              class="tags-section"
              *ngIf="searching"
            >
              <ion-spinner id="mainSpinner" name="dots" color="primary"></ion-spinner>
            </div>
          </ion-toolbar>
        </ion-header>
      </ion-row>
    </div>
  </div>

  <div class="search-container d-relative d-md-flex">
    <!--Body Map and Cards-->
    <div class="map-and-images">
      <div
        class="map d-md-inline-flex"
        [ngClass]="{
          'w-100': showPreviewSpotOnMobile(),
          'd-none': currentView === views.list,
          'd-flex flex-column': currentView === views.map && !!selectedMarkerBuilding
        }"
      >
        <app-duplomap
          class="w-100"
          [ngClass]="{ 'duplo-map-mobile-height': showPreviewSpotOnMobile(), 'h-100': !showPreviewSpotOnMobile() }"
          *ngIf="!hasGeoLocationError"
          [options]="mapOptions"
          [markers]="spotMarkers"
          [clientPosition]="clientPosition"
          [isSpotNearMe]="isSpotNearMeSearch"
          (geopositionEmitter)="searchByGeoposition($event)"
        >
        </app-duplomap>
        <div class="d-md-none preview-card-height" *ngIf="selectedMarkerBuilding && currentView === this.views.map">
          <app-spot-map-mobile-card [spot]="selectedMarkerBuilding"> </app-spot-map-mobile-card>
        </div>
      </div>

      <div
        class="image-bar float-none float-md-right d-md-block"
        *ngIf="showResults()"
        [ngClass]="{ 'd-none': currentView === views.map }"
      >
        <div id="featuredListings" class="full-width" *ngIf="isThereFeaturedSpots()">
          <div class="flex listing-type-section">
            <span class="listing-type-title"> {{ 'global.search.topProperties' | translate }} </span>
            <span class="listing-type-count">({{ topPropertiesCount }} {{ 'global.search.found' | translate }})</span>
          </div>
          <div class="cards-container">
            <div class="mobile-width" *ngFor="let listing of featuredSpotBuildings">
              <app-spot-featured-card
                [activeListingIds]="allListingIds"
                [listing]="listing"
                [searchCriteria]="spotBuildingSearchRequest"
                [checkBoxValues]="checkBoxValues"
                [searchTags]="getAllCurrentTags()"
                [lazyLoadImages]="true"
              >
              </app-spot-featured-card>
            </div>
          </div>
        </div>

        <div
          id="regularListings"
          [ngClass]="{ 'listing-type-section-separator': isThereFeaturedSpots() }"
          class="full-width"
          *ngIf="isThereRegularSpots()"
        >
          <div class="flex listing-type-section">
            <span class="listing-type-title"> {{ 'global.search.otherProperties' | translate }}</span>
            <span class="listing-type-count">({{ otherPropertiesCount }} {{ 'global.search.found' | translate }})</span>
          </div>

          <div class="cards-container">
            <div class="mobile-width" *ngFor="let listing of regularSpotBuildings">
              <app-spot-building-card
                [activeListingIds]="allListingIds"
                [listing]="listing"
                [searchCriteria]="spotBuildingSearchRequest"
                [checkBoxValues]="checkBoxValues"
                [searchTags]="getAllCurrentTags()"
                [lazyLoadImages]="true"
                [defaultCoworkingType]=""
              >
              </app-spot-building-card>
            </div>
          </div>
        </div>

        <div class="full-width listing-type-section-separator mb-5 pb-5 mb-md-0 pb-md-0">
          <div class="pagination-header">
            <label>{{ getPaginationText() }} </label>
          </div>
          <pagination
            [boundaryLinks]="totalSpotBuildingsCount > maxSizePag * pageSize"
            responsive="true"
            #spotBuildingPagination
            class="full-width pagination-control"
            [itemsPerPage]="pageSize"
            [maxSize]="maxSizePag"
            nextText=">"
            [align]="true"
            previousText="<"
            [firstText]="firstLbl"
            [lastText]="lastLbl"
            (pageChanged)="pageChanged($event)"
            [totalItems]="totalSpotBuildingsCount"
            [(ngModel)]="currentPage"
          >
          </pagination>
        </div>
      </div>

      <div class="image-bar float-none float-md-right d-md-block" *ngIf="showNoResultMessage()">
        <div class="no-results-section">
          <div class="no-results-title"><label translate="spotBuilding.noResultsTitle"></label></div>
          <div class="no-results-txt"><label translate="spotBuilding.noResultsTxt"></label></div>
        </div>
      </div>
    </div>

    <div style="min-height: 400px;" *ngIf="!showResults() && !showNoResultMessage()"></div>

    <div
      class="h-70 d-block d-md-none w-100 d-flex justify-content-center align-items-center p-3 bg-light fixed-bottom"
    >
      <ion-button (click)="saveSearchAction($event)" color="light" class="filter-button flex-grow-1 height-48">
        <div class="search-icon d-flex d-md-block justify-content-center location-label">
          <span translate="SaveSearch"></span>
        </div>
      </ion-button>
      <ion-button (click)="switchView()" color="light" class="filter-button flex-grow-1 height-48">
        <div class="search-icon d-flex d-md-block justify-content-center location-label">
          <fa-icon *ngIf="currentView === views.list" class="icon mr-2" [icon]="faMap" size="md"></fa-icon>
          <fa-icon *ngIf="currentView === views.map" class="icon mr-2" [icon]="faList" size="md"></fa-icon>
          <span>{{ (currentView === views.map ? views.list : views.map) | translate }}</span>
        </div>
      </ion-button>
    </div>

    <app-scroll-to-top
      [showComponent]="displayScrollToTop"
      (scroll)="contentBlock.scrollToTop()"
      [target]="contentBlock"
      scroller
    >
    </app-scroll-to-top>

    <app-footer inViewport (inViewportAction)="isFooterVisible($event)" class="d-none d-md-block"></app-footer>
  </div>
</ion-content>
