<ion-row class="location-header">
  <ion-col size="12">
    <ion-label [translate]="'global.list-your-property.searchLocation'"></ion-label>
  </ion-col>
</ion-row>
<ion-grid [ngClass]="{ 'location-container': isOtherState() }" style="height: 84% !important;">
  <ion-row *ngIf="isMobile && !onlyStateCity">
    <ion-col size="12">
      <div class="spot-nearby-container" (click)="onSpotNearMeChange()">
        <ion-checkbox class="spot-near-me" [checked]="spotNearMe.selected"> </ion-checkbox>
        <div class="spot-label">
          <span>SP<fa-icon class="img-spot" [icon]="faMapMarkerAlt" size="md"></fa-icon>T</span>s
          {{ 'global.list-property.listPropertyNearby' | translate }}
        </div>
      </div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!isMobile">
    <ion-col size="12">
      <div class="spot-nearby-container" (click)="onSpotNearMeChange()">
        <ion-checkbox class="spot-near-me" [checked]="spotNearMe.selected"> </ion-checkbox>
        <div class="spot-label">
          <span>SP<fa-icon class="img-spot" [icon]="faMapMarkerAlt" size="md"></fa-icon>T</span>s
          {{ 'global.list-property.listPropertyNearby' | translate }}
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="defaultCityStateOptions.length">
    <ion-segment
      *ngIf="isMobile && !onlyStateCity"
      class="state-flex-container"
      mode="ios"
      color="secondary"
      (ionChange)="selectCityState($event)"
      (click)="SelectCityState()"
    >
      <ion-segment-button
        class="state-flex-col-other"
        *ngFor="let cityState of defaultCityStateOptions"
        [value]="cityState"
        [checked]="cityState.id === selectedCityState?.id"
      >
        <ion-label localize [textValue]="cityState.name"></ion-label>
      </ion-segment-button>
    </ion-segment>
    <ion-segment
      *ngIf="!isMobile"
      class="state-flex-container"
      mode="ios"
      color="secondary"
      (ionChange)="selectCityState($event)"
      (click)="SelectCityState()"
    >
      <ion-segment-button
        class="state-flex-col-other"
        *ngFor="let cityState of defaultCityStateOptions"
        [value]="cityState"
        [checked]="cityState.id === selectedCityState?.id"
      >
        <ion-label localize [textValue]="cityState.name"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-row>

  <ion-row class="autocomplete">
    <ion-col>
      <ion-searchbar
        placeholder="{{ 'global.list-your-property.state' | translate }}"
        type="text"
        [(ngModel)]="inputState"
        (ionChange)="getFuzzyOptionsState()"
        debounce="500"
        showCancelButton="never"
        spellcheck="false"
        #searchbar
        style="padding-top: 0%;"
        autocomplete="new-text"
      >
      </ion-searchbar>

      <ion-list class="ion-city-list" *ngIf="stateOptions && stateOptions.length">
        <ion-item
          *ngFor="let state of stateOptions; let index = index"
          (click)="selectState(state)"
          localize
          [textValue]="state.name"
          inset="true"
          lines="full"
        >
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>

  <ion-row class="autocomplete">
    <ion-col style="padding-top: 0%;">
      <ion-searchbar
        placeholder="{{ 'global.list-your-property.city' | translate }}"
        type="text"
        [(ngModel)]="inputCity"
        (ionChange)="getFuzzyOptions()"
        debounce="500"
        showCancelButton="never"
        spellcheck="false"
        #searchbar
        style="padding-top: 0%;"
        autocomplete="new-text"
      >
      </ion-searchbar>

      <ion-list class="ion-city-list" *ngIf="cityOptions && cityOptions.length">
        <ion-item
          *ngFor="let cityOption of cityOptions; let index = index"
          [class.preselected-city]="index === preSelectedCityIndex"
          (click)="selectCity(cityOption)"
          localize
          [textValue]="cityOption.name"
          inset="true"
          lines="full"
        >
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-row class="ion-location-footer" style="background-color:#043650">
  <ion-col size="4" style="justify-content: center">
    <ion-button color="secondary" color="light" fill="clear" (click)="clearSearch()">{{
      'Clear' | translate
    }}</ion-button>
  </ion-col>
  <ion-col size="8">
    <ion-button class="float-right" color="light" fill="clear" (click)="dismiss(false)">{{
      'Update' | translate
    }}</ion-button>
    <ion-button class="float-right" color="light" fill="clear" (click)="dismiss(true)">{{
      'Cancel' | translate
    }}</ion-button>
  </ion-col>
</ion-row>
