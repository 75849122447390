<form [formGroup]="addressForm" class="full-width" name="addressForm" autocomplete="off">
  <!-- <ion-row class="flex-row full-width heading-title">
    <p class="center" translate="global.list-your-property.propertyLocated"></p>
  </ion-row> -->
  <!-- <ion-label class="full-width"
    >{{ 'global.list-your-property.address' | translate }}
    <span *ngIf="detailForm.value.listed">*</span>
  </ion-label> -->
  <ion-row class="full-width margin-top-5 margin-bottom-10" *ngIf="addressOptions">
    <!-- <ion-input class="input-border" id="address" name="address" type="address" min="6" formControlName="address">
    </ion-input> -->
    <!-- <ion-textarea
      class="input-border left-input-padding"
      id="address"
      name="address"
      formControlName="address"
      rows="2"
    ></ion-textarea> -->

    <multi-language-elem
      style="width: 100%"
      (onValueChange)="addressChange($event)"
      [formArrayValue]="address"
      #title
      [options]="addressOptions"
    >
    </multi-language-elem>
  </ion-row>

  <ion-row class="full-width margin-top-5 margin-bottom-10">
    <ion-label class="full-width"
      >{{ 'global.list-your-property.state' | translate }}
      <span *ngIf="detailForm.value.listed">*</span>
    </ion-label>
    <ion-row class="full-width listing-type-select">
      <ion-select
        color="secondary"
        formControlName="state"
        class="address-select"
        (ngModelChange)="setCity()"
        okText="Okay"
        cancelText="Dismiss"
      >
        <ion-select-option
          color="secondary"
          *ngFor="let state of states"
          [value]="state.id"
          localize
          [textValue]="state.name"
        >
        </ion-select-option>
      </ion-select>
    </ion-row>
  </ion-row>

  <ion-row class="full-width margin-top-5 margin-bottom-10">
    <ion-label class="full-width"
      >{{ 'global.list-your-property.city' | translate }}
      <span *ngIf="detailForm.value.listed">*</span>
    </ion-label>
    <ion-row class="full-width listing-type-select">
      <ion-select color="secondary" formControlName="city" class="address-select" okText="Okay" cancelText="Dismiss">
        <ion-select-option
          color="secondary"
          *ngFor="let city of cities"
          [value]="city.id"
          localize
          [textValue]="city.name"
        >
        </ion-select-option>
      </ion-select>
    </ion-row>
  </ion-row>

  <ion-row class="full-width margin-top-5 margin-bottom-10">
    <ion-label class="full-width">{{ 'global.list-your-property.zip' | translate }} </ion-label>
    <ion-row class="full-width listing-type-select">
      <ion-input class="input-border left-input-padding" id="zip" name="zip" type="text" min="6" formControlName="zip">
      </ion-input>
    </ion-row>
  </ion-row>
</form>
