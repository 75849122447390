import { Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { extract } from '@app/core';
import { UserHomeComponent } from './user-home.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyMessageComponent } from './my-message/my-message.component';
import { ReviewMyListingComponent } from './my-listing/my-listing.component';
import { ReviewMyPaymentComponent } from './my-payment/my-payment.component';
import { ListingAnalyticsComponent } from './listing-analytics/listing-analytics.component';
import { AuthGuard } from '@app/guards/auth.guard';
import { CreateListingComponent } from './my-listing/create-listing';
import { CurrentPlansComponent } from './my-listing/current-plans/current-plans.component';
import { CanDeactivateGuard } from '@app/guards/can-deactivate.guard';
const ɵ0 = {
    title: extract('User Home')
}, ɵ1 = {
    title: extract('User Account')
}, ɵ2 = {
    title: extract('User Account')
}, ɵ3 = {
    title: extract('My Messages')
}, ɵ4 = {
    title: extract('My Messages')
}, ɵ5 = {
    title: extract('Review My Listings')
}, ɵ6 = {
    title: extract('Review My Listings')
}, ɵ7 = {
    title: extract('Review My Payments')
}, ɵ8 = {
    title: extract('Review My Payments')
}, ɵ9 = {
    title: extract('Listing Analytics')
}, ɵ10 = {
    title: extract('Listing Analytics')
}, ɵ11 = {
    title: extract('Current Plans')
}, ɵ12 = {
    title: extract('Current Plans')
}, ɵ13 = { title: extract('Create Property') }, ɵ14 = { title: extract('Create Property') }, ɵ15 = { title: extract('Create Property') }, ɵ16 = { title: extract('Create Property') };
const routes = [
    Shell.childRoutesRunningGuardsResolversAlways([
        { path: '', redirectTo: '/', pathMatch: 'full' },
        {
            path: 'user-home',
            component: UserHomeComponent,
            canActivate: [AuthGuard],
            data: ɵ0
        },
        {
            path: 'user-home/my-account',
            component: MyAccountComponent,
            canDeactivate: [CanDeactivateGuard],
            data: ɵ1
        },
        {
            path: 'user-home/my-account/:language',
            component: MyAccountComponent,
            canDeactivate: [CanDeactivateGuard],
            data: ɵ2
        },
        {
            path: 'user-home/my-messages',
            component: MyMessageComponent,
            canActivate: [AuthGuard],
            data: ɵ3
        },
        {
            path: 'user-home/my-messages/:language',
            component: MyMessageComponent,
            canActivate: [AuthGuard],
            data: ɵ4
        },
        {
            path: 'user-home/my-listings',
            component: ReviewMyListingComponent,
            canActivate: [AuthGuard],
            data: ɵ5,
            runGuardsAndResolvers: 'always'
        },
        {
            path: 'user-home/my-listings/:language',
            component: ReviewMyListingComponent,
            canActivate: [AuthGuard],
            data: ɵ6,
            runGuardsAndResolvers: 'always'
        },
        {
            path: 'user-home/my-payments',
            component: ReviewMyPaymentComponent,
            canActivate: [AuthGuard],
            data: ɵ7
        },
        {
            path: 'user-home/my-payments/:language',
            component: ReviewMyPaymentComponent,
            canActivate: [AuthGuard],
            data: ɵ8
        },
        {
            path: 'user-home/listing-analytics',
            component: ListingAnalyticsComponent,
            canActivate: [AuthGuard],
            data: ɵ9,
            runGuardsAndResolvers: 'always'
        },
        {
            path: 'user-home/listing-analytics/:language',
            component: ListingAnalyticsComponent,
            canActivate: [AuthGuard],
            data: ɵ10,
            runGuardsAndResolvers: 'always'
        },
        {
            path: 'user-home/current-plans',
            component: CurrentPlansComponent,
            canActivate: [AuthGuard],
            data: ɵ11
        },
        {
            path: 'user-home/current-plans/:language',
            component: CurrentPlansComponent,
            canActivate: [AuthGuard],
            data: ɵ12
        },
        {
            path: 'user-home/my-listings/create-property',
            component: CreateListingComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
            runGuardsAndResolvers: 'always',
            data: ɵ13
        },
        {
            path: 'user-home/my-listings/create-property/:language',
            component: CreateListingComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
            runGuardsAndResolvers: 'always',
            data: ɵ14
        },
        {
            path: 'user-home/my-listings/create-property/:listingId',
            component: CreateListingComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
            runGuardsAndResolvers: 'always',
            data: ɵ15
        },
        {
            path: 'user-home/my-listings/create-property/:listingId/:language',
            component: CreateListingComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
            runGuardsAndResolvers: 'always',
            data: ɵ16
        }
    ])
];
export class UserHomeRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
