export enum SizeIcon {
  MD,
  LG
}

export enum SpotSetType {
  INDUSTRIAL_LEASE = 'INDUSTRIAL_LEASE',
  INDUSTRIAL_SALE = 'INDUSTRIAL_SALE',
  OFFICE_LEASE = 'OFFICE_LEASE',
  OFFICE_SALE = 'OFFICE_SALE',
  COWORKING = 'COWORKING'
}
