import { Component, OnInit, OnDestroy, ViewChild, Input, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { ListingDetailService } from '../../../core/listings/listing-detail.service';
import { ImageService } from '../../../core/image.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DetailPosition } from '@app/models';
import { DetailPositionService } from '@app/search-navigation/services/detail-position.service';
import { BreadcrumbOptions } from '@app/shared';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { IonContent, IonSlides, PopoverController, ToastController, AlertController } from '@ionic/angular';
import { faShareAltSquare } from '@fortawesome/free-solid-svg-icons';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { PlatformHelperService, PropertyTypeHelperService, PurchaseCreditsService, I18nService } from '@app/core';
import { faStar, faAward, faBuilding, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { CommonGenericService } from '@app/core/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EventCheckerService } from '@app/core/listings';
import { UserActivity, UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { MetaService } from '@app/core/seo';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core/auth';
import { MyListingMenuComponent } from '@app/shared/my-listing-menu/my-listing-menu.component';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { take } from 'rxjs/operators';
import { LoginFormDialogService } from '@app/shared/login';
import { MatDialog } from '@angular/material/dialog';
import { AgentNotificationComponent } from '@app/search-navigation/components/agent-notification/agent-notification.component';
import { WhatsAppService } from '@app/core/whatsapp.service';
import { MyListingEditComponent } from '@app/shared/my-listing-edit/my-listing-edit.component';
import { Location } from '@angular/common';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { SelectedFilterKey } from '../listing-search/filter-selected-keys';
import { SearchTagsService } from '@app/core/search-tags.service';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { GenericMapService } from '@app/core/generic-map.service';
import { state } from '@angular/animations';
import { Subscription } from 'rxjs';
import { CoworkingService } from '@app/search-navigation/services/coworking.service';
import { ListingOfferType } from '@app/models/transaction-type.model';
import { SubscribeModalComponent } from '@app/shared/subscribe/subscribe-modal.component';

@Component({
  selector: 'app-listing-detail',
  templateUrl: './listing-detail.component.html',
  styleUrls: ['./listing-detail.component.scss']
})
export class ListingDetailComponent implements OnInit, OnDestroy {
  @Input('previewListing') previewListing?: boolean;
  @Input('createdListing') createdListing?: any;
  @Input('imgList') imgList?: any;

  listingId: number;
  listing: any;

  drawerOptions: any;
  listingPhotos: any;
  isMobile: boolean = false;
  showDetails: boolean = true;
  detailModal: any;
  currentPosition: any;
  currentPhotoIndex: any = 0;
  faMapMarkerAlt: any;
  breadcrumbNavOptions: BreadcrumbOptions = {
    usePlatformLocation: false
  };
  options = {
    singleMarker: true,
    ignoreZoom: false,
    zoom: 15
  };
  toolbarText = {
    next: 'global.menu.next',
    prev: 'global.menu.previous',
    nextListing: 'global.menu.nextListing',
    prevListing: 'global.menu.previousListing',
    search: 'global.menu.search',
    returnToSearch: 'global.menu.returnToSearch'
  };

  isLoggedIn$: any;

  placeholderImage: string = 'assets/placeholder-image.png';
  loading: boolean = true;
  backbuttonSubscription: any;
  marker: any;
  @ViewChild(IonContent, { static: true }) content: IonContent;
  @ViewChild('photoSlider', { static: false }) photoSlider: IonSlides;
  hasPointer: boolean;
  faShareAltSquare: any;
  isDesktop: any;
  listingFaIcon: any;
  propertyFaIcon: any;
  listingSubtypes: string;
  displayScrollToTop: boolean = true;
  propertyType: any;
  isAndroid: boolean = false;
  spotAdmin: boolean;
  screenWidth: any;
  slideOpts = {
    initialSlide: 0,
    slidesPerView: 3
  };
  slideOptsTwo = {
    initialSlide: 0,
    slidesPerView: 2
  };
  slideOptsMobile = {
    initialSlide: 0,
    slidesPerView: 1
  };
  contactPhone: boolean = false;
  contactEmail: boolean = false;
  whatsappPhone: boolean = false;
  dialogRef: any;
  lang: any;
  user: any;
  listingAdmin: boolean;
  myListingPage: boolean;
  myListingAnalytics: boolean;
  searchTags: any;
  searchDTO: any;
  checkBoxValues: any;
  coworkingPositionsSeachParam: any;
  googleMapSize: string = '400x300';
  googleMapSizeOnePhoto: string = '640x300';
  openUrl: boolean = false;
  faHeart: any;
  isLoggedIn: boolean;
  favoritesLoginText: string;
  showMoreLoginText: string;
  loginSubscription: Subscription;
  updateSubscription: Subscription;
  localStorageKey: SelectedFilterKey;
  sharedPrivatePositionsText: string = '';
  defaultCoworkingType: string = '';
  showCoworkingPrivate: boolean = false;
  currentDevice: string;
  isDesktopM: string;
  isMobilePhone: string;
  isTablet: string;
  countSubscribe: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private listingService: ListingDetailService,
    private breakpointObserver: BreakpointObserver,
    private imgService: ImageService,
    private detailPositionService: DetailPositionService,
    private ngNavigatorShareService: NgNavigatorShareService,
    private platformHelperService: PlatformHelperService,
    private commonService: CommonGenericService,
    private propertyHelperService: PropertyTypeHelperService,
    private deviceDetectorService: DeviceDetectorService,
    private purchaseService: PurchaseCreditsService,
    private _eventChecker: EventCheckerService,
    private userActivityService: UserActivityService,
    private metaService: MetaService,
    private i18nService: I18nService,
    private auth: AuthenticationService,
    private loginFormDialog: LoginFormDialogService,
    private dialog: MatDialog,
    private agentNotPopOverCtrl: PopoverController,
    private whatsAppService: WhatsAppService,
    private toastCtrl: ToastController,
    private _location: Location,
    private listingSearchService: ListingSearchService,
    private analyticsService: AnalyticsService,
    private commomMapSvc: GenericMapService,
    private coworkingService: CoworkingService,
    private alertCtrl: AlertController
  ) {
    this.isLoggedIn$ = this.auth.$isLoginSubject;
    this.hasPointer = window.matchMedia('(pointer: fine)').matches;
    this.faShareAltSquare = faShareAltSquare;
    this.faMapMarkerAlt = faMapMarkerAlt;
    this.ngNavigatorShareService = ngNavigatorShareService;
    this.isDesktop = this.platformHelperService.isDesktopMode();
    this.screenWidth = window.innerWidth;
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.getParams();
  }

  getParams() {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras) {
      if (navigation.extras.state) {
        this.localStorageKey = navigation.extras.state.localStorageKey;
      }
      if (navigation.extras.state && navigation.extras.state.openUrl) {
        this.openUrl = true;
      }
      if (
        navigation.extras.state &&
        navigation.extras.state.searchCriteria &&
        navigation.extras.state.searchCriteria.coworkingPositions
      ) {
        this.coworkingPositionsSeachParam = navigation.extras.state.searchCriteria.coworkingPositions;
      }
      this.searchTags =
        navigation.extras.state && navigation.extras.state.searchTags ? navigation.extras.state.searchTags : null;
      this.searchDTO =
        navigation.extras.state && navigation.extras.state.searchCriteria
          ? navigation.extras.state.searchCriteria
          : null;
      this.checkBoxValues =
        navigation.extras.state && navigation.extras.state.checkBoxValues
          ? navigation.extras.state.checkBoxValues
          : null;
      this.myListingAnalytics =
        navigation.extras.state && navigation.extras.state.myListingAnalytics
          ? navigation.extras.state.myListingAnalytics
          : null;
      this.myListingPage =
        navigation.extras.state && navigation.extras.state.myListingPage ? navigation.extras.state.myListingPage : null;
      if (this.myListingAnalytics) {
        this.listingSearchService.saveLastSeenSearchPage(SelectedFilterKey.LISTING_ANALYTICS);
      } else if (this.myListingPage) {
        this.listingSearchService.saveLastSeenSearchPage(SelectedFilterKey.MY_LISTINGS);
      } else if (
        navigation.extras.state &&
        navigation.extras.state.localStorageKey === SelectedFilterKey.FAVORITES_SEARCH
      ) {
        this.listingSearchService.saveLastSeenSearchPage(SelectedFilterKey.FAVORITES_SEARCH);
      } else {
        this.listingSearchService.saveLastSeenSearchPage(SelectedFilterKey.REGULAR_SEARCH);
      }
    }
  }

  ngOnInit(): void {
    this.isDesktopM = this.deviceDetectorService.isDesktop() ? 'desktop' : '';
    this.isMobilePhone = this.deviceDetectorService.isMobile() ? 'mobile' : '';
    this.isTablet = this.deviceDetectorService.isTablet() ? 'tablet' : '';
    if (this.isDesktopM.length) {
      this.currentDevice = this.isDesktopM;
    } else if (this.isMobilePhone.length) {
      this.currentDevice = this.isMobilePhone;
    } else if (this.isTablet.length) {
      this.currentDevice = this.isTablet;
    }
    this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
    this.loading = true;
    this.subscribeToRouteParams();
    this.detectDevice();
    this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
    this.user = JSON.parse(localStorage.getItem('user'));
    this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
    this.faHeart = faHeart;
    this.favoritesLoginText = this.i18nService.get('global.form.favoriteSignIn');
    this.showMoreLoginText = this.i18nService.get('global.form.defaultSignInMessage');
    this.updateListingNow();
    this.defaultCoworkingType = this.listingSearchService.findCoworkingTypeFromSearch();
  }

  isFavoriteListing() {
    return this.listing ? this.listing.favorite : false;
  }

  setFavoriteLisitng() {
    if (!this.isLoggedIn) {
      this.openLoginDialog(this.favoritesLoginText)
        .afterClosed()
        .subscribe(() => {
          if (this.isLoggedIn) {
            this.toggleFavorite();
          }
        });
    } else {
      this.toggleFavorite();
    }
  }

  toggleFavorite() {
    this.listingService.toggleFavoriteListing(this.listing, this.listing.favorite || false).subscribe(() => {
      const updatedListing: object = { ...this.listing, favorite: !this.listing.favorite };
      this._eventChecker.updateListing$.next(updatedListing);
      this.listing = updatedListing;
    });
  }

  openLoginDialog(text: string | Array<string>) {
    const currentRoute: string = this.router.url;
    return this.loginFormDialog.openDialog({
      url: currentRoute,
      customText: text
    });
  }

  openShowMoreLogInModal(id: number) {
    const subscribeDialog = this.dialog.open(SubscribeModalComponent, {
      width: '400px',
      panelClass: 'my-custom-dialog-class',
      data: id
    });

    subscribeDialog.afterClosed().subscribe(val => {
      if (val != undefined) {
        switch (val) {
          case 1:
            this.showBrokerWhatsapp(val);
            break;
          case 2:
            this.navigateToBroker(this.listing.offeredByUser.id, val);
            break;
          case 3:
            this.showContactEmail(val);
            break;
          case 4:
            this.showContactPhone(val);
            break;
          default:
            return null;
        }
      }
    });
  }

  getPropSubTypeText() {
    let propSubTypeTxt = this.i18nService.get(this.propertyType);
    if (this.listing) {
      if (
        this.listing.isForLeaseSale === ListingOfferType.Lease ||
        this.listing.isForLeaseSale === ListingOfferType.Sale ||
        this.listing.isForLeaseSale === ListingOfferType.Coworking
      ) {
        const translationKey =
          this.listing.isForLeaseSale === ListingOfferType.Lease
            ? 'global.list-your-property.forLease'
            : this.listing.isForLeaseSale === ListingOfferType.Sale
            ? 'global.list-your-property.forSale'
            : this.listing.isForLeaseSale === ListingOfferType.Coworking
            ? 'global.list-your-property.forCoworking'
            : '';
        propSubTypeTxt = propSubTypeTxt.concat(' ' + this.i18nService.get(translationKey));
        if (this.listing.propertySubTypes) {
          propSubTypeTxt = propSubTypeTxt.concat(this.addColon(this.listing.propertySubTypes));
          if (this.listingSubtypes) {
            propSubTypeTxt = propSubTypeTxt.concat(' ' + this.listingSubtypes);
          }
        }
      }
    }
    return propSubTypeTxt;
  }

  getBuildingTitle() {
    if (this.listing && this.listing.building) {
      return this.i18nService.getTranslation(this.listing.propertyName);
    }
    return '';
  }

  calculateCoworkingValuesByNavigation() {
    if (this.coworkingPositionsSeachParam) {
      this.listing.coworkingPositions = this.coworkingPositionsSeachParam;
      if (this.listing.coworkingDailyPrice) {
        this.listing.coworkingDailyPrice = this.listing.coworkingDailyPrice * this.listing.coworkingPositions;
      }
      if (this.listing.coworkingMonthlyPrice) {
        this.listing.coworkingMonthlyPrice = this.listing.coworkingMonthlyPrice * this.listing.coworkingPositions;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  private detectDevice() {
    const os: string = this.deviceDetectorService.os;
    this.isAndroid = os.toLowerCase() === 'android';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  async openEditListingMenu(event: any, data?: any) {
    event.stopPropagation();
    data = await this.listingService.getRevisionOrActiveVersionBasedOnUserRole(data);
    this.dialogRef = this.dialog.open(MyListingEditComponent, {
      height: 'auto',
      width: '550px',
      data: data ? { data: data, myListingPage: false } : null
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  private async getNonPreviewListing() {
    this.activatedRoute.params.subscribe(async paramMap => {
      try {
        // Handling the angular routes for detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId
        let baseId = paramMap.baseId;
        this.listingId = paramMap.listingId;
        if (isNaN(baseId)) {
          baseId = this.listingId;
          this.listingId = undefined;
        }

        // if URL only includes base_id, so return 404 when the baseId is not an active listing
        // OR find the most recent active listing associated with the baseId and then navigating to it.
        let newDetailsURL = '';
        const lang = this.i18nService.getCurrentLanguage();
        this.listingId = this.listingId ? this.listingId : -1;
        const result: any = await this.listingService
          .getListingDetailsSummarized(baseId, this.listingId, lang)
          .toPromise();
        if (result) {
          if (result.mostRecentActiveListing && result.newDetailsURL) {
            newDetailsURL = result.newDetailsURL;
            this.listingId = result.mostRecentActiveListing;
            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
              newDetailsURL = this.i18nService.addCurrentLanguageToPath(newDetailsURL);
            }
            const currentURL = window.location.href;
            if (currentURL && !currentURL.endsWith(newDetailsURL)) {
              this._location.go(newDetailsURL);
            }
          } else {
            this.navigateToNotFoundPage();
            return;
          }
          const isOffice = this.commonService.isPropertyType(2001, result.listing);
          this.listing = result.listing;
          this.calculateCoworkingValuesByNavigation();
          this.setSharedPrivatePositionsText();
          this.listing.premiumAgents = result.premiumAgents;
          this.listingFaIcon = this.listing && this.listing.listingType.toLowerCase() === 'featured' ? faStar : faAward;
          this.propertyFaIcon = isOffice ? faBuilding : faWarehouse;
          this.propertyType = this.propertyHelperService.getPropertyTypeTranslationName(
            result.listing.building.buildingType.id
          );
          if (this.listing.propertySubTypes && this.listing.propertySubTypes.length) {
            this.listing.propertySubTypes = this.commonService.translateLocaleText(
              this.listing.propertySubTypes,
              'name'
            );
            this.listingSubtypes = this.commonService.transformArrayStringList(this.listing.propertySubTypes, 'name');
          }
          this.marker = { lat: this.listing.building.latitude, lng: this.listing.building.longitude };
          this.listingPhotos = await this.listingService.getListingPhotos(this.listingId).toPromise();
          const detailsMetaTags = this.buildTitleMetatag(this.listing);
          const fullTitleText = (await detailsMetaTags).metaTagTitle;
          const keywordsMetaTag = (await detailsMetaTags).keywordsMetaTag;
          const descText = this.removeHTMLCode(this.i18nService.getTranslation(this.listing.comments));
          const locale = this.i18nService.getCurrentLocale();
          const listingTitle = this.i18nService.getTranslation(this.listing.title);
          const fullDetailsURL = `${environment.spotServerUrl}${newDetailsURL}`;

          const tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            {
              name: 'mobile-web-app-title',
              content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },

            { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [listingTitle] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
            {
              name: 'og:image',
              property: 'og:image',
              content:
                this.listingPhotos && this.listingPhotos.length
                  ? encodeURI(
                      `${environment.spotServerUrl}${this.getMediumImg(
                        this.getCroppedOrOriginalImage(this.listingPhotos[0])
                      )}`
                    )
                  : null
            },
            {
              name: 'image',
              content:
                this.listingPhotos && this.listingPhotos.length
                  ? encodeURI(
                      `${environment.spotServerUrl}${this.getMediumImg(
                        this.getCroppedOrOriginalImage(this.listingPhotos[0])
                      )}`
                    )
                  : null
            },
            { name: 'twitter:card', content: `summary_large_image` },
            { name: 'twitter:title', content: [listingTitle] },
            {
              name: 'twitter:image',
              content:
                this.listingPhotos && this.listingPhotos.length
                  ? encodeURI(
                      `${environment.spotServerUrl}${this.getMediumImg(
                        this.getCroppedOrOriginalImage(this.listingPhotos[0])
                      )}`
                    )
                  : null
            },
            { name: 'twitter:description', content: descText }
          ];
          this.metaService.setDynamicTags(tags, fullTitleText);
          const activeIds = this.getPositionData();
          this.currentDetailPosition(activeIds);
          if (this.listingPhotos && this.listingPhotos.length > 0 && this.listingPhotos.length <= 2) {
            const latitude = this.listing.building.latitude;
            const longitude = this.listing.building.longitude;
            const mapImgForOneListing = this.listingPhotos.length == 1 ? true : false;
            let customSize = '';
            if (this.isMobile && this.screenWidth) {
              const heightAspectRatio = Math.ceil((this.screenWidth * 3) / 4);
              customSize = `${this.screenWidth}x${heightAspectRatio}`;
            } else {
              customSize = mapImgForOneListing ? this.googleMapSizeOnePhoto : this.googleMapSize;
            }

            const scale = mapImgForOneListing ? 1 : 1;
            const staticMapImageUrl = this.commomMapSvc.generateStaticSatelliteMap(
              latitude,
              longitude,
              customSize,
              scale
            );
            const googleMapImage = {
              google: true,
              customSize: customSize,
              url: staticMapImageUrl,
              mapImgForOneListing: mapImgForOneListing
            };
            this.listingPhotos.push(googleMapImage);
          }

          this.loading = false;
        } else {
          this.navigateToNotFoundPage();
          return;
        }
      } catch (e) {
        if (e) {
          this.navigateToNotFoundPage();
          setTimeout(() => {
            this.metaService.setNonFoundTags();
          }, 300);
        }
      }
    });
  }

  private navigateToNotFoundPage() {
    const state: any = {
      badURL: window.location.href
    };
    this.router.navigateByUrl('/not-found', { state: state });
  }

  /**
   *  Use the following pattern:
      <listing type> | <property type> | <neighborhood> | <building name> | SiiLA SPOT

      full example = "Lease | Corporate Floor, Corporate Office Space | Faria Lima | Aqwa Corporate - Fase 1 | SiiLA SPOT"
   */
  private async buildTitleMetatag(listing: any) {
    let metaTagTitle = '';

    // Listing Type
    const listingTypeKey =
      listing.isForLeaseSale === ListingOfferType.Lease
        ? 'global.list-your-property.lease'
        : listing.isForLeaseSale === ListingOfferType.Sale
        ? 'global.list-your-property.sale'
        : listing.isForLeaseSale === ListingOfferType.Coworking
        ? 'global.list-your-property.coworking'
        : '';
    const listingTypeTag = this.commonService.capitalizeFirstLetter(this.i18nService.get(listingTypeKey).toLowerCase());

    // Property Type
    let propertyTypeTag = '';
    const propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.building.buildingType.id);
    propertyTypeTag = this.i18nService.get(propertyTypeKey);

    let propertySubTypeTags = null;
    let propertySubTypesWithComma = null;
    if (listing.propertySubTypes && listing.propertySubTypes.length > 0) {
      propertySubTypeTags = listing.propertySubTypes.map((sub: any) => sub.name).join(' | ');
      propertySubTypesWithComma = listing.propertySubTypes.map((sub: any) => sub.name).join();
    }

    const propertyTypeTitleTag = propertySubTypeTags ? propertySubTypeTags : propertyTypeTag;

    //neighborhood
    const neighborhood = listing.neighborhood;

    // Building Name
    const buildingName = this.i18nService.getTranslation(listing.propertyName);

    metaTagTitle = `${buildingName} | ${listingTypeTag} | ${propertyTypeTitleTag} | ${neighborhood} | SiiLA SPOT`;

    // KeyWord Metatag
    let keywordsMetaTag = '';
    let companyName = '';
    if (listing.offeredByUser) {
      // Broker Company Name
      const brokerId = listing.offeredByUser.id;
      companyName = await this.getUserInfo(brokerId, this.listingId).then((broker: any) => {
        companyName = broker.companyName;
        return companyName;
      });
    }
    const keywordsMetaTags = [];
    keywordsMetaTags.push(buildingName);
    keywordsMetaTags.push(listingTypeTag);
    keywordsMetaTags.push(propertyTypeTag);
    if (propertySubTypesWithComma) {
      keywordsMetaTags.push(propertySubTypesWithComma);
    }
    keywordsMetaTags.push(neighborhood);
    keywordsMetaTags.push(companyName);

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag };
  }

  removeHTMLCode(htmlText: string) {
    var div = document.createElement('div');
    div.innerHTML = htmlText;
    var text = div.textContent || div.innerText || '';
    return text;
  }

  getGoogleMapImageOrCroppedOrOriginalImage(photo: any) {
    const imgOrUrl = photo && photo.image ? this.getMediumImg(this.getCroppedOrOriginalImage(photo)) : photo.url;
    return imgOrUrl;
  }

  getSlideHeightClass(photo: any) {
    let className = 'slide-height';
    if (photo.google && photo.customSize) {
      className = photo.mapImgForOneListing ? 'one-photo-static-map' : 'slide-height';
    }
    return className;
  }

  getCroppedOrOriginalImage(listingPhoto: any) {
    if (listingPhoto) {
      if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
        return listingPhoto.croppedImage.id;
      }
      return listingPhoto.image.id;
    }
  }

  openListYourSpotDialogue() {
    this.isLoggedIn$.pipe(take(1)).subscribe((val: any) => {
      if (val) {
        this.openListingMenu();
      } else {
        this.loginFormDialog.openDialog('my-listing');
      }
    });
  }

  private openListingMenu() {
    this.dialogRef = this.dialog.open(MyListingMenuComponent, {
      height: 'auto',
      width: '550px'
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  async updateListingNow() {
    this.updateSubscription = this._eventChecker.updateListing$.subscribe((listing: any) => {
      if (listing === 'listing-detail') {
        this.getNonPreviewListing();
      }
    });
  }

  desktopShare() {
    let copyText: any = document.getElementById('shareLink');
    copyText.value = window.location.href;
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    alert('Copied the text: ' + copyText.value);
  }

  private async getPreviewListing() {
    try {
      const list: any = await this.listingService.getListingById(this.createdListing.id).toPromise();
      this.listingService
        .getListingDetails(this.createdListing.id)
        .toPromise()
        .then((result: any) => {
          this.listing = result.listing;
          this.calculateCoworkingValuesByNavigation();
          this.setSharedPrivatePositionsText();
          this.listing.premiumAgents = result.premiumAgents;
          const isOffice = this.commonService.isPropertyType(2001, result.listing);
          this.listingFaIcon = this.listing && this.listing.listingType.toLowerCase() === 'featured' ? faStar : faAward;
          this.propertyFaIcon = isOffice ? faBuilding : faWarehouse;
          this.propertyType = this.propertyHelperService.getPropertyTypeTranslationName(
            result.listing.building.buildingType.id
          );
          if (this.listing.propertySubTypes && this.listing.propertySubTypes.length) {
            this.listing.propertySubTypes = this.commonService.translateLocaleText(
              this.listing.propertySubTypes,
              'name'
            );
            this.listingSubtypes = this.commonService.transformArrayStringList(this.listing.propertySubTypes, 'name');
          }
        });
      this.marker = { lat: list.building.latitude, lng: list.building.longitude };
      this.listingPhotos = this.sortListingPhotosByOrderId(this.imgList);
      this.loading = false;
    } catch (error) {
      console.error('Error loading preview listing page: ', error);
      this.router.navigateByUrl('/');
    }
  }

  sortListingPhotosByOrderId(listingPhotos: any) {
    if (listingPhotos == null) {
      return null;
    }
    return (<Array<any>>listingPhotos).sort((photo1, photo2) => (photo1.orderId > photo2.orderId ? 1 : -1));
  }

  checkRole(role: string) {
    try {
      let user = JSON.parse(localStorage.getItem('user'));
      return user && user.roles ? user.roles.includes(role) : false;
    } catch (e) {
      console.error('roles undefined --> ', e);
    }
  }

  hideImageDots(index: any) {
    if (
      this.screenWidth > 700 &&
      (index === this.listingPhotos.length - 1 || index === this.listingPhotos.length - 2)
    ) {
      return true;
    }
    if (this.screenWidth < 700 && this.screenWidth > 500 && index === this.listingPhotos.length - 1) {
      return true;
    }
  }

  checkArrows() {
    if (this.screenWidth > 700 && this.listingPhotos.length <= 3) {
      return false;
    } else if (this.screenWidth > 500 && this.screenWidth < 700 && this.listingPhotos.length <= 2) {
      return false;
    } else {
      return true;
    }
  }

  getMeasurementValue() {
    if (this.listing.measurementStandard) {
      return this.i18nService.getTranslation(this.listing.measurementStandard.measurement);
    } else {
      return this.i18nService.get('global.coworking.availableSpace');
      //return 'Available Space!';
    }
  }

  checkScreenWidth() {
    if (this.screenWidth > 700) {
      return this.slideOpts;
    } else if (this.screenWidth < 500) {
      return this.slideOptsMobile;
    } else if (this.screenWidth > 500 && this.screenWidth < 700) {
      return this.slideOptsTwo;
    }
  }

  showBrokerWhatsapp(notSubscribe: any = null) {
    if (!this.isLoggedIn) {
      if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
        this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
        this.countSubscribe = this.countSubscribe + 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      } else {
        this.countSubscribe = 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      }
    }

    if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
      if (!localStorage.getItem('emailSubscribed')) {
        this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
        this.openShowMoreLogInModal(1);
        return;
      }
    }
    this.whatsappPhone = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
  }

  navigateToBroker(id: any, notSubscribe: any = null) {
    if (!this.isLoggedIn) {
      if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
        this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
        this.countSubscribe = this.countSubscribe + 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      } else {
        this.countSubscribe = 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      }
    }

    if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
      if (!localStorage.getItem('emailSubscribed')) {
        this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
        this.openShowMoreLogInModal(2);
        return;
      }
    }
    this.registerUserActivity(UserActivityType.BROKER_SHOWDETAIL);
    this.router.navigateByUrl(`/listing-user/${id}`, { state: { listingId: this.listing.id } });
  }

  showContactEmail(notSubscribe: any = null) {
    if (!this.isLoggedIn) {
      if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
        this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
        this.countSubscribe = this.countSubscribe + 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      } else {
        this.countSubscribe = 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      }
    }
    if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
      if (!localStorage.getItem('emailSubscribed')) {
        this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
        this.openShowMoreLogInModal(3);
        return;
      }
    }
    this.contactEmail = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
  }

  showContactPhone(notSubscribe: any = null) {
    if (!this.isLoggedIn) {
      if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
        this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
        this.countSubscribe = this.countSubscribe + 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      } else {
        this.countSubscribe = 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      }
    }

    if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
      if (!localStorage.getItem('emailSubscribed')) {
        this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
        this.openShowMoreLogInModal(4);
        return;
      }
    }
    this.contactPhone = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWPHONE);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
  }

  registerUserActivity(userActivityType: UserActivityType) {
    if (this.listing.offeredByUser) {
      const userActivity: UserActivity = {
        module: userActivityType,
        brokerId: this.listing.offeredByUser.id,
        listingId: this.listing.id
      };
      this.userActivityService.saveUserActivity(userActivity);
      // 'send_to': 'AW-689462276/eHv9CJeNrrcBEIS44cgC',
      //         'event_callback': callback
      // }')
    }
  }

  async rejectListing() {
    let transitionTo = 'draft';
    //  if the admin rejects the modifications the modified listing should go to Revision status and the original listing should go to Active/Revision.
    //  priorListingId should be related to the active one.
    if (this.listing.listingStatus.id === 1 && this.listing.priorListingId) {
      transitionTo = 'revision';
    }
    let action = this.purchaseService.applyAction(this.listing.id, transitionTo);
    if (action) {
      if (this.currentPosition && this.currentPosition.hasNext) {
        this.navigateToNext();
      } else {
        this.router.navigateByUrl('user-home/my-listings');
        this._eventChecker.updateListing$.next('refresh');
      }
    }
  }

  async approveListing() {
    try {
      let action = await this.purchaseService.approveListing(this.listing.id);
      if (action) {
        if (this.currentPosition && this.currentPosition.hasNext) {
          this.navigateToNext();
          this._eventChecker.updateListing$.next(action);
        } else {
          this.router.navigateByUrl('user-home/my-listings');
          this._eventChecker.updateListing$.next('refresh');
        }
      }
    } catch (error) {
      const message = this.i18nService.get('global.approvingErrorMessage');
      this.showMessage(null, message, false);
    }
  }

  async showMessage(header: string, message: string, keepUrl?: boolean) {
    const okText = this.i18nService.get('global.OK');
    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [okText]
    });
    alert.onDidDismiss().then(() => {});
    await alert.present();
  }

  async subscribeToRouteParams() {
    if (this.previewListing) {
      this.getPreviewListing();
    } else {
      this.getNonPreviewListing();
    }
  }

  getI18nValue(textValue: string) {
    try {
      if (textValue && JSON.parse(textValue)) {
        return this.i18nService.getTranslation(textValue);
      } else {
        return '';
      }
    } catch (e) {
      console.error('failed to parse locale string -> listings search');
    }
  }

  async getUrlUpdate() {
    let lang = this.i18nService.getCurrentLanguage();
    let res: any = await this.listingService.checkListingDetailRoute(this.listingId, lang).toPromise();
    let detailsURL: any;
    if (res) {
      detailsURL = res.detailsURL;
      if (this.i18nService.currentUrlContainsCurrentLanguage()) {
        detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
      }
    }
    return detailsURL;
  }

  async shareLink() {
    this.userActivityService.trackListingShare(this.listing.id);
    let res: any = await this.getUrlUpdate();
    this.ngNavigatorShareService
      .share({
        title: this.getI18nValue(this.listing.title),
        text: this.i18nService.get('global.listing-card.shareLinkText'),
        url: environment.spotServerUrl + res.concat(this.i18nService.getLanguagePathURL())
      })
      .catch(async error => {
        if (error) {
          let result: any = this.copyTextToClipboard(
            environment.spotServerUrl + res.concat(this.i18nService.getLanguagePathURL())
          );
          if (result) {
            const toast = await this.toastCtrl.create({
              message: this.i18nService.get('global.listing-card.desktopShareText'),
              duration: 3500,
              animated: true,
              keyboardClose: true,
              cssClass: 'toast-alert'
            });
            toast.onDidDismiss().then(() => {});
            return await toast.present();
          }
        }
      });
  }

  async copyTextToClipboard(text: any) {
    var txtArea = document.createElement('textarea');
    txtArea.id = 'txt';
    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = text;
    document.body.appendChild(txtArea);
    txtArea.select();
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      if (successful) {
        return true;
      }
    } catch (err) {
    } finally {
      document.body.removeChild(txtArea);
    }
    return false;
  }

  getLargeThumbnail(id: number) {
    return this.imgService.largeThumbnail(id);
  }

  getMediumImg(id: number) {
    return this.imgService.mediumThumbnail(id);
  }

  getMediumThumbnail(id: number, dimensions: string) {
    return this.imgService.mediumThumbnail(id, dimensions);
  }

  navigateToNext() {
    if (this.currentPosition && this.currentPosition.hasNext) {
      const nextArticle = this.currentPosition.listReference[this.currentPosition.currentIndex + 1];
      this.redirectTo(nextArticle);
    }
  }

  navigateToPrev() {
    if (this.currentPosition && this.currentPosition.hasPrevious && this.currentPosition.currentIndex) {
      const previousArticle = this.currentPosition.listReference[this.currentPosition.currentIndex - 1];
      this.redirectTo(previousArticle);
    }
  }

  currentDetailPosition(list: any) {
    if (list) {
      const detailIndex = list.findIndex((id: number) => {
        return id == this.listingId;
      });

      const currentPosition: DetailPosition = {
        hasPrevious: detailIndex > 0,
        currentIndex: detailIndex,
        hasNext: detailIndex < list.length - 1,
        listReference: list
      };

      this.currentPosition = currentPosition;
    }
  }

  getPositionData() {
    return this.detailPositionService.getDetailPosition;
  }

  navigateToSearch() {
    this.router.navigateByUrl(this.findSearchURLPage());
  }

  routeToSearchPage() {
    const searchPage = this.findSearchURLPage();
    let searchPageURL = searchPage.split('/');
    if (this.i18nService.currentUrlContainsCurrentLanguage()) {
      searchPageURL = this.i18nService.addCurrentLanguageToPath(searchPage).split('/');
    }
    if (!searchPageURL[0].startsWith('/')) {
      searchPageURL[0] = '/'.concat(searchPageURL[0]);
    }
    return searchPageURL;
  }

  findSearchURLPage() {
    const lastVisitedPage = this.listingSearchService.readLastSearchPageFromStorage();
    if (this.myListingPage) {
      return 'user-home/my-listings';
    } else if (this.myListingAnalytics) {
      return 'user-home/listing-analytics';
    } else if (lastVisitedPage === SelectedFilterKey.FAVORITES_SEARCH) {
      return '/search/favorites';
    } else {
      return '/search';
    }
  }

  getSearchLabel() {
    let state: any = this._location.getState();
    let searchDto = this.listingSearchService.readSearchDTOFromStorage(SelectedFilterKey.REGULAR_SEARCH, null);
    if (searchDto != null) {
      return 'global.menu.returnToSearch';
    } else {
      return 'global.menu.newSearch';
    }
  }

  async redirectTo(uri: string, navi?: boolean) {
    const state: any = {
      myListingAnalytics: this.myListingAnalytics,
      myListingPage: this.myListingPage,
      searchTags: this.searchTags,
      searchCriteria: this.searchDTO,
      checkBoxValues: this.checkBoxValues,
      localStorageKey: this.localStorageKey
    };

    let lang = this.i18nService.getCurrentLanguage();
    let res: any = await this.listingService.checkListingDetailRoute(uri, lang).toPromise();
    let detailsURL: any;
    if (res) {
      detailsURL = res.detailsURL;
      if (this.i18nService.currentUrlContainsCurrentLanguage()) {
        detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
      }
    }
    this.router.navigateByUrl(detailsURL, { state: state });
  }

  updatePhotoIndex() {
    this.photoSlider.getActiveIndex().then(index => (this.currentPhotoIndex = index));
  }

  getPreviousPhoto() {
    if (this.currentPhotoIndex === 0) {
      this.photoSlider.slideTo(this.listingPhotos.length - 1);
    } else {
      this.photoSlider.slidePrev();
    }
    this.updatePhotoIndex();
  }

  getNextPhoto() {
    if (this.screenWidth > 700) {
      if (this.currentPhotoIndex === this.listingPhotos.length - 3) {
        this.photoSlider.slideTo(0);
      } else {
        this.photoSlider.slideNext();
      }
    } else if (this.screenWidth > 500 && this.screenWidth < 700) {
      if (this.currentPhotoIndex === this.listingPhotos.length - 2) {
        this.photoSlider.slideTo(0);
      } else {
        this.photoSlider.slideNext();
      }
    } else {
      if (this.currentPhotoIndex === this.listingPhotos.length - 1) {
        this.photoSlider.slideTo(0);
      } else {
        this.photoSlider.slideNext();
      }
    }
    this.updatePhotoIndex();
  }

  addColon(propertySubTypes: any) {
    return propertySubTypes && propertySubTypes.length ? ':' : '';
  }

  isHeaderVisible(event: any) {
    this.displayScrollToTop = event.visible ? false : true;
  }

  scrollToTop(elem: any) {
    elem.scrollIntoView();
  }

  getDefaultTranslation(translationArtifact: any) {
    try {
      const artifact = JSON.parse(translationArtifact);
      if (window.location.href.endsWith('/pt-br') || !window.location.href.endsWith('/en')) {
        return artifact['pt-br'] ? artifact['pt-br'] : artifact['en'];
      } else {
        return artifact['en'] ? artifact['en'] : artifact['pt-br'];
      }
    } catch (e) {
      const error = `${e} : ${translationArtifact}`;
      console.error(error);
    }
  }

  private setSharedPrivatePositionsText() {
    this.showCoworkingPrivate = this.defaultCoworkingType && this.defaultCoworkingType == 'private';
    this.sharedPrivatePositionsText = this.coworkingService.getSharedPrivatePositionsText(
      this.listing,
      this.showCoworkingPrivate
    );
  }

  async clickToChat() {
    const fullListingDetailsURL = encodeURI(this.buildUrl());
    const headerKey = 'global.listing-detail.clickToChat.whatsAppMsgHeader';
    const headerMsg = this.i18nService.get(headerKey);
    const msgToUserAgent = this.i18nService.get('global.listing-detail.clickToChat.msgToUserAgent');

    const message = `${headerMsg} ${fullListingDetailsURL}

${msgToUserAgent}`;
    this.whatsAppService.sendMessageViaWhataspp(this.listing.offeredByUser.whatsappNumber, message);

    return false;
  }

  private buildUrl() {
    return window.location.href.concat(this.i18nService.getLanguagePathURL());
  }

  async clickToEmail(ev: any) {
    const defaultMsg = this.i18nService.get('global.listing-detail.clickToEmail.msgToUserAgent');
    const message = `${defaultMsg}`;

    const popover = await this.agentNotPopOverCtrl.create({
      component: AgentNotificationComponent,
      event: ev,
      cssClass: 'popover-agent-email',
      componentProps: {
        defaultMsg: message,
        brokerId: this.listing.offeredByUser.id,
        listingId: this.listing.id,
        popupCtrl: this.agentNotPopOverCtrl,
        pageURLFrom: encodeURI(this.buildUrl())
      }
    });
    return await popover.present();
  }

  private getParentElem(elems: any) {
    let elem: any = null;
    for (let i = 0; i < elems.length; i++) {
      if (elems[i].scrollTop > 0) {
        elem = elems[i];
      }
    }
    return elem;
  }

  private isEndOfPage(element: any) {
    return Math.ceil(element.scrollHeight - element.scrollTop) === element.clientHeight;
  }

  private animateScroll(parentElement: any, target: number, durationPerClick: number) {
    const isAtTarget: boolean = parentElement.scrollTop === target || this.isEndOfPage(parentElement);
    if (isAtTarget) {
      return;
    }

    setTimeout(() => {
      const isWithinTarget: boolean = parentElement.scrollTop + durationPerClick <= target;
      parentElement.scrollTop = isWithinTarget ? parentElement.scrollTop + durationPerClick : target;
      this.animateScroll(parentElement, target, durationPerClick);
    }, durationPerClick);
  }

  public scrollToView() {
    setTimeout(() => {
      const parentElement: any = document.getElementsByTagName('MAT-SIDENAV-CONTENT');
      const targetElement: any = document.getElementById('listingCardView');
      const deltaElem: any = this.getParentElem(parentElement);
      const offset: number = window.outerWidth <= 500 ? 80 : 120;
      const targetPosition: number =
        Math.round(targetElement.getBoundingClientRect().top) + Math.round(deltaElem.scrollTop) - offset;
      this.animateScroll(deltaElem, targetPosition, 10);
    }, 500);
  }

  removedTagsFromNearBy(searchFilter: any) {
    this.searchTags = searchFilter.searchTags;
    this.searchDTO = searchFilter.nearbySearchCriteria.searchDTO;
    this.checkBoxValues = searchFilter.nearbySearchCriteria.checkBoxValues;
  }

  async getUserInfo(brokerId: any, listingId: any) {
    return await this.listingService
      .getUserById(brokerId, listingId)
      .toPromise()
      .then(broker => {
        return broker;
      });
  }

  public isRentCondoIPTUNegotiables() {
    return (
      (!this.listing.monthlyRent || this.listing.monthlyRent == 0) &&
      (!this.listing.monthlyCondoFee || this.listing.monthlyCondoFee == 0) &&
      (!this.listing.monthlyIptu || this.listing.monthlyIptu == 0)
    );
  }

  hasCoworkingSubType(listing: { id: number; isForLeaseSale: string }) {
    return listing.isForLeaseSale === ListingOfferType.Coworking;
  }

  getListingArea() {
    if (
      this.listing &&
      this.listing.isForLeaseSale === 'L' &&
      this.listing &&
      this.listing.monthlyRent &&
      this.listing &&
      this.listing.monthlyRent != 0 &&
      !this.listing.coworkingMaxPositions &&
      this.listing.listingType.toLowerCase() != 'basic'
    ) {
      return this.listing.leaseableArea;
    } else if (
      this.listing &&
      this.listing.isForLeaseSale === 'S' &&
      this.listing &&
      this.listing.offeringPrice &&
      this.listing &&
      this.listing.offeringPrice != 0 &&
      !this.listing.coworkingMaxPositions &&
      this.listing.listingType.toLowerCase() != 'basic'
    ) {
      return this.listing.rentableArea;
    }
  }
}
