<div class="listing-modal">
  <div class="description-content">
    <div>
      <div>
        <ion-row
          (click)="openEditListingMenu($event, listing)"
          style="cursor: pointer;"
          *ngIf="
            !previewListing &&
            (spotAdmin ||
              (listing?.offeredByUser?.id === user?.id && listing?.listingType.toLowerCase() !== 'basic') ||
              (listingAdmin &&
                user?.company?.id === listing?.offeredByUser?.company?.id &&
                listing?.listingType.toLowerCase() !== 'basic'))
          "
        >
          <ion-col size="12">
            <p class="my-listing-text">
              <ion-icon name="create"></ion-icon> {{ 'global.listing-card.modify' | translate }}
            </p>
          </ion-col>
        </ion-row>

        <!-- Claim Your SPOT - BASIC Listing-->
        <ion-row *ngIf="false && listing.listingType.toLowerCase() === 'basic' && screenWidth < 1200">
          <ion-col size="12" class="default-contact-left">
            <p translate="global.listing-detail.basic.claimYourSpotHeader"></p>
          </ion-col>
          <ion-col class="center detail-info">
            <a (click)="openListYourSpotDialogue()" class="point">
              {{ 'global.listing-detail.basic.claimYour' | translate }}
              <span> SP<fa-icon class="img-spot" [icon]="faMapMarkerAlt" size="md"></fa-icon>T </span>
            </a>
          </ion-col>
        </ion-row>

        <!-- Pricing options-->
        <ion-row class="row-border-bottom margin-top-20" *ngIf="showPricingOptionsSection()">
          <p class="contact-title margin-top-unset">{{ 'global.listingPricing.pricingOptions' | translate }}</p>
        </ion-row>

        <ion-row class="row-display border-bottom-unset" style="width: 400px;" *ngIf="showPricingOptionsSection()">
          <ion-grid>
            <ion-row style="padding-top: 15px;">
              <ion-col size="5">
                <ion-label
                  style="font-size: 15px; color: #424242; text-transform: capitalize;"
                  class="coworking-weight"
                >
                  {{ 'global.coworking.positionsNeeded' | translate }}
                </ion-label>
              </ion-col>
              <ion-col size="2">
                <ion-input
                  #posInput
                  type="number"
                  style="border: 1px solid; padding: 0px; padding-left: 5px !important; height: 30px; color: black; font-size: 14px;"
                  [(ngModel)]="coworkingPositions"
                  autofocus="true"
                  (ionChange)="coworkingPositionValueChanged($event)"
                  [ngClass]="{ 'coworking-color-red': coworkingPositions > listing.coworkingMaxPositions }"
                >
                </ion-input>
              </ion-col>
              <ion-col size="4" style="font-size: 15px;">
                {{ 'global.max' | translate }} ({{ listing.coworkingMaxPositions | localNumber: '1.0-0' }})
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" *ngIf="priceSharedOption && pricePrivateOption">
                <ion-label
                  style="font-size: 15px; color: #424242; text-transform: capitalize;"
                  class="coworking-weight"
                >
                  {{ 'global.coworking.coworkingType' | translate }}
                </ion-label>
              </ion-col>
              <ion-col size="12" *ngIf="!priceSharedOption || !pricePrivateOption"> </ion-col>
              <ion-col size="5" *ngIf="!priceSharedOption || !pricePrivateOption">
                <ion-label
                  style="font-size: 15px; color: #424242; text-transform: capitalize;"
                  class="coworking-weight"
                >
                  {{ 'global.coworking.coworkingType' | translate }}:
                </ion-label>
              </ion-col>
              <ion-col size="7" *ngIf="!priceSharedOption || !pricePrivateOption">
                <ion-label
                  style="font-size: 15px; color: #424242; text-transform: capitalize;"
                  class="coworking-weight"
                >
                  {{
                    priceSharedOption
                      ? ('global.coworking.sharedSpace' | translate)
                      : ('global.coworking.privateSpace' | translate)
                  }}
                </ion-label>
              </ion-col>
              <ion-col size="12" *ngIf="priceSharedOption && pricePrivateOption">
                <ion-list>
                  <ion-radio-group [(ngModel)]="coworkingType" class="top-search-btn" style="font-size: 1.3em;">
                    <ion-item *ngIf="priceSharedOption" class="width-200">
                      <ion-label style="font-size: 15px; color: #424242;" class="coworking-weight">{{
                        'global.coworking.sharedSpace' | translate
                      }}</ion-label>
                      <ion-radio slot="end" value="shared" selected (ionSelect)="coworkingTypeChanged($event)">
                      </ion-radio>
                    </ion-item>

                    <ion-item *ngIf="pricePrivateOption" class="width-200">
                      <ion-label style="font-size: 15px; color: #424242;" class="coworking-weight">{{
                        'global.coworking.privateSpace' | translate
                      }}</ion-label>
                      <ion-radio slot="end" value="private" (ionSelect)="coworkingTypeChanged($event)"></ion-radio>
                    </ion-item>
                  </ion-radio-group>
                </ion-list>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="coworkingDailyPrice || coworkingMonthlyPrice">
              <ion-col size="12" *ngIf="coworkingDailyPrice">
                <ion-label style="font-size: 16px; color: black;"
                  ><b
                    >{{ 'global.coworking.dailyFrom' | translate }}: R$
                    {{ coworkingDailyPrice | localNumber: '1.0-0' }}</b
                  ></ion-label
                >
              </ion-col>
              <ion-col size="12" *ngIf="coworkingMonthlyPrice">
                <ion-label style="font-size: 16px; color: black;"
                  ><b
                    >{{ 'global.coworking.monthlyFrom' | translate }}: R$
                    {{ coworkingMonthlyPrice | localNumber: '1.0-0' }}</b
                  ></ion-label
                >
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-row>

        <!-- Available Spaces -->
        <div *ngIf="!isBasic() && availableSpaces.length > 0">
          <ion-row class="row-border-bottom margin-top-20">
            <p class="contact-title margin-top-unset" translate="global.listing-detail.basic.availableSpaces"></p>
          </ion-row>

          <ion-row class="available-space-header">
            <ion-col size="3">
              <ion-label [translate]="availableSpaceCols[0]"></ion-label>
            </ion-col>
            <ion-col size="5">
              <ion-label [translate]="availableSpaceCols[1]"></ion-label>
            </ion-col>
            <ion-col size="4">
              <ion-label [translate]="availableSpaceCols[2]"></ion-label>
            </ion-col>
          </ion-row>
          <ion-row *ngFor="let brr of availableSpaces" class="available-space-rows">
            <ion-col size="3">
              <ion-label>{{ brr.floorOrWarehouse }}</ion-label>
            </ion-col>
            <ion-col size="5">
              <ion-label>{{ brr.suiteOrModule }}</ion-label>
            </ion-col>
            <ion-col size="4">
              <ion-label>{{ brr.area | localNumber: '1.2-2' }}</ion-label>
            </ion-col>
          </ion-row>
        </div>
      </div>

      <!-- Description-->
      <div *ngIf="!isBasic()">
        <ion-row class="row-border-bottom margin-top-20">
          <p class="contact-title margin-top-unset" translate="global.listing-detail.description"></p>
        </ion-row>
        <ion-row class="row-display border-bottom-unset">
          <ion-col size="12">
            <div class="row">
              <span *ngIf="listing?.comments">
                <p class="ql-editor comments-field pre-line-text" localize [textValue]="listing?.comments"></p>
              </span>
            </div>
          </ion-col>
        </ion-row>

        <div *ngIf="listing?.listingStatus?.id === 1 && spotAdmin && seeRevisionChanges">
          <ion-row class="row-border-bottom margin-top-20">
            <p class="contact-title margin-top-unset" translate="global.revisionChanges.title"></p>
          </ion-row>

          <ion-row>
            <ion-grid class="margin-top-20">
              <ion-row class="grid thColor">
                <ion-col size="3">
                  <p></p>
                </ion-col>
                <ion-col class="borderCol" size="4.5">
                  <ion-label translate="global.revisionChanges.currentValue">Current Value</ion-label>
                </ion-col>
                <ion-col size="4.5">
                  <ion-label translate="global.revisionChanges.newValue">New Value</ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="grid" *ngFor="let listingrevision of revisionListings">
                <ion-col size="3">
                  <ion-label>{{ listingrevision.fieldName | translate }}</ion-label>
                </ion-col>
                <ion-col class="borderCol" size="4.5">
                  <ion-label>{{ listingrevision.currentValue }}</ion-label>
                </ion-col>
                <ion-col size="4.5">
                  <ion-label>{{ listingrevision.newValue }}</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-row>
        </div>
      </div>

      <div>
        <!--Amenities -->
        <ion-row class="row-border-bottom margin-top-20" *ngIf="listingTags.length > 0">
          <p class="contact-title margin-top-unset" translate="global.listing-detail.amenities"></p>
        </ion-row>
        <ion-row class="row-display margin-top-unset border-bottom-unset" *ngIf="listingTags.length > 0">
          <ion-col size="12">
            <span>
              <div>
                <ion-chip class="search-tag" *ngFor="let tag of listingTags">
                  <ion-text class="font-size-em" *ngIf="tag.value && !tag.decimal"
                    >{{ tag.name | translate }}: {{ tag.value | localNumber: '1.0-0' }}</ion-text
                  >
                  <ion-text class="font-size-em" *ngIf="tag.value && tag.decimal"
                    >{{ tag.name | translate }}: {{ tag.value | localNumber: '1.2-2' }}</ion-text
                  >
                  <ion-text class="font-size-em" *ngIf="!tag.value">{{ tag.name | translate }}</ion-text>
                </ion-chip>
              </div>
            </span>
          </ion-col>
        </ion-row>

        <!-- Video Tour -->
        <div class="margin-top-20" *ngIf="listing?.vrTourUrl">
          <ion-row class="row-border-bottom">
            <p class="contact-title" translate="global.listing-detail.videoTour"></p>
          </ion-row>
          <ion-row *ngIf="listing?.vrTourUrl">
            <ion-col size="12">
              <app-video [videoURL]="listing?.vrTourUrl"></app-video>
            </ion-col>
          </ion-row>
        </div>

        <!-- MAP -->
        <ion-row #listingMapView class="row-display border-bottom-unset">
          <ion-col size="12" class="new-map-listing-detail">
            <nearby-listing-map
              [isListingDetail]="true"
              [searchCriteria]="nearbySearchCriteria"
              [currentListing]="listing"
              [listings]="nearbyListings"
              [searchTags]="searchTags"
              (refetchListing)="refetchListing($event)"
              (getSelectPropertyListing)="getSelectPropertyListing($event)"
            >
            </nearby-listing-map>
          </ion-col>
        </ion-row>

        <!-- nearby listing -->
        <ion-row #listingCardView id="listingCardView">
          <ion-col>
            <nearby-listing-card
              class="nearby-listing-container"
              [searchCriteria]="nearbySearchCriteria"
              [searchTags]="searchTags"
              [selectedBuildingName]="selectedBuildingName"
              [nearbyListings]="selectedPropertyListings"
            >
            </nearby-listing-card>
          </ion-col>
        </ion-row>

        <!--Sub section Header-->
        <div class="margin-top-20" *ngIf="listingsInSameProperty && listingsInSameProperty.length">
          <ion-row class="row-display">
            <p
              *ngIf="listingsInSameProperty.length > 1"
              class="contact-title"
              translate="global.listing-detail.otherPropertie"
            ></p>
            <p
              *ngIf="listingsInSameProperty.length <= 1"
              class="contact-title"
              translate="global.listing-detail.otherProperties"
            ></p>
          </ion-row>
          <div class="search-results">
            <div class="mobile-width" *ngFor="let listing of listingsInSameProperty">
              <app-listing-card
                style="text-align: center;"
                [activeListingIds]="listingIdsInSameProperty"
                [searchCriteria]="nearbySearchCriteria.searchDTO"
                [searchTags]="searchTags"
                [checkBoxValues]="nearbySearchCriteria.checkBoxValues"
                [listing]="listing"
                [lazyLoadImages]="true"
                [cardImgWithPadding]="true"
                [defaultCoworkingType]="coworkingType"
              >
              </app-listing-card>
            </div>
          </div>
          <div style="text-align: center;">
            <ion-button fill="clear" *ngIf="showMoreIconListingsInSameProperty && !loading" (click)="getMoreListings()">
              <ion-icon class="dismiss-icon" name="ios-arrow-down"></ion-icon>
              <ion-text style="font-size: 1.3em;">{{ 'global.form.moreResults' | translate }}</ion-text>
            </ion-button>
            <span *ngIf="loading">
              <ion-spinner name="dots" color="primary"></ion-spinner>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Sub section Header-->
    <ion-row
      class="new-row-display margin-top-20"
      style="margin-bottom: 10px;"
      *ngIf="
        listing.listingType.toLowerCase() !== 'premium' &&
        listing.listingType.toLowerCase() !== 'featured' &&
        advertisements
      "
    >
      <app-row-header style="width: 100%" [providers]="true" title="{{ 'global.serviceProviders' | translate }}">
      </app-row-header>
    </ion-row>

    <ion-row
      *ngIf="
        listing.listingType.toLowerCase() !== 'premium' &&
        listing.listingType.toLowerCase() !== 'featured' &&
        advertisements
      "
    >
      <div class="full-width">
        <our-partners [listingId]="listing.id" [listingDetail]="true" [advertisements]="advertisements"></our-partners>
      </div>
    </ion-row>
  </div>
</div>
