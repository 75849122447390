<ion-row style=" margin-bottom: 10px;">
  <ion-label>
    {{ 'createSpaces.uploadSpace' | translate }}
    <a (click)="downloadExcel()" style="cursor: pointer;text-decoration: underline;">link</a>
  </ion-label>
</ion-row>

<ion-col style="padding-left: 0px;">
  <label>{{ 'createSpaces.uploadTemplate' | translate }}</label
  ><label>{{ fileName }}</label>
  <button (click)="processTemplate()" [hidden]="!fileName || loading" class="sticky-next-btn next-btn">
    {{ 'createSpaces.processTemplate' | translate }}
  </button>
  <ion-spinner name="dots" color="primary" style="top: 24px;" *ngIf="loading"></ion-spinner>
  <button [hidden]="fileName || loading" mat-raised-button (click)="inputFile.click()" class="next-btn">
    {{ 'FileUpload' | translate }}
  </button>
  <input
    #inputFile
    id="inputImageFile"
    type="file"
    class="form-control input-file"
    (change)="fileChangedEvent($event)"
    style="visibility:hidden; width: 0px"
  />
</ion-col>

<ion-row *ngIf="spacesParameters && spacesParameters.length != 0 && SpotBuildingsResponse" style="margin: auto;">
  <table class="table">
    <thead class="thead-light spaces-table" style="font-size: 13px;text-align: center;white-space: nowrap;">
      <tr>
        <th *ngIf="SpotBuildingsResponse.type == 'C'" scope="col">
          {{ 'buildinsDetail.coworkingType' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.building.buildingType.id == 2001" scope="col">
          {{ 'buildinsDetail.floor' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.building.buildingType.id == 2001" scope="col">
          {{ 'buildinsDetail.suite' | translate }}
        </th>
        <th
          *ngIf="
            SpotBuildingsResponse.building.buildingType.id == 1001 &&
            SpotBuildingsResponse.building.buildingSubType.id != 1002
          "
          scope="col"
        >
          {{ 'buildinsDetail.wareHouse' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.building.buildingType.id == 1001" scope="col">
          {{ 'buildinsDetail.module' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.type == 'L'" scope="col">
          {{ 'buildinsDetail.monthlyRentNoSymbols' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.type == 'L'" scope="col">
          {{ 'buildinsDetail.availableSpace' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.type == 'L'" scope="col">
          {{ 'buildinsDetail.monthlyRentNoSymbols' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.type == 'L'" scope="col">{{ 'buildinsDetail.condition' | translate }}</th>
        <th *ngIf="SpotBuildingsResponse.type == 'S'" scope="col">
          {{ 'buildinsDetail.askingPrice' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.type == 'S'" scope="col">
          {{ 'buildinsDetail.totalAreaSale' | translate }}<sup>2</sup>
        </th>
        <th *ngIf="SpotBuildingsResponse.type == 'S'" scope="col">
          {{ 'buildinsDetail.askingPriceRm' | translate }}<sup>2</sup>
        </th>
        <th *ngIf="SpotBuildingsResponse.type == 'C'" scope="col">
          {{ 'buildinsDetail.positions' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.type == 'C'" scope="col">
          {{ 'buildinsDetail.monthlyCostPerson' | translate }}
        </th>
        <th *ngIf="SpotBuildingsResponse.type == 'C'" scope="col">
          {{ 'buildinsDetail.condition' | translate }}
        </th>
        <th scope="col">{{ 'buildinsDetail.spaceDetails' | translate }}</th>
      </tr>
    </thead>
    <tbody class="text-center" style="font-weight: bold;font-size: 13px;">
      <tr *ngFor="let parameters of spacesParameters; let i = index">
        <td *ngIf="SpotBuildingsResponse.type == 'C'">{{ parameters.coworkingType }}</td>
        <td class="spaceFloorK" *ngIf="SpotBuildingsResponse.building.buildingType.id == 2001">
          {{ parameters.floor }}
        </td>
        <td *ngIf="SpotBuildingsResponse.building.buildingType.id == 2001">{{ parameters.suite }}</td>
        <td
          *ngIf="
            SpotBuildingsResponse.building.buildingType.id == 1001 &&
            SpotBuildingsResponse.building.buildingSubType.id != 1002
          "
        >
          {{ parameters.warehouse }}
        </td>
        <td *ngIf="SpotBuildingsResponse.building.buildingType.id == 1001">{{ parameters.module }}</td>
        <td *ngIf="SpotBuildingsResponse.type == 'L'">
          {{
            (parameters.monthlyIptu + parameters.monthlyAskingRent + parameters.monthlyCondo) / parameters.area
              | localNumber: '1.0-0'
          }}
          R$/m²
        </td>
        <td *ngIf="SpotBuildingsResponse.type == 'L'">{{ parameters.area | localNumber: '1.0-0' }} m²</td>
        <td *ngIf="SpotBuildingsResponse.type == 'L'">
          R$
          {{ parameters.monthlyIptu + parameters.monthlyAskingRent + parameters.monthlyCondo | localNumber: '1.0-0' }}
        </td>
        <td *ngIf="SpotBuildingsResponse.type == 'L'">
          {{ parameters.spaceCondition ? i18n.getTranslation(parameters.spaceCondition.name) : '' }}
        </td>
        <td *ngIf="SpotBuildingsResponse.type == 'S'">R$ {{ parameters.askingPrice | localNumber: '1.0-0' }}</td>
        <td *ngIf="SpotBuildingsResponse.type == 'S'">{{ parameters.area | localNumber: '1.0-0' }} m²</td>
        <td *ngIf="SpotBuildingsResponse.type == 'S'">
          {{
            parameters.askingPrice == null || parameters.area == null
              ? 0
              : (parameters.askingPrice / parameters.area | localNumber: '1.0-0')
          }}
          R$/m²
        </td>
        <td *ngIf="SpotBuildingsResponse.type == 'C'">{{ parameters.positions }}</td>
        <td *ngIf="SpotBuildingsResponse.type == 'C'">{{ parameters.monthlyCost | localNumber: '1.0-0' }}</td>
        <td *ngIf="SpotBuildingsResponse.type == 'C'">
          {{ parameters.spaceCondition ? i18n.getTranslation(parameters.spaceCondition.name) : '' }}
        </td>
        <td>
          <ion-button
            style="height: 28px;color: #00334D;--color-hover: white; margin: -11px 0px -2px 0px;"
            (click)="seeMoreAction(parameters)"
          >
            <span *ngIf="parameters.spacePhotos == null">{{ 'createSpaces.addPhotos' | translate }}</span>
            <span *ngIf="parameters.spacePhotos">{{ 'createSpaces.editPhotos' | translate }}</span>
          </ion-button>
        </td>
      </tr>
    </tbody>
  </table>
</ion-row>
