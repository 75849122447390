import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('app/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'search-navigation',
    loadChildren: () => import('app/search-navigation/search-navigation.module').then(m => m.SearchNavigationModule)
  },
  {
    path: 'user-home',
    loadChildren: () => import('app/user-home/user-home.routing.module').then(m => m.UserHomeRoutingModule)
  },
  {
    path: 'spot-buildings',
    loadChildren: () => import('app/spot-buildings/spot-buildings.module').then(m => m.SpotBuildingsModule)
  },
  {
    path: 'companies',
    loadChildren: () => import('app/company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'brokers',
    loadChildren: () => import('app/brokers/brokers.module').then(m => m.BrokersModule)
  },
  {
    path: 'favorites',
    loadChildren: () => import('./favorites/favorites.module').then(m => m.FavoritesModule)
  },
  {
    path: 'images',
    loadChildren: () => import('app/preview-image/preview-image.module').then(m => m.PreviewImageModule)
  },
  {
    path: 'not-found',
    loadChildren: () => import('app/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'ignore'
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
