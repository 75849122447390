import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbOptions } from '@app/shared';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AccountService, AuthenticationService } from '@app/core/auth';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { CommonGenericService } from '@app/core/common.service';

import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { UpdateEmailModalComponent } from '../update-email/update-email-modal.component';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
import { UpdatePasswordModalComponent } from '../update-password/update-password-modal.component';
import { EditImageModalComponent } from '../edit-image/edit-image-modal.component';
import { UserProfile } from '@app/models/user-account.model';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { formatPhoneNumberWithoutRegion } from './phone-custom-validation';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { ImageService } from '@app/core/image.service';
import { I18nService } from '@app/core';

@Component({
  selector: 'my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, OnDestroy {
  isMobile: boolean = false;
  advancedSettingsClass: string = 'collapse';
  expandAdvancedSettings: boolean = false;
  getLanguage: any;
  imagePlaceholder: string = 'assets/images/default-profile-photo.png';
  intlTelephoneOptions: any = this.commonService.getDefaultIntlPhoneProp();
  profileImage: any;
  profileImageSubscription: Subscription;
  profileLogoSubscription: Subscription;
  userAccountInfo: any;
  multiLangOptions: any;
  userBiographyValue: any;
  userBiographyValueInitial: any;
  biographyInit: any;
  userProfileForm: FormGroup;
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };
  noValidate: boolean = false;
  userHomeService: any;
  imageLogos: any;
  logoId: any = null;

  constructor(
    private auth: AuthenticationService,
    private commonService: CommonGenericService,
    private router: Router,
    private userAccountService: AccountService,
    userHomeService: UserHomeService,
    private userActivityService: UserActivityService,
    private imageService: ImageService,
    private i18nService: I18nService
  ) {
    this.userHomeService = userHomeService;
    this.userProfileForm = this.userHomeService.createUserProfileForm();
  }

  ngOnInit() {
    this.profileLogoSubscription = this.imageService.getLogoId().subscribe(val => {
      this.logoId = val;
      if (this.logoId != null && this.auth.$isLoginSubject.value) {
        this.saveUserAccount(this.logoId);
      }
    });
    this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_USER_PROFILE_PAGE);
    this.profileImageSubscription = this.userHomeService.profileImage$.subscribe((image: any) => {
      this.profileImage = image;
    });

    this.getUserProfile();
    this.getLanguage = this.commonService.getLanguage;
    this.multiLangOptions = this.userHomeService.getMultiLangOption();
  }

  ngOnDestroy() {
    this.profileImageSubscription.unsubscribe();
    this.profileLogoSubscription.unsubscribe();
  }

  private initUserProfileForm() {
    this.userBiographyValue = this.userAccountInfo.biography ? JSON.parse(this.userAccountInfo.biography) : undefined;
    this.userBiographyValueInitial = this.userAccountInfo.biography
      ? JSON.parse(this.userAccountInfo.biography)
      : undefined;
    this.setUserProfileFormValue('profileInfo', 'jobTitle', this.userAccountInfo.jobTitle);
    this.setUserProfileFormValue('userName', 'firstName', this.userAccountInfo.firstName);
    this.setUserProfileFormValue('userName', 'lastName', this.userAccountInfo.lastName);

    this.setUserProfileFormValue('location', 'address', this.userAccountInfo.address);
    this.setUserProfileFormValue('location', 'city', this.userAccountInfo.city);
    this.setUserProfileFormValue('location', 'state', this.userAccountInfo.state);
    this.setUserProfileFormValue('location', 'postalCode', this.userAccountInfo.postalCode);
    this.setUserProfileFormValue('location', 'country', this.userAccountInfo.country);
    this.setUserProfileFormValue('location', 'company', this.userAccountInfo.company.name);

    this.setUserProfileFormValue('advancedSettings', 'excludeFromCommunity', this.userAccountInfo.excludeFromCommunity);
    this.setUserProfileFormValue('advancedSettings', 'emailNotification', this.userAccountInfo.emailNotification);
    this.setUserProfileFormValue(
      'advancedSettings',
      'excludeFromCommunityEmails',
      this.userAccountInfo.excludeFromCommunityEmails
    );

    this.setUserProfileFormValue(
      'contact',
      'primaryPhone',
      this.userHomeService.getContactNumber(this.userAccountInfo, 'PHONE_NUMBER')
    );
    this.setUserProfileFormValue(
      'contact',
      'whatsApp',
      this.userHomeService.getContactNumber(this.userAccountInfo, 'WHATSAPP')
    );
  }

  private setUserProfileFormValue(groupName: string, fieldName: string, fieldValue: any) {
    let formFieldValue = fieldValue !== null || fieldName !== 'undefined' ? fieldValue : '';
    this.userProfileForm
      .get(groupName)
      .get(fieldName)
      .setValue(formFieldValue);
  }

  private getCurrentImageProfile(prop: any) {
    const userAccountInfo: any = this.userHomeService.setUserAccountProp(this.userAccountInfo, prop);
    const newProfileImage = this.userHomeService.setProfileImageProp(prop);
    this.userHomeService.profileImage$.next(newProfileImage);
    // this.userAccountService.currentUser$.next(userAccountInfo);
  }

  private async getUserProfile() {
    const userPhotos: any = await this.userAccountService.getUserPhotoList().toPromise();
    const image: any = this.userAccountService.getUploadedImageId(userPhotos);
    this.userAccountInfo = await this.userAccountService.getAccount().toPromise();
    this.userHomeService.getContactCountriesISO(this.userAccountInfo.phones);
    this.profileImage = this.userHomeService.setProfileImageProp(image);
    this.initUserProfileForm();
  }

  private getMediumImg(logoId: any = null) {
    return this.imageService.mediumThumbnail(logoId);
  }

  private revomeLogo() {
    this.saveUserAccount('');
    this.imageService.setLogoId(null);
  }

  private async openInfoModal(headerTitle: string, bodyMessage: string, data?: any, extraData?: any) {
    const modalProps: any = {
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: 'generic-info-modal',
      component: InfoModalComponent,
      componentProps: {
        headerTitle: headerTitle,
        bodyMessage: bodyMessage,
        data: data,
        extraData: extraData
      }
    };
    const infoModal = await this.commonService.createModal(modalProps);
    infoModal.onWillDismiss().then((result: any) => {
      if (result.data.action === 'changeEmailSuccess' || result.data.action === 'changePasswordSuccess') {
        this.auth.logout();
      } else if (result.data.action === 'updateUserProfile') {
        this.getMostCurrentProfile();
      } else if (result.data.action === 'updateProfileImageSuccess') {
        this.getCurrentImageProfile(result.data.extraData);
      }
    });
    return infoModal.present();
  }

  private async getMostCurrentProfile() {
    const userAccount: any = await this.userAccountService.getAccount().toPromise();
    const userAccountInfo: any = {
      profile: userAccount,
      profileImage: this.profileImage.croppedImage,
      originalImage: this.profileImage.originalImage
    };
    // this.commonService.updateLocalStorageData('userProfile', userAccount);
    // this.userAccountService.currentUser$.next(userAccountInfo);
    this.userProfileForm.reset();
    this.goBackToUserHome();
  }

  public async openUpdateEmailModal() {
    const componentProps: any = {
      auth: this.auth,
      commonService: this.commonService,
      currentEmail: this.userAccountInfo.login,
      openInfoModal: this.openInfoModal,
      userAccountService: this.userAccountService,
      userHomeService: this.userHomeService
    };
    const cssClass: string = 'change-email-modal';
    const modalProps: any = this.userHomeService.getModalProp(UpdateEmailModalComponent, componentProps, cssClass);
    const updateEmailModal = await this.commonService.createModal(modalProps);
    return await updateEmailModal.present();
  }

  public async openUpdatePasswordModal() {
    const componentProps: any = {
      auth: this.auth,
      commonService: this.commonService,
      login: this.userAccountInfo.login,
      openInfoModal: this.openInfoModal,
      userHomeService: this.userHomeService,
      userAccountService: this.userAccountService
    };
    const cssClass: string = 'change-password-modal';
    const modalProps: any = this.userHomeService.getModalProp(UpdatePasswordModalComponent, componentProps, cssClass);
    const updatePasswordModal = await this.commonService.createModal(modalProps);
    return await updatePasswordModal.present();
  }

  public async openEditImageModal() {
    const componentProps: any = {
      commonService: this.commonService,
      getCurrentImageProfile: this.getCurrentImageProfile,
      login: this.userAccountInfo.login,
      profileImage: this.profileImage,
      openInfoModal: this.openInfoModal,
      profileImageUrl: this.profileImage.originalImage,
      userAccountService: this.userAccountService,
      userAccountInfo: this.userAccountInfo,
      userHomeService: this.userHomeService
    };
    const cssClass: string = 'edit-image-modal';
    const modalProps: any = this.userHomeService.getModalProp(EditImageModalComponent, componentProps, cssClass);
    const editImageModal = await this.commonService.createModal(modalProps);
    return await editImageModal.present();
  }

  public async openEditLogoModal() {
    let userlogo = [];
    if (this.logoId) {
      userlogo.push(this.logoId);
    } else {
      userlogo.push(
        this.userAccountInfo.companyLogoImageId != undefined ? this.userAccountInfo.companyLogoImageId : null
      );
    }

    const imageLogo: any = this.userAccountService.getUploadedImageId(userlogo);
    this.imageLogos = this.userHomeService.setProfileImageProp(imageLogo);

    const componentProps: any = {
      commonService: this.commonService,
      getCurrentImageProfile: this.getCurrentImageProfile,
      login: this.userAccountInfo.login,
      profileImage: this.imageLogos,
      openInfoModal: this.openInfoModal,
      profileImageUrl: this.imageLogos.originalImage,
      userAccountService: this.userAccountService,
      userAccountInfo: this.userAccountInfo,
      userHomeService: this.userHomeService,
      editLogo: true
    };
    const cssClass: string = 'edit-image-modal';
    const modalProps: any = this.userHomeService.getModalProp(EditImageModalComponent, componentProps, cssClass);
    const editImageModal = await this.commonService.createModal(modalProps);
    return await editImageModal.present();
  }

  get biographyFormArray() {
    return this.userProfileForm.get('profileInfo').get('biography');
  }

  public canDeactivate(): boolean {
    if (this.noValidate) {
      // only when saving
      this.noValidate = false;
      return true;
    }

    const biographyList: any[] = this.userProfileForm.value.profileInfo.biography;
    const biography: string = biographyList.reduce((accumulator: any, item: any) => {
      accumulator = Object.assign(accumulator, item);
      return accumulator;
    }, {});
    let bio = JSON.stringify(biography);
    return !this.userProfileForm.touched && (bio == this.userAccountInfo.biography || !this.userAccountInfo.biography);
  }

  public validateWhatsAppNumber() {
    const whatsAppControl: FormControl = <FormControl>this.userProfileForm.get('contact').get('whatsApp');

    if (whatsAppControl.value == null) {
      return true;
    }

    if (whatsAppControl.errors || whatsAppControl.value.internationalNumber.length === 0) {
      const headerLabel: string = 'global.user.editUserProfile';
      let bodyMessage: string = 'global.user.profileForm.invalidNumber';
      if (
        (whatsAppControl.errors && whatsAppControl.errors.invalidNumberForRegion) ||
        whatsAppControl.value.internationalNumber.length === 0
      ) {
        bodyMessage = 'global.user.profileForm.invalidNumberForRegion';
      }
      this.openInfoModal(headerLabel, bodyMessage);
      return false;
    }
    return true;
  }

  private isFormInvalid(): boolean {
    const primaryPhoneControl: FormControl = <FormControl>this.userProfileForm.get('contact').get('primaryPhone');
    const jobTitle: FormControl = <FormControl>this.userProfileForm.get('profileInfo').get('jobTitle');
    const userNameControl: FormControl = <FormControl>this.userProfileForm.get('userName');

    const jobTitleErrors = jobTitle.errors ? jobTitle.errors.required : false;
    const userNameErrors = userNameControl.invalid;

    return primaryPhoneControl.value == null || jobTitleErrors || userNameErrors;
  }

  public saveUserAccount(logoId: any = null) {
    if (logoId || logoId == '') {
      this.noValidate = true;
      this.userAccountInfo.companyLogoImageId = logoId == '' ? null : logoId;
      const santizedRawFormData = this.userHomeService.santizeUserProfileForm(this.userProfileForm);
      const userProfilePayload: UserProfile = this.userHomeService.sanitizeUserProfile(
        this.userAccountInfo,
        santizedRawFormData
      );
      this.userAccountService.saveUserProfile(userProfilePayload).subscribe(() => {
        this.getUserProfile();
      });
    } else {
      const isFormInvalid: boolean = this.isFormInvalid();
      if (!isFormInvalid && this.validateWhatsAppNumber()) {
        this.noValidate = true;
        this.userAccountInfo.companyLogoImageId = this.logoId;
        const santizedRawFormData = this.userHomeService.santizeUserProfileForm(this.userProfileForm);
        const userProfilePayload: UserProfile = this.userHomeService.sanitizeUserProfile(
          this.userAccountInfo,
          santizedRawFormData
        );
        this.userAccountService.saveUserProfile(userProfilePayload).subscribe(() => {
          const headerLabel: string = 'global.user.editUserProfile';
          const bodyMessage: string = 'global.user.message.userProfileUpdateSuccessfully';
          const action = 'updateUserProfile';
          this.openInfoModal(headerLabel, bodyMessage, action);
        });
      }
    }
  }

  public goBackToUserHome() {
    this.router.navigateByUrl(this.i18nService.getHomeSPOTURL());
  }

  public expandCollapseAdvSettings(): void {
    this.expandAdvancedSettings = !this.expandAdvancedSettings;
    this.advancedSettingsClass = this.expandAdvancedSettings ? 'expand' : 'collapse';
  }

  public setSubmitButtonClass() {
    const isInvalidForm: boolean = this.isFormInvalid();
    return isInvalidForm ? 'non-mobile-btn-disabled mobile-btn-disabled' : 'none-mobile-btn mobile-btn';
  }
}
