<header class="col-md-12 title">
  <div style="padding-top: 20px;padding-left: 10px;">
    <span style="font-size: 18px;font-weight: 800;">{{ 'favorites.tourBooks.shareTourTitle' | translate }}</span>
    <img *ngIf="isMobile" class="close-icon" (click)="close()" src="assets/images/icons/closeWhite.png" alt="" />
    <img *ngIf="!isMobile" class="close-icon" (click)="close()" src="assets/images/icons/close.png" alt="" />
  </div>
</header>
<div>
  <div style="padding: 24px;">
    <div class="col-md-12">
      <h6 style="margin-top: 20px;">
        <b>{{ 'favorites.tourBooks.fullName' | translate }}</b>
      </h6>
    </div>
    <div class="col-md-12" style="padding: 18px;margin-top: -18px;">
      <input
        #spaceInput
        id="fullName"
        name="fullName"
        type="text"
        [(ngModel)]="fullName"
        class="form-control"
        [placeholder]="'favorites.tourBooks.fullName' | translate"
      />
    </div>
    <div class="col-md-12">
      <h6 style="margin-top: 10px;">
        <b>{{ 'favorites.tourBooks.email' | translate }}</b>
      </h6>
    </div>
    <div class="col-md-12" style="padding: 18px;margin-top: -18px;">
      <input
        #spaceInput
        id="email"
        name="email"
        type="text"
        [(ngModel)]="email"
        class="form-control"
        [placeholder]="'favorites.tourBooks.email' | translate"
      />
    </div>

    <div class="col-md-12" style="padding: 18px;margin-top: -18px;">
      <strong>{{ 'favorites.or' | translate }}</strong>
    </div>

    <div class="col-md-12" style="padding: 18px;margin-top: -18px;">
      <a [href]="url" target="_blank" style="color: #0d6efd;"
        >{{ 'favorites.shareIn' | translate }}<img src="../../../assets/icons/whatsapp.png" alt=""
      /></a>
    </div>
  </div>
  <div style="width: 100%;text-align: center;" class="tags-section" *ngIf="searching">
    <ion-spinner id="mainSpinner" name="dots" color="primary"></ion-spinner>
  </div>
  <div class="col-md-12 mobile-btn" style="background: #F8F9FA;">
    <button *ngIf="isMobile" (click)="close()" style="margin-top: 12px;" class="btn-color-1 btn-border btn-mobile">
      {{ 'Cancel' | translate }}
    </button>
    <ion-button
      *ngIf="!isMobile"
      [disabled]="!email || !fullName || !validateEmail() || searching"
      style="margin-top: 12px;"
      class="btn-color-2 btn-mobile"
      (click)="sendEmail()"
    >
      {{ 'favorites.send' | translate }}
    </ion-button>
    <ion-button
      *ngIf="isMobile"
      [ngClass]="{
        'btn-color-mobile': !email || !fullName || !validateEmail()
      }"
      [disabled]="!email || !fullName || !validateEmail() || searching"
      style="margin-top: 12px;"
      class="btn-color-2 btn-mobile"
      (click)="sendEmail()"
    >
      {{ 'favorites.send' | translate }}
    </ion-button>
    <button *ngIf="!isMobile" (click)="close()" style="margin-top: 12px;" class="btn-cancel-set btn-mobile">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</div>
