import { Component, OnInit, Input } from '@angular/core';
import { BreadcrumbOptions } from '@app/shared';
import { I18nService } from '@app/core';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };

  @Input() title: string;
  @Input() standardBackButton?: boolean = false;

  titleTranslated: string;

  constructor(private i18nService: I18nService) {}

  ngOnInit() {
    this.titleTranslated = this.i18nService.get(this.title);
  }
}
