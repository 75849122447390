import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CommonGenericService } from '../common.service';
import { PropertyTypeHelperService } from '../helpers';
import { I18nService } from '../i18n.service';
import { ImageService } from '../image.service';
import { environment } from '@env/environment';
import { CompanyDTO } from '@app/models/company.model';
import { AgentDTO } from '@app/models/spot-buildings/agent-broker.model';
import { BrokersDTO } from '@app/models/bokers.model';
import { Router } from '@angular/router';
import { AppRouteNamesService, CustomSpotSearchType } from '../helpers/app-route-names.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../logger.service';
import { BrokerMetatag, CompanyMetatag, ListingMetatag } from '@app/models/spot-buildings/spot-buildings-types';

const metaLog = new Logger('MetaService');
export interface MetaTag {
  name: string;
  content: any;
}

@Injectable()
export class MetaService {
  private metatagsInfoSearchAPI: string = `/api/metatags/spotsearch`;
  private metatagsOnlyhAPI: string = `/api/metatags/prerender/sent`;
  constructor(
    private title: Title,
    private meta: Meta,
    private i18NService: I18nService,
    private propertyHelperService: PropertyTypeHelperService,
    private commonService: CommonGenericService,
    private imgService: ImageService,
    private route: Router,
    private appRouteNames: AppRouteNamesService,
    private http: HttpClient
  ) {}

  setDynamicTags(tags: MetaTag[], title?: string) {
    this.title.setTitle(title || this.title.getTitle());
    tags.forEach(t => {
      if (t.name) {
        if (t.name.includes('og:')) {
          this.meta.removeTag(`property='${t.name}'`);
          this.meta.updateTag({ property: t.name, content: t.content });
        } else {
          this.meta.removeTag(`name='${t.name}'`);
          this.meta.updateTag({ name: t.name, content: t.content });
        }
      }
    });
  }

  setNonFoundTags() {
    this.meta.addTags([{ name: 'prerender-status-code', content: '404' }]);
  }

  getMetaTagsFrom(title: string, description: string, keywords?: string): any[] {
    const tags = [
      // general
      { name: 'name', content: encodeURIComponent(title) },
      { name: 'author', content: 'SiiLA' },
      { name: 'og:url', content: `${encodeURI(window.location.href)}` },
      { name: 'og:locale', content: [this.i18NService.getCurrentLocale()] },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:site_url', content: `${encodeURI(window.location.href)}` },
      { name: 'twitter:description', content: [description] },
      // Title
      { name: 'og:title', content: [title] }, //don't prepend for og:title tag but do for generic and twitter
      { name: 'title', content: [title] },
      { name: 'twitter:title', content: [title] },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(title)
      },
      // Description
      { name: 'og:description', content: [description] },
      { name: 'description', content: [description] }
    ];

    if (keywords) {
      tags.push({ name: 'keywords', content: [keywords] });
    }

    return tags;
  }

  setCompanyMetaTags(company: CompanyDTO, agentsDTO: AgentDTO[], spotBuildingNames: string[]) {
    const detailsMetaTags = this.buildCompanyMetatag(company, agentsDTO, spotBuildingNames);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const descText = company.biography ? this.removeHTMLCode(this.i18NService.getTranslation(company.biography)) : '';
    const locale = this.i18NService.getCurrentLocale();
    const companyTitle = this.i18NService.getTranslation(company.name);
    const fullDetailsURL = window.location.href;

    this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      { name: 'title', content: [fullTitleText] },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [companyTitle] },
      { name: 'og:email', content: 'spot@siila.com.br' },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      {
        name: 'og:image',
        property: 'og:image',
        content:
          company.mainImageId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
            : null
      },
      {
        name: 'image',
        content:
          company.mainImageId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
            : null
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [companyTitle] },
      {
        name: 'twitter:image',
        content:
          company.mainImageId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
            : null
      },
      { name: 'twitter:description', content: descText }
    ];

    this.setDynamicTags(tags, fullTitleText);
  }

  setBrokerMetaTags(brokerDTO: any, brokerBIO: string, spotBuildingNames: string[]) {
    const detailsMetaTags = this.buildBrokerMetatag(brokerDTO, spotBuildingNames);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const descText = brokerBIO ? this.removeHTMLCode(this.i18NService.getTranslation(brokerBIO)) : '';
    const locale = this.i18NService.getCurrentLocale();
    const brokerTitle = this.i18NService.getTranslation(brokerDTO.firstNameAndLastName);
    const fullDetailsURL = window.location.href;

    this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      { name: 'title', content: [fullTitleText] },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [brokerTitle] },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      {
        name: 'og:image',
        property: 'og:image',
        content:
          brokerDTO.photoId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
            : null
      },
      {
        name: 'image',
        content:
          brokerDTO.photoId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
            : null
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [brokerTitle] },
      {
        name: 'twitter:image',
        content:
          brokerDTO.photoId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
            : null
      },
      { name: 'twitter:description', content: descText }
    ];

    this.setDynamicTags(tags, fullTitleText);
  }

  buildBrokerMetatag(broker: AgentDTO, spotBuildingNames: string[]) {
    let metaTagTitle = '';

    const brokerTitleTag = broker.firstNameAndLastName;

    metaTagTitle = `${brokerTitleTag} | SiiLA SPOT`;

    // KeyWord Metatag
    let keywordsMetaTag = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(brokerTitleTag);
    keywordsMetaTags.push(broker.companyName);
    keywordsMetaTags.push(broker.userEmail);
    const leaseVal = this.commonService.capitalizeFirstLetter(
      this.i18NService.get('global.list-your-property.lease').toLowerCase()
    );
    const saleVal = this.commonService.capitalizeFirstLetter(
      this.i18NService.get('global.list-your-property.sale').toLowerCase()
    );
    keywordsMetaTags.push(leaseVal);
    keywordsMetaTags.push(saleVal);

    if (spotBuildingNames) {
      spotBuildingNames.forEach((item: string, index: number) => {
        keywordsMetaTags.push(item);
      });
    }

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag };
  }

  setBrokerMetaTagsLight(brokerDTO: BrokerMetatag) {
    const detailsMetaTags = this.buildBrokerMetatagLight(brokerDTO);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const descText = brokerDTO.biography
      ? this.removeHTMLCode(this.i18NService.getTranslation(brokerDTO.biography))
      : '';
    const locale = this.i18NService.getCurrentLocale();
    const brokerTitle = this.i18NService.getTranslation(brokerDTO.brokerName);
    const fullDetailsURL = window.location.href;

    this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      { name: 'title', content: [fullTitleText] },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [brokerTitle] },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      {
        name: 'og:image',
        property: 'og:image',
        content:
          brokerDTO.photoId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
            : null
      },
      {
        name: 'image',
        content:
          brokerDTO.photoId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
            : null
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [brokerTitle] },
      {
        name: 'twitter:image',
        content:
          brokerDTO.photoId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
            : null
      },
      { name: 'twitter:description', content: descText }
    ];

    this.setDynamicTags(tags, fullTitleText);
  }

  buildBrokerMetatagLight(broker: BrokerMetatag) {
    let metaTagTitle = '';

    const brokerTitleTag = broker.brokerName;

    metaTagTitle = `${brokerTitleTag} | SiiLA SPOT`;

    // KeyWord Metatag
    let keywordsMetaTag = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(brokerTitleTag);
    keywordsMetaTags.push(broker.companyName);
    keywordsMetaTags.push(broker.userEmail);
    const leaseVal = this.commonService.capitalizeFirstLetter(
      this.i18NService.get('global.list-your-property.lease').toLowerCase()
    );
    const saleVal = this.commonService.capitalizeFirstLetter(
      this.i18NService.get('global.list-your-property.sale').toLowerCase()
    );
    keywordsMetaTags.push(leaseVal);
    keywordsMetaTags.push(saleVal);

    if (broker.spotBuildingNames) {
      broker.spotBuildingNames.forEach((item: string, index: number) => {
        keywordsMetaTags.push(item);
      });
    }

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag };
  }

  buildCompanyMetatag(company: CompanyDTO, agentsDTO: AgentDTO[], spotBuildingNames: string[]) {
    let metaTagTitle = '';

    const companyTitleTag = company.name;

    metaTagTitle = `${companyTitleTag} | SiiLA SPOT`;

    // KeyWord Metatag
    let keywordsMetaTag = '';
    let companyName = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(companyTitleTag);

    const leaseVal = this.commonService.capitalizeFirstLetter(
      this.i18NService.get('global.list-your-property.lease').toLowerCase()
    );
    const saleVal = this.commonService.capitalizeFirstLetter(
      this.i18NService.get('global.list-your-property.sale').toLowerCase()
    );

    keywordsMetaTags.push(leaseVal);
    keywordsMetaTags.push(saleVal);
    if (company.websiteURL) {
      keywordsMetaTags.push(company.websiteURL);
    }

    if (agentsDTO) {
      agentsDTO.forEach((item: AgentDTO, index: number) => {
        keywordsMetaTags.push(item.firstNameAndLastName);
      });
    }

    if (spotBuildingNames) {
      spotBuildingNames.forEach((item: string, index: number) => {
        keywordsMetaTags.push(item);
      });
    }

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag };
  }

  setCompanyMetaTagsLight(company: CompanyMetatag) {
    const detailsMetaTags = this.buildCompanyMetatagLight(company);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const descText = company.biography ? this.removeHTMLCode(this.i18NService.getTranslation(company.biography)) : '';
    const locale = this.i18NService.getCurrentLocale();
    const companyTitle = this.i18NService.getTranslation(company.companyName);
    const fullDetailsURL = window.location.href;

    this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      { name: 'title', content: [fullTitleText] },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [companyTitle] },
      { name: 'og:email', content: 'spot@siila.com.br' },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      {
        name: 'og:image',
        property: 'og:image',
        content:
          company.mainImageId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
            : null
      },
      {
        name: 'image',
        content:
          company.mainImageId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
            : null
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [companyTitle] },
      {
        name: 'twitter:image',
        content:
          company.mainImageId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
            : null
      },
      { name: 'twitter:description', content: descText }
    ];

    this.setDynamicTags(tags, fullTitleText);
  }

  buildCompanyMetatagLight(company: CompanyMetatag) {
    let metaTagTitle = '';

    const companyTitleTag = company.companyName;

    metaTagTitle = `${companyTitleTag} | SiiLA SPOT`;

    // KeyWord Metatag
    let keywordsMetaTag = '';
    let companyName = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(companyTitleTag);

    const leaseVal = this.commonService.capitalizeFirstLetter(
      this.i18NService.get('global.list-your-property.lease').toLowerCase()
    );
    const saleVal = this.commonService.capitalizeFirstLetter(
      this.i18NService.get('global.list-your-property.sale').toLowerCase()
    );

    keywordsMetaTags.push(leaseVal);
    keywordsMetaTags.push(saleVal);
    if (company.website) {
      keywordsMetaTags.push(company.website);
    }

    if (company.agentNames) {
      company.agentNames.forEach((item: string, index: number) => {
        keywordsMetaTags.push(item);
      });
    }

    if (company.spotBuildingNames) {
      company.spotBuildingNames.forEach((item: string, index: number) => {
        keywordsMetaTags.push(item);
      });
    }

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag };
  }

  buildCompanyMetatag2(listing: any, locale: any) {
    let metaTagTitle = '';
    let metaTagSubject = '';

    // Listing Type
    const listingTypeKey =
      listing.type === 'L'
        ? 'global.list-your-property.lease'
        : listing.type === 'S'
        ? 'global.list-your-property.sale'
        : listing.type === 'C'
        ? 'global.list-your-property.coworking'
        : '';
    const listingTypeTag = this.commonService.capitalizeFirstLetter(this.i18NService.get(listingTypeKey).toLowerCase());

    // Property Type
    let propertyTypeTag = '';
    const propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.building.buildingType.id);
    propertyTypeTag = this.i18NService.get(propertyTypeKey);

    const propertyTypeTitleTag = propertyTypeTag;

    // Building Name
    const buildingName = this.i18NService.getTranslation(listing.propertyName);
    const neighborhood = listing.neighborhood;
    const cityName = listing.building.city ? this.i18NService.getTranslation(listing.building.city.name) : undefined;
    const stateName = listing.building.city
      ? this.i18NService.getTranslation(listing.building.city.countryState.name)
      : undefined;
    const address = this.i18NService.getTranslation(listing.building.address);
    const company = listing.offeredByCompany.name;

    metaTagTitle = `${buildingName} | ${listingTypeTag} | ${propertyTypeTitleTag} | SiiLA SPOT`;

    metaTagSubject = buildingName + ', ' + stateName + ', ' + cityName;
    if (neighborhood) {
      metaTagSubject += ', ' + neighborhood;
    }

    metaTagSubject += ' - ' + company + ' - ' + listingTypeTag;

    // KeyWord Metatag
    let keywordsMetaTag = '';
    let companyName = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(buildingName);
    keywordsMetaTags.push(listingTypeTag);
    keywordsMetaTags.push(propertyTypeTag);
    keywordsMetaTags.push(cityName);
    keywordsMetaTags.push(stateName);
    keywordsMetaTags.push(neighborhood);
    keywordsMetaTags.push(address);

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag, metaTagSubject };
  }

  buildSpotBuildingMetatag(listing: any, locale: any) {
    let metaTagTitle = '';
    let metaTagSubject = '';

    // Listing Type
    const listingTypeKey =
      listing.type === 'L'
        ? 'global.list-your-property.lease'
        : listing.type === 'S'
        ? 'global.list-your-property.sale'
        : listing.type === 'C'
        ? 'global.list-your-property.coworking'
        : '';
    const listingTypeTag = this.commonService.capitalizeFirstLetter(this.i18NService.get(listingTypeKey).toLowerCase());

    // Property Type
    let propertyTypeTag = '';
    const propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.building.buildingType.id);
    propertyTypeTag = this.i18NService.get(propertyTypeKey);

    const propertyTypeTitleTag = propertyTypeTag;

    // Building Name
    const buildingName = this.i18NService.getTranslation(listing.propertyName);
    const neighborhood = listing.neighborhood;
    const cityName = listing.building.city ? this.i18NService.getTranslation(listing.building.city.name) : undefined;
    const stateName = listing.building.city
      ? this.i18NService.getTranslation(listing.building.city.countryState.name)
      : undefined;
    const address = this.i18NService.getTranslation(listing.building.address);
    const company = listing.offeredByCompany.name;

    metaTagTitle = `${buildingName} | ${listingTypeTag} | ${propertyTypeTitleTag} | SiiLA SPOT`;

    metaTagSubject = buildingName + ', ' + stateName + ', ' + cityName;
    if (neighborhood) {
      metaTagSubject += ', ' + neighborhood;
    }

    metaTagSubject += ' - ' + company + ' - ' + listingTypeTag;

    // KeyWord Metatag
    let keywordsMetaTag = '';
    let companyName = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(buildingName);
    keywordsMetaTags.push(listingTypeTag);
    keywordsMetaTags.push(propertyTypeTag);
    keywordsMetaTags.push(cityName);
    keywordsMetaTags.push(stateName);
    keywordsMetaTags.push(neighborhood);
    keywordsMetaTags.push(address);

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag, metaTagSubject };
  }

  buildSpotListingMetatag(listing: ListingMetatag, locale: any) {
    let metaTagTitle = '';
    let metaTagSubject = '';

    // Listing Type
    const listingTypeKey =
      listing.listingType === 'L'
        ? 'global.list-your-property.lease'
        : listing.listingType === 'S'
        ? 'global.list-your-property.sale'
        : listing.listingType === 'C'
        ? 'global.list-your-property.coworking'
        : '';
    const listingTypeTag = this.commonService.capitalizeFirstLetter(this.i18NService.get(listingTypeKey).toLowerCase());

    // Property Type
    let propertyTypeTag = '';
    const propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.buildingTypeId);
    propertyTypeTag = this.i18NService.get(propertyTypeKey);

    const propertyTypeTitleTag = propertyTypeTag;

    // Building Name
    const buildingName = this.i18NService.getTranslation(listing.title);
    const neighborhood = listing.neighborhood;
    const cityName = listing.city ? this.i18NService.getTranslation(listing.city) : undefined;
    const stateName = listing.state ? this.i18NService.getTranslation(listing.state) : undefined;
    const address = this.i18NService.getTranslation(listing.address);
    const company = listing.companyName;

    metaTagTitle = `${buildingName} | ${listingTypeTag} | ${propertyTypeTitleTag} | SiiLA SPOT`;

    metaTagSubject = buildingName + ', ' + stateName + ', ' + cityName;
    if (neighborhood) {
      metaTagSubject += ', ' + neighborhood;
    }

    metaTagSubject += ' - ' + company + ' - ' + listingTypeTag;

    // KeyWord Metatag
    let keywordsMetaTag = '';
    let companyName = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(buildingName);
    keywordsMetaTags.push(listingTypeTag);
    keywordsMetaTags.push(propertyTypeTag);
    keywordsMetaTags.push(cityName);
    keywordsMetaTags.push(stateName);
    keywordsMetaTags.push(neighborhood);
    keywordsMetaTags.push(address);

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag, metaTagSubject };
  }

  public async onlyMetatags() {
    return await this.http.get(this.metatagsOnlyhAPI).toPromise();
  }

  async setHomeMetatags() {
    const fullDetailsURL = window.location.href;
    const locale = this.i18NService.getCurrentLocale();
    const detailsMetaTags = await this.buildHomeMetatag(this.route.url, locale);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const subject = detailsMetaTags.metaTagSubject;
    let descText = `Brazil's largest and most comprehensive database of available industrial, office and coworking spaces.`;

    if (locale !== 'en-US') {
      descText = 'O maior e mais completo banco de dados do Brasil de imóveis logísticos, escritórios e coworking';
    }
    descText = detailsMetaTags.metaDescription ? detailsMetaTags.metaDescription : descText;

    this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      //{ name: 'title', content: [fullTitleText] },
      { name: 'subject', content: subject },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: fullTitleText },
      { name: 'og:email', content: 'spot@siila.com.br' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [fullTitleText] },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [fullTitleText] },
      { name: 'twitter:description', content: descText },
      {
        name: 'og:image',
        property: 'og:image',
        content: encodeURI(environment.spotServerUrl + '/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png')
      },
      {
        name: 'image',
        content: encodeURI(environment.spotServerUrl + '/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png')
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [fullTitleText] },
      {
        name: 'twitter:image',
        content: encodeURI(environment.spotServerUrl + '/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png')
      }
    ];

    this.setDynamicTags(tags, fullTitleText);
  }

  private async findMetatagsFrom(searchUrl: string) {
    return await this.http.get(this.metatagsInfoSearchAPI + searchUrl).toPromise();
  }

  async buildHomeMetatag(url: string, locale: any) {
    const customSearchType = this.appRouteNames.findCustomSearchType(url);
    let metaTagTitle = null;
    let metaTagSubject = null;
    let keywordsMetaTag = null;
    const lang = locale == 'en-US' ? 'en' : 'pt-br';
    if (customSearchType) {
      let metatagSearchAPI = this.i18NService.removeLanguageFromURL(url);
      metatagSearchAPI = metatagSearchAPI.replace(`/${this.appRouteNames.getSearchRouteName()}`, '');
      if (customSearchType == CustomSpotSearchType.STATE) {
        metatagSearchAPI += '/default';
      }
      const response: any = await this.findMetatagsFrom(
        `${metatagSearchAPI}?lang=${lang}&customSearchType=${customSearchType}`
      );

      metaTagTitle = response.title;
      keywordsMetaTag = response.title;
      metaTagSubject = response.title;
      let metaDescription = response.description;
      return { metaTagTitle, keywordsMetaTag, metaTagSubject, metaDescription };
    }

    metaTagTitle = 'SiiLA SPOT | Commercial Properties for Lease and Sale';
    metaTagSubject = 'SiiLA SPOT | Commercial Properties for Lease and Sale';
    if (locale !== 'en-US') {
      metaTagTitle = 'SiiLA SPOT | Imóveis comerciais para locação e venda';
      metaTagSubject = 'SiiLA SPOT | Imóveis comerciais para locação e venda';
    }

    // KeyWord Metatag
    keywordsMetaTag = '';

    keywordsMetaTag =
      'coworking sp, coworking bh, coworking porto alegre, coworking, wework, regus, Paulista, Faria Lima, Cidade Jardim, Barueri, SiiLA SPOT, lease, siila.com.br, SiiLA, offices, last mile, last mile delivery, compra e venda de imoveis, Imóveis comerciais, galpões, centros de distribuição, módulos em condomínios logísticos,  condomínio logístico, condominio logistico, corretor de imóveis , Locação de imóveis, compra e venda de imóveis, compra e venda de imoveis, venda de imóveis';

    return { metaTagTitle, keywordsMetaTag, metaTagSubject };
  }

  public addCanonicalTag(currentPath: string) {
    let path = currentPath;
    if (path == null || path === undefined) {
      return path;
    }
    //if (path.endsWith("/en")) {
    //  path = path.slice(0, path.length - 8);
    //}

    /* const linkTag = document.createElement('link');
    linkTag.setAttribute('rel', 'canonical');
    linkTag.href = path;
    document.head.appendChild(linkTag); */

    var existingCanonicalLink = document.querySelector(`link[rel='canonical']`) as HTMLLinkElement;

    if (!existingCanonicalLink) {
      const linkTag = document.createElement('link');
      linkTag.setAttribute('rel', 'canonical');
      linkTag.href = path;
      document.head.appendChild(linkTag);
    } else {
      existingCanonicalLink.href = path;
    }
  }

  setSpotBuildingMetaTags(buildingDetail: any, spotPhotos: any) {
    const locale = this.i18NService.getCurrentLocale();
    const detailsMetaTags = this.buildSpotBuildingMetatag(buildingDetail, locale);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const subject = detailsMetaTags.metaTagSubject;
    const descText = buildingDetail.comments
      ? this.removeHTMLCode(this.i18NService.getTranslation(buildingDetail.comments))
      : '';

    const listingTitle = this.i18NService.getTranslation(buildingDetail.title);
    const fullDetailsURL = window.location.href;
    this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      { name: 'title', content: [fullTitleText] },
      { name: 'subject', content: subject },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:email', content: 'spot@siila.com.br' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [fullTitleText] },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      {
        name: 'og:image',
        property: 'og:image',
        content:
          spotPhotos && spotPhotos.length
            ? encodeURI(
                `${environment.spotServerUrl}${this.getMediumImg(
                  spotPhotos[0].croppedImageId ? spotPhotos[0].croppedImageId : spotPhotos[0].imageId
                )}`
              )
            : null
      },
      {
        name: 'image',
        content:
          spotPhotos && spotPhotos.length
            ? encodeURI(
                `${environment.spotServerUrl}${this.getMediumImg(
                  spotPhotos[0].croppedImageId ? spotPhotos[0].croppedImageId : spotPhotos[0].imageId
                )}`
              )
            : null
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [fullTitleText] },
      {
        name: 'twitter:image',
        content:
          spotPhotos && spotPhotos.length
            ? encodeURI(
                `${environment.spotServerUrl}${this.getMediumImg(
                  spotPhotos[0].croppedImageId ? spotPhotos[0].croppedImageId : spotPhotos[0].imageId
                )}`
              )
            : null
      },
      { name: 'twitter:description', content: descText }
    ];

    this.setDynamicTags(tags, fullTitleText);
  }

  setSpotBuildingMetaTags2(listingDetail: ListingMetatag, spotPhotos: any) {
    const locale = this.i18NService.getCurrentLocale();
    const detailsMetaTags = this.buildSpotListingMetatag(listingDetail, locale);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const subject = detailsMetaTags.metaTagSubject;
    const descText = listingDetail.comments
      ? this.removeHTMLCode(this.i18NService.getTranslation(listingDetail.comments))
      : '';

    const listingTitle = this.i18NService.getTranslation(listingDetail.title);
    const fullDetailsURL = window.location.href;
    this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      { name: 'title', content: [fullTitleText] },
      { name: 'subject', content: subject },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:email', content: 'spot@siila.com.br' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [fullTitleText] },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      {
        name: 'og:image',
        property: 'og:image',
        content:
          listingDetail.photoId > 0
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(listingDetail.photoId)}`)
            : null
      },
      {
        name: 'image',
        content:
          listingDetail.photoId > 0
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(listingDetail.photoId)}`)
            : null
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [fullTitleText] },
      {
        name: 'twitter:image',
        content:
          listingDetail.photoId > 0
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(listingDetail.photoId)}`)
            : null
      },
      { name: 'twitter:description', content: descText }
    ];

    this.setDynamicTags(tags, fullTitleText);
  }

  removeHTMLCode(htmlText: string) {
    var div = document.createElement('div');
    div.innerHTML = htmlText;
    var text = div.textContent || div.innerText || '';
    return text;
  }

  getMediumImg(id: number) {
    return this.imgService.mediumThumbnail(id);
  }
}
