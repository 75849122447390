import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { AuthGuard, BackwardGuard } from '@app/guards';
import { Shell } from '@app/shell/shell.service';
import { BrokerDetailsComponent } from './broker-details/broker-details.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  Shell.childRoutesRunningGuardsResolversAlways([
    {
      path: `${AppRouteNames.OLD_USR_PROFILE_ROUTE}/:name/:brokerId`,
      component: BrokerDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Broker Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.OLD_USR_PROFILE_ROUTE}/:name/:brokerId/lang/:language`,
      component: BrokerDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Broker Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.USR_PROFILE_ROUTE}/:name/:brokerId/en/:language`,
      component: BrokerDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Broker Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.USR_PROFILE_ROUTE_CL}/:name/:brokerId/pt-br/:language`,
      component: BrokerDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Broker Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.USR_PROFILE_ROUTE}/:name/:brokerId`,
      component: BrokerDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Broker Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.USR_PROFILE_ROUTE}/:name/:brokerId/:language`,
      component: BrokerDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Broker Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.USR_PROFILE_ROUTE_CL}/:name/:brokerId`,
      component: BrokerDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Broker Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.USR_PROFILE_ROUTE_CL}/:name/:brokerId/:language`,
      component: BrokerDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Broker Detail'), shouldReuse: false }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class BrokersRoutingModule {}
