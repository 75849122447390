import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@app/core/auth';
import { MatDialog } from '@angular/material';
import { ImageService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { MyListingMenuComponent } from '@app/shared/my-listing-menu/my-listing-menu.component';
import { MyMessageService } from './my-message/my-message.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
export class UserHomeComponent {
    constructor(accountService, commonService, imageService, router, dialog, myMessageService, socketIO, userActivityService) {
        this.accountService = accountService;
        this.commonService = commonService;
        this.imageService = imageService;
        this.router = router;
        this.dialog = dialog;
        this.myMessageService = myMessageService;
        this.socketIO = socketIO;
        this.userActivityService = userActivityService;
        this.imagePlaceholder = 'assets/images/default-profile-photo.png';
        this.userAccountInfo = {};
        this.breadcrumbOptions = {
            navigateHome: true,
            usePlatformLocation: true
        };
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
    }
    getUserProfile(userAccount) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const userPhotos = yield this.accountService.getUserPhotoList().toPromise();
            const image = this.accountService.getUploadedImageId(userPhotos);
            this.userAccountInfo = {
                profile: userAccount,
                profileImage: this.getMediumImg(image.profileImageId),
                originalImage: this.getMediumImg(image.originalImageId)
            };
        });
    }
    ngOnInit() {
        this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_USER_HOME_PAGE);
        this.userAccountSubscription = this.accountService.currentUser$.subscribe(userAccount => {
            this.getUserProfile(userAccount);
        });
    }
    openListingMenu(data) {
        this.dialogRef = this.dialog.open(MyListingMenuComponent, {
            height: 'auto',
            width: '550px',
            panelClass: 'padding-unset'
        });
        this.dialogRef.afterClosed().subscribe((result) => { });
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    ngOnDestroy() {
        if (this.userAccountSubscription) {
            this.userAccountSubscription.unsubscribe();
        }
    }
    goToUserPage(pageRoute) {
        this.router.navigateByUrl(`${pageRoute}`);
    }
    getUnreadMessages() {
        //TODO: Replace with something from chat module
        return this.myMessageService.getUnreadMessages();
    }
    navigateToPurchase() {
        this.router.navigateByUrl('/list-property');
    }
    navigateToRegister() {
        this.router.navigateByUrl('/user-home/current-plans');
    }
    checkRole(role) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    }
}
