<mat-card>
  <mat-card-title style="font-size: 20px;">{{ title }}</mat-card-title>
  <mat-card-content>
    <div id="pac-container" class="form-group">
      <input
        type="text"
        class="form-control"
        (keydown.enter)="$event.preventDefault()"
        placeholder="{{ enterAddressDesc }}"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        autofocus
        #search
      />
    </div>
    <agm-map
      [latitude]="buildingMarker.lat"
      [longitude]="buildingMarker.lng"
      [zoom]="zoom"
      (mapClick)="addMarker($event)"
    >
      <agm-marker
        [latitude]="buildingMarker.lat"
        [longitude]="buildingMarker.lng"
        [markerDraggable]="true"
        (dragEnd)="markerDragEnd($event)"
      >
      </agm-marker>
    </agm-map>
  </mat-card-content>
</mat-card>
