import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { UnitOfMeasure } from '../../../../models/spot-buildings/spot-available-spaces';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { I18nService } from '../../../../core/i18n.service';
import { PlatformHelperService } from '@app/core';
import { CommonGenericService } from '../../../../core/common.service';
import { SpotBuildingSpaceService } from '../../../services/spaces.service';
import { CoworkingSpaceType } from '../../../../models/spot-buildings/spot-available-spaces';
import { RequestInformationType } from '../../../../models/request-information.model';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';
import { VideoComponent } from '@app/shared/video/video.component';
export class SpaceDetailsComponent {
    constructor(navParams, i18n, popoverCtrl, platformConfigHelper, spacesService, spotBuildingsService, streamingMedia, commonService) {
        this.navParams = navParams;
        this.i18n = i18n;
        this.popoverCtrl = popoverCtrl;
        this.platformConfigHelper = platformConfigHelper;
        this.spacesService = spacesService;
        this.spotBuildingsService = spotBuildingsService;
        this.streamingMedia = streamingMedia;
        this.commonService = commonService;
        this.emptyFieldValue = '--';
        this.isMobile = false;
    }
    ngOnInit() {
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.spot = this.navParams.data.spot;
        this.space = this.navParams.data.space;
        this.closeIcon = faTimesCircle;
        this.buildingTypeDTO = this.spot.building.buildingType;
        this.isForIndustrial = this.buildingTypeDTO.id == 1001;
        this.isForOffice = this.buildingTypeDTO.id == 2001;
        this.isForCoworking = this.spot.type == 'C';
        if (this.isForCoworking) {
            this.isForOffice = false;
        }
        this.showSpaceImgs = true;
        this.spaceDescription = this.i18n.getTranslation(this.space.description);
        this.investmentOpportunity = this.space.investmentOpportunity
            ? this.i18n.getTranslation(this.space.investmentOpportunity)
            : undefined;
        this.spotTitle = this.getSpotTitle();
        this.spacePhotos = this.space.spacePhotos;
        this.fillUpDetailsTableRows();
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
    }
    fillUpDetailsTableRows() {
        this.detailsTableRows = [];
        if (this.isForOffice) {
            if (this.spot.forLease) {
                const firstRow = {
                    col1: this.getMaxContiguousTableField(),
                    col2: this.getMonthlyRentTableField()
                };
                const secondRow = {
                    col1: this.getCeilingHeightOfficeTableField(),
                    col2: this.getCondoTableField()
                };
                const thirdRow = {
                    col1: this.getParkingSpacesTableField(),
                    col2: this.getIptuTableField()
                };
                const fourthRow = {
                    col1: this.getDirectSubleaseTableField(),
                    col2: this.getTotalTableField()
                };
                this.detailsTableRows.push(firstRow);
                this.detailsTableRows.push(secondRow);
                this.detailsTableRows.push(thirdRow);
                this.detailsTableRows.push(fourthRow);
            }
            if (this.spot.forSale) {
                const firstRow = {
                    col1: this.getCeilingHeightOfficeTableField(),
                    col2: this.getParkingSpacesTableField()
                };
                this.detailsTableRows.push(firstRow);
            }
        }
        if (this.isForIndustrial) {
            if (this.spot.forLease) {
                const row1 = {
                    col1: this.getDocksTableField(),
                    col2: this.getIncludesRetailTableField()
                };
                this.detailsTableRows.push(row1);
                const row2 = {
                    col1: this.getTruckParkingSpacesTableField(),
                    col2: this.getFullBuildOutTableField()
                };
                this.detailsTableRows.push(row2);
                const row3 = {
                    col1: this.getLoadCapacityTableField(),
                    col2: this.getDirectSubleaseTableField()
                };
                this.detailsTableRows.push(row3);
                const row4 = {
                    col1: this.getSkyLightsTableField(),
                    col2: this.getMonthlyRentTableField()
                };
                this.detailsTableRows.push(row4);
                const row5 = {
                    col1: this.getCeilingHeightForIndustrial(),
                    col2: this.getCondoTableField()
                };
                this.detailsTableRows.push(row5);
                const row6 = {
                    col1: this.getColdStorageTableField(),
                    col2: this.getIptuTableField()
                };
                this.detailsTableRows.push(row6);
                const row7 = {
                    col1: this.getIncludesOfficeTableField(),
                    col2: this.getTotalTableField()
                };
                this.detailsTableRows.push(row7);
            }
            if (this.spot.forSale) {
                const row1 = {
                    col1: this.getDocksTableField(),
                    col2: this.getColdStorageTableField()
                };
                this.detailsTableRows.push(row1);
                const row2 = {
                    col1: this.getTruckParkingSpacesTableField(),
                    col2: this.getIncludesOfficeTableField()
                };
                this.detailsTableRows.push(row2);
                const row3 = {
                    col1: this.getLoadCapacityTableField(),
                    col2: this.getIncludesRetailTableField()
                };
                this.detailsTableRows.push(row3);
                const row4 = {
                    col1: this.getSkyLightsTableField(),
                    col2: this.getFullBuildOutTableField()
                };
                this.detailsTableRows.push(row4);
                const row5 = {
                    col1: this.getCeilingHeightForIndustrial(),
                    col2: undefined
                };
                this.detailsTableRows.push(row5);
            }
        }
        if (this.isForCoworking) {
            const firstRow = {
                col1: this.getconferenceTimeHours(),
                col2: this.getparkingCost()
            };
            const secondRow = {
                col1: this.getprivateOutdoor(),
                col2: this.getprintCopies()
            };
            const thirdRow = {
                col1: this.getsharedOutdoor(),
                col2: this.getMainHandling()
            };
            const fourthRow = {
                col1: this.getparkingAvailable(),
                col2: null
            };
            this.detailsTableRows.push(firstRow);
            this.detailsTableRows.push(secondRow);
            this.detailsTableRows.push(thirdRow);
            this.detailsTableRows.push(fourthRow);
        }
    }
    closeModal() {
        this.popoverCtrl.dismiss();
    }
    requestInfoAction(event) {
        this.popoverCtrl.dismiss({
            event: event,
            requestInfo: true,
            spot: this.spot,
            space: this.space,
            spotTitle: this.spotTitle,
            requestType: RequestInformationType.REQ_INFO
        });
    }
    requestTourAction(event) {
        this.popoverCtrl.dismiss({
            event: event,
            requestInfo: true,
            spot: this.spot,
            space: this.space,
            spotTitle: this.spotTitle,
            requestType: RequestInformationType.REQ_TOUR
        });
    }
    openVideoTour(pEvent) {
        if (this.space.vrTourUrl) {
            this.openVideoTourPopup(pEvent, this.space.vrTourUrl, this.spot.propertyName, this.isMobile, (error) => {
                console.error('Callback from video tour: ', error);
                this.spotBuildingsService.logError(error);
            });
        }
    }
    openVideoTourPopup(ev, videoURL, spotTitle, isMobile, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (isMobile) {
                if (videoURL) {
                    let options = {
                        successCallback: () => { },
                        errorCallback: (e) => {
                            console.error('Error streaming', +e);
                            callback(e);
                        },
                        orientation: 'landscape',
                        shouldAutoClose: false,
                        controls: true
                    };
                    try {
                        this.streamingMedia.playVideo(videoURL, options);
                    }
                    catch (err) {
                        console.error(err);
                        if (callback) {
                            callback(err);
                        }
                    }
                }
                return;
            }
            const popover = yield this.popoverCtrl.create({
                component: VideoComponent,
                event: ev,
                cssClass: 'video-player-popup',
                componentProps: { videoURL: videoURL, fromPopup: true, spotTitle: spotTitle },
                backdropDismiss: true
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    if (callback) {
                        callback(popoverData.data);
                    }
                }
            });
            return yield popover.present();
        });
    }
    getSpotTitle() {
        let val = this.moduleSuiteValue();
        if (val == '' || val == null) {
            return `${this.warehouseFloorTitle()} ${this.warehouseFloorValue()} - ${this.spot.propertyName}`;
        }
        else {
            return `${this.warehouseFloorTitle()} ${this.warehouseFloorValue()}, ${this.moduleSuiteTitle()} ${this.moduleSuiteValue()} - ${this.spot.propertyName}`;
        }
    }
    warehouseFloorTitle() {
        if (this.isForIndustrial) {
            return this.i18n.get('buildinsDetail.wareHouse') + ':';
        }
        if (this.isForOffice || this.isForCoworking) {
            return this.i18n.get('buildinsDetail.floor') + ':';
        }
    }
    warehouseFloorValue() {
        if (this.isForIndustrial) {
            return this.space.warehouse ? this.space.warehouse : '';
        }
        if (this.isForOffice || this.isForCoworking) {
            return this.space.floor ? this.findFloorTranslation(this.space.floor) : '';
        }
    }
    moduleSuiteTitle() {
        if (this.isForIndustrial) {
            return this.i18n.get('buildinsDetail.module') + ':';
        }
        if (this.isForOffice) {
            return this.i18n.get('buildinsDetail.suite') + ':';
        }
        if (this.isForCoworking) {
            if (this.space.coworkingType) {
                if (this.space.coworkingType == CoworkingSpaceType.PRIVATE_OFFICE) {
                    return this.i18n.get('buildinsDetail.suite') + ':';
                }
                if (this.space.coworkingType == CoworkingSpaceType.DEDICATED_WORKSPACE) {
                    return this.i18n.get('spaceDetail.desk') + ':';
                }
            }
        }
        return '';
    }
    moduleSuiteValue() {
        if (this.isForIndustrial) {
            return this.space.module ? this.space.module : '';
        }
        if (this.isForOffice) {
            return this.space.suite ? this.space.suite : '';
        }
        if (this.isForCoworking) {
            if (this.space.coworkingType == CoworkingSpaceType.DEDICATED_WORKSPACE ||
                this.space.coworkingType == CoworkingSpaceType.PRIVATE_OFFICE) {
                return this.space.suite ? this.space.suite : '';
            }
        }
        return '';
    }
    getAvailableSpaceTitle() {
        return this.i18n.get('spaceDetail.availableSpace') + ':';
    }
    getAvailableSpaceValue() {
        const area = this.commonService.formatNumberTo(this.space.area, 0) ? this.space.area : 0;
        return this.formatValueBasedOnMeasure(UnitOfMeasure.SQUARE_METERS, area);
    }
    getCoworkingPositionsTitle() {
        if (this.isForCoworking) {
            return this.i18n.get('spaceDetail.availablePositions') + ':';
        }
    }
    getPriceTitle() {
        if (this.spot.forLease) {
            return this.i18n.get('spaceDetail.monthlyRent').concat(':');
        }
        else {
            return this.i18n.get('spaceDetail.askingPrice') + ':';
        }
    }
    getCoworkingPositions() {
        if (this.isForCoworking) {
            return this.space.positions;
        }
    }
    getPriceValue() {
        let value = undefined;
        if (this.spot.forSale) {
            value = this.space.askingPrice;
        }
        if (this.spot.forLease) {
            value = this.spacesService.getTotalMonthlyRent(this.space);
        }
        if (value) {
            value = this.commonService.formatNumberTo(value, 0);
            value = this.formatValueBasedOnMeasure(UnitOfMeasure.PRICE, value);
        }
        return value != 0 && value != null ? value : this.i18n.get('global.list-your-property.negotiable');
    }
    getPricePerAreaTitle() {
        if (this.spot.forLease) {
            return this.i18n.get('spaceDetail.monthlyRentRm').concat(':');
        }
        else {
            return this.i18n.get('spaceDetail.askingPriceRm') + ':';
        }
    }
    getPricePerAreaValue() {
        let value = undefined;
        if (this.spot.forLease) {
            if (!this.space.area) {
                value = '0';
            }
            else {
                value = this.commonService.formatNumberTo(this.spacesService.getTotalMonthlyRent(this.space) / this.space.area);
            }
        }
        else {
            if (!this.space.area) {
                value = '0';
            }
            else {
                value = this.commonService.formatNumberTo(this.space.askingPrice / this.space.area);
            }
        }
        if (value != '0') {
            return this.formatValueBasedOnMeasure(UnitOfMeasure.PRICE_SQUARE_METERS, value);
        }
        else {
            return this.i18n.get('global.list-your-property.negotiable');
        }
    }
    getMonthlyCostTitle() {
        return this.i18n.get('buildinsDetail.MonthlyCostCoworking') + ':';
    }
    getMonthlyCostValue() {
        return this.commonService.getMonthlyCostWithPositions(this.space);
        /* const value = this.space.monthlyCost;
        if (value) {
          return this.formatValueBasedOnMeasure(UnitOfMeasure.PRICE, value);
        } */
    }
    getFieldValue(val, unitMeasure, decimals) {
        if (!unitMeasure && !decimals)
            return val ? val : this.emptyFieldValue;
        let fieldValue = decimals && val ? this.commonService.formatNumberTo(val, decimals) : val;
        fieldValue = this.formatValueBasedOnMeasure(unitMeasure, fieldValue);
        if (!fieldValue) {
            return this.emptyFieldValue;
        }
        return fieldValue;
    }
    formatValueBasedOnMeasure(unitMeasure, fieldValue) {
        if (unitMeasure && fieldValue) {
            if (unitMeasure == UnitOfMeasure.PRICE) {
                fieldValue = `${unitMeasure} ${fieldValue}`;
            }
            else {
                fieldValue = `${fieldValue} ${unitMeasure}`;
            }
        }
        return fieldValue;
    }
    getBooleanFieldValue(val) {
        return val ? this.i18n.get('global.revisionChanges.yes') : this.i18n.get('global.revisionChanges.no');
    }
    getMaxContiguousTableField() {
        const field = {
            name: 'maxContiguous',
            translate: 'spaceDetail.maxContiguous',
            value: this.getPropertyValue('maxContiguous', UnitOfMeasure.SQUARE_METERS)
        };
        return field;
    }
    getMonthlyRentTableField() {
        const field = {
            name: 'R',
            translate: 'spaceDetail.R',
            priceClass: true,
            value: this.getPropertyValue('R', UnitOfMeasure.PRICE, 2)
        };
        return field;
    }
    getCeilingHeightOfficeTableField() {
        const fieldName = 'ceilingHeightOffice';
        const field = {
            name: fieldName,
            translate: `spaceDetail.${fieldName}`,
            value: this.getPropertyValue(fieldName, UnitOfMeasure.METERS)
        };
        return field;
    }
    getCondoTableField() {
        const fieldName = 'condo';
        const field = {
            name: fieldName,
            translate: `spaceDetail.${fieldName}`,
            priceClass: true,
            value: this.getPropertyValue(fieldName, UnitOfMeasure.PRICE, 2)
        };
        return field;
    }
    getParkingSpacesTableField() {
        const fieldName = 'parkingSpaces';
        const field = {
            name: fieldName,
            translate: `spaceDetail.${fieldName}`,
            value: this.getPropertyValue(fieldName)
        };
        return field;
    }
    getIptuTableField() {
        const fieldName = 'iptu';
        const field = {
            name: fieldName,
            translate: `spaceDetail.${fieldName}`,
            priceClass: true,
            value: this.getPropertyValue(fieldName, UnitOfMeasure.PRICE, 2)
        };
        return field;
    }
    getDirectSubleaseTableField() {
        const fieldName = 'directSublease';
        const field = {
            name: fieldName,
            translate: `spaceDetail.${fieldName}`,
            value: this.getPropertyValue(fieldName)
        };
        return field;
    }
    getTotalTableField() {
        const fieldName = 'total';
        const field = {
            name: fieldName,
            translate: `spaceDetail.${fieldName}`,
            priceClass: true,
            value: this.getPropertyValue(fieldName, UnitOfMeasure.PRICE, 2)
        };
        return field;
    }
    getDocksTableField() {
        return this.createTableField('docks');
    }
    getIncludesRetailTableField() {
        return this.createTableField('includesRetail');
    }
    getTruckParkingSpacesTableField() {
        return this.createTableField('truckParkingSpaces');
    }
    getFullBuildOutTableField() {
        return this.createTableField('fullBuildOut');
    }
    getLoadCapacityTableField() {
        return this.createTableField('loadCapacity', UnitOfMeasure.SQUARE_TON_METERS, 0);
    }
    getSkyLightsTableField() {
        return this.createTableField('skyLights');
    }
    getCeilingHeightForIndustrial() {
        return this.createTableField('ceilingHeight', UnitOfMeasure.METERS, 0);
    }
    getColdStorageTableField() {
        return this.createTableField('coldStorage');
    }
    getconferenceTimeHours() {
        return this.spot.offeredByCompany.name == 'WeWork'
            ? this.createTableField('conferenceCredits')
            : this.createTableField('conferenceTimeHours');
    }
    getparkingCost() {
        return this.createTableField('parkingCost', UnitOfMeasure.PRICE, 0);
    }
    getprivateOutdoor() {
        return this.spot.offeredByCompany.name == 'WeWork'
            ? this.createTableField('privateOutdoorSpace')
            : this.createTableField('privateOutdoor');
    }
    getprintCopies() {
        return this.spot.offeredByCompany.name == 'WeWork'
            ? this.createTableField('printsCopiesMonth')
            : this.createTableField('printCopies', '', 0);
    }
    getsharedOutdoor() {
        return this.createTableField('sharedOutdoor');
    }
    getMainHandling() {
        return this.createTableField('mainHandling');
    }
    getparkingAvailable() {
        return this.createTableField('parkingAvailable');
    }
    getIncludesOfficeTableField() {
        return this.createTableField('includesOffice');
    }
    createTableField(fieldName, unit, decimals) {
        const field = {
            name: fieldName,
            translate: `spaceDetail.${fieldName}`,
            value: this.getPropertyValue(fieldName, unit, decimals)
        };
        return field;
    }
    getPropertyValue(propertyName, unitOfMeasure, decimals) {
        switch (propertyName) {
            case 'docks':
                return this.getFieldValue(this.space.docks, unitOfMeasure, decimals);
            case 'includesRetail':
                return this.getBooleanFieldValue(this.space.includesRetail);
            case 'cardParkingSpaces':
            case 'parkingSpaces':
                return this.getFieldValue(this.space.parkingSpaces, unitOfMeasure, decimals);
            case 'fullBuildOut':
                return this.getBooleanFieldValue(this.space.fullBuildOut);
            case 'truckParkingSpaces':
                return this.getFieldValue(this.space.truckParkingSpaces, unitOfMeasure, decimals);
            case 'directSublease':
                return this.commonService.getLeaseTypeTxt(this.space.industrialLeaseType);
            case 'loadCapacity':
                return this.getFieldValue(this.space.loadCapacity, unitOfMeasure, decimals);
            case 'R':
            case 'rentPerm2':
                return this.getFieldValue(this.space.monthlyAskingRent, unitOfMeasure, decimals);
            case 'skyLights':
                return this.getBooleanFieldValue(this.space.skyLights);
            case 'condo':
            case 'condoPerm2':
                return this.getFieldValue(this.space.monthlyCondo, unitOfMeasure, decimals);
            case 'ceilingHeight':
            case 'ceilingHeightOffice':
                return this.getFieldValue(this.space.ceilingHeight, unitOfMeasure, decimals);
            case 'iptu':
            case 'iptuPerm2':
                return this.getFieldValue(this.space.monthlyIptu, unitOfMeasure, decimals);
            case 'includesOffice':
                return this.getBooleanFieldValue(this.space.includesOffice);
            case 'coldStorage':
                return this.getBooleanFieldValue(this.space.coldStorage);
            case 'maxContiguous':
                return this.getFieldValue(this.space.maxContiguous, unitOfMeasure, decimals);
            case 'conferenceTimeHours':
                return this.getFieldValue(this.space.conferenceTimeHours, unitOfMeasure, decimals);
            case 'parkingCost':
                return this.getFieldValue(this.space.parkingCost, unitOfMeasure, decimals);
            case 'privateOutdoor':
                return this.getBooleanFieldValue(this.space.privateOutdoor);
            case 'printCopies':
                return this.getFieldValue(this.space.printCopies, unitOfMeasure, decimals);
            case 'sharedOutdoor':
                return this.getBooleanFieldValue(this.space.sharedOutdoor);
            case 'mainHandling':
                return this.getBooleanFieldValue(this.space.mailHandling);
            case 'parkingAvailable':
                return this.getBooleanFieldValue(this.space.parkingAvailable);
            case 'total':
                return this.getFieldValue(this.spacesService.getTotalMonthlyRent(this.space, true), unitOfMeasure, decimals);
            case 'conferenceCredits':
                return this.getFieldValue(this.space.conferenceTimeHours, unitOfMeasure, decimals);
            case 'privateOutdoorSpace':
                return this.getFieldValue(this.space.privateOutdoorCredits, unitOfMeasure, decimals);
            case 'printsCopiesMonth':
                return this.getFieldValue(this.space.printCopies, unitOfMeasure, decimals);
            default:
                return this.emptyFieldValue;
        }
    }
    toogleAction(action) {
        switch (action) {
            case 'moreInfo':
                this.showMoreInfo = this.showMoreInfo ? this.showMoreInfo : !this.showMoreInfo;
                this.showSpaceImgs = false;
                this.showMediaKit = false;
                break;
            case 'spaceImgs':
                this.showMoreInfo = false;
                this.showSpaceImgs = this.showSpaceImgs ? this.showSpaceImgs : !this.showSpaceImgs;
                this.showMediaKit = false;
                break;
            case 'mediaKit':
                this.showMoreInfo = false;
                this.showSpaceImgs = false;
                this.showMediaKit = this.showMediaKit ? this.showMediaKit : !this.showMediaKit;
                break;
        }
    }
    findFloorTranslation(floorValue) {
        if (!floorValue) {
            return '';
        }
        let translationKey = null;
        if (this.isMezzanine(floorValue)) {
            translationKey = 'buildinsDetail.mezzanine';
        }
        else if (this.isGroundFloor(floorValue)) {
            translationKey = 'buildinsDetail.groundfloor';
        }
        else if (this.isRooftop(floorValue)) {
            translationKey = 'buildinsDetail.rooftop';
        }
        if (translationKey != null) {
            return this.i18n.get(translationKey);
        }
        return floorValue;
    }
    isMezzanine(floor) {
        return this.isEqualsTo(floor, ['Mezzanine', 'Mezanino']);
    }
    isGroundFloor(floor) {
        return this.isEqualsTo(floor, ['Ground floor', 'Térreo', 'Terreo']);
    }
    isRooftop(floor) {
        return this.isEqualsTo(floor, ['Terraço', 'Rooftop']);
    }
    isEqualsTo(floor, customFloor) {
        return floor && customFloor.includes(floor);
    }
}
