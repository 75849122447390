import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbOptions } from '@app/shared';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountService } from '@app/core/auth';
import { MatDialog } from '@angular/material';
import { UserProfile } from '@app/models/user-account.model';
import { ImageService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { MyListingMenuComponent } from '@app/shared/my-listing-menu/my-listing-menu.component';
import { MyMessageService } from './my-message/my-message.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';

@Component({
  selector: 'user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit, OnDestroy {
  spotAdmin: Boolean;
  constructor(
    private accountService: AccountService,
    private commonService: CommonGenericService,
    private imageService: ImageService,
    private router: Router,
    private dialog: MatDialog,
    private myMessageService: MyMessageService,
    private socketIO: SocketIOService,
    private userActivityService: UserActivityService
  ) {
    this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
  }

  currentUser: any;
  imagePlaceholder: string = 'assets/images/default-profile-photo.png';
  userAccountInfo: any = {};
  dialogRef: any;
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: true,
    usePlatformLocation: true
  };
  userAccountSubscription: Subscription;

  private async getUserProfile(userAccount: any) {
    const userPhotos: any = await this.accountService.getUserPhotoList().toPromise();
    const image = this.accountService.getUploadedImageId(userPhotos);
    this.userAccountInfo = {
      profile: userAccount,
      profileImage: this.getMediumImg(image.profileImageId),
      originalImage: this.getMediumImg(image.originalImageId)
    };
  }

  ngOnInit() {
    this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_USER_HOME_PAGE);
    this.userAccountSubscription = this.accountService.currentUser$.subscribe(userAccount => {
      this.getUserProfile(userAccount);
    });
  }

  openListingMenu(data?: any) {
    this.dialogRef = this.dialog.open(MyListingMenuComponent, {
      height: 'auto',
      width: '550px',
      panelClass: 'padding-unset'
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  ngOnDestroy() {
    if (this.userAccountSubscription) {
      this.userAccountSubscription.unsubscribe();
    }
  }

  goToUserPage(pageRoute: string) {
    this.router.navigateByUrl(`${pageRoute}`);
  }

  getUnreadMessages() {
    //TODO: Replace with something from chat module
    return this.myMessageService.getUnreadMessages();
  }

  navigateToPurchase() {
    this.router.navigateByUrl('/list-property');
  }

  navigateToRegister() {
    this.router.navigateByUrl('/user-home/current-plans');
  }

  checkRole(role: string) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }
}
