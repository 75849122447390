<ion-row>
  <!-- <ion-col size="7" style="padding-left: 16px;">
    <span>Advanced filters</span>
  </ion-col> -->
  <ion-col size="12" style="text-align: end;padding-right: 14px;">
    <img
      class="close-icon"
      style="width: 14px;height: 14px;cursor: pointer;"
      (click)="cancel()"
      src="assets/images/icons/close.png"
      alt=""
    />
  </ion-col>
</ion-row>

<ion-grid class="overflow" *ngIf="!loading" #modalContent>
  <ion-row class="ml-3">
    <ion-col size="12" style="color: #043650;">
      <span class="font-title">{{ 'buildinsDetail.searchTypeTitle' | translate }}</span>
    </ion-col>
  </ion-row>
  <div class="col-md-12">
    <ion-row class="row-border-bottom marginRow"></ion-row>
  </div>

  <ion-row class="ml-3">
    <ion-col size="12" sizeMd="4">
      <span class="font-properties d-inline-block mb-2">{{ 'buildinsDetail.propertyType' | translate }}</span>
      <div ngbDropdown class="d-inline-block w-100">
        <button class="form-control" id="dropdownBasic1" ngbDropdownToggle style="text-align: left;height:44px">
          <span *ngIf="searchDto.buildingTypes[0] == '1001' && searchDto.listingLeaseOrSale[0] != 'C'">{{
            'global.industrial' | translate
          }}</span>
          <span *ngIf="searchDto.buildingTypes[0] == '2001' && searchDto.listingLeaseOrSale[0] != 'C'">{{
            'global.office' | translate
          }}</span>
          <span *ngIf="searchDto.listingLeaseOrSale[0] == 'C'">{{
            'global.list-your-property.coworking' | translate
          }}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button class="form-control" (click)="setBuildinType(1001)" ngbDropdownItem>
            {{ 'global.industrial' | translate }}
          </button>
          <button class="form-control" (click)="setBuildinType(2001)" ngbDropdownItem>
            {{ 'global.office' | translate }}
          </button>
          <button
            class="form-control"
            *ngIf="searchDto.listingLeaseOrSale[0] == 'L' || searchDto.listingLeaseOrSale[0] == 'C'"
            (click)="setListingLeaseOrSale('C')"
            ngbDropdownItem
          >
            {{ 'global.list-your-property.coworking' | translate }}
          </button>
        </div>
      </div>
    </ion-col>
    <ion-col size="12" sizeMd="4" *ngIf="searchDto.buildingTypes[0] == '1001'">
      <span class="font-properties d-inline-block mb-2">{{ 'buildinsDetail.propertySub' | translate }}</span>
      <div ngbDropdown class="d-inline-block w-100">
        <button class="form-control" id="dropdownBasic2" ngbDropdownToggle style="text-align: left;height:44px">
          <span *ngIf="searchDto.propertySubTypes.length > 0 && searchDto.propertySubTypes[0].id == 1001">{{
            'buildinsDetail.industrialComplex' | translate
          }}</span>
          <span *ngIf="searchDto.propertySubTypes.length > 0 && searchDto.propertySubTypes[0].id == 1002">{{
            'buildinsDetail.standAlone' | translate
          }}</span>
          <span *ngIf="searchDto.propertySubTypes.length == 0"
            >{{ 'buildinsDetail.propertySubText' | translate }}
          </span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <button class="form-control" (click)="setPropertySubTypes(null)" ngbDropdownItem>
            {{ 'buildinsDetail.propertySubText' | translate }}
          </button>
          <button class="form-control" (click)="setPropertySubTypes(1001)" ngbDropdownItem>
            {{ 'buildinsDetail.industrialComplex' | translate }}
          </button>
          <button class="form-control" (click)="setPropertySubTypes(1002)" ngbDropdownItem>
            {{ 'buildinsDetail.standAlone' | translate }}
          </button>
        </div>
      </div>
    </ion-col>
    <ion-col size="12" sizeMd="4" *ngIf="searchDto.listingLeaseOrSale[0] != 'C'">
      <span class="font-properties d-inline-block mb-2">{{ 'buildinsDetail.listingType' | translate }}</span>
      <div ngbDropdown class="d-inline-block w-100">
        <button class="form-control" id="dropdownBasic3" ngbDropdownToggle style="text-align: left;height:44px">
          <span *ngIf="searchDto.listingLeaseOrSale[0] == 'S'">{{ 'buildinsDetail.sale' | translate }}</span>
          <span *ngIf="searchDto.listingLeaseOrSale[0] == 'L'">{{ 'buildinsDetail.lease' | translate }}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
          <button class="form-control" (click)="setListingLeaseOrSale('S')" ngbDropdownItem>
            {{ 'buildinsDetail.sale' | translate }}
          </button>
          <button class="form-control" (click)="setListingLeaseOrSale('L')" ngbDropdownItem>
            {{ 'buildinsDetail.lease' | translate }}
          </button>
        </div>
      </div>
    </ion-col>

    <ion-col size="12" sizeMd="4" *ngIf="searchDto.listingLeaseOrSale[0] == 'C'">
      <span class="font-properties d-inline-block mb-2">{{ 'buildinsDetail.availabilityDate' | translate }}</span>
      <div class="d-inline-block w-100">
        <input [(ngModel)]="searchDto.availabilityDate" class="form-control" type="date" />
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="ml-3">
    <ion-col size="12" sizeMd="4">
      <span class="font-properties d-inline-block mb-2">{{ 'buildinsDetail.propertyFilter' | translate }}</span>
      <ion-row class="search-row">
        <ion-searchbar
          class="p-0"
          type="text"
          [(ngModel)]="inputBuilding"
          (ionChange)="changeFuzzySearch()"
          debounce="500"
          showCancelButton="never"
          spellcheck="false"
          placeholder="{{ 'buildinsDetail.search' | translate }}"
          #searchbar
          style="height: 35px;"
        >
        </ion-searchbar>
      </ion-row>
      <ion-row style="position: absolute;z-index: 2;">
        <ion-list class="list-flow " *ngIf="propertySearch && propertySearch.length">
          <ion-item
            href=""
            *ngFor="let building of propertySearch; let index = index"
            style="cursor: pointer;"
            (click)="setProperty(building)"
            localize
            inset="true"
            lines="full"
          >
            <div style="text-transform: none;" [innerHTML]="building.name"></div>
          </ion-item>
        </ion-list>
      </ion-row>
      <ion-row *ngIf="selectedProperty && selectedProperty.length">
        <ion-col size="12">
          <span class="font-title">{{ 'buildinsDetail.searchCriteria' | translate }}</span>
        </ion-col>
        <span class="propertyButton ellipsis-span" *ngFor="let property of selectedProperty; let index = index">
          {{ property.name }}
          <ion-icon
            class="deleteProperty ellipsis-span"
            style="position: absolute;margin-top: 5px;"
            (click)="removeProperty(index)"
            name="close"
          ></ion-icon>
        </span>

        <ion-button
          style="--border-radius: 2em;margin-right: 10px;height: 28px;
        margin-top: 6px;"
          color="secondary"
          [ngClass]="{ selectedBtn: searchDto.bicycleRack }"
          fill="outline"
          (click)="clearProperties()"
        >
          <span [ngClass]="{ white: searchDto.bicycleRack }">{{ 'buildinsDetail.clearSearch' | translate }}</span>
        </ion-button>
      </ion-row>
    </ion-col>
    <ion-col size="12" sizeMd="4">
      <span class="font-properties d-inline-block mb-2">{{ 'buildinsDetail.state' | translate }}</span>
      <div ngbDropdown class="d-inline-block w-100">
        <button
          [disabled]="selectedProperty.length > 0"
          class="form-control"
          id="dropdownBasic4"
          ngbDropdownToggle
          style="text-align: left;height:44px"
        >
          <span *ngIf="stateName == ''">{{ 'buildinsDetail.selectState' | translate }}</span>
          <span *ngIf="stateName != ''">{{ stateName }}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic4">
          <button *ngFor="let state of states" class="form-control" (click)="getcitiesByState(state)" ngbDropdownItem>
            {{ i18NService.getTranslation(state.name) }}
          </button>
        </div>
      </div>
    </ion-col>
    <ion-col size="12" sizeMd="4">
      <span class="font-properties d-inline-block mb-2">{{ 'buildinsDetail.city' | translate }}</span>
      <div ngbDropdown class="d-inline-block w-100">
        <button
          [disabled]="!searchDto.stateId"
          class="form-control"
          id="dropdownBasic4"
          ngbDropdownToggle
          style="text-align: left;height:44px"
        >
          <span *ngIf="cityName == ''">{{ 'buildinsDetail.selectCity' | translate }}</span>
          <span *ngIf="cityName != ''">{{ cityName }}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic4">
          <button *ngFor="let city of cities" class="form-control" (click)="setCity(city)" ngbDropdownItem>
            {{ i18NService.getTranslation(city.name) }}
          </button>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="ml-3" *ngIf="isAdmin">
    <ion-col size="12" sizeMd="4" *ngIf="searchDto.listingLeaseOrSale[0] != 'C'">
      <span class="font-properties d-inline-block mb-2">{{ 'buildinsDetail.listingStatus' | translate }}</span>
      <div ngbDropdown class="d-inline-block w-100">
        <button class="form-control" id="dropdownBasic99" ngbDropdownToggle style="text-align: left;height:44px">
          <span *ngIf="searchDto.spotStatus == 'ACTIVE'">{{ 'buildinsDetail.active' | translate }}</span>
          <span *ngIf="searchDto.spotStatus == 'INACTIVE'">{{ 'buildinsDetail.inactive' | translate }}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic99">
          <button class="form-control" (click)="setActiveFilter(true)" ngbDropdownItem>
            {{ 'buildinsDetail.active' | translate }}
          </button>
          <button class="form-control" (click)="setActiveFilter(false)" ngbDropdownItem>
            {{ 'buildinsDetail.inactive' | translate }}
          </button>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="ml-3" style="margin-bottom: 10px;">
    <ion-col size="12" style="color: #043650;">
      <span class="font-title">{{ 'buildinsDetail.moreFilters' | translate }}</span>
    </ion-col>
  </ion-row>
  <div class="col-md-12 mb-2">
    <ion-row class="row-border-bottom marginRow"></ion-row>
  </div>

  <app-filter-ranges-spotbuildings
    [checkboxValues]="checkboxValues"
    [staticMinMax]="staticMinMax"
    [rangeModelValues]="rangeModelValues"
    [searchDto]="searchDto"
    [type]="searchDto.listingLeaseOrSale"
    [property]="searchDto.buildingTypes"
    [clear]="clear"
  >
  </app-filter-ranges-spotbuildings>

  <ion-row *ngIf="searchDto.listingLeaseOrSale[0] == 'C'" class="ml-3" style="margin-bottom: 10px;margin-top: -18px;">
    <ion-col size="12" style="color: #043650;">
      <span class="font-title">{{ 'buildinsDetail.spaceType' | translate }}</span>
    </ion-col>
  </ion-row>
  <div *ngIf="searchDto.listingLeaseOrSale[0] == 'C'" class="col-md-12">
    <ion-row class="row-border-bottom marginRow"></ion-row>
  </div>

  <ion-row *ngIf="searchDto.listingLeaseOrSale[0] == 'C'" class="row">
    <ion-col class="coworking_width">
      <div class="col-md-3" style="float: left;">
        <div
          class="coworking_filter width_coworking"
          (click)="coworkingFilter(1)"
          [ngClass]="{ coworking_filter2: filter == 1 }"
        >
          <div class="col-md-12">
            <img src="assets/images/privateOffice.png" alt="" />
          </div>
          <div class="col-md-12 text-center">
            <span>{{ 'buildinsDetail.privateOffice' | translate }} </span>
          </div>
        </div>
      </div>
      <div class="col-md-3" style="float: left;">
        <div
          class="coworking_filter width_coworking"
          (click)="coworkingFilter(2)"
          [ngClass]="{ coworking_filter2: filter == 2 }"
        >
          <div class="col-md-12">
            <img src="assets/images/delicatedWorkspace.png" alt="" />
          </div>
          <div class="col-md-12 text-center">
            <span>{{ 'buildinsDetail.dedicatedWorkspace' | translate }} </span>
          </div>
        </div>
      </div>
      <div class="col-md-3" style="float: left;">
        <div
          class="coworking_filter width_coworking"
          (click)="coworkingFilter(3)"
          [ngClass]="{ coworking_filter2: filter == 3 }"
        >
          <div class="col-md-12">
            <img src="assets/images/openWorkspace.png" alt="" />
          </div>
          <div class="col-md-12 text-center">
            <span>{{ 'buildinsDetail.openWorkspace' | translate }} </span>
          </div>
        </div>
      </div>
      <div class="col-md-3" style="float: left;">
        <div
          class="coworking_filter width_coworking"
          (click)="coworkingFilter(4)"
          [ngClass]="{ coworking_filter2: filter == 4 }"
        >
          <div class="col-md-12">
            <img src="assets/images/fullFloorOffices.png" alt="" />
          </div>
          <div class="col-md-12 text-center">
            <span>{{ 'buildinsDetail.fullFloorOffices' | translate }} </span>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="ml-3" style="margin-bottom: 10px;margin-top: -18px;">
    <ion-col size="12" style="color: #043650;">
      <span class="font-title">{{ 'buildinsDetail.features' | translate }}</span>
    </ion-col>
  </ion-row>
  <div class="col-md-12">
    <ion-row class="row-border-bottom marginRow"></ion-row>
  </div>

  <ion-row class="ml-3">
    <ion-label class="font-properties" style="width: 100%; margin-top: 15px;margin-bottom: 10px;font-size: 14px;">
      {{ 'buildinsDetail.generalFeatures' | translate }}</ion-label
    >
    <br />
    <ion-button
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.twentyFourHourSecurity }"
      fill="outline"
      (click)="searchDto.twentyFourHourSecurity = !searchDto.twentyFourHourSecurity"
    >
      <span
        [ngClass]="{ white: searchDto.twentyFourHourSecurity }"
        translate="global.addtSearchFilters.twentyFourHourSecurity"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.bulletProofCabin }"
      fill="outline"
      (click)="searchDto.bulletProofCabin = !searchDto.bulletProofCabin"
    >
      <span [ngClass]="{ white: searchDto.bulletProofCabin }" translate="global.addtSearchFilters.armoredCabin"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.sluiceGate }"
      fill="outline"
      (click)="searchDto.sluiceGate = !searchDto.sluiceGate"
    >
      <span [ngClass]="{ white: searchDto.sluiceGate }" translate="global.addtSearchFilters.sluiceGate"></span>
    </ion-button>

    <ion-button
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.sprinklers }"
      fill="outline"
      (click)="searchDto.sprinklers = !searchDto.sprinklers"
    >
      <span [ngClass]="{ white: searchDto.sprinklers }" translate="global.addtSearchFilters.sprinklers"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '2001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.centralAirConditioning }"
      fill="outline"
      (click)="searchDto.centralAirConditioning = !searchDto.centralAirConditioning"
    >
      <span
        [ngClass]="{ white: searchDto.centralAirConditioning }"
        translate="global.addtSearchFilters.centralAirConditioning"
      ></span>
    </ion-button>

    <ion-button
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.electricGenerator }"
      fill="outline"
      (click)="searchDto.electricGenerator = !searchDto.electricGenerator"
    >
      <span
        [ngClass]="{ white: searchDto.electricGenerator }"
        translate="global.addtSearchFilters.electricGenerator"
      ></span>
    </ion-button>

    <ion-button
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.leedStatus }"
      fill="outline"
      (click)="searchDto.leedStatus = !searchDto.leedStatus"
    >
      <span [ngClass]="{ white: searchDto.leedStatus }" translate="global.addtSearchFilters.sustentability"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] != 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.heliport }"
      fill="outline"
      (click)="searchDto.heliport = !searchDto.heliport"
    >
      <span [ngClass]="{ white: searchDto.heliport }" translate="global.addtSearchFilters.heliport"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.truckYard }"
      fill="outline"
      (click)="searchDto.truckYard = !searchDto.truckYard"
    >
      <span [ngClass]="{ white: searchDto.truckYard }" translate="global.addtSearchFilters.truckYard"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] != 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.singleTenant }"
      fill="outline"
      (click)="searchDto.singleTenant = !searchDto.singleTenant"
    >
      <span [ngClass]="{ white: searchDto.singleTenant }" translate="global.addtSearchFilters.singleTenant"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '2001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.bicycleRack }"
      fill="outline"
      (click)="searchDto.bicycleRack = !searchDto.bicycleRack"
    >
      <span [ngClass]="{ white: searchDto.bicycleRack }" translate="global.addtSearchFilters.bicycleRack"></span>
    </ion-button>

    <ion-button
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.fiberOptic }"
      fill="outline"
      (click)="searchDto.fiberOptic = !searchDto.fiberOptic"
    >
      <span [ngClass]="{ white: searchDto.fiberOptic }" translate="global.addtSearchFilters.fiberOptic"></span>
    </ion-button>
  </ion-row>

  <ion-row class="ml-3">
    <ion-label class="font-properties" style=" width: 100%;margin-top: 15px;margin-bottom: 10px;font-size: 14px;">
      {{ 'buildinsDetail.spotFeatures' | translate }}
    </ion-label>
    <br />
    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.blockAndSteelConstruction }"
      fill="outline"
      (click)="searchDto.blockAndSteelConstruction = !searchDto.blockAndSteelConstruction"
    >
      <span
        [ngClass]="{ white: searchDto.blockAndSteelConstruction }"
        translate="global.addtSearchFilters.blockAndSteelConstruction"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.steelConstruction }"
      fill="outline"
      (click)="searchDto.steelConstruction = !searchDto.steelConstruction"
    >
      <span
        [ngClass]="{ white: searchDto.steelConstruction }"
        translate="global.addtSearchFilters.steelConstruction"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.logisticsCondition }"
      fill="outline"
      (click)="searchDto.logisticsCondition = !searchDto.logisticsCondition"
    >
      <span [ngClass]="{ white: searchDto.logisticsCondition }" translate="global.addtSearchFilters.logistics"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.manufacturingCondition }"
      fill="outline"
      (click)="searchDto.manufacturingCondition = !searchDto.manufacturingCondition"
    >
      <span
        [ngClass]="{ white: searchDto.manufacturingCondition }"
        translate="global.addtSearchFilters.manufacturing"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.flexCondition }"
      fill="outline"
      (click)="searchDto.flexCondition = !searchDto.flexCondition"
    >
      <span [ngClass]="{ white: searchDto.flexCondition }" translate="global.addtSearchFilters.flex"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001' && searchDto.listingLeaseOrSale[0] == 'L'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.forRetailUseConditionIndustrial }"
      fill="outline"
      (click)="searchDto.forRetailUseConditionIndustrial = !searchDto.forRetailUseConditionIndustrial"
    >
      <span
        [ngClass]="{ white: searchDto.forRetailUseConditionIndustrial }"
        translate="global.addtSearchFilters.forRetail"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.datacenterCondition }"
      fill="outline"
      (click)="searchDto.datacenterCondition = !searchDto.datacenterCondition"
    >
      <span [ngClass]="{ white: searchDto.datacenterCondition }" translate="global.addtSearchFilters.dataCenter"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.includeColdStorage }"
      fill="outline"
      (click)="searchDto.includeColdStorage = !searchDto.includeColdStorage"
    >
      <span
        [ngClass]="{ white: searchDto.includeColdStorage }"
        translate="global.addtSearchFilters.includeStorage"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.skyLightsSpace }"
      fill="outline"
      (click)="searchDto.skyLightsSpace = !searchDto.skyLightsSpace"
    >
      <span [ngClass]="{ white: searchDto.skyLightsSpace }" translate="global.addtSearchFilters.skyLights"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '1001'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.crossDocking }"
      fill="outline"
      (click)="searchDto.crossDocking = !searchDto.crossDocking"
    >
      <span [ngClass]="{ white: searchDto.crossDocking }" translate="global.addtSearchFilters.crossDocking"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '2001' && searchDto.listingLeaseOrSale[0] != 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.coreAndShellCondition }"
      fill="outline"
      (click)="searchDto.coreAndShellCondition = !searchDto.coreAndShellCondition"
    >
      <span
        [ngClass]="{ white: searchDto.coreAndShellCondition }"
        translate="global.addtSearchFilters.coreAndShel"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '2001' && searchDto.listingLeaseOrSale[0] != 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.preBuildCondition }"
      fill="outline"
      (click)="searchDto.preBuildCondition = !searchDto.preBuildCondition"
    >
      <span [ngClass]="{ white: searchDto.preBuildCondition }" translate="global.addtSearchFilters.preBuild"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '2001' && searchDto.listingLeaseOrSale[0] != 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.plugAndPlayConditionOffice }"
      fill="outline"
      (click)="searchDto.plugAndPlayConditionOffice = !searchDto.plugAndPlayConditionOffice"
    >
      <span
        [ngClass]="{ white: searchDto.plugAndPlayConditionOffice }"
        translate="global.addtSearchFilters.plugAndPlay"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '2001' && searchDto.listingLeaseOrSale[0] != 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.fullFloorCondition }"
      fill="outline"
      (click)="searchDto.fullFloorCondition = !searchDto.fullFloorCondition"
    >
      <span [ngClass]="{ white: searchDto.fullFloorCondition }" translate="global.addtSearchFilters.fullFloor"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '2001' && searchDto.listingLeaseOrSale[0] != 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.forRetailUseConditionOffice }"
      fill="outline"
      (click)="searchDto.forRetailUseConditionOffice = !searchDto.forRetailUseConditionOffice"
    >
      <span
        [ngClass]="{ white: searchDto.forRetailUseConditionOffice }"
        translate="global.addtSearchFilters.forRetail"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.buildingTypes[0] == '2001' && searchDto.listingLeaseOrSale[0] == 'L'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.subLease }"
      fill="outline"
      (click)="searchDto.subLease = !searchDto.subLease"
    >
      <span [ngClass]="{ white: searchDto.subLease }" translate="global.addtSearchFilters.subLease"></span>
    </ion-button>
    <!--  coworking features -->
    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] == 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.furnishedConditionCoworking }"
      fill="outline"
      (click)="searchDto.furnishedConditionCoworking = !searchDto.furnishedConditionCoworking"
    >
      <span
        [ngClass]="{ white: searchDto.furnishedConditionCoworking }"
        translate="global.addtSearchFilters.furnished"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] == 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.plugAndPlayConditionCoworking }"
      fill="outline"
      (click)="searchDto.plugAndPlayConditionCoworking = !searchDto.plugAndPlayConditionCoworking"
    >
      <span
        [ngClass]="{ white: searchDto.plugAndPlayConditionCoworking }"
        translate="global.addtSearchFilters.plugAndPlay"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] == 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.conferenceRoom }"
      fill="outline"
      (click)="searchDto.conferenceRoom = !searchDto.conferenceRoom"
    >
      <span [ngClass]="{ white: searchDto.conferenceRoom }" translate="global.addtSearchFilters.conferenceRoom"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] == 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.privateOutdoorSpace }"
      fill="outline"
      (click)="searchDto.privateOutdoorSpace = !searchDto.privateOutdoorSpace"
    >
      <span
        [ngClass]="{ white: searchDto.privateOutdoorSpace }"
        translate="global.addtSearchFilters.privateOutdoorSpace"
      ></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] == 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.parkingAvailable }"
      fill="outline"
      (click)="searchDto.parkingAvailable = !searchDto.parkingAvailable"
    >
      <span [ngClass]="{ white: searchDto.parkingAvailable }" translate="global.addtSearchFilters.parking"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] == 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.petFriendly }"
      fill="outline"
      (click)="searchDto.petFriendly = !searchDto.petFriendly"
    >
      <span [ngClass]="{ white: searchDto.petFriendly }" translate="global.addtSearchFilters.petFriendly"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] == 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.barista }"
      fill="outline"
      (click)="searchDto.barista = !searchDto.barista"
    >
      <span [ngClass]="{ white: searchDto.barista }" translate="global.addtSearchFilters.barista"></span>
    </ion-button>

    <ion-button
      *ngIf="searchDto.listingLeaseOrSale[0] == 'C'"
      class="btn-features"
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.enhancedCleaning }"
      fill="outline"
      (click)="searchDto.enhancedCleaning = !searchDto.enhancedCleaning"
    >
      <span
        [ngClass]="{ white: searchDto.enhancedCleaning }"
        translate="global.addtSearchFilters.enhancedCleaning"
      ></span>
    </ion-button>
  </ion-row>

  <app-scroll-to-top
    [showComponent]="true"
    [displayBlock]="true"
    (scroll)="scrollToTop()"
    [target]="modalContent"
    scroller
  >
  </app-scroll-to-top>
</ion-grid>

<ion-row class="fixed-ion-btnrow" *ngIf="!loading" style=" background-color: #F6F7F8 !important;">
  <ion-col size="12">
    <ion-button
      (click)="dismiss()"
      class="main-filter-height search-filter main-filter-radius float-right btn-color-2"
      id="searchMainFilter"
      style="width: 132px;"
    >
      <div class="location-label-search">
        {{ 'global.menu.search' | translate }}
      </div>
    </ion-button>

    <ion-button
      style="margin-right: 10px;"
      color="secondary"
      class="float-right btn-color-1"
      fill="outline"
      (click)="clearSearchExternal()"
    >
      <span translate="buildinsDetail.clear"></span>
    </ion-button>
  </ion-col>
</ion-row>
