import { Component, OnInit, Injector, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { state } from '@angular/animations';
import { ListingService } from '@app/core/listings/listing.service';
import { ListingSearchSingleOption } from '@app/search-navigation/search-navigation-options';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';

@Component({
  selector: 'app-my-listing-menu',
  templateUrl: './my-listing-menu.component.html',
  styleUrls: ['./my-listing-menu.component.scss']
})
export class MyListingMenuComponent implements OnInit {
  spotAdmin: Boolean;
  constructor(
    private data: MatDialogRef<any>,
    private dialog: MatDialog,
    private router: Router,
    private listingService: ListingService,
    private userActivityService: UserActivityService
  ) {
    this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
  }

  ngOnInit(): void {
    this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_MYLISTINGS_PAGE);
  }

  dismissAsDialog() {
    this.dialog.closeAll();
  }

  navigateToPurchase() {
    this.dialog.closeAll();
    this.router.navigateByUrl('/list-property');
  }

  navigateToRegister() {
    this.dialog.closeAll();
    this.router.navigateByUrl('/user-home/current-plans');
  }

  navigateToListings() {
    this.dialog.closeAll();
    this.listingService.updateModel('destroy');
    this.router.navigateByUrl('user-home/my-listings');
  }

  checkRole(role: string) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }
}
