import env from './.env';

export const environment = {
  production: true,
  version: env.npm_package_version,
  serverUrl: 'https://stg.siila.com.br',
  spotServerUrl: 'https://stg.siilaspot.com.br',
  tdmessageUrl: 'https://stgapp.siila.com.br:8911',
  sockJs: 'https://stgapp.siila.com.br/chat',
  ws: 'wss://44i6e0stm6.execute-api.sa-east-1.amazonaws.com/stg',
  requirePassword: false,
  syncEnabled: false,
  defaultLanguage: 'pt-BR',
  supportedLanguages: ['en-US', 'pt-BR'],
  analyticsTracking: 'UA-144262831-2',
  apiURL: '/api',
  publicApiKeyPaylee: 'BeWT3k/vypkk+pbCv0DR43TM7Lb33GAY5hB2z/yRoa4=',
  baseTrackingID: '',
  agentWhatsAppTrackingID: 'UA-144262831-2',
  agentEmailTrackingID: 'UA-144262831-2',
  agentPhoneTrackingID: 'UA-144262831-2',
  installAppTrackingID: 'UA-144262831-2',
  sendEmailTrackingID: 'UA-144262831-2',
  getStartedUserRegistrationID: 'UA-144262831-2',
  googleAPIKey: 'AIzaSyD7ILit-WpiTauIV0bdY2OpeSGnNfA9jXE',
  serverBaseUrl: 'https://stgapp.siila.com.br/#!/',
  matomoSiteId: '1',
  firebaseConfig: {
    apiKey: 'AIzaSyAMg44gjwyFXIUT9H2_1YPp7CyKM4I0pM4',
    authDomain: 'reix-firebase.firebaseapp.com',
    projectId: 'reix-firebase',
    storageBucket: 'reix-firebase.appspot.com',
    messagingSenderId: '188092070535',
    appId: '1:188092070535:web:482df9481f78cebcae9622',
    measurementId: 'G-8XLC44BLF6'
  },
  platformId: 'br',
  cloudfrontURL: 'https://dnclcgcvl4f14.cloudfront.net/'
};
