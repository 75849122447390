<ion-header class="header">
  <ion-toolbar color="white" style="background-color: white;">
    <ion-buttons slot="start">
      <ion-button fill="solid" (click)="toggleSideMenu()">
        <ion-icon name="menu"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons [ngClass]="{ 'justify-left': isAppInstalled() }" style="display: contents;">
      <a id="home-image-logo" [routerLink]="goToHomePage()">
        <img style="height: 24px;" class="brand-img" (click)="changeBorder()" src="assets/logoSpot.png" />
      </a>
    </ion-buttons>

    <ion-buttons slot="end" style="width: 62%;">
      <div class="header__buttons-container" style="width: 100%;">
        <div class="col-md-7" style="padding-left: 0px;" *ngIf="loadUpdate" (click)="loadNewUpdate()">
          <div class="newVwesion">
            <div class="divAsync">
              <fa-icon class="iconAsync" [icon]="faSyncAlt"> </fa-icon>
            </div>
            <div>
              <h1 class="h1NewVersion">
                {{ 'global.newVersion' | translate }}
              </h1>
              <h1 class="h1launchVersion">
                {{ 'global.launchVersion' | translate }}
              </h1>
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          [ngClass]="{
            alingMobile: !showH1HomeTitle(),
            aling: showH1HomeTitle(),
            loadUpdateMobile: !showH1HomeTitle() && loadUpdate
          }"
          style="margin-top: 10px;"
        >
          <span
            *ngIf="!userName && !isMobilePhone"
            (click)="openLoginDialog()"
            style="color:#00334d
          ;"
            ><b>{{ 'login' | translate }}</b>
          </span>
          <ion-button
            *ngIf="!userName && !checkRoute() && !isMobilePhone"
            (click)="navigateToRegister()"
            class="btn-color-2"
            style="--background-hover:#00334d !important; --border-radius: 4px;margin-top: -10px;margin-right: 8px;white-space: nowrap;"
          >
            {{ 'createAccount' | translate }}
          </ion-button>

          <span
            *ngIf="!isIos"
            style="color:#00334d
          ;font-weight: 700;"
            (click)="promptInstallPWA()"
            >{{ 'global.menu.installApp' | translate }}</span
          >

          <span
            (click)="promptIos()"
            *ngIf="!isStandAlone() && isIos"
            style="color:#00334d
          ;font-weight: 700;"
            >{{ 'global.menu.installApp' | translate }}</span
          >

          <app-notification
            id="mobileAppNotification"
            *ngIf="!!userName && currentDevice != 'desktop'"
          ></app-notification>

          <div
            id="desktopAppNotification"
            *ngIf="!!userName && currentDevice == 'desktop'"
            style="float: right;display: -webkit-box;"
          >
            <app-notification></app-notification>

            <ion-avatar>
              <img
                style="width: 40px;
              height: 40px;
              margin: -10px 9px 3px 0px;"
                [src]="userAccount?.profileImage"
                default="{{ imagePlaceholder }}"
              />
            </ion-avatar>
            <span style="margin-top: 0px;" [matMenuTriggerFor]="menu"> {{ userName }}</span>
            <button class="btn-profile" mat-button [matMenuTriggerFor]="menu">
              <fa-icon style="font-size: 10px;" class="arrow-icon " [icon]="downArrow" size="lg"></fa-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="goToMyAccount()">
                <fa-icon class="top-icon" style=" margin-right: 10px;" [icon]="faUserCircle"></fa-icon
                >{{ 'global.myAccount' | translate }}
              </button>
              <button mat-menu-item (click)="goToFavorites()">
                <img
                  src="assets/icons/starDashboard.png"
                  style="width: 20px;margin-top: -8px;margin-left: -4px;"
                  alt=""
                />
                {{ 'global.list-your-property.dashboard' | translate }}
              </button>
              <button style="color: #DC3545" mat-menu-item (click)="logOut($event)">
                <img src="assets/icons/Logout.png" alt="" style="width: 15px;margin-right: 8px;" />
                {{ 'global.form.logOut' | translate }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
