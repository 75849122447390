import { Component, OnInit, ViewChild, ElementRef, ComponentRef, OnDestroy, AfterViewInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormGroup } from '@angular/forms';
import { MatStepper, MatDialog } from '@angular/material';
import { IonReorderGroup, ToastController, AlertController } from '@ionic/angular';
import { BreadcrumbOptions } from '@app/shared';
import { RegisterListingService } from '@app/core/register-listing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ListingService,
  ListingFormService,
  EventCheckerService,
  ListingCleanupService,
  ListingDetailService
} from '@app/core/listings';
import { ImageService, PurchaseCreditsService, I18nService } from '@app/core';
import { URLValidation } from './components/img-upload/img-upload.component';
import { UtilityService } from '@app/core/helpers/utility-services';

@Component({
  selector: 'app-create-listing',
  templateUrl: './create-listing.component.html',
  styleUrls: ['./create-listing.component.scss']
})
export class CreateListingComponent implements OnInit, OnDestroy, AfterViewInit {
  isMobile: boolean = false;
  previewLoaded: boolean = false;
  listingTypeForm: FormGroup;
  detailsForm: FormGroup;
  addressForm: FormGroup;
  moreDetailsForm: FormGroup;
  listingPriceForm: FormGroup;
  listingFeaturesForm: FormGroup;
  currentStep: any = 0;
  listing: any;
  dialogRef: any;
  imgList: any = [];
  uploadingImages: boolean = false;
  tagValues: any = [];
  subTypes: any = [];
  listType: any;
  listingType: any;
  previewListing: any;
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };
  listingPurchaseId: any;
  allCompanies: any;
  otherForms: any;
  building: any;
  filename: any;
  company: any;
  offeredByUserId: any;
  measurementStandards: any;
  vrTourUrl: any;
  listingClass: any;
  listingExpDate: any;
  industrialFilters: any;
  officeFilters: any;
  ref: ComponentRef<any>;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild(IonReorderGroup, { static: true }) reorderGroup: IonReorderGroup;
  @ViewChild('title', { static: false }) title: ElementRef;
  @ViewChild('description', { static: false }) description: ElementRef;
  titleFieldOptions: any;
  descriptionFieldOptions: any;
  coworkingInfo: any = [];
  maximumPositions: any = [];
  videoURLInvalid = false;
  spotAdmin: any;
  adminUpdate: boolean = false;
  skipDiscardChanges: boolean = false;
  fromMyListings: boolean;
  tempListing: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private registerListService: RegisterListingService,
    private router: Router,
    private listingService: ListingService,
    private registerService: RegisterService,
    private _ts: TranslateService,
    private imageService: ImageService,
    private listingFormService: ListingFormService,
    private _eventChecker: EventCheckerService,
    private toastCtrl: ToastController,
    private purchaseService: PurchaseCreditsService,
    private listingCleanupService: ListingCleanupService,
    private alertCtrl: AlertController,
    private i18nService: I18nService,
    private listingDetailsService: ListingDetailService,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
    this.getParams();
    this.multiLangInit();
    this.getAmenities();
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.skipDiscardChanges = false;
    this.detectFeatures();
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.currentStep = 0), 0);
  }

  public onStepperSelectionChange(evant: any) {
    this.scrollToSectionHook();
  }

  private scrollToSectionHook() {
    const element = document.querySelector('.stepperTop');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }, 75);
    }
  }

  goForward() {
    if (this.currentStep === 0) {
      this.listType = this.listingTypeForm.controls.commericalType.value;
    }
    setTimeout(() => {
      if (this.currentStep === 5 && !this.listing) {
        !this.previewListing ? this.setBuildingPreview() : this.setBuildingPreview(true, this.previewListing);
      } else if (this.currentStep === 5 && this.listing && this.listing.listingStatus.id === 4 && this.spotAdmin) {
        this.displayAlert();
      } else if (this.currentStep === 5 && this.listing && this.isUserAllowToEditPendingApprovalListing()) {
        this.fromMyListings = false;
        this.displayAlert();
      } else if (this.currentStep === 5 && this.listing) {
        this.setBuildingPreview(false, null, true);
      }
      this.stepper.next();
      this.currentStep = this.currentStep + 1;
    }, 0);
  }

  goBack() {
    this.stepper.previous();
    this.currentStep = this.currentStep - 1;
    this.previewLoaded = false;
    this.listingTypeForm.markAsDirty();
  }

  checkRole(role: string) {
    return this.listingCleanupService.checkRole(role);
  }

  async getParams() {
    let listingId = this.route.snapshot.paramMap.get('listingId');
    const navigation = this.router.getCurrentNavigation();
    let navigationState;
    if (navigation == undefined) {
      await this.getListing(listingId).then(val => {
        let state = { listing: this.tempListing, myListingPage: true, date: new Date().getTime() };
        navigationState = state;
      });
    } else {
      navigationState = navigation.extras.state;
    }

    let credit: any = navigationState && navigationState.credit ? navigationState.credit : null;
    this.listingPurchaseId = navigationState && navigationState.credit ? credit.id : 0;
    this.listingType = navigationState && navigationState.credit ? credit.premium : false;
    this.listing = navigationState && navigationState.listing ? navigationState.listing : null;
    this.listingClass = this.listing
      ? this.listing.listingType
      : navigationState && navigationState.credit && navigationState.credit.premium
      ? 'PREMIUM'
      : 'PROMOTED';
    this.fromMyListings = navigationState && navigationState.myListingPage ? navigationState.myListingPage : null;
    this.listingExpDate = navigationState && navigationState.credit ? credit.listingExpirationDate : null;
    this.offeredByUserId = navigationState && navigationState.offeredByUserId ? navigationState.offeredByUserId : null;
    this.company = navigationState && navigationState.company ? navigationState.company : null;
    this.listing ? (this.offeredByUserId = this.listing.offeredByUser) : null;
    this.listing && this.listing.offeredByUser ? (this.company = this.listing.offeredByUser.company) : null;

    if (this.listingPurchaseId === 0 && !this.listing) {
      this.router.navigateByUrl('/user-home');
    }

    this.listing ? this.initListing() : this.createInitForm();
    this.initCoworkingFields();
  }

  async getListing(listingId: any) {
    let listingDetail: any = await this.listingDetailsService.getListingDetails(listingId).toPromise();
    let listingPhotos: any = await this.listingDetailsService.getListingPhotos(listingId).toPromise();
    this.tempListing = listingDetail.listing;
    this.tempListing.listingPhotos = listingPhotos;
  }

  async initCoworkingFields() {
    let response: any = this.listingCleanupService.initCoworkingFields();
    this.coworkingInfo = response.coworkingInfo;
    this.maximumPositions = response.maximumPositions;
  }

  async setCoworkingFields() {
    let user: any = localStorage.getItem('user');
    if (this.listingPriceForm.controls['coworkingEntries'].value) {
      this.coworkingInfo = this.listingPriceForm.controls['coworkingEntries'].value;
      if (this.coworkingInfo.length === 0) {
        this.coworkingInfo = this.listingCleanupService.nullCoworkingFields();
      }
    }
    if (this.listingPriceForm.controls['coworkingMaxPositions'].value) {
      this.maximumPositions = [{ maximumPositions: this.listingPriceForm.controls['coworkingMaxPositions'].value }];
    }
  }

  public checkCowork() {
    return this.listingCleanupService.checkCowork(this.coworkingInfo);
  }

  async initListing() {
    this.building = await this.listingService.getBuildingToRevise(this.listing.building.id);
    await this.measurementS();
    this.listingClass = this.listing.listingType;
    this.listingExpDate = this.listing.endDate;
    this.reinitForms(this.listing, this.building);
  }

  async measurementS() {
    this.measurementStandards = await this.getMeasurementStands();
    this.measurementStandards = await this.getMeasurementTranslations(this.measurementStandards);
  }

  async getMeasurementTranslations(measurements: any) {
    Object.keys(measurements).forEach(async (key, idx) => {
      measurements[key].measurement = this.getI18nValue(measurements[key].measurement);
    });
    return measurements;
  }

  async getMeasurementStands() {
    return this.registerService.getMeasurementStandard().toPromise();
  }

  async setBuildingPreview(val?: any, previewListing?: any, revise?: any, admin?: any, toApprove?: boolean) {
    if (this.maximumPositions[0].maximumPositions && this.coworkingInfo) {
      this.listingPriceForm.controls['coworkingMaxPositions'].setValue(this.maximumPositions[0].maximumPositions);
      this.listingPriceForm.controls['coworkingEntries'].setValue(this.coworkingInfo);
    }
    if (this.addressForm && this.addressForm.value && this.addressForm.value.address) {
      const obj = JSON.parse(this.addressForm.value.address);
      if (obj['en']) {
        obj['en'] = this.utilityService.removeHTMLCodeFrom(obj['en']);
      }

      if (obj['pt-br']) {
        obj['pt-br'] = this.utilityService.removeHTMLCodeFrom(obj['pt-br']);
      }
      this.addressForm.value.address = JSON.stringify(obj);
    }

    let mergedForm = {
      ...this.listingTypeForm.value,
      ...this.detailsForm.value,
      ...this.addressForm.value,
      ...this.moreDetailsForm.value,
      ...this.listingPriceForm.value,
      ...this.listingFeaturesForm.value
    };
    //this.company
    mergedForm.vrTourUrl = this.vrTourUrl;
    let tags = { industrial: this.industrialFilters, office: this.officeFilters };
    if (!admin) {
      this.previewListing = await this.registerListService.formBuildingListing(
        mergedForm,
        this.offeredByUserId,
        val ? true : null,
        this.listingPurchaseId,
        this.listingTypeForm.value.subType,
        this.listingType,
        tags,
        this.imgList,
        previewListing ? previewListing : null,
        revise ? this.listing : null
      );
      if (this.previewListing) {
        this.imgList = this.previewListing.photos;
        setTimeout(() => {
          this.previewLoaded = true;
          this.listingTypeForm.markAsPristine();
          this.detailsForm.markAsPristine();
          this.addressForm.markAsPristine();
          this.moreDetailsForm.markAsPristine();
          this.listingPriceForm.markAsPristine();
          this.listingFeaturesForm.markAsPristine();
        }, 200);
      } else {
        this.goBack();
      }
    } else {
      try {
        this.saveListing(val, previewListing, revise, admin, toApprove);
      } catch (error) {
        this.showSaveErr();
      }
    }
  }

  async submitListing() {
    try {
      let res: any = await this.registerListService.updateToApproval(this.previewListing);
      if (res) {
        this.adminUpdate = true;
        this.previewLoaded = false;
        this.fromMyListings ? this.router.navigateByUrl('/user-home/my-listings') : this.navigateToDetailsPage(res.id);
      }
    } catch (error) {
      console.error('Error Submitting Listing: ', error);
    }
  }

  async approveListing() {
    let action: any = await this.purchaseService.approveListing(this.previewListing.id);
    if (action) {
      this.adminUpdate = true;
      this.previewLoaded = false;
      this._eventChecker.updateListing$.next('no-refresh');
      this.fromMyListings
        ? this.router.navigateByUrl('/user-home/my-listings')
        : this.navigateToDetailsPage(this.previewListing.id);
    }
  }

  async navigateToDetailsPage(listingId: any) {
    const detailsURL: string = await this.listingDetailsService.getListingDetailsURLFrom(listingId);
    this.router.navigateByUrl(detailsURL);
  }

  async saveListing(val?: any, previewListing?: any, revise?: any, admin?: any, toApprove?: boolean) {
    if (this.maximumPositions[0].maximumPositions && this.coworkingInfo) {
      this.listingPriceForm.controls['coworkingMaxPositions'].setValue(this.maximumPositions[0].maximumPositions);
      this.listingPriceForm.controls['coworkingEntries'].setValue(this.coworkingInfo);
    }
    //this.offeredByUserId = this.moreDetailsForm.value.offeredByUserId;
    let mergedForm = {
      ...this.listingTypeForm.value,
      ...this.detailsForm.value,
      ...this.addressForm.value,
      ...this.moreDetailsForm.value,
      ...this.listingPriceForm.value,
      //...this.company,
      ...this.listingFeaturesForm.value
    };
    mergedForm.vrTourUrl = this.vrTourUrl;
    let tags = { industrial: this.industrialFilters, office: this.officeFilters };
    let tempListing: any = await this.registerListService.formBuildingListing(
      mergedForm,
      this.offeredByUserId,
      val ? true : null,
      this.listingPurchaseId,
      this.listingTypeForm.value.subType,
      this.listingType,
      tags,
      this.imgList,
      previewListing ? previewListing : null,
      revise ? this.listing : null
    );
    let res: any;
    try {
      res = await this.registerListService.adminSaveListing(tempListing);
    } catch (error) {
      this.showSaveErr();
    }

    if (res) {
      if (toApprove) {
        await this.purchaseService.approveListing(this.listing.id);
      }
      res.listingPhotos = tempListing.photos;
      this.finishActionBySpotAdmin(res.id);
    } else {
      this.showSaveErr();
    }
  }

  finishActionBySpotAdmin(listingId: number) {
    this.currentStep = 0;
    this.adminUpdate = true;
    this.skipDiscardChanges = true;
    this._eventChecker.updateListing$.next('no-refresh');
    this.fromMyListings ? this.router.navigateByUrl('/user-home/my-listings') : this.navigateToDetailsPage(listingId);
  }

  async getSpaceTranslations(subTypes: any) {
    Object.keys(subTypes).forEach(async (key, idx) => {
      subTypes[key].name = this.getI18nValue(subTypes[key].name);
      subTypes[key].selected = false;
    });
    return subTypes;
  }

  async getSpaceTypes(id: any) {
    return this.registerService.getBuildingSubTypes(id).toPromise();
  }

  async getAmenities() {
    this.industrialFilters = await this.registerService.getAmenities('industrial');
    this.officeFilters = await this.registerService.getAmenities('office');
  }

  getI18nValue(textValue: string) {
    try {
      if (textValue && JSON.parse(textValue)) {
        return this.i18nService.getTranslation(textValue);
      } else {
        return '';
      }
    } catch (e) {
      console.error('failed to parse locale string -> listings search');
    }
  }

  getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  multiLangInit() {
    let response: any = this.listingCleanupService.multiLangInit();
    this.titleFieldOptions = response.titleFieldOptions;
    this.descriptionFieldOptions = response.descriptionFieldOptions;
  }

  async reinitForms(listing: any, building: any) {
    let forms: any = await this.listingFormService.reinitForm(listing, building, this.measurementStandards);
    this.listingTypeForm = forms[0];
    this.listingPriceForm = forms[4];
    this.detailsForm = forms[1];
    this.addressForm = forms[2];
    this.moreDetailsForm = forms[3];
    this.listingFeaturesForm = forms[5];
    this.subTypes = await this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value);
    this.subTypes = await this.getSpaceTranslations(this.subTypes);
    if (this.listingTypeForm.controls['loadedSubTypes'].value) {
      this.setCoworkingFields();
      this.checkSubTypes();
    }
    if (listing.listingPhotos) {
      this.imgList = await this.listingFormService.checkListingPhotos(listing);
    }
    if (listing.vrTourUrl) {
      this.vrTourUrl = listing.vrTourUrl;
    }
    this.listingTypeForm.markAsDirty();
  }

  async checkSubTypes() {
    let existing: any = this.listingTypeForm.controls['loadedSubTypes'].value;
    Object.keys(this.subTypes).forEach((key, idx) => {
      existing.findIndex((v: any) => {
        if (v.id === this.subTypes[key].id) {
          this.subTypes[key].selected = true;
        }
      });
    });
    this.listingTypeForm.controls['subType'].setValue(this.subTypes);
  }

  async createInitForm() {
    await this.measurementS();
    let forms: any = await this.listingFormService.createInitForm(this.measurementStandards);
    this.listingTypeForm = forms[0];
    this.detailsForm = forms[1];
    this.addressForm = forms[2];
    this.moreDetailsForm = forms[3];
    this.listingPriceForm = forms[4];
    this.listingFeaturesForm = forms[5];
    this.subTypes = await this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value);
    this.subTypes = await this.getSpaceTranslations(this.subTypes);
    this.listingTypeForm.controls['subType'].setValue(this.subTypes);
    this.listingTypeForm.markAsDirty();
  }

  canDeactivate(): boolean {
    if (this.listingPurchaseId === 0 && !this.listing) {
      return true;
    }
    if (this.adminUpdate || this.skipDiscardChanges) {
      return true;
    } else if (this.previewLoaded) {
      this.showUploadErr();
      return true;
    }
    return (
      this.listingTypeForm &&
      !this.listingTypeForm.dirty &&
      this.detailsForm &&
      !this.detailsForm.dirty &&
      this.addressForm &&
      !this.addressForm.dirty &&
      this.moreDetailsForm &&
      !this.moreDetailsForm.dirty &&
      this.listingPriceForm &&
      !this.listingPriceForm.dirty &&
      this.listingFeaturesForm &&
      !this.listingFeaturesForm.dirty
    );
  }

  async showUploadErr() {
    const toast = await this.toastCtrl.create({
      message: 'Your listing has been saved as a draft.',
      duration: 3500,
      animated: true,
      keyboardClose: true,
      cssClass: 'toast-alert'
    });
    toast.onDidDismiss().then(() => {});
    return await toast.present();
  }

  async showSaveErr() {
    const toast = await this.toastCtrl.create({
      message: 'There was an issue saving your listing.',
      duration: 3500,
      animated: true,
      keyboardClose: true,
      cssClass: 'toast-alert'
    });
    toast.onDidDismiss().then(() => {});
    return await toast.present();
  }

  async displayAlert() {
    const alert = await this.alertCtrl.create({
      header: this._ts.instant('global.updateListing'),
      subHeader: this._ts.instant('global.updateListingText'),
      cssClass: 'ion-alert-listings',
      backdropDismiss: true,
      buttons: [
        {
          text: this._ts.instant('Cancel'),
          role: 'Cancel',
          handler: () => {
            this.goBack();
          }
        },
        {
          text: this._ts.instant('Ok'),
          handler: () => {
            this.setBuildingPreview(false, null, true, true, false);
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {});
    await alert.present();
  }

  onVrTourChanged(urlValidation: URLValidation) {
    this.videoURLInvalid = urlValidation.videoURLInvalid;
    this.vrTourUrl = urlValidation.vrTourSelected;
  }

  detectFeatures() {
    this._eventChecker.updateOfficeFilters$.subscribe((pOfficeFilters: any) => {
      if (pOfficeFilters) {
        this.officeFilters = pOfficeFilters;
      }
    });

    this._eventChecker.updateIndustrialFilters$.subscribe((pFilters: any) => {
      if (pFilters) {
        this.industrialFilters = pFilters;
      }
    });
  }

  isUserAllowToEditPendingApprovalListing() {
    return this.registerListService.isUserAllowToEditPendingApprovalListing(this.listing);
  }

  public cancelAndNavigateToDetails() {
    this.fromMyListings = false;
    this.finishActionBySpotAdmin(this.listing.id);
  }
}
