import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent, TranslateParser } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Logger } from './logger.service';
import enJsonFile from '../../translations/en-US.json';
import countryJSONFile from '../../translations/pt-BR.json';
import { LanguageService } from './language.service';
import { environment } from '@env/environment';
import { ReixSpotLangs, ReixLangCulture, ReixSpotLanguagesService } from './reixspotlanguages.service';

const log = new Logger('I18nService');
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
  return s;
}

interface SpotURI {
  url: string;
  queryParams: string;
}

@Injectable()
export class I18nService {
  defaultLanguage!: string;
  supportedLanguages!: string[];

  private langChangeSubscription!: Subscription;
  private supportedLanguagesByURL: string[] = [];
  private currentLanguage: string;
  private enURLPath: string;
  private countryURLPath: string;
  private isCultureEN: boolean;

  constructor(
    private translateService: TranslateService,
    private languageSvc: LanguageService,
    private reixSpotLangSrv: ReixSpotLanguagesService
  ) {
    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation(ReixLangCulture.EN, enJsonFile);
    translateService.setTranslation(ReixLangCulture.COUNTRY_LANG, countryJSONFile);

    this.supportedLanguagesByURL = [ReixSpotLangs.COUNTRY_LANG, ReixSpotLangs.EN];
    this.enURLPath = `/${ReixSpotLangs.EN}`;
    this.countryURLPath = `/${ReixSpotLangs.COUNTRY_LANG}`;
    this.translateService.onLangChange.subscribe((response: any) => {
      this.currentLanguage = this.getLangBasedSPOTLanguage(response.lang);
      this.isCultureEN = response.lang == ReixLangCulture.EN;
    });
  }

  isLangEN = () => this.isCultureEN;

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = ReixLangCulture.COUNTRY_LANG;

    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      sessionStorage.setItem(languageKey, event.lang);
      this.languageSvc.locale = event.lang;
    });
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    this.langChangeSubscription.unsubscribe();
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the session storage.
   * If no parameter is specified, the language is loaded from session storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language = language || sessionStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = this.supportedLanguages.includes(language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = this.supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    log.debug(`Language set to ${language} - ${this.getCurrentLanguage()}`);
    this.languageSvc.locale = language;
    this.translateService.use(language);
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  getTranslation(textValue: string): string {
    try {
      if (textValue && JSON.parse(textValue)) {
        // Using the default language in lower case in order to get the pt-br values defined in some JSON objects.
        const defaultLanguage = this.getCurrentLanguage();
        let newValue = JSON.parse(textValue)[defaultLanguage.toLowerCase()];
        return newValue ? newValue : JSON.parse(textValue)[Object.keys(JSON.parse(textValue))[0]];
      } else {
        return '';
      }
    } catch (e) {
      //console.error('failed to parse locale string', textValue, this.getCurrentLanguage());
    }
    return textValue;
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

  /**
   * Get the value defined on the translations JSON files.
   * @param translationKey
   */
  get(translationKey: string, interpolateParams?: Object): string {
    let result = '';
    this.translateService.get(translationKey, interpolateParams).subscribe(data => {
      result = data;
    });
    return result;
  }

  getDefaultLang = () => ReixSpotLangs.COUNTRY_LANG.toString();

  setLanguageBasedOnURL(url: string) {
    const urlParts = url.split('/');
    let lang: string = urlParts[urlParts.length - 1];
    const indexOfQueryParams = lang.indexOf('?');
    if (indexOfQueryParams != -1) {
      lang = lang.substr(0, indexOfQueryParams);
    }

    if (
      lang.toLocaleLowerCase() === ReixSpotLangs.COUNTRY_LANG &&
      this.language &&
      this.language === ReixLangCulture.COUNTRY_LANG
    ) {
      document.documentElement.setAttribute('lang', 'pt');
      return;
    }
    if (lang.toLocaleLowerCase() === ReixSpotLangs.EN && this.language && this.language === ReixLangCulture.EN) {
      document.documentElement.setAttribute('lang', ReixSpotLangs.EN);
      return;
    }
    switch (lang.toLowerCase()) {
      case ReixSpotLangs.COUNTRY_LANG:
        this.language = ReixLangCulture.COUNTRY_LANG;
        document.documentElement.setAttribute('lang', 'pt');
        break;
      case ReixSpotLangs.EN:
        this.language = ReixLangCulture.EN;
        document.documentElement.setAttribute('lang', ReixSpotLangs.EN);
        break;
      default:
        this.language = null;
        break;
    }
  }

  isLanguageParam(requestParam: string) {
    return this.supportedLanguagesByURL.includes(requestParam);
  }

  removeLanguageFromURL(url: string) {
    let newUrl = url;
    const urlParams = url.split('/');
    if (urlParams.length >= 2) {
      let langParam: string = urlParams[urlParams.length - 1];
      if (this.isLanguageParam(langParam)) {
        newUrl = urlParams.slice(0, urlParams.length - 1).join('/');
      }
    }
    return newUrl;
  }

  getLanguagePathURL() {
    const currentURL = window.location.href;
    const urlParams = currentURL.split('/');
    if (urlParams.length >= 2) {
      let langParam: string = urlParams[urlParams.length - 1];
      if (this.isLanguageParam(langParam)) {
        return langParam;
      }
    }
    return this.getDefaultLang();
  }

  getHomeSPOTURL() {
    if (this.language === ReixLangCulture.EN) {
      return this.enURLPath;
    } else if (this.language === ReixLangCulture.COUNTRY_LANG) {
      return this.countryURLPath;
    }
    return '/';
  }

  concatLangParamBasedOnURL(url: any) {
    if (!this.hasURLLangPathParam(url)) {
      return this.concatLanguagePathURL(url);
    }
    return url;
  }

  hasURLLangPathParam(url: any) {
    const spotURI = this.separateURIAndQueryParams(url);
    const currentURL = spotURI.url;

    const urlParams = currentURL.split('/');
    if (urlParams.length >= 2) {
      let langParam: string = urlParams[urlParams.length - 1];
      if (this.isLanguageParam(langParam)) {
        return true;
      }
    }
    return false;
  }

  currentURLHasLangParam() {
    return this.hasURLLangPathParam(window.location.href);
  }

  concatLanguagePathURL(currentPath: string) {
    let path = this.adjustLastSlash(currentPath);
    if (this.language === ReixLangCulture.EN) {
      path = path.concat(this.enURLPath);
    } else if (this.language === ReixLangCulture.COUNTRY_LANG) {
      path = path.concat(this.countryURLPath);
    }
    return path;
  }

  private adjustLastSlash(currentPath: string) {
    let path = currentPath;
    if (path == null || path === undefined) {
      return path;
    }
    if (path.endsWith('/')) {
      path = path.slice(0, path.length - 1);
    }
    return path;
  }

  currentUrlContainsCurrentLanguage() {
    const currentURL = window.location.href;
    const urlParams = currentURL.split('/');
    if (urlParams.length >= 2) {
      let langParam: string = urlParams[urlParams.length - 1];
      if (this.language === ReixLangCulture.EN && langParam === ReixSpotLangs.EN) {
        return true;
      } else if (this.language === ReixLangCulture.COUNTRY_LANG && langParam === ReixSpotLangs.COUNTRY_LANG) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get the current language defined for the user
   * It returns en, pt-br
   */
  getCurrentLanguage() {
    if (this.currentLanguage === undefined) {
      const currentLanguageStorage = environment.defaultLanguage;
      if (currentLanguageStorage) {
        return this.getLangBasedSPOTLanguage(currentLanguageStorage);
      } else {
        const locale: string = this.languageSvc.locale;
        return this.getLangBasedSPOTLanguage(locale);
      }
    }
    return this.currentLanguage;
  }

  getCurrentLocale() {
    return this.getLocaleBasedSPOTLanguage(this.getCurrentLanguage());
  }

  private getLangBasedSPOTLanguage(language: string) {
    if (language === ReixLangCulture.EN) {
      return ReixSpotLangs.EN;
    } else if (language === ReixLangCulture.COUNTRY_LANG) {
      return ReixSpotLangs.COUNTRY_LANG;
    }
    return language;
  }

  private getLocaleBasedSPOTLanguage(language: string) {
    if (language === ReixSpotLangs.EN) {
      return ReixLangCulture.EN;
    } else if (language === ReixSpotLangs.COUNTRY_LANG) {
      return ReixLangCulture.COUNTRY_LANG;
    }
    return language;
  }

  public getTranslationInTargetLanguage(key: string, language: string, interpolateParams?: Object): string {
    let currentLanguage: string = this.translateService.currentLang;
    this.translateService.currentLang = language;
    let translation: string = this.translateService.instant(key, interpolateParams);

    this.translateService.currentLang = currentLanguage;

    return translation;
  }

  /**
   *
   * @param translationKey
   */
  public getMetatagTranslation(translationKey: string, interpolateParams?: Object) {
    if (this.currentUrlContainsCurrentLanguage()) {
      return this.get(translationKey, interpolateParams);
    } else {
      let result = this.getTranslationInTargetLanguage(translationKey, this.getCurrentLanguage(), interpolateParams);
      return result;
    }
  }

  public addCurrentLanguageToPath(currentPath: string) {
    const spotURI = this.separateURIAndQueryParams(currentPath);
    const url = spotURI.url;
    const queryParams = spotURI.queryParams;

    let newURL = this.concatLanguagePathURL(this.removeLanguageFromURL(url));
    if (queryParams) {
      newURL = newURL.concat(queryParams);
    }

    return newURL;
  }

  public addDefaultLanguageToPath(currentPath: string) {
    if (this.hasURLLangPathParam(currentPath)) {
      return currentPath;
    }
    const spotURI = this.separateURIAndQueryParams(currentPath);
    const url = spotURI.url;
    const queryParams = spotURI.queryParams;

    let newURL = this.adjustLastSlash(this.removeLanguageFromURL(url));
    newURL = newURL.concat(this.countryURLPath);
    if (queryParams) {
      newURL = newURL.concat(queryParams);
    }

    return newURL;
  }

  private separateURIAndQueryParams(currentPath: string): SpotURI {
    const indexQueryParam = currentPath.indexOf('?');
    let url = currentPath;
    let queryParams = null;

    if (indexQueryParam != -1) {
      url = currentPath.substring(0, indexQueryParam);
      queryParams = currentPath.substring(indexQueryParam, currentPath.length);
    }

    return { url: url, queryParams: queryParams };
  }
}
