import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { I18nService } from '@app/core';
import { NotificationUser } from '@app/models/notification-user.model';
import { SearchNotification } from '@app/models/search-notification.model';
import { faBell, faEllipsisH, faBellSlash, faEyeSlash, faCircle } from '@fortawesome/free-solid-svg-icons';
import { NotificationService } from '../services/notification.service';
import { turnOff } from '../util/params';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  faBell: any;
  faEllipsisH: any;
  faBellSlash: any;
  faEyeSlash: any;
  faCircle: any;

  notifications: any;
  showShortDesciption = true;
  turnOffParams = turnOff;
  unread: boolean;
  newNotificationIncoming = true;

  constructor(
    private i18nService: I18nService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.faBell = faBell;
    this.faEllipsisH = faEllipsisH;
    this.faBellSlash = faBellSlash;
    this.faEyeSlash = faEyeSlash;
    this.faCircle = faCircle;
    this.initNotification();
    this.notificationService.incomingNotificationsEvent.subscribe((notification: any) => {
      if (notification) {
        this.unread = true;
        this.initNotification();
      }
    });
  }

  ringBellListener() {
    return (
      this.notifications &&
      this.notifications.totalElements > 0 &&
      this.notifications.content.filter((noti: any) => !noti.read).length > 0
    );
  }

  goToNotification() {
    this.router.navigateByUrl('/notifications');
  }

  initNotification() {
    this.notificationService
      .getNotifications(0, 'en', { showUnread: this.unread })
      .then((notify: SearchNotification) => {
        this.notifications = notify;
      });
  }

  getContent(content: string) {
    return this.i18nService.getTranslation(content);
  }

  turnOffNotification(turnOff: any) {
    this.notificationService.turnOffNotification(turnOff).then(val => {
      this.initNotification();
    });
  }

  goToSettings() {
    this.router.navigateByUrl('/settings');
  }

  goToActionLink(notify: any) {
    if (notify.read) {
      window.open(notify.actionLink, 'blank');
    } else {
      this.notificationService.readNotification(notify.id).then(() => {
        this.initNotification();
        window.open(notify.actionLink, 'blank');
      });
    }
  }

  hideNotification(id: number) {
    this.notificationService.hideNotification(id).then(() => {
      this.initNotification();
    });
  }

  getUnread() {
    this.initNotification();
  }
}
