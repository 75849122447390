/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../../../../../node_modules/@agm/core/agm-core.ngfactory";
import * as i5 from "@agm/core";
import * as i6 from "./map.component";
import * as i7 from "../../../core/i18n.service";
var styles_MapComponent = [i0.styles];
var RenderType_MapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
export function View_MapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { searchElementRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 36, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"], ["style", "font-size: 20px;"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(8, 0, null, 0, 28, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "form-group"], ["id", "pac-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(13, 0, [[1, 0], ["search", 1]], null, 0, "input", [["autocapitalize", "off"], ["autocorrect", "off"], ["autofocus", ""], ["class", "form-control"], ["spellcheck", "off"], ["type", "text"]], [[8, "placeholder", 0]], [[null, "keydown.enter"]], function (_v, en, $event) { var ad = true; if (("keydown.enter" === en)) {
        var pd_0 = ($event.preventDefault() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(16, 0, null, null, 19, "agm-map", [], [[2, "sebm-google-map-container", null]], [[null, "mapClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapClick" === en)) {
        var pd_0 = (_co.addMarker($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_AgmMap_0, i4.RenderType_AgmMap)), i1.ɵprd(4608, null, i5.CircleManager, i5.CircleManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.DataLayerManager, i5.DataLayerManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.InfoWindowManager, i5.InfoWindowManager, [i5.GoogleMapsAPIWrapper, i1.NgZone, i5.MarkerManager]), i1.ɵprd(4608, null, i5.KmlLayerManager, i5.KmlLayerManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.LayerManager, i5.LayerManager, [i5.GoogleMapsAPIWrapper]), i1.ɵprd(4608, null, i5.PolygonManager, i5.PolygonManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.PolylineManager, i5.PolylineManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.RectangleManager, i5.RectangleManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(512, null, i5.GoogleMapsAPIWrapper, i5.GoogleMapsAPIWrapper, [i5.MapsAPILoader, i1.NgZone]), i1.ɵprd(512, null, i5.ɵa, i5.ɵa, [i5.MapsAPILoader]), i1.ɵdid(27, 770048, null, 0, i5.AgmMap, [i1.ElementRef, i5.GoogleMapsAPIWrapper, i1.PLATFORM_ID, i5.ɵa, i1.NgZone], { longitude: [0, "longitude"], latitude: [1, "latitude"], zoom: [2, "zoom"] }, { mapClick: "mapClick" }), i1.ɵprd(512, null, i5.MarkerManager, i5.MarkerManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(30, 0, null, 0, 4, "agm-marker", [], null, [[null, "dragEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragEnd" === en)) {
        var pd_0 = (_co.markerDragEnd($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i5.FitBoundsAccessor, null, [i5.AgmMarker]), i1.ɵdid(32, 1720320, null, 1, i5.AgmMarker, [i5.MarkerManager], { latitude: [0, "latitude"], longitude: [1, "longitude"], draggable: [2, "draggable"] }, { dragEnd: "dragEnd" }), i1.ɵqud(603979776, 2, { infoWindow: 1 }), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.buildingMarker.lng; var currVal_4 = _co.buildingMarker.lat; var currVal_5 = _co.zoom; _ck(_v, 27, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.buildingMarker.lat; var currVal_7 = _co.buildingMarker.lng; var currVal_8 = true; _ck(_v, 32, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.enterAddressDesc, ""); _ck(_v, 13, 0, currVal_1); var currVal_2 = true; _ck(_v, 16, 0, currVal_2); }); }
export function View_MapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i1.ɵdid(1, 114688, null, 0, i6.MapComponent, [i5.MapsAPILoader, i1.NgZone, i7.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapComponentNgFactory = i1.ɵccf("app-map", i6.MapComponent, View_MapComponent_Host_0, {}, { newLocationEvent: "newLocationEvent" }, []);
export { MapComponentNgFactory as MapComponentNgFactory };
