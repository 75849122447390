<ion-content *ngIf="!isMobile" #contentBlock class="main-container">
  <ion-row [ngClass]="{ 'company-card': !isMobile }">
    <ion-card
      [ngClass]="{ 'company-card': !isMobile }"
      class="ion-listing-card detail-ion-card standard-shadow px-0 px-md-5"
    >
      <app-show-images
        id="imgTemplate"
        [propertyName]="companyName"
        [SpotPhotos]="companyPhotos"
        [pageName]="'company'"
      >
      </app-show-images>

      <div class="company__info">
        <div class="company__info__logo">
          <img
            class="company__info__logo__dimensions"
            [src]="company && company.mainImageId ? getMediumImg(company.mainImageId) : defaultImage"
          />
        </div>

        <div class="company__info__data">
          <div class="company__info__data__header">
            <div class="company__info__data__header__title">{{ companyName }}</div>
            <div class="company__info__data__header__social">
              <ion-button class="shared-btn btn-color-2" (click)="shareCompany($event)">
                <fa-icon class="shared-icon" [icon]="sharedIcon" size="md"></fa-icon>
                <span>{{ 'brokerProfile.shareProfile' | translate }} </span>
              </ion-button>
            </div>
          </div>
          <div [innerHTML]="companyBio" class="company__info__data__content"></div>
        </div>
      </div>

      <div class="row-company-separator"></div>

      <app-spot-card-tabs
        title="{{ 'PortFolio' | translate }}"
        [spotBuildingSearchRequest]="spotBuildingSearchRequest"
        portfolioFor="COMPANY_DETAIL"
      >
      </app-spot-card-tabs>

      <section class="services-container mt-5 w-100">
        <industry-news [companyId]="companyId"></industry-news>
      </section>
    </ion-card>
  </ion-row>

  <div class="our__team__section" *ngIf="agentsDTO && agentsDTO.length > 0">
    <div class="our__team__section__title">{{ 'OurTeam' | translate }}</div>
    <div class="our__team__section__user__gallery">
      <div class="company__agent__cards__container">
        <div class="gallery__arrows__cols">
          <fa-icon
            [ngClass]="{ 'fa-disabled': isStartOfSlide() }"
            class="arrow-icon"
            (click)="arrowsNavigation($event, -1)"
            [icon]="leftArrow"
            size="lg"
          ></fa-icon>
        </div>

        <div class="slider__card__container">
          <ion-slides
            class="slider__preview--dimensions slider__preview"
            [options]="sliderPreviewOptions"
            (ionSlideTransitionEnd)="getNextPhoto($event)"
            #previewSlider
            id="previewSlider"
          >
            <ion-slide
              (click)="navigateToBroker(agentDTO.brokerId)"
              class="slider__preview__img--dimensions"
              *ngFor="let agentDTO of agentsDTO; let i = index"
            >
              <app-company-agent-card [agentDTO]="agentDTO"> </app-company-agent-card>
            </ion-slide>
          </ion-slides>
        </div>

        <div class="gallery__arrows__cols">
          <fa-icon
            class="arrow-icon"
            [ngClass]="{ 'fa-disabled': isEndOfSlide() }"
            (click)="arrowsNavigation($event, 1)"
            [icon]="rightArrow"
            size="lg"
          ></fa-icon>
        </div>
      </div>

      <div class="image-dots" *ngIf="agentsDTO && agentsDTO.length > 1">
        <span
          class="dot"
          [ngClass]="{ active: i == currentPhotoIndex }"
          *ngFor="let agentDot of totalDtos; let i = index"
          (click)="slideToIndex($event, i)"
        >
        </span>
      </div>
    </div>
  </div>

  <div class="scrollToTop">
    <app-scroll-to-top
      [showComponent]="displayScrollToTop"
      (scroll)="contentBlock.scrollToTop()"
      [target]="contentBlock"
      scroller
    >
    </app-scroll-to-top>
  </div>
  <app-footer inViewport (inViewportAction)="isFooterVisible($event)"></app-footer>
</ion-content>

<!--Mobile-->
<ion-content class="mobile__container--content" #contentBlock *ngIf="isMobile">
  <app-show-images
    id="imgTemplate"
    class="mobile__container__photos__section"
    [propertyName]="companyName"
    [SpotPhotos]="companyPhotos"
    [pageName]="'company'"
  >
  </app-show-images>

  <div class="mobile__container">
    <div class="company__logo__section">
      <div class="company__info__mobile__logo">
        <img
          class="company__info__mobile__logo__dimensions"
          [src]="company && company.mainImageId ? getMediumImg(company.mainImageId) : defaultImage"
        />
      </div>
    </div>

    <div class="mobile__container__company-name">{{ companyName }}</div>

    <div class="mobile__container__company-bio" [innerHTML]="companyBio" *ngIf="companyBio"></div>

    <div class="company__info__data__header__social mobile__container__company-social">
      <ion-button class="shared-btn-mobile btn-color-2" (click)="shareCompany($event)">
        <fa-icon class="shared-icon" [icon]="sharedIcon" size="md"></fa-icon>
        <span> {{ 'brokerProfile.shareProfile' | translate }}</span>
      </ion-button>
    </div>

    <div class="row-separator-mobile"></div>

    <app-spot-card-tabs
      title="{{ 'PortFolio' | translate }}"
      [spotBuildingSearchRequest]="spotBuildingSearchRequest"
      portfolioFor="COMPANY_DETAIL"
    >
    </app-spot-card-tabs>

    <section class="services-container mt-5 w-100">
      <industry-news [companyId]="companyId"></industry-news>
    </section>

    <div class="our__team__section" style="margin-bottom: 80px;" *ngIf="agentsDTO && agentsDTO.length > 0">
      <div class="our__team__section__title">{{ 'OurTeam' | translate }}</div>
      <div class="our__team__section__user__gallery">
        <div class="company__agent__cards__container">
          <div class="gallery__arrows__cols">
            <fa-icon
              [ngClass]="{ 'fa-disabled': isStartOfSlide() }"
              class="arrow-icon"
              (click)="arrowsNavigation($event, -1)"
              [icon]="leftArrow"
              size="lg"
            ></fa-icon>
          </div>

          <div class="slider__card__container">
            <ion-slides
              class="slider__preview--dimensions slider__preview"
              [options]="sliderPreviewOptions"
              (ionSlideTransitionEnd)="getNextPhoto($event)"
              #previewSlider
              id="previewSlider"
            >
              <ion-slide
                (click)="navigateToBroker(agentDTO.brokerId)"
                class="slider__preview__img--dimensions"
                *ngFor="let agentDTO of agentsDTO; let i = index"
              >
                <app-company-agent-card [agentDTO]="agentDTO"> </app-company-agent-card>
              </ion-slide>
            </ion-slides>
          </div>

          <div class="gallery__arrows__cols">
            <fa-icon
              class="arrow-icon"
              [ngClass]="{ 'fa-disabled': isEndOfSlide() }"
              (click)="arrowsNavigation($event, 1)"
              [icon]="rightArrow"
              size="lg"
            ></fa-icon>
          </div>
        </div>

        <div class="image-dots" *ngIf="agentsDTO && agentsDTO.length > 1">
          <span
            class="dot"
            [ngClass]="{ active: i == currentPhotoIndex }"
            *ngFor="let agentDot of totalDtos; let i = index"
            (click)="slideToIndex($event, i)"
          >
          </span>
        </div>
      </div>
    </div>
  </div>

  <div inViewport (inViewportAction)="isFooterVisible($event)" class="scrollToTop">
    <app-scroll-to-top
      [showComponent]="displayScrollToTop"
      (scroll)="contentBlock.scrollToTop()"
      [target]="contentBlock"
      scroller
    >
    </app-scroll-to-top>
  </div>
</ion-content>
