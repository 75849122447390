import { Component, OnInit, Input, HostListener } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService, I18nService } from '@app/core';
import { ListingPriceService } from '@app/core/listings';

@Component({
  selector: 'app-listing-price',
  templateUrl: './listing-pricing.component.html',
  styleUrls: ['./listing-pricing.component.scss']
})
export class ListingPriceComponent implements OnInit {
  @Input('form') listingPriceForm: FormGroup;
  @Input('addressForm') addressForm: FormGroup;
  @Input('otherForm') listingTypeForm: FormGroup;
  @Input('stepper') stepper: MatStepper;
  @Input('subTypes') subTypes: any;
  @Input('listing') listing: any;
  @Input('listingClass') listingClass: any;
  screenWidth: any;
  pricingOptions: any;
  public regex: string = '^(0*100{1,1}.?((?<=.)0*)?%?$)|(^0*d{0,2}.?((?<=.)d*)?%?)$';
  measurementStandards: any;
  constructor(
    private registerService: RegisterService,
    private _ts: TranslateService,
    private langService: LanguageService,
    private listingPriceService: ListingPriceService,
    private i18NService: I18nService
  ) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.initMeasurement();

    if (this.listing) {
      this.listing.isForLeaseSale === 'SALE'
        ? this.setValidation('S')
        : this.listing.isForLeaseSale === 'LEASE' || this.listing.isForLeaseSale === 'L'
        ? this.setValidation('L')
        : '';
    } else {
      this.setValidation('L');
    }
    this.setListingClass();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  async initMeasurement() {
    this.measurementStandards = await this.getMeasurementStands();
    this.measurementStandards = await this.getMeasurementTranslations(this.measurementStandards);
  }

  async setListingClass() {
    if (this.listingClass === 'PROMOTED') {
      this.listingPriceForm.controls['leaseableAreaTotal'].disable();
    }
  }

  async getMeasurementStands() {
    return this.registerService.getMeasurementStandard().toPromise();
  }

  setValidation(type: any) {
    if (type === 'L') {
      // Rent
      if (this.listingPriceForm.value.negRentableArea) {
        this.listingPriceForm.controls.monthlyRent.setValue(null);
        this.listingPriceForm.controls.monthlyRentPerArea.setValue('');
        this.listingPriceForm.controls['monthlyRent'].setValidators([]);
        this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
        this.listingPriceForm.controls.monthlyRent.disable();
        this.listingPriceForm.controls['monthlyRentPerArea'].setValue(null);
        this.listingPriceForm.controls['monthlyRentPerArea'].disable();
      } else {
        this.listingPriceForm.controls.monthlyRent.enable();
        this.listingPriceForm.controls['monthlyRent'].setValidators([Validators.required]);
        this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
        this.listingPriceForm.controls['monthlyRentPerArea'].enable();
      }
      // Condo
      if (this.listingPriceForm.value.negCondoFeeArea) {
        this.listingPriceForm.controls.monthlyCondoFee.setValue(null);
        this.listingPriceForm.controls.monthlyCondoFeePerArea.setValue('');
        this.listingPriceForm.controls['monthlyCondoFee'].setValidators([]);
        this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
        this.listingPriceForm.controls.monthlyCondoFee.disable();
        this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(null);
        this.listingPriceForm.controls['monthlyCondoFeePerArea'].disable();
      } else {
        this.listingPriceForm.controls.monthlyCondoFee.enable();
        this.listingPriceForm.controls['monthlyCondoFee'].setValidators([Validators.required]);
        this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
        this.listingPriceForm.controls['monthlyCondoFeePerArea'].enable();
      }
      // IPTU
      if (this.listingPriceForm.value.negIPTUArea) {
        this.listingPriceForm.controls.monthlyIptu.setValue(null);
        this.listingPriceForm.controls.monthlyIptuPerArea.setValue('');
        this.listingPriceForm.controls['monthlyIptu'].setValidators([]);
        this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
        this.listingPriceForm.controls.monthlyIptu.disable();
        this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(null);
        this.listingPriceForm.controls['monthlyIptuPerArea'].disable();
      } else {
        this.listingPriceForm.controls.monthlyIptu.enable();
        this.listingPriceForm.controls['monthlyIptu'].setValidators([Validators.required]);
        this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
        this.listingPriceForm.controls['monthlyIptuPerArea'].enable();
      }
    } else if (type === 'S') {
      if (this.listingPriceForm.value.pricePer) {
        this.listingPriceForm.controls['totalAskingPrice'].setValidators([]);
        this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
        this.listingPriceForm.controls.totalAskingPrice.disable();
        this.listingPriceForm.controls['pricePerSquare'].disable();
      } else {
        this.listingPriceForm.controls.totalAskingPrice.enable();
        this.listingPriceForm.controls['totalAskingPrice'].setValidators([Validators.required]);
        this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
        this.listingPriceForm.controls['pricePerSquare'].enable();
      }
    }
  }

  checkTotal() {
    if (this.listingPriceForm.value.pricePer) {
      this.listingPriceForm.controls.totalAskingPrice.enable();
      this.listingPriceForm.controls['totalAskingPrice'].setValidators([Validators.required]);
      this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
      this.listingPriceForm.controls['pricePerSquare'].setValue(null);
      this.listingPriceForm.controls['pricePerSquare'].enable();
    } else {
      this.listingPriceForm.controls.totalAskingPrice.setValue(null);
      this.listingPriceForm.controls.pricePerSquare.setValue('');
      this.listingPriceForm.controls['totalAskingPrice'].setValidators([]);
      this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
      this.listingPriceForm.controls.totalAskingPrice.disable();
      this.listingPriceForm.controls['pricePerSquare'].disable();
    }
  }

  checkMonthlyCondo() {
    if (this.listingPriceForm.value.negCondoFeeArea) {
      this.listingPriceForm.controls.monthlyCondoFee.enable();
      this.listingPriceForm.controls['monthlyCondoFee'].setValidators([Validators.required]);
      this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
      this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(null);
      this.listingPriceForm.controls['monthlyCondoFeePerArea'].enable();
    } else {
      this.listingPriceForm.controls.monthlyCondoFee.disable();
      this.listingPriceForm.controls.monthlyCondoFee.setValue(null);
      this.listingPriceForm.controls.monthlyCondoFeePerArea.setValue('');
      this.listingPriceForm.controls['monthlyCondoFee'].setValidators([]);
      this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
      this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(null);
      this.listingPriceForm.controls['monthlyCondoFeePerArea'].disable();
    }
  }

  checkMonthlyIPTU() {
    if (this.listingPriceForm.value.negIPTUArea) {
      this.listingPriceForm.controls.monthlyIptu.enable();
      this.listingPriceForm.controls['monthlyIptu'].setValidators([Validators.required]);
      this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
      this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(null);
      this.listingPriceForm.controls['monthlyIptuPerArea'].enable();
    } else {
      this.listingPriceForm.controls.monthlyIptu.setValue(null);
      this.listingPriceForm.controls.monthlyIptuPerArea.setValue('');
      this.listingPriceForm.controls['monthlyIptu'].setValidators([]);
      this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
      this.listingPriceForm.controls.monthlyIptu.disable();
      this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(null);
      this.listingPriceForm.controls['monthlyIptuPerArea'].disable();
    }
  }

  checkMonthly() {
    if (this.listingPriceForm.value.negRentableArea) {
      this.listingPriceForm.controls.monthlyRent.enable();
      this.listingPriceForm.controls['monthlyRent'].setValidators([Validators.required]);
      this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
      this.listingPriceForm.controls['monthlyRentPerArea'].setValue(null);
      this.listingPriceForm.controls['monthlyRentPerArea'].enable();
    } else {
      this.listingPriceForm.controls.monthlyRent.setValue(null);
      this.listingPriceForm.controls.monthlyRentPerArea.setValue('');
      this.listingPriceForm.controls['monthlyRent'].setValidators([]);
      this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
      this.listingPriceForm.controls.monthlyRent.disable();
      this.listingPriceForm.controls['monthlyRentPerArea'].setValue(null);
      this.listingPriceForm.controls['monthlyRentPerArea'].disable();
    }
  }

  percentageMask() {
    let percent: any = this.listingPriceForm.value.currentOccupancy;
    if (percent >= 0 && percent < 100) {
      this.listingPriceForm.controls['currentOccupancy'].setValue(percent);
    } else {
      this.listingPriceForm.controls['currentOccupancy'].setValue(100);
    }
  }

  percentageBlur() {
    if (this.listingPriceForm.value.currentOccupancy >= 0 && this.listingPriceForm.value.currentOccupancy < 100) {
      let percent: any = parseFloat(this.listingPriceForm.value.currentOccupancy);
      percent = percent.toFixed(2);
      this.listingPriceForm.controls['currentOccupancy'].setValue(percent);
    }
  }

  interestMask() {
    let percentInterestOffered: any = this.listingPriceForm.value.percentInterestOffered;
    if (percentInterestOffered >= 0 && percentInterestOffered < 100) {
      this.listingPriceForm.controls['percentInterestOffered'].setValue(percentInterestOffered);
    } else {
      this.listingPriceForm.controls['percentInterestOffered'].setValue(100);
    }
  }

  async getMeasurementTranslations(measurements: any) {
    Object.keys(measurements).forEach(async (key, idx) => {
      measurements[key].measurement = this.getI18nValue(measurements[key].measurement);
    });
    return measurements;
  }

  checkCharacters(event: any) {
    let num = event.detail.value;
    let regexp: any;
    if (num) {
      let lastChar: any = num.substr(num.length - 1);
      if (this.langService.locale === 'en-US') {
        regexp = /^-?(?:\d{0,12}|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
      } else {
        regexp = /^-?(?:\d{0,12}|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
      }
      let tempValue = num.substring(0, num.length - 1);
      if (
        (!regexp.test(num) && lastChar !== '.' && lastChar !== ',') ||
        num.substr(0) === '.' ||
        num.substr(0) === ','
      ) {
        if (tempValue.length && tempValue.length > 12) {
          console.error('Invalid Value: ', tempValue);
          event.srcElement.value = null;
        } else {
          event.srcElement.value = tempValue;
        }
      }
    }
  }

  checkRentableArea() {
    let price = this.listingPriceForm.value.totalAskingPrice;
    let area = this.listingPriceForm.value.rentableArea;
    price = this.listingPriceService.convertForNumber(price);
    area = this.listingPriceService.convertForNumber(area);
    let pricePerSquare: any = price / area;
    if (isFinite(pricePerSquare) && pricePerSquare !== 0) {
      pricePerSquare = (pricePerSquare * 100) / 100;
      pricePerSquare = this.listingPriceService.convertForDisplay(pricePerSquare);
      this.listingPriceForm.controls['pricePerSquare'].setValue(pricePerSquare);
      this.listingPriceForm.controls['pricePerSquare'].updateValueAndValidity();
    }
  }

  rentableAreaBlur() {
    let area = this.listingPriceForm.value.rentableArea;
    area = this.listingPriceService.convertForNumber(area);
    if (isFinite(area) && area !== 0) {
      area = this.listingPriceService.convertForDisplay(area);
      this.listingPriceForm.controls['rentableArea'].setValue(area);
      this.checkRentableArea();
    }
  }

  totalPriceBlur($event: any) {
    let price = this.listingPriceForm.value.totalAskingPrice;
    price = this.listingPriceService.convertForNumber(price);
    if (isFinite(price) && price !== 0) {
      price = this.listingPriceService.convertForDisplay(price);
      this.listingPriceForm.controls['totalAskingPrice'].setValue(price);
      this.checkRentableArea();
    }
  }

  updateAmountPer() {
    if (this.listingPriceForm.value.rentableArea) {
      let price = this.listingPriceForm.value.pricePerSquare;
      let area = this.listingPriceForm.value.rentableArea;
      price = this.listingPriceService.convertForNumber(price);
      area = this.listingPriceService.convertForNumber(area);
      let totalAskingPrice: any = price * area;
      if (isFinite(totalAskingPrice) && totalAskingPrice !== 0) {
        totalAskingPrice = this.listingPriceService.convertForDisplay(totalAskingPrice);
        this.listingPriceForm.controls['totalAskingPrice'].setValue(totalAskingPrice);
      }
    }
  }

  interestBlur() {
    if (
      this.listingPriceForm.value.percentInterestOffered >= 0 &&
      this.listingPriceForm.value.percentInterestOffered < 100
    ) {
      let percent: any = parseFloat(this.listingPriceForm.value.percentInterestOffered);
      percent = percent.toFixed(2);
      this.listingPriceForm.controls['percentInterestOffered'].setValue(percent);
    }
  }

  checkLeaseableArea() {
    let rent = this.listingPriceForm.value.monthlyRent;
    let area = this.listingPriceForm.value.leaseableArea;
    rent = this.listingPriceService.convertForNumber(rent);
    area = this.listingPriceService.convertForNumber(area);
    let monthlyRentPerArea: any = rent / area;
    if (isFinite(monthlyRentPerArea) && monthlyRentPerArea !== 0 && monthlyRentPerArea !== '') {
      let monthlyRentPerArea2: any = (monthlyRentPerArea * 100) / 100;
      rent = this.listingPriceService.convertForDisplay(monthlyRentPerArea2);
      this.listingPriceForm.controls['monthlyRentPerArea'].setValue(rent);
      this.listingPriceForm.controls['monthlyRentPerArea'].updateValueAndValidity();
    }
    this.updateMonthlyCondo();
    this.updateMonthlyIptu();
  }

  async leaseableAreaBlur(event: any) {
    let area = this.listingPriceForm.value.leaseableArea;
    area = this.listingPriceService.convertToNumber(area);
    if (isFinite(area) && area !== 0 && area !== '') {
      area = this.listingPriceService.convertForDisplay(area);
      this.listingPriceForm.controls['leaseableArea'].setValue(area);
      await this.monthlyRentBlur(event);
      this.checkLeaseableArea();
    }
  }

  async leaseableAreatTotalBlur(event: any) {
    let area = this.listingPriceForm.value.leaseableAreaTotal;
    area = this.listingPriceService.convertToNumber(area);
    if (isFinite(area) && area !== 0 && area !== '') {
      area = this.listingPriceService.convertForDisplay(area);
      this.listingPriceForm.controls['leaseableAreaTotal'].setValue(area);
    }
  }

  async monthlyRentBlur($event: any) {
    let rent = this.listingPriceForm.value.monthlyRent;
    rent = this.listingPriceService.convertForNumber(rent);
    if (isFinite(rent) && rent !== 0 && rent !== '') {
      rent = this.listingPriceService.convertForDisplay(rent);
      this.listingPriceForm.controls['monthlyRent'].setValue(rent);
      this.checkLeaseableArea();
    }
  }

  updateMonthlyFee() {
    if (this.listingPriceForm.value.leaseableArea) {
      let rent = this.listingPriceForm.value.monthlyRentPerArea;
      let area = this.listingPriceForm.value.leaseableArea;
      rent = this.listingPriceService.convertForNumber(rent);
      area = this.listingPriceService.convertForNumber(area);
      let monthlyRent: any = rent * area;
      if (isFinite(monthlyRent) && monthlyRent !== 0 && monthlyRent !== '') {
        monthlyRent = this.listingPriceService.convertForDisplay(monthlyRent);
        this.listingPriceForm.controls['monthlyRent'].setValue(monthlyRent);
        this.checkLeaseableArea();
      }
    }
  }

  async standardChange($event: any) {}

  condoFeeBlur($event: any) {
    let condoFee = this.listingPriceForm.value.monthlyCondoFee;
    condoFee = this.listingPriceService.convertForNumber(condoFee);
    if (isFinite(condoFee) && condoFee !== 0 && condoFee !== '') {
      condoFee = this.listingPriceService.convertForDisplay(condoFee);
      this.listingPriceForm.controls['monthlyCondoFee'].setValue(condoFee);
      this.updateMonthlyCondo();
    }
  }

  updateMonthlyCondoFee() {
    if (this.listingPriceForm.value.leaseableArea) {
      let condoFeeArea = this.listingPriceForm.value.monthlyCondoFeePerArea;
      let area = this.listingPriceForm.value.leaseableArea;
      condoFeeArea = this.listingPriceService.convertForNumber(condoFeeArea);
      area = this.listingPriceService.convertForNumber(area);
      let monthlyCondoFee: any = condoFeeArea * area;
      monthlyCondoFee = this.listingPriceService.convertForDisplay(monthlyCondoFee);
      this.listingPriceForm.controls['monthlyCondoFee'].setValue(monthlyCondoFee);
      this.updateMonthlyCondo();
    }
  }

  updateMonthlyCondo() {
    let condoFee = this.listingPriceForm.value.monthlyCondoFee;
    let area = this.listingPriceForm.value.leaseableArea;
    condoFee = this.listingPriceService.convertForNumber(condoFee);
    area = this.listingPriceService.convertForNumber(area);
    let monthlyCondoFeePerArea: any = condoFee / area;
    if (isFinite(monthlyCondoFeePerArea) && monthlyCondoFeePerArea !== 0 && monthlyCondoFeePerArea !== '') {
      monthlyCondoFeePerArea = (monthlyCondoFeePerArea * 100) / 100;
      monthlyCondoFeePerArea = this.listingPriceService.convertForDisplay(monthlyCondoFeePerArea);
      if (monthlyCondoFeePerArea !== 0 && monthlyCondoFeePerArea !== '') {
        this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(monthlyCondoFeePerArea);
        this.listingPriceForm.controls['monthlyCondoFeePerArea'].updateValueAndValidity();
      }
    }
  }

  //IPTU FORMATTING
  updateIptuFee() {
    if (this.listingPriceForm.value.leaseableArea) {
      let iptuArea = this.listingPriceForm.value.monthlyIptuPerArea;
      let area = this.listingPriceForm.value.leaseableArea;
      iptuArea = this.listingPriceService.convertForNumber(iptuArea);
      area = this.listingPriceService.convertForNumber(area);
      let monthlyIptu: any = iptuArea * area;
      monthlyIptu = this.listingPriceService.convertForDisplay(monthlyIptu);
      this.listingPriceForm.controls['monthlyIptu'].setValue(monthlyIptu);
      this.updateMonthlyIptu();
    }
  }

  updateMonthlyIptu() {
    let iptu = this.listingPriceForm.value.monthlyIptu;
    let area = this.listingPriceForm.value.leaseableArea;
    iptu = this.listingPriceService.convertForNumber(iptu);
    area = this.listingPriceService.convertForNumber(area);
    let monthlyIptuPerArea: any = iptu / area;
    if (isFinite(monthlyIptuPerArea) && monthlyIptuPerArea !== 0 && monthlyIptuPerArea !== '') {
      monthlyIptuPerArea = (monthlyIptuPerArea * 100) / 100;
      monthlyIptuPerArea = this.listingPriceService.convertForDisplay(monthlyIptuPerArea);
      this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(monthlyIptuPerArea);
      this.listingPriceForm.controls['monthlyIptuPerArea'].updateValueAndValidity();
    }
  }

  iptuFeeBlur($event: any) {
    let monthlyIptu = this.listingPriceForm.value.monthlyIptu;
    monthlyIptu = this.listingPriceService.convertForNumber(monthlyIptu);
    if (isFinite(monthlyIptu) && monthlyIptu !== 0 && monthlyIptu !== '') {
      monthlyIptu = this.listingPriceService.convertForDisplay(monthlyIptu);
      this.listingPriceForm.controls['monthlyIptu'].setValue(monthlyIptu);
      this.updateMonthlyIptu();
    }
  }

  getI18nValue(textValue: string) {
    try {
      if (textValue && JSON.parse(textValue)) {
        return this.i18NService.getTranslation(textValue);
      } else {
        return '';
      }
    } catch (e) {
      console.error('failed to parse locale string -> listings search');
    }
  }
}
