import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { I18nService } from '@app/core/i18n.service';
import { ListingOfferType } from '@app/models/transaction-type.model';
import { map } from 'rxjs/operators';
import { LocationService } from '@app/shared/services/location.service';
import { CommonGenericService } from '../../core/common.service';
import { NearbyListingSvc } from '../../core/listings/nearby-listing.service';
import { BaseService } from './base-service';
import { Subject } from 'rxjs';
export class SpotBuildingsSearchService extends BaseService {
    constructor(http, i18n, locationService, commonService, nearbyListingSvc) {
        super();
        this.http = http;
        this.i18n = i18n;
        this.locationService = locationService;
        this.commonService = commonService;
        this.nearbyListingSvc = nearbyListingSvc;
        //this.showUniquePropertyListing = false;
        this.offeringPrice = false;
        this.offeringPricePerArea = false;
        this.monthlyRent = false;
        this.monthlyRentPerArea = false;
        this.rentableArea = false;
        this.ceilingHeight = false;
        this.hasParkingSpaces = false;
        this.hasDockRatio = false;
        this.hasLoadCapacity = false;
        this.trckParkingSpaces = false;
        this.parkingSpots = false;
        this.baySize = false;
        this.selectMarker$ = new Subject();
        this.moreFiltersRangeAPI = `/api/buildinglisting/more-filters-ranges/`;
        this.spotBuildingIdsAPI = `/api/spotbuildings/search/ids/fast`;
        this.countSpotByTypeAPI = `/api/spotbuildings/search/count/spot-type`;
        this.countSpotByListingClassAPI = `/api/spotbuildings/search/count/listing-class`;
        this.spotBuildingDetailsAPI = `/api/spotbuildings/search/detail`;
        this.spotBuildingMapPinsAPI = `/api/spotbuildings/search/map`;
        this.spotBuildingPropertySearchAPI = `/api/buildinglisting/titles`;
        this.spotBuildingSortAPI = `/api/spotbuildings/search/{spotBuildingType}/sort/{sortBy}/{direction}`;
        this.searchDtoFromUrlEndpoint = `/api/spotbuildings/search/dto-by-url/`;
        this.urlFromSearchDtoEndpoint = `/api/spotbuildings/search/url-by-search-dto`;
        this.spotSearchEndpoint = `/api/spot-search`;
        this.allSpotCompaniesAPI = `/api/all-spot-companies`;
    }
    initDefaultFilters() {
        this.industrialLeaseDefaultRangeFilters = {
            totalArea: { min: 100, max: 750000 },
            landArea: { min: 0, max: 0 },
            ceilingHeight: { min: 0, max: 30 },
            totalAskingPrice: { min: 0, max: 0 },
            askingPricePerArea: { min: 0, max: 0 },
            propertySize: { min: 0, max: 750000 },
            totalMonthlyRent: { min: 4500, max: 2000000 },
            monthlyRentPerArea: { min: 1, max: 75 },
            carParkingSpaces: { min: 0, max: 0 },
            numberFloor: { min: 0, max: 0 },
            yearBuilt: { minDate: new Date(1950, 0, 1), maxDate: new Date(2030, 0, 1) },
            yearRenovated: { minDate: new Date(1990, 0, 1), maxDate: new Date(2022, 0, 1) },
            monthlyCostPerPerson: { min: 0, max: 0 }
        };
        this.industrialSaleDefaultRangeFilters = {
            totalArea: { min: 50, max: 40000 },
            landArea: { min: 0, max: 3000000 },
            ceilingHeight: { min: 0, max: 30 },
            totalAskingPrice: { min: 100000, max: 50000000 },
            askingPricePerArea: { min: 500, max: 3000 },
            propertySize: { min: 0, max: 750000 },
            totalMonthlyRent: { min: 0, max: 0 },
            monthlyRentPerArea: { min: 0, max: 0 },
            carParkingSpaces: { min: 0, max: 0 },
            numberFloor: { min: 0, max: 0 },
            yearBuilt: { minDate: new Date(1950, 0, 1), maxDate: new Date(2040, 0, 1) },
            yearRenovated: { minDate: new Date(1990, 0, 1), maxDate: new Date(2022, 0, 1) },
            monthlyCostPerPerson: { min: 0, max: 0 }
        };
        this.otherLeaseDefaultRangeFilters = {
            totalArea: { min: 15, max: 98400 },
            landArea: { min: 0, max: 0 },
            ceilingHeight: { min: 0, max: 7 },
            totalAskingPrice: { min: 0, max: 0 },
            askingPricePerArea: { min: 0, max: 0 },
            propertySize: { min: 0, max: 120000 },
            totalMonthlyRent: { min: 500, max: 7500000 },
            monthlyRentPerArea: { min: 1, max: 300 },
            carParkingSpaces: { min: 1, max: 700 },
            numberFloor: { min: 1, max: 50 },
            yearBuilt: { minDate: new Date(1901, 0, 1), maxDate: new Date(2030, 0, 1) },
            yearRenovated: { minDate: new Date(1990, 0, 1), maxDate: new Date(2022, 0, 1) },
            monthlyCostPerPerson: { min: 0, max: 0 }
        };
        this.otherSaleDefaultRangeFilters = {
            totalArea: { min: 20, max: 98400 },
            landArea: { min: 0, max: 200000 },
            ceilingHeight: { min: 0, max: 20 },
            totalAskingPrice: { min: 10000, max: 1000000000 },
            askingPricePerArea: { min: 500, max: 50000 },
            propertySize: { min: 0, max: 120000 },
            totalMonthlyRent: { min: 0, max: 0 },
            monthlyRentPerArea: { min: 0, max: 0 },
            carParkingSpaces: { min: 1, max: 700 },
            numberFloor: { min: 1, max: 50 },
            yearBuilt: { minDate: new Date(1901, 0, 1), maxDate: new Date(2030, 0, 1) },
            yearRenovated: { minDate: new Date(1990, 0, 1), maxDate: new Date(2022, 0, 1) },
            monthlyCostPerPerson: { min: 0, max: 10000 }
        };
    }
    searchSpotBuildingIds(searchRequest, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append('page', page.toString());
            urlSearchParams.append('per_page', '10');
            urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
            return yield this.http
                .post(this.spotBuildingIdsAPI + '?' + urlSearchParams, searchRequest, {
                observe: 'response'
            })
                .pipe(map(val => {
                return {
                    headers: val.headers,
                    body: val.body
                };
            }))
                .toPromise();
        });
    }
    countSpotsByType(searchRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let payload = Object.assign({}, searchRequest);
            payload.listingLeaseOrSale = null;
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
            return yield this.http
                .post(this.countSpotByTypeAPI + '?' + urlSearchParams, payload, {
                observe: 'response'
            })
                .pipe(map(val => {
                return val.body;
            }))
                .toPromise();
        });
    }
    countSpotsByListingClass(searchRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let payload = Object.assign({}, searchRequest);
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
            return yield this.http
                .post(this.countSpotByListingClassAPI + '?' + urlSearchParams, payload, {
                observe: 'response'
            })
                .pipe(map(val => {
                return val.body;
            }))
                .toPromise();
        });
    }
    sortCurrentSpotBuildingIds(searchRequest) {
        const _super = Object.create(null, {
            interporlateURL: { get: () => super.interporlateURL }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const sortAPI = _super.interporlateURL.call(this, this.spotBuildingSortAPI, {
                spotBuildingType: searchRequest.spotBuildingType,
                sortBy: searchRequest.sortBy,
                direction: searchRequest.direction
            });
            return yield this.http
                .post(sortAPI, searchRequest.spotIds, {
                observe: 'response'
            })
                .pipe(map(val => {
                return {
                    headers: val.headers,
                    body: val.body
                };
            }))
                .toPromise();
        });
    }
    getSpotBuildingDetails(page, pageSize, spotBuildingIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let params = '';
            let detailsURL = `${this.spotBuildingDetailsAPI}?page=${page}&per_page=${pageSize}${params}`;
            return yield this.http
                .post(detailsURL, spotBuildingIds)
                .toPromise()
                .then((response) => {
                const listings = response;
                return listings;
            });
        });
    }
    getSpotBulidingMapPins(spotRequest, pageNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append('page', pageNumber.toString());
            urlSearchParams.append('per_page', '2000');
            urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
            return yield this.http
                .post(this.spotBuildingMapPinsAPI + '?' + urlSearchParams, this.handlingCoworkingPropertyType(spotRequest), {
                observe: 'response'
            })
                .pipe(map(val => {
                return {
                    headers: val.headers,
                    body: val.body
                };
            }))
                .toPromise();
        });
    }
    getPropertySearch(propertyType, buildingType, propertySubTypeId = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let url = `${this.spotBuildingPropertySearchAPI}/${propertyType}/${buildingType}/${this.i18n.getCurrentLanguage()}`;
            if (propertySubTypeId != null) {
                url = url + `?propertySubtypeId=${propertySubTypeId}`;
            }
            return this.http
                .get(url)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    initCheckboxFields() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let obj = {
                hasParkingSpaces: this.hasParkingSpaces,
                hasDockRatio: this.hasDockRatio,
                hasLoadCapacity: this.hasLoadCapacity
            };
            return obj;
        });
    }
    getCities() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.locationService.getCities();
        });
    }
    getStates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.locationService.getStates();
        });
    }
    getStatesByCountry(buildingTypeId, spotType, propertySubTypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.locationService.getStatesWithSpots(buildingTypeId, spotType, propertySubTypeId);
        });
    }
    getcitiesByState(buildingTypeId, stateId, spotType, propertySubTypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (stateId != null) {
                return this.locationService.getCitiesByStatesWithSpots(buildingTypeId, stateId, spotType);
            }
        });
    }
    getMinMaxOptions(dto) {
        return this.http.post(`${this.moreFiltersRangeAPI}`, this.handlingCoworkingPropertyType(dto)).pipe(map(val => val));
    }
    handlingCoworkingPropertyType(listingSearchDto) {
        let copySearchDTOForURL = Object.assign({}, listingSearchDto);
        if (listingSearchDto.buildingTypes && listingSearchDto.buildingTypes.includes(ListingOfferType.Coworking)) {
            copySearchDTOForURL.buildingTypes = null;
        }
        return copySearchDTOForURL;
    }
    // MAP functions
    createNearbyListingMarker(listings, searchRequest, isLoggedIn) {
        return listings.reduce((accumulator, listing) => {
            const hasValidLngLat = this.nearbyListingSvc.hasValidCoordinate(listing, 'lat', 'lng');
            if (hasValidLngLat) {
                let infoWindow = this.nearbyListingSvc.createSpotBuildingInfoWindow(listing, isLoggedIn);
                let marker = this.nearbyListingSvc.createMarker(listing, infoWindow);
                this.commonService.changeMarkerIcon(marker, 'mouseover', this.nearbyListingSvc.orangePin);
                this.commonService.changeMarkerIcon(marker, 'mouseout', this.nearbyListingSvc.bluePin);
                marker.addListener('click', (event) => {
                    event.domEvent.stopImmediatePropagation();
                    const prevSelectMarker = this.nearbyListingSvc.prevSelectedMarker(accumulator, marker)[0];
                    marker.setIcon(this.nearbyListingSvc.orangePin);
                    marker.set('isSelected', true);
                    if (window.innerWidth >= 768) {
                        marker.infoWindow.open(marker.getMap(), marker);
                    }
                    this.selectMarker$.next(listing);
                    // this.scrollToListingCard();
                    this.getNearByPropertyListing(marker.listingIds, listing, searchRequest);
                    if (prevSelectMarker) {
                        this.nearbyListingSvc.updatePrevSelectedMarker(prevSelectMarker);
                    }
                });
                marker.infoWindow.addListener('closeclick', () => {
                    marker.setIcon(this.nearbyListingSvc.bluePin);
                    marker.set('isSelected', false);
                    //  this.allMatchingListing = [];
                    //this.showUniquePropertyListing = false;
                });
                accumulator.push(marker);
            }
            return accumulator;
        }, []);
    }
    getNearByPropertyListing(listingIds, selectedBuilding, listingSearchDto) {
        let searchParam = Object.assign({}, listingSearchDto);
        //this.selectedBuilding = selectedBuilding;
        searchParam.listingIds = listingIds;
        /*
        this.listingService.getAllNearByPropertyListing(searchParam).then((result: any) => {
          this.allMatchingListing = result.body.listings;
          this.showUniquePropertyListing = true;
        });
        */
    }
    getClientPositionForMapSearches(position) {
        return this.nearbyListingSvc.getUserLocationCoordinate(position.coords);
    }
    setStaticMinMaxValues(filters) {
        let obj;
        obj = {
            dockRatio: { min: 0, max: 0 },
            loadCapacity: { min: 0, max: 0 },
            totalArea: { min: 0, max: filters.totalAreaEnd },
            landArea: { min: 0, max: filters.landAreaEnd },
            ceilingHeight: { min: 0, max: filters.ceilingHeightMax },
            totalAskingPrice: { min: 0, max: filters.totalAskingPriceEnd },
            askingPricePerArea: { min: 0, max: filters.askingPricePerAreaMax },
            propertySize: { min: 0, max: filters.propertySizeEnd },
            totalMonthlyRent: { min: 0, max: filters.totalMonthlyRentEnd },
            monthlyRentPerArea: { min: 0, max: filters.monthlyRentPerAreaMax },
            parkingSpaces: { min: 0, max: filters.parkingSpacesMax },
            numberFloor: { min: 0, max: filters.numberFloorEnd },
            yearBuilt: { minDate: new Date(1950, 0, 1), maxDate: new Date(2030, 0, 1) },
            yearRenovated: { minDate: new Date(1990, 0, 1), maxDate: new Date(2022, 0, 1) },
            monthlyCostPerPerson: { min: 0, max: filters.monthlyCostPerPersonMax }
        };
        return obj;
    }
    setSearchRangeFilters(searchRangeFilters, minmax) {
        let filters = {
            parkingSpacesMin: searchRangeFilters.parkingSpaces.lower !== minmax.parkingSpaces.min ||
                searchRangeFilters.parkingSpaces.upper !== minmax.parkingSpaces.max
                ? searchRangeFilters.parkingSpaces.lower
                : null,
            parkingSpacesMax: searchRangeFilters.parkingSpaces.lower !== minmax.parkingSpaces.min ||
                searchRangeFilters.parkingSpaces.upper !== minmax.parkingSpaces.max
                ? searchRangeFilters.parkingSpaces.upper
                : null,
            loadCapacityMin: searchRangeFilters.loadCapacity.lower !==
                minmax.loadCapacity.min /*||
            searchRangeFilters.loadCapacity.upper !== minmax.loadCapacity.*/
                ? searchRangeFilters.loadCapacity.lower
                : null,
            loadCapacityMax: searchRangeFilters.loadCapacity.lower !== minmax.loadCapacity.min ||
                searchRangeFilters.loadCapacity.upper !== minmax.loadCapacity.max
                ? searchRangeFilters.loadCapacity.upper
                : null,
            dockRatioMin: searchRangeFilters.dockRatio.lower !== minmax.dockRatio.min ||
                searchRangeFilters.dockRatio.upper !== minmax.dockRatio.max
                ? searchRangeFilters.dockRatio.lower
                : null,
            dockRatioMax: searchRangeFilters.dockRatio.lower !== minmax.dockRatio.min ||
                searchRangeFilters.dockRatio.upper !== minmax.dockRatio.max
                ? searchRangeFilters.dockRatio.upper
                : null,
            totalAreaIni: searchRangeFilters.totalArea.lower !== minmax.totalArea.min ||
                searchRangeFilters.totalArea.upper !== minmax.totalArea.max
                ? searchRangeFilters.totalArea.lower
                : null,
            totalAreaEnd: searchRangeFilters.totalArea.lower !== minmax.totalArea.min ||
                searchRangeFilters.totalArea.upper !== minmax.totalArea.max
                ? searchRangeFilters.totalArea.upper
                : null,
            landAreaIni: searchRangeFilters.landArea.lower !== minmax.landArea.min ||
                searchRangeFilters.landArea.upper !== minmax.landArea.max
                ? searchRangeFilters.landArea.lower
                : null,
            landAreaEnd: searchRangeFilters.landArea.lower !== minmax.landArea.min ||
                searchRangeFilters.landArea.upper !== minmax.landArea.max
                ? searchRangeFilters.landArea.upper
                : null,
            ceilingHeightMin: searchRangeFilters.ceilingHeight.lower !== minmax.ceilingHeight.min ||
                searchRangeFilters.ceilingHeight.upper !== minmax.ceilingHeight.max
                ? searchRangeFilters.ceilingHeight.lower
                : null,
            ceilingHeightMax: searchRangeFilters.ceilingHeight.lower !== minmax.ceilingHeight.min ||
                searchRangeFilters.ceilingHeight.upper !== minmax.ceilingHeight.max
                ? searchRangeFilters.ceilingHeight.upper
                : null,
            totalAskingPriceIni: searchRangeFilters.totalAskingPrice.lower !== minmax.totalAskingPrice.min ||
                searchRangeFilters.totalAskingPrice.upper !== minmax.totalAskingPrice.max
                ? searchRangeFilters.totalAskingPrice.lower
                : null,
            totalAskingPriceEnd: searchRangeFilters.totalAskingPrice.lower !== minmax.totalAskingPrice.min ||
                searchRangeFilters.totalAskingPrice.upper !== minmax.totalAskingPrice.max
                ? searchRangeFilters.totalAskingPrice.upper
                : null,
            askingPricePerAreaMin: searchRangeFilters.askingPricePerArea.lower !== minmax.askingPricePerArea.min ||
                searchRangeFilters.askingPricePerArea.upper !== minmax.askingPricePerArea.max
                ? searchRangeFilters.askingPricePerArea.lower
                : null,
            askingPricePerAreaMax: searchRangeFilters.askingPricePerArea.lower !== minmax.askingPricePerArea.min ||
                searchRangeFilters.askingPricePerArea.upper !== minmax.askingPricePerArea.max
                ? searchRangeFilters.askingPricePerArea.upper
                : null,
            propertySizeIni: searchRangeFilters.propertySize.lower !== minmax.propertySize.min ||
                searchRangeFilters.propertySize.upper !== minmax.propertySize.max
                ? searchRangeFilters.propertySize.lower
                : null,
            propertySizeEnd: searchRangeFilters.propertySize.lower !== minmax.propertySize.min ||
                searchRangeFilters.propertySize.upper !== minmax.propertySize.max
                ? searchRangeFilters.propertySize.upper
                : null,
            totalMonthlyRentIni: searchRangeFilters.totalMonthlyRent.lower !== minmax.totalMonthlyRent.min ||
                searchRangeFilters.totalMonthlyRent.upper !== minmax.totalMonthlyRent.max
                ? searchRangeFilters.totalMonthlyRent.lower
                : null,
            totalMonthlyRentEnd: searchRangeFilters.totalMonthlyRent.lower !== minmax.totalMonthlyRent.min ||
                searchRangeFilters.totalMonthlyRent.upper !== minmax.totalMonthlyRent.max
                ? searchRangeFilters.totalMonthlyRent.upper
                : null,
            monthlyRentPerAreaMin: searchRangeFilters.monthlyRentPerArea.lower !== minmax.monthlyRentPerArea.min ||
                searchRangeFilters.monthlyRentPerArea.upper !== minmax.monthlyRentPerArea.max
                ? searchRangeFilters.monthlyRentPerArea.lower
                : null,
            monthlyRentPerAreaMax: searchRangeFilters.monthlyRentPerArea.lower !== minmax.monthlyRentPerArea.min ||
                searchRangeFilters.monthlyRentPerArea.upper !== minmax.monthlyRentPerArea.max
                ? searchRangeFilters.monthlyRentPerArea.upper
                : null,
            numberFloorIni: searchRangeFilters.numberFloor.lower !== minmax.numberFloor.min ||
                searchRangeFilters.numberFloor.upper !== minmax.numberFloor.max
                ? searchRangeFilters.numberFloor.lower
                : null,
            numberFloorEnd: searchRangeFilters.numberFloor.lower !== minmax.numberFloor.min ||
                searchRangeFilters.numberFloor.upper !== minmax.numberFloor.max
                ? searchRangeFilters.numberFloor.upper
                : null,
            monthlyCostPerPersonMin: searchRangeFilters.monthlyCostPerPerson.lower !== minmax.monthlyCostPerPerson.min ||
                searchRangeFilters.monthlyCostPerPerson.upper !== minmax.monthlyCostPerPerson.max
                ? searchRangeFilters.monthlyCostPerPerson.lower
                : null,
            monthlyCostPerPersonMax: searchRangeFilters.monthlyCostPerPerson.lower !== minmax.monthlyCostPerPerson.min ||
                searchRangeFilters.monthlyCostPerPerson.upper !== minmax.monthlyCostPerPerson.max
                ? searchRangeFilters.monthlyCostPerPerson.upper
                : null
        };
        this.deleteFilterIfMaxValueIsNull(filters);
        return filters;
    }
    /**
     * If the maxixum value of a field is null, that means it is not necessary to apply a filter for that field.
     * @param filters
     */
    deleteFilterIfMaxValueIsNull(filters) {
        // Send min value as null as long as the max value is null.
        filters.dockRatioMin = filters.dockRatioMax ? filters.dockRatioMin : null;
        filters.loadCapacityMin = filters.loadCapacityMax ? filters.loadCapacityMin : null;
        filters.parkingSpacesMin = filters.parkingSpacesMax ? filters.parkingSpacesMin : null;
    }
    clearFilters(dto, checkboxValues) {
        if (checkboxValues) {
            checkboxValues.hasParkingSpaces = false;
            checkboxValues.hasDockRatio = false;
            checkboxValues.hasLoadCapacity = false;
        }
        if (dto.refrigerated)
            dto.refrigerated = false;
        if (dto.sluiceGate)
            dto.sluiceGate = false;
        if (dto.hasLockerRooms)
            dto.hasLockerRooms = false;
        if (dto.heliport)
            dto.heliport = false;
        if (dto.sprinklers)
            dto.sprinklers = false;
        if (dto.electricGenerator)
            dto.electricGenerator = false;
        if (dto.plugAndPlay)
            dto.plugAndPlay = false;
        if (dto.furnished)
            dto.furnished = false;
        if (dto.coreAndShel)
            dto.coreAndShel = false;
        if (dto.centralAirConditioning)
            dto.centralAirConditioning = false;
        if (dto.miniSplitAirConditioning)
            dto.miniSplitAirConditioning = false;
        if (dto.bicycleRack)
            dto.bicycleRack = false;
        if (dto.fiberOptic)
            dto.fiberOptic = false;
        if (dto.leedStatus)
            dto.leedStatus = false;
        if (dto.raisedFloor)
            dto.raisedFloor = false;
        if (dto.hourSecurity)
            dto.hourSecurity = false;
        if (dto.neverUsed)
            dto.neverUsed = false;
        if (dto.hasRestrooms)
            dto.hasRestrooms = false;
        if (dto.armoredCabin)
            dto.armoredCabin = false;
        if (dto.manufacturing)
            dto.manufacturing = false;
        //if (dto.logistics) dto.logistics = false;
        if (dto.officeSpaceAvailable)
            dto.officeSpaceAvailable = false;
        //if (dto.fireProtectionSystem) dto.fireProtectionSystem = false;
        if (dto.blockConstruction)
            dto.blockConstruction = false;
        if (dto.steelConstruction)
            dto.steelConstruction = false;
        if (dto.blockAndSteelConstruction)
            dto.blockAndSteelConstruction = false;
        if (dto.skyLights)
            dto.skyLights = false;
        if (dto.singleTenant)
            dto.singleTenant = false;
        if (dto.multiTenant)
            dto.multiTenant = false;
        if (dto.truckYard)
            dto.truckYard = false;
        if (dto.crossDocking)
            dto.crossDocking = false;
        if (dto.twentyFourHourSecurity)
            dto.twentyFourHourSecurity = false;
        if (dto.coreAndShel)
            dto.coreAndShel = false;
        if (dto.conferenceRoom)
            dto.conferenceRoom = false;
        if (dto.parkingAvailable)
            dto.parkingAvailable = false;
        if (dto.privateOutdoorSpace)
            dto.privateOutdoorSpace = false;
        if (dto.plugAndPlayConditionCoworking)
            dto.plugAndPlayConditionCoworking = false;
        if (dto.furnishedConditionCoworking)
            dto.furnishedConditionCoworking = false;
        if (dto.barista)
            dto.barista = false;
        if (dto.enhancedCleaning)
            dto.enhancedCleaning = false;
        if (dto.petFriendly)
            dto.petFriendly = false;
        if (dto.bulletProofCabin)
            dto.bulletProofCabin = false;
        return dto;
    }
    mapMinMaxOptions(filters, minMax) {
        let tags = {
            dockRatio: {
                upper: filters && filters.dockRatioMax ? filters.dockRatioMax : minMax.dockRatio.max,
                lower: filters && filters.dockRatioMin ? filters.dockRatioMin : minMax.dockRatio.min
            },
            loadCapacity: {
                upper: filters && filters.loadCapacityMax ? filters.loadCapacityMax : minMax.loadCapacity.max,
                lower: filters && filters.loadCapacityMin ? filters.loadCapacityMin : minMax.loadCapacity.min
            },
            parkingSpaces: {
                upper: filters && filters.parkingSpacesMax ? filters.parkingSpacesMax : minMax.parkingSpaces.max,
                lower: filters && filters.parkingSpacesMin ? filters.parkingSpacesMin : minMax.parkingSpaces.min
            },
            totalArea: {
                upper: filters && filters.totalAreaEnd ? filters.totalAreaEnd : minMax.totalArea.max,
                lower: filters && filters.totalAreaIni ? filters.totalAreaIni : minMax.totalArea.min
            },
            landArea: {
                upper: filters && filters.landAreaEnd ? filters.landAreaEnd : minMax.landArea.max,
                lower: filters && filters.landAreaIni ? filters.landAreaIni : minMax.landArea.min
            },
            ceilingHeight: {
                upper: filters && filters.ceilingHeightMax ? filters.ceilingHeightMax : minMax.ceilingHeight.max,
                lower: filters && filters.ceilingHeightMin ? filters.ceilingHeightMin : minMax.ceilingHeight.min
            },
            totalAskingPrice: {
                upper: filters && filters.totalAskingPriceEnd ? filters.totalAskingPriceEnd : minMax.totalAskingPrice.max,
                lower: filters && filters.totalAskingPriceIni ? filters.totalAskingPriceIni : minMax.totalAskingPrice.min
            },
            askingPricePerArea: {
                upper: filters && filters.askingPricePerAreaMax ? filters.askingPricePerAreaMax : minMax.askingPricePerArea.max,
                lower: filters && filters.askingPricePerAreaMin ? filters.askingPricePerAreaMin : minMax.askingPricePerArea.min
            },
            propertySize: {
                upper: filters && filters.propertySizeEnd ? filters.propertySizeEnd : minMax.propertySize.max,
                lower: filters && filters.propertySizeIni ? filters.propertySizeIni : minMax.propertySize.min
            },
            totalMonthlyRent: {
                upper: filters && filters.totalMonthlyRentEnd ? filters.totalMonthlyRentEnd : minMax.totalMonthlyRent.max,
                lower: filters && filters.totalMonthlyRentIni ? filters.totalMonthlyRentIni : minMax.totalMonthlyRent.min
            },
            monthlyRentPerArea: {
                upper: filters && filters.monthlyRentPerAreaMax ? filters.monthlyRentPerAreaMax : minMax.monthlyRentPerArea.max,
                lower: filters && filters.monthlyRentPerAreaMin ? filters.monthlyRentPerAreaMin : minMax.monthlyRentPerArea.min
            },
            numberFloor: {
                upper: filters && filters.numberFloorEnd ? filters.numberFloorEnd : minMax.numberFloor.max,
                lower: filters && filters.numberFloorIni ? filters.numberFloorIni : minMax.numberFloor.min
            },
            yearBuilt: {
                upper: filters && filters.yearBuiltEnd ? filters.yearBuiltEnd : minMax.yearBuilt.maxDate,
                lower: filters && filters.yearBuiltIni ? filters.yearBuiltIni : minMax.yearBuilt.minDate
            },
            yearRenovated: {
                upper: filters && filters.yearRenovatedEnd ? filters.yearRenovatedEnd : minMax.yearRenovated.maxDate,
                lower: filters && filters.yearRenovatedIni ? filters.yearRenovatedIni : minMax.yearRenovated.minDate
            },
            monthlyCostPerPerson: {
                upper: filters && filters.monthlyCostPerPersonMax
                    ? filters.monthlyCostPerPersonMax
                    : minMax.monthlyCostPerPerson.max,
                lower: filters && filters.monthlyCostPerPersonMin ? filters.monthlyCostPerPersonMin : minMax.monthlyCostPerPerson.min
            }
        };
        return tags;
    }
    readSearchDTOFromStorage(itemName, defaultValue) {
        const searchDtoItem = itemName;
        const filterPreferencesDTO = this.readFilterPreferencesFrom(searchDtoItem);
        if (filterPreferencesDTO && filterPreferencesDTO.length) {
            return JSON.parse(filterPreferencesDTO);
        }
        return defaultValue;
    }
    saveSearchDtoOnStorage(itemName, listingSearchDto) {
        const listingSearchDtoCopy = Object.assign({}, listingSearchDto);
        const searchDto = JSON.stringify(listingSearchDtoCopy);
        this.saveFilterPreferencesFrom(itemName, searchDto);
    }
    saveFilterPreferencesFrom(itemName, searchDto) {
        sessionStorage.setItem(itemName, searchDto);
    }
    readFilterPreferencesFrom(selectedFilterKey) {
        return sessionStorage.getItem(selectedFilterKey);
    }
    removeFilterPreferencesFrom(selectedFilterKey) {
        sessionStorage.removeItem(selectedFilterKey);
    }
    findSearchDTOFromURL(searchUrl, keyword, buildingName, spotset) {
        let urlSearchParams = new URLSearchParams();
        if (keyword) {
            urlSearchParams.append('keyword', keyword);
        }
        if (buildingName) {
            urlSearchParams.append('buildingName', buildingName);
        }
        if (spotset) {
            urlSearchParams.append('spotset', spotset);
        }
        urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
        searchUrl += '?' + urlSearchParams.toString();
        return this.http.get(this.searchDtoFromUrlEndpoint + searchUrl).toPromise();
    }
    getUrlFromListingsSearchDto(searchDto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http
                .post(this.urlFromSearchDtoEndpoint, this.handlingCoworkingPropertyType(searchDto))
                .toPromise();
        });
    }
    findSavedSearchBy(userId, savedSearchId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.spotSearchEndpoint + `/${userId}/${savedSearchId}`).toPromise();
        });
    }
    findAllSpotCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.allSpotCompaniesAPI).toPromise();
        });
    }
}
