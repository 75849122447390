import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { ListPropertyService, ListingService, ListingDetailService, EventCheckerService } from '@app/core/listings';
import { AdminUpdatesComponent } from '@app/user-home/my-listing/admin-updates/admin-updates.component';
import { I18nService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { RegisterListingService } from '@app/core/register-listing.service';

@Component({
  selector: 'app-my-listing-edit',
  templateUrl: './my-listing-edit.component.html',
  styleUrls: ['./my-listing-edit.component.scss']
})
export class MyListingEditComponent implements OnInit {
  listing: any;
  tempListing: any;
  spotAdmin: Boolean;
  listingStatus: any;
  nextListing: any;
  deleteDraftHeader: string;
  deleteDraftSubHeader: string;
  discardChangesLbl: string;
  cancelLbl: string;
  fromMyListings: boolean;
  constructor(
    private data: MatDialogRef<any>,
    private dialog: MatDialog,
    private router: Router,
    private alertCtrl: AlertController,
    private listingService: ListingService,
    private listingDetailService: ListingDetailService,
    private popoverCtrl: PopoverController,
    private _eventChecker: EventCheckerService,
    private _ts: TranslateService,
    private i18nService: I18nService,
    private registerListingService: RegisterListingService
  ) {
    if (this.data._containerInstance._config.data.data) {
      let listing: any = this.data._containerInstance._config.data.data;
      this.fromMyListings = this.data._containerInstance._config.data.myListingPage;
      this.nextListing = listing;
      let listingId: any = listing.id;
      this.checkListingStatus(listingId);
    }
    this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');

    this.deleteDraftHeader = i18nService.get('global.my-list-menu.deleteDraft');
    this.deleteDraftSubHeader = i18nService.get('global.my-list-menu.deleteDraftSubHeader');
    this.discardChangesLbl = i18nService.get('global.discardChanges');
    this.cancelLbl = i18nService.get('Cancel');
  }

  ngOnInit(): void {}

  async checkListingStatus(listingId: any) {
    await this.getListing(listingId);
    this.listingStatus = this.tempListing.listingStatus;
    this.setListing(this.tempListing);
    return;
  }

  async getListing(listingId: any) {
    let listingDetail: any = await this.listingDetailService.getListingDetails(listingId).toPromise();
    let listingPhotos: any = await this.listingDetailService.getListingPhotos(listingId).toPromise();
    this.tempListing = listingDetail.listing;
    this.tempListing.listingPhotos = listingPhotos;
  }

  setListing(listing: any) {
    this.listing = listing;
    return this.listing;
  }

  dismissAsDialog() {
    this.dialog.closeAll();
  }

  discardDraft(event: any) {
    this.discardConfirmMessage();
    this.dialog.closeAll();
  }

  async reactivateListing(event: any) {
    this.displayAlert();
    this.dialog.closeAll();
  }

  async deactivateListing(event: any) {
    this.deactivateAlert();
    this.dialog.closeAll();
  }

  async reviseListing() {
    this.router.navigateByUrl(`/user-home/my-listings/create-property/${this.listing.id}`, {
      state: { listing: this.listing, myListingPage: this.fromMyListings, date: new Date().getTime() },
      replaceUrl: true,
      queryParams: { date: new Date().getTime() }
    });
    this.dismissAsDialog();
  }

  showSubmitForApprovalOption() {
    return (
      !this.checkRole('ROLE_LISTING_ADMIN') &&
      !this.checkRole('ROLE_SPOT_ADMIN') &&
      (this.listing.listingStatus.id === 7 || this.listing.listingStatus.id === 8)
    );
  }

  async submitForApproval() {
    try {
      let res: any = await this.registerListingService.updateToApproval(this.listing);
      if (res) {
        this._eventChecker.updateListing$.next('refresh');
      }
      this.dismissAsDialog();
    } catch (error) {
      console.error('Error Submitting Listing: ', error);
    }
  }

  async renewListing(listingId: any) {
    this.router.navigateByUrl('/user-home/current-plans', {
      state: { revise: true, listingId: listingId, listing: this.listing }
    });
    this.dismissAsDialog();
  }

  async updateReactivatedListing() {
    /* let listing = await this.purchaseService.reactivateListing(this.listing.id);
    if (listing) {
      this._eventChecker.updateListing$.next(listing);
      this.dismissAsDialog();
    } */
  }

  async updateDeactivatedListing() {
    /* let listing = await this.purchaseService.deactivateListing(this.listing.id);
    if (listing) {
      this._eventChecker.updateListing$.next(listing);
      this.dismissAsDialog();
    } */
  }

  async deactivateAlert() {
    const alert = await this.alertCtrl.create({
      header: this._ts.instant('global.deactivateListing'),
      subHeader: this._ts.instant('global.deactivateListingText'),
      cssClass: 'ion-alert-listings',
      buttons: [
        {
          text: this._ts.instant('Cancel'),
          role: 'Cancel',
          handler: () => {}
        },
        {
          text: this._ts.instant('Ok'),
          handler: () => {
            this.updateDeactivatedListing();
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {
      //this.router.navigateByUrl('/user-home/my-listings');
    });
    await alert.present();
  }

  async displayAlert() {
    const alert = await this.alertCtrl.create({
      header: this._ts.instant('global.reactivateListing'),
      subHeader: this._ts.instant('global.reactivateListingText'),
      cssClass: 'ion-alert-listings',
      buttons: [
        {
          text: this._ts.instant('Cancel'),
          role: 'Cancel',
          handler: () => {}
        },
        {
          text: this._ts.instant('Ok'),
          handler: () => {
            this.updateReactivatedListing();
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {
      //this.router.navigateByUrl('/user-home/my-listings');
    });
    await alert.present();
  }

  async adminUpdates(ev: any) {
    this.dialog.closeAll();
    const popover = await this.popoverCtrl.create({
      component: AdminUpdatesComponent,
      event: ev,
      cssClass: 'popover-renewal',
      componentProps: { listing: this.listing },
      backdropDismiss: false
    });

    popover.onDidDismiss().then((popoverData: any) => {
      if (popoverData.data) {
      }
    });
    return await popover.present();
  }

  async discardConfirmMessage() {
    const cancelTxt = this.i18nService.get('Cancel');
    const discardTxt = this.i18nService.get('global.discardChanges');

    const alert = await this.alertCtrl.create({
      header: this.deleteDraftHeader,
      subHeader: this.deleteDraftSubHeader,
      cssClass: 'ion-alert-listings',
      buttons: [
        {
          text: cancelTxt,
          role: 'Cancel',
          handler: () => {}
        },
        {
          text: discardTxt,
          handler: () => {
            this.listingService
              .deleteDraft(this.listing.id)
              .toPromise()
              .then((result: any) => {
                this.listingService.updateModel('reload');
                this.router.navigateByUrl('/user-home/my-listings');
              });
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {
      //this.router.navigateByUrl('/user-home/my-listings');
    });
    await alert.present();
  }

  checkRole(role: string) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  isUserAllowToEditPendingApprovalListing() {
    return this.registerListingService.isUserAllowToEditPendingApprovalListing(this.listing);
  }

  isRegularUserEditingRevisionListing() {
    return (
      !this.checkRole('ROLE_LISTING_ADMIN') && !this.checkRole('ROLE_SPOT_ADMIN') && this.listing.listingStatus.id === 8
    );
  }
}
