<ion-content #contentBlock class="main__container">
  <div class="main__container__center">
    <div class="favorites__section">
      <span class="favorites__section__title">{{ 'global.myDashboard' | translate }}</span>
    </div>
    <div class="favorites__section">
      <mat-tab-group class="w-100" mat-align-tabs="start" [(selectedIndex)]="selectedIndex">
        <mat-tab [label]="'global.list-your-property.favorites' | translate">
          <div class="favorites__section__results">
            <app-spot-card-tabs
              [isFavorite]="isFavorite"
              [spotBuildingSearchRequest]="spotBuildingSearchRequest"
              hideStatesFilter="true"
              showHeaderSorting="true"
              onlyFavorites="true"
              portfolioFor="FAVORITE_SEARCH"
              (totalCountEvent)="updateCounts($event)"
            >
            </app-spot-card-tabs>
          </div>
        </mat-tab>
        <mat-tab [label]="'favorites.spotSet' | translate">
          <app-spot-sets (eventSpotSet)="eventSpotSet($event)"></app-spot-sets>
        </mat-tab>
        <mat-tab [label]="'favorites.tourBook' | translate">
          <app-tour-books (changeStepOutput)="changeStep($event)" [countSpotSet]="countSpotSet"></app-tour-books>
        </mat-tab>
        <mat-tab [label]="'favorites.savedSearch' | translate">
          <app-saved-search-dashboard
            (changeStepOutput)="changeStep($event)"
            [countSpotSet]="countSpotSet"
          ></app-saved-search-dashboard>
        </mat-tab>

        <!-- <mat-tab [label]="'favorites.search' | translate"> </mat-tab> -->
      </mat-tab-group>
    </div>
  </div>

  <div class="scrollToTop">
    <app-scroll-to-top
      [showComponent]="displayScrollToTop"
      (scroll)="contentBlock.scrollToTop()"
      [target]="contentBlock"
      scroller
    >
    </app-scroll-to-top>
  </div>
  <app-footer inViewport (inViewportAction)="isFooterVisible($event)"></app-footer>
</ion-content>
