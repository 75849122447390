import * as tslib_1 from "tslib";
import { ListingTypePopoverComponent } from '@app/search-navigation/components/listing-type-popover';
import { PopoverController } from '@ionic/angular';
import { ListingProptypePopoverComponent } from '@app/search-navigation/components/listing-proptype-popover';
import { CoworkingPopoverComponent } from '@app/search-navigation/components/coworking-popover';
import { ListingLocationPopoverComponent } from '@app/search-navigation/components/listing-location-popover';
import { PropertyTypePopoverComponent } from '@app/search-navigation/components/property-type-popover';
import { RenewExtendModalComponent } from '@app/user-home/my-listing/renew-extend-modal/renew-extend-modal.component';
import { MoreFiltersSpotbuildingsComponent } from '@app/spot-buildings/components/search/more-filters/more-filters-spotbuildings.component';
import { SpaceDetailsComponent } from '../../spot-buildings/components/detail/space-details/space-details.component';
import { SpotBuildingDTO } from '../../models/spot-buildings/spot-buildings.model';
import { RequestInformationComponent } from '../../spot-buildings/components/request-information/request-information.component';
import { imageUploadSpacesBuilding } from '@app/spot-buildings/components/create/spaces-buildings/image-upload-spaces-building/image-upload-spaces-building';
import { VideoComponent } from '../../shared/video/video.component';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';
import { ImageGalleryComponent } from '@app/shared/image-gallery/image-gallery.component';
import { SocialMediaComponent } from '../../shared/social-media/social-media.component';
export class PopoverCtrlHelper {
    constructor(popoverCtrl, streamingMedia) {
        this.popoverCtrl = popoverCtrl;
        this.streamingMedia = streamingMedia;
    }
    createNewListingTypePopover(ev, searchModel) {
        const listingType = searchModel.listingType;
        const popover = this.popoverCtrl.create({
            component: ListingTypePopoverComponent,
            event: ev,
            cssClass: 'listing-type-popover',
            componentProps: { listType: listingType, searchDto: searchModel },
            backdropDismiss: true
        });
        return popover;
    }
    openNewListingTypePopover(ev, searchModel, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.createNewListingTypePopover(ev, searchModel);
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    if (callback) {
                        callback(popoverData.data);
                    }
                }
            });
            return yield popover.present();
        });
    }
    // More Popover
    createMorePopover(ev, checkBoxValues, searchModel, listingSearchDto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.popoverCtrl.create({
                component: ListingProptypePopoverComponent,
                event: ev,
                cssClass: 'popover-type-search',
                componentProps: {
                    propType: searchModel.propertyType,
                    searchDto: listingSearchDto,
                    checkboxValues: checkBoxValues
                },
                backdropDismiss: true
            });
        });
    }
    createMorePopoverBuildingListing(ev, checkBoxValues, searchModel, searchRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.popoverCtrl.create({
                component: MoreFiltersSpotbuildingsComponent,
                event: ev,
                cssClass: 'popover-type-search-filters',
                componentProps: {
                    propType: searchModel.propertyType,
                    searchDto: searchRequest,
                    checkboxValues: checkBoxValues
                },
                backdropDismiss: true
            });
        });
    }
    openMorePopover(ev, checkBoxValues, searchModel, listingSearchDto, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.createMorePopover(ev, checkBoxValues, searchModel, listingSearchDto);
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    if (callback) {
                        callback(popoverData.data);
                    }
                }
            });
            return yield popover.present();
        });
    }
    openMorePopoverForBuildingListing(ev, checkBoxValues, searchModel, listingSearchDto, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.createMorePopoverBuildingListing(ev, checkBoxValues, searchModel, listingSearchDto);
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    if (callback) {
                        callback(popoverData.data);
                    }
                }
            });
            return yield popover.present();
        });
    }
    openCoworkingPopover(ev, listingSearchDto, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: CoworkingPopoverComponent,
                event: ev,
                cssClass: 'coworking-popover',
                componentProps: { searchDto: listingSearchDto },
                backdropDismiss: false
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    listingSearchDto = popoverData.data;
                    if (callback) {
                        callback(popoverData.data);
                    }
                }
            });
            return yield popover.present();
        });
    }
    openLocationPopover(ev, locationFilters, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: ListingLocationPopoverComponent,
                event: ev,
                cssClass: 'popover-location-search',
                componentProps: locationFilters,
                backdropDismiss: true
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData && popoverData.data && callback) {
                    callback(popoverData.data);
                }
            });
            return yield popover.present();
        });
    }
    openNewPropertyTypePopover(ev, listingSearchDto, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const propertyType = listingSearchDto.buildingTypes && listingSearchDto.buildingTypes.length > 0
                ? listingSearchDto.buildingTypes[0]
                : null;
            const popover = yield this.popoverCtrl.create({
                component: PropertyTypePopoverComponent,
                event: ev,
                cssClass: listingSearchDto.buildingTypes
                    ? 'popover-property-search'
                    : !propertyType
                        ? 'property-type-popover'
                        : 'popover-type-search',
                componentProps: { propType: propertyType, searchDto: listingSearchDto },
                backdropDismiss: true
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    listingSearchDto = popoverData.data;
                    if (callback) {
                        callback(listingSearchDto);
                    }
                }
            });
            return yield popover.present();
        });
    }
    openRenewExtendListingsModal(ev, listingIds, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: RenewExtendModalComponent,
                event: ev,
                cssClass: 'popover-renew-extend',
                componentProps: { listingIds: listingIds },
                backdropDismiss: false
            });
            popover.onDidDismiss().then((popoverData) => {
                if (popoverData.data) {
                    callback(popoverData.data);
                }
            });
            return yield popover.present();
        });
    }
    openSpacesDetailPopover(ev, space, spotBuilding, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: SpaceDetailsComponent,
                event: ev,
                cssClass: 'space-detail-popover',
                componentProps: { space: space, spot: spotBuilding },
                backdropDismiss: true
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    if (callback) {
                        callback(popoverData.data);
                    }
                    if (popoverData.data.requestInfo) {
                        const space = popoverData.data.space;
                        const spot = popoverData.data.spot;
                        const spotTitle = popoverData.data.spotTitle;
                        const requestType = popoverData.data.requestType;
                        const agents = [];
                        if (space.agentDTO) {
                            agents.push(space.agentDTO);
                            if (space.secondAgentDTO) {
                                agents.push(space.secondAgentDTO);
                            }
                        }
                        let requestInfoInput = {
                            requestTitle: spotTitle,
                            companyLogoImageId: spot.companyLogoImageId,
                            agents: agents,
                            spotId: spot.id,
                            requestType: requestType
                        };
                        this.openRequestInformationPopup(popoverData.data.event, requestInfoInput);
                    }
                }
            });
            return yield popover.present();
        });
    }
    openRequestInformationPopup(ev, reqInfoInput, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: RequestInformationComponent,
                event: ev,
                cssClass: 'request-info-popover',
                componentProps: { reqInfoInput: reqInfoInput },
                backdropDismiss: true
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    if (callback) {
                        callback(popoverData.data);
                    }
                }
            });
            return yield popover.present();
        });
    }
    openGalleryImageComponent(ev, photos, spotTitle, listingType, description, topImage, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: ImageGalleryComponent,
                event: ev,
                cssClass: 'gallery-img-popover',
                componentProps: {
                    photos: photos,
                    fromPopup: true,
                    spotTitle: spotTitle,
                    listingType: listingType,
                    description: description,
                    topImage: topImage
                },
                backdropDismiss: true
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    if (callback) {
                        callback(popoverData.data);
                    }
                }
            });
            return yield popover.present();
        });
    }
    openSocialMediaPopup(ev, socialMediaInput, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: SocialMediaComponent,
                event: ev,
                cssClass: 'social-media-popover',
                componentProps: { socialMediaInput: socialMediaInput },
                backdropDismiss: true
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    if (callback) {
                        callback(popoverData.data);
                    }
                }
            });
            return yield popover.present();
        });
    }
    openVideoTourPopup(ev, videoURL, spotTitle, isMobile, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            /* if (false && isMobile) {
              if (videoURL) {
                let options: StreamingVideoOptions = {
                  successCallback: () => {
                    console.log('Video played');
                  },
                  errorCallback: (e: any) => {
                    console.log('Error streaming', +e);
                    callback(e);
                  },
                  orientation: 'landscape',
                  shouldAutoClose: false,
                  controls: true
                };
        
                try {
                  this.streamingMedia.playVideo(videoURL, options);
                } catch (err) {
                  console.error(err);
                  if (callback) {
                    callback(err);
                  }
                }
              }
              return;
            } */
            const popover = yield this.popoverCtrl.create({
                component: VideoComponent,
                event: ev,
                cssClass: 'video-player-popup',
                componentProps: { videoURL: videoURL, fromPopup: true, spotTitle: spotTitle },
                backdropDismiss: true
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    if (callback) {
                        callback(popoverData.data);
                    }
                }
            });
            return yield popover.present();
        });
    }
    openImageUploadSpaceBuilding(spaceId, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: imageUploadSpacesBuilding,
                cssClass: 'popover-type-Space',
                componentProps: { spaceId: spaceId },
                backdropDismiss: false
            });
            popover.onDidDismiss().then(popoverData => {
                callback(true);
            });
            return yield popover.present();
        });
    }
}
