import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListPropertyService } from '@app/core/listings';
import * as CartActions from '@app/store/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { AlertController } from '@ionic/angular';
import { RegisterService } from '@app/core/register.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { I18nService } from '@app/core';
import { Location } from '@angular/common';
export class ListCartComponent {
    constructor(breakpointObserver, router, store, listPropertyService, alertCtrl, registerService, i18NService, _location) {
        this.breakpointObserver = breakpointObserver;
        this.router = router;
        this.store = store;
        this.listPropertyService = listPropertyService;
        this.alertCtrl = alertCtrl;
        this.registerService = registerService;
        this.i18NService = i18NService;
        this._location = _location;
        this.isMobile = false;
        this.cart = [];
        this.typeSelection = [];
        this.totalPrice = 0;
        this.selectOptions = {
            cssClass: 'remove-ok'
        };
        this.upgrade = [];
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
            this.isMobile = result.matches;
        });
        this.faMapMarkerAlt = faMapMarkerAlt;
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.initDropdowns();
        this.clearPurchaseSelectionState();
    }
    ngOnInit() {
        this.store.select(CartActions.GetItem).subscribe((store) => {
            this.cart = store.cart.items;
            this.cart.forEach((item, index) => {
                if (item.upgrade) {
                    this.upgrade[index] = true;
                }
                else {
                    this.upgrade[index] = false;
                }
            });
            this.totalPrice = this.updateTotalPrice();
        });
        this.paymentProcessedHeader = this.i18NService.get('global.purchase-options.paymentProcessed');
        this.paymentProcessedSubHeader = this.i18NService.get('global.purchase-options.paymentProcessed');
        this.paymentProcessedOk = this.i18NService.get('global.purchase-options.paymentProcessedOK');
    }
    clearPurchaseSelectionState() {
        this.store.dispatch(CartActions.ClearSelection({ payload: { item: null } }));
    }
    nameChange(event) {
        if (event.value && event.value.id) {
            this.offeredByUserId = event.value;
        }
    }
    updateListing(premium, index) {
        let flag;
        if (premium) {
            flag = false;
        }
        else {
            flag = true;
        }
        let updatedItem = this.updateCartObject(flag, index);
        this.store.dispatch(CartActions.UpdateItem({ payload: { item: updatedItem, index: index } }));
    }
    updateTotalPrice() {
        this.totalPrice = 0;
        this.cart.forEach((item, index) => {
            if (item.upgrade) {
                this.totalPrice = this.totalPrice = this.totalPrice + item.premiumPrice * item.numOfListings;
            }
            else {
                this.totalPrice = this.totalPrice + item.listingPricePerMonth * item.numOfListings;
            }
        });
        this.listPropertyService.setCartTotalPrice(this.totalPrice);
        this.listPropertyService.setCartItems(this.cart);
        return this.totalPrice;
    }
    removeFromCart(id, index) {
        this.store.dispatch(CartActions.RemoveItem({ payload: { item: { id: id }, index: index } }));
    }
    updateCartObject(premium, index) {
        const obj = Object.assign({}, this.cart[index], { upgrade: premium });
        return obj;
    }
    skipPayment() {
        let price = this.listPropertyService.getCartTotalPrice() + '00';
        let cartElements = this.listPropertyService.getCartItems();
        var loginFormObject = {};
        loginFormObject.purchaseOptions = cartElements;
        loginFormObject.paymentInstallments = 1;
        loginFormObject.paymentAmount = 0;
        if (this.spotAdmin) {
            loginFormObject.userId = this.offeredByUserId ? this.offeredByUserId.id : null;
            loginFormObject.companyId = this.selectCompany.id;
        }
        this.listPropertyService
            .skipPaymentAPI(loginFormObject)
            .toPromise()
            .then((result) => {
            this.cart.forEach((item, index) => {
                this.removeFromCart(item.id, index);
            });
            this.showPaymentMessage();
        });
    }
    navigateToPaymentPage() {
        let spotAdminSelection = {};
        if (this.selectCompany || this.offeredByUserId) {
            spotAdminSelection.companyId = this.selectCompany.id;
            spotAdminSelection.userId = this.offeredByUserId.id;
        }
        this.listPropertyService.setPaymentSpotAdminSelection(spotAdminSelection);
        this.router.navigateByUrl('/payment');
    }
    addListing() {
        this.router.navigateByUrl('/list-property');
    }
    goBack() {
        this.router.navigateByUrl('/list-property');
    }
    back() {
        this._location.back();
    }
    showPaymentMessage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                header: this.paymentProcessedHeader,
                subHeader: this.paymentProcessedSubHeader,
                buttons: [this.paymentProcessedOk]
            });
            alert.onDidDismiss().then(() => {
                this.router.navigateByUrl('/user-home/my-listings');
            });
            yield alert.present();
        });
    }
    checkRole(role) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    }
    initDropdowns() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.allCompanies = yield this.getCompanies();
            this.user = JSON.parse(localStorage.getItem('user'));
            this.users = yield this.getUsersByCompany(this.user.company.id);
            let index = this.allCompanies.findIndex((v) => {
                return v.id === this.user.company.id;
            });
            let userIndex = this.users.findIndex((v) => {
                return v.id === this.user.id;
            });
            this.selectCompany = this.allCompanies[index];
            this.offeredByUserId = this.users[userIndex];
        });
    }
    getCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getListingCompanies().toPromise();
        });
    }
    getUsersByCompany(companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getUsersByCompany(companyId).toPromise();
        });
    }
    portChange($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.users = yield this.getUsersByCompany($event.value.id);
            this.agentComponent.clear();
            this.offeredByUserId = null;
        });
    }
}
