<form [formGroup]="listingTypeForm" class="full-width" name="listingTypeForm" autocomplete="off">
  <!-- <ion-row class="flex-row full-width heading-title">
    <p class="center blue" translate="global.list-your-property.typePublish"></p>
  </ion-row> -->
  <ion-label class="full-width blue">
    {{ 'global.list-your-property.typeOffer' | translate }}
  </ion-label>
  <ion-row class="full-width">
    <ion-segment
      (click)="changeSaleType()"
      mode="ios"
      formControlName="listingType"
      class="segment-block"
      color="secondary"
    >
      <ion-segment-button class="segment-border width-30 border-radius-unset" value="forRent">
        <ion-label translate="global.list-your-property.forLease"></ion-label>
      </ion-segment-button>

      <ion-segment-button
        class="segment-border border-radius-unset"
        [ngClass]="{ 'width-30': !isMobile, 'width-60': isMobile }"
        value="forCoworking"
      >
        <ion-label translate="global.list-your-property.forCoworking"></ion-label>
      </ion-segment-button>

      <ion-segment-button class="segment-border width-30 border-radius-unset" checked value="forSale">
        <ion-label translate="global.list-your-property.forSale"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-row>

  <ion-label class="full-width blue margin-top-15"
    >{{ 'global.list-your-property.typeProperty' | translate }}
  </ion-label>
  <ion-row class="full-width">
    <ion-segment
      mode="ios"
      (click)="checkBuildingType()"
      formControlName="commericalType"
      class="segment-block"
      color="secondary"
    >
      <ion-segment-button class="segment-border border-radius-unset width-30" checked value="1001">
        <ion-label translate="global.list-your-property.industrial"></ion-label>
      </ion-segment-button>
      <ion-segment-button class="segment-border border-radius-unset width-30" value="2001">
        <ion-label translate="global.list-your-property.office"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-row>

  <ion-label class="full-width blue margin-top-15">{{ 'global.list-your-property.subType' | translate }} </ion-label>
  <ion-row class="full-width">
    <ion-col size="6" *ngFor="let type of subTypes; let i = index">
      <button
        [ngClass]="{ 'sub-type': type.selected, 'no-pointer-events': type.disabled }"
        class="selected-white"
        (click)="setSubTypes(i)"
      >
        {{ type.name }}
      </button>
    </ion-col>
  </ion-row>
</form>
