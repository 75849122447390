import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListPropertyService } from '@app/core/listings';
import * as CartActions from '@app/store/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { AlertController } from '@ionic/angular';
import { RegisterService } from '@app/core/register.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { I18nService } from '@app/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-listing-cart',
  templateUrl: './list-cart.component.html',
  styleUrls: ['./list-cart.component.scss']
})
export class ListCartComponent implements OnInit {
  isMobile: boolean = false;
  cart: any = [];
  typeSelection: any = [];
  upgradeType: any;
  totalPrice: any = 0;
  selectOptions: any = {
    cssClass: 'remove-ok'
  };
  upgrade: any = [];
  faMapMarkerAlt: any;
  user: any;
  users: any;
  selectCompany: any;
  offeredByUserId: any;
  admin: any;
  allCompanies: any;
  spotAdmin: any;
  listingAdmin: any;
  paymentProcessedHeader: string;
  paymentProcessedSubHeader: string;
  paymentProcessedOk: string;
  @ViewChild('agentComponent', { static: false }) agentComponent?: IonicSelectableComponent;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private store: Store<any>,
    private listPropertyService: ListPropertyService,
    private alertCtrl: AlertController,
    private registerService: RegisterService,
    private i18NService: I18nService,
    private _location: Location
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.faMapMarkerAlt = faMapMarkerAlt;
    this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
    this.initDropdowns();
    this.clearPurchaseSelectionState();
  }

  ngOnInit(): void {
    this.store.select(CartActions.GetItem).subscribe((store: any) => {
      this.cart = store.cart.items;
      this.cart.forEach((item: any, index: number) => {
        if (item.upgrade) {
          this.upgrade[index] = true;
        } else {
          this.upgrade[index] = false;
        }
      });
      this.totalPrice = this.updateTotalPrice();
    });
    this.paymentProcessedHeader = this.i18NService.get('global.purchase-options.paymentProcessed');
    this.paymentProcessedSubHeader = this.i18NService.get('global.purchase-options.paymentProcessed');
    this.paymentProcessedOk = this.i18NService.get('global.purchase-options.paymentProcessedOK');
  }

  private clearPurchaseSelectionState() {
    this.store.dispatch(CartActions.ClearSelection({ payload: { item: null } }));
  }

  nameChange(event: any) {
    if (event.value && event.value.id) {
      this.offeredByUserId = event.value;
    }
  }

  updateListing(premium: boolean, index: any) {
    let flag;
    if (premium) {
      flag = false;
    } else {
      flag = true;
    }
    let updatedItem = this.updateCartObject(flag, index);
    this.store.dispatch(CartActions.UpdateItem({ payload: { item: updatedItem, index: index } }));
  }

  updateTotalPrice() {
    this.totalPrice = 0;
    this.cart.forEach((item: any, index: number) => {
      if (item.upgrade) {
        this.totalPrice = this.totalPrice = this.totalPrice + item.premiumPrice * item.numOfListings;
      } else {
        this.totalPrice = this.totalPrice + item.listingPricePerMonth * item.numOfListings;
      }
    });
    this.listPropertyService.setCartTotalPrice(this.totalPrice);
    this.listPropertyService.setCartItems(this.cart);
    return this.totalPrice;
  }

  removeFromCart(id: number, index: number) {
    this.store.dispatch(CartActions.RemoveItem({ payload: { item: { id: id }, index: index } }));
  }

  updateCartObject(premium: any, index: any) {
    const obj = Object.assign({}, this.cart[index], { upgrade: premium });
    return obj;
  }

  skipPayment() {
    let price = this.listPropertyService.getCartTotalPrice() + '00';
    let cartElements = this.listPropertyService.getCartItems();
    var loginFormObject: any = {};
    loginFormObject.purchaseOptions = cartElements;
    loginFormObject.paymentInstallments = 1;
    loginFormObject.paymentAmount = 0;
    if (this.spotAdmin) {
      loginFormObject.userId = this.offeredByUserId ? this.offeredByUserId.id : null;
      loginFormObject.companyId = this.selectCompany.id;
    }
    this.listPropertyService
      .skipPaymentAPI(loginFormObject)
      .toPromise()
      .then((result: any) => {
        this.cart.forEach((item: any, index: number) => {
          this.removeFromCart(item.id, index);
        });
        this.showPaymentMessage();
      });
  }

  navigateToPaymentPage() {
    let spotAdminSelection: any = {};
    if (this.selectCompany || this.offeredByUserId) {
      spotAdminSelection.companyId = this.selectCompany.id;
      spotAdminSelection.userId = this.offeredByUserId.id;
    }
    this.listPropertyService.setPaymentSpotAdminSelection(spotAdminSelection);
    this.router.navigateByUrl('/payment');
  }

  addListing() {
    this.router.navigateByUrl('/list-property');
  }

  goBack() {
    this.router.navigateByUrl('/list-property');
  }

  back() {
    this._location.back();
  }

  async showPaymentMessage() {
    const alert = await this.alertCtrl.create({
      header: this.paymentProcessedHeader,
      subHeader: this.paymentProcessedSubHeader,
      buttons: [this.paymentProcessedOk]
    });
    alert.onDidDismiss().then(() => {
      this.router.navigateByUrl('/user-home/my-listings');
    });
    await alert.present();
  }

  checkRole(role: string) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  async initDropdowns() {
    this.allCompanies = await this.getCompanies();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.users = await this.getUsersByCompany(this.user.company.id);
    let index = this.allCompanies.findIndex((v: any) => {
      return v.id === this.user.company.id;
    });
    let userIndex = this.users.findIndex((v: any) => {
      return v.id === this.user.id;
    });
    this.selectCompany = this.allCompanies[index];
    this.offeredByUserId = this.users[userIndex];
  }

  async getCompanies() {
    return this.registerService.getListingCompanies().toPromise();
  }

  async getUsersByCompany(companyId: number) {
    return this.registerService.getUsersByCompany(companyId).toPromise();
  }

  async portChange($event: any) {
    this.users = await this.getUsersByCompany($event.value.id);
    this.agentComponent.clear();
    this.offeredByUserId = null;
  }
}
