import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18nService } from '@app/core';
import { textFromHtml } from '@app/core/helpers/share-helper';
import { FavoriteSpotService } from '../services/favorite-spot.service';

@Component({
  selector: 'app-share-email',
  templateUrl: './share-email.component.html',
  styleUrls: ['./share-email.component.scss']
})
export class ShareEmailComponent implements OnInit {
  fullName: string;
  email: string;
  isMobile: boolean = false;
  searching = false;
  url: any;

  constructor(
    private dialog: MatDialogRef<ShareEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver,
    private favoriteServices: FavoriteSpotService,
    public i18nService: I18nService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit() {
    this.url = `https://api.whatsapp.com/send?text=${this.i18nService.get(
      'favorites.shareWhatsApp'
    )}${this.data.url.replace(' ', '%20')}`;
  }

  close() {
    this.dialog.close();
  }

  validateEmail() {
    var EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    if (this.email.match(EMAIL_REGEX)) {
      return true;
    }
    return false;
  }

  sendEmail() {
    let email = {
      email: this.email,
      fullName: this.fullName
    };

    this.searching = true;
    this.favoriteServices.sendEmail(this.data.id, email, this.i18nService.getCurrentLanguage()).then(val => {
      this.dialog.close(true);
      this.searching = false;
    });
  }
}
