import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import * as MyListingModel from '@app/models/my-listing.model';
import { RegisterService } from '@app/core/register.service';
import { StatusHelperService } from '@app/core/helpers/status-helper.service';
import { ClassHelperService } from '@app/core/helpers/classes-helper.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
export class ListingFilterModalComponent {
    constructor(breakpointObserver, classHelperSvc, popoverCtrl, registerSvc, statusHelperSvc) {
        this.breakpointObserver = breakpointObserver;
        this.classHelperSvc = classHelperSvc;
        this.popoverCtrl = popoverCtrl;
        this.registerSvc = registerSvc;
        this.statusHelperSvc = statusHelperSvc;
        this.isMobile = false;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    ngOnInit() {
        this.statusHelperSvc.getStatuses();
        this.initMyListingFilter();
    }
    getUserByCompany(companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerSvc.getUsersByCompany(companyId).toPromise();
        });
    }
    initMyListingFilter() {
        this.selectStatus = this.statusHelperSvc.selectStatus;
        this.selectClass = this.classHelperSvc.selectClass;
    }
    cancel() {
        this.popoverCtrl.dismiss();
    }
    changeCompany($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.agents = yield this.getUserByCompany($event.value.id);
            this.agentComponent.clear();
        });
    }
    changeStatus($event) {
        this.selectStatus = $event.value;
    }
    changeClass($event) {
        this.selectClass = $event.value;
    }
    updateMyListingFilter() {
        this.popoverCtrl.dismiss({
            agents: this.agents,
            selectAgent: this.selectAgent,
            selectCompany: this.selectCompany,
            selectStatus: this.selectStatus,
            selectClass: this.selectClass
        });
    }
    setModalContainerWidth() {
        return this.isMobile ? 'filter-selection-mobile' : 'filter-selection';
    }
    setFieldWidth() {
        return this.isMobile ? 'field-width-mobile' : '';
    }
    setLabelColSize() {
        return this.isMobile ? 4 : 3;
    }
    setFilterColSize() {
        return this.isMobile ? 8 : 9;
    }
}
