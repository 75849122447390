import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { SpotBuildingsSearchService } from '../../../services/spot-buildings-search.service';
import { I18nService } from '@app/core';
import * as Fuse from 'fuse.js';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CoworkingSpaceType } from '@app/models/spot-buildings/spot-available-spaces';
export class MoreFiltersSpotbuildingsComponent {
    constructor(navParams, popoverCtrl, spotBuildingsSearchService, i18NService) {
        this.navParams = navParams;
        this.popoverCtrl = popoverCtrl;
        this.spotBuildingsSearchService = spotBuildingsSearchService;
        this.i18NService = i18NService;
        this.btnFilter = false;
        this.loading = true;
        this.states = [];
        this.selectProperty = true;
        this.clear = 0;
        this.isMobile = false;
        this.inputBuilding = '';
        this.selectedProperty = [];
        this.stopFuzzy = false;
        this.stateName = '';
        this.cityName = '';
        this.filter = 0;
        this.faTimes = faTimes;
    }
    ngOnInit() {
        this.getPopData();
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
        if (this.searchDto.coworkingType) {
            this.coworkingFilter(0, this.searchDto.coworkingType);
        } /* else {
          this.coworkingFilter(1, CoworkingSpaceType.PRIVATE_OFFICE);
        } */
        if (!this.searchDto.buildingTypes) {
            this.searchDto.buildingTypes = [];
            this.searchDto.buildingTypes[0] = '2001';
        }
        if (!this.searchDto.listingLeaseOrSale) {
            this.searchDto.listingLeaseOrSale = [];
            this.searchDto.listingLeaseOrSale[0] = 'L';
        }
        if (!this.searchDto.propertySubTypes) {
            this.searchDto.propertySubTypes = [];
        }
        if (this.searchDto.spotTitlesOrAddresses) {
            this.selectedProperty = this.searchDto.spotTitlesOrAddresses;
        }
        if (!this.searchDto.spotStatus) {
            this.searchDto.spotStatus = 'ACTIVE';
        }
        this.isAdmin = this.checkSpotAdmin();
        this.setMinOptions(this.searchDto);
        this.getStatesByCountry();
        this.getPropertySearch();
        this.getcitiesByState(null);
    }
    getPropertySearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.propertySearch = yield this.spotBuildingsSearchService.getPropertySearch(this.searchDto.buildingTypes[0], this.searchDto.listingLeaseOrSale[0], this.searchDto.propertySubTypes.length > 0 ? this.searchDto.propertySubTypes[0].id : null);
            this.initBuildingSearch();
        });
    }
    changeFuzzySearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.propertySearch = this.suggest_property(this.inputBuilding);
        });
    }
    initBuildingSearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const options = {
                keys: ['name'],
                shouldSort: true,
                caseSensitive: false,
                threshold: 0.5,
                findAllMatches: true
            };
            this.fuse = new Fuse(this.propertySearch, options);
            this.changeFuzzySearch();
        });
    }
    setProperty(building) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.inputBuilding = '';
            this.searchDto.stateId = null;
            this.searchDto.cityId = null;
            this.stateName = '';
            this.cityName = '';
            this.selectedProperty.push(building);
        });
    }
    suggest_property(term) {
        if (term) {
            var q = term.toLowerCase().trim();
            return this.fuse
                .search(q)
                .slice(0, 10)
                .map((element) => {
                return element;
            });
        }
    }
    checkSpotAdmin() {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user && this.user.roles.includes('ROLE_SPOT_ADMIN')) {
            return true;
        }
        else {
            return false;
        }
    }
    removeProperty(index) {
        this.selectedProperty.splice(index, 1);
    }
    clearProperties() {
        this.selectedProperty = [];
    }
    setBuildinType(event) {
        this.selectProperty = false;
        this.searchDto.buildingTypes[0] = event;
        if (this.searchDto.listingLeaseOrSale[0] == 'C') {
            this.setListingLeaseOrSale('L');
        }
        this.clearSearch();
        this.getStatesByCountry();
        this.getPropertySearch();
        this.setMinOptions(this.searchDto);
    }
    setDefaultBuildinTypeAndListingType() {
        this.selectProperty = false;
        this.searchDto.buildingTypes[0] = '2001';
        this.setListingLeaseOrSale('L');
        this.clearSearch();
        this.getStatesByCountry();
        this.getPropertySearch();
    }
    setPropertySubTypes(event) {
        let subtype;
        if (event == 1001) {
            subtype = {
                id: event,
                name: 'Industrial Complex',
                isChecked: true,
                translationNames: ''
            };
            this.searchDto.propertySubTypes[0] = subtype;
        }
        else {
            if (event == 1002) {
                subtype = {
                    id: event,
                    name: 'Stand Alone',
                    isChecked: true,
                    translationNames: ''
                };
                this.searchDto.propertySubTypes[0] = subtype;
            }
            else {
                this.searchDto.propertySubTypes = [];
            }
        }
        this.getPropertySearch();
        this.getStatesByCountry();
    }
    setListingLeaseOrSale(event) {
        this.searchDto.listingLeaseOrSale[0] = event;
        if (event == 'C') {
            this.searchDto.buildingTypes[0] = '2001';
        }
        this.clearSearch();
        this.getPropertySearch();
        this.getStatesByCountry();
        this.setMinOptions(this.searchDto);
    }
    setActiveFilter(active) {
        if (active) {
            this.searchDto.spotStatus = 'ACTIVE';
        }
        else {
            this.searchDto.spotStatus = 'INACTIVE';
        }
    }
    getBuildingSubTypeId() {
        if (this.searchDto.propertySubTypes && this.searchDto.propertySubTypes.length > 0) {
            return this.searchDto.propertySubTypes[0].id;
        }
        return null;
    }
    getStatesByCountry() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.searchDto.stateId) {
                this.searchDto.stateId = null;
                this.searchDto.cityId = null;
                this.states = [];
                this.cities = [];
            }
            this.states = yield this.spotBuildingsSearchService.getStatesByCountry(this.searchDto.buildingTypes[0], this.searchDto.listingLeaseOrSale[0], this.getBuildingSubTypeId());
            if (this.searchDto.stateId && this.states) {
                let state = this.states.filter((state) => state.id == this.searchDto.stateId);
                this.stateName = state && state.length > 0 ? this.i18NService.getTranslation(state[0].name) : null;
            }
            this.states = this.states.sort(this.SortArray);
            this.putRioAndSaoPauloOnTop(this.states);
            if (this.searchDto.cityId) {
                this.getcitiesByState(null);
            }
        });
    }
    SortArray(x, y) {
        if (x.name < y.name) {
            return -1;
        }
        if (x.name > y.name) {
            return 1;
        }
        return 0;
    }
    putRioAndSaoPauloOnTop(states) {
        if (states && states.length > 1) {
            for (var i = 0; i < states.length; ++i) {
                var state = states[i];
                if (state.name.includes('Rio de Janeiro') || state.name.includes('São Paulo')) {
                    states.splice(i, 1);
                    if (states[0].name.includes('São Paulo')) {
                        states.splice(1, 0, state);
                    }
                    else {
                        states.splice(0, 0, state);
                    }
                }
            }
        }
        return states;
    }
    getcitiesByState(state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (state != null) {
                this.stateName = this.i18NService.getTranslation(state.name);
                this.searchDto.stateId = state.id;
            }
            this.cities = yield this.spotBuildingsSearchService.getcitiesByState(this.searchDto.buildingTypes[0], this.searchDto.stateId, this.searchDto.listingLeaseOrSale[0], this.getBuildingSubTypeId());
            if (state == null) {
                let city = this.cities.filter((city) => city.id == this.searchDto.cityId);
                this.cityName = this.i18NService.getTranslation(city[0].name);
            }
        });
    }
    setCity(city) {
        this.cityName = this.i18NService.getTranslation(city.name);
        this.searchDto.cityId = city.id;
    }
    getPopData() {
        this.checkboxValues = this.navParams.data.checkboxValues;
        if (this.navParams.data.propType === '') {
            this.propertyType = null;
        }
        else {
            this.propertyType = this.navParams.data.propType;
            this.searchDto = this.navParams.data.searchDto;
        }
    }
    setMinOptions(searchDto) {
        this.spotBuildingsSearchService
            .getMinMaxOptions(searchDto)
            .toPromise()
            .then((result) => {
            localStorage.setItem('minMax', JSON.stringify(result));
            this.staticMinMax = this.spotBuildingsSearchService.setStaticMinMaxValues(result);
            this.rangeModelValues = this.spotBuildingsSearchService.mapMinMaxOptions(this.searchDto, this.staticMinMax);
            this.loading = false;
        });
    }
    dismiss() {
        this.rangeFilters = this.spotBuildingsSearchService.setSearchRangeFilters(this.rangeModelValues, this.staticMinMax);
        this.searchDto.spotTitlesOrAddresses = this.selectedProperty;
        let searchCopyDto = Object.assign({}, this.searchDto, this.rangeFilters);
        if (this.filter == 4) {
            searchCopyDto.positions = null;
        }
        if (this.filter == 0) {
            searchCopyDto.coworkingType = null;
        }
        this.popoverCtrl.dismiss(searchCopyDto);
    }
    cancel() {
        this.popoverCtrl.dismiss();
    }
    clearSearch() {
        this.clear = this.clear + 1;
        this.searchDto = this.spotBuildingsSearchService.clearFilters(this.searchDto, this.checkboxValues);
        let result = JSON.parse(localStorage.getItem('minMax'));
        this.staticMinMax = this.spotBuildingsSearchService.setStaticMinMaxValues(result);
        this.rangeModelValues = this.spotBuildingsSearchService.mapMinMaxOptions(null, this.staticMinMax);
        //this.searchDto.buildingTypes = ['2001'];
        //this.searchDto.listingLeaseOrSale = ['L'];
        this.searchDto.stateId = null;
        this.searchDto.cityId = null;
        this.searchDto.location = null;
        this.selectProperty = true;
        this.searchDto.yearBuiltIni = null;
        this.searchDto.yearBuiltEnd = null;
        this.searchDto.yearRenovatedIni = null;
        this.searchDto.yearRenovatedEnd = null;
        this.searchDto.spotTitlesOrAddresses = null;
        this.selectedProperty = [];
        this.stateName = '';
        this.cityName = '';
        this.searchDto.propertySubTypes = [];
        this.searchDto.availabilityDate = null;
        this.searchDto.coworkingType = null;
        this.searchDto.positions = null;
        this.filter = 0;
    }
    clearSearchExternal() {
        this.setDefaultBuildinTypeAndListingType();
    }
    scrollToTop() {
        this.modalContent.nativeElement.scrollTop = 0;
    }
    handleKeyboardEvent(event) {
        if (event.keyCode === 27) {
            this.cancel();
        }
    }
    coworkingFilter(coworkingType, CoworkingSpace) {
        if (coworkingType == 1 || CoworkingSpace == CoworkingSpaceType.PRIVATE_OFFICE) {
            this.searchDto.coworkingType = CoworkingSpaceType.PRIVATE_OFFICE;
            this.filter = this.filter == 1 ? 0 : 1;
        }
        if (coworkingType == 2 || CoworkingSpace == CoworkingSpaceType.DEDICATED_WORKSPACE) {
            this.searchDto.coworkingType = CoworkingSpaceType.DEDICATED_WORKSPACE;
            this.filter = this.filter == 2 ? 0 : 2;
        }
        if (coworkingType == 3 || CoworkingSpace == CoworkingSpaceType.OPEN_WORKSPACE) {
            this.searchDto.coworkingType = CoworkingSpaceType.OPEN_WORKSPACE;
            this.filter = this.filter == 3 ? 0 : 3;
        }
        if (coworkingType == 4 || CoworkingSpace == CoworkingSpaceType.FULL_FLOOR_OFFICES) {
            this.searchDto.coworkingType = CoworkingSpaceType.FULL_FLOOR_OFFICES;
            this.filter = this.filter == 4 ? 0 : 4;
        }
    }
}
