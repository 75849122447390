import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { I18nService } from '@app/core';
import { BaseService } from '../../spot-buildings/services/base-service';
import { CompanyDTO } from '../../models/company.model';

@Injectable()
export class CompanyService implements BaseService {
  private companyAPI: string = 'api/company';
  private spotAPI: string = 'api/buildinglisting';
  private companyPhotosAPI: string = `api/companyPhotos`;

  constructor(private http: HttpClient, private i18NService: I18nService) {}
  interporlateURL(url: string, values: any): string {
    throw new Error('Method not implemented.');
  }

  getCompanyDetailURL(companyId: number) {
    return this.http
      .get(`${this.companyAPI}/url/${companyId}/${this.i18NService.getCurrentLanguage()}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getCompanyById(companyId: number) {
    return this.http
      .get(`${this.companyAPI}/${companyId}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getCompanyMetadataById(companyId: number) {
    return this.http
      .get(`${this.companyAPI}/${companyId}/metadata`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getPhotosByCompanyId(companyId: number) {
    return this.http
      .get(`${this.companyPhotosAPI}/company/${companyId}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getAgentsWithSpotsFrom(companyId: number) {
    return this.http
      .get(`${this.companyAPI}/${companyId}/agents/spots`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getSpotTitlesByCompanyId(companyId: number) {
    return this.http
      .get(`${this.spotAPI}/titles/${companyId}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getCompanyBiography(company: CompanyDTO) {
    if (company) {
      return this.i18NService.getTranslation(company.biography);
    }
  }
}
