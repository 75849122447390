import * as tslib_1 from "tslib";
import { OnInit, ElementRef, ComponentRef, OnDestroy, AfterViewInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatStepper, MatDialog } from '@angular/material';
import { IonReorderGroup, ToastController, AlertController } from '@ionic/angular';
import { RegisterListingService } from '@app/core/register-listing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { ListingService, ListingFormService, EventCheckerService, ListingCleanupService, ListingDetailService } from '@app/core/listings';
import { ImageService, PurchaseCreditsService, I18nService } from '@app/core';
import { UtilityService } from '@app/core/helpers/utility-services';
export class CreateListingComponent {
    constructor(breakpointObserver, registerListService, router, listingService, registerService, _ts, imageService, listingFormService, _eventChecker, toastCtrl, purchaseService, listingCleanupService, alertCtrl, i18nService, listingDetailsService, utilityService, dialog, route) {
        this.breakpointObserver = breakpointObserver;
        this.registerListService = registerListService;
        this.router = router;
        this.listingService = listingService;
        this.registerService = registerService;
        this._ts = _ts;
        this.imageService = imageService;
        this.listingFormService = listingFormService;
        this._eventChecker = _eventChecker;
        this.toastCtrl = toastCtrl;
        this.purchaseService = purchaseService;
        this.listingCleanupService = listingCleanupService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.listingDetailsService = listingDetailsService;
        this.utilityService = utilityService;
        this.dialog = dialog;
        this.route = route;
        this.isMobile = false;
        this.previewLoaded = false;
        this.currentStep = 0;
        this.imgList = [];
        this.uploadingImages = false;
        this.tagValues = [];
        this.subTypes = [];
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.coworkingInfo = [];
        this.maximumPositions = [];
        this.videoURLInvalid = false;
        this.adminUpdate = false;
        this.skipDiscardChanges = false;
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
            this.isMobile = result.matches;
        });
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.getParams();
        this.multiLangInit();
        this.getAmenities();
    }
    ngOnDestroy() { }
    ngOnInit() {
        this.skipDiscardChanges = false;
        this.detectFeatures();
    }
    ngAfterViewInit() {
        setTimeout(() => (this.currentStep = 0), 0);
    }
    onStepperSelectionChange(evant) {
        this.scrollToSectionHook();
    }
    scrollToSectionHook() {
        const element = document.querySelector('.stepperTop');
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
            }, 75);
        }
    }
    goForward() {
        if (this.currentStep === 0) {
            this.listType = this.listingTypeForm.controls.commericalType.value;
        }
        setTimeout(() => {
            if (this.currentStep === 5 && !this.listing) {
                !this.previewListing ? this.setBuildingPreview() : this.setBuildingPreview(true, this.previewListing);
            }
            else if (this.currentStep === 5 && this.listing && this.listing.listingStatus.id === 4 && this.spotAdmin) {
                this.displayAlert();
            }
            else if (this.currentStep === 5 && this.listing && this.isUserAllowToEditPendingApprovalListing()) {
                this.fromMyListings = false;
                this.displayAlert();
            }
            else if (this.currentStep === 5 && this.listing) {
                this.setBuildingPreview(false, null, true);
            }
            this.stepper.next();
            this.currentStep = this.currentStep + 1;
        }, 0);
    }
    goBack() {
        this.stepper.previous();
        this.currentStep = this.currentStep - 1;
        this.previewLoaded = false;
        this.listingTypeForm.markAsDirty();
    }
    checkRole(role) {
        return this.listingCleanupService.checkRole(role);
    }
    getParams() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let listingId = this.route.snapshot.paramMap.get('listingId');
            const navigation = this.router.getCurrentNavigation();
            let navigationState;
            if (navigation == undefined) {
                yield this.getListing(listingId).then(val => {
                    let state = { listing: this.tempListing, myListingPage: true, date: new Date().getTime() };
                    navigationState = state;
                });
            }
            else {
                navigationState = navigation.extras.state;
            }
            let credit = navigationState && navigationState.credit ? navigationState.credit : null;
            this.listingPurchaseId = navigationState && navigationState.credit ? credit.id : 0;
            this.listingType = navigationState && navigationState.credit ? credit.premium : false;
            this.listing = navigationState && navigationState.listing ? navigationState.listing : null;
            this.listingClass = this.listing
                ? this.listing.listingType
                : navigationState && navigationState.credit && navigationState.credit.premium
                    ? 'PREMIUM'
                    : 'PROMOTED';
            this.fromMyListings = navigationState && navigationState.myListingPage ? navigationState.myListingPage : null;
            this.listingExpDate = navigationState && navigationState.credit ? credit.listingExpirationDate : null;
            this.offeredByUserId = navigationState && navigationState.offeredByUserId ? navigationState.offeredByUserId : null;
            this.company = navigationState && navigationState.company ? navigationState.company : null;
            this.listing ? (this.offeredByUserId = this.listing.offeredByUser) : null;
            this.listing && this.listing.offeredByUser ? (this.company = this.listing.offeredByUser.company) : null;
            if (this.listingPurchaseId === 0 && !this.listing) {
                this.router.navigateByUrl('/user-home');
            }
            this.listing ? this.initListing() : this.createInitForm();
            this.initCoworkingFields();
        });
    }
    getListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let listingDetail = yield this.listingDetailsService.getListingDetails(listingId).toPromise();
            let listingPhotos = yield this.listingDetailsService.getListingPhotos(listingId).toPromise();
            this.tempListing = listingDetail.listing;
            this.tempListing.listingPhotos = listingPhotos;
        });
    }
    initCoworkingFields() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let response = this.listingCleanupService.initCoworkingFields();
            this.coworkingInfo = response.coworkingInfo;
            this.maximumPositions = response.maximumPositions;
        });
    }
    setCoworkingFields() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let user = localStorage.getItem('user');
            if (this.listingPriceForm.controls['coworkingEntries'].value) {
                this.coworkingInfo = this.listingPriceForm.controls['coworkingEntries'].value;
                if (this.coworkingInfo.length === 0) {
                    this.coworkingInfo = this.listingCleanupService.nullCoworkingFields();
                }
            }
            if (this.listingPriceForm.controls['coworkingMaxPositions'].value) {
                this.maximumPositions = [{ maximumPositions: this.listingPriceForm.controls['coworkingMaxPositions'].value }];
            }
        });
    }
    checkCowork() {
        return this.listingCleanupService.checkCowork(this.coworkingInfo);
    }
    initListing() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.building = yield this.listingService.getBuildingToRevise(this.listing.building.id);
            yield this.measurementS();
            this.listingClass = this.listing.listingType;
            this.listingExpDate = this.listing.endDate;
            this.reinitForms(this.listing, this.building);
        });
    }
    measurementS() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.measurementStandards = yield this.getMeasurementStands();
            this.measurementStandards = yield this.getMeasurementTranslations(this.measurementStandards);
        });
    }
    getMeasurementTranslations(measurements) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Object.keys(measurements).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                measurements[key].measurement = this.getI18nValue(measurements[key].measurement);
            }));
            return measurements;
        });
    }
    getMeasurementStands() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getMeasurementStandard().toPromise();
        });
    }
    setBuildingPreview(val, previewListing, revise, admin, toApprove) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.maximumPositions[0].maximumPositions && this.coworkingInfo) {
                this.listingPriceForm.controls['coworkingMaxPositions'].setValue(this.maximumPositions[0].maximumPositions);
                this.listingPriceForm.controls['coworkingEntries'].setValue(this.coworkingInfo);
            }
            if (this.addressForm && this.addressForm.value && this.addressForm.value.address) {
                const obj = JSON.parse(this.addressForm.value.address);
                if (obj['en']) {
                    obj['en'] = this.utilityService.removeHTMLCodeFrom(obj['en']);
                }
                if (obj['pt-br']) {
                    obj['pt-br'] = this.utilityService.removeHTMLCodeFrom(obj['pt-br']);
                }
                this.addressForm.value.address = JSON.stringify(obj);
            }
            let mergedForm = Object.assign({}, this.listingTypeForm.value, this.detailsForm.value, this.addressForm.value, this.moreDetailsForm.value, this.listingPriceForm.value, this.listingFeaturesForm.value);
            //this.company
            mergedForm.vrTourUrl = this.vrTourUrl;
            let tags = { industrial: this.industrialFilters, office: this.officeFilters };
            if (!admin) {
                this.previewListing = yield this.registerListService.formBuildingListing(mergedForm, this.offeredByUserId, val ? true : null, this.listingPurchaseId, this.listingTypeForm.value.subType, this.listingType, tags, this.imgList, previewListing ? previewListing : null, revise ? this.listing : null);
                if (this.previewListing) {
                    this.imgList = this.previewListing.photos;
                    setTimeout(() => {
                        this.previewLoaded = true;
                        this.listingTypeForm.markAsPristine();
                        this.detailsForm.markAsPristine();
                        this.addressForm.markAsPristine();
                        this.moreDetailsForm.markAsPristine();
                        this.listingPriceForm.markAsPristine();
                        this.listingFeaturesForm.markAsPristine();
                    }, 200);
                }
                else {
                    this.goBack();
                }
            }
            else {
                try {
                    this.saveListing(val, previewListing, revise, admin, toApprove);
                }
                catch (error) {
                    this.showSaveErr();
                }
            }
        });
    }
    submitListing() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let res = yield this.registerListService.updateToApproval(this.previewListing);
                if (res) {
                    this.adminUpdate = true;
                    this.previewLoaded = false;
                    this.fromMyListings ? this.router.navigateByUrl('/user-home/my-listings') : this.navigateToDetailsPage(res.id);
                }
            }
            catch (error) {
                console.error('Error Submitting Listing: ', error);
            }
        });
    }
    approveListing() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let action = yield this.purchaseService.approveListing(this.previewListing.id);
            if (action) {
                this.adminUpdate = true;
                this.previewLoaded = false;
                this._eventChecker.updateListing$.next('no-refresh');
                this.fromMyListings
                    ? this.router.navigateByUrl('/user-home/my-listings')
                    : this.navigateToDetailsPage(this.previewListing.id);
            }
        });
    }
    navigateToDetailsPage(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const detailsURL = yield this.listingDetailsService.getListingDetailsURLFrom(listingId);
            this.router.navigateByUrl(detailsURL);
        });
    }
    saveListing(val, previewListing, revise, admin, toApprove) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.maximumPositions[0].maximumPositions && this.coworkingInfo) {
                this.listingPriceForm.controls['coworkingMaxPositions'].setValue(this.maximumPositions[0].maximumPositions);
                this.listingPriceForm.controls['coworkingEntries'].setValue(this.coworkingInfo);
            }
            //this.offeredByUserId = this.moreDetailsForm.value.offeredByUserId;
            let mergedForm = Object.assign({}, this.listingTypeForm.value, this.detailsForm.value, this.addressForm.value, this.moreDetailsForm.value, this.listingPriceForm.value, this.listingFeaturesForm.value);
            mergedForm.vrTourUrl = this.vrTourUrl;
            let tags = { industrial: this.industrialFilters, office: this.officeFilters };
            let tempListing = yield this.registerListService.formBuildingListing(mergedForm, this.offeredByUserId, val ? true : null, this.listingPurchaseId, this.listingTypeForm.value.subType, this.listingType, tags, this.imgList, previewListing ? previewListing : null, revise ? this.listing : null);
            let res;
            try {
                res = yield this.registerListService.adminSaveListing(tempListing);
            }
            catch (error) {
                this.showSaveErr();
            }
            if (res) {
                if (toApprove) {
                    yield this.purchaseService.approveListing(this.listing.id);
                }
                res.listingPhotos = tempListing.photos;
                this.finishActionBySpotAdmin(res.id);
            }
            else {
                this.showSaveErr();
            }
        });
    }
    finishActionBySpotAdmin(listingId) {
        this.currentStep = 0;
        this.adminUpdate = true;
        this.skipDiscardChanges = true;
        this._eventChecker.updateListing$.next('no-refresh');
        this.fromMyListings ? this.router.navigateByUrl('/user-home/my-listings') : this.navigateToDetailsPage(listingId);
    }
    getSpaceTranslations(subTypes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Object.keys(subTypes).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                subTypes[key].name = this.getI18nValue(subTypes[key].name);
                subTypes[key].selected = false;
            }));
            return subTypes;
        });
    }
    getSpaceTypes(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getBuildingSubTypes(id).toPromise();
        });
    }
    getAmenities() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.industrialFilters = yield this.registerService.getAmenities('industrial');
            this.officeFilters = yield this.registerService.getAmenities('office');
        });
    }
    getI18nValue(textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18nService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    multiLangInit() {
        let response = this.listingCleanupService.multiLangInit();
        this.titleFieldOptions = response.titleFieldOptions;
        this.descriptionFieldOptions = response.descriptionFieldOptions;
    }
    reinitForms(listing, building) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let forms = yield this.listingFormService.reinitForm(listing, building, this.measurementStandards);
            this.listingTypeForm = forms[0];
            this.listingPriceForm = forms[4];
            this.detailsForm = forms[1];
            this.addressForm = forms[2];
            this.moreDetailsForm = forms[3];
            this.listingFeaturesForm = forms[5];
            this.subTypes = yield this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value);
            this.subTypes = yield this.getSpaceTranslations(this.subTypes);
            if (this.listingTypeForm.controls['loadedSubTypes'].value) {
                this.setCoworkingFields();
                this.checkSubTypes();
            }
            if (listing.listingPhotos) {
                this.imgList = yield this.listingFormService.checkListingPhotos(listing);
            }
            if (listing.vrTourUrl) {
                this.vrTourUrl = listing.vrTourUrl;
            }
            this.listingTypeForm.markAsDirty();
        });
    }
    checkSubTypes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let existing = this.listingTypeForm.controls['loadedSubTypes'].value;
            Object.keys(this.subTypes).forEach((key, idx) => {
                existing.findIndex((v) => {
                    if (v.id === this.subTypes[key].id) {
                        this.subTypes[key].selected = true;
                    }
                });
            });
            this.listingTypeForm.controls['subType'].setValue(this.subTypes);
        });
    }
    createInitForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.measurementS();
            let forms = yield this.listingFormService.createInitForm(this.measurementStandards);
            this.listingTypeForm = forms[0];
            this.detailsForm = forms[1];
            this.addressForm = forms[2];
            this.moreDetailsForm = forms[3];
            this.listingPriceForm = forms[4];
            this.listingFeaturesForm = forms[5];
            this.subTypes = yield this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value);
            this.subTypes = yield this.getSpaceTranslations(this.subTypes);
            this.listingTypeForm.controls['subType'].setValue(this.subTypes);
            this.listingTypeForm.markAsDirty();
        });
    }
    canDeactivate() {
        if (this.listingPurchaseId === 0 && !this.listing) {
            return true;
        }
        if (this.adminUpdate || this.skipDiscardChanges) {
            return true;
        }
        else if (this.previewLoaded) {
            this.showUploadErr();
            return true;
        }
        return (this.listingTypeForm &&
            !this.listingTypeForm.dirty &&
            this.detailsForm &&
            !this.detailsForm.dirty &&
            this.addressForm &&
            !this.addressForm.dirty &&
            this.moreDetailsForm &&
            !this.moreDetailsForm.dirty &&
            this.listingPriceForm &&
            !this.listingPriceForm.dirty &&
            this.listingFeaturesForm &&
            !this.listingFeaturesForm.dirty);
    }
    showUploadErr() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastCtrl.create({
                message: 'Your listing has been saved as a draft.',
                duration: 3500,
                animated: true,
                keyboardClose: true,
                cssClass: 'toast-alert'
            });
            toast.onDidDismiss().then(() => { });
            return yield toast.present();
        });
    }
    showSaveErr() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastCtrl.create({
                message: 'There was an issue saving your listing.',
                duration: 3500,
                animated: true,
                keyboardClose: true,
                cssClass: 'toast-alert'
            });
            toast.onDidDismiss().then(() => { });
            return yield toast.present();
        });
    }
    displayAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                header: this._ts.instant('global.updateListing'),
                subHeader: this._ts.instant('global.updateListingText'),
                cssClass: 'ion-alert-listings',
                backdropDismiss: true,
                buttons: [
                    {
                        text: this._ts.instant('Cancel'),
                        role: 'Cancel',
                        handler: () => {
                            this.goBack();
                        }
                    },
                    {
                        text: this._ts.instant('Ok'),
                        handler: () => {
                            this.setBuildingPreview(false, null, true, true, false);
                        }
                    }
                ]
            });
            alert.onDidDismiss().then(() => { });
            yield alert.present();
        });
    }
    onVrTourChanged(urlValidation) {
        this.videoURLInvalid = urlValidation.videoURLInvalid;
        this.vrTourUrl = urlValidation.vrTourSelected;
    }
    detectFeatures() {
        this._eventChecker.updateOfficeFilters$.subscribe((pOfficeFilters) => {
            if (pOfficeFilters) {
                this.officeFilters = pOfficeFilters;
            }
        });
        this._eventChecker.updateIndustrialFilters$.subscribe((pFilters) => {
            if (pFilters) {
                this.industrialFilters = pFilters;
            }
        });
    }
    isUserAllowToEditPendingApprovalListing() {
        return this.registerListService.isUserAllowToEditPendingApprovalListing(this.listing);
    }
    cancelAndNavigateToDetails() {
        this.fromMyListings = false;
        this.finishActionBySpotAdmin(this.listing.id);
    }
}
