import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { I18nService, LanguageService } from '@app/core';
import { ListingPriceService } from '@app/core/listings';
import { IndustrialStatusDTO } from '@app/models/industrialStatus.model';

import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { BuildingFeaturesDTO } from '@app/models/spot-buildings/spot-buildings-features.model';
import { SpotBuildingDTO } from '@app/models/spot-buildings/spot-buildings.model';

import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { SpotBuildingsUtilService } from '@app/spot-buildings/util/spot-buildings-util.service';
import { validateURL } from '@app/shared/util/url-validator';

export interface URLValidation {
  videoURLInvalid: boolean;
}
@Component({
  selector: 'spot-buildings-features',
  templateUrl: './spot-buildings-features.component.html',
  styleUrls: ['./spot-buildings-features.component.scss']
})
export class SpotBuildingsFeaturesComponent implements OnInit {
  @Input('spotBuildings') spotBuildings: CreateSpotBuildingsRequest;
  @Input('typeOffer') typeOffer: boolean;
  @Input('entireSale') entireSale: boolean;
  @Input('propertyType') propertyType: number[];
  @Input('buildingDetail') buildingDetail: SpotBuildingDTO;
  @Input('emitChanges') emitChanges: boolean;

  buildingsOfficeIndustrialFilters: any;
  allConstructionStatus: any = [];
  originalConstructions: any;
  selectContructionStatus: any;
  update = 0;
  videoURLInvalid = false;
  vrTourUrl: any;
  vrTourSelected: any;
  existingInvestment: any;
  InvestmentFieldOptions: any;

  constructor(
    private spotBuildingsUtilService: SpotBuildingsUtilService,
    private langService: LanguageService,
    private listingPriceService: ListingPriceService,
    private createSpotBuildingsService: CreateSpotBuildingsService,
    private i18NService: I18nService
  ) {}

  ngOnInit(): void {
    this.getAmenitiesBuildings();
    this.getIndustrialStatus();
    this.multiLangInit();
  }

  ngOnChanges(changes: any) {
    this.getAmenitiesBuildings();

    if (this.buildingDetail && this.update == 0) {
      this.update++;
      this.initValuesUpdate();
    }
  }

  checkDate(ev: any) {}

  multiLangInit() {
    this.InvestmentFieldOptions = {
      columnLabel: 'global.list-your-property.investmentOpportunity',
      element: { richtext: true },
      formArrayName: 'description',
      required: true
    };
  }

  initValuesUpdate() {
    this.spotBuildings.rentableArea = this.buildingDetail.rentableArea;
    this.spotBuildings.averageFloorPrivateArea = this.buildingDetail.averageFloorPrivateArea;
    this.spotBuildings.ceilingHeight = this.buildingDetail.ceilingHeight;
    this.spotBuildings.yearBuilt = this.buildingDetail.yearBuilt;
    this.spotBuildings.yearRenovated = this.buildingDetail.yearRenovated;
    this.spotBuildings.numberStories = this.buildingDetail.numberStories;
    this.spotBuildings.carParkingSpaces = this.buildingDetail.carParkingSpaces;
    this.spotBuildings.parkingSpaces = this.buildingDetail.parkingSpaces;
    this.spotBuildings.landArea = this.buildingDetail.landArea;
    this.spotBuildings.truckParkingSpaces = this.buildingDetail.truckParkingSpaces;
    this.spotBuildings.numberOfBuildings = this.buildingDetail.numberOfBuildings;
    this.spotBuildings.investmentOpportunity = this.buildingDetail.investmentOpportunity;
    this.spotBuildings.majorTenants = this.buildingDetail.majorTenants;
    this.spotBuildings.capRate = this.buildingDetail.capRate;
    this.spotBuildings.percentageLeased = this.buildingDetail.percentageLeased;
    this.vrTourUrl = this.buildingDetail.vrTourUrl ? this.buildingDetail.vrTourUrl : null;
    this.spotBuildings.vrTourUrl = this.vrTourUrl;
    this.spotBuildings.area = this.buildingDetail.area;
    this.spotBuildings.askingPrice = this.buildingDetail.askingPrice;
    this.spotBuildings.docks = this.buildingDetail.docks;
    this.spotBuildings.loadCapacity = this.buildingDetail.loadCapacity;

    this.spotBuildings.currentOccupancy = this.buildingDetail.currentOccupancy;
    this.spotBuildings.startDate = this.buildingDetail.startDate;
    this.spotBuildings.endDate = this.buildingDetail.endDate;
    this.spotBuildings.revenue = this.buildingDetail.revenue;
    this.spotBuildings.lastMonthlyMeeting = this.buildingDetail.lastMonthlyMeeting;

    if (this.buildingDetail.industrialStatus) {
      this.spotBuildings.industrialStatus = this.buildingDetail.industrialStatus;
      this.spotBuildings.industrialStatus.name = this.i18NService.getTranslation(
        this.buildingDetail.industrialStatus.name
      );
    }

    this.selectContructionStatus = this.spotBuildings.industrialStatus;
    this.existingInvestment = this.buildingDetail.investmentOpportunity;

    //init features

    this.spotBuildings.twentyFourHourSecurity = this.buildingDetail.twentyFourHourSecurity;
    this.spotBuildings.electricGenerator = this.buildingDetail.electricGenerator;
    this.spotBuildings.heliport = this.buildingDetail.heliport;
    this.spotBuildings.sprinklers = this.buildingDetail.sprinklers;
    this.spotBuildings.leedStatus = this.buildingDetail.leedStatus;
    this.spotBuildings.singleTenant = this.buildingDetail.singleTenant;
    this.spotBuildings.multiTenant = this.buildingDetail.multiTenant;
    this.spotBuildings.bicycleRack = this.buildingDetail.bicycleRack;
    this.spotBuildings.fiberOptic = this.buildingDetail.fiberOptic;
    this.spotBuildings.centralAirConditioning = this.buildingDetail.centralAirConditioning;
    this.spotBuildings.petFriendly = this.buildingDetail.petFriendly;
    this.spotBuildings.truckYard = this.buildingDetail.truckYard;
    this.spotBuildings.crossDocking = this.buildingDetail.crossDocking;
    this.spotBuildings.sluiceGate = this.buildingDetail.sluiceGate;
    this.spotBuildings.blockAndSteelConstruction = this.buildingDetail.blockAndSteelConstruction;
    this.spotBuildings.blockConstruction = this.buildingDetail.blockConstruction;
    this.spotBuildings.steelConstruction = this.buildingDetail.steelConstruction;
    this.spotBuildings.includesOffice = this.buildingDetail.includesOffice;
    this.spotBuildings.includesRetail = this.buildingDetail.includesRetail;
    this.spotBuildings.coldStorage = this.buildingDetail.coldStorage;
    this.spotBuildings.fullBuildOut = this.buildingDetail.fullBuildOut;
    this.spotBuildings.skyLights = this.buildingDetail.skyLights;
    this.spotBuildings.bulletProofCabin = this.buildingDetail.bulletProofCabin;
    this.spotBuildings.mailPackageHandling = this.buildingDetail.mailPackageHandling;
    this.spotBuildings.enhancedCleaning = this.buildingDetail.enhancedCleaning;
    this.spotBuildings.barista = this.buildingDetail.barista;
    this.spotBuildings.cafeteria = this.buildingDetail.cafeteria;
  }

  checkFeaturesUpdate(building: any) {
    for (let i = 0; i < this.buildingsOfficeIndustrialFilters.length; i++) {
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'twentyFourHourSecurity') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.twentyFourHourSecurity;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'electricGenerator') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.electricGenerator;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'heliport') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.heliport;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'sprinklers') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.sprinklers;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'leedStatus') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.leedStatus;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'singleTenant') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.singleTenant;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'multiTenant') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.multiTenant;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'bicycleRack') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.bicycleRack;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'fiberOptic') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.fiberOptic;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'centralAirConditioning') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.centralAirConditioning;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'petFriendly') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.petFriendly;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'truckYard') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.truckYard;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'crossDocking') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.crossDocking;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'sluiceGate') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.sluiceGate;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'blockAndSteelConstruction') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.blockAndSteelConstruction;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'blockConstruction') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.blockConstruction;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'steelConstruction') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.steelConstruction;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'includesOffice') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.includesOffice;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'includesRetail') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.includesRetail;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'coldStorage') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.coldStorage;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'fullBuildOut') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.fullBuildOut;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'skyLights') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.skyLights;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'bulletProofCabin') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.bulletProofCabin;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'mailAndPackage') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.mailPackageHandling;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'enhancedCleaning') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.enhancedCleaning;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'barista') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.barista;
      }
      if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'cafeteria') {
        this.buildingsOfficeIndustrialFilters[i].selected = building.cafeteria;
      }
    }
  }

  async getIndustrialStatus() {
    let constructionStatus = await this.createSpotBuildingsService.getIndustrialStatus();
    this.originalConstructions = constructionStatus;
    Object.values(constructionStatus).forEach(val => {
      this.allConstructionStatus.push({ id: val.id, name: this.i18NService.getTranslation(val.name) });
    });
  }

  constructionChange($event: any) {
    let p = this.selectContructionStatus;
    this.spotBuildings.industrialStatus = $event.value; /* Object.assign({}, construction); */
  }

  async getAmenitiesBuildings() {
    if (this.propertyType[0] == 1001) {
      this.buildingsOfficeIndustrialFilters = await this.spotBuildingsUtilService.getAmenitiesBuildings(
        'industrialBuildings',
        this.spotBuildings.type,
        this.spotBuildings.entireSale
      );
      if (this.buildingDetail) {
        this.checkFeaturesUpdate(this.buildingDetail);
      } else {
        this.checkFeaturesUpdate(this.spotBuildings);
        if (this.spotBuildings.industrialStatus) {
          this.spotBuildings.industrialStatus.name = this.i18NService.getTranslation(
            this.spotBuildings.industrialStatus.name
          );
          this.selectContructionStatus = this.spotBuildings.industrialStatus;
        }
      }
    } else {
      this.buildingsOfficeIndustrialFilters = await this.spotBuildingsUtilService.getAmenitiesBuildings(
        'officeBuildings',
        this.spotBuildings.type,
        this.spotBuildings.entireSale
      );
      if (this.buildingDetail) {
        this.checkFeaturesUpdate(this.buildingDetail);
      } else {
        this.checkFeaturesUpdate(this.spotBuildings);
        if (this.spotBuildings.industrialStatus) {
          this.spotBuildings.industrialStatus.name = this.i18NService.getTranslation(
            this.spotBuildings.industrialStatus.name
          );
          this.selectContructionStatus = this.spotBuildings.industrialStatus;
        }
      }
    }
  }

  setOfficeIndustrialTypes(index: number) {
    if (this.buildingsOfficeIndustrialFilters[index].selected) {
      this.buildingsOfficeIndustrialFilters[index].selected = false;
    } else {
      this.buildingsOfficeIndustrialFilters[index].selected = true;
    }

    let features = this.spotBuildingsUtilService.checkFields(this.buildingsOfficeIndustrialFilters);

    this.checkFeaturesRequest(features);
  }

  checkFeaturesRequest(features: BuildingFeaturesDTO) {
    this.spotBuildings.twentyFourHourSecurity = features.twentyFourHourSecurity;
    this.spotBuildings.electricGenerator = features.electricGenerator;
    this.spotBuildings.heliport = features.heliport;
    this.spotBuildings.sprinklers = features.sprinklers;
    this.spotBuildings.leedStatus = features.leedStatus;
    this.spotBuildings.singleTenant = features.singleTenant;
    this.spotBuildings.multiTenant = features.multiTenant;
    this.spotBuildings.bicycleRack = features.bicycleRack;
    this.spotBuildings.fiberOptic = features.fiberOptic;
    this.spotBuildings.centralAirConditioning = features.centralAirConditioning;
    this.spotBuildings.petFriendly = features.petFriendly;
    this.spotBuildings.truckYard = features.truckYard;
    this.spotBuildings.crossDocking = features.crossDocking;
    this.spotBuildings.sluiceGate = features.sluiceGate;
    this.spotBuildings.blockAndSteelConstruction = features.blockAndSteelConstruction;
    this.spotBuildings.blockConstruction = features.blockConstruction;
    this.spotBuildings.steelConstruction = features.steelConstruction;
    this.spotBuildings.includesOffice = features.includesOffice;
    this.spotBuildings.includesRetail = features.includesRetail;
    this.spotBuildings.coldStorage = features.coldStorage;
    this.spotBuildings.fullBuildOut = features.fullBuildOut;
    this.spotBuildings.skyLights = features.skyLights;
    this.spotBuildings.bulletProofCabin = features.bulletProofCabin;
    this.spotBuildings.mailPackageHandling = features.mailPackageHandling;
    this.spotBuildings.enhancedCleaning = features.enhancedCleaning;
    this.spotBuildings.barista = features.barista;
    this.spotBuildings.cafeteria = features.cafeteria;
  }

  checkCharacters(event: any, dec: boolean) {
    let num = event.detail.value;
    let regexp: any;
    if (num) {
      let lastChar: any = num.substr(num.length - 1);
      if (dec) {
        if (this.langService.locale === 'en-US') {
          regexp = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
        } else {
          regexp = /^-?(?:\d+|\d{1,3}(?:.\d{3})+)(?:(\.|,)\d+)?$/;
        }
      } else {
        regexp = /^[0-9]*$/;
      }

      let tempValue = num.substring(0, num.length - 1);
      if (
        (!regexp.test(num) && lastChar !== '.' && lastChar !== ',') ||
        num.substr(0) === '.' ||
        num.substr(0) === ',' ||
        (!dec && !regexp.test(num))
      ) {
        event.srcElement.value = tempValue;
      }

      // Validating maximum value for integer
      if (!dec && tempValue > 2147483647) {
        event.srcElement.value = 2147483647;
      }
    }
  }

  formaPropertySize() {
    this.spotBuildings.rentableArea = this.listingPriceService.convertForNumber(this.spotBuildings.rentableArea);
    if (this.spotBuildings.rentableArea > 0) {
      this.spotBuildings.rentableArea = this.listingPriceService.convertForDisplay(this.spotBuildings.rentableArea);
    }
  }

  validateCustomURL() {
    this.videoURLInvalid = false;
    if (this.vrTourUrl) {
      var vrTourUrl2 = this.vrTourUrl.split('\n');
      if (vrTourUrl2.length > 2) {
        this.videoURLInvalid = true;
      } else {
        vrTourUrl2.forEach((item: any) => {
          if (!validateURL(item.trim())) {
            this.videoURLInvalid = true;
          } else {
            this.spotBuildings.vrTourUrl = this.vrTourUrl;
          }
        });
      }
    } else {
      this.spotBuildings.vrTourUrl = this.vrTourUrl;
    }
  }
}
