import { Component, OnInit, AfterViewChecked, Input, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ImageService, I18nService } from '@app/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { IonSlides } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserActivityType, UserActivity } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';

@Component({
  selector: 'our-partners',
  templateUrl: './our-partners.component.html',
  styleUrls: ['./our-partners.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '0px',
          opacity: 0
        })
      ),
      state(
        'closed',
        style({
          height: '350px',
          opacity: 1,
          overflowY: 'hidden',
          position: 'absolute',
          bottom: '-180px',
          borderRadius: '10px',
          backgroundColor: 'white',
          zIndex: 99,
          width: '100%'
        })
      ),
      transition('open => closed', [animate('0.4s')]),
      transition('closed => open', [animate('0.4s')])
    ])
  ]
})
export class OurPartnersComponent implements OnInit, AfterViewChecked {
  showCardBody: any = [];
  currentPhotoIndex: any = [];
  @Input('advertisements') advertisements: any = [];
  @Input('listingDetail') listingDetail: boolean;
  @Input('listingId') listingId: number;
  @ViewChildren('photoSlider') photoSlider: QueryList<IonSlides>;
  hasPointer: boolean;

  constructor(
    private imageService: ImageService,
    private _ts: TranslateService,
    private userActivityService: UserActivityService,
    private i18NService: I18nService
  ) {}

  ngOnInit(): void {
    this.hasPointer = window.matchMedia('(pointer: fine)').matches;
    setTimeout(() => {
      this.initValues();
    }, 2000);
  }

  ngAfterViewChecked(): void {}

  showDetails(index: any) {
    this.showCardBody[index] = !this.showCardBody[index];
  }

  getMediumImg(id: number) {
    return this.imageService.mediumThumbnail(id);
  }

  getPreviousPhoto(event: Event, currentAd: any, index: number) {
    event.stopPropagation();
    if (this.currentPhotoIndex[index] === 0) {
      this.photoSlider.toArray()[index].slideTo(currentAd.photos.length - 1);
    } else {
      this.photoSlider.toArray()[index].slidePrev();
    }
    this.updatePhotoIndex(index);
  }

  getNextPhoto(event: Event, currentAd: any, index: number) {
    event.stopPropagation();
    if (this.currentPhotoIndex[index] === currentAd.photos.length - 1) {
      this.photoSlider.toArray()[index].slideTo(0);
    } else {
      this.photoSlider.toArray()[index].slideNext();
    }
    this.updatePhotoIndex(index);
  }

  openWebsite(advertisement: any) {
    const website = advertisement.website;
    window.open(website, '_system');
    this.registerUserActivity(advertisement.id, UserActivityType.SERVICE_PROVIDER_DETAIL);
  }

  registerUserActivity(adCompanyId: number, module: UserActivityType) {
    const userActivity: UserActivity = {
      module: module,
      brokerId: null,
      listingId: this.listingId,
      adCompanyId: adCompanyId
    };
    this.userActivityService.saveUserActivity(userActivity);
  }

  initValues() {
    if (this.advertisements) {
      for (let ad of this.advertisements) {
        let i = this.advertisements.indexOf(ad);
        this.showCardBody[i] = true;
        this.currentPhotoIndex[i] = 0;
      }
    }
  }

  getI18nValue(textValue: string) {
    try {
      if (textValue && JSON.parse(textValue)) {
        return this.i18NService.getTranslation(textValue);
      } else {
        return '';
      }
    } catch (e) {
      console.error('failed to parse locale string -> listings search');
    }
  }

  updatePhotoIndex(index: number) {
    this.photoSlider
      .toArray()
      [index].getActiveIndex()
      .then((ind: number) => {
        this.currentPhotoIndex[index] = ind;
      });
  }
}
