import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'review-my-listings',
  templateUrl: './my-listing.component.html',
  styleUrls: ['./my-listing.component.scss']
})
export class ReviewMyListingComponent {
  date: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.date = new Date();
    this.activatedRoute.params.subscribe(val => {
      this.date = new Date();
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }
  ngOnInit() {}
}
