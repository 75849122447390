import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { DirectionType, SliderOptionsType, sliderStatusType } from '@app/models/shared/lazy-slider.model';
import { LazySliderService } from '../services/lazy-slider.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'lazy-slider',
  templateUrl: './lazy-slider.component.html',
  styleUrls: ['./lazy-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class LazySliderComponent implements OnChanges, OnInit {
  @ViewChild('slider', { static: false }) slider: IonSlides;
  @Input() noDataMsg: string = 'No Data Found';
  @Input() slideOptionOverride: SliderOptionsType;
  @Input() showPager: boolean = false;
  @Input() showScrollbar: boolean = false;
  @Input() loadedItems: any[] = []; // Unloaded items should be a falsy value like "null".
  @Input() totalCount: number;
  @Input() loading: boolean = false;
  @Input() queryPage: number;
  @Input('querySize') queryPageSize: number;
  @Input('changeSlide') changeSlide$: Subject<DirectionType> = null;
  @Output('getItems') getMoreItems: EventEmitter<{ pageSize: number; pageNumber: number }> = new EventEmitter<{
    pageSize: number;
    pageNumber: number;
  }>();
  @Output() onSliderUpdate: EventEmitter<sliderStatusType> = new EventEmitter<sliderStatusType>();
  @Input() isPromoted?: boolean;

  slidesPerView: number;
  sliderOptions: SliderOptionsType;
  pagerSubscription: Subscription = null;
  defaultSliderOptions: SliderOptionsType = cloneDeep(this.lazySliderSvc.defaultSliderOpts);

  constructor(private lazySliderSvc: LazySliderService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.slider && changes.loadedItems) {
      this.slider.update();
      this.slider.lockSwipes(false);
    }
  }

  ngOnInit(): void {
    this.setSliderOptions();
    this.subscribeToSlideChange();
  }

  ngOnDestroy() {
    if (this.pagerSubscription) {
      this.pagerSubscription.unsubscribe();
    }
  }

  setSliderOptions() {
    this.sliderOptions = {
      ...this.defaultSliderOptions,
      ...this.slideOptionOverride
    };
  }

  subscribeToSlideChange() {
    if (!this.changeSlide$) return;
    this.pagerSubscription = this.changeSlide$.subscribe(Direction => {
      if (Direction === 'next') return this.slideNext();
      if (Direction === 'prev') return this.slidePrev();
    });
  }

  onSlidesInit() {
    this.slider.update().then(() => {
      this.setSlidesPerView(window.innerWidth);
      this.notifyChange();
    });
  }

  async getPaginationInfo() {
    const activeIndex = await this.slider.getActiveIndex();
    const currentPage = Math.ceil((activeIndex + 1) / this.slidesPerView);
    return { activeIndex, currentPage };
  }

  async slidePrev() {
    this.slider.slidePrev();
  }

  async slideNext() {
    this.slider.slideNext();
  }

  async onSlideNextEnd() {
    const { currentPage } = await this.getPaginationInfo();
    const loadedAmount = this.loadedItems.filter((i: any) => i).length;
    const missingItems = currentPage * this.loadedItems.length > loadedAmount;
    if (missingItems && loadedAmount < this.totalCount) {
      this.fetchItems();
    }
  }

  fetchItems() {
    this.slider.lockSwipes(true);
    this.getMoreItems.emit({
      pageSize: this.queryPageSize,
      pageNumber: this.queryPage + 1
    });
  }

  onResize(event: any) {
    this.setSlidesPerView(event.target.innerWidth);
  }

  private setSlidesPerView(containerWidth: number) {
    if (this.sliderOptions.breakpoints) {
      const breakpointProps = this.lazySliderSvc.getBreakPointProps(containerWidth, this.sliderOptions.breakpoints);
      if (this.slidesPerView === breakpointProps.slidesPerView) return;

      this.slidesPerView = breakpointProps.slidesPerView;
      this.sliderOptions = {
        ...cloneDeep(this.sliderOptions),
        ...breakpointProps
      };
    }
  }

  async notifyChange() {
    const isEnd = await this.slider.isEnd();
    const isBeginning = await this.slider.isBeginning();
    const sliderState: sliderStatusType = { isEnd, isBeginning };
    this.onSliderUpdate.emit(sliderState);
  }
}
