<ion-content>
  <app-breadcrumb inViewport [inViewportOptions]="{ thredhold: [0] }" [options]="breadcrumbOptions"></app-breadcrumb>
  <div style="max-width: 800px; background: white; margin: 0 auto; ">
    <ion-row>
      <div class="logo-wrapper">
        <h4 class="create-heading" style="margin-top: 15px;" *ngIf="!renew">
          {{ 'global.my-list-menu.registerYour' | translate }}
          <img class="spot-img" src="assets/SPOT-Full-Logo.png" />
        </h4>
        <h4 class="create-heading" style="margin-top: 15px;" *ngIf="renew">
          {{ 'global.my-list-menu.renewYour' | translate }}
          <img class="spot-img" src="assets/SPOT-Full-Logo.png" />
        </h4>
      </div>

      <p style="text-align: center;   margin: 5px;" *ngIf="!renew">
        {{ 'global.my-list-menu.addNewListingCredits' | translate }}
      </p>

      <p style="text-align: center;   margin: 5px;" *ngIf="renew">
        {{ 'global.my-list-menu.renewUsingAvialableCredits' | translate }}
      </p>

      <ion-row *ngIf="spotAdmin" class="full-width margin-top-5" style="margin-top: 10px;">
        <ion-col size="12">
          <ion-label>{{ 'global.purchase-options.company' | translate }} <span class="blue">*</span></ion-label>
          <span class="width-full my-listing-company">
            <ionic-selectable
              style="border: 1px solid lightgrey;"
              [items]="allCompanies"
              itemValueField="id"
              itemTextField="name"
              [disabled]="!spotAdmin"
              [(ngModel)]="selectCompany"
              [hasVirtualScroll]="true"
              placeholder="Company"
              [canSearch]="true"
              (onChange)="portChange($event)"
              searchPlaceholder="{{ 'global.menu.search' | translate }}"
              closeButtonText="{{ 'Cancel' | translate }}"
              #portComponent
            >
            </ionic-selectable>
          </span>
        </ion-col>
      </ion-row>

      <ion-row
        *ngIf="spotAdmin || listingAdmin"
        class="full-width margin-top-5"
        style="margin-top: 10px;
    margin-bottom: 10px;"
      >
        <ion-col size="12">
          <ion-label>{{ 'global.my-list-menu.listingAgent' | translate }} <span class="blue">*</span></ion-label>
          <span class="width-full my-listing-company">
            <ionic-selectable
              style="border: 1px solid lightgrey;"
              [(ngModel)]="offeredByUserId"
              [items]="users"
              itemValueField="id"
              [disabled]="!selectCompany || (!spotAdmin && !listingAdmin)"
              itemTextField="firstNameAndLastName"
              [hasVirtualScroll]="true"
              placeholder="Agent"
              [canSearch]="true"
              (onChange)="nameChange($event)"
              searchPlaceholder="{{ 'global.menu.search' | translate }}"
              closeButtonText="{{ 'Cancel' | translate }}"
              #agentComponent
            >
            </ionic-selectable>
          </span>
        </ion-col>
      </ion-row>
    </ion-row>

    <ion-row *ngFor="let credit of userCredits; let index = i">
      <button class="purchase-credits" (click)="navigateToCreate(credit, credit)" *ngIf="credit.purchaseAmount > 0">
        <ion-row style="margin-bottom: 5px;">
          <span
            >{{ 'global.my-list-menu.listingClass' | translate }}:
            <span style="text-transform: uppercase;">{{ credit?.premium ? 'Premium' : 'Promoted' }}</span></span
          >
        </ion-row>

        <ion-row style="margin-bottom: 5px;">
          <span>{{ 'global.my-list-menu.purchaseDate' | translate }}: {{ credit?.effectiveDate | date }}</span>
        </ion-row>

        <ion-row style="margin-bottom: 5px; position: relative;">
          <span>{{ 'global.my-list-menu.creditsPurchased' | translate }}: {{ credit?.creditsPurchased }}</span>
          <span class="select-btn">{{ 'global.my-list-menu.select' | translate }}</span>
        </ion-row>

        <ion-row style="margin-bottom: 5px;">
          <span class="credit-available"
            >{{ 'global.my-list-menu.creditsAvailable' | translate }}: {{ credit?.creditsAvailable }}</span
          >
        </ion-row>

        <ion-row style="margin-bottom: 5px;">
          <span>{{ 'global.my-list-menu.listingExp' | translate }}: {{ credit?.listingExpirationDate | date }}</span>
        </ion-row>
      </button>

      <button
        class="free-card position-relative"
        (click)="navigateToCreate(credit, credit)"
        *ngIf="credit.purchaseAmount == 0"
      >
        <ion-row style="margin-bottom: 5px;">
          <span>{{ 'global.my-list-menu.listingIssued' | translate }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;" *ngIf="credit.companyName">
          <span>{{ 'global.purchase-options.company' | translate }}: {{ credit?.companyName }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;" *ngIf="!credit.companyName">
          <span>{{ 'global.purchase-options.company' | translate }}: {{ selectCompany.name }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;" *ngIf="credit.companyName">
          <span>{{ 'global.my-list-menu.userAccount' | translate }}: {{ credit?.purchasedBy }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;">
          <span>{{ 'global.my-list-menu.issueDate' | translate }}: {{ credit?.effectiveDate | date }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;">
          <span
            >{{ 'global.my-list-menu.listingClass' | translate }}: {{ credit?.premium ? 'Premium' : 'Promoted' }}</span
          >
        </ion-row>
        <ion-row style="margin-bottom: 5px;">
          <span>{{ 'global.my-list-menu.purchaseDate' | translate }}: {{ credit?.effectiveDate | date }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;">
          <span>{{ 'global.my-list-menu.creditsPurchased' | translate }}: {{ credit?.creditsPurchased }} </span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;">
          <span class="credit-available"
            >{{ 'global.my-list-menu.creditsAvailable' | translate }}: {{ credit?.creditsAvailable }}</span
          >
        </ion-row>
        <ion-row style="margin-bottom: 5px;">
          <span>{{ 'global.my-list-menu.listingExp' | translate }}: {{ credit?.listingExpirationDate | date }}</span>
        </ion-row>
        <span class="helper-text position-absolute" translate="global.my-list-menu.selectThisCredit"></span>
      </button>
    </ion-row>

    <ion-row class="submit-row">
      <ion-col class="padding-20-tb" size="6">
        <ion-button (click)="navigateToPurchase()" style="width: 100%" color="secondary">
          {{ 'global.my-list-menu.purchaseMoreCredits' | translate }}
        </ion-button>
      </ion-col>
      <ion-col class="padding-20-tb" size="6">
        <ion-button (click)="navigateToMyListing()" style="width: 100%" color="secondary">
          {{ 'global.my-list-menu.reviewPurchases' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
