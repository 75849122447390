import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, ElementRef, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ListingService, EventCheckerService, CreateListingService, ListingCarouselService } from '@app/core/listings';
import { Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import * as Fuse from 'fuse.js';
import { IonSelect, IonContent } from '@ionic/angular';
import { PROPERTY_TYPES } from '@app/models';
import { TRANSACTION_TYPES, ListingOfferType } from '@app/models/transaction-type.model';
import { I18nService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { SearchTagsService } from '@app/core/search-tags.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { RegisterService } from '@app/core/register.service';
import { ReplaySubject } from 'rxjs';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { SelectedFilterKey } from './filter-selected-keys';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { UserService, UserRoles } from '@app/core/helpers/user.service';
import { StatusHelperService } from '@app/core/helpers/status-helper.service';
import { ClassHelperService } from '@app/core/helpers/classes-helper.service';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { takeUntil } from 'rxjs/operators';
import { MetaService } from '@app/core/seo';
import { environment } from '@env/environment';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { ShellComponent } from '@app/shell/shell.component';
export class ListingSearchComponent {
    constructor(router, listingService, activatedRoute, location, i18nService, breakpointObserver, createListingService, commonService, searchTagService, registerService, _eventChecker, nearbyListingSvc, listingSearchService, userService, statusHelperService, classHelperService, popOverCtrlHelper, listingCarouselService, metaService, userActivityService, sellComponent) {
        this.router = router;
        this.listingService = listingService;
        this.activatedRoute = activatedRoute;
        this.location = location;
        this.i18nService = i18nService;
        this.breakpointObserver = breakpointObserver;
        this.createListingService = createListingService;
        this.commonService = commonService;
        this.searchTagService = searchTagService;
        this.registerService = registerService;
        this._eventChecker = _eventChecker;
        this.nearbyListingSvc = nearbyListingSvc;
        this.listingSearchService = listingSearchService;
        this.userService = userService;
        this.statusHelperService = statusHelperService;
        this.classHelperService = classHelperService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.listingCarouselService = listingCarouselService;
        this.metaService = metaService;
        this.userActivityService = userActivityService;
        this.sellComponent = sellComponent;
        this.scrollToCarousel = new EventEmitter();
        this.displayScrollToTop = true;
        this.mapToggleIcons = {
            map: { imageUrl: 'assets/images/icons/google-map-225.png' },
            list: { imageUrl: 'assets/images/icons/list-view.png' }
        };
        this.propertyTypes = [...PROPERTY_TYPES];
        this.transactionTypes = [...TRANSACTION_TYPES];
        this.isMobile = false;
        this.breadcrumbNavOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.listingSearchDto = {};
        this.listings = [];
        this.listingMarkers = [];
        this.displayMap = false;
        this.selectOptions = { cssClass: 'top-search-btn' };
        this.mapOptions = {
            zoom: 15,
            singleMarker: false,
            ignoreZoom: false,
            geopositionControl: true
        };
        this.inputCity = '';
        this.optionSelected = false;
        this.cityOptions = [];
        this.searching = false;
        this.searchingMoreListings = false;
        this.currentPage = 1;
        this.totalListingsCount = 0;
        this.totalActiveListingsCount = 0;
        this.showMoreIcon = false;
        this.searchTagsLength = 0;
        this.noListingsFoundMessage = '';
        this.avoidSearchOnBuildingTypeChange = false;
        this.avoidSearchOnListingTypeChange = false;
        this.showUniquePropertyListing = false;
        this.isInfowindowClose = false;
        this.allMatchingListing = [];
        this.currentActiveInfoWindow = null;
        this.admin = false;
        this.searchAnalytics = true;
        this.hasGeoLocationError = false;
        this.loadingPage = false;
        this.isFavoritesPage = false;
        this.showAllFavoritesOnly = false;
        this.destroyed$ = new ReplaySubject(1);
        this.disabledExtendListingsBtn = true;
        this.contFilter = 0;
        this.searchByGeoLocation = (position) => {
            this.hasGeoLocationError = false;
            this.clientPosition = this.nearbyListingSvc.getUserLocationCoordinate(position.coords);
            this.nearByListingSearch(this.clientPosition);
        };
        this.showGeoLocationError = (error) => {
            this.hasGeoLocationError = true;
            this.noListingsFoundMessage = this.nearbyListingSvc.getNoResultMessage(0);
            this.geoLocationErrorCode = this.nearbyListingSvc.getGeoLocationErrorCode(error);
        };
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.screenWidth = window.innerWidth;
        this.outerScreenWidth = window.outerWidth;
        this.classLbl = this.i18nService.get('global.listing-card.class');
        this.searchModel = {
            keywordText: '',
            propertyType: '',
            listingType: '',
            stateId: null,
            cityId: null,
            propertySubTypes: null
        };
    }
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
        this.outerScreenWidth = event.target.outerWidth;
    }
    ngOnDestroy() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.destroyed$.next(true);
            this.destroyed$.complete();
            if (this.modalSubscribtion) {
                this.modalSubscribtion.unsubscribe();
            }
            if (this.userGeoLocationSubscription) {
                this.userGeoLocationSubscription.unsubscribe();
            }
        });
    }
    ngOnInit() {
        this.subscribeToParamsChange();
        this.loadingPage = true;
        this.searchModalSubscription();
        this.localStorageKey = this.listingSearchService.findLocalStorageKeyFromSelectedFilters({
            myListingAnalytics: this.myListingAnalytics,
            myListingPage: this.myListingPage,
            favoritesPage: this.isFavoritesPage
        });
        this.initSpotNearMeSearch();
        this.clearSearchData();
        this.getSearchDTO();
        this.breakpointObserver.observe([Breakpoints.Handset, '(min-width: 700px)']).subscribe(result => {
            this.isMobile = result.matches;
        });
        this.faSearch = faSearch;
        this.spotAdmin = this.userService.checkRole(UserRoles.ROLE_SPOT_ADMIN);
        this.listingAdmin = this.userService.checkRole(UserRoles.ROLE_LISTING_ADMIN);
        this.getAdvertisements();
        this.updateListingNow();
        this.loadingPage = false;
    }
    subscribeToParamsChange() {
        this.activatedRoute.params.subscribe((params) => {
            const { from } = params;
            if (from && from.includes('favorites')) {
                this.isFavoritesPage = true;
                this.listingSearchDto.onlyFavorites = true;
                this.clearSpotNearMeSearch();
                if (/^(favorites)\d+$/g.test(from)) {
                    this.showAllFavoritesOnly = true;
                }
            }
            else {
                this.isFavoritesPage = false;
            }
            this.date = new Date();
        });
    }
    searchModalSubscription() {
        if (!this.modalSubscribtion) {
            this.modalSubscribtion = this.listingService.currentModel.pipe(takeUntil(this.destroyed$)).subscribe(model => {
                let sm = model;
                if (sm === 'reload') {
                    this.searchMyListings(this.listingSearchDto);
                }
                else if (sm === 'destroy' && !this.loadingPage) {
                    return;
                }
                else if (sm && !sm.updateModelFromListingDetail) {
                    if (sm.regularSearch && !this.isComponentLoadedFromRegularSearch()) {
                        return;
                    }
                    if (!Object.keys(sm).length) {
                        return;
                    }
                    this.searchModel = sm;
                    this.getFilter();
                    this.updateSearchDTOFromModal();
                }
                else if (sm && sm.updateModelFromListingDetail) {
                    this.listingSearchDto = this.listingSearchService.readSearchDTOFromStorage(SelectedFilterKey.REGULAR_SEARCH, {});
                    this.listingService.propertySubTypesFromHomepage = this.listingSearchDto.propertySubTypes;
                    this.searchModel = sm;
                    this.getFilter();
                    this.searchModel.updateModelFromListingDetail = false;
                    this.updateSearchModalFromSearchDTO();
                }
            });
        }
    }
    getFilter() {
        if (this.router.url.includes('filter=true') && this.contFilter == 0) {
            document.getElementById('filter').click();
            this.contFilter = 1;
        }
    }
    getCompanies() {
        this.registerService
            .getListingCompanies()
            .toPromise()
            .then(companies => {
            this.allCompanies = companies;
            this.setAdminView();
        });
    }
    getSearchDTO() {
        this.getListingSearchDto().then(() => {
            this.checkBoxValues = this.createListingService.initCheckboxFields();
            this.updateSearchModalFromSearchDTO();
            this.triggerSearchFilterChange();
            if (this.isComponentLoadedFromRegularSearch()) {
                this.getStates();
                if (this.searchModel.stateId)
                    this.getCities();
                this.searchListings(this.activatedRoute.snapshot.url.length <= 1);
                this.setMetaTags();
            }
        });
    }
    getListingSearchDto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.allCities = yield this.listingService.getCities();
            if (!this.showAllFavoritesOnly) {
                this.listingSearchDto = this.listingSearchService.readSearchDTOFromStorage(this.localStorageKey, this.listingSearchDto);
            }
            if (!this.isComponentLoadedFromRegularSearch()) {
                this.statusHelperService.getStatuses();
                this.getCompanies();
                this.searchListings(true);
            }
            else if (!this.listingSearchDto ||
                !this.listingSearchDto.listingLeaseOrSale ||
                (this.listingSearchDto &&
                    this.listingSearchDto.listingLeaseOrSale &&
                    this.listingSearchDto.listingLeaseOrSale[0] !== ListingOfferType.Coworking)) {
                // We want to keep lease type as the coworking URL
                if (!this.listingSearchDto || !this.listingSearchDto.listingLeaseOrSale || !this.listingSearchDto.buildingTypes) {
                    yield this.buildSearchDTOBasedOnURL();
                }
                else if (this.listingSearchDto &&
                    this.listingSearchDto.buildingTypes &&
                    this.listingSearchDto.buildingTypes[0] !== ListingOfferType.Coworking) {
                    yield this.buildSearchDTOBasedOnURL();
                }
            }
        });
    }
    buildSearchDTOBasedOnURL() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // GET DTO from URL
            let searchURL = null;
            try {
                const params = this.activatedRoute.snapshot.params;
                if (this.activatedRoute.snapshot.url.length > 1 &&
                    params &&
                    params.from !== 'spotsNearMe' &&
                    !this.isFavoritesPage) {
                    searchURL = this.activatedRoute.snapshot.url.slice(1).join('/');
                    searchURL = this.i18nService.removeLanguageFromURL(searchURL);
                    if (!searchURL || searchURL.length == 0) {
                        return;
                    }
                    const buildingNameFilter = this.activatedRoute.snapshot.queryParamMap.get('building');
                    yield this.listingService
                        .getListingsSearchDto(searchURL, this.activatedRoute.snapshot.queryParamMap.get('keyword'), buildingNameFilter)
                        .then((dtoFromURL) => {
                        if (dtoFromURL) {
                            this.listingSearchDto.buildingName = buildingNameFilter;
                            this.listingSearchDto.buildingTypes = dtoFromURL.buildingTypes
                                ? dtoFromURL.buildingTypes
                                : this.listingSearchDto.buildingTypes;
                            this.listingSearchDto.cityId = dtoFromURL.cityId ? dtoFromURL.cityId : this.listingSearchDto.cityId;
                            this.listingSearchDto.listingLeaseOrSale = dtoFromURL.listingLeaseOrSale
                                ? dtoFromURL.listingLeaseOrSale
                                : this.listingSearchDto.listingLeaseOrSale;
                            this.listingSearchDto.stateId = dtoFromURL.stateId ? dtoFromURL.stateId : this.listingSearchDto.stateId;
                            this.listingSearchDto.keyword = dtoFromURL.keyword;
                            this.listingSearchDto.buildingId = dtoFromURL.buildingId;
                        }
                    });
                }
            }
            catch (error) {
                const state = {
                    badURL: `${environment.spotServerUrl}/search/${searchURL}`
                };
                console.error('Error search page', error, searchURL);
                this.router.navigateByUrl('/not-found', { state: state });
            }
        });
    }
    setAdminView() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.selectCompany = null;
            this.selectUser = null;
            if (this.listingSearchDto) {
                this.setInitialValuesForAdminUsers();
                if (this.listingSearchDto.companyId) {
                    this.selectCompany = this.listingService.getSelectedCompany(this.allCompanies, this.listingSearchDto.companyId)[0];
                    this.users = yield this.getUsersByCompany(this.selectCompany.id);
                    if (this.listingSearchDto.offeredByUserId) {
                        this.selectUser = this.listingService.getSelectedAgent(this.users, this.listingSearchDto.offeredByUserId)[0];
                    }
                }
            }
        });
    }
    updateSearchDTOFromModal() {
        if (this.listingSearchDto && this.searchModel) {
            this.listingSearchDto.buildingTypes = this.searchModel.propertyType ? [this.searchModel.propertyType] : null;
            this.listingSearchDto.listingLeaseOrSale = this.searchModel.listingType ? [this.searchModel.listingType] : null;
            this.listingSearchDto.stateId = this.searchModel.stateId ? this.searchModel.stateId : null;
            this.listingSearchDto.cityId = this.searchModel.cityId ? this.searchModel.cityId : null;
            this.listingSearchDto.keyword = this.searchModel.keywordText ? this.searchModel.keywordText : null;
            if (this.searchModel.propertySubTypes) {
                this.listingSearchDto.propertySubTypes = this.searchModel.propertySubTypes;
                this.listingSearchDto.propertySubtypeIds = this.searchModel.propertySubTypes.map((t) => t.id);
            }
            else {
                this.listingSearchDto.propertySubtypeIds = null;
            }
        }
    }
    updateSearchModalFromSearchDTO() {
        //Clear out few things before initializing city, state, propType and listingType from carousel urls
        this.clearSearchModel();
        if (this.listingSearchDto) {
            if (this.listingSearchDto && this.listingSearchDto.buildingTypes) {
                this.avoidSearchOnBuildingTypeChange = true;
                this.searchModel.propertyType = this.listingSearchDto.buildingTypes[0];
            }
            if (this.listingSearchDto && this.listingSearchDto.listingLeaseOrSale) {
                this.avoidSearchOnListingTypeChange = true;
                this.searchModel.listingType = this.listingSearchDto.listingLeaseOrSale[0]
                    ? this.listingSearchDto.listingLeaseOrSale[0].toString()
                    : null;
            }
            if (this.listingSearchDto.stateId)
                this.searchModel.stateId = this.listingSearchDto.stateId;
            if (this.listingSearchDto.cityId)
                this.searchModel.cityId = this.listingSearchDto.cityId;
            if (this.listingSearchDto.keyword)
                this.searchModel.keywordText = this.listingSearchDto.keyword;
            this.searchModel.propertySubTypes = this.listingSearchDto.propertySubTypes;
            this.searchModel.coworkingPositions = this.listingSearchDto.coworkingPositions;
            this.searchModel.coworkingType = this.listingSearchDto.coworkingType;
            this.setCoworkingSelectedBasedOnSearchModel();
        }
    }
    searchMyListings(dto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            dto.myListingPage = true;
            this.disabledExtendListingsBtn = true;
            return yield this.listingService.searchListingIds(this.listingSearchDto, 1).then((listingIds) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.totalActiveListingsCount = listingIds && listingIds.headers ? listingIds.headers.get('X-Total-Active') : 0;
                this.totalListingsCount = listingIds.body.length;
                if (!this.totalListingsCount || this.totalListingsCount == 0) {
                    this.noSearchResults();
                }
                else {
                    if (this.myListingAnalytics) {
                        yield this.getAnalyticsData(listingIds.body);
                        return;
                    }
                    yield this.getListingsByPage(1, 20, listingIds.body);
                }
            }));
        });
    }
    getAnalyticsData(listingIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.analyticsData = yield this.listingService.getListingAnalytics(listingIds);
            this.searchAnalytics = false;
            if (this.analyticsData) {
                yield this.getListingsByPage(1, 20, listingIds);
            }
        });
    }
    getListingsByPage(page, pageSize, listingIds, concatNewResult) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.listingService
                .getListingsByPage(page, pageSize, listingIds, this.listingSearchDto.coworkingPositions, this.listingSearchDto.coworkingType, this.currentSearchTracking && this.currentSearchTracking.searchId ? this.currentSearchTracking.searchId : null, this.myListingAnalytics ? true : false)
                .then(result => {
                this.showMoreIcon = this.totalListingsCount - this.currentPage * pageSize > 0;
                if (concatNewResult) {
                    let newListings = result;
                    if (newListings && newListings.length) {
                        this.listings = this.listings.concat(newListings);
                    }
                }
                else {
                    this.listings = result;
                }
                this.allListingIds = listingIds;
                this.updateTags();
                this.isInValidToRenewAndExtendListings();
                this.searching = false;
            });
        });
    }
    isInValidToRenewAndExtendListings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.disabledExtendListingsBtn = true;
            if (this.myListingPage && this.allListingIds && this.listingSearchDto && this.listingSearchDto.listingStatuses) {
                const expiredOrActiveFilters = this.listingSearchDto.listingStatuses.filter((status) => status == '4' || status == '5');
                this.disabledExtendListingsBtn = !(expiredOrActiveFilters.length === this.listingSearchDto.listingStatuses.length);
            }
        });
    }
    searchListingsChangeListener() {
        this.updateSearchDTOFromModal();
        this.searchListings(true);
    }
    searchListings(resetUrl, clientPosition) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (localStorage.getItem('sell')) {
                localStorage.removeItem('sell');
            }
            else {
                this.sellComponent.openLoginDialogFirstLoad(true);
            }
            this.noListingsFoundMessage = '';
            this.searching = true;
            this.showUniquePropertyListing = false;
            this.currentActiveInfoWindow = null;
            this.currentPage = 1;
            this.allMatchingListing = [];
            const isSpotNearMeSearch = this.isSpotNearMeSearch;
            if (resetUrl && this.isComponentLoadedFromRegularSearch()) {
                this.resetUrl();
            }
            this.commonService.deleteNullAttributesFrom(this.listingSearchDto);
            this.listingSearchService.saveSearchDtoOnStorage(this.localStorageKey, this.listingSearchDto);
            this.triggerSearchFilterChange();
            this.isSpotNearMeSearch = isSpotNearMeSearch;
            yield this.updateTags();
            this.setMetaTags();
            // tracking listings Search
            yield this.trackingListingsSearch();
            yield this.findAllSearchInfoToBeDisplayed().then((r) => {
                if (!this.isSpotNearMeSearch) {
                    this.searching = false;
                }
            });
        });
    }
    findAllSearchInfoToBeDisplayed() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.isComponentLoadedFromRegularSearch()) {
                this.listingSearchDto = this.removeSearchAttr(this.listingSearchDto);
                this.hasGeoLocationError = false;
                yield this.searchMyListings(this.listingSearchDto);
            }
            else if (this.isSpotNearMeSearch) {
                this.getUserCurrentGeoLocation();
            }
            else if (this.listingSearchDto.onlyFavorites) {
                this.hasGeoLocationError = false;
                this.listingSearchDto = this.removeSearchAttr(this.listingSearchDto);
                yield this.regularListingSearch();
                this.getAllListing();
            }
            else {
                this.hasGeoLocationError = false;
                this.listingSearchDto = this.removeSearchAttr(this.listingSearchDto);
                yield this.regularListingSearch();
                this.getAllListing();
            }
        });
    }
    trackingListingsSearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.currentTags) {
                const searchTags = JSON.parse(JSON.stringify(this.currentTags));
                searchTags.map((tag) => {
                    tag.name = this.i18nService.getTranslationInTargetLanguage(tag.name, 'pt-BR');
                    return tag;
                });
                this.currentSearchTracking = yield this.userActivityService.trackListingsSearch(this.listingSearchDto, searchTags);
            }
        });
    }
    triggerSearchFilterChange() {
        this.updateSearchModalFromSearchDTO();
        if (this.searchModel) {
            this.searchModel.regularSearch = this.isComponentLoadedFromRegularSearch();
            const page = this.myListingAnalytics ? 'ANALYTICS' : this.myListingPage ? 'LISTING_PAGE' : 'REGULAR';
            this.searchModel.searchPage = page;
        }
        this.listingService.updateModel(this.searchModel);
    }
    updateTags() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentTags = yield this.searchTagService.updateSearchTags(this.listingSearchDto, this.isSpotNearMeSearch);
            this.statusHelperService.updateSelectedStatusesBasedOnDTO(this.listingSearchDto);
            this.classHelperService.updateSelectedClassesBasedOnDTO(this.listingSearchDto);
            return this.currentTags;
        });
    }
    getUsersByCompany(companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getUsersByCompany(companyId).toPromise();
        });
    }
    removeMyListingTag(item) {
        this.selectCompany = null;
        this.selectUser = null;
        this.listingSearchDto.companyId = null;
        this.listingSearchDto.offeredByUserId = null;
        this.searchListings(true);
    }
    removeMyUserTag(item) {
        this.selectUser = null;
        this.listingSearchDto.offeredByUserId = null;
        this.searchListings(true);
    }
    removeMyStatusTag(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.statusRemoved = true;
            this.statusHelperService.removeStatus(status);
            this.listingSearchDto.listingStatuses = yield this.statusHelperService.getStatusIds();
            yield this.searchListings(true);
            yield this.statusChange();
        });
    }
    statusChange($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.statusRemoved) {
                if (!this.listingSearchDto.listingStatuses.length) {
                    this.statusHelperService.selectStatus = [];
                }
                this.statusRemoved = false;
                return;
            }
            this.listingSearchDto.listingStatuses = yield this.statusHelperService.getStatusIds();
        });
    }
    removeMyClassTag(listingClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.classRemoved = true;
            this.classHelperService.removeClass(listingClass);
            this.listingSearchDto.listingClasses = yield this.classHelperService.getClassIds();
            yield this.searchListings(true);
            yield this.classChange();
        });
    }
    classChange($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.classRemoved) {
                if (!this.listingSearchDto.listingClasses.length) {
                    this.classHelperService.selectClass = [];
                }
                this.classRemoved = false;
                return;
            }
            this.listingSearchDto.listingClasses = yield this.classHelperService.getClassIds();
        });
    }
    // Observable functions for handling update events
    updateListingNow() {
        this._eventChecker.updateListing$.subscribe((listing) => {
            this.isInValidToRenewAndExtendListings();
            if (listing) {
                const updatedListing = this.listings.find((currListing) => listing.id === currListing.id);
                const favoriteChanged = !!updatedListing && updatedListing.favorite !== listing.favorite;
                const isFavoriteFiltered = this.listingSearchDto.onlyFavorites;
                if (listing === 'refresh' || (isFavoriteFiltered && favoriteChanged)) {
                    if (!this.loadingPage) {
                        this.searchListings(true, null);
                    }
                }
                else if (listing === 'no-refresh') {
                }
                else {
                    if (this.listings) {
                        this.listings = this.listings.map((currListing) => {
                            if (listing.id === currListing.id) {
                                return listing;
                            }
                            return currListing;
                        });
                    }
                    if (this.allMatchingListing) {
                        this.allMatchingListing = this.allMatchingListing.map(matchingListing => {
                            if (listing.id === matchingListing.id) {
                                return listing;
                            }
                            return matchingListing;
                        });
                    }
                }
            }
        });
        this._eventChecker.removeListing$.subscribe((id) => {
            if (this.listings && id) {
                let index = this.listings.findIndex((v) => {
                    return v.id === id;
                });
                this.listings.splice(index, 1);
            }
        });
        this._eventChecker.paUpdate$.subscribe((listing) => {
            if (this.listings && listing) {
                let index = this.listings.findIndex((v) => {
                    return v.id === listing.id;
                });
                this.listings[index] = listing;
            }
        });
    }
    clearCompany() {
        this.listingSearchDto.listingStatuses = null;
        this.selectCompany = null;
        this.selectUser = null;
        if (this.spotAdmin) {
            this.listingSearchDto.offeredByUserId = null;
            this.listingSearchDto.companyId = null;
        }
        else if (this.listingAdmin) {
            this.listingSearchDto.offeredByUserId = null;
        }
    }
    searchByGeoposition(clientMarker) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (clientMarker.position && clientMarker.map) {
                clientMarker.clientCoordinates = true;
                const clientCoordinates = {
                    lat: -23.548322,
                    lng: -46.631155,
                    clientCoordinates: true
                };
                this.refreshSearchData(clientCoordinates);
            }
        });
    }
    refreshSearchData(clientCoordinates) {
        this.searchListings(clientCoordinates);
    }
    openLocationPopover(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.isComponentLoadedFromRegularSearch()) {
                yield this.getStates();
            }
            this.locationFilters = {
                cities: this.cities,
                states: this.states,
                cityId: this.searchModel.cityId,
                stateId: this.searchModel.stateId,
                allCities: this.allCities,
                isSpotNearMeSearch: this.isComponentLoadedFromRegularSearch() ? this.isSpotNearMeSearch : null
            };
            const isFavoriteFiltered = this.listingSearchDto.onlyFavorites;
            this.popOverCtrlHelper.openLocationPopover(ev, this.locationFilters, (popoverData) => {
                if (!popoverData.fromCancel &&
                    (this.searchModel.stateId != popoverData.stateId ||
                        this.searchModel.cityId != popoverData.cityId ||
                        this.isSpotNearMeSearch !== popoverData.isSpotNearMeSearch)) {
                    this.searchModel.stateId = popoverData.stateId;
                    this.searchModel.cityId = popoverData.cityId;
                    this.selectedStateName = popoverData.stateName;
                    this.selectedCityName = popoverData.cityName;
                    this.isSpotNearMeSearch = popoverData.isSpotNearMeSearch;
                    this.listingSearchDto.stateId = this.searchModel.stateId;
                    this.listingSearchDto.cityId = this.searchModel.cityId;
                    this.listingSearchDto.onlyFavorites = !popoverData.isSpotNearMeSearch && isFavoriteFiltered;
                    this.searchListings(true);
                }
            });
        });
    }
    openMorePopover(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.popOverCtrlHelper.openMorePopover(ev, this.checkBoxValues, this.searchModel, this.listingSearchDto, (data) => {
                this.listingSearchDto = data;
                this.searchListings(true);
            });
        });
    }
    openNewListingTypePopover(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.popOverCtrlHelper.openNewListingTypePopover(ev, this.searchModel, (data) => {
                if (data === 'null') {
                    this.searchModel.listingType = null;
                }
                else {
                    this.searchModel.listingType = data;
                }
                this.listingSearchDto.listingLeaseOrSale = this.searchModel.listingType ? [this.searchModel.listingType] : null;
                this.deleteSearchTagsBasedOnListingType();
                this.searchListings(true);
            });
        });
    }
    openCoworkingPopover(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.popOverCtrlHelper.openCoworkingPopover(ev, this.listingSearchDto, (popoverData) => {
                this.listingSearchDto = popoverData;
                this.searchModel.coworkingPositions = popoverData.coworkingPositions;
                this.searchModel.coworkingType = popoverData.coworkingType;
                this.listingService.coworkingFilterFromHomepage = {};
                this.listingService.coworkingFilterFromHomepage.coworkingPositions = popoverData.coworkingPositions;
                this.listingService.coworkingFilterFromHomepage.coworkingType = popoverData.coworkingType;
                this.searchListings(true);
            });
        });
    }
    openNewPropertyTypePopover(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.popOverCtrlHelper.openNewPropertyTypePopover(ev, this.listingSearchDto, (popoverData) => {
                if (popoverData) {
                    this.avoidSearchOnBuildingTypeChange = false;
                    this.listingSearchDto = popoverData;
                    const oldPropertyType = this.searchModel.propertyType;
                    let newPropertyType = null;
                    if (this.listingSearchDto.buildingTypes && this.listingSearchDto.buildingTypes.length > 0) {
                        newPropertyType = this.listingSearchDto.buildingTypes[0];
                    }
                    const searchListings = this.haveSubTypesChanged(oldPropertyType, newPropertyType);
                    this.searchModel.propertyType = newPropertyType;
                    this.searchModel.propertySubTypes = this.listingSearchDto.propertySubTypes;
                    this.listingService.propertySubTypesFromHomepage = this.searchModel.propertySubTypes;
                    if (newPropertyType === ListingOfferType.Coworking) {
                        this.listingSearchDto.listingLeaseOrSale = [ListingOfferType.Coworking];
                        this.searchModel.listingType = ListingOfferType.Coworking;
                    }
                    if (oldPropertyType === ListingOfferType.Coworking &&
                        (!newPropertyType || newPropertyType !== ListingOfferType.Coworking)) {
                        this.searchModel.listingType = null;
                        this.listingSearchDto.listingLeaseOrSale = null;
                    }
                    this.setCoworkingSelectedBasedOnSearchModel();
                    if (searchListings) {
                        this.searchListings(false);
                    }
                }
            });
        });
    }
    haveSubTypesChanged(oldPropertyType, newPropertyType) {
        if (oldPropertyType == newPropertyType) {
            const newSubTypes = this.listingSearchDto.propertySubTypes;
            const oldSubTypes = this.searchModel.propertySubTypes;
            if (!newSubTypes && !oldSubTypes) {
                return false;
            }
            return JSON.stringify(newSubTypes) !== JSON.stringify(oldSubTypes);
        }
        return false;
    }
    setCoworkingSelectedBasedOnSearchModel() {
        this.coworkingSelected = false;
        if (this.searchModel) {
            if (this.searchModel.listingType === ListingOfferType.Coworking) {
                this.coworkingSelected = true;
            }
            if (!this.searchModel.propertySubTypes || this.searchModel.propertySubTypes.length == 0) {
                this.clearPropertySubtypes();
            }
            if (!this.coworkingSelected) {
                this.clearCoworkingValues();
            }
            if (this.coworkingSelected && !this.searchModel.coworkingType) {
                this.searchModel.coworkingType = 'shared';
                this.searchModel.coworkingPositions = this.searchModel.coworkingPositions
                    ? this.searchModel.coworkingPositions
                    : 1;
            }
        }
    }
    toggleMap(override) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.displayMap = !this.displayMap || override;
        });
    }
    openTypePopover(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.openNewListingTypePopover(ev);
        });
    }
    openProptypePopover(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.openNewPropertyTypePopover(ev);
        });
    }
    checkPropertyType() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.avoidSearchOnBuildingTypeChange)
                this.searchListings(true);
            this.avoidSearchOnBuildingTypeChange = false;
        });
    }
    navigateToSearch(event) {
        event.data = this.searchModel;
        this.scrollToCarousel.emit(event);
    }
    clearSearchData() {
        this.clearSearchModel();
        this.clearPropertySubtypes();
        this.clearCoworkingValues();
        this.clearListingSearchDTO();
        this.updateTags();
    }
    clearSearchModel() {
        this.searchModel = {
            cityId: null,
            stateId: null,
            propertySubTypeIds: null,
            propertyType: null,
            propertySubTypes: null,
            listingType: null,
            keywordText: null
        };
    }
    clearSearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.avoidSearchByChangedModelAfterClearSearch();
            this.clearSearchModel();
            this.clearPropertySubtypes();
            this.clearCoworkingValues();
            this.clearListingSearchDTO();
            this.clearSpotNearMeSearch();
            this.clearFavoritesSearchSearch();
            this.searchTagService.clearSearchTags();
            this.triggerSearchFilterChange();
            this.searchListings(true);
        });
    }
    clearSpotNearMeSearch() {
        this.isSpotNearMeSearch = false;
    }
    clearFavoritesSearchSearch() {
        this.showAllFavoritesOnly = false;
        this.listingSearchDto.onlyFavorites = false;
    }
    avoidSearchByChangedModelAfterClearSearch() {
        this.avoidSearchOnListingTypeChange = true;
        this.avoidSearchOnBuildingTypeChange = true;
    }
    clearListingSearchDTO() {
        this.allMatchingListing = [];
        this.listingSearchDto = this.searchTagService.clearSearchTagsFromDTO(this.listingSearchDto);
        if (this.listingSearchDto) {
            this.listingSearchDto.buildingTypes = null;
            this.listingSearchDto.listingLeaseOrSale = null;
            this.listingSearchDto.stateId = null;
            this.listingSearchDto.cityId = null;
            this.listingSearchDto.keyword = null;
        }
    }
    clearCoworkingValues() {
        this.listingService.coworkingFilterFromHomepage = {};
        this.searchModel.coworkingPositions = null;
        this.searchModel.coworkingType = null;
        this.listingSearchDto.coworkingPositions = null;
        this.listingSearchDto.coworkingType = null;
    }
    clearPropertySubtypes() {
        this.listingService.propertySubTypesFromHomepage = null;
        this.searchModel.propertySubTypes = null;
        this.listingSearchDto.propertySubTypes = null;
        this.listingSearchDto.propertySubtypeIds = null;
    }
    regularListingSearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.listingService.searchListingIds(this.listingSearchDto, 1).then((listingIds) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.totalActiveListingsCount = listingIds && listingIds.headers ? listingIds.headers.get('X-Total-Active') : 0;
                this.totalListingsCount = listingIds.body.length;
                if (!this.totalListingsCount || this.totalListingsCount === 0) {
                    this.noSearchResults();
                }
                else {
                    yield this.getListingsByPage(1, 10, listingIds.body);
                }
            }));
        });
    }
    noSearchResults() {
        this.noListingsFoundMessage = this.i18nService.get('global.search.noListingsFound');
        this.listings = [];
        this.showMoreIcon = this.totalListingsCount - this.currentPage * 20 > 0;
        this.allListingIds = null;
        this.updateTags();
    }
    resetUrl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.updateSearchDTOFromModal();
            const currentUrlObject = this.activatedRoute.snapshot.url;
            if (this.listingSearchDto.buildingTypes ||
                this.listingSearchDto.listingLeaseOrSale ||
                this.listingSearchDto.stateId ||
                this.listingSearchDto.cityId ||
                this.listingSearchDto.keyword ||
                (currentUrlObject.length > 1 && this.isSpotNearMeSearch)) {
                var response = yield this.listingService.getUrlFromListingsSearchDto(this.listingSearchDto);
                let lang = this.i18nService.getCurrentLanguage();
                response.url = JSON.parse(response.url);
                const newUrl = `${response.url[lang]}${response.keyword ? '?keyword=' + response.keyword : ''}`;
                const currentUrl = currentUrlObject.slice(1).join('/');
                if (`/${currentUrl}` !== newUrl) {
                    if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                        this.location.go(this.i18nService.addCurrentLanguageToPath(`/search${newUrl}`));
                    }
                    else {
                        this.location.go(`/search${newUrl}`);
                    }
                }
            }
            else if (currentUrlObject.length >= 1 && !this.isSpotNearMeSearch && !this.showAllFavoritesOnly) {
                if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                    this.location.go(this.i18nService.addCurrentLanguageToPath(`/search`));
                }
                else {
                    this.location.go(`/search`);
                }
            }
            else if (currentUrlObject.length === 1 && this.isSpotNearMeSearch) {
                this.router.navigate([`/search/`, 'spotsNearMe']);
            }
        });
    }
    scrollToListingCard() {
        if (this.outerScreenWidth <= 900) {
            setTimeout(() => {
                const elementCoordinate = this.uniqueListing.nativeElement;
                this.parentBlock.scrollToPoint(elementCoordinate.offsetLeft, elementCoordinate.offsetTop, 500);
            }, 500);
        }
    }
    getMoreListings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.searchingMoreListings = true;
            const pageSize = !this.isComponentLoadedFromRegularSearch() ? 20 : 10;
            yield this.getListingsByPage(++this.currentPage, pageSize, this.allListingIds, true);
            this.searchingMoreListings = false;
        });
    }
    getStates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.states = yield this.listingService.getStates();
        });
    }
    getCities() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cities = yield this.listingService.getCitiesByState(this.searchModel.stateId);
            if (this.searchModel.cityId) {
                const cityNameFull = this.cities
                    .filter((city) => {
                    return city.id === this.searchModel.cityId;
                })
                    .map((city) => city.name);
                this.inputCity = this.i18nService.getTranslation(cityNameFull);
                this.optionSelected = true;
            }
            const options = {
                keys: ['name']
            };
            this.fuse = new Fuse(this.cities, options);
        });
    }
    getCitiesAndResetUrl() {
        this.searchModel.cityId = null;
        this.inputCity = '';
        this.getCities();
        this.resetUrl();
    }
    selectCity(city) {
        this.searchModel.cityId = city.id;
        this.inputCity = this.i18nService.getTranslation(city.name);
        this.optionSelected = true;
        this.cityOptions = [];
        this.resetUrl();
    }
    getFuzzyOptions() {
        if (this.optionSelected) {
            this.optionSelected = false;
            return;
        }
        if (!this.inputCity.trim().length) {
            this.cityOptions = [];
            this.searchModel.cityId = null;
            this.resetUrl();
            return;
        }
        this.cityOptions = this.fuse.search(this.inputCity);
    }
    removeTag(fieldName, index) {
        this.searchTagService.removeTagFromModels(fieldName, index, this.searchModel, this.listingSearchDto, this.checkBoxValues);
        if (fieldName.toLocaleLowerCase() === 'spotsnearme') {
            this.isSpotNearMeSearch = false;
        }
        if (fieldName.toLocaleLowerCase() === 'onlyfavorites') {
            this.showAllFavoritesOnly = false;
            this.listingSearchDto.onlyFavorites = false;
        }
        this.searchListings(true);
        if (fieldName === 'sale' || fieldName === 'lease') {
            this.avoidSearchOnListingTypeChange = true;
        }
        else if (fieldName === 'propertyType' || fieldName === 'officeType') {
            this.avoidSearchOnBuildingTypeChange = true;
        }
    }
    isHeaderVisible(event) {
        this.displayScrollToTop = event.visible ? false : true;
    }
    deleteSearchTagsBasedOnListingType() {
        this.searchTagService.deleteSearchTagsBasedOnListingType(this.listingSearchDto, this.checkBoxValues);
        this.avoidSearchOnListingTypeChange = false;
    }
    showNoResultMessage() {
        return [
            this.noListingsFoundMessage.length,
            (!this.displayMap && !this.hasGeoLocationError) || (this.displayMap && !this.hasGeoLocationError)
        ].every((condition) => condition);
    }
    removeSearchAttr(searchDTO) {
        const attrs = ['longitude', 'latitude', 'radius'];
        return this.isSpotNearMeSearch ? searchDTO : this.commonService.removeObjectAttr(searchDTO, attrs);
    }
    createNearbyListingMarker(listings) {
        return listings.reduce((accumulator, listing) => {
            const hasValidLngLat = this.nearbyListingSvc.hasValidCoordinate(listing, 'lat', 'lng');
            if (hasValidLngLat) {
                let infoWindow = this.nearbyListingSvc.createNearByListingInfoWindow(listing);
                let marker = this.nearbyListingSvc.createMarker(listing, infoWindow);
                this.commonService.changeMarkerIcon(marker, 'mouseover', this.nearbyListingSvc.orangePin);
                this.commonService.changeMarkerIcon(marker, 'mouseout', this.nearbyListingSvc.bluePin);
                marker.addListener('click', () => {
                    const prevSelectMarker = this.nearbyListingSvc.prevSelectedMarker(accumulator, marker)[0];
                    marker.setIcon(this.nearbyListingSvc.orangePin);
                    marker.set('isSelected', true);
                    marker.infoWindow.open(marker.getMap(), marker);
                    this.scrollToListingCard();
                    this.getNearByPropertyListing(marker.listingIds, listing);
                    if (prevSelectMarker) {
                        this.nearbyListingSvc.updatePrevSelectedMarker(prevSelectMarker);
                    }
                });
                marker.infoWindow.addListener('closeclick', () => {
                    marker.setIcon(this.nearbyListingSvc.bluePin);
                    marker.set('isSelected', false);
                    this.allMatchingListing = [];
                    this.showUniquePropertyListing = false;
                });
                accumulator.push(marker);
            }
            return accumulator;
        }, []);
    }
    getUserCurrentGeoLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.searchByGeoLocation, this.showGeoLocationError);
        }
        else {
            const errorCode = { code: { POSITION_UNAVAILABLE: 'POSITION_UNAVAILABLE' } };
            this.showGeoLocationError(errorCode);
        }
    }
    initSpotNearMeSearch() {
        const state = this.activatedRoute.snapshot.params;
        this.isSpotNearMeSearch = this.nearbyListingSvc.isSpotsNearMeSearch(state, 'from', 'spotsNearMe');
        this.displayMap = this.isSpotNearMeSearch;
    }
    getNearByPropertyListing(listingIds, selectedBuilding) {
        let searchParam = Object.assign({}, this.listingSearchDto);
        this.selectedBuilding = selectedBuilding;
        if (this.isSpotNearMeSearch) {
            const unsetAttrs = ['cityId', 'stateId', 'offeredByUserId', 'companyId', 'status'];
            searchParam.latitude = this.clientPosition.latitude;
            searchParam.longitude = this.clientPosition.longitude;
            searchParam.radius = this.clientPosition.radius;
            searchParam = this.nearbyListingSvc.unsetListingSearchDTO(this.listingSearchDto, unsetAttrs);
        }
        searchParam.listingIds = listingIds;
        this.listingService.getAllNearByPropertyListing(searchParam).then((result) => {
            this.allMatchingListing = result.body.listings;
            this.showUniquePropertyListing = true;
        });
    }
    getAllListing() {
        const pageNumber = 1;
        this.listingService.searchNearByListing(this.listingSearchDto, pageNumber).then((result) => {
            this.totalListingsCount = parseInt(result.headers.get('x-total-count'));
            this.noListingsFoundMessage = this.nearbyListingSvc.getNoResultMessage(this.totalListingsCount);
            this.listingMarkers = this.createNearbyListingMarker(result.body);
        });
    }
    nearByListingSearch(clientPosition) {
        const unsetAttrs = ['cityId', 'stateId', 'offeredByUserId', 'companyId', 'status'];
        this.listings = [];
        this.searching = true;
        this.listingSearchDto.latitude = clientPosition.latitude;
        this.listingSearchDto.longitude = clientPosition.longitude;
        this.listingSearchDto.radius = clientPosition.radius;
        this.listingSearchDto = this.nearbyListingSvc.unsetListingSearchDTO(this.listingSearchDto, unsetAttrs);
        this.getAllListing();
        this.regularListingSearch();
    }
    isComponentLoadedFromRegularSearch() {
        return !this.myListingPage && !this.myListingAnalytics;
    }
    isSmallScreenWidth() {
        return [
            this.outerScreenWidth < 1545 && this.allMatchingListing.length === 1,
            this.outerScreenWidth < 1545 && this.allMatchingListing.length > 1,
            this.outerScreenWidth >= 1545 && this.allMatchingListing.length === 1
        ].some((condition) => condition);
    }
    mapViewClass() {
        const isSmallScreen = this.isSmallScreenWidth();
        const mapWithCardView = isSmallScreen ? 'listing-map-view-sm' : 'listing-map-view-lg';
        return this.allMatchingListing.length ? mapWithCardView : 'map-view-fullscreen';
    }
    listCardViewClass() {
        const isSmallScreen = this.isSmallScreenWidth();
        return isSmallScreen ? 'listing-card-view-sm' : 'listing-card-view-lg';
    }
    ngOnChanges(changes) {
        for (let propName in changes) {
            let chng = changes[propName];
            let cur = JSON.stringify(chng.currentValue);
            let prev = JSON.stringify(chng.previousValue);
        }
    }
    setInitialValuesForAdminUsers() {
        if (this.listingSearchDto) {
            if (this.spotAdmin && !this.listingSearchDto.spotAdmin) {
                this.listingSearchDto.companyId = null;
                this.listingSearchDto.offeredByUserId = null;
                this.listingSearchDto.spotAdmin = true;
                this.selectCompany = null;
                this.selectUser = null;
            }
            else if (this.listingAdmin && !this.listingSearchDto.listingAdmin) {
                this.listingSearchDto.listingAdmin = true;
                this.listingSearchDto.offeredByUserId = null;
                this.selectUser = null;
            }
        }
    }
    getAdvertisements() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.advertisements = yield this.listingCarouselService.getAdvertisements(8).toPromise();
        });
    }
    updateMyListingFilter(filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (filter) {
                this.statusHelperService.selectStatus = filter.selectStatus;
                this.classHelperService.selectClass = filter.selectClass;
                this.users = filter.agents;
                if (filter.selectCompany) {
                    this.selectCompany = filter.selectCompany;
                    this.listingSearchDto.companyId = filter.selectCompany.id;
                    this.listingSearchDto.offeredByUserId = null;
                }
                if (filter.selectAgent) {
                    this.listingSearchDto.offeredByUserId = filter.selectAgent.id;
                    this.selectUser = filter.selectAgent;
                }
                yield this.statusChange();
                yield this.classChange();
                this.searchListings(true);
            }
        });
    }
    setButtonColor(width) {
        return this.screenWidth >= width ? 'light' : 'secondary';
    }
    setMetaTags() {
        if (this.isComponentLoadedFromRegularSearch()) {
            if (this.currentTags) {
                if (this.currentTags instanceof Promise) {
                    this.currentTags.then((currentTags) => {
                        const title = this.searchTagService.getMetatagTitleBasedOnCurrentTags();
                        const description = this.i18nService.getMetatagTranslation('metatags.searchPage.description');
                        const tags = this.metaService.getMetaTagsFrom(title, description);
                        this.metaService.setDynamicTags(tags, title);
                    });
                }
                else {
                    const title = this.searchTagService.getMetatagTitleBasedOnCurrentTags();
                    const description = this.i18nService.getMetatagTranslation('metatags.searchPage.description');
                    const tags = this.metaService.getMetaTagsFrom(title, description);
                    this.metaService.setDynamicTags(tags, title);
                }
            }
        }
    }
    openRenewExtendListingsModal(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.popOverCtrlHelper.openRenewExtendListingsModal(ev, this.allListingIds, (popoverData) => {
                if (popoverData && popoverData.updated) {
                    this.searchMyListings(this.listingSearchDto);
                }
            });
        });
    }
    showTotalActiveListingCounts() {
        return (this.listingSearchDto &&
            this.listingSearchDto.listingStatuses &&
            this.myListingPage &&
            this.listingSearchDto.listingStatuses.length == 1 &&
            this.listingSearchDto.listingStatuses.includes(4));
    }
    showListingTypeBtn() {
        return !(this.listingSearchDto &&
            this.listingSearchDto.buildingTypes &&
            this.listingSearchDto.buildingTypes[0] === ListingOfferType.Coworking);
    }
}
