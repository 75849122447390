<ion-content>
  <ion-row class="title-main">
    <h3 class="no-margin" translate="global.terms.title"></h3>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.termsOfUse"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.definition"></h4>
  </ion-row>
  <ion-row class="text">
    <p>
      <b>{{ 'global.terms.subtitles.definitions.advertiser' | translate }}</b>
      <span>{{ 'global.terms.definitions.advertiser' | translate }}</span>
    </p>
  </ion-row>
  <ion-row class="text">
    <p>
      <b>{{ 'global.terms.subtitles.definitions.ad' | translate }}</b>
      <span>{{ 'global.terms.definitions.ad' | translate }}</span>
    </p>
  </ion-row>
  <ion-row class="text">
    <p>
      <b>{{ 'global.terms.subtitles.definitions.property' | translate }}</b>
      <span>{{ 'global.terms.definitions.property' | translate }}</span>
    </p>
  </ion-row>
  <ion-row class="text">
    <p>
      <b>{{ 'global.terms.subtitles.definitions.plan' | translate }}</b>
      <span>{{ 'global.terms.definitions.plan' | translate }}</span>
    </p>
  </ion-row>
  <ion-row class="text">
    <p>
      <b>{{ 'global.terms.subtitles.definitions.siila' | translate }}</b>
      <span>{{ 'global.terms.definitions.siila' | translate }}</span>
    </p>
  </ion-row>
  <ion-row class="text">
    <p>
      <b>{{ 'global.terms.subtitles.definitions.spot' | translate }}</b>
      <span>{{ 'global.terms.definitions.spot' | translate }}</span>
    </p>
  </ion-row>
  <ion-row class="text">
    <p>
      <b>{{ 'global.terms.subtitles.definitions.user' | translate }}</b>
      <span>{{ 'global.terms.definitions.user' | translate }}</span>
    </p>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.definitions1"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.definitions2"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.spot"></h4>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.spot1"></p>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.spot2"></p>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.spot3"></p>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.spot4"></p>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.spot5"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.registration"></h4>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.registration1"></p>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.registration2"></p>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.registration3"></p>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.registration4"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.ad"></h4>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad1"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad2"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad3"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad4"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad5"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad6"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad7"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad8"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad9"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad10"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad11"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.ad12"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.plan"></h4>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.plan"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.liability"></h4>
  </ion-row>

  <ion-row class="text">
    <p translate="global.terms.paragraphs.liability1"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.liability2"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.liability3"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.liability4"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.liability5"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.liability6"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.access"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.access"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.relationship"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.relationship"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.communications"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.communications"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.validity"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.validity"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.tolerance"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.tolerance"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.jurisdiction"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.jurisdiction"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.versions"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.paragraphs.versions"></p>
  </ion-row>

  <ion-row class="title">
    <p translate="global.terms.footer.date"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.terms.footer.reserved"></p>
  </ion-row>
</ion-content>
