<ion-content id="detailStep">
  <app-breadcrumb
    inViewport
    *ngIf="currentStep === 0"
    [inViewportOptions]="{ thredhold: [0] }"
    [options]="breadcrumbOptions"
  >
  </app-breadcrumb>

  <ion-toolbar class="stepperTop" *ngIf="currentStep !== 0" style="border-bottom: 1px solid lightgrey;">
    <ion-buttons slot="start">
      <ion-button class="back-button" ion-button icon-only (click)="goBack()">
        <ion-icon color="primary" name="md-arrow-back" size="medium"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-row>
      <ion-col>
        <p *ngIf="currentStep === 6 && previewLoaded && previewListing" class="revise-header-tag" (click)="goBack()">
          {{ 'global.my-list-menu.reviseListing' | translate }}
        </p>
      </ion-col>

      <ion-col *ngIf="previewLoaded && previewListing && currentStep === 6">
        <button
          class="next-btn float-right"
          *ngIf="!spotAdmin && previewListing.listingStatus.id !== 8"
          style="background: #ff9900; color: white; width:34%; height:28px;float: right;text-transform: uppercase;margin-right: 16px;"
          (click)="submitListing()"
        >
          {{ 'global.submitApproval' | translate }}
        </button>
      </ion-col>
    </ion-row>
    <ng-content></ng-content>
  </ion-toolbar>

  <div style="max-width: 1200px; background: white; margin: 0 auto; height: 100%;">
    <div
      *ngIf="previewLoaded && previewListing && currentStep === 6"
      style="width: 100%; max-width:1200px; height: 100px;
    width: 100%;
    margin-top: 15px;
    position: fixed;
    bottom: 0;
    z-index: 999;"
    >
      <button
        class="next-btn float-right"
        style="background: #ff9900; color: white; width:100%; height:50px; position: absolute; bottom:0;"
        (click)="spotAdmin && previewListing.listingStatus.id === 8 ? approveListing() : submitListing()"
      >
        {{
          (spotAdmin && previewListing.listingStatus.id === 8 ? 'global.saveApproval' : 'global.submitApproval')
            | translate
        }}
      </button>
    </div>
    <ion-row class="logo-wrapper" *ngIf="currentStep !== 6">
      <div style="position: relative;">
        <button
          (click)="goForward()"
          *ngIf="currentStep === 0"
          class="sticky-next-btn next-btn float-right bottom-btn raised-btn"
          [disabled]="!listingTypeForm?.valid"
        >
          {{ 'global.list-your-property.next' | translate }}
        </button>

        <button
          *ngIf="currentStep === 1"
          id="stepOneNextBtn"
          (click)="goForward()"
          class="sticky-next-btn raised-btn next-btn float-right bottom-btn"
          [disabled]="
            !detailsForm?.valid ||
            (!detailsForm.controls.listed.value && !detailsForm.controls.propertySet.value) ||
            (detailsForm.controls.listed.value && !addressForm.valid) ||
            !detailsForm.controls.propertyName.value
          "
        >
          {{ 'global.list-your-property.next' | translate }}
        </button>

        <button
          *ngIf="currentStep === 2"
          (click)="goForward()"
          class=" sticky-next-btn raised-btn next-btn float-right bottom-btn"
          [disabled]="!moreDetailsForm?.valid"
        >
          {{ 'global.list-your-property.next' | translate }}
        </button>

        <button
          *ngIf="currentStep === 3"
          id="step3Next"
          (click)="goForward()"
          class=" sticky-next-btn raised-btn next-btn float-right bottom-btn"
          [disabled]="!listingFeaturesForm?.valid"
        >
          {{ 'global.list-your-property.next' | translate }}
        </button>

        <span *ngIf="listingTypeForm?.value?.listingType === 'forCoworking'">
          <button
            *ngIf="currentStep === 4"
            (click)="goForward()"
            id="checkCoworkNext"
            class="sticky-next-btn raised-btn next-btn float-right bottom-btn"
            [disabled]="!maximumPositions[0].maximumPositions || checkCowork()"
          >
            {{ 'global.list-your-property.next' | translate }}
          </button>
        </span>

        <span *ngIf="listingTypeForm?.value?.listingType !== 'forCoworking'">
          <button
            id="step4"
            *ngIf="currentStep === 4"
            (click)="goForward()"
            class="sticky-next-btn raised-btn next-btn float-right bottom-btn"
            [disabled]="!listingPriceForm?.valid"
          >
            {{ 'global.list-your-property.next' | translate }}
          </button>
        </span>
      </div>
      <h4 class="heading" style="margin-bottom: 30px;">
        {{ 'global.my-list-menu.registerYour' | translate }}
        <img class="spot-img" src="assets/SPOT-Full-Logo.png" />
      </h4>
      <p class="reg-font">
        {{ 'global.my-list-menu.listingRegistrationPage' | translate }} {{ currentStep + 1 }}
        {{ 'global.of' | translate }} 6
      </p>
      <span style="padding-left: 17px; padding-right: 17px;;" *ngIf="currentStep === 1">
        <p class="reg-font">
          {{ 'global.my-list-menu.selectPropertyFromList' | translate }}
        </p>
      </span>
      <p *ngIf="currentStep === 0" class="reg-font">
        {{ 'global.my-list-menu.listingClass' | translate }}: {{ listingClass }}
      </p>
      <p *ngIf="currentStep === 0" class="reg-font">
        {{ 'global.my-list-menu.listingExp' | translate }}: {{ listingExpDate | date }}
      </p>
      <p *ngIf="currentStep === 3 || currentStep === 4" class="reg-font">
        {{ 'global.my-list-menu.enterMoreInfoListig' | translate }}
      </p>
      <span style="padding-left: 17px; padding-right: 17px;;">
        <p *ngIf="currentStep === 5" class="reg-font">
          {{ 'global.my-list-menu.selectOneMorePhotos' | translate }}
        </p>
      </span>
    </ion-row>

    <ion-row>
      <mat-horizontal-stepper
        style="width: 100%"
        (selectionChange)="onStepperSelectionChange($event)"
        [ngClass]="{ 'unset-stepper-padding': currentStep === 6 }"
        [linear]="true"
        [selectedIndex]="currentStep"
        #stepper
      >
        <mat-step *ngIf="listingTypeForm" [completed]="listingTypeForm?.valid">
          <app-list-type
            [listing]="listing"
            [stepper]="stepper"
            [detailsForm]="detailsForm"
            [subTypes]="subTypes"
            [listingPriceForm]="listingPriceForm"
            [addressForm]="addressForm"
            [form]="listingTypeForm"
            [buildingNames]="buildingNames"
            [buildings]="buildings"
          >
          </app-list-type>
          <button
            (click)="goForward()"
            class="next-btn float-right bottom-btn"
            mat-raised-button
            id="mainNextBtn"
            matStepperNext
            [disabled]="!listingTypeForm?.valid"
          >
            {{ 'global.list-your-property.next' | translate }}
          </button>
        </mat-step>

        <mat-step *ngIf="detailsForm && officeFilters && industrialFilters" [completed]="detailsForm?.valid">
          <app-create-details
            [listingFeaturesForm]="listingFeaturesForm"
            [officeFilters]="officeFilters"
            [industrialFilters]="industrialFilters"
            [addressForm]="addressForm"
            [listing]="listing"
            [actualBuilding]="detailsForm.value.actualBuilding"
            [typeForm]="listingTypeForm"
            [imgList]="imgList"
            [form]="detailsForm"
          >
          </app-create-details>
          <button
            mat-raised-button
            matStepperNext
            (click)="goForward()"
            class="next-btn float-right bottom-btn"
            [disabled]="
              !detailsForm?.valid ||
              (!detailsForm.controls.listed.value && !detailsForm.controls.propertySet.value) ||
              (detailsForm.controls.listed.value && !addressForm.valid) ||
              !detailsForm.controls.propertyName.value
            "
          >
            {{ 'global.list-your-property.next' | translate }}
          </button>
        </mat-step>

        <mat-step *ngIf="moreDetailsForm" [completed]="moreDetailsForm?.valid">
          <app-more-details
            [offeredByUserId]="offeredByUserId"
            [listing]="listing"
            [selectCompany]="company"
            [listType]="listType"
            [tagValues]="tagValues"
            [otherForm]="listingTypeForm"
            [form]="moreDetailsForm"
          >
          </app-more-details>
          <button
            mat-raised-button
            matStepperNext
            (click)="goForward()"
            class="next-btn float-right bottom-btn"
            [disabled]="!moreDetailsForm?.valid"
          >
            {{ 'global.list-your-property.next' | translate }}
          </button>
        </mat-step>

        <mat-step *ngIf="listingFeaturesForm" [completed]="listingFeaturesForm?.valid">
          <app-listing-features
            [listing]="listing"
            [officeFilters]="officeFilters"
            [industrialFilters]="industrialFilters"
            [listingTypeForm]="listingTypeForm"
            [form]="listingFeaturesForm"
          >
          </app-listing-features>
          <button
            mat-raised-button
            matStepperNext
            id="listingFeatureNextBtn"
            (click)="goForward()"
            class="next-btn float-right bottom-btn"
            [disabled]="!listingFeaturesForm?.valid"
          >
            {{ 'global.list-your-property.next' | translate }}
          </button>
        </mat-step>

        <mat-step
          *ngIf="subTypes && listingTypeForm?.value?.subType"
          [completed]="
            listingPriceForm?.valid || (maximumPositions && maximumPositions[0].maximumPositions && !checkCowork())
          "
        >
          <span *ngIf="listingTypeForm?.value?.listingType === 'forCoworking'">
            <app-coworking [maximumPositions]="maximumPositions" [listing]="listing" [coworkingInfo]="coworkingInfo">
            </app-coworking>
            <button
              mat-raised-button
              matStepperNext
              id="forCoworkingNextBtn"
              (click)="goForward()"
              class="next-btn float-right bottom-btn"
              [disabled]="!maximumPositions[0].maximumPositions || checkCowork()"
            >
              {{ 'global.list-your-property.next' | translate }}
            </button>
          </span>
          <span *ngIf="listingTypeForm?.value?.listingType !== 'forCoworking'">
            <app-listing-price
              [listing]="listing"
              [listingClass]="listingClass"
              [otherForm]="listingTypeForm"
              [form]="listingPriceForm"
            >
            </app-listing-price>
            <button
              mat-raised-button
              matStepperNext
              id="nextBtnListingPrice"
              (click)="goForward()"
              class="next-btn float-right bottom-btn"
              [disabled]="!listingPriceForm?.valid"
            >
              {{ 'global.list-your-property.next' | translate }}
            </button>
          </span>
        </mat-step>

        <mat-step *ngIf="listingTypeForm" [completed]="imgList">
          <span *ngIf="!listing && !previewListing">
            <app-img-upload
              [uploadingImages]="uploadingImages"
              [saveImgImmediately]="true"
              (vrTourSelected)="onVrTourChanged($event)"
              [imgList]="imgList"
            ></app-img-upload>
          </span>

          <span *ngIf="listing || previewListing">
            <app-img-upload
              [vrTourUrl]="vrTourUrl"
              (vrTourSelected)="onVrTourChanged($event)"
              [previewListing]="previewListing"
              [listing]="listing"
              [imgList]="imgList"
              [uploadingImages]="uploadingImages"
              [saveImgImmediately]="true"
            ></app-img-upload>
          </span>

          <div class="preview-row">
            <button
              *ngIf="isUserAllowToEditPendingApprovalListing()"
              (click)="cancelAndNavigateToDetails()"
              class="cancel-btn float-right bottom-btn"
              type="reset"
              style="margin-right: 20px;"
            >
              {{ 'global.unsavedCancel' | translate }}
            </button>
            <button
              (click)="goForward()"
              class="next-btn float-right bottom-btn"
              mat-raised-button
              matStepperNext
              [disabled]="uploadingImages || !imgList.length || videoURLInvalid"
            >
              {{
                ((spotAdmin && listing && listing.listingStatus.id === 4) || isUserAllowToEditPendingApprovalListing()
                  ? 'global.list-your-property.save'
                  : 'global.list-your-property.preview') | translate
              }}
            </button>
          </div>
        </mat-step>

        <mat-step [completed]="">
          <div *ngIf="previewLoaded && previewListing" class="scroll-button" style="width: 100%;">
            <app-listing-detail [imgList]="imgList" [previewListing]="true" [createdListing]="previewListing">
            </app-listing-detail>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </ion-row>
  </div>
</ion-content>
