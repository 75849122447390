/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./my-listing-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./my-listing-filter.component";
import * as i7 from "../../../core/common.service";
var styles_MyListingFilterComponent = [i0.styles];
var RenderType_MyListingFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyListingFilterComponent, data: {} });
export { RenderType_MyListingFilterComponent as RenderType_MyListingFilterComponent };
export function View_MyListingFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "my-listing-filter"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 9, "ion-button", [["class", "review-filter-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMyListingFilterModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(3, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "span", [["translate", "global.myListingFilter.listingReviewFilters"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(10, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.setButtonColor(1200); _ck(_v, 3, 0, currVal_0); var currVal_1 = "review-filter-btn"; var currVal_2 = _co.overrideColor(1200); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.overrideColor(1200); _ck(_v, 9, 0, currVal_3); var currVal_4 = "global.myListingFilter.listingReviewFilters"; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_MyListingFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-listing-filter", [], null, null, null, View_MyListingFilterComponent_0, RenderType_MyListingFilterComponent)), i1.ɵdid(1, 49152, null, 0, i6.MyListingFilterComponent, [i7.CommonGenericService, i3.PopoverController], null, null)], null, null); }
var MyListingFilterComponentNgFactory = i1.ɵccf("my-listing-filter", i6.MyListingFilterComponent, View_MyListingFilterComponent_Host_0, { screenWidth: "screenWidth", agents: "agents", listingCompanies: "listingCompanies", isSpotAdmin: "isSpotAdmin", isListingAdmin: "isListingAdmin", selectCompany: "selectCompany", selectAgent: "selectAgent", listingSearchDto: "listingSearchDto" }, { updateFilter: "updateFilter" }, []);
export { MyListingFilterComponentNgFactory as MyListingFilterComponentNgFactory };
