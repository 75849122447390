<ion-content>
  <app-breadcrumb inViewport [inViewportOptions]="{ thredhold: [0] }" [options]="breadcrumbOptions"></app-breadcrumb>

  <div class="detail-block-background">
    <div style="max-width: 800px; background: white; margin: 0 auto; height: 100%;">
      <ion-row>
        <ion-row class="logo-wrapper" style="width: 100%; margin-top: 15px;">
          <h4 class="create-heading">
            {{ 'global.my-payment.paymentReview' | translate }}
          </h4>
        </ion-row>

        <ion-row style="width: 100%;">
          <ion-col size="12">
            <p style="text-align: center;padding: 5px;">
              {{ 'global.my-payment.include' | translate }}
            </p>
            <p style="text-align: center; padding: 5px;">
              {{ 'global.my-payment.contact' | translate }}
            </p>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="spotAdmin || listingAdmin" class="full-width margin-top-5" style="margin-top: 10px;">
          <ion-col size="12">
            <ion-label translate="global.my-list-menu.company"> <span class="blue">*</span></ion-label>
            <span class="width-full my-listing-company">
              <ionic-selectable
                style="border: 1px solid lightgrey;"
                [items]="allCompanies"
                itemValueField="id"
                itemTextField="name"
                [disabled]="!spotAdmin"
                [(ngModel)]="selectCompany"
                [hasVirtualScroll]="true"
                placeholder="Company"
                [canSearch]="true"
                (onChange)="portChange($event)"
                searchPlaceholder="{{ 'global.menu.search' | translate }}"
                closeButtonText="{{ 'Cancel' | translate }}"
                #portComponent
              >
              </ionic-selectable>
            </span>
          </ion-col>
        </ion-row>

        <ion-row
          *ngIf="spotAdmin || listingAdmin"
          class="full-width margin-top-5"
          style="margin-top: 10px;
    margin-bottom: 10px;"
        >
          <ion-col size="12">
            <ion-label>{{ 'global.my-list-menu.listingAgent' | translate }} <span class="blue">*</span></ion-label>
            <span class="width-full my-listing-company">
              <ionic-selectable
                style="border: 1px solid lightgrey;"
                [(ngModel)]="offeredByUserId"
                [items]="users"
                itemValueField="id"
                [disabled]="!selectCompany || (!spotAdmin && !listingAdmin)"
                itemTextField="firstNameAndLastName"
                [hasVirtualScroll]="true"
                placeholder="Agent"
                [canSearch]="true"
                (onChange)="nameChange($event)"
                searchPlaceholder="{{ 'global.menu.search' | translate }}"
                closeButtonText="{{ 'Cancel' | translate }}"
                clearButtonText="{{ 'Clear' | translate }}"
                [canClear]="true"
                #agentComponent
              >
              </ionic-selectable>
            </span>
          </ion-col>
        </ion-row>
      </ion-row>

      <ion-row *ngFor="let purchase of purchases; let index = i" style="width: 100%;">
        <button class="purchase-card" *ngIf="purchase.purchaseAmount > 0">
          <ion-row style="margin-bottom: 5px;">
            <span translate="global.my-list-menu.listingsCreditsPurchased"></span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.my-list-menu.company' | translate }}: {{ purchase?.companyName }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.my-list-menu.purchaseDate' | translate }}: {{ purchase?.purchaseDate | date }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.my-list-menu.purchaseBy' | translate }}: {{ purchase?.purchasedBy }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span
              >{{ 'global.my-list-menu.purchaseAmount' | translate }}: R$
              {{ purchase?.purchaseAmount | localNumber: '1.2-2' }}</span
            >
          </ion-row>
        </button>
        <ion-row style="width: 100%; margin: 5px;" *ngIf="purchase.purchaseAmount > 0 && purchase.details">
          <ion-col size="1"> </ion-col>
          <ion-col size="11">
            <span style="margin: 5px; color: #043650;"
              ><b>{{ 'global.my-list-menu.purchaseDetails' | translate }}:</b></span
            >
            <button class="detail-card" *ngFor="let detail of purchase.details; let index = k">
              <ion-row style="margin-bottom: 5px;">
                <span
                  >{{ 'global.my-list-menu.listingClass' | translate }}:
                  {{ detail?.premium ? 'PREMIUM' : 'PROMOTED' }}</span
                >
              </ion-row>

              <ion-row style="margin-bottom: 5px;">
                <span>{{ 'global.my-list-menu.creditsPurchased' | translate }}: {{ detail?.creditsPurchased }}</span>
              </ion-row>

              <ion-row style="margin-bottom: 5px;">
                <span class="credit-available"
                  >{{ 'global.my-list-menu.creditsAvailable' | translate }}: {{ detail?.creditsAvailable }}</span
                >
              </ion-row>

              <ion-row style="margin-bottom: 5px;">
                <span
                  >{{ 'global.my-list-menu.listingExp' | translate }}: {{ detail?.listingExpirationDate | date }}</span
                >
              </ion-row>
            </button>
          </ion-col>
        </ion-row>
        <ion-row
          style="width: 100%; margin: 5px;"
          *ngIf="purchase.purchaseAmount > 0 && purchase.paymentDetails.length > 0"
        >
          <ion-col size="1"> </ion-col>
          <ion-col size="11">
            <span style="margin: 5px; color: #043650;"
              ><b>{{ 'global.my-list-menu.paymentDetails' | translate }}:</b></span
            >
            <button class="postback-card" *ngFor="let postback of purchase.paymentDetails">
              <ion-row style="margin-bottom: 5px;">
                <span
                  >{{ 'global.my-list-menu.paymentUpdateReceived' | translate }}:
                  {{ postback?.updateDate | date }}</span
                >
              </ion-row>
              <ion-row style="margin-bottom: 5px;">
                <span>{{ 'global.my-list-menu.paymentStatus' | translate }}:&nbsp;</span>
                <span localize [textValue]="postback?.paymentStatus"></span>
              </ion-row>
            </button>
          </ion-col>
        </ion-row>
        <ng-container *ngIf="purchase.purchaseAmount == 0">
          <button class="free-card" *ngFor="let detail of purchase.details; let index = j">
            <ion-row style="margin-bottom: 5px;">
              <span>{{ 'global.my-list-menu.listingIssued' | translate }}</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;" *ngIf="purchase.companyName">
              <span>{{ 'global.my-list-menu.company' | translate }}: {{ purchase?.companyName }}</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;" *ngIf="!purchase.companyName">
              <span>{{ 'global.my-list-menu.company' | translate }}: {{ selectCompany.name }}</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;" *ngIf="purchase.companyName">
              <span>{{ 'global.my-list-menu.userAccount' | translate }}: {{ purchase?.purchasedBy }}</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;">
              <span>{{ 'global.my-list-menu.issueDate' | translate }}: {{ purchase?.purchaseDate | date }}</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;">
              <span
                >{{ 'global.my-list-menu.listingClass' | translate }}:
                {{ detail?.premium ? 'PREMIUM' : 'PROMOTED' }}</span
              >
            </ion-row>

            <ion-row style="margin-bottom: 5px;">
              <span>{{ 'global.my-list-menu.creditsPurchased' | translate }}: {{ detail?.creditsPurchased }}</span>
            </ion-row>

            <ion-row style="margin-bottom: 5px;">
              <span class="credit-available"
                >{{ 'global.my-list-menu.creditsAvailable' | translate }}: {{ detail?.creditsAvailable }}</span
              >
            </ion-row>

            <ion-row style="margin-bottom: 5px;">
              <span
                >{{ 'global.my-list-menu.listingExp' | translate }}: {{ detail?.listingExpirationDate | date }}</span
              >
            </ion-row>
          </button>
        </ng-container>
      </ion-row>
      <!--ion-row *ngFor="let purchase of purchases; let index = i" style="width: 100%;">
    <button class="purchase-card" *ngIf="purchase.purchaseAmount > 0">
      <ion-row style="margin-bottom: 5px;">
        <span>Listing Credits Purchased</span>
      </ion-row>
      <ion-row style="margin-bottom: 5px;">
        <span>Company: {{ purchase?.companyName }}</span>
      </ion-row>
      <ion-row style="margin-bottom: 5px;">
        <span>Purchase Date: {{ purchase?.purchaseDate | date }}</span>
      </ion-row>
      <ion-row style="margin-bottom: 5px;">
        <span>Purchased By: {{ purchase?.purchasedBy }}</span>
      </ion-row>
      <ion-row style="margin-bottom: 5px;">
        <span>Purchase Amount: R$ {{ purchase?.purchaseAmount | localNumber: '1.2-2' }}</span>
      </ion-row>
    </button>
    <ion-row style="width: 100%; margin: 5px;" *ngIf="purchase.purchaseAmount > 0 && purchase.details">
      <ion-col size="1"> </ion-col>
      <ion-col size="11">
        <span style="margin: 5px; color: #043650;"><b>{{ 'global.my-list-menu.purchaseDetails' | translate }}:</b></span>
        <button class="detail-card" *ngFor="let detail of purchase.details; let index = k">
          <ion-row style="margin-bottom: 5px;">
            <span>Listing Class: {{ detail?.premium ? 'PREMIUM' : 'PROMOTED' }}</span>
          </ion-row>

          <ion-row style="margin-bottom: 5px;">
            <span>Credits Purchased: {{ detail?.creditsPurchased }}</span>
          </ion-row>

          <ion-row style="margin-bottom: 5px;">
            <span class="credit-available">Credits Available: {{ detail?.creditsAvailable }}</span>
          </ion-row>

          <ion-row style="margin-bottom: 5px;">
            <span>Listing Expiration: {{ detail?.listingExpirationDate | date }}</span>
          </ion-row>
        </button>
      </ion-col>
    </ion-row>
    <ion-row
      style="width: 100%; margin: 5px;"
      *ngIf="purchase.purchaseAmount > 0 && purchase.paymentDetails.length > 0"
    >
      <ion-col size="1"> </ion-col>
      <ion-col size="11">
        <span style="margin: 5px; color: #043650;"><b>{{ 'global.my-list-menu.paymentDetails' | translate }}:</b></span>
        <button class="postback-card" *ngFor="let postback of purchase.paymentDetails">
          <ion-row style="margin-bottom: 5px;">
            <span>Payment Update Received: {{ postback?.updateDate | date }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>Payment Status: {{ postback?.paymentStatus }}</span>
          </ion-row>
        </button>
      </ion-col>
    </ion-row>
    <ng-container *ngIf="purchase.purchaseAmount == 0">
      <button class="free-card" *ngFor="let detail of purchase.details; let index = j">
        <ion-row style="margin-bottom: 5px;">
          <span>Listing Credits Issued</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;" *ngIf="purchase.companyName">
          <span>Company: {{ purchase?.companyName }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;" *ngIf="!purchase.companyName">
          <span>Company: {{ selectCompany.name }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;" *ngIf="purchase.companyName">
          <span>User Account: {{ purchase?.purchasedBy }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;">
          <span>Issue Date: {{ purchase?.purchaseDate | date }}</span>
        </ion-row>
        <ion-row style="margin-bottom: 5px;">
          <span>Listing Class: {{ detail?.premium ? 'PREMIUM' : 'PROMOTED' }}</span>
        </ion-row>

        <ion-row style="margin-bottom: 5px;">
          <span>Credits Purchased: {{ detail?.creditsPurchased }}</span>
        </ion-row>

        <ion-row style="margin-bottom: 5px;">
          <span class="credit-available">Credits Available: {{ detail?.creditsAvailable }}</span>
        </ion-row>

        <ion-row style="margin-bottom: 5px;">
          <span>Listing Expiration: {{ detail?.listingExpirationDate | date }}</span>
        </ion-row>
      </button>
    </ng-container>
  </ion-row-->

      <ion-row *ngFor="let activity of listingActivities; let index = i" style="width: 100%;">
        <button class="purchase-card" *ngIf="activity.activityType === 'PURCHASE'">
          <ion-row style="margin-bottom: 5px;">
            <span translate="global.my-list-menu.listingsCreditsPurchased"></span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.my-list-menu.company' | translate }}: {{ activity.purchaseInfo?.companyName }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span
              >{{ 'global.my-list-menu.purchaseDate' | translate }}:
              {{ activity.purchaseInfo?.purchaseDate | date }}</span
            >
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.my-list-menu.purchaseBy' | translate }}: {{ activity.purchaseInfo?.purchasedBy }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span
              >{{ 'global.my-list-menu.purchaseAmount' | translate }}: R$
              {{ activity.purchaseInfo?.purchaseAmount | localNumber: '1.2-2' }}</span
            >
          </ion-row>
        </button>
        <ion-row
          style="width: 100%; margin: 5px;"
          *ngIf="activity.activityType === 'PURCHASE' && activity.purchaseInfo.details"
        >
          <ion-col size="1"> </ion-col>
          <ion-col size="11">
            <span style="margin: 5px; color: #043650;"
              ><b>{{ 'global.my-list-menu.purchaseDetails' | translate }}:</b></span
            >
            <button class="detail-card" *ngFor="let detail of activity.purchaseInfo.details; let index = k">
              <ion-row style="margin-bottom: 5px;">
                <span
                  >{{ 'global.my-list-menu.listingClass' | translate }}:
                  {{ detail?.premium ? 'PREMIUM' : 'PROMOTED' }}</span
                >
              </ion-row>

              <ion-row style="margin-bottom: 5px;">
                <span>{{ 'global.my-list-menu.creditsPurchased' | translate }}: {{ detail?.creditsPurchased }}</span>
              </ion-row>

              <ion-row style="margin-bottom: 5px;">
                <span class="credit-available"
                  >{{ 'global.my-list-menu.creditsAvailable' | translate }}: {{ detail?.creditsAvailable }}</span
                >
              </ion-row>

              <ion-row style="margin-bottom: 5px;">
                <span
                  >{{ 'global.my-list-menu.listingExp' | translate }}: {{ detail?.listingExpirationDate | date }}</span
                >
              </ion-row>
            </button>
          </ion-col>
        </ion-row>
        <ion-row
          style="width: 100%; margin: 5px;"
          *ngIf="activity.activityType === 'PURCHASE' && activity.purchaseInfo.paymentDetails.length > 0"
        >
          <ion-col size="1"> </ion-col>
          <ion-col size="11">
            <span style="margin: 5px; color: #043650;"
              ><b>{{ 'global.my-list-menu.paymentDetails' | translate }}:</b></span
            >
            <button class="postback-card" *ngFor="let postback of activity.purchaseInfo.paymentDetails">
              <ion-row style="margin-bottom: 5px;">
                <span
                  >{{ 'global.my-list-menu.paymentUpdateReceived' | translate }}:
                  {{ postback?.updateDate | date }}</span
                >
              </ion-row>
              <ion-row style="margin-bottom: 5px;">
                <span>{{ 'global.my-list-menu.paymentStatus' | translate }}:&nbsp;</span>
                <span localize [textValue]="postback?.paymentStatus"></span>
              </ion-row>
            </button>
          </ion-col>
        </ion-row>
        <ng-container *ngIf="activity.activityType === 'CREDIT'">
          <button class="free-card" *ngFor="let detail of activity.purchaseInfo.details; let index = j">
            <ion-row style="margin-bottom: 5px;">
              <span>{{ 'global.my-list-menu.listingIssued' | translate }}</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;" *ngIf="activity.purchaseInfo.companyName">
              <span>{{ 'global.my-list-menu.company' | translate }}: {{ activity.purchaseInfo?.companyName }}</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;" *ngIf="!activity.purchaseInfo.companyName">
              <span>{{ 'global.my-list-menu.company' | translate }}: {{ selectCompany.name }}</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;" *ngIf="activity.purchaseInfo.companyName">
              <span>{{ 'global.my-list-menu.userAccount' | translate }}: {{ activity.purchaseInfo?.purchasedBy }}</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;">
              <span
                >{{ 'global.my-list-menu.issueDate' | translate }}:
                {{ activity.purchaseInfo?.purchaseDate | date }}</span
              >
            </ion-row>
            <ion-row style="margin-bottom: 5px;">
              <span
                >{{ 'global.my-list-menu.listingClass' | translate }}:
                {{ detail?.premium ? 'PREMIUM' : 'PROMOTED' }}</span
              >
            </ion-row>

            <ion-row style="margin-bottom: 5px;">
              <span>{{ 'global.my-list-menu.initialCreditsPurchased' | translate }}: {{ detail?.initialCredits }}</span>
            </ion-row>

            <ion-row style="margin-bottom: 5px;">
              <span class="credit-available"
                >{{ 'global.my-list-menu.creditsAvailable' | translate }}: {{ detail?.creditsAvailable }}</span
              >
              <div class="save-cancel-btn" *ngIf="detail?.creditsAvailable > 0 && spotAdmin">
                <div class="pointer-cursor" (click)="removeCredits(detail.id, detail.creditsAvailable)">
                  <span style="padding-left: 10px; color: #043650; font-weight: 900;">{{
                    'global.my-payment.clickToRemoveCredits' | translate
                  }}</span>
                  <ion-ripple-effect></ion-ripple-effect>
                </div>
              </div>
            </ion-row>

            <ion-row style="margin-bottom: 5px;">
              <span
                >{{ 'global.my-list-menu.listingExp' | translate }}: {{ detail?.listingExpirationDate | date }}</span
              >
            </ion-row>
          </button>
        </ng-container>

        <ng-container *ngIf="activity.activityType === 'CREDIT_REMOVAL'">
          <button class="free-card">
            <ion-row style="margin-bottom: 5px;">
              <span>Listing Credits Removed</span>
            </ion-row>
            <ion-row style="margin-bottom: 5px;" *ngIf="activity.activityLog.offeredByUserName">
              <span
                >{{ 'global.my-list-menu.userAccount' | translate }}: {{ activity.activityLog.offeredByUserName }}</span
              >
            </ion-row>
            <ion-row style="margin-bottom: 5px;" *ngIf="activity.activityLog.getOfferedByUserCompanyName">
              <span
                >{{ 'global.my-list-menu.company' | translate }}:
                {{ activity.activityLog.getOfferedByUserCompanyName }}</span
              >
            </ion-row>
            <ion-row style="margin-bottom: 5px;">
              <span
                >{{ 'global.my-list-menu.initialDatePurchased' | translate }}:
                {{ activity.activityLog.purchaseDate | date }}</span
              >
            </ion-row>
            <ion-row style="margin-bottom: 5px;">
              <span
                >{{ 'global.my-list-menu.removeDate' | translate }}: {{ activity.activityLog.createdDate | date }}</span
              >
            </ion-row>
            <ion-row style="margin-bottom: 5px;">
              <span
                >{{ 'global.my-list-menu.creditsRemoved' | translate }}: {{ activity.activityLog.creditsRemoved }}</span
              >
            </ion-row>
          </button>
        </ng-container>

        <button class="free-card" *ngIf="activity.activityType === 'RENEWAL'">
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.my-list-menu.listingRenewal' | translate }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;display: flex; flex-direction: row; flex-wrap: wrap;">
            <span>{{ 'global.listing' | translate }}:&nbsp;</span>
            <span localize [textValue]="activity.activityLog?.buildingTypeName"></span>
            <span>&nbsp;</span>
            <span
              *ngIf="activity.activityLog?.isForLeaseSale === 'L'"
              [translate]="'global.listing-card.forLease'"
              [translateParams]="{ colon: '' }"
            ></span>
            <span
              *ngIf="activity.activityLog?.isForLeaseSale === 'S'"
              [translate]="'global.listing-card.forSale'"
              [translateParams]="{ colon: '' }"
            ></span>
            <span
              *ngIf="activity.activityLog?.isForLeaseSale === 'C'"
              [translate]="'global.listing-card.forCoworking'"
              [translateParams]="{ colon: '' }"
            ></span>
            <span>&nbsp;-&nbsp; </span>
            <span localize [textValue]="activity.activityLog?.buildingTitle"></span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.listing' | translate }} ID: {{ activity.activityLog?.listingId }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span
              >{{ 'global.my-list-menu.listingClass' | translate }}: {{ activity.activityLog?.newListingType }}</span
            >
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span
              >{{ 'global.my-list-menu.company' | translate }}:
              {{ activity.activityLog?.getOfferedByUserCompanyName }}</span
            >
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.my-list-menu.renewedBy' | translate }}: {{ activity.activityLog?.createdUser }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span
              >{{ 'global.my-list-menu.renewalDate' | translate }}: {{ activity.activityLog?.createdDate | date }}</span
            >
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span
              >{{ 'global.my-list-menu.newListingExpiration' | translate }}:
              {{ activity.activityLog?.newEndDate | date }}</span
            >
          </ion-row>
        </button>

        <button class="free-card" *ngIf="activity.activityType === 'ADMIN UPDATE'">
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.my-list-menu.listingModification' | translate }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;display: flex; flex-direction: row; flex-wrap: wrap;">
            <span>{{ 'global.listing' | translate }}:&nbsp;</span>
            <span localize [textValue]="activity.activityLog?.buildingTypeName"></span>
            <span>&nbsp;</span>
            <span
              *ngIf="activity.activityLog?.isForLeaseSale === 'L'"
              [translate]="'global.listing-card.forLease'"
              [translateParams]="{ colon: '' }"
            ></span>
            <span
              *ngIf="activity.activityLog?.isForLeaseSale === 'S'"
              [translate]="'global.listing-card.forSale'"
              [translateParams]="{ colon: '' }"
            ></span>
            <span
              *ngIf="activity.activityLog?.isForLeaseSale === 'C'"
              [translate]="'global.listing-card.forCoworking'"
              [translateParams]="{ colon: '' }"
            ></span>
            <span>&nbsp;-&nbsp; </span>
            <span localize [textValue]="activity.activityLog?.buildingTitle"></span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.listing' | translate }} ID: {{ activity.activityLog?.listingId }}</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span
              >{{ 'global.my-list-menu.company' | translate }}:
              {{ activity.activityLog?.getOfferedByUserCompanyName }}</span
            >
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span
              >{{ 'global.my-list-menu.listingAgent' | translate }}: {{ activity.activityLog?.offeredByUserName }}</span
            >
          </ion-row>
          <ion-row style="margin-bottom: 5px;">
            <span>{{ 'global.my-list-menu.modifiedBy' | translate }}: SPOT</span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;" *ngIf="activity.activityLog.newListingType">
            <span
              >{{ 'global.my-list-menu.newListingClass' | translate }}: {{ activity.activityLog?.newListingType }}</span
            >
          </ion-row>
          <ion-row style="margin-bottom: 5px;" *ngIf="activity.activityLog.newListingStatus">
            <span>{{ 'global.my-list-menu.newListingStatus' | translate }}:&nbsp;</span>
            <span localize [textValue]="activity.activityLog?.newListingStatus.status"></span>
          </ion-row>
          <ion-row style="margin-bottom: 5px;" *ngIf="activity.activityLog.newEndDate">
            <span
              >{{ 'global.my-list-menu.newListingExpiration' | translate }}:
              {{ activity.activityLog?.newEndDate | date }}</span
            >
          </ion-row>
        </button>
      </ion-row>
    </div>
  </div>
</ion-content>
