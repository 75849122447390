import * as tslib_1 from "tslib";
import { OnInit, ElementRef, NgZone, EventEmitter } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { I18nService } from '@app/core';
export class Marker {
    constructor(lat, lng) {
        this.lat = lat;
        this.lng = lng;
    }
}
export class BuildingLocation {
    constructor(marker, address, stateName, cityName, cityId) {
        this.marker = marker;
        this.address = address;
        this.stateName = stateName;
        this.cityName = cityName;
        this.cityId = cityId;
    }
}
export class MapComponent {
    constructor(mapsAPILoader, ngZone, i18NService) {
        this.mapsAPILoader = mapsAPILoader;
        this.ngZone = ngZone;
        this.i18NService = i18NService;
        this.defaultLat = -23.5955;
        this.defaultLng = -46.6885;
        this.newLocationEvent = new EventEmitter();
    }
    ngOnInit() {
        this.zoom = 15;
        this.enterAddressDesc = this.i18NService.get('map.enterAddress');
        this.title = this.i18NService.get('map.whereBuildingLocated');
        this.buildingMarker = new Marker(this.defaultLat, this.defaultLng);
        //load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder();
            this.setNewMarker(this.defaultLat, this.defaultLng);
            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    //get the place result
                    let place = autocomplete.getPlace();
                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    //set latitude, longitude
                    this.setNewMarker(place.geometry.location.lat(), place.geometry.location.lng());
                });
            });
        });
    }
    // Get Current Location Coordinates
    setCurrentLocation() {
        /*
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(position => {
            this.buildingMarker.lat = position.coords.latitude;
            this.buildingMarker.lng = position.coords.longitude;
            this.getAddress(this.buildingMarker.lat, this.buildingMarker.lng);
          });
        }
        */
    }
    addMarker(event) {
        const coords = event.coords;
        this.setNewMarker(coords.lat, coords.lng);
    }
    markerDragEnd($event) {
        this.setNewMarker($event.coords.lat, $event.coords.lng);
    }
    findAddressStateAndCityFrom(marker) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.geoCoder.geocode({ location: { lat: marker.lat, lng: marker.lng } }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        let address = results[0].formatted_address;
                        if (address) {
                            for (let i = 0; i < 2; i++) {
                                let lastIdx = address.lastIndexOf(',');
                                address = address.substring(0, lastIdx);
                            }
                        }
                        let stateName = null;
                        let cityName = null;
                        for (var i = 0; i < results[0].address_components.length; i++) {
                            for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                                //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                                if (results[0].address_components[i].types[b] == 'administrative_area_level_1') {
                                    stateName = results[0].address_components[i].long_name;
                                }
                                else if (results[0].address_components[i].types[b] == 'locality' ||
                                    results[0].address_components[i].types[b] == 'administrative_area_level_2') {
                                    cityName = results[0].address_components[i].long_name;
                                }
                            }
                        }
                        const buildingLocation = new BuildingLocation(marker, address, stateName, cityName);
                        this.newLocationEvent.emit(buildingLocation);
                    }
                    else {
                    }
                }
                else {
                    console.error('Geocoder failed due to: ' + status);
                }
            });
        });
    }
    setNewMarker(lat, lng) {
        if (lat && lng) {
            this.buildingMarker.lat = lat;
            this.buildingMarker.lng = lng;
            this.findAddressStateAndCityFrom(this.buildingMarker);
        }
    }
}
