import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export enum ReixSpotLangs {
  EN = 'en',
  COUNTRY_LANG = 'pt-br'
}

export enum ReixLangCulture {
  EN = 'en-US',
  COUNTRY_LANG = 'pt-BR'
}

@Injectable()
export class ReixSpotLanguagesService {
  private defaultLang: string;

  getDefaultLang = () => {
    if (!this.defaultLang) {
      if (environment.defaultLanguage == ReixLangCulture.COUNTRY_LANG) {
        this.defaultLang = ReixSpotLangs.COUNTRY_LANG.toString();
      } else {
        this.defaultLang = ReixSpotLangs.EN.toString();
      }
    }
    return this.defaultLang;
  };
}
