import { Routes } from '@angular/router';
const ɵ0 = () => import("./home/home.module.ngfactory").then(m => m.HomeModuleNgFactory), ɵ1 = () => import("./search-navigation/search-navigation.module.ngfactory").then(m => m.SearchNavigationModuleNgFactory), ɵ2 = () => import("./user-home/user-home.routing.module.ngfactory").then(m => m.UserHomeRoutingModuleNgFactory), ɵ3 = () => import("./spot-buildings/spot-buildings.module.ngfactory").then(m => m.SpotBuildingsModuleNgFactory), ɵ4 = () => import("./company/company.module.ngfactory").then(m => m.CompanyModuleNgFactory), ɵ5 = () => import("./brokers/brokers.module.ngfactory").then(m => m.BrokersModuleNgFactory), ɵ6 = () => import("./favorites/favorites.module.ngfactory").then(m => m.FavoritesModuleNgFactory), ɵ7 = () => import("./preview-image/preview-image.module.ngfactory").then(m => m.PreviewImageModuleNgFactory), ɵ8 = () => import("./not-found/not-found.module.ngfactory").then(m => m.NotFoundModuleNgFactory);
const routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: 'search-navigation',
        loadChildren: ɵ1
    },
    {
        path: 'user-home',
        loadChildren: ɵ2
    },
    {
        path: 'spot-buildings',
        loadChildren: ɵ3
    },
    {
        path: 'companies',
        loadChildren: ɵ4
    },
    {
        path: 'brokers',
        loadChildren: ɵ5
    },
    {
        path: 'favorites',
        loadChildren: ɵ6
    },
    {
        path: 'images',
        loadChildren: ɵ7
    },
    {
        path: 'not-found',
        loadChildren: ɵ8
    },
    {
        path: '**',
        redirectTo: 'not-found'
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
