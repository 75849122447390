<!-- Mobile -->
<div *ngIf="isMobile" class="gallery__container" style="margin: 40px 0px 24px 0px;">
  <div class="flex" *ngIf="fromPopup" style="border-bottom: 1px solid white;">
    <div class="gallery__spot__title" style="color: #FFFF;margin-left: 18px;">
      <label>{{ spotTitle }}</label>
    </div>

    <div class="flex-align-content-end header" style="margin: 0px 18px 0px 0px;">
      <img
        class="close-icon"
        style="width: 14px;height: 14px;"
        (click)="closeModal()"
        src="assets/images/icons/closeWhite.png"
        alt=""
      />
    </div>
  </div>

  <div *ngIf="listingType == 'PREMIUM'" id="floatingPagination" class="premium__floating">
    <div class="premium__floating__section">
      <img style="width: 22px;" src="assets/images/icons/stars.svg" alt="" />
      <label class="premium__floating__text">{{ 'global.gold' | translate }}</label>
    </div>
  </div>
  <div *ngIf="listingType == 'FEATURED'" id="floatingPagination" class="premium__floating">
    <div class="premium__floating__section">
      <img style="width: 22px;" src="assets/images/icons/auto_awesome.svg" alt="" />
      <label class="premium__floating__text">{{ 'global.platinum' | translate }}</label>
    </div>
  </div>
  <div style="width: 100%;">
    <ion-slides
      (ionSlideTransitionEnd)="getNextPhoto($event)"
      [options]="slideOptions"
      pager="true"
      #photoSlider
      id="photoSlider"
    >
      <ion-slide *ngFor="let photo of galleryImgs; let i = index" style="width: 100%;">
        <ion-row>
          <img
            [alt]="spotTitle"
            [title]="spotTitle"
            class="top-img"
            [defaultImage]="defaultImage"
            [ngStyle]="cssImgMobile()"
            [lazyLoad]="photo && photo.imageId ? this.getMediumImg(this.getCroppedOrOriginalImage(photo)) : photo"
          />
        </ion-row>
      </ion-slide>
    </ion-slides>
  </div>

  <div *ngIf="!noPhotos" id="floatingPagination" class="gallery__floating__pagination">
    <span class="gallery__floating__pagination--position">{{ getCurrentFromSlider() }}</span>
  </div>
  <div class="col-md-12">
    <div class="description">
      <ion-label>{{ description }}</ion-label>
    </div>
  </div>
</div>

<!-- Desktop -->
<div *ngIf="!isMobile" class="gallery__container" [ngClass]="{ 'gallery__container--margin': fromPopup }">
  <div class="flex" *ngIf="fromPopup">
    <div class="gallery__spot__title">
      <label>{{ spotTitle }}</label>
    </div>

    <div class="flex-align-content-end header">
      <img
        class="close-icon"
        style="width: 14px;height: 14px;margin-right: -14px;"
        (click)="closeModal()"
        src="assets/images/icons/close.png"
        alt=""
      />
    </div>
  </div>

  <div
    *ngIf="!noPhotos"
    id="floatingPagination"
    [ngClass]="{
      'gallery__floating__pagination__popup--margin': fromPopup,
      gallery__nested__floating__position: nestedFloating
    }"
    class="gallery__floating__pagination"
  >
    <span class="gallery__floating__pagination--position">{{ getCurrentFromSlider() }}</span>
  </div>

  <div class="gallery__grid" [ngClass]="{ 'gallery__section__separator--paddingtop': fromPopup }">
    <div class="gallery__arrows__cols">
      <fa-icon
        [ngClass]="{ 'fa-disabled': isStartOfSlide() }"
        class="arrow-icon"
        (click)="slideToIndex($event, -1)"
        [icon]="leftArrow"
        size="lg"
      ></fa-icon>
    </div>
    <div class="gallery__slider__primary__container">
      <ion-slides
        class="slider__primary--dimensions"
        (ionSlideTransitionEnd)="getNextPhoto($event)"
        #photoSlider
        id="photoSlider"
      >
        <ion-slide class="slider__primary__img--dimensions" *ngFor="let photo of galleryImgs; let i = index">
          <img
            class="slider__primary__img--dimensions"
            [default]="defaultImage"
            [lazyLoad]="photo && photo.imageId ? this.getMediumImg(this.getCroppedOrOriginalImage(photo)) : photo"
          />
        </ion-slide>
      </ion-slides>
    </div>
    <div class="gallery__arrows__cols">
      <fa-icon
        class="arrow-icon"
        [ngClass]="{ 'fa-disabled': isEndOfSlide() }"
        (click)="slideToIndex($event, 1)"
        [icon]="rightArrow"
        size="lg"
      ></fa-icon>
    </div>
  </div>

  <div
    id="preview-slider"
    *ngIf="!noPhotos"
    class="gallery__section__separator--paddingtop gallery__slider__preview__container"
  >
    <ion-slides
      class="slider__preview--dimensions slider__preview"
      [options]="sliderPreviewOptions"
      (ionSlideTransitionEnd)="getNextPhoto($event)"
      #previewSlider
      id="previewSlider"
    >
      <ion-slide class="slider__preview__img--dimensions" *ngFor="let photo of galleryImgs; let i = index">
        <img
          (click)="syncSlidersFromPreview($event, i)"
          [ngClass]="{ 'slider__preview__img__active--border': i == currentPhotoIndex }"
          class="slider__preview__img--dimensions"
          [default]="defaultImage"
          [src]="photo && photo.imageId ? getMediumImg(getCroppedOrOriginalImage(photo)) : photo"
        />
      </ion-slide>
    </ion-slides>
  </div>
</div>
