<form [formGroup]="moreDetailsForm" class="full-width" name="moreDetailsForm" autocomplete="off">
  <ion-row class="full-width margin-top-5 margin-bottom-10">
    <ion-label class="full-width"
      >{{ 'global.list-your-property.neighborhood' | translate }} <span class="blue">*</span>
    </ion-label>
    <ion-row class="full-width listing-type-select">
      <ion-input
        class="input-border"
        id="neighborhood"
        maxlength="30"
        name="neighborhood"
        type="text"
        formControlName="neighborhood"
      >
      </ion-input>
    </ion-row>
  </ion-row>

  <ion-row class="full-width" id="detail-scroll">
    <multi-language-elem
      (onValueChange)="titleChange($event)"
      [formArrayValue]="existingTitle"
      #title
      [options]="titleFieldOptions"
    >
    </multi-language-elem>
    <multi-language-elem
      #description
      [formArrayValue]="existingComments"
      (onValueChange)="descriptionChange($event)"
      [options]="descriptionFieldOptions"
    >
    </multi-language-elem>
  </ion-row>

  <ion-row class="full-width margin-top-5" style="margin-top: 10px;" *ngIf="basicListingType">
    <ion-label class="full-width"
      >{{ 'global.list-your-property.propertyCompany' | translate }} <span class="blue">*</span>
    </ion-label>
    <ion-row class="full-width listing-type-select">
      <ion-input
        class="input-border"
        id="managementCompany"
        maxlength="30"
        name="managementCompany"
        type="text"
        formControlName="propertyManagementCompany"
      >
      </ion-input>
    </ion-row>

    <ion-label class="full-width">{{ 'global.list-your-property.propertyManager' | translate }}</ion-label>
    <ion-row class="full-width listing-type-select">
      <ion-input
        class="input-border"
        id="managerName"
        maxlength="30"
        name="managerName"
        type="text"
        formControlName="managerName"
      >
      </ion-input>
    </ion-row>

    <ion-label class="full-width">{{ 'global.list-your-property.email' | translate }}</ion-label>
    <ion-row class="full-width listing-type-select">
      <ion-input
        class="input-border"
        id="managerEmail"
        maxlength="30"
        name="managerEmail"
        type="email"
        formControlName="managerEmail"
      >
      </ion-input>
    </ion-row>

    <ion-label class="full-width">{{ 'global.list-your-property.phoneNumber' | translate }}</ion-label>
    <ion-row class="full-width listing-type-select">
      <ion-input
        class="input-border"
        id="managerTelephone"
        maxlength="30"
        name="managerTelephone"
        type="text"
        formControlName="managerTelephone"
      >
      </ion-input>
    </ion-row>
  </ion-row>
</form>

<ion-row
  *ngIf="(spotAdmin || listingAdmin) && selectCompany && !basicListingType"
  class="full-width margin-top-5"
  style="margin-top: 10px;"
>
  <ion-col size="12">
    <ion-label class="label-color"
      >{{ 'global.my-list-menu.company' | translate }} : {{ selectCompany.name }}
    </ion-label>
    <!-- <ionic-selectable
        style="border: 1px solid lightgrey;"
        [items]="allCompanies"
        itemValueField="id"
        [disabled]="true"
        itemTextField="name"
        [(ngModel)]="selectCompany"
        [hasVirtualScroll]="true"
        placeholder="Company"
        [canSearch]="true"
        (onChange)="portChange($event)"
        searchPlaceholder="{{ 'global.menu.search' | translate }}"
        closeButtonText="{{ 'Cancel' | translate }}"
        #portComponent
      >
      </ionic-selectable> -->
  </ion-col>
</ion-row>

<ion-row
  *ngIf="(spotAdmin || listingAdmin) && !basicListingType"
  class="full-width margin-top-5"
  style="margin-top: 10px;
  margin-bottom: 10px;"
>
  <ion-col size="12">
    <ion-label>{{ 'global.my-list-menu.listingAgent' | translate }}</ion-label>
    <span class="width-full my-listing-agent">
      <ionic-selectable
        style="border: 1px solid lightgrey;"
        [(ngModel)]="offeredByUserId"
        [items]="users"
        itemValueField="id"
        [attr.disabled]="spotAdmin ? false : true"
        itemTextField="firstNameAndLastName"
        [hasVirtualScroll]="true"
        placeholder="Agent"
        [canSearch]="true"
        (onChange)="nameChange($event)"
        searchPlaceholder="{{ 'global.menu.search' | translate }}"
        closeButtonText="{{ 'Cancel' | translate }}"
        #agentComponent
      >
      </ionic-selectable>
    </span>
  </ion-col>
</ion-row>
