<div class="row" style="margin-right: 40px;margin-left: 40px; margin-top: 40px;">
  <div
    [ngClass]="{ 'more-notifications-title-desktop': !isMobile, 'more-notifications-title-mobile': isMobile }"
    class="col-md-12"
  >
    <strong>{{ 'notifications.moreNotification' | translate }}</strong>
  </div>
  <div style="border-radius: 0px 0px 8px 8px;" class="col-md-12 content-border">
    <div style="border-radius: 8px 8px 0px 0px" class="col-md-12 content-filter">
      <div
        class="col-md-8"
        style="white-space: nowrap;display: flex;
      align-items: center;"
      >
        <span
          [ngClass]="{ notification_history_txt__desktop: !isMobile, notification_history_txt__mobile: isMobile }"
          class="notification_history_txt"
          >{{ 'notifications.notificationHistoy' | translate }}</span
        >
      </div>
      <div class="col-md-4 filter-div">
        <fa-icon
          style="font-size: 14px;
      color: white;
      position: absolute;
      margin: 24px 0px 0px 0px; cursor: pointer; position: inherit;"
          class="arrow-icon "
          [icon]="search"
          size="lg"
          (click)="searchNotification()"
        >
        </fa-icon>
        <input
          *ngIf="seeSearch"
          (keyup)="searchFilter()"
          (blur)="searchFilter()"
          style="width: 90%;
         margin: 19px -10px 18px 10px;
      background: #00334D;
      border: none;
      border-bottom: 1px solid white;
      color: white;"
          type="text"
          [(ngModel)]="filterNotification"
        />
        <button
          class="btn-profile"
          mat-button
          [matMenuTriggerFor]="filterSet"
          style="
      color: white;
      margin-top: 6px;"
        >
          <fa-icon style="font-size: 11px;" class="arrow-icon " [icon]="filter" size="lg"> </fa-icon>
        </button>
        <mat-menu #filterSet="matMenu">
          <button mat-menu-item (click)="nameAscending()">
            {{ 'favorites.nameAscending' | translate }}
          </button>
          <button mat-menu-item (click)="nameDescending()">
            {{ 'favorites.nameDescending' | translate }}
          </button>
          <button mat-menu-item (click)="oldestFirst()">
            {{ 'favorites.oldestFirst' | translate }}
          </button>
          <button mat-menu-item (click)="newestFirst()">
            {{ 'favorites.newestFirst' | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="col-md-12" style="padding: 0px">
      <table class="table responsivegene">
        <tbody *ngIf="notifications?.content">
          <tr *ngFor="let notification of notifications.content">
            <td style="width: 34px;">
              <fa-icon
                style="font-size: 12px; color: #ff9900;"
                *ngIf="!notification.read"
                class="icon-size"
                [icon]="faCircle"
              ></fa-icon>
            </td>
            <td style="cursor: pointer;display: grid;" (click)="goToActionLink(notification)">
              <span class="notification-text" [innerHTML]="getContent(notification.content)"></span>
              <span
                style="font-weight: 400;
              font-size: 10px;
              line-height: 16px;
              color: #6C757D;"
                class="current"
                >{{ notificationService.notificationDate(notification.createdDate) }}</span
              >
            </td>
            <td>
              <button style="text-align: end;" mat-menu-item [matMenuTriggerFor]="hideOptions">
                <fa-icon style="font-size: 14px;" class="arrow-icon " [icon]="faEllipsisH" size="lg"> </fa-icon>
              </button>
              <mat-menu #hideOptions="matMenu" xPosition="before" yPosition="above">
                <button mat-menu-item (click)="hideNotification(notification.id)">
                  <fa-icon class="arrow-icon hide-icons" [icon]="faEyeSlash" size="lg"> </fa-icon
                  >{{ 'hideNotification' | translate }}
                </button>
                <button
                  *ngIf="notification.notificationActivityType == turnOffParams.RECOMMENDATION"
                  mat-menu-item
                  (click)="turnOffNotification({ recommendationNotification: false })"
                >
                  <fa-icon class="arrow-icon hide-icons" [icon]="faBellSlash" size="lg"> </fa-icon
                  >{{ 'notifications.turnOffRecommendations' | translate }}
                </button>
                <button
                  *ngIf="notification.notificationActivityType == turnOffParams.FAVORITES"
                  mat-menu-item
                  (click)="turnOffNotification({ favoriteNotification: false })"
                >
                  <fa-icon class="arrow-icon hide-icons" [icon]="faBellSlash" size="lg"> </fa-icon
                  >{{ 'notifications.turnOffFavorites' | translate }}
                </button>
                <button
                  *ngIf="notification.notificationActivityType == turnOffParams.SPOT_SET"
                  mat-menu-item
                  (click)="turnOffNotification({ spotSetNotification: false })"
                >
                  <fa-icon class="arrow-icon hide-icons" [icon]="faBellSlash" size="lg"> </fa-icon
                  >{{ 'notifications.turnOffSpotSets' | translate }}
                </button>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="notifications?.content.length === 0"
        class="col-md-12"
        style="text-align: center;margin: 50px 0px 50px 1px;"
      >
        <div class="col-md-12">
          <strong style="color: #6C757D;font-size: 27px;">{{ 'notifications.message1' | translate }}</strong>
        </div>
        <!-- <div class="col-md-12">
          <span style="color: #6C757D;" [innerHTML]="'notifications.message2' | translate"></span>
        </div> -->
        <div class="col-md-12" style="margin-top: 30px;">
          <ion-button
            style="text-transform: none !important;"
            (click)="goBrowseProperties()"
            class="btn-color-2 see-more-space"
          >
            <span style="text-transform: none !important;">{{ 'notifications.btnBrowse' | translate }}</span>
          </ion-button>
        </div>
      </div>
      <div
        class="col-md-12"
        style="padding: 0px; background:#F8F9FA;display: flex;justify-content: end;border-top: 1px solid #E5E5E5;"
      >
        <ngb-pagination
          style="margin: 17px 14px 0px 0px;"
          (pageChange)="changePage($event)"
          [pageSize]="pageSize"
          [collectionSize]="collection"
          [maxSize]="size"
          [(page)]="page"
          aria-label="Default pagination"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
