import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ListingDetailComponent } from './pages/listing-detail/listing-detail.component';
import { ListingSearchComponent } from '@app/search-navigation/pages/listing-search/listing-search.component';
import { RegisterFormComponent } from '@app/shared/register';
import { ForgotFormComponent } from '@app/shared/forgot-password';
import { FourAllPaymentComponent } from '@app/shared/four-all-payment/four-all-payment.component';
import { BackwardGuard, BackStepperGuard, AuthGuard } from '@app/guards';
import { ListPropertyAddComponent } from './pages/list-property/add/list-property-add.component';
import { ListCartComponent } from './pages/list-property/cart/list-cart.component';
import { ListPropertyComponent } from '@app/search-navigation/pages/list-property/list-property.component';
import { ListUserComponent } from '@app/search-navigation/pages/listing-user';
import { ChangePasswordComponent } from '@app/shared/change-password';
import { PrivacyPolicyComponent } from '@app/shared/privacy-policy/privacy-policy.component';
import { TermsComponent } from '@app/shared/terms/terms.component';
import { DownloadComponent } from '@app/shared/download';
import { MoreNotificationsComponent } from '@app/shared/notifications/more-notifications/more-notifications.component';
import { SettingsNotificationComponent } from '@app/shared/settings-notification/settings-notification.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/', pathMatch: 'full' },
    {
      path: 'register',
      component: RegisterFormComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Register New User')
      }
    },
    {
      path: 'register/:language',
      component: RegisterFormComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Register New User')
      }
    },
    {
      path: 'forgot-password',
      component: ForgotFormComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Forgot Password')
      }
    },
    {
      path: 'forgot-password/:language',
      component: ForgotFormComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Forgot Password')
      }
    },
    {
      path: 'change-password',
      component: ChangePasswordComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Change Password')
      }
    },
    {
      path: 'change-password/:language',
      component: ChangePasswordComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Change Password')
      }
    },
    {
      path: 'list-property',
      component: ListPropertyComponent,
      canActivate: [AuthGuard],
      data: { title: extract('List Property'), skipParamCheck: true }
    },
    {
      path: 'list-property/:language',
      component: ListPropertyComponent,
      canActivate: [AuthGuard],
      data: { title: extract('List Property'), skipParamCheck: true }
    },
    {
      path: 'list-property/add',
      component: ListPropertyAddComponent,
      canActivate: [AuthGuard],
      data: { title: extract('List Property Add'), skipParamCheck: true }
    },
    {
      path: 'list-property/add/:language',
      component: ListPropertyAddComponent,
      canActivate: [AuthGuard],
      data: { title: extract('List Property Add'), skipParamCheck: true }
    },
    {
      path: 'listing-user/:id',
      component: ListUserComponent,
      data: { title: extract('Listing User'), skipParamCheck: true }
    },
    {
      path: 'listing-user/:id/:language',
      component: ListUserComponent,
      data: { title: extract('Listing User'), skipParamCheck: true }
    },
    {
      path: 'cart',
      component: ListCartComponent,
      canActivate: [AuthGuard],
      data: { title: extract('List Property'), skipParamCheck: true }
    },
    {
      path: 'cart/:language',
      component: ListCartComponent,
      canActivate: [AuthGuard],
      data: { title: extract('List Property'), skipParamCheck: true }
    },
    {
      path: 'payment',
      component: FourAllPaymentComponent,
      canActivate: [AuthGuard],
      data: { title: extract('Payment'), skipParamCheck: true }
    },
    {
      path: 'payment/:language',
      component: FourAllPaymentComponent,
      canActivate: [AuthGuard],
      data: { title: extract('Payment'), skipParamCheck: true }
    },
    /* LISTING DETAILS COMPONENT */
    /* detail/:baseId */
    {
      path: 'detail/:baseId',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    {
      path: 'detail/:baseId/:language',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    /* detail/:baseId/:listingId */
    {
      path: 'detail/:baseId/:listingId',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    {
      path: 'detail/:baseId/:listingId/:language',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    /* detail/:listingType/:propertyType/:buildingName/:baseId */
    {
      path: 'detail/:listingType/:propertyType/:buildingName/:baseId',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    {
      path: 'detail/:listingType/:propertyType/:buildingName/:baseId/:lang',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    /* detail/:listingType/:propertyType/:buildingName/:baseId/:listingId */
    {
      path: 'detail/:listingType/:propertyType/:buildingName/:baseId/:listingId',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    {
      path: 'detail/:listingType/:propertyType/:buildingName/:baseId/:listingId/:lang',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    /*                              'detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId
        The above route is taking by detail/:listingType/:propertyType/:buildingName/:baseId/:listingId
     */
    {
      path: 'detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId/:lang',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    /* detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId/:listingId */
    {
      path: 'detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId/:listingId',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    {
      path: 'detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId/:listingId/:lang',
      component: ListingDetailComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }
    },
    /* SEARCH COMPONENT */
    /* {
      path: 'search',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listing Search'), skipParamCheck: false },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'search/:language',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listing Search'), skipParamCheck: false },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'search/:from',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'search/:from/:language',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'search/:listingType/:propertyType',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'search/:listingType/:propertyType/:language',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'search/:listingType/:propertyType/:state',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'search/:listingType/:propertyType/:state/:language',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'search/:listingType/:propertyType/:state/:city',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'search/:listingType/:propertyType/:state/:city/:language',
      component: ListingSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    }, */
    {
      path: `${AppRouteNames.PRIVACY_POLICY_ROUTE}/:language`,
      component: PrivacyPolicyComponent,
      data: { title: extract('Privacy Policy') }
    },
    {
      path: `${AppRouteNames.PRIVACY_POLICY_ROUTE_CL}/:language`,
      component: PrivacyPolicyComponent,
      data: { title: extract('Privacy Policy') }
    },
    {
      path: `${AppRouteNames.TERMS_ROUTE}/:language`,
      component: TermsComponent,
      data: { title: extract('Terms Of Use') }
    },
    {
      path: `${AppRouteNames.TERMS_ROUTE_CL}/:language`,
      component: TermsComponent,
      data: { title: extract('Terms Of Use') }
    },
    {
      path: 'download',
      component: DownloadComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Register New User')
      }
    },
    {
      path: 'download/:language',
      component: DownloadComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Register New User')
      }
    },
    {
      path: 'notifications',
      component: MoreNotificationsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('User Notifications')
      }
    },
    {
      path: 'notifications/:language',
      component: MoreNotificationsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('User Notifications')
      }
    },
    {
      path: 'settings',
      component: SettingsNotificationComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('settings')
      }
    },
    {
      path: 'settings/:language',
      component: SettingsNotificationComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('settings')
      }
    }
  ])
];

//Pass child routes to Shell for reuse

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SearchNavigationRoutingModule {}
