<div class="full-width rel-pos">
  <ion-label class="full-width blue">
    {{ 'global.list-your-property.typeOffer' | translate }}
  </ion-label>

  <ion-row class="full-width">
    <ion-segment [disabled]="isUpdate" mode="ios" class="segment-block" color="secondary">
      <ion-segment-button
        class="segment-border border-radius-unset width-30"
        [checked]="spotBuildings.type == 'L'"
        (click)="checkBuildingOffer(1)"
      >
        <ion-label translate="global.list-your-property.forLease"></ion-label>
      </ion-segment-button>
      <ion-segment-button
        class="segment-border border-radius-unset width-30"
        [checked]="spotBuildings.type == 'C'"
        (click)="checkBuildingOffer(2)"
      >
        <ion-label translate="global.list-your-property.forCoworking"></ion-label>
      </ion-segment-button>
      <ion-segment-button
        class="segment-border border-radius-unset width-30"
        [checked]="spotBuildings.type == 'S'"
        (click)="checkBuildingOffer(3)"
      >
        <ion-label translate="global.list-your-property.forSale"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-row>

  <ion-label class="full-width blue" *ngIf="spotBuildings.type == 'S'">
    {{ 'global.list-your-property.typeOfSale' | translate }}
  </ion-label>

  <ion-row class="full-width" *ngIf="spotBuildings.type == 'S'">
    <ion-segment [disabled]="isUpdate" mode="ios" class="segment-block" color="secondary">
      <ion-segment-button
        class="segment-border border-radius-unset width-30"
        [checked]="spotBuildings.entireSale"
        (click)="checkBuildingTypeSale(true)"
      >
        <ion-label translate="global.list-your-property.entireSale"></ion-label>
      </ion-segment-button>
      <ion-segment-button
        class="segment-border border-radius-unset width-30"
        [checked]="!spotBuildings.entireSale"
        (click)="checkBuildingTypeSale(false)"
      >
        <ion-label translate="global.list-your-property.partialSale"></ion-label>
      </ion-segment-button>
      <!--  <ion-segment-button
        class="segment-border border-radius-unset width-30"
        [checked]="propertyType[0] == 3001"
        (click)="checkBuildingType(3001)"
      >
        <ion-label translate="global.list-your-property.retail"></ion-label>
      </ion-segment-button> -->
    </ion-segment>
  </ion-row>

  <ion-label class="full-width blue margin-top-15"
    >{{ 'global.list-your-property.typeProperty' | translate }}
  </ion-label>
  <ion-row class="full-width">
    <ion-segment [disabled]="isUpdate" mode="ios" class="segment-block" color="secondary">
      <ion-segment-button
        class="segment-border border-radius-unset width-30"
        [checked]="propertyType[0] == 2001"
        (click)="checkBuildingType(2001)"
      >
        <ion-label translate="global.list-your-property.office"></ion-label>
      </ion-segment-button>
      <ion-segment-button
        [disabled]="spotBuildings.type == 'C'"
        [ngStyle]="{ color: spotBuildings.type == 'C' ? 'black' : '' }"
        class="segment-border border-radius-unset width-30"
        [checked]="propertyType[0] == 1001"
        (click)="checkBuildingType(1001)"
      >
        <ion-label translate="global.list-your-property.industrial"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-row>

  <ion-label class="full-width blue margin-top-15">{{ 'global.menu.entities.listingType' | translate }} </ion-label>
  <ion-row class="full-width">
    <ion-segment mode="ios" class="segment-block" color="secondary">
      <ion-segment-button
        class="segment-border border-radius-unset width-30"
        [checked]="spotBuildings.listingType == 'FEATURED'"
        (click)="checkListingType(1)"
      >
        <ion-label translate="global.platinum"></ion-label>
      </ion-segment-button>
      <ion-segment-button
        class="segment-border border-radius-unset width-30"
        [checked]="spotBuildings.listingType == 'PREMIUM'"
        (click)="checkListingType(2)"
      >
        <ion-label translate="global.gold"></ion-label>
      </ion-segment-button>
      <ion-segment-button
        [disabled]="spotBuildings.type == 'C'"
        [ngStyle]="{ color: spotBuildings.type == 'C' ? 'black' : '' }"
        class="segment-border border-radius-unset width-30"
        [checked]="spotBuildings.listingType == 'PROMOTED'"
        (click)="checkListingType(3)"
      >
        <ion-label translate="global.purchase-options.promoted"></ion-label>
      </ion-segment-button>
      <!-- <ion-segment-button class="segment-border border-radius-unset width-30"
        [checked]="spotBuildings.listingType == 'BASIC'" (click)="checkListingType(4)">
        <ion-label translate="global.purchase-options.basic"></ion-label>
      </ion-segment-button> -->
    </ion-segment>
  </ion-row>

  <ion-row style="padding-top: 15px;">
    <ion-col>
      <ion-checkbox class="margin-right-10" [(ngModel)]="spotBuildings.offMarket"></ion-checkbox>
      <ion-label class="weight" translate="global.list-your-property.offMarket"></ion-label>
    </ion-col>

    <ion-col>
      <ion-checkbox [(ngModel)]="spotBuildings.featured"></ion-checkbox>
      <ion-label class="weight" translate="global.list-your-property.featuredInHomepage"></ion-label>
    </ion-col>

    <ion-col>
      <ion-checkbox [(ngModel)]="spotBuildings.enabled"></ion-checkbox>
      <ion-label class="weight" translate="global.list-your-property.active"></ion-label>
    </ion-col>
  </ion-row>

  <ion-row class="full-width margin-top-5">
    <ion-label class="full-width">{{ 'global.list-your-property.propertyName' | translate }} </ion-label>
    <ion-row class="search-row">
      <ion-searchbar
        [disabled]="isUpdate"
        style="padding-left: unset;"
        type="text"
        [(ngModel)]="inputBuilding"
        (ionChange)="changeFuzzySearch()"
        debounce="500"
        showCancelButton="never"
        spellcheck="false"
        placeholder="{{ 'global.menu.search' | translate }}"
        #searchbar
      >
      </ion-searchbar>
      <button [disabled]="isUpdate" (click)="clearInput()">
        <ion-icon class="close-icon" name="close"></ion-icon>
      </button>
    </ion-row>
    <ion-row class="full-width">
      <ion-list class="list-flow full-width" *ngIf="buildingNames && buildingNames.length">
        <ion-item
          *ngFor="let building of buildingNames; let index = index"
          (click)="setProperty(building, false)"
          localize
          inset="true"
          lines="full"
        >
          <div style="text-transform: none;" [innerHTML]="building.name"></div>
        </ion-item>
      </ion-list>
    </ion-row>
  </ion-row>

  <ion-row *ngIf="inputBuilding" class="full-width margin-top-5 margin-bottom-10">
    <ion-row class="full-width listing-type-select">
      <multi-language-elem
        *ngIf="inputBuilding != '' && !stopFuzzy"
        style="width: 100%"
        (onValueChange)="propertyNameChange($event)"
        [formArrayValue]="propertyName"
        #title
        [options]="propertyNameFieldOptions"
      >
      </multi-language-elem>
    </ion-row>
  </ion-row>

  <ion-row *ngIf="inputBuilding != '' && !stopFuzzy && actualBuilding">
    <app-duplomap
      class="map-size"
      [building]="actualBuilding"
      [showInCard]="true"
      [markers]="marker"
      [options]="mapOptions"
    ></app-duplomap>
  </ion-row>
</div>
