import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { LoaderComponent } from './loader/loader.component';
import { RowHeaderComponent } from './row-header/row-header.component';
import {
  LocaleChangerDirective,
  LocaleCheckerDirective,
  ImagePreloadDirective,
  LazyImageDirective
} from './directives';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { FooterComponent } from './footer/footer.component';
import { FourAllPaymentComponent } from './four-all-payment/four-all-payment.component';
import { HeaderComponent } from './header/header.component';
import { ContactFormComponent } from './contact-dialog/contact-form.component';
import { ContactDialogService } from './contact-dialog/contact-dialog.service';
import { LoginFormComponent, LoginFormDialogService } from './login';
import { PrivacyPolicyDialogComponent, PrivacyPolicyService } from './privacy-policy-dialog';
import { CompleteProfileComponent } from './register/complete-profile/complete-profile-modal.component';
import { PrivacyPolicyReviewComponent } from './privacy-policy-dialog/privacy-policy-review/privacy-policy-review.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InstallPromptComponent } from './install-prompt-dialog/install-prompt.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PageNotFoundComponent } from './page-not-found';
import { DuploMapModule } from './maps';
import { BreadcrumbComponent } from './breadcrumb';
import { ScrollToTopComponent } from './scroll';
import { RegisterFormComponent } from './register';
import { ForgotFormComponent } from './forgot-password';
import { OurPartnersComponent } from './our-partners';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PWAService } from './pwa-service';
import { ChromeInstallComponent } from '@app/shared/install-prompt-dialog';
import { IonicSelectableModule } from 'ionic-selectable';
import { ToasterNotificationComponent } from '@app/shared/toaster-notification/toaster-notification.component';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { ChangePasswordComponent } from './change-password';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsComponent } from './terms/terms.component';
import { SaveChangeModalComponent } from './save-change-prompt-modal/save-change-modal.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MyListingMenuComponent } from './my-listing-menu/my-listing-menu.component';
import { MyListingEditComponent } from './my-listing-edit/my-listing-edit.component';
import { MultiLanguageComponent } from './multi-language/multi-languge.component';
import { QuillModule } from 'ngx-quill';
import { InViewportModule } from 'ng-in-viewport';
import { IntlLanguageElemComponent } from './intl-language/intl-language.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { VideoComponent } from './video/video.component';
import { SecureDomPipe } from './pipes/secure-dom.pipe';
import { LocalNumberPipe } from './pipes/number-pipe';
import { TranslateStringCutter } from './pipes/translateStringCutter';
import { ScrollingDirective } from './directives/scrolling/scrolling.directive';
import { AccessGrantedDialogComponent } from './access-granted/access-granted.component';
import { ListingAnalyticsInfoComponent } from './listing-analytics-info/listing-analytics-info.component';
import { MatSlideToggleModule, MatTabsModule, MatTooltipModule } from '@angular/material';
import { EditCropImageModalComponent } from './edit-crop-image-modal/edit-crop-image-modal.component';
import { EventTrackerDirective } from '@app/google-analytics/event-tracket-directive';
import { RouterModule } from '@angular/router';
import { DownloadComponent } from './download';
import { AppTopBannerComponent } from './app-top-banner/app-top-banner.component';
import { AppAcceptCancelModalComponent } from './accept-cancel-modal/app-accept-cancel-modal.component';
import { AppAcceptCancelModalService } from './accept-cancel-modal/accept-cancel-modal.service';
import { MultipleFilesProgressBarComponent } from './multiple-files-progress-bar/multiple-files-progress-bar.component';
import { FullScreenMapWrapperComponent } from './full-screen-map-wrapper/full-screen-map-wrapper.component';
import { PortalModule } from '@angular/cdk/portal';
import { SubscribeModalComponent } from './subscribe/subscribe-modal.component';
import { PreviewImagesComponent } from './preview-images/preview-images.component';
import { imageUploadComponent } from '@app/shared/image-upload/image-upload.component';
import { ImageUploadService } from '@app/shared/services/image-upload.service';
import { LocationService } from './services/location.service';
import { EditCropImageSaveModalComponent } from './edit-crop-image-save-modal/edit-crop-image-save-modal.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { ShowImagesComponent } from './show-images/show-images.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { ServicesComponent } from '@app/shared/services-component/services.component';
import { AppSpotCardSlider } from './spot-card-slider/spot-card-slider';
import { SpotCardSliderService } from './services/spot-card-slider.service';
import { SpotCard } from './cards/spot-card/spot-card.component';
import { AppListingSlider } from '@app/search-navigation/components/listing-slider/app-listing-slider.component';
import { LazySliderService } from './services/lazy-slider.service';
import { LazySliderComponent } from './lazy-slider/lazy-slider.component';
import { SpotCardPlaceholderComponent } from './cards/spot-card-placeholder/spot-card-placeholder.component';
import { SpotCardTabsComponent } from './spot-card-tabs/spot-card-tabs.component';
import { FavoriteSpotComponent } from './favorite-spot/favorite-spot.component';
import { FavoriteSpotService } from './services/favorite-spot.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationComponent } from './notifications/notifications.component';
import { NotificationService } from './services/notification.service';
import { MoreNotificationsComponent } from './notifications/more-notifications/more-notifications.component';
import { PaginationModule } from 'ngx-bootstrap';
import { SpotSetsComponent } from './spot-sets/spot-sets.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { TourBooksComponent } from './tour-books/tour-books.component';
import { MessageModalComponent } from './message-modal/message-modal.component';
import { MessageModalService } from './services/message-modal.service';
import { DownloadFileService } from './services/downloadFile.service';
import { ShareEmailComponent } from './share-email/share-email.component';
import { EditTourBookComponent } from './edit-tour-book/edit-tour-book.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { SettingsNotificationComponent } from './settings-notification/settings-notification.component';
import { SavedSearchDashboardComponent } from './saved-search-dashboard/saved-search-dashboard.component';
import { HomePageAppService } from './services/homepageapp.service';
import { NewsComponent } from './news/news.component';
import { NewsCardComponent } from './news/news-card/news-card.component';
import { NewsService } from './services/news.service';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    DuploMapModule,
    NgxIntlTelInputModule,
    FontAwesomeModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    ImageCropperModule,
    IonicSelectableModule,
    EditorModule,
    QuillModule.forRoot(),
    InViewportModule,
    MatTooltipModule,
    PortalModule,
    MatTabsModule,
    NgbModule,
    MatSlideToggleModule,
    PaginationModule
  ],
  entryComponents: [
    ContactFormComponent,
    LoginFormComponent,
    PrivacyPolicyDialogComponent,
    PrivacyPolicyReviewComponent,
    CompleteProfileComponent,
    PageNotFoundComponent,
    InstallPromptComponent,
    RegisterFormComponent,
    ForgotFormComponent,
    ChangePasswordComponent,
    ChromeInstallComponent,
    InfoModalComponent,
    SaveChangeModalComponent,
    MyListingMenuComponent,
    MyListingEditComponent,
    AccessGrantedDialogComponent,
    EditCropImageModalComponent,
    MultipleFilesProgressBarComponent,
    AppAcceptCancelModalComponent,
    FullScreenMapWrapperComponent,
    SubscribeModalComponent,
    PreviewImagesComponent,
    EditCropImageSaveModalComponent,
    ImageGalleryComponent,
    ShowImagesComponent,
    SocialMediaComponent,
    FavoriteSpotComponent,
    NotificationComponent,
    MoreNotificationsComponent,
    MessageModalComponent,
    SpotSetsComponent,
    ConfirmDeleteComponent,
    TourBooksComponent,
    ShareEmailComponent,
    EditTourBookComponent,
    SendNotificationComponent,
    SettingsNotificationComponent,
    SavedSearchDashboardComponent,
    NewsComponent,
    NewsCardComponent,
    ScrollToTopComponent
  ],
  declarations: [
    LoaderComponent,
    ContactFormComponent,
    LoginFormComponent,
    PrivacyPolicyDialogComponent,
    PrivacyPolicyReviewComponent,
    CompleteProfileComponent,
    RegisterFormComponent,
    ForgotFormComponent,
    ChangePasswordComponent,
    OurPartnersComponent,
    RowHeaderComponent,
    HeaderComponent,
    FooterComponent,
    FourAllPaymentComponent,
    PageNotFoundComponent,
    InstallPromptComponent,
    ChromeInstallComponent,
    BreadcrumbComponent,
    LocaleChangerDirective,
    LocaleCheckerDirective,
    ImagePreloadDirective,
    ScrollToTopComponent,
    LazyImageDirective,
    ToasterNotificationComponent,
    InfoModalComponent,
    SaveChangeModalComponent,
    MyListingMenuComponent,
    MyListingEditComponent,
    AccessGrantedDialogComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    MultiLanguageComponent,
    IntlLanguageElemComponent,
    NavigationBarComponent,
    VideoComponent,
    SecureDomPipe,
    ScrollingDirective,
    LocalNumberPipe,
    TranslateStringCutter,
    ListingAnalyticsInfoComponent,
    EditCropImageModalComponent,
    MultipleFilesProgressBarComponent,
    EventTrackerDirective,
    DownloadComponent,
    AppTopBannerComponent,
    AppAcceptCancelModalComponent,
    FullScreenMapWrapperComponent,
    SubscribeModalComponent,
    PreviewImagesComponent,
    imageUploadComponent,
    EditCropImageSaveModalComponent,
    ImageGalleryComponent,
    ShowImagesComponent,
    SocialMediaComponent,
    ServicesComponent,
    AppSpotCardSlider,
    ServicesComponent,
    SpotCard,
    AppListingSlider,
    LazySliderComponent,
    SpotCardPlaceholderComponent,
    SpotCardTabsComponent,
    FavoriteSpotComponent,
    NotificationComponent,
    MoreNotificationsComponent,
    SearchFilterPipe,
    MessageModalComponent,
    SpotSetsComponent,
    SearchFilterPipe,
    ConfirmDeleteComponent,
    TourBooksComponent,
    ShareEmailComponent,
    EditTourBookComponent,
    SendNotificationComponent,
    SettingsNotificationComponent,
    SavedSearchDashboardComponent,
    NewsComponent,
    NewsCardComponent
  ],
  exports: [
    LoaderComponent,
    ContactFormComponent,
    LoginFormComponent,
    RegisterFormComponent,
    ForgotFormComponent,
    ChangePasswordComponent,
    RowHeaderComponent,
    OurPartnersComponent,
    HeaderComponent,
    FooterComponent,
    FourAllPaymentComponent,
    PageNotFoundComponent,
    InstallPromptComponent,
    ChromeInstallComponent,
    BreadcrumbComponent,
    LocaleChangerDirective,
    LocaleCheckerDirective,
    ImagePreloadDirective,
    ScrollToTopComponent,
    LazyImageDirective,
    MaterialModule,
    DuploMapModule,
    NgxIntlTelInputModule,
    ImageCropperModule,
    IonicSelectableModule,
    ToasterNotificationComponent,
    EditorModule,
    MyListingMenuComponent,
    MyListingEditComponent,
    AccessGrantedDialogComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    MultiLanguageComponent,
    IntlLanguageElemComponent,
    NavigationBarComponent,
    VideoComponent,
    ScrollingDirective,
    LocalNumberPipe,
    TranslateStringCutter,
    ListingAnalyticsInfoComponent,
    DownloadComponent,
    AppTopBannerComponent,
    AppAcceptCancelModalComponent,
    FullScreenMapWrapperComponent,
    SubscribeModalComponent,
    PreviewImagesComponent,
    imageUploadComponent,
    ImageGalleryComponent,
    ShowImagesComponent,
    SocialMediaComponent,
    ServicesComponent,
    AppSpotCardSlider,
    ServicesComponent,
    SpotCard,
    AppListingSlider,
    LazySliderComponent,
    SpotCardPlaceholderComponent,
    SpotCardTabsComponent,
    FavoriteSpotComponent,
    NotificationComponent,
    MoreNotificationsComponent,
    MessageModalComponent,
    SpotSetsComponent,
    ConfirmDeleteComponent,
    TourBooksComponent,
    ShareEmailComponent,
    EditTourBookComponent,
    SendNotificationComponent,
    SettingsNotificationComponent,
    SavedSearchDashboardComponent,
    NewsComponent,
    NewsCardComponent
  ],
  providers: [
    ContactDialogService,
    PrivacyPolicyService,
    LoginFormDialogService,
    AppAcceptCancelModalService,
    PWAService,
    ImageUploadService,
    LocationService,
    SpotCardSliderService,
    LazySliderService,
    FavoriteSpotService,
    NotificationService,
    MessageModalService,
    DownloadFileService,
    HomePageAppService,
    NewsService
  ]
})
export class SharedModule {}
