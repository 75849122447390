<ion-content>
  <div class="detail-block-background">
    <div style="max-width: 800px; background: white; margin: 0 auto; height: 100%;">
      <app-breadcrumb
        inViewport
        [inViewportOptions]="{ thredhold: [0] }"
        [options]="breadcrumbOptions"
        [standardBackButton]="true"
        [backToPage]="'/'"
      ></app-breadcrumb>
      <ion-row class="flex-dir-icon">
        <ion-col size="12">
          <div class="logo-wrapper">
            <h4 class="main-heading" [translate]="'global.purchase-options.buyYour'"></h4>
            <img class="logo-img" src="assets/SPOT-Full-Logo.png" />
          </div>
          <ion-icon *ngIf="cart?.length" (click)="navigateToCart()" class="absol shop-cart-icon" name="cart"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row class="block-center"> </ion-row>

      <ion-row class="detail-center">
        <ul [ngClass]="{ 'margin-center': !isMobile }">
          <li class="detail-description" translate="global.purchase-options.headingBlockThreee"></li>
          <li class="detail-description" translate="global.purchase-options.headingBlockFour"></li>
        </ul>
        <div class="full-width">
          <p class="purchase-details" translate="global.purchase-options.purchaseDetails"></p>
        </div>
        <div class="margin-center" [ngClass]="{ center: !isMobile, 'full-width': !isMobile }">
          <p class="purchase-heading" translate="global.purchase-options.propertyToList"></p>
        </div>
      </ion-row>

      <ion-row class="padding-bottom-50">
        <button
          (click)="navigateToUpgrade(option)"
          class="listing-btn-height disable-hover"
          *ngFor="let option of listingOptions; let first = first"
        >
          <ion-row>
            <ion-col [size]="isMobile ? 5 : 7" class="purchase-block-4">
              <span class="font-size-14" localize> {{ option.description }}</span>
            </ion-col>

            <ion-col [size]="isMobile ? 7 : 5" class="purchase-block-8">
              <ion-row class="full-width">
                <ion-col class="purchase-option" [size]="isMobile ? 6 : 4">
                  <span class="just-center"> R$ {{ option.listingPricePerMonth | localNumber: '2.0-0' }} </span>
                </ion-col>
                <ion-col class="each-block-col" [size]="isMobile ? 6 : 8">
                  <span class="each-block text-left">
                    <span translate="global.purchase-options.currencyQuarter">{{
                      (first ? 'global.purchase-options.perQuarter' : 'global.purchase-options.currencyQuarter')
                        | translate
                    }}</span>
                  </span>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </button>
      </ion-row>
    </div>
  </div>
</ion-content>
