import { BrokersDTO } from '../bokers.model';
import { BuildingDTO } from '../building.model';
import { CompanyDTO } from '../company.model';
import { IndustrialStatusDTO } from '../industrialStatus.model';

export class CreateSpotBuildingsRequest {
  id: number;
  propertyName: string;
  comments: string;
  building: BuildingDTO;
  offeredByCompany: CompanyDTO;
  brokers: BrokersDTO[];
  type: string;
  featured: boolean;
  enabled: boolean;
  rentableArea: number;
  yearBuilt: string;
  yearRenovated: string;
  numberStories: number;
  ceilingHeight: number;
  industrialStatus: IndustrialStatusDTO;
  parkingSpaces: number;
  carParkingSpaces: number;
  landArea: number;
  averageFloorPrivateArea: number;
  twentyFourHourSecurity: boolean;
  electricGenerator: boolean;
  heliport: boolean;
  sprinklers: boolean;
  leedStatus: boolean;
  singleTenant: boolean;
  multiTenant: boolean;
  bicycleRack: boolean;
  fiberOptic: boolean;
  centralAirConditioning: boolean;
  petFriendly: boolean;
  listingType: string;
  truckParkingSpaces: number;
  numberOfBuildings: number;
  truckYard: boolean;
  crossDocking: boolean;
  sluiceGate: boolean;
  blockAndSteelConstruction: boolean;
  blockConstruction: boolean;
  steelConstruction: boolean;
  address: string;
  neighborhood: string;
  investmentOpportunity?: string;
  entireSale: boolean;
  majorTenants: string;
  capRate: number;
  percentageLeased: number;
  currentOccupancy: number;
  revenue: number;
  startDate: Date;
  endDate: Date;
  lastMonthlyMeeting: Date;
  vrTourUrl: string;
  askingPrice: number;
  area: number;
  docks: number;
  includesOffice: boolean;
  includesRetail: boolean;
  coldStorage: boolean;
  fullBuildOut: boolean;
  loadCapacity: number;
  skyLights: boolean;
  bulletProofCabin: boolean;
  mailPackageHandling: boolean;
  barista: boolean;
  enhancedCleaning: boolean;
  cafeteria: boolean;
  offMarket: boolean;
}
