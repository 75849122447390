import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContactDialogService } from '../contact-dialog/contact-dialog.service';
import { ListPropertyService } from '../../core/listings/list-property.service';
import { Router } from '@angular/router';
import { CommonGenericService } from '@app/core/common.service';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { take } from 'rxjs/operators';
import { LoginFormDialogService } from '../login';
import { AuthenticationService } from '@app/core/auth';
import { Observable } from 'rxjs';
import { I18nService } from '@app/core';
import { environment } from '@env/environment';
import { HomePageAppService } from '../services/homepageapp.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  globalPhoneNumber1: any;
  globalPhoneNumber2: any;
  globalContactEmail: any;

  footerLinks: any[] = [
    {
      label: 'global.search.findYourSPOT',
      navigateTo: () => {
        this.router.navigateByUrl('/search');
      }
    },
    {
      label: 'global.search.propertyListings',
      navigateTo: () => {
        this.$isLoggedIn.pipe(take(1)).subscribe(val => {
          if (val) {
            this.router.navigate([`/list-property`]);
          } else {
            this.loginFormDialog.openDialog('/list-property');
          }
        });
      }
    }
  ];

  contactLink = {
    label: 'global.menu.options.contactUs'
  };

  subscribeLink = {
    label: 'global.newsForm.subList'
  };

  aboutLink = {
    label: 'global.menu.options.silBr'
  };

  termsLink = {
    label: 'global.menu.options.terms'
  };

  privacyPolicyLink = {
    label: 'global.menu.options.privacyPolicy'
  };

  faMapMarkerAlt: any;
  $isLoggedIn: Observable<boolean>;
  solutions: any[] = [];
  constructor(
    private contactDialogService: ContactDialogService,
    private router: Router,
    private commonService: CommonGenericService,
    private loginFormDialog: LoginFormDialogService,
    private auth: AuthenticationService,
    private i18NService: I18nService,
    private homePageAppService: HomePageAppService,
    private routingHelperSrv: RoutingHelperService
  ) {}

  ngOnInit(): void {
    this.globalPhoneNumber1 = this.i18NService.get('global.PhoneNumber1');
    this.globalPhoneNumber2 = this.i18NService.get('global.PhoneNumber2');
    this.globalContactEmail = this.i18NService.get('global.contactEmail');
    this.faMapMarkerAlt = faMapMarkerAlt;
    this.$isLoggedIn = this.auth.isLoggedIn();
    this.solutions = this.homePageAppService.getAllSolutions();
    this.setShortNameAndLinks();
    this.homePageAppService.foundSolutions.subscribe((all: any[]) => {
      this.solutions = all;
      this.setShortNameAndLinks();
    });
  }

  private setShortNameAndLinks() {
    if (this.solutions) {
      for (let solution of this.solutions) {
        solution.urlLink = this.homePageAppService.getSolutionURLByObject(solution);
        solution.nameLink = this.i18NService.getTranslation(solution.shortName);
      }
    }
  }

  ngOnDestroy(): void {}

  presentContactDialog() {
    this.contactDialogService.openDialog();
  }

  presentNewsletterDialog() {}

  dismissContactDialog() {}

  goToSiiLaHomePage() {
    this.commonService.navgiateToSiilaHomePage();
  }

  goToSiilaProductsPage() {
    window.open(this.homePageAppService.getProductsURL(), '_system');
  }

  goToSiilaTermsPage() {
    this.routingHelperSrv.navigateToTermsPage();
  }

  goToSiilaPrivacyPolicyPage() {
    this.routingHelperSrv.navigateToPrivacyPolicyPage();
  }

  siilaHomePageURL() {
    return environment.serverUrl;
  }

  siilaSpotHomePageURL() {
    return environment.spotServerUrl;
  }

  openContactEmail() {
    window.location.href = `mailto:spot@siila.com.br`;
    return false;
  }

  goToHomePage() {
    return this.commonService.goToHomePage();
  }

  goToAllProducts() {
    return this.homePageAppService.getProductsURL();
  }

  goToMarketAnalytics() {
    return this.homePageAppService.getMarketAnalyticsURL();
  }

  goToIndices() {
    return this.homePageAppService.getIndicesURL();
  }

  goToSpot() {
    return this.homePageAppService.getSpotURL();
  }

  goToAcademy() {
    return this.homePageAppService.getAcademyURL();
  }

  goToGrocs() {
    return this.homePageAppService.getGrocsURL();
  }

  goToInvestorSurvey() {
    return this.homePageAppService.getInvestorSurveyURL();
  }

  goToFiiDataPRO() {
    return this.homePageAppService.getFiiDataProURL();
  }

  getInstitutionalInvestorsURL() {
    return this.homePageAppService.getInstitutionalInvestorsURL();
  }

  getOwnersDevelopersURL() {
    return this.homePageAppService.getOwnersDevelopersURL();
  }

  getTenantsOccupiersURL() {
    return this.homePageAppService.getTenantsOccupiersURL();
  }

  getBrokerageFirmsURL() {
    return this.homePageAppService.getBrokerageFirmsURL();
  }

  getValuationAdvisorsURL() {
    return this.homePageAppService.getValuationAdvisorsURL();
  }

  getServiceProvidersURL() {
    return this.homePageAppService.getServiceProvidersURL();
  }

  getReitInvestorsURL() {
    return this.homePageAppService.getReitInvestorsURL();
  }
}
