<form [formGroup]="listingFeaturesForm" class="full-width" name="listingFeaturesForm" autocomplete="off">
  <!--Industrial -->
  <ion-label class="full-width">{{ 'global.list-your-property.features' | translate }} </ion-label>

  <ion-row
    class="full-width margin-top-5"
    *ngIf="
      listingTypeForm.controls.commericalType.value === 1001 || listingTypeForm.controls.commericalType.value === '1001'
    "
  >
    <ion-col size="6">
      <ion-label class="full-width">{{ 'global.list-your-property.ceilingHeight' | translate }} (m) </ion-label>
    </ion-col>
    <ion-col size="6">
      <ion-label class="full-width">{{ 'global.list-your-property.carParkingSpaces' | translate }} </ion-label>
    </ion-col>

    <ion-col size="6">
      <ion-input
        class="input-border"
        id="ceilingHeight"
        name="ceilingHeight"
        type="tel"
        inputmode="decimal"
        (ionBlur)="formatHeight()"
        (ionChange)="checkCharacters($event, true)"
        formControlName="ceilingHeight"
      >
      </ion-input>
    </ion-col>

    <ion-col size="6">
      <ion-input
        class="input-border"
        id="carParkingSpaces"
        name="carParkingSpaces"
        type="tel"
        (ionChange)="checkCharacters($event, false)"
        formControlName="carParkingSpaces"
      >
      </ion-input>
    </ion-col>

    <ion-col size="6">
      <ion-label class="full-width">{{ 'global.list-your-property.truckParkingSpaces' | translate }} </ion-label>
    </ion-col>

    <ion-col size="6">
      <ion-label class="full-width">{{ 'global.list-your-property.dockRatio' | translate }} </ion-label>
    </ion-col>

    <ion-col size="6">
      <ion-input
        class="input-border"
        id="truckParkingSpaces"
        name="truckParkingSpaces"
        type="tel"
        (ionChange)="checkCharacters($event, false)"
        formControlName="truckParkingSpaces"
      >
      </ion-input>
    </ion-col>

    <ion-col size="6">
      <ion-input
        (ionBlur)="formatDockRatio()"
        class="input-border"
        id="dockRatio"
        inputmode="decimal"
        (ionChange)="checkCharacters($event, true)"
        name="dockRatio"
        type="tel"
        formControlName="dockRatio"
      >
      </ion-input>
    </ion-col>

    <ion-col size="6">
      <ion-label class="full-width">{{ 'global.list-your-property.loadCapacity' | translate }} </ion-label>
    </ion-col>

    <ion-col size="6">
      <ion-label class="full-width"> </ion-label>
    </ion-col>

    <ion-col size="6">
      <ion-input
        class="input-border"
        id="loadCapacity"
        (ionChange)="checkCharacters($event, false)"
        name="loadCapacity"
        type="tel"
        formControlName="loadCapacity"
      >
      </ion-input>
    </ion-col>
  </ion-row>

  <!-- Office -->
  <ion-row
    class="full-width margin-top-5"
    *ngIf="
      listingTypeForm.controls.commericalType.value === '2001' || listingTypeForm.controls.commericalType.value === 2001
    "
  >
    <ion-col size="6">
      <ion-label class="full-width">{{ 'global.list-your-property.ceilingHeight' | translate }} (m) </ion-label>
      <ion-input
        class="input-border"
        id="ceilingHeight"
        name="ceilingHeight"
        type="tel"
        inputmode="decimal"
        (ionChange)="checkCharacters($event, true)"
        (ionBlur)="formatHeight()"
        formControlName="ceilingHeight"
      >
      </ion-input>
    </ion-col>

    <ion-col size="6">
      <ion-label class="full-width">{{ 'global.list-your-property.parkingSpaces' | translate }} </ion-label>
      <ion-input
        class="input-border"
        id="parkingSpaces"
        name="parkingSpaces"
        type="tel"
        (ionChange)="checkCharacters($event, false)"
        formControlName="parkingSpaces"
      >
      </ion-input>
    </ion-col>
  </ion-row>

  <ion-label class="full-width">{{ 'global.list-your-property.otherFeatures' | translate }} </ion-label>
  <ion-row class="full-width margin-top-5" style="margin-top: 10px;">
    <ion-col
      size="12"
      *ngIf="
        listingTypeForm.controls.commericalType.value === 1001 ||
        listingTypeForm.controls.commericalType.value === '1001'
      "
    >
      <ion-row class="full-width">
        <ion-col size="6" *ngFor="let tag of industrialFilters; let i = index">
          <button [ngClass]="{ 'sub-type': tag.selected }" class="selected-white" (click)="setIndustrialTypes(i)">
            {{ tag.name | translate }}
          </button>
        </ion-col>
      </ion-row>
    </ion-col>

    <ion-col
      size="12"
      *ngIf="
        listingTypeForm.controls.commericalType.value === 2001 ||
        listingTypeForm.controls.commericalType.value === '2001'
      "
    >
      <ion-row class="full-width">
        <ion-col size="6" *ngFor="let tag of officeFilters; let i = index">
          <button [ngClass]="{ 'sub-type': tag.selected }" class="selected-white" (click)="setOfficeTypes(i)">
            {{ tag.name | translate }}
          </button>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</form>
