<ion-toolbar class="stepperTop toolbar" [ngStyle]="cssToolbar()">
  <!-- Mobile -->
  <ion-buttons *ngIf="isMobile">
    <div class="col-md-12" style="display: flex;">
      <div style="width: 20%;">
        <ion-button style="float: left;" class="back-button" ion-button icon-only (click)="goBack()">
          <ion-icon color="primary" style="--ion-color-base: #0D6EFD !important;" name="md-arrow-back" size="medium">
          </ion-icon>
        </ion-button>
      </div>
      <div style="width: 80%;float: left;">
        <div style="width: 100%;text-align: center;">
          <span style=" font-size: 19px; font-weight: 700;">{{ buildingDetail.propertyName }}</span>
        </div>
        <div style="width: 100%;text-align: center;">
          <span style="font-size: 12px;color: #00334D;font-weight: 400;">{{ buildingDetail.address }}</span>
        </div>
        <div style="width: 100%;text-align: center;">
          <span
            style="font-size: 12px;color: #00334D;font-weight: 400;"
            *ngIf="!buildingDetail.offMarket && (buildingDetail.type == 'L' || buildingDetail.type == 'C')"
            >{{ getAskingRent() }}</span
          >
          <span
            style="font-size: 12px;color: #00334D;font-weight: 400;"
            *ngIf="buildingDetail.offMarket && buildingDetail.type == 'L'"
            >Negotiable</span
          >
          <span style="font-size: 12px;color: #00334D;font-weight: 400;" *ngIf="buildingDetail.type == 'S'">{{
            getAskingPrice()
          }}</span>
        </div>
      </div>
      <div style="width: 20%;float: left;text-align: center;display: inline-flex;justify-content: center;">
        <app-favorite-spot [spot]="buildingDetail" [size]="favSizeIcon"></app-favorite-spot>
      </div>
    </div>
  </ion-buttons>

  <!-- Desktop -->
  <ion-buttons *ngIf="!isMobile" slot="start" style="width: 100%;">
    <div class="col-md-12">
      <div class="col-md-2 padding-0" style="float: left;">
        <ion-button style="margin-top: 24px;" class="back-button" ion-button icon-only (click)="goBack()">
          <ion-icon color="primary" style="--ion-color-base: #0D6EFD !important;" name="md-arrow-back" size="medium">
          </ion-icon>
          <span class="back-button" style="color: #0D6EFD;">{{ 'global.menu.search' | translate }}</span>
        </ion-button>
      </div>
      <div *ngIf="buildingDetail" class="col-md-8" style="float: left;">
        <div class="col-md-12" style="text-align: center;">
          <span>
            <h1 style="font-size: 30px; margin: 0; font-weight: 600;">{{ buildingDetail.propertyName }}</h1>
          </span>
          <br />
        </div>
        <ion-row class="color-span" style="margin-top:0px;margin-bottom: 7px; text-align: center;">
          <ion-col class="boderCol">
            <span>{{ buildingDetail.building.buildingType.name | translate }}</span>
            <span>{{ propertyHelperService.getSpotBuildingTypeLongTranslation(buildingDetail.type) | translate }}</span>
          </ion-col>
          <ion-col
            class="boderCol"
            style="white-space: nowrap;
          padding: 5px 13px 0px 13px;"
          >
            <span> {{ buildingDetail.address }}</span>
          </ion-col>
          <ion-col style="margin-left: 20px;">
            <span *ngIf="!buildingDetail.offMarket && (buildingDetail.type == 'L' || buildingDetail.type == 'C')">{{
              getAskingRent()
            }}</span>
            <span *ngIf="buildingDetail.offMarket && buildingDetail.type == 'L'">Negotiable</span>
            <span *ngIf="buildingDetail.type == 'S'">{{ getAskingPrice() }}</span>
          </ion-col>
        </ion-row>
      </div>

      <div id="favContainer" class="col-md-2" style="margin-top: 24px;display: inline-flex;justify-content: flex-end;">
        <app-favorite-spot [spot]="buildingDetail" [size]="favSizeIcon" includeLabel="true"></app-favorite-spot>
      </div>
    </div>
  </ion-buttons>
</ion-toolbar>

<div class="detail-height" style="background-color: rgb(255,255,255);">
  <!-- Mobile -->
  <ion-row *ngIf="isMobile" class="max-width-mobile">
    <ion-col>
      <ion-card class="max-width-mobile ion-listing-card detail-ion-card standard-shadow card-position">
        <ion-card-content *ngIf="buildingDetail" class="card-content-default px-2">
          <app-show-images
            [topImage]="'30%'"
            [listingType]="buildingDetail.listingType"
            [SpotPhotos]="SpotPhotos"
            [entireSale]="buildingDetail.entireSale"
            [propertyName]="buildingDetail.propertyName"
            [description]="buildingDetail.address"
          >
          </app-show-images>

          <ion-row class="rowTypes">
            <div class="range-mobile">
              <ion-label *ngIf="buildingDetail.entireSale">
                {{ 'buildinsDetail.areaForSale' | translate }} m<sup>2</sup></ion-label
              >
              <ion-label *ngIf="!buildingDetail.entireSale && buildingDetail.type != 'C'">
                {{ 'buildinsDetail.availableSpaceRange' | translate }}
              </ion-label>
              <ion-label *ngIf="buildingDetail.type == 'C' && getPositions() != 'N/A'">
                {{ 'spaceDetail.availablePositions' | translate }}</ion-label
              >
              <ion-label *ngIf="buildingDetail.type == 'C' && getPositions() == 'N/A'">
                {{ 'buildinsDetail.neighborhood' | translate }}</ion-label
              >
              <br />
              <span *ngIf="buildingDetail.entireSale && buildingDetail.type != 'C'">{{ getAvailableAreaText() }} </span>
              <span *ngIf="!buildingDetail.entireSale && buildingDetail.type != 'C'"
                >{{ getAvailableAreaText() }}
              </span>
              <span *ngIf="buildingDetail.type == 'C' && getPositions() != 'N/A'">{{ getPositions() }}</span>
              <span *ngIf="buildingDetail.type == 'C' && getPositions() == 'N/A'">{{
                buildingDetail.neighborhood
              }}</span>
            </div>
            <div class="range-mobile" style=" margin-top: 10px;">
              <ion-label *ngIf="buildingDetail.type == 'L'">
                {{ 'buildinsDetail.monthlyRentRange' | translate }}</ion-label
              >
              <ion-label *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale">
                {{ 'buildinsDetail.RangeAskingPrices' | translate }} R/m<sup>2</sup></ion-label
              >

              <ion-label *ngIf="buildingDetail.type == 'S' && buildingDetail.entireSale">
                {{ 'buildinsDetail.askingPrice' | translate }}/m<sup>2</sup></ion-label
              >
              <ion-label *ngIf="buildingDetail.type == 'C'">
                {{ 'buildinsDetail.monthlyCostPerson' | translate }}</ion-label
              >

              <br />
              <span *ngIf="buildingDetail.type == 'L'">{{ getAskingRentPerArea() }}</span>
              <span *ngIf="buildingDetail.type == 'S'">{{ getAskingPricePerArea() }} </span>

              <span *ngIf="buildingDetail.type == 'C'">{{ getMonthlyRentPerPerson() }}</span>
            </div>
            <div style="width: 100%;">
              <button
                class="btn-color-1 btn-border"
                (click)="openVideoTour($event)"
                style="margin-top: 18px;width: 100%;"
                *ngIf="this.buildingDetail.vrTourUrl"
              >
                {{ 'buildinsDetail.virtualTour' | translate }}
              </button>
              <button
                class="btn-color-1 btn-border"
                (click)="shareCompany($event)"
                style="margin-top: 5px;width: 100%;"
              >
                <fa-icon class="shared-icon" [icon]="sharedIcon" size="md"></fa-icon>
                {{ 'buildinsDetail.share' | translate }}
              </button>
              <ion-button
                class="btn-color-2"
                (click)="requestTourAction($event)"
                style="margin-top: 5px;
              width: 100%;"
              >
                {{ 'buildinsDetail.requestTour' | translate }}
              </ion-button>
            </div>
          </ion-row>

          <ion-row class="row-border-bottom marginRow"></ion-row>

          <ion-row class="row padding-0" style="margin-bottom: 30px;">
            <div
              *ngIf="
                buildingDetail.entireSale &&
                (investmentOpportunity ||
                  capRate !== '0.00' ||
                  percentageLeased !== '0.00' ||
                  (buildingDetail.majorTenants && buildingDetail.majorTenants.length > 0))
              "
              class="flex-col-nowrap col-md-12"
            >
              <div class="margin-top-20" *ngIf="investmentOpportunity">
                <ion-label class="titleSize-mobile black-color-col"
                  >{{ 'spaceDetail.investmentOpportunity' | translate }}
                </ion-label>
              </div>
              <div class="full-width" style="margin-top: 32px; margin-bottom: 15px;" *ngIf="investmentOpportunity">
                <span class="investment__opportunity" [innerHTML]="investmentOpportunity"></span>
              </div>
              <div
                style="border-top: 1px solid #00334d;
              border-bottom: 1px solid #00334d;"
                *ngIf="
                  capRate !== '0.00' ||
                  percentageLeased !== '0.00' ||
                  (buildingDetail.majorTenants && buildingDetail.majorTenants.length > 0)
                "
              >
                <div
                  class="entire__sale_data__itemcontainer"
                  *ngIf="capRate !== '0.00'"
                  style="margin-top: 5px;
                margin-bottom: 5px;"
                >
                  <div class="item_icon">
                    <img src="assets/images/icons/cap_rate.svg" />
                  </div>
                  <div class="data__info">
                    <div>
                      <span class="data__info__title">{{ 'buildinsDetail.capRate' | translate }}</span>
                      <span class="data__info__value">{{ capRate }}%</span>
                    </div>
                  </div>
                </div>
                <div
                  class="entire__sale_data__itemcontainer"
                  *ngIf="percentageLeased !== '0.00'"
                  style="margin-top: 5px;
                margin-bottom: 5px;"
                >
                  <div class="item_icon">
                    <img src="assets/images/icons/percent_leased.svg" />
                  </div>
                  <div class="data__info">
                    <div>
                      <span class="data__info__title">{{ 'buildinsDetail.percentLeased' | translate }}</span>
                      <span class="data__info__value">{{ percentageLeased }}%</span>
                    </div>
                  </div>
                </div>
                <div
                  class="entire__sale_data__itemcontainer"
                  style="min-width: 270px; margin-top: 5px;
                margin-bottom: 5px;"
                  *ngIf="buildingDetail.majorTenants && buildingDetail.majorTenants.length > 0"
                >
                  <div class="item_icon">
                    <img src="assets/images/icons/major_tenants.svg" />
                  </div>
                  <div class="data__info">
                    <div>
                      <span class="data__info__title">{{ 'buildinsDetail.majorTenants' | translate }}</span>
                      <span *ngFor="let tenant of majorTenants" class="data__info__value">{{ tenant }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="margin-top-20 col-md-12" [ngClass]="{ 'margin-top-48': buildingDetail.entireSale }">
              <ion-label class="titleSize-mobile black-color-col"
                >{{ 'buildinsDetail.buildingDescription' | translate }}
              </ion-label>
              <ion-button *ngIf="isAdmin" class="btn-color-2" (click)="editBuilding()" style="margin-top: -11px;">
                Edit
              </ion-button>
              <br />
              <p class="margin-top-20" [innerHtml]="description" style="color: #000000;"></p>
            </div>

            <div class="margin-top-20 col-md-12">
              <ion-label class="titleSize-mobile black-color-col"
                >{{ 'buildinsDetail.propertyDetails' | translate }}
              </ion-label>
              <br />

              <ion-row class="margin-top-20" style="border: 1px solid #E0E2E7;border-radius: 4px;">
                <ion-col class="keyFacts" size="12">{{ 'global.keyFacts' | translate }} </ion-col>

                <ion-col size="6" *ngIf="buildingDetail.neighborhood">
                  <ion-label class="label-color">{{ 'buildinsDetail.neighborhood' | translate }}</ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.neighborhood" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.neighborhood }}</b>
                  </ion-label>
                </ion-col>
                <ion-col
                  size="6"
                  *ngIf="buildingDetail.building.buildingSubType && buildingDetail.building.buildingType.id == 1001"
                >
                  <ion-label class="label-color">{{ 'buildinsDetail.propertySubType' | translate }}</ion-label>
                </ion-col>
                <ion-col
                  size="6"
                  *ngIf="buildingDetail.building.buildingSubType && buildingDetail.building.buildingType.id == 1001"
                  class="colDetail1"
                >
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.building.buildingSubType.name }}</b>
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.ceilingHeight">
                  <ion-label class="label-color"
                    >{{ 'global.list-your-property.ceilingHeightAvg' | translate }}
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.ceilingHeight" class="colDetail1">
                  <ion-label class="label-color2"
                    ><b>{{ buildingDetail.ceilingHeight }} m</b>
                  </ion-label>
                </ion-col>

                <ion-col size="6" *ngIf="buildingDetail.rentableArea">
                  <ion-label class="label-color">{{ 'global.list-your-property.propertySize' | translate }} </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.rentableArea" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.rentableArea | localNumber: '1.0-0' }} m<sup>2</sup></b>
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.building.industrialStatus">
                  <ion-label class="label-color"
                    >{{ 'global.list-your-property.constructionStatus' | translate }}
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.building.industrialStatus" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ i18NService.getTranslation(buildingDetail.building.industrialStatus.name) }}</b>
                  </ion-label>
                </ion-col>

                <ion-col size="6" *ngIf="buildingDetail.yearBuilt">
                  <ion-label class="label-color">{{ 'global.list-your-property.yearBuilt' | translate }} </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.yearBuilt" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.yearBuilt }}</b>
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.parkingSpaces">
                  <ion-label class="label-color"
                    >{{ 'global.list-your-property.NumberParkingSpaces' | translate }}
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.parkingSpaces" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.parkingSpaces }}</b>
                  </ion-label>
                </ion-col>

                <ion-col size="6" *ngIf="buildingDetail.yearRenovated">
                  <ion-label class="label-color"
                    >{{ 'global.list-your-property.yearRenovated' | translate }}
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.yearRenovated" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.yearRenovated }}</b>
                  </ion-label>
                </ion-col>
                <ion-col
                  size="6"
                  *ngIf="buildingDetail.averageFloorPrivateArea && buildingDetail.building.buildingType.id == 2001"
                >
                  <ion-label class="label-color"
                    >{{ 'global.list-your-property.averageFloorPrivateArea' | translate }}
                  </ion-label>
                </ion-col>
                <ion-col
                  size="6"
                  *ngIf="buildingDetail.averageFloorPrivateArea && buildingDetail.building.buildingType.id == 2001"
                  class="colDetail1"
                >
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.averageFloorPrivateArea | localNumber: '1.0-0' }} m<sup>2</sup></b>
                  </ion-label>
                </ion-col>

                <ion-col size="6" *ngIf="buildingDetail.numberStories">
                  <ion-label class="label-color">{{ 'global.list-your-property.floors' | translate }} </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.numberStories" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.numberStories }}</b>
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.landArea">
                  <ion-label class="label-color">{{ 'global.list-your-property.totalLanArea' | translate }} </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.landArea" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.landArea | localNumber: '1.0-0' }} m<sup>2</sup></b>
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.truckParkingSpaces">
                  <ion-label class="label-color"
                    >{{ 'global.list-your-property.numberTruckParking' | translate }}
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.truckParkingSpaces" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.truckParkingSpaces }}</b>
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.numberOfBuildings">
                  <ion-label class="label-color"
                    >{{ 'global.list-your-property.numberBuildings' | translate }}
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.numberOfBuildings" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.numberOfBuildings }}</b>
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.carParkingSpaces">
                  <ion-label class="label-color"
                    >{{ 'global.list-your-property.NumberCarParkingSpaces' | translate }}
                  </ion-label>
                </ion-col>
                <ion-col size="6" *ngIf="buildingDetail.carParkingSpaces" class="colDetail1">
                  <ion-label class="label-color2">
                    <b>{{ buildingDetail.carParkingSpaces }}</b>
                  </ion-label>
                </ion-col>
              </ion-row>
            </div>
            <ion-row class="col-md-12">
              <ion-col size="12" style="text-align: end;">
                <ion-label class="date"
                  >{{ 'global.dateCreated' | translate }}: {{ buildingDetail.createdDate }}
                </ion-label>
              </ion-col>
              <ion-col size="12" style="text-align: end;">
                <ion-label class="date"
                  >{{ 'global.modifiedDate' | translate }}:
                  {{ getDateFormatFrom(buildingDetail.modifiedDate) }}</ion-label
                >
              </ion-col>
            </ion-row>

            <div class="col-md-12" style="margin-top: 10;">
              <ion-label class="titleSize-mobile black-color-col"
                >{{ 'buildinsDetail.buildingFeatures' | translate }}
              </ion-label>
              <br />

              <div class="margin-top-20" [ngClass]="{ 'show-less': showShortDesciption }">
                <ion-row style="font-weight: 600;line-height: 24px;">
                  <ion-col size="12" *ngIf="buildingDetail.twentyFourHourSecurity">
                    <img class="imgFeatures" src="assets/images/icons/security.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.twentyFourHourSecurity' | translate }} </ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.bulletProofCabin">
                    <img class="imgFeatures" src="assets/images/icons/Truck Yard.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.bulletProofCabin' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.sluiceGate">
                    <img class="imgFeatures" src="assets/images/icons/Sluice-Gate.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.sluiceGate' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.sprinklers">
                    <img class="imgFeatures" src="assets/images/icons/highlight.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.sprinklers' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.electricGenerator">
                    <img class="imgFeatures" src="assets/images/icons/electrical_services.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.electricGenerator' | translate }} </ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.leedStatus">
                    <img class="imgFeatures" src="assets/images/icons/eco.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.sustentability' | translate }} </ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.skyLights">
                    <img class="imgFeatures" src="assets/images/icons/sky_lights.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.skyLights' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.heliport">
                    <img class="imgFeatures" src="assets/images/icons/Heliport.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.heliport' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.truckYard">
                    <img class="imgFeatures" src="assets/images/icons/Truck Yard.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.truckYard' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.singleTenant">
                    <img class="imgFeatures" src="assets/images/icons/person.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.singleTenant' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.crossDocking">
                    <img class="imgFeatures" src="assets/images/icons/Cross-Docking.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.crossDocking' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.blockAndSteelConstruction">
                    <img class="imgFeatures" src="assets/images/icons/Block & Steel Construction.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.blockAndSteelConstruction' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.bicycleRack">
                    <img class="imgFeatures" src="assets/images/icons/pedal_bike.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.bicycleRack' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.fiberOptic">
                    <img class="imgFeatures" src="assets/images/icons/sky_lights.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.fiberOptic' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.blockConstruction">
                    <img class="imgFeatures" src="assets/images/icons/Block & Steel Construction.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.blockConstruction' | translate }} </ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.centralAirConditioning">
                    <img class="imgFeatures" src="assets/images/icons/ac_unit.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.centralAirConditioning' | translate }} </ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.multiTenant">
                    <img class="imgFeatures" src="assets/images/icons/multi_tenant.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.multiTenant' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.petFriendly">
                    <img class="imgFeatures" src="assets/images/icons/pets.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.petFriendly' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.steelConstruction">
                    <img class="imgFeatures" src="assets/images/icons/Block & Steel Construction.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.steelConstruction' | translate }} </ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.includesOffice">
                    <img class="imgFeatures" src="assets/images/icons/includes_office.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.includesOffice' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.includesRetail">
                    <img class="imgFeatures" src="assets/images/icons/includes_retail.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.includesRetail' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.coldStorage">
                    <img class="imgFeatures" src="assets/images/icons/cold_storage.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.coldStorage' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.fullBuildOut">
                    <img class="imgFeatures" src="assets/images/icons/full_builld_out.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.fullBuildOut' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.mailPackageHandling">
                    <img class="imgFeatures" src="assets/images/icons/markunread_mailbox.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.mailAndPackage' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.barista">
                    <img class="imgFeatures" src="assets/images/icons/local_cafe.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.barista' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.enhancedCleaning">
                    <img class="imgFeatures" src="assets/images/icons/cleaning_services.svg" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.enhancedCleaning' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="12" *ngIf="buildingDetail.cafeteria">
                    <img class="imgFeatures" src="assets/images/icons/cafeteria.png" alt="" />
                    <ion-label>{{ 'global.addtSearchFilters.cafeteria' | translate }}</ion-label>
                  </ion-col>
                </ion-row>
              </div>

              <a
                [hidden]="hiddeMoreFeatures"
                *ngIf="showShortDesciption"
                style="color: #0D6EFD;"
                type="button"
                (click)="alterDescriptionText()"
              >
                {{ 'buildinsDetail.seeMore' | translate }}
              </a>
              <a *ngIf="!showShortDesciption" style="color: #0D6EFD;" type="button" (click)="alterDescriptionText()">
                {{ 'buildinsDetail.seeLess' | translate }}
              </a>
            </div>

            <div class="margin-top-20 listing-map col-md-12">
              <ion-label class="titleSize-mobile black-color-col"
                >{{ 'buildinsDetail.propertyLocation' | translate }}
              </ion-label>
              <br />
              <br />
              <app-full-screen-map-wrapper>
                <app-duplomap *ngIf="markers.length" [markers]="markers" [options]="mapOptions"> </app-duplomap>
              </app-full-screen-map-wrapper>
            </div>
          </ion-row>

          <ion-row class="row-border-bottom marginRow" style="padding-top: 54px;"></ion-row>

          <ion-row *ngIf="buildingDetail.offMarket && buildingDetail.type != 'S'">
            <div style="width: 100%;">
              <div class="col-md-12" style="padding-left: 0px;">
                <h2>
                  <ion-label class="black-color-col titleSize-mobile" style="font-size: 24px;">{{
                    'buildinsDetail.searchParameters' | translate
                  }}</ion-label>
                </h2>
              </div>

              <div>
                <button class="btn-review" style="margin-left: 16%;margin-top: 148px;" (click)="goToMarketAnalytics()">
                  <img
                    style="width: 14px;float: left;margin-top: 4px;"
                    src="assets/images/icons/lockedWhite.png"
                    alt=""
                  />
                  <p style="margin-top: 2px;">{{ 'buildinsDetail.offLeased' | translate }}</p>
                </button>
                <tbody class="table blur" style="display: block;border: 1px solid #CED4DA;border-radius: 6px;">
                  <tr style="display: block;">
                    <td></td>
                    <td>
                      <span> {{ 'buildinsDetail.wareHouse' | translate }}</span> 2
                    </td>
                    <td>
                      <span> {{ 'buildinsDetail.module' | translate }}</span
                      >4
                    </td>
                    <td>
                      <span>{{ 'buildinsDetail.monthlyRentNoSymbols' | translate }}</span> Negotiable
                    </td>
                    <td>
                      <span>{{ 'buildinsDetail.availableSpaceNoSymbols' | translate }}</span
                      >222 m²
                    </td>
                    <td>
                      <span> {{ 'buildinsDetail.monthlyRentNoSymbols' | translate }}</span>
                      Negotiable
                    </td>
                    <td>
                      <span>{{ 'buildinsDetail.condition' | translate }}</span>
                      Flex
                    </td>
                    <td>
                      <span>{{ 'buildinsDetail.term' | translate }}</span>
                      2 {{ 'Years' | translate }}
                    </td>
                    <td>
                      <span>{{ 'buildinsDetail.listingAgent' | translate }}</span>
                      <span style="color: #0D6EFD;float: revert;">VERA CRUZ REAL ESTATE</span>
                    </td>
                    <td>
                      <span>{{ 'buildinsDetail.availability' | translate }}</span>
                      Now
                    </td>
                    <td>
                      <ion-button style="text-transform: none !important;" class="btn-color-2 see-more-space">
                        <span style="text-transform: none !important;">{{ 'buildinsDetail.seeMore' | translate }}</span>
                      </ion-button>
                    </td>
                  </tr>
                </tbody>
              </div>
            </div>
          </ion-row>

          <ion-row *ngIf="!buildingDetail.offMarket">
            <div
              *ngIf="buildingDetail.type == 'C' ? spacesCoworking.length > 0 : spacesParameters"
              style="width: 100%;"
            >
              <div class="col-md-12" style="padding-left: 0px;">
                <h2>
                  <ion-label class="black-color-col titleSize-mobile" style="font-size: 24px;">{{
                    'buildinsDetail.searchParameters' | translate
                  }}</ion-label>
                </h2>
              </div>

              <div class="row">
                <div class="col-md-12 pt-3" style="text-align: center;">
                  <span style="color: #6C757D;font-size: 18px;font-weight: 600;"
                    >{{ 'buildinsDetail.setSpacesTitle' | translate }} ({{ countSapces }})</span
                  >
                </div>
                <div class="col-md-12 pt-3" style="text-align: center;">
                  <div class="col-md-6" style="margin-bottom: 10px;padding: 0px;">
                    <button class="btnSpaces" (click)="openReportSpaces()" style="width: 100%;">
                      <img src="assets/images/icons/reports.png" class="spacesIcon" />{{
                        'buildinsDetail.spacesRepot' | translate
                      }}
                    </button>
                  </div>
                  <div class="col-md-6" style="margin-bottom: 10px;padding: 0px;">
                    <button
                      [disabled]="countSapces == 0"
                      class="btnSpaces"
                      (click)="openSetSpaces()"
                      style="width: 100%;"
                    >
                      <img *ngIf="countSapces != 0" src="assets/images/icons/setSpot.svg" class="spacesIconSvg" />
                      <img
                        *ngIf="countSapces == 0"
                        src="assets/images/icons/apartment48.svg"
                        class="spacesIconSvg-disabled"
                      />
                      <span style="margin-left: 26px;">{{ 'buildinsDetail.spacesSetSpot' | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                *ngIf="spacesParameters && buildingDetail.type == 'C'"
                class="col-md-12 flex_coworking_mobile"
                style="padding-left: 0px;margin-top: 20px;"
              >
                <div *ngIf="filter3" class="col-md-3" style="float: left;">
                  <div
                    class="coworking_filter width_coworking_mobile"
                    (click)="coworkingFilter(3)"
                    [ngClass]="{ coworking_filter2: filter == 3 }"
                  >
                    <div class="col-md-12">
                      <img src="assets/images/openWorkspace.png" alt="" />
                    </div>
                    <div class="col-md-12 text-center">
                      <span>{{ 'buildinsDetail.openWorkspace' | translate }}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="filter1" class="col-md-3" style="float: left;">
                  <div
                    class="coworking_filter width_coworking_mobile"
                    (click)="coworkingFilter(1)"
                    [ngClass]="{ coworking_filter2: filter == 1 }"
                  >
                    <div class="col-md-12">
                      <img src="assets/images/privateOffice.png" alt="" />
                    </div>
                    <div class="col-md-12 text-center">
                      <span>{{ 'buildinsDetail.privateOffice' | translate }} </span>
                    </div>
                  </div>
                </div>
                <div *ngIf="filter2" class="col-md-3" style="float: left;">
                  <div
                    class="coworking_filter width_coworking_mobile"
                    (click)="coworkingFilter(2)"
                    [ngClass]="{ coworking_filter2: filter == 2 }"
                  >
                    <div class="col-md-12">
                      <img src="assets/images/delicatedWorkspace.png" alt="" />
                    </div>
                    <div class="col-md-12 text-center">
                      <span>{{ 'buildinsDetail.dedicatedWorkspace' | translate }} </span>
                    </div>
                  </div>
                </div>

                <div *ngIf="filter4" class="col-md-3" style="float: left;">
                  <div
                    class="coworking_filter width_coworking_mobile"
                    (click)="coworkingFilter(4)"
                    [ngClass]="{ coworking_filter2: filter == 4 }"
                  >
                    <div class="col-md-12">
                      <img src="assets/images/fullFloorOffices.png" alt="" />
                    </div>
                    <div class="col-md-12 text-center">
                      <span>{{ 'buildinsDetail.fullFloorOffices' | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div [ngClass]="{ 'show-less-space': showShortSpace }">
                <tbody
                  class="table"
                  *ngFor="
                    let parameters of buildingDetail.type == 'C' ? spacesCoworking : spacesParameters;
                    let i = index
                  "
                  style="display: block;border: 1px solid #CED4DA;border-radius: 6px;"
                >
                  <tr style="display: block;">
                    <td>
                      <input
                        type="checkbox"
                        [(ngModel)]="parameters.select"
                        (change)="countSpaces(parameters)"
                        [checked]="parameters.select"
                        style="margin-top: 2px;"
                      />
                      <span>{{ 'buildinsDetail.select' | translate }}</span>
                    </td>
                    <td
                      *ngIf="buildingDetail.type == 'C' && filter != 4 && !buildingDetail.entireSale"
                      style="    text-align: end;"
                    >
                      <span style="float: left;"> {{ 'buildinsDetail.coworkingType' | translate }}</span
                      >{{ i18NService.getTranslation(parameters.coworkingType) | translate }}
                    </td>
                    <td
                      *ngIf="buildingDetail.building.buildingType.id == 2001 && !buildingDetail.entireSale"
                      style="    text-align: end;"
                    >
                      <span style="float: left;"> {{ 'buildinsDetail.floor' | translate }}</span
                      >{{ parameters.floor }}
                    </td>
                    <td
                      [ngStyle]="{ display: !parameters.suite ? 'flex' : '' }"
                      *ngIf="buildingDetail.building.buildingType.id == 2001 && seeSuit && !buildingDetail.entireSale"
                    >
                      <span>{{ 'buildinsDetail.suite' | translate }}</span
                      >{{ parameters.suite }}
                    </td>
                    <td *ngIf="buildingDetail.building.buildingType.id == 1001 && !buildingDetail.entireSale">
                      <span> {{ 'buildinsDetail.wareHouse' | translate }}</span
                      >{{ parameters.warehouse }}
                    </td>
                    <td *ngIf="buildingDetail.building.buildingType.id == 1001 && !buildingDetail.entireSale">
                      <span> {{ 'buildinsDetail.module' | translate }}</span
                      >{{ parameters.module }}
                    </td>
                    <td *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale">
                      <span>{{ 'buildinsDetail.monthlyRentM' | translate }}</span>
                      {{ validateDecimal(parameters.monthlyAskingRent / parameters.area) }}
                    </td>
                    <td *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale">
                      <span>{{ 'buildinsDetail.availableSpace' | translate }}</span
                      >{{ parameters.area | localNumber: '1.0-0' }} m²
                    </td>
                    <td *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale">
                      <span> {{ 'buildinsDetail.monthlyRent' | translate }}</span>

                      {{ validateDecimal(parameters.monthlyAskingRent) }}
                    </td>
                    <td *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale">
                      <span>{{ 'buildinsDetail.condition' | translate }}</span>
                      {{ parameters.spaceCondition ? i18NService.getTranslation(parameters.spaceCondition.name) : '' }}
                    </td>
                    <td *ngIf="buildingDetail.type == 'L' && parameters.term > 1 && !buildingDetail.entireSale">
                      <span>{{ 'buildinsDetail.term' | translate }}</span>
                      {{ getTermsTxt(parameters.term) }}
                    </td>
                    <td *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale">
                      <span>{{ 'buildinsDetail.askingPrice' | translate }}</span>
                      {{ validateDecimal(parameters.askingPrice) }}
                    </td>
                    <td *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale">
                      <span> {{ 'buildinsDetail.totalAreaSale' | translate }}</span
                      >{{ validateDecimal(parameters.area) }} m²
                    </td>
                    <td *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale">
                      <span> {{ 'buildinsDetail.askingPriceRm' | translate }}<sup>2</sup></span>
                      {{ validateDecimal(parameters.askingPrice / parameters.area) }}
                    </td>
                    <td
                      *ngIf="buildingDetail.type == 'C' && seePositions && filter != 4 && !buildingDetail.entireSale"
                      style=" text-align: end;"
                    >
                      <span style="float: left;">{{ 'buildinsDetail.positions' | translate }}</span>
                      {{
                        filter == 3 && parameters.positions > 1
                          ? ('buildinsDetail.positionRange' | translate) + parameters.positions
                          : parameters.positions
                      }}
                    </td>
                    <td
                      *ngIf="
                        buildingDetail.type == 'C' &&
                        filter != 1 &&
                        filter != 4 &&
                        filter != 3 &&
                        !buildingDetail.entireSale
                      "
                      style=" text-align: end;"
                    >
                      <span style="float: left;">{{ 'buildinsDetail.monthlyCostPerson' | translate }}</span>
                      R$ {{ parameters.monthlyCost | localNumber: '1.0-0' }}
                    </td>
                    <td
                      *ngIf="buildingDetail.type == 'C' && filter != 4 && !buildingDetail.entireSale"
                      style=" text-align: end;"
                    >
                      <span style="float: left;">{{ 'buildinsDetail.MonthlyCostCoworking' | translate }}</span>
                      R$
                      {{
                        parameters.positions == 0
                          ? parameters.monthlyCost
                          : parameters.positions * parameters.monthlyCost
                      }}
                    </td>
                    <td
                      *ngIf="buildingDetail.type == 'C' && filter != 4 && !buildingDetail.entireSale"
                      style=" text-align: end;"
                    >
                      <span style="float: left;">{{ 'buildinsDetail.condition' | translate }}</span
                      >{{ parameters.spaceCondition ? i18NService.getTranslation(parameters.spaceCondition.name) : '' }}
                    </td>
                    <td *ngIf="!buildingDetail.entireSale">
                      <span>{{ 'buildinsDetail.listingAgent' | translate }}</span>
                      <span style="color: #0D6EFD;float: revert;">{{
                        parameters.agentDTO == null ? '' : parameters.agentDTO.companyName
                      }}</span>
                    </td>
                    <td *ngIf="parameters.availableNow && filter != 4 && !buildingDetail.entireSale">
                      <span>{{ 'buildinsDetail.availability' | translate }}</span>
                      Now
                    </td>
                    <td
                      *ngIf="
                        !parameters.availableNow &&
                        filter != 4 &&
                        parameters.availabilityDate &&
                        !buildingDetail.entireSale
                      "
                    >
                      <span>{{ 'buildinsDetail.availability' | translate }}</span>
                      {{ parameters.availabilityDate }}
                    </td>
                    <td *ngIf="buildingDetail.entireSale">
                      <span>{{ 'setReport.propertyName' | translate }}</span>
                      {{ buildingDetail.propertyName }}
                    </td>
                    <td *ngIf="buildingDetail.entireSale">
                      <span>{{ 'setReport.propertyAdress' | translate }}</span>
                      {{ buildingDetail.address }}
                    </td>
                    <td *ngIf="buildingDetail.entireSale">
                      <span>{{ 'global.list-your-property.areaforSale' | translate }}</span>
                      {{ buildingDetail.building.buildingType.name | translate }}
                      {{ propertyHelperService.getSpotBuildingTypeLongTranslation(buildingDetail.type) | translate }}
                    </td>
                    <td *ngIf="buildingDetail.entireSale">
                      <span>{{ 'global.list-your-property.askingPrice' | translate }}</span>
                      {{ getAskingPrice() }}
                    </td>
                    <td>
                      <ion-button
                        class="btn-color-2 see-more-space"
                        (click)="
                          !buildingDetail.entireSale ? seeMoreAction($event, parameters) : openGalleryImg($event)
                        "
                      >
                        <span>{{ 'buildinsDetail.seeMore' | translate }}</span>
                      </ion-button>
                    </td>
                  </tr>
                </tbody>
              </div>
              <a
                [hidden]="hiddeMoreSpaces"
                *ngIf="showShortSpace"
                style="color: #0D6EFD;"
                type="button"
                (click)="alterSpaceText()"
              >
                {{ 'buildinsDetail.seeMore' | translate }}
              </a>
              <a
                [hidden]="hiddeMoreSpaces"
                *ngIf="!showShortSpace"
                style="color: #0D6EFD;"
                type="button"
                (click)="alterSpaceText()"
              >
                {{ 'buildinsDetail.seeLess' | translate }}
              </a>
            </div>
          </ion-row>

          <div
            id="outSideParametersMobile"
            *ngIf="spacesParameters && spacesParameters.length != originalSpaces.length"
            class="col-md-12 outside-search-params"
          >
            <span (click)="outSideSearch()">{{ 'buildinsDetail.outsideSearchParams' | translate }}</span>
          </div>

          <ion-row
            *ngIf="buildingDetail.type == 'C' ? spacesCoworking.length > 0 : spacesParameters"
            class="row-border-bottom marginRow"
          ></ion-row>

          <ion-row class="w-100" *ngIf="showPortfolioSection()">
            <ion-col size="12">
              <h2>
                <ion-label class="titleSize black-color-col">
                  {{ 'buildinsDetail.listingAgentPortfolio' | translate }}</ion-label
                >
              </h2>
              <app-spot-card-slider
                *ngIf="sbSliderFilters.companyId"
                [spotBuildingSearchPayload]="sbSliderFilters"
                [cardContainerClass]="'px-2'"
                (totalCountEvent)="portfolioCountEvent($event)"
              >
              </app-spot-card-slider>
              <p class="text-center text-secondary mt-3" *ngIf="!sbSliderFilters.companyId">
                {{ 'buildinsDetail.noSpotBuildings' | translate }}
              </p>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="showPortfolioSection()" class="row-border-bottom marginRow"></ion-row>

          <ion-row>
            <div class="col-md-12 text-center">
              <label
                ><b style="font-size: 33px;color: #00334d;">{{ 'buildinsDetail.subscribeTitle' | translate }}</b></label
              >
              <br />
            </div>

            <div class="col-md-12">
              <ion-slides [options]="slideOptions" pager="true" #photoSlider id="photoSlider">
                <ion-slide style="width: 100%;">
                  <div class="subscribeCard-mobile">
                    <div>
                      <img src="assets/images/Market_Analytics.jpg" />
                    </div>
                    <div class="cardsSubscription">
                      <span
                        ><b class="fontSize20 label-color2">{{ 'buildinsDetail.marketAnalitics' | translate }}</b></span
                      >
                      <br />
                      <span class="label-color2">{{ 'buildinsDetail.subscriptionAccess' | translate }}</span>
                      <ion-button (click)="goToSiilaSolutions(1)" class="buttonWidth btn-color-2">
                        {{ 'buildinsDetail.subscribe' | translate }}
                      </ion-button>
                    </div>
                  </div>
                </ion-slide>
                <ion-slide style="width: 100%;">
                  <div class="subscribeCard-mobile">
                    <div>
                      <img src="assets/images/sbi.jpg" />
                    </div>
                    <div class="cardsSubscription">
                      <span
                        ><b class="fontSize20 label-color2">{{ 'buildinsDetail.sbi' | translate }}</b></span
                      >
                      <br />
                      <span class="label-color2">{{ 'buildinsDetail.sbiSiila' | translate }}</span>
                      <ion-button (click)="goToSiilaSolutions(2)" class="buttonWidth btn-color-2">
                        {{ 'buildinsDetail.learnMore' | translate }}
                      </ion-button>
                    </div>
                  </div>
                </ion-slide>
                <ion-slide style="width: 100%;">
                  <div class="subscribeCard-mobile">
                    <div>
                      <img src="assets/images/FII_Data.jpg" />
                    </div>
                    <div class="cardsSubscription">
                      <span
                        ><b class="fontSize20 label-color2">{{ 'buildinsDetail.fiiData' | translate }}</b></span
                      >
                      <br />
                      <span class="label-color2">{{ 'buildinsDetail.reitsAnalytics' | translate }}</span>
                      <ion-button (click)="goToSiilaSolutions(3)" class="buttonWidth btn-color-2">
                        {{ 'buildinsDetail.getStarted' | translate }}
                      </ion-button>
                    </div>
                  </div>
                </ion-slide>
              </ion-slides>
            </div>
          </ion-row>

          <ion-row class="row-border-bottom marginRow"></ion-row>

          <app-services *ngIf="isMobile" [isMobile]="isMobile" [spotBuildingId]="buildingDetail.id"></app-services>
        </ion-card-content>
      </ion-card>
    </ion-col>
    <div id="brokerMessage" class="row div-brokers-mobile" style="border-top: 1px solid #E5E5E5;z-index: 2;">
      <div style="width: 40%;">
        <img
          style="height:48px; object-fit: contain;width: 103px;margin-top: 20px;margin-left: 26px;"
          [src]="getMediumImg(buildingDetail.companyLogoImageId)"
          (click)="openCompanyDetails($event)"
          default="assets/no-company-logo.png"
        />
      </div>
      <div style="width: 60%;">
        <ion-button
          class="btn-color-2"
          (click)="openRequestInfo($event)"
          style="width: 90%;margin-top: 22px;margin-bottom: 9px;"
        >
          <img src="assets/images/icons/message.png" style="margin-right: 10px;" />
          {{ 'buildinsDetail.message' | translate }}
        </ion-button>
      </div>
    </div>
    <!--  <div class="scroll-to-top display" style="display: flex;">
      <button class="btnUp" (click)="scrollUp()">
        <ion-icon name="arrow-round-up"></ion-icon>
      </button>
    </div> -->
  </ion-row>

  <!-- Desktop -->
  <ion-row *ngIf="!isMobile" class="max-width" style="margin-top: 70px;">
    <ion-col>
      <ion-card class="max-width ion-listing-card detail-ion-card standard-shadow card-position">
        <ion-card-content id="imgTemplate" *ngIf="buildingDetail" class="card-content-default">
          <app-show-images
            [listingType]="buildingDetail.listingType"
            [SpotPhotos]="SpotPhotos"
            [entireSale]="buildingDetail.entireSale"
            [propertyName]="buildingDetail.propertyName"
            [description]="test"
            [offMarket]="buildingDetail.offMarket"
            [type]="buildingDetail.type"
          >
          </app-show-images>

          <ion-row class="rowTypes">
            <ion-col class="boderCol black-color-col" style="color: #6c757d !important;">
              <h3 style="font-size: 18px; color: #6c757d !important;font-size: 18px;font-weight: bold;">
                <ion-label *ngIf="buildingDetail.entireSale"> {{ 'buildinsDetail.areaForSale' | translate }}</ion-label>
                <ion-label *ngIf="!buildingDetail.entireSale && buildingDetail.type != 'C'">
                  {{ 'buildinsDetail.availableSpaceRange' | translate }}</ion-label
                >
                <ion-label *ngIf="buildingDetail.type == 'C' && getPositions() != 'N/A'">
                  {{ 'spaceDetail.availablePositions' | translate }}</ion-label
                >
                <ion-label *ngIf="buildingDetail.type == 'C' && getPositions() == 'N/A'">
                  {{ 'buildinsDetail.neighborhood' | translate }}</ion-label
                >
              </h3>
              <span *ngIf="buildingDetail.entireSale && buildingDetail.type != 'C'">{{ getAvailableAreaText() }} </span>
              <span id="availableAreaTxt" *ngIf="!buildingDetail.entireSale && buildingDetail.type != 'C'"
                >{{ getAvailableAreaText() }}
              </span>
              <span *ngIf="buildingDetail.type == 'C' && getPositions() != 'N/A'">{{ getPositions() }}</span>
              <span *ngIf="buildingDetail.type == 'C' && getPositions() == 'N/A'">{{
                buildingDetail.neighborhood
              }}</span>
            </ion-col>
            <ion-col class="boderCol black-color-col" style="color: #6c757d !important;">
              <h3 style="font-size: 18px; color: #6c757d !important;font-size: 18px;font-weight: bold;">
                <ion-label *ngIf="buildingDetail.type == 'L'">
                  {{ 'buildinsDetail.monthlyRentRange' | translate }}</ion-label
                >
                <ion-label *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale">
                  {{ 'buildinsDetail.RangeAskingPrices' | translate }}</ion-label
                >

                <ion-label *ngIf="buildingDetail.type == 'S' && buildingDetail.entireSale">
                  {{ 'buildinsDetail.askingPrice' | translate }} m<sup>2</sup></ion-label
                >
                <ion-label *ngIf="buildingDetail.type == 'C'">
                  {{ 'buildinsDetail.monthlyCostPerson' | translate }}</ion-label
                >
              </h3>
              <span id="askingRentPerArea" *ngIf="buildingDetail.type == 'L'">{{ getAskingRentPerArea() }} </span>
              <span id="askingPricePerArea" *ngIf="buildingDetail.type == 'S'">{{ getAskingPricePerArea() }} </span>
              <span id="monthlyRentPerPersonDetail" *ngIf="buildingDetail.type == 'C'">{{
                getMonthlyRentPerPerson()
              }}</span>
            </ion-col>
            <ion-col>
              <button
                [disabled]="!buildingDetail.vrTourUrl"
                class="btn-color-1 btn-border"
                (click)="openVideoTour($event)"
                style="margin-top: 4px;position: absolute;cursor: pointer;"
                *ngIf="this.buildingDetail.vrTourUrl"
              >
                {{ 'buildinsDetail.virtualTour' | translate }}
              </button>
              <button
                class="btn-color-1 btn-border"
                (click)="shareCompany($event)"
                style="margin-top: 4px;position: absolute;cursor: pointer;margin-left: 126px;"
              >
                <fa-icon class="shared-icon" [icon]="sharedIcon" size="md"></fa-icon>
                {{ 'buildinsDetail.share' | translate }}
              </button>
              <ion-button class="btn-color-2" (click)="requestTourAction($event)" style="margin-left: 251px;">
                {{ 'buildinsDetail.requestTour' | translate }}
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row class="row-border-bottom marginRow" id="position1"></ion-row>

          <div class="row" style="margin-bottom: 30px;">
            <div class="col-md-8" style="padding-bottom: 44px;">
              <div
                class="flex-col-nowrap"
                *ngIf="
                  buildingDetail.entireSale &&
                  (investmentOpportunity ||
                    capRate !== '0.00' ||
                    percentageLeased !== '0.00' ||
                    (buildingDetail.majorTenants && buildingDetail.majorTenants.length > 0))
                "
              >
                <div class="margin-top-20" *ngIf="investmentOpportunity">
                  <ion-label class="titleSize black-color-col"
                    >{{ 'spaceDetail.investmentOpportunity' | translate }}
                  </ion-label>
                </div>
                <div class="full-width" style="margin-top: 32px;" *ngIf="investmentOpportunity">
                  <span class="investment__opportunity" [innerHTML]="investmentOpportunity"></span>
                </div>
                <div
                  class="entire__sale__data"
                  *ngIf="
                    capRate !== '0.00' ||
                    percentageLeased !== '0.00' ||
                    (buildingDetail.majorTenants && buildingDetail.majorTenants.length > 0)
                  "
                >
                  <div class="entire__sale_data__itemcontainer" *ngIf="capRate !== '0.00'">
                    <div class="item_icon">
                      <img src="assets/images/icons/cap_rate.svg" />
                    </div>
                    <div class="data__info">
                      <div>
                        <span class="data__info__title">{{ 'buildinsDetail.capRate' | translate }}</span>
                      </div>
                      <div>
                        <span class="data__info__value">{{ capRate }}%</span>
                      </div>
                    </div>
                  </div>
                  <div class="entire__sale__data__separator" *ngIf="percentageLeased !== '0.00'"></div>
                  <div class="entire__sale_data__itemcontainer" *ngIf="percentageLeased !== '0.00'">
                    <div class="item_icon">
                      <img src="assets/images/icons/percent_leased.svg" />
                    </div>
                    <div class="data__info">
                      <div>
                        <span class="data__info__title">{{ 'buildinsDetail.percentLeased' | translate }}</span>
                      </div>
                      <div>
                        <span class="data__info__value">{{ percentageLeased }}%</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="entire__sale__data__separator"
                    *ngIf="
                      (percentageLeased !== '0.00' || capRate !== '0.00') &&
                      buildingDetail.majorTenants &&
                      buildingDetail.majorTenants.length > 0
                    "
                  ></div>
                  <div
                    class="entire__sale_data__itemcontainer"
                    style="min-width: 270px;"
                    *ngIf="buildingDetail.majorTenants && buildingDetail.majorTenants.length > 0"
                  >
                    <div class="item_icon">
                      <img src="assets/images/icons/major_tenants.svg" />
                    </div>
                    <div class="data__info">
                      <div>
                        <span class="data__info__title">{{ 'buildinsDetail.majorTenants' | translate }}</span>
                      </div>
                      <div class="flex-col-wrap">
                        <span *ngFor="let tenant of majorTenants" class="data__info__value">{{ tenant }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="margin-top-20" [ngClass]="{ 'margin-top-48': buildingDetail.entireSale }">
                <h2>
                  <ion-label class="titleSize black-color-col">
                    {{ 'buildinsDetail.buildingDescription' | translate }}
                  </ion-label>
                </h2>
                <ion-button
                  *ngIf="isAdmin"
                  class="mt-3 btn-color-2"
                  (click)="editBuilding()"
                  style="margin-top: -11px;"
                >
                  Edit
                </ion-button>
                <p class="margin-top-20" [innerHtml]="description" style="color: #000000;"></p>
              </div>

              <div class="margin-top-20">
                <h2>
                  <ion-label class="titleSize black-color-col"
                    >{{ 'buildinsDetail.propertyDetails' | translate }}
                  </ion-label>
                </h2>

                <ion-row class="margin-top-20" style="border: 1px solid #E0E2E7;border-radius: 4px;">
                  <ion-col class="keyFacts" size="12">{{ 'spaceDetail.keyFacts' | translate }} </ion-col>

                  <ion-col size="3" *ngIf="buildingDetail.neighborhood">
                    <ion-label class="label-color">{{ 'buildinsDetail.neighborhood' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.neighborhood" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.neighborhood }}</b>
                    </ion-label>
                  </ion-col>
                  <ion-col
                    size="3"
                    *ngIf="buildingDetail.building.buildingSubType && buildingDetail.building.buildingType.id == 1001"
                  >
                    <ion-label class="label-color">{{ 'buildinsDetail.propertySubType' | translate }}</ion-label>
                  </ion-col>
                  <ion-col
                    size="3"
                    *ngIf="buildingDetail.building.buildingSubType && buildingDetail.building.buildingType.id == 1001"
                    class="colDetail1"
                  >
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.building.buildingSubType.name }}</b>
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.ceilingHeight">
                    <ion-label class="label-color"
                      >{{ 'global.list-your-property.ceilingHeightAvg' | translate }}
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.ceilingHeight" class="colDetail1">
                    <ion-label class="label-color2"
                      ><b>{{ buildingDetail.ceilingHeight }} m</b>
                    </ion-label>
                  </ion-col>

                  <ion-col size="3" *ngIf="buildingDetail.rentableArea">
                    <ion-label class="label-color">{{ 'buildinsDetail.propertySize' | translate }} </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.rentableArea" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.rentableArea | localNumber: '1.0-0' }} m<sup>2</sup></b>
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.building.industrialStatus">
                    <ion-label class="label-color"
                      >{{ 'global.list-your-property.constructionStatus' | translate }}
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.building.industrialStatus" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ i18NService.getTranslation(buildingDetail.building.industrialStatus.name) }}</b>
                    </ion-label>
                  </ion-col>

                  <ion-col size="3" *ngIf="buildingDetail.yearBuilt">
                    <ion-label class="label-color">{{ 'global.list-your-property.yearBuilt' | translate }} </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.yearBuilt" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.yearBuilt }}</b>
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.parkingSpaces">
                    <ion-label class="label-color"
                      >{{ 'global.list-your-property.NumberParkingSpaces' | translate }}
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.parkingSpaces" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.parkingSpaces }}</b>
                    </ion-label>
                  </ion-col>

                  <ion-col size="3" *ngIf="buildingDetail.yearRenovated">
                    <ion-label class="label-color"
                      >{{ 'global.list-your-property.yearRenovated' | translate }}
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.yearRenovated" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.yearRenovated }}</b>
                    </ion-label>
                  </ion-col>
                  <ion-col
                    size="3"
                    *ngIf="buildingDetail.averageFloorPrivateArea && buildingDetail.building.buildingType.id == 2001"
                  >
                    <ion-label class="label-color"
                      >{{ 'global.list-your-property.averageFloorPrivateArea' | translate }}
                    </ion-label>
                  </ion-col>
                  <ion-col
                    size="3"
                    *ngIf="buildingDetail.averageFloorPrivateArea && buildingDetail.building.buildingType.id == 2001"
                    class="colDetail1"
                  >
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.averageFloorPrivateArea | localNumber: '1.0-0' }} m<sup>2</sup></b>
                    </ion-label>
                  </ion-col>

                  <ion-col size="3" *ngIf="buildingDetail.numberStories">
                    <ion-label class="label-color">{{ 'global.list-your-property.floors' | translate }} </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.numberStories" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.numberStories }}</b>
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.landArea">
                    <ion-label class="label-color"
                      >{{ 'global.list-your-property.totalLanArea' | translate }}
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.landArea" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.landArea | localNumber: '1.0-0' }} m<sup>2</sup></b>
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.truckParkingSpaces">
                    <ion-label class="label-color"
                      >{{ 'global.list-your-property.numberTruckParking' | translate }}
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.truckParkingSpaces" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.truckParkingSpaces }}</b>
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.numberOfBuildings">
                    <ion-label class="label-color"
                      >{{ 'global.list-your-property.numberBuildings' | translate }}
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.numberOfBuildings" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.numberOfBuildings }}</b>
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.carParkingSpaces">
                    <ion-label class="label-color"
                      >{{ 'global.list-your-property.NumberCarParkingSpaces' | translate }}
                    </ion-label>
                  </ion-col>
                  <ion-col size="3" *ngIf="buildingDetail.carParkingSpaces" class="colDetail1">
                    <ion-label class="label-color2">
                      <b>{{ buildingDetail.carParkingSpaces }}</b>
                    </ion-label>
                  </ion-col>
                </ion-row>
              </div>
              <ion-row size="12">
                <ion-col size="12" style="text-align: end;">
                  <ion-label class="date"
                    >{{ 'global.dateCreated' | translate }}: {{ buildingDetail.createdDate | date: 'dd/MM/yyyy' }}
                  </ion-label>
                </ion-col>
                <ion-col size="12" style="text-align: end;">
                  <ion-label class="date"
                    >{{ 'global.modifiedDate' | translate }}: {{ buildingDetail.modifiedDate | date: 'dd/MM/yyyy' }}
                  </ion-label>
                </ion-col>
              </ion-row>

              <div style="margin-top: 10;" *ngIf="features != 0">
                <h2>
                  <ion-label class="titleSize black-color-col"
                    >{{ 'buildinsDetail.buildingFeatures' | translate }}
                  </ion-label>
                </h2>

                <div class="margin-top-20" [ngClass]="{ 'show-less': showShortDesciption }">
                  <ion-row style="font-weight: 600;line-height: 24px;">
                    <ion-col size="6" *ngIf="buildingDetail.twentyFourHourSecurity">
                      <img class="imgFeatures" src="assets/images/icons/security.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.twentyFourHourSecurity' | translate }} </ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.bulletProofCabin">
                      <img class="imgFeatures" src="assets/images/icons/Truck Yard.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.bulletProofCabin' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.sluiceGate">
                      <img class="imgFeatures" src="assets/images/icons/Sluice-Gate.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.sluiceGate' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.sprinklers">
                      <img class="imgFeatures" src="assets/images/icons/highlight.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.sprinklers' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.electricGenerator">
                      <img class="imgFeatures" src="assets/images/icons/electrical_services.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.electricGenerator' | translate }} </ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.leedStatus">
                      <img class="imgFeatures" src="assets/images/icons/eco.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.sustentability' | translate }} </ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.skyLights">
                      <img class="imgFeatures" src="assets/images/icons/sky_lights.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.skyLights' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.heliport">
                      <img class="imgFeatures" src="assets/images/icons/Heliport.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.heliport' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.truckYard">
                      <img class="imgFeatures" src="assets/images/icons/Truck Yard.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.truckYard' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.singleTenant">
                      <img class="imgFeatures" src="assets/images/icons/person.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.singleTenant' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.crossDocking">
                      <img class="imgFeatures" src="assets/images/icons/Cross-Docking.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.crossDocking' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.blockAndSteelConstruction">
                      <img class="imgFeatures" src="assets/images/icons/Block & Steel Construction.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.blockAndSteelConstruction' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.bicycleRack">
                      <img class="imgFeatures" src="assets/images/icons/pedal_bike.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.bicycleRack' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.fiberOptic">
                      <img class="imgFeatures" src="assets/images/icons/sky_lights.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.fiberOptic' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.blockConstruction">
                      <img class="imgFeatures" src="assets/images/icons/Block & Steel Construction.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.blockConstruction' | translate }} </ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.centralAirConditioning">
                      <img class="imgFeatures" src="assets/images/icons/ac_unit.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.centralAirConditioning' | translate }} </ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.multiTenant">
                      <img class="imgFeatures" src="assets/images/icons/multi_tenant.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.multiTenant' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.petFriendly">
                      <img class="imgFeatures" src="assets/images/icons/pets.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.petFriendly' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.steelConstruction">
                      <img class="imgFeatures" src="assets/images/icons/Block & Steel Construction.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.steelConstruction' | translate }} </ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.includesOffice">
                      <img class="imgFeatures" src="assets/images/icons/includes_office.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.includesOffice' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.includesRetail">
                      <img class="imgFeatures" src="assets/images/icons/includes_retail.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.includesRetail' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.coldStorage">
                      <img class="imgFeatures" src="assets/images/icons/cold_storage.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.coldStorage' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.fullBuildOut">
                      <img class="imgFeatures" src="assets/images/icons/full_builld_out.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.fullBuildOut' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.mailPackageHandling">
                      <img class="imgFeatures" src="assets/images/icons/markunread_mailbox.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.mailAndPackage' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.barista">
                      <img class="imgFeatures" src="assets/images/icons/local_cafe.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.barista' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.enhancedCleaning">
                      <img class="imgFeatures" src="assets/images/icons/cleaning_services.svg" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.enhancedCleaning' | translate }}</ion-label>
                    </ion-col>
                    <ion-col size="6" *ngIf="buildingDetail.cafeteria">
                      <img class="imgFeatures" src="assets/images/icons/cafeteria.png" alt="" />
                      <ion-label>{{ 'global.addtSearchFilters.cafeteria' | translate }}</ion-label>
                    </ion-col>
                  </ion-row>
                </div>

                <a
                  [hidden]="hiddeMoreFeatures"
                  *ngIf="showShortDesciption"
                  style="color: #0D6EFD;"
                  type="button"
                  (click)="alterDescriptionText()"
                >
                  {{ 'buildinsDetail.seeMore' | translate }}
                </a>
                <a *ngIf="!showShortDesciption" style="color: #0D6EFD;" type="button" (click)="alterDescriptionText()">
                  {{ 'buildinsDetail.seeLess' | translate }}
                </a>
              </div>
              <div class="margin-top-20 listing-map">
                <h2>
                  <ion-label class="titleSize black-color-col"
                    >{{ 'buildinsDetail.propertyLocation' | translate }}
                  </ion-label>
                </h2>
                <br />
                <app-full-screen-map-wrapper>
                  <app-duplomap *ngIf="markers.length" [markers]="markers" [options]="mapOptions"> </app-duplomap>
                </app-full-screen-map-wrapper>
              </div>
            </div>
            <div class="col-md-4" id="broker" style="padding: 0px 0px -1px 25px;">
              <div id="position3" [ngStyle]="sticky ? cssSticky() : maxSticky ? cssMaxSticky() : null">
                <div class="company">
                  <div class="col-md-12 label-color" style="font-weight: 500;margin-top: -6px;text-align: center;">
                    {{ 'buildinsDetail.partner' | translate }}
                    <img style="width: 70px;margin-left: 5px;display: initial;" src="assets/images/SiiLA_SVG.svg" />
                  </div>

                  <div>
                    <div
                      [ngClass]="{
                        imgCompany: buildingDetail.companyLogoImageId,
                        imgCompanyDefault: !buildingDetail.companyLogoImageId
                      }"
                    >
                      <a
                        rel="nofollow"
                        *ngIf="buildingDetail.brokers[0].companyURL"
                        (click)="openCompanyUrl(buildingDetail.brokers[0].companyURL)"
                        target="blank"
                      >
                        <img
                          [src]="getMediumImg(buildingDetail.companyLogoImageId)"
                          default="assets/images/default-profile-photo.png"
                        />
                      </a>
                      <img
                        *ngIf="!buildingDetail.brokers[0].companyURL"
                        [src]="getMediumImg(buildingDetail.companyLogoImageId)"
                        default="assets/images/default-profile-photo.png"
                      />
                    </div>

                    <!--  <button class="btn-color-1 btn-border" style="width: 90%">
                      {{ 'buildinsDetail.visit' | translate }} {{ buildingDetail.offeredByCompany.name }}
                    </button> -->
                    <button
                      (click)="openCompanyDetails($event)"
                      *ngIf="buildingDetail.companySpotProfile"
                      class="btn-color-1 btn-border"
                      style="width: 90%; margin-top: 4px;"
                    >
                      {{ 'buildinsDetail.visitSiila' | translate }}
                    </button>

                    <button
                      (click)="openBuilding()"
                      class="btn-color-1 btn-border"
                      style="width: 90%;margin-top: 9px;margin-bottom: 9px;"
                    >
                      {{ 'buildinsDetail.visitMarket' | translate }}
                    </button>
                  </div>
                </div>

                <div class="brokers">
                  <div class="row" style="padding: 0px;">
                    <div class="col-md-3" style="float: left; margin-top: 22px;">
                      <img
                        (click)="openBrokerDetails(buildingDetail.brokers[0].userId)"
                        style="border-radius: 50%; width: 56px; height: 56px;object-fit: cover; cursor: pointer;"
                        [src]="getMediumImg(buildingDetail.brokers[0].photoId)"
                        default="assets/images/default-profile-photo.png"
                        alt=""
                      />
                    </div>
                    <div class="col-md-9" style="float: left;padding: 0px;bottom: 6px;">
                      <span (click)="openBrokerDetails(buildingDetail.brokers[0].userId)" style="cursor: pointer;"
                        ><b class="black-color-col" style="font-size: 16px;">{{
                          buildingDetail.brokers[0].firstNameAndLastName
                        }}</b></span
                      >
                      <br />
                      <p
                        class="label-color2"
                        style="font-weight: 600; cursor: pointer;"
                        (click)="openBrokerDetails(buildingDetail.brokers[0].userId)"
                      >
                        {{
                          buildingDetail.brokers[0].jobTitle
                            ? buildingDetail.brokers[0].jobTitle
                            : buildingDetail.brokers[0].companyName
                        }}
                      </p>
                      <p style="color: #0D6EFD;">
                        <img style="margin-top: 2px;" src="assets/images/icons/mail.png" class="img-brokers" />
                        <span *ngIf="!contactEmail" style="cursor: pointer;" (click)="showContactEmail()">
                          {{ 'global.listing-detail.showEmail' | translate }}
                        </span>
                        <span *ngIf="contactEmail">
                          {{ buildingDetail.brokers[0].userEmail }}
                        </span>
                      </p>
                      <p
                        *ngIf="buildingDetail.brokers[0].phoneNumber"
                        class="label-color2"
                        style="font-weight: 500;color: #0D6EFD;"
                      >
                        <img
                          style="margin-top: 2px;"
                          src="assets/images/icons/whatsappVector.png"
                          class="img-brokers"
                        />
                        <span *ngIf="!whatsappPhone" style="cursor: pointer;" (click)="showBrokerWhatsapp()">
                          {{ 'global.listing-detail.showWhataspp' | translate }}
                        </span>
                        <span *ngIf="whatsappPhone">
                          {{ buildingDetail.brokers[0].phoneNumber }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <ion-row
                    *ngIf="buildingDetail.brokers.length > 1"
                    style="margin: 6px -1px 5px 0px;border-bottom: 1px solid #e0e2e7;"
                  ></ion-row>
                  <div
                    class="row"
                    style="padding: 0px;    margin-bottom: 20px;"
                    *ngIf="buildingDetail.brokers.length > 1"
                  >
                    <div class="col-md-3" style="float: left; margin-top: 22px;">
                      <img
                        (click)="openBrokerDetails(buildingDetail.brokers[1].userId)"
                        style="border-radius: 50%; width: 56px; height: 56px;object-fit: cover; cursor: pointer;"
                        [src]="getMediumImg(buildingDetail.brokers[1].photoId)"
                        default="assets/images/default-profile-photo.png"
                      />
                    </div>
                    <div class="col-md-9" style="float: left;padding: 0px;">
                      <span (click)="openBrokerDetails(buildingDetail.brokers[1].userId)" style="cursor: pointer;"
                        ><b class="black-color-col" style="font-size: 16px;">{{
                          buildingDetail.brokers[1].firstNameAndLastName
                        }}</b></span
                      >
                      <br />
                      <p
                        class="label-color2"
                        style="font-weight: 600;"
                        (click)="openBrokerDetails(buildingDetail.brokers[1].userId)"
                        style="cursor: pointer;"
                      >
                        {{
                          buildingDetail.brokers[1].jobTitle
                            ? buildingDetail.brokers[1].jobTitle
                            : buildingDetail.brokers[1].companyName
                        }}
                      </p>
                      <p style="color: #0D6EFD;">
                        <img style="margin-top: 2px;" src="assets/images/icons/mail.png" class="img-brokers" />
                        <span *ngIf="!contactEmail2" style="cursor: pointer;" (click)="showContactEmail(null, 1)">
                          {{ 'global.listing-detail.showEmail' | translate }}
                        </span>
                        <span *ngIf="contactEmail2">
                          {{ buildingDetail.brokers[1].userEmail }}
                        </span>
                      </p>
                      <p class="label-color2" style="font-weight: 500;color: #0D6EFD;">
                        <img
                          style="margin-top: 2px;width: 19px;height: 19px;"
                          src="assets/images/icons/whatsappVector.png"
                          class="img-brokers"
                        />
                        <span *ngIf="!whatsappPhone2" style="cursor: pointer;" (click)="showBrokerWhatsapp(null, 1)">
                          {{ 'global.listing-detail.showWhataspp' | translate }}
                        </span>
                        <span *ngIf="whatsappPhone2">
                          {{ buildingDetail.brokers[1].phoneNumber }}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div
                    [ngClass]="{
                      bokerMail: buildingDetail.brokers.length == 1,
                      bokerMail2: buildingDetail.brokers.length > 1
                    }"
                  >
                    <div class="row" style="margin-left: 8px;">
                      <ion-button
                        (click)="openRequestInfo($event)"
                        class="btn-color-2"
                        style="width: 88%;font-size: 14px;"
                      >
                        <span style="text-transform: none;">{{ 'buildinsDetail.message' | translate }}</span>
                      </ion-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12" id="position2"></div>

            <ion-row *ngIf="!buildingDetail.offMarket">
              <div *ngIf="buildingDetail.type == 'C' ? spacesCoworking.length > 0 : spacesParameters" class="col-md-12">
                <ion-row class="row-border-bottom" style="margin: 40px 0px 40px 0px;"></ion-row>
              </div>
              <div *ngIf="buildingDetail.type == 'C' ? spacesCoworking.length > 0 : spacesParameters" class="col-md-12">
                <h2>
                  <ion-label class="black-color-col" style="font-size: 24px;">{{
                    'buildinsDetail.searchParameters' | translate
                  }}</ion-label>
                </h2>
                <div class="row">
                  <div class="col-md-12 pt-3" style="text-align: center;">
                    <span style="color: #6C757D;font-size: 18px;font-weight: 600;"
                      >{{ 'buildinsDetail.setSpacesTitle' | translate }} ({{ countSapces }})</span
                    >
                  </div>
                  <div class="col-md-12 pt-3" style="text-align: center;">
                    <div class="col-md-6" style="float: left;">
                      <button class="btnSpaces" (click)="openReportSpaces()" style="float: right;width: 200px">
                        <img src="assets/images/icons/reports.png" class="spacesIcon" />{{
                          'buildinsDetail.spacesRepot' | translate
                        }}
                      </button>
                    </div>
                    <div class="col-md-6" style="float: right;">
                      <button
                        [disabled]="countSapces == 0"
                        class="btnSpaces"
                        (click)="openSetSpaces()"
                        style="float: left;width: 200px"
                        [ngClass]="{ 'text-disabled': countSapces == 0 }"
                      >
                        <img *ngIf="countSapces != 0" src="assets/images/icons/setSpot.svg" class="spacesIconSvg" />
                        <img
                          *ngIf="countSapces == 0"
                          src="assets/images/icons/apartment48.svg"
                          class="spacesIconSvg-disabled"
                        />
                        <span style="margin-left: 26px;"> {{ 'buildinsDetail.spacesSetSpot' | translate }}</span>
                      </button>
                      <img
                        src="assets/images/icons/tootilSpace.png"
                        class="spacesIcon"
                        style="position: inherit;padding-left: 8px;
                      width: 24px;cursor: pointer;"
                        [title]="'buildinsDetail.tooltiSetSpace' | translate"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12" style="display: flex;justify-content: center;margin-top: 20px;">
                <div
                  *ngIf="spacesParameters && buildingDetail.type == 'C'"
                  class="col-md-12"
                  style="margin:0 auto;display: contents;"
                >
                  <div *ngIf="filter3" class="img_width">
                    <div
                      class="coworking_filter"
                      (click)="coworkingFilter(3)"
                      [ngClass]="{ coworking_filter2: filter == 3 }"
                    >
                      <div>
                        <img style="width : 202px" src="assets/images/openWorkspace.png" alt="" />
                      </div>
                      <div class="text-center">
                        <span>{{ 'buildinsDetail.openWorkspace' | translate }}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="filter1" class="img_width">
                    <div
                      class="coworking_filter"
                      (click)="coworkingFilter(1)"
                      [ngClass]="{ coworking_filter2: filter == 1 }"
                    >
                      <div>
                        <img src="assets/images/privateOffice.png" alt="" />
                      </div>
                      <div class="text-center">
                        <span>{{ 'buildinsDetail.privateOffice' | translate }} </span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="filter2" class="img_width">
                    <div
                      class="coworking_filter"
                      (click)="coworkingFilter(2)"
                      [ngClass]="{ coworking_filter2: filter == 2 }"
                    >
                      <div>
                        <img src="assets/images/delicatedWorkspace.png" alt="" />
                      </div>
                      <div class="text-center">
                        <span>{{ 'buildinsDetail.dedicatedWorkspace' | translate }} </span>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="filter4" class="img_width">
                    <div
                      class="coworking_filter"
                      (click)="coworkingFilter(4)"
                      [ngClass]="{ coworking_filter2: filter == 4 }"
                    >
                      <div>
                        <img src="assets/images/fullFloorOffices.png" alt="" />
                      </div>
                      <div class=" text-center">
                        <span>{{ 'buildinsDetail.fullFloorOffices' | translate }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ion-row
                *ngIf="buildingDetail.type == 'C' ? spacesCoworking.length > 0 : spacesParameters"
                style="margin: auto;margin-top: 20px;"
              >
                <table class="table">
                  <thead
                    class="thead-light details-spaces-table"
                    style="font-size: 12px;text-align: center;white-space: nowrap;"
                  >
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          style="margin-top: 2px;
                      position: absolute;"
                          (click)="selectAll()"
                          [(ngModel)]="selectallSpaces"
                        />
                        <span style="margin-left:18px ;">{{ 'buildinsDetail.select' | translate }}</span>
                      </th>
                      <th *ngIf="buildingDetail.type == 'C' && filter != 4 && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.coworkingType' | translate }}
                      </th>
                      <th
                        *ngIf="buildingDetail.building.buildingType.id == 2001 && !buildingDetail.entireSale"
                        scope="col"
                      >
                        {{ 'buildinsDetail.floor' | translate }}
                      </th>
                      <th
                        *ngIf="buildingDetail.building.buildingType.id == 2001 && seeSuit && !buildingDetail.entireSale"
                        scope="col"
                      >
                        {{ 'buildinsDetail.suite' | translate }}
                      </th>
                      <th
                        *ngIf="buildingDetail.building.buildingType.id == 1001 && !buildingDetail.entireSale"
                        scope="col"
                      >
                        {{ 'buildinsDetail.wareHouse' | translate }}
                      </th>
                      <th
                        *ngIf="buildingDetail.building.buildingType.id == 1001 && !buildingDetail.entireSale"
                        scope="col"
                      >
                        {{ 'buildinsDetail.module' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.monthlyRentNoSymbols' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.availableSpaceNoSymbols' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.monthlyRentRm' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.condition' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.term' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.askingPrice' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.totalAreaSale' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.askingPriceRm' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'C' && seePositions && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.positions' | translate }}
                      </th>
                      <th
                        *ngIf="
                          buildingDetail.type == 'C' &&
                          filter != 1 &&
                          filter != 4 &&
                          filter != 3 &&
                          !buildingDetail.entireSale
                        "
                        scope="col"
                      >
                        {{ 'buildinsDetail.monthlyCostPerson' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'C' && filter != 4 && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.MonthlyCostCoworking' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.type == 'C' && filter != 4 && !buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.condition' | translate }}
                      </th>
                      <th *ngIf="!buildingDetail.entireSale" scope="col">
                        {{ 'buildinsDetail.listingAgent' | translate }}
                      </th>
                      <th scope="col" *ngIf="filter != 4 && !buildingDetail.entireSale">
                        {{ 'buildinsDetail.availability' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.entireSale" scope="col">{{ 'setReport.propertyName' | translate }}</th>
                      <th *ngIf="buildingDetail.entireSale" scope="col">
                        {{ 'setReport.propertyAdress' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.entireSale" scope="col">
                        {{ 'global.list-your-property.areaforSale' | translate }}
                      </th>
                      <th *ngIf="buildingDetail.entireSale" scope="col">
                        {{ 'global.list-your-property.askingPrice' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'buildinsDetail.spaceDetails' | translate }}
                      </th>
                    </tr>
                  </thead>

                  <tbody class="text-center" style="font-weight: bold;font-size: 12px;white-space: nowrap;">
                    <tr
                      *ngFor="
                        let parameters of buildingDetail.type == 'C' ? spacesCoworking : spacesParameters;
                        let i = index
                      "
                    >
                      <td>
                        <input
                          type="checkbox"
                          [(ngModel)]="parameters.select"
                          (change)="countSpaces(parameters)"
                          [checked]="parameters.select"
                        />
                      </td>
                      <td *ngIf="buildingDetail.entireSale">{{ buildingDetail.propertyName }}</td>
                      <td *ngIf="buildingDetail.entireSale">{{ buildingDetail.address }}</td>
                      <td *ngIf="buildingDetail.entireSale">
                        {{ buildingDetail.building.buildingType.name | translate }}
                        {{ propertyHelperService.getSpotBuildingTypeLongTranslation(buildingDetail.type) | translate }}
                      </td>
                      <td *ngIf="buildingDetail.entireSale">{{ getAskingPrice() }}</td>
                      <td *ngIf="buildingDetail.type == 'C' && filter != 4 && !buildingDetail.entireSale">
                        {{ i18NService.getTranslation(parameters.coworkingType) | translate }}
                      </td>
                      <td
                        class="floorSpaceKK"
                        *ngIf="buildingDetail.building.buildingType.id == 2001 && !buildingDetail.entireSale"
                      >
                        {{ findFloorTranslation(parameters.floor) }}
                      </td>
                      <td
                        *ngIf="buildingDetail.building.buildingType.id == 2001 && seeSuit && !buildingDetail.entireSale"
                      >
                        {{ parameters.suite }}
                      </td>
                      <td *ngIf="buildingDetail.building.buildingType.id == 1001 && !buildingDetail.entireSale">
                        {{ parameters.warehouse }}
                      </td>
                      <td *ngIf="buildingDetail.building.buildingType.id == 1001 && !buildingDetail.entireSale">
                        {{ parameters.module }}
                      </td>
                      <td *ngIf="buildingDetail.type == 'L'">
                        {{ validateDecimal(parameters.monthlyAskingRent / parameters.area, 2) }}
                      </td>
                      <td *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale">
                        {{ parameters.area | localNumber: '1.0-0' }} m²
                      </td>
                      <td *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale">
                        {{ validateDecimal(parameters.monthlyAskingRent, 1) }}
                      </td>
                      <td *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale">
                        {{
                          parameters.spaceCondition ? i18NService.getTranslation(parameters.spaceCondition.name) : ''
                        }}
                      </td>
                      <td *ngIf="buildingDetail.type == 'L' && !buildingDetail.entireSale">
                        {{ getTermsTxt(parameters.term) }}
                      </td>
                      <td *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale">
                        {{ validateDecimal(parameters.askingPrice, 1) }}
                      </td>
                      <td *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale">
                        {{
                          parameters.area == 0 || parameters.area == null
                            ? validateDecimal(parameters.area)
                            : validateDecimal(parameters.area) + ' m²'
                        }}
                      </td>
                      <td
                        *ngIf="buildingDetail.type == 'S' && !buildingDetail.entireSale && !buildingDetail.entireSale"
                      >
                        {{ validateDecimal(parameters.askingPrice / parameters.area, 2) }}
                      </td>
                      <td *ngIf="buildingDetail.type == 'C' && seePositions && !buildingDetail.entireSale">
                        {{
                          filter == 3 && parameters.positions > 1
                            ? ('buildinsDetail.positionRange' | translate) + parameters.positions
                            : parameters.positions
                        }}
                      </td>
                      <td
                        class="monthlyCostC"
                        *ngIf="
                          buildingDetail.type == 'C' &&
                          filter != 1 &&
                          filter != 4 &&
                          filter != 3 &&
                          !buildingDetail.entireSale
                        "
                      >
                        {{ getMonthlyCostValue(parameters) }}
                      </td>
                      <td
                        class="monthlyCostPositions"
                        *ngIf="buildingDetail.type == 'C' && filter != 4 && !buildingDetail.entireSale"
                      >
                        {{ getMonthlyCostWithPositions(parameters) }}
                      </td>
                      <td *ngIf="buildingDetail.type == 'C' && filter != 4 && !buildingDetail.entireSale">
                        {{
                          parameters.spaceCondition ? i18NService.getTranslation(parameters.spaceCondition.name) : ''
                        }}
                      </td>
                      <td *ngIf="!buildingDetail.entireSale" style="color: #0D6EFD">
                        <span (click)="openRequestInfoFromAgent($event, parameters)" class="listing__party">{{
                          parameters.agentDTO == null ? '' : parameters.agentDTO.companyName
                        }}</span>
                      </td>
                      <td *ngIf="parameters.availableNow && filter != 4 && !buildingDetail.entireSale">
                        {{ 'buildinsDetail.now' | translate }}
                      </td>
                      <td *ngIf="!parameters.availableNow && filter != 4 && !buildingDetail.entireSale">
                        {{ parameters.availabilityDate | date: 'dd/MM/yyyy' }}
                      </td>
                      <td>
                        <ion-button
                          class="btn-color-2 see-more-space"
                          (click)="
                            !buildingDetail.entireSale ? seeMoreAction($event, parameters) : openGalleryImg($event)
                          "
                        >
                          {{ 'buildinsDetail.seeMore' | translate }}
                        </ion-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ion-row>
            </ion-row>

            <div *ngIf="buildingDetail.offMarket && buildingDetail.type != 'S'" class="col-md-12">
              <ion-row class="row-border-bottom" style="margin: 40px 0px 40px 0px;"></ion-row>
            </div>
            <ion-row *ngIf="buildingDetail.offMarket && buildingDetail.type != 'S'">
              <div class="col-md-12">
                <h2>
                  <ion-label class="black-color-col" style="font-size: 24px;">{{
                    'buildinsDetail.searchParameters' | translate
                  }}</ion-label>
                </h2>
              </div>
              <ion-row style="margin: auto;margin-top: 20px;">
                <button class="btn-review" style="margin-left: 34%;" (click)="goToMarketAnalytics()">
                  <img
                    style="width: 14px;float: left;margin-top: 4px;"
                    src="assets/images/icons/lockedWhite.png"
                    alt=""
                  />
                  <p style="margin-top: 2px;">{{ 'buildinsDetail.offLeased' | translate }}</p>
                </button>
                <table class="table">
                  <thead class="thead-light" style="font-size: 12px;text-align: center;white-space: nowrap;">
                    <tr>
                      <th scope="col">
                        {{ 'buildinsDetail.wareHouse' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'buildinsDetail.module' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'buildinsDetail.monthlyRentM' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'buildinsDetail.availableSpaceNoSymbols' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'buildinsDetail.monthlyRent' | translate }}
                      </th>
                      <th scope="col">{{ 'buildinsDetail.condition' | translate }}</th>
                      <th scope="col">{{ 'buildinsDetail.term' | translate }}</th>

                      <th scope="col">{{ 'buildinsDetail.listingAgent' | translate }}</th>
                      <th scope="col">{{ 'buildinsDetail.availability' | translate }}</th>
                      <th scope="col">{{ 'buildinsDetail.spaceDetails' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-center blur" style="font-weight: bold;font-size: 12px;white-space: nowrap;">
                    <tr *ngFor="let offMarket of spaceOffMarket; let i = index">
                      <td>2</td>
                      <td>4</td>
                      <td>Negotiable</td>
                      <td>222 m²</td>
                      <td>Negotiable</td>
                      <td>Flex</td>
                      <td>2 {{ 'Years' | translate }}</td>
                      <td style="color: #0D6EFD">
                        <span class="listing__party">VERA CRUZ REAL ESTATE</span>
                      </td>
                      <td>Now</td>
                      <td>
                        <ion-button class="btn-color-2 see-more-space">
                          {{ 'buildinsDetail.seeMore' | translate }}
                        </ion-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ion-row>
            </ion-row>

            <div
              *ngIf="spacesParameters && spacesParameters.length != originalSpaces.length"
              class="col-md-12 outside-search-params"
            >
              <span (click)="outSideSearch()">{{ 'buildinsDetail.outsideSearchParams' | translate }}</span>
            </div>

            <div class="col-md-12">
              <ion-row class="row-border-bottom" style="margin:40px 0px 40px 0px"></ion-row>
            </div>

            <ion-row class="w-100" *ngIf="showPortfolioSection()">
              <ion-col size="12">
                <h2>
                  <ion-label class="titleSize black-color-col" style="padding-left: 12px;">
                    {{ 'buildinsDetail.listingAgentPortfolio' | translate }}</ion-label
                  >
                </h2>
                <app-spot-card-slider
                  *ngIf="sbSliderFilters.companyId"
                  (totalCountEvent)="portfolioCountEvent($event)"
                  [spotBuildingSearchPayload]="sbSliderFilters"
                >
                </app-spot-card-slider>
                <p class="text-center text-secondary mt-3" *ngIf="!sbSliderFilters.companyId">
                  {{ 'buildinsDetail.noSpotBuildings' | translate }}
                </p>
              </ion-col>
            </ion-row>

            <div class="col-md-12" *ngIf="showPortfolioSection()">
              <ion-row class="row-border-bottom" style="margin:40px 0px 40px 0px"></ion-row>
            </div>

            <div class="col-md-12 text-center">
              <h2>
                <label
                  ><b style="font-size: 30px;color: #00334d;">{{
                    'buildinsDetail.subscribeTitle' | translate
                  }}</b></label
                >
              </h2>
            </div>
            <ion-row class="subscribe margin-top-20">
              <div class="banner-container">
                <div class="banner-card bgma" (click)="onCardClick('bgma')">
                  <img src="assets/icons/ma.png" alt="Market Analytics Icon" class="banner-icon" />
                  <h2 class="banner-title">{{ 'buildinsDetail.marketAnalyticsTitle' | translate }}</h2>
                  <p class="banner-description">{{ 'buildinsDetail.marketAnalyticsDescription' | translate }}</p>
                  <button class="banner-button">{{ 'buildinsDetail.marketAnalyticsButton' | translate }}</button>
                </div>

                <div class="banner-card bga" (click)="onCardClick('bga')">
                  <img src="assets/icons/academy.png" alt="Academy Icon" class="banner-icon" />
                  <h2 class="banner-title">{{ 'buildinsDetail.academyTitle' | translate }}</h2>
                  <p class="banner-description">{{ 'buildinsDetail.academyDescription' | translate }}</p>
                  <button class="banner-button">{{ 'buildinsDetail.academyButton' | translate }}</button>
                </div>

                <div class="banner-card bgi" (click)="onCardClick('bgi')">
                  <img src="assets/icons/indices.png" alt="Indices Icon" class="banner-icon" />
                  <h2 class="banner-title">{{ 'buildinsDetail.indicesTitle' | translate }}</h2>
                  <p class="banner-description">{{ 'buildinsDetail.indicesDescription' | translate }}</p>
                  <button class="banner-button">{{ 'buildinsDetail.indicesButton' | translate }}</button>
                </div>
              </div>
            </ion-row>

            <div class="col-md-12">
              <ion-row class="row-border-bottom" style="margin: 40px 0px 40px 0px;"></ion-row>
            </div>
            <div class="col-md-12">
              <app-services [isMobile]="isMobile" [spotBuildingId]="buildingDetail.id"></app-services>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</div>
<app-footer></app-footer>
