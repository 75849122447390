import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { PlatformHelperService, PropertyTypeHelperService, I18nService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { SpotBuildingDetailDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { AppCarouselRestoreService } from '@app/search-navigation/components/carousel';
import { AppListingSliderService } from '@app/search-navigation/components/listing-slider';
import { CoworkingService } from '@app/search-navigation/services/coworking.service';
import { DetailPositionService } from '@app/search-navigation/services/detail-position.service';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { IonSlides, ToastController } from '@ionic/angular';
import { ImageService } from '../../../../core/image.service';
import { SpacesFilterDTO } from '../../../../models/spot-buildings/spot-available-spaces';
import { SpotBuildingSpaceService } from '../../../services/spaces.service';
import { SizeIcon } from '../../../../models/shared/shared-enums.model';

@Component({
  selector: 'app-spot-featured-card',
  templateUrl: './spot-featured-card.component.html',
  styleUrls: ['./spot-featured-card.component.scss']
})
export class SpotFeaturedCardComponent implements OnInit {
  @ViewChild('photoSlider', { static: false }) photoSlider: IonSlides;

  //Inputs
  @Input() fromCarousel?: boolean;
  @Input() listing: SpotBuildingDetailDTO;
  @Input() cardImgWithPadding?: boolean;
  @Input() localStorageKey: string;
  @Input() activeListingIds: any;
  @Input() activeSlideIndex: any;
  @Input() loadedListings: any;
  @Input() totalListingCount: any;
  @Input() lazyLoadImages: boolean;
  @Input() isLoadFromMain?: boolean;
  @Input() searchCriteria?: any;
  @Input() checkBoxValues?: any;
  @Input() searchTags?: any;
  @Input() reduceCardHeight?: boolean = false;

  hasPointer: boolean;
  isMobile: boolean = false;
  screenWidth: number;
  dialogRef: any;
  currentPhotoIndex = 0;
  count = 0;
  defaultImage: string = this.platformConfigHelper.Defaults().imagePlaceholder;
  noPhotos: boolean = true;
  favSize: SizeIcon = SizeIcon.LG;
  spotName: string;

  constructor(
    private imageService: ImageService,
    private router: Router,
    private detailPositionService: DetailPositionService,
    private platformConfigHelper: PlatformHelperService,
    private dialog: MatDialog,
    private commonService: CommonGenericService,
    private i18nService: I18nService,
    private spotBuildingsService: SpotBuildingsService,
    private spacesService: SpotBuildingSpaceService,
    private listingSliderService: AppListingSliderService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.screenWidth = window.innerWidth;
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit() {
    const isOffice = this.commonService.isPropertyType(2001, this.listing);
    this.spotName = this.i18nService.getTranslation(this.listing.buildingTitle);
    this.hasPointer = window.matchMedia('(pointer: fine)').matches;
    this.noPhotos = !this.listing.buildingListingPhotos || this.listing.buildingListingPhotos.length == 0;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  changeSlide(event: any) {
    let swipeDirection = event.srcElement.dom7ElementDataStorage.swiper.swipeDirection;
    this.listingSliderService.setSlide(swipeDirection);
  }

  public getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  public getLargeImg(id: any) {
    return this.imageService.largeThumbnail(id);
  }

  public getCompanyLogoImg(id: any) {
    return id ? this.getMediumImg(id) : '../../../assets/no-company-logo.png';
  }

  openPreviewComponent($event: Event): void {
    $event.stopImmediatePropagation();
    this.dialogRef = this.dialog.open(PreviewImagesComponent, {
      height: '78%',
      width: '60%',
      data: this.listing,
      panelClass: 'custom-modalbox'
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  public async openCard(id: number, $event: Event, externalLink = false) {
    const state: any = {
      localStorageKey: this.localStorageKey,
      searchCriteria: this.searchCriteria,
      searchTags: this.searchTags,
      checkBoxValues: this.checkBoxValues,
      openUrl: true
    };
    $event.stopImmediatePropagation();

    let lang = this.i18nService.getCurrentLanguage();
    if (window.location.href.includes('/pt-br')) {
      lang = 'pt-br';
    } else if (window.location.href.includes('/en')) {
      lang = 'en';
    }

    let res: any = await this.spotBuildingsService.getSpotBuildingDetailURLFormat(id, lang).toPromise();
    if (res) {
      let detailsURL = res.detailsURL;
      if (this.i18nService.currentUrlContainsCurrentLanguage()) {
        detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
      }

      if (externalLink) {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          this.router.navigateByUrl(detailsURL);
        } else {
          window.open(detailsURL, '_blank');
        }
        return;
      }
      let spacesFilter: SpacesFilterDTO = Object.assign({}, this.searchCriteria);
      this.spacesService.saveSpacesFilter(spacesFilter);
      if (this.isMobile) {
        this.router.navigateByUrl(detailsURL, { state: state });
      } else {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          this.router.navigateByUrl(detailsURL);
        } else {
          window.open(detailsURL, '_blank');
        }
      }
      this.detailPositionService.setPositionData(this.activeListingIds);
    }
  }

  public getNextPhoto(id: number, $event: Event) {
    this.photoSlider.getActiveIndex().then(index => {
      if (index < this.currentPhotoIndex) {
        this.currentPhotoIndex = index;
      } else {
        if (this.currentPhotoIndex === this.listing.buildingListingPhotos.length - 1 && this.count == 0) {
          this.openCard(id, $event);
        } else {
          if ($event.type === 'click') {
            this.photoSlider.slideNext();
            this.updatePhotoIndex();
            this.count = 1;
          } else {
            if (this.count == 1) {
              this.count = 0;
            } else {
              this.updatePhotoIndex();
            }
          }
        }
      }
    });
  }

  public getPreviousPhoto() {
    if (this.currentPhotoIndex === 0) {
      this.photoSlider.slideTo(this.listing.buildingListingPhotos.length);
    } else {
      this.photoSlider.slidePrev();
    }
    this.updatePhotoIndex();
  }

  public showPrevNextNav() {
    return [this.hasPointer, this.listing.buildingListingPhotos && this.listing.buildingListingPhotos.length > 1].every(
      condtion => condtion
    );
  }

  getCroppedOrOriginalImage(listingPhoto: any) {
    if (listingPhoto) {
      if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
        return listingPhoto.croppedImage.id;
      }
      return listingPhoto.image.id;
    }
  }

  public updatePhotoIndex() {
    this.photoSlider.getActiveIndex().then(index => {
      this.currentPhotoIndex = index;
    });
  }

  slideToIndex(event: Event, index: number) {
    event.stopPropagation();
    this.photoSlider.slideTo(index);
  }

  getCityState() {
    return `${this.i18nService.getTranslation(this.listing.cityName)} - ${this.i18nService.getTranslation(
      this.listing.stateName
    )}, Brasil`;
  }

  getSubHeaderCardInfo() {
    return `${this.i18nService.getTranslation(this.listing.buildingAddress)} `;
  }

  getTypeText() {
    return this.commonService.getTypeText(this.listing.type);
  }

  getPropertyTypeText() {
    return this.commonService.getPropertyTypeText(this.listing.buildingTypeId);
  }

  getAvailableAreaText() {
    return this.commonService.getAvailableAreaText(this.listing.spaceRangesDTO);
  }

  getAvgAskingRent() {
    return this.commonService.getAvgAskingRent(this.listing.spaceRangesDTO, this.listing.type);
  }

  getPositions(): any {
    return this.commonService.getPositions(this.listing.spaceRangesDTO);
  }
}
