import { Component, forwardRef, Input, OnInit, SimpleChanges } from '@angular/core';

import { MatDatepicker } from '@angular/material/datepicker';
import { SpotBuildingSearchRequest } from '@app/models/spot-buildings/spot-building-search.model';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _moment from 'moment';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { parse } from 'path';

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'YYYY'
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-filter-ranges-spotbuildings',
  templateUrl: './filter-ranges-spotbuildings.component.html',
  styleUrls: ['./filter-ranges-spotbuildings.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterRangesSpotbuildingsComponent),
      multi: true
    }
  ]
})
export class FilterRangesSpotbuildingsComponent implements OnInit {
  @Input('staticMinMax') staticMinMax: any;
  @Input('rangeModelValues') rangeModelValues: any;
  @Input('checkboxValues') checkboxValues: any;
  @Input('type') type: any;
  @Input('property') property: any;
  @Input('searchDto') searchDto: SpotBuildingSearchRequest;
  @Input('clear') clear: any;

  yearBuilt1: any;
  yearBuilt2: any;

  yearRenovated1: any;
  yearRenovated2: any;

  minDate = new Date(2010, 0, 1);
  maxDate = new Date(2022, 0, 1);

  constructor() {}

  ngOnInit(): void {
    if (this.searchDto.yearBuiltIni) {
      this.yearBuilt1 = new Date((this.searchDto.yearBuiltIni + 1).toString());
    }

    if (this.searchDto.yearBuiltEnd) {
      this.yearBuilt2 = new Date((this.searchDto.yearBuiltEnd + 1).toString());
    }

    if (this.searchDto.yearRenovatedIni) {
      this.yearRenovated1 = new Date((this.searchDto.yearRenovatedIni + 1).toString());
    }

    if (this.searchDto.yearRenovatedEnd) {
      this.yearRenovated2 = new Date((this.searchDto.yearRenovatedEnd + 1).toString());
    }

    this.initPositions();
  }

  ngOnChanges(changes: any) {
    if (changes.type) {
      this.type = changes.type.currentValue;
    }

    if (changes.property) {
      this.property = changes.property.currentValue;
    }

    if (changes.clear) {
      this.yearBuilt1 = null;
      this.yearBuilt2 = null;
      this.yearRenovated1 = null;
      this.yearRenovated2 = null;
    }

    this.initPositions();
  }

  setYearBuiltRenovated(event: any, tipeYear: number) {
    if (tipeYear == 1 && event.current != null) {
      this.searchDto.yearBuiltIni = event.next.year;
    }

    if (tipeYear == 2 && event.current != null) {
      this.searchDto.yearBuiltEnd = event.next.year;
    }

    if (tipeYear == 3 && event.current != null) {
      this.searchDto.yearRenovatedIni = event.next.year;
    }

    if (tipeYear == 4 && event.current != null) {
      this.searchDto.yearRenovatedEnd = event.next.year;
    }
  }

  checkValues(filterName: string) {
    if (
      this.rangeModelValues[filterName].lower < 0 ||
      this.rangeModelValues[filterName].lower == null ||
      this.rangeModelValues[filterName].lower > this.rangeModelValues[filterName].upper
    ) {
      this.rangeModelValues[filterName].lower = 0;
    }

    if (
      this.rangeModelValues[filterName].upper > this.staticMinMax[filterName].max ||
      this.rangeModelValues[filterName].upper == null
    ) {
      this.rangeModelValues[filterName].upper = this.staticMinMax[filterName].max;
    }

    this.rangeModelValues[filterName] = {
      lower: this.rangeModelValues[filterName].lower,
      upper: this.rangeModelValues[filterName].upper
    };
  }

  checkboxValue(checboxName: string, filterName: string) {
    if (this.checkboxValues[checboxName]) {
      this.rangeModelValues[filterName].lower = 0;
      this.rangeModelValues[filterName].upper = this.staticMinMax[filterName].max;
      this.rangeModelValues[filterName] = {
        lower: this.rangeModelValues[filterName].lower,
        upper: this.rangeModelValues[filterName].upper
      };
    }
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<_moment.Moment>) {
    if (!datepicker.opened) {
      datepicker.open();
    }
  }

  _yearSelectedHandler(chosenDate: _moment.Moment, datepicker: MatDatepicker<_moment.Moment>, tipeYear: number) {
    if (tipeYear == 1) {
      this.yearBuilt1 = chosenDate;
      this.searchDto.yearBuiltIni = chosenDate.year();
    }

    if (tipeYear == 2) {
      this.yearBuilt2 = chosenDate;
      this.searchDto.yearBuiltEnd = chosenDate.year();
    }

    if (tipeYear == 3) {
      this.yearRenovated1 = chosenDate;
      this.searchDto.yearRenovatedIni = chosenDate.year();
    }

    if (tipeYear == 4) {
      this.yearRenovated2 = chosenDate;
      this.searchDto.yearRenovatedEnd = chosenDate.year();
    }

    datepicker.close();
  }

  initPositions() {
    if (!this.searchDto.positions && this.type[0] === 'C') {
      this.searchDto.positions = null;
    }
  }
}
