import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { ImageService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-coworking',
  templateUrl: './coworking.component.html',
  styleUrls: ['./coworking.component.scss']
})
export class CoworkingComponent implements OnInit {
  @Input('stepper') stepper: MatStepper;
  @Input('coworkingInfo') coworkingInfo: any;
  @Input('listing') listing?: any;
  @Input('maximumPositions') maximumPositions: any;
  constructor() {}

  ngOnInit(): void {
    if (this.coworkingInfo != null && this.coworkingInfo.length > 0) {
      this.coworkingInfo[0].positionIni = 1;
      this.coworkingInfo[0].readOnly = true;
    }
  }

  removeTier(index: any) {
    this.coworkingInfo.splice(index, 1);
  }

  checkMaxNumber(value: any) {
    value = parseInt(value);
    if (!isNaN(value)) {
      this.maximumPositions[0].maximumPositions = value;
    }
  }

  checkIni(value: any, idx: any) {
    value = parseInt(value);
    if (!isNaN(value)) {
      this.coworkingInfo[idx].positionIni = value;
    }
  }

  checkDailyPosition(value: any, idx: any) {
    value = parseFloat(value);
    value = value.toFixed(2);
    if (!isNaN(value)) {
      this.coworkingInfo[idx].sharedDailyByPosition = parseFloat(value).toFixed(2);
    }
  }

  checkMonthlyPosition(value: any, idx: any) {
    value = parseFloat(value);
    value = value.toFixed(2);
    if (!isNaN(value)) {
      this.coworkingInfo[idx].sharedMonthlyByPosition = value;
    }
  }

  checkPrivateDailyPosition(value: any, idx: any) {
    value = parseFloat(value);
    value = value.toFixed(2);
    if (!isNaN(value)) {
      this.coworkingInfo[idx].nonSharedDailyByPosition = value;
    }
  }

  checkPrivateMonthlyPosition(value: any, idx: any) {
    value = parseFloat(value);
    value = value.toFixed(2);
    if (!isNaN(value)) {
      this.coworkingInfo[idx].nonSharedMonthlyByPosition = value;
    }
  }

  addExtraCoworking() {
    let user: any = localStorage.getItem('user');
    this.coworkingInfo.push({
      id: null,
      listingId: null,
      positionIni: null,
      sharedDailyByPosition: null,
      sharedMonthlyByPosition: null,
      nonSharedDailyByPosition: null,
      nonSharedMonthlyByPosition: null,
      createdByUserId: user.id,
      createdDate: new Date(),
      readOnly: false
    });
  }
}
