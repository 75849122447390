import { Component, OnInit } from '@angular/core';
import { PopoverController, AlertController } from '@ionic/angular';
import { ContactDialogService } from '../contact-dialog/contact-dialog.service';
import { ListPropertyService } from '../../core/listings/list-property.service';
import { Router } from '@angular/router';
import { BreadcrumbOptions } from '@app/shared';
import { environment } from '@env/environment';
import { I18nService } from '@app/core';
import { Store } from '@ngrx/store';
import * as CartActions from '@app/store/actions';
declare var $: any;
declare var jquery: any;
declare var FourAllTransparentCheckout: any;

@Component({
  selector: 'four-all-payment',
  templateUrl: './four-all-payment.component.html',
  styleUrls: ['./four-all-payment.component.scss']
})
export class FourAllPaymentComponent implements OnInit {
  cartTotalPrice: any;
  cartElements: any;
  cart: any;
  spotAdminSelection: any;
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };

  constructor(
    private router: Router,
    private listPropertyService: ListPropertyService,
    private alertCtrl: AlertController,
    private i18nService: I18nService,
    private store: Store<any>
  ) {}

  getSelection() {
    this.cartTotalPrice = this.listPropertyService.getCartTotalPrice() + '00';
    this.cartElements = this.listPropertyService.getCartItems();
    this.spotAdminSelection = this.listPropertyService.getPaymentSpotAdminSelection();
    this.store.select(CartActions.GetItem).subscribe((store: any) => {
      this.cart = store.cart.items;
    });
  }

  async showPaymentMessage(message: string) {
    const alert = await this.alertCtrl.create({
      header: this.i18nService.get('global.purchase-options.paymentProcessed'),
      subHeader: message,
      buttons: [this.i18nService.get('global.purchase-options.paymentProcessedOK')]
    });
    alert.onDidDismiss().then(() => {
      this.router.navigateByUrl('/user-home/my-payments');
    });
    await alert.present();
  }

  ngOnInit(): void {
    this.getSelection();
    FourAllTransparentCheckout({
      //publicApiKey: 'BeWT3k/vypkk+pbCv0DR43TM7Lb33GAY5hB2z/yRoa4=',
      // T0nBElMzOyT24f1HchAnRCHBOiKPRFXuvJ49raqTnkU=
      publicApiKey: environment.publicApiKeyPaylee,
      //publicApiKey: 'T0nBElMzOyT24f1HchAnRCHBOiKPRFXuvJ49raqTnkU=',
      backendAPI: this.listPropertyService,
      cartElements: this.cartElements,
      cart: this.cart,
      store: this.store,
      spotAdminSelection: this.spotAdminSelection,
      element: document.getElementById('payment-area'),
      form: document.getElementById('form'),
      paymentMessage: this.showPaymentMessage,
      i18nService: this.i18nService,
      alertCtrl: this.alertCtrl,
      router: this.router,

      primaryColor: '#043650',
      primaryFont: 'Arial, sans-serif',

      submitBorder: '15px',
      titleBorder: '15px',

      requireAddress: false,

      /*customer: {
        fullName: 'John Doe',
        emailAddress: 'john@doe.com.br',
        cpf: '03003003003',
        phoneNumber: '5551999995555',
        birthday: '1990-10-10'
      },*/

      payment: {
        amount: Number(this.cartTotalPrice),
        subtotal: Number(this.cartTotalPrice),
        shipping: 0,
        installments: 3
      },

      onLoad: function() {
        // Callback opcional
      },

      onError: function() {
        // Callback opcional
      },

      performPayment: function(event: Event) {
        JSON.stringify($('form').serializeArray());
        var loginForm = $('form').serializeArray();
        var loginFormObject: any = {};
        loginFormObject.customer = {}; // this.customer;
        loginFormObject.payment = this.payment;
        $.each(loginForm, function(t: any, v: any) {
          loginFormObject[v.name] = v.value;
        });

        loginFormObject.customer.email = loginFormObject.email;
        loginFormObject.customer.birthday = loginFormObject.birthday;
        loginFormObject.customer.cpf = loginFormObject.cpf;
        loginFormObject.customer.fullName = loginFormObject.fullName;
        loginFormObject.customer.phoneNumber = loginFormObject.phoneNumber;
        loginFormObject.purchaseOptions = this.cartElements;

        if (this.spotAdminSelection) {
          loginFormObject.userId = this.spotAdminSelection.userId;
          loginFormObject.companyId = this.spotAdminSelection.companyId;
        }

        this.backendAPI
          .fourAllPaymentTest(loginFormObject)
          .toPromise()
          .then((result: any) => {
            let message = '';
            if (result) {
              if (result.status.id === 4) {
                message = this.i18nService.get('global.purchase-options.paymentTrxCaptured');
              } else {
                // tslint:disable-next-line: max-line-length
                message = `${this.i18nService.get(
                  'global.purchase-options.paymentMsgSubheader'
                )} ${this.i18nService.getTranslation(result.status.description)}`;
              }
            }
            this.paymentMessage(message);
            this.cart.forEach((item: any, idx: number) => {
              this.store.dispatch(CartActions.RemoveItem({ payload: { item: { id: item.id }, index: idx } }));
            });
          });
        event.preventDefault();
      }
    });
  }

  goBack() {
    this.router.navigateByUrl('/cart');
  }
}
