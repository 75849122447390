<ion-row>
  <ion-col style="width: 100%" size="12">
    <h5 translate="global.list-your-property.addPhotos"></h5>
  </ion-col>
  <ion-col style="width: 100%" size="12">
    <button mat-raised-button (click)="inputFile.click()" class="next-btn">
      {{ 'FileUpload' | translate }}
    </button>
    <input
      #inputFile
      id="inputFile"
      type="file"
      multiple
      class="input-margin-bottom"
      (change)="fileChangeEvent($event, false)"
      style="visibility:hidden;"
    />
  </ion-col>

  <ion-row>
    <i *ngIf="!imgList.length">{{ 'required' | translate }}</i>
  </ion-row>

  <ion-row>
    <ion-reorder-group class="full-width" (ionItemReorder)="doReorder($event)" disabled="false">
      <ion-row no-padding *ngFor="let img of imgList; let i = index" class="img-card-preview standard-shadow">
        <div *ngIf="img" class="full-width">
          <ion-row class="badge-row full-width">
            <ion-badge class="margin-5" color="secondary">{{ i + 1 }}</ion-badge>
            <ion-icon class="trash-icon" (click)="removeImage($event, i)" name="trash"></ion-icon>
          </ion-row>

          <ion-item no-padding class="full-width">
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-label
                    class="edit-label"
                    no-padding
                    no-margin
                    color="secondary"
                    [translate]="'global.user.editImage'"
                    (click)="openEditImageModal(i)"
                  >
                  </ion-label>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col size="5" no-padding>
                  <img class="img-contain" [src]="getImage(img)" />
                </ion-col>

                <ion-col size="7" no-padding class="img-caption-col">
                  <ion-row>
                    <ion-col no-padding size="12">
                      <ion-input
                        type="text"
                        *ngIf="img.image"
                        [(ngModel)]="img.image.caption"
                        (ionBlur)="captionChangeListener(i, $event)"
                        class="input-border transform-initial"
                        placeholder="{{ 'global.insertCaption' | translate }}"
                      ></ion-input>
                      <ion-input
                        type="text"
                        *ngIf="!img.image"
                        [(ngModel)]="img.caption"
                        class="input-border transform-initial"
                        placeholder="{{ 'global.insertCaption' | translate }}"
                      >
                      </ion-input>
                    </ion-col>
                  </ion-row>

                  <ion-row class="content-flex-row">
                    <ion-col size="10" class="img-content-col">
                      <span class="full-width font-size-12 transform-initial ow-break-word"
                        >{{ getImageContent(img) }}
                      </span>
                    </ion-col>
                    <ion-col size="2" no-padding class="reorder-end">
                      <ion-reorder></ion-reorder>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </div>
      </ion-row>
    </ion-reorder-group>
  </ion-row>
</ion-row>

<ion-row class="full-width margin-top-5 margin-top-60">
  <ion-col size="12">
    <ion-label style="margin-top: 20px;" class="full-width">
      <h5 class="title">{{ 'global.virtualTourURL' | translate }}</h5>
      <br />
      {{ 'global.virtualTourContent' | translate }} https://www.youtube.com/embed/...
      {{ 'global.virtualTourContent2' | translate }}
    </ion-label>
    <ion-textarea
      [ngClass]="{ 'red-border': videoURLInvalid }"
      class="input-border"
      (ionChange)="validateCustomURL()"
      type="text"
      [(ngModel)]="vrTourUrl"
    ></ion-textarea>
    <ion-label class="form-error" *ngIf="videoURLInvalid">
      {{ 'global.my-list-menu.invalidURL' | translate }}
    </ion-label>
  </ion-col>
</ion-row>
