import { Component, OnInit, HostListener, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { ListPropertyService } from '@app/core/listings/list-property.service';

@Component({
  selector: 'app-popover-type-page',
  templateUrl: './listing-proptype-popover.component.html',
  styleUrls: ['./listing-proptype-popover.component.scss']
})
export class ListingProptypePopoverComponent implements OnInit {
  checkboxValues: any;
  propertyType: any;
  expandFilters: any;
  rangeFilters: any;
  btnFilter: boolean = false;
  minMaxvalues: any;
  rangeModelValues: any;
  staticMinMax: any;
  searchDto: any;
  loading: boolean = true;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private listingService: ListPropertyService
  ) {}

  ngOnInit(): void {
    this.getPopData();
    this.setMinOptions(this.rangeFilters, this.searchDto);
  }

  getPopData() {
    this.checkboxValues = this.navParams.data.checkboxValues;
    if (this.navParams.data.propType === '') {
      this.propertyType = null;
    } else {
      this.propertyType = this.navParams.data.propType;
      this.searchDto = this.navParams.data.searchDto;
    }
  }

  setMinOptions(filter: any, searchDto: any) {
    this.listingService
      .getMinMaxOptions(searchDto)
      .toPromise()
      .then((result: any) => {
        localStorage.setItem('minMax', JSON.stringify(result));
        this.staticMinMax = this.listingService.setStaticMinMaxValues(result);
        this.rangeModelValues = this.listingService.mapMinMaxOptions(this.searchDto, this.staticMinMax);
        // if (Object.entries(this.searchDto).length === 0 && this.searchDto.constructor === Object!) {

        // } else {
        //   if (this.listingService.isRangeFilters(this.searchDto)) {
        //     this.rangeModelValues = this.listingService.mapMinMaxOptions(this.searchDto, this.staticMinMax);
        //   } else {
        //     this.rangeModelValues = this.listingService.mapMinMaxOptions(this.rangeFilters, this.staticMinMax);
        //   }
        // }
        this.loading = false;
      });
  }

  dismiss() {
    this.rangeFilters = this.listingService.setSearchRangeFilters(this.rangeModelValues, this.staticMinMax);
    let searchCopyDto = { ...this.searchDto, ...this.rangeFilters };
    if (searchCopyDto.ceilingHeightMax === this.staticMinMax.ceilingHeight.max) {
      searchCopyDto.ceilingHeightMax = null;
    }
    if (searchCopyDto.offeringPriceEnd === this.staticMinMax.offeringPrice.max) {
      searchCopyDto.offeringPriceEnd = null;
    }
    if (searchCopyDto.offeringPricePerAreaEnd === this.staticMinMax.offeringPricePerArea.max) {
      searchCopyDto.offeringPricePerAreaEnd = null;
    }
    if (searchCopyDto.monthlyRentMax === this.staticMinMax.monthlyRent.max) {
      searchCopyDto.monthlyRentMax = null;
    }
    if (searchCopyDto.monthlyRentPerAreaMax === this.staticMinMax.monthlyRentPerArea.max) {
      searchCopyDto.monthlyRentPerAreaMax = null;
    }
    if (searchCopyDto.rentableAreaEnd === this.staticMinMax.rentableArea.max) {
      searchCopyDto.rentableAreaEnd = null;
    }
    this.popoverCtrl.dismiss(searchCopyDto);
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }

  clearSearch() {
    this.searchDto = this.listingService.clearFilters(this.searchDto, this.checkboxValues);
    this.rangeModelValues = this.listingService.mapMinMaxOptions(null, this.staticMinMax);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.cancel();
    }
  }
}
