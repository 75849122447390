import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ImageService } from '@app/core/image.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType, UserActivity } from '@app/models/user-activity.model';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-premium-agents',
  templateUrl: './premium-agents.component.html',
  styleUrls: ['./premium-agents.component.scss']
})
export class PremiumAgentsComponent implements OnInit {
  @Input('listing') listing: any;
  brokerPhone: any = [];
  brokerEmail: any = [];
  brokerWhatsapp: any = [];
  imagePlaceholder: string = 'assets/images/default-profile-photo.png';
  unsetBioOverflow: boolean = false;
  currentDevice: string;
  isDesktop: string;
  isMobilePhone: string;
  isTablet: string;
  constructor(
    private router: Router,
    private imgService: ImageService,
    private userActivityService: UserActivityService,
    private analyticsService: AnalyticsService,
    private deviceDetector: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
    this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
    this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
    if (this.isDesktop.length) {
      this.currentDevice = this.isDesktop;
    } else if (this.isMobilePhone.length) {
      this.currentDevice = this.isMobilePhone;
    } else if (this.isTablet.length) {
      this.currentDevice = this.isTablet;
    }
    if (this.listing && this.listing.premiumAgents) {
      Object.keys(this.listing.premiumAgents).forEach((key, index) => {
        this.brokerPhone[index] = false;
        this.brokerEmail[index] = false;
        this.brokerWhatsapp[index] = false;
      });
    }
  }

  showBrokerEmail(index: number) {
    this.brokerEmail[index] = true;
    this.registerUserActivity(this.listing.premiumAgents[index].id, UserActivityType.BROKER_SHOWEMAIL);
  }

  unsetOverflow() {
    this.unsetBioOverflow = true;
  }

  checkOverflow() {
    let el = document.getElementById('#bio');
    var curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

    var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
  }

  showBrokerPhone(index: number) {
    this.brokerPhone[index] = true;
    this.registerUserActivity(this.listing.premiumAgents[index].id, UserActivityType.BROKER_SHOWPHONE);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
  }

  showBrokerWhatsapp(index: number) {
    this.brokerWhatsapp[index] = true;
    this.registerUserActivity(this.listing.premiumAgents[index].id, UserActivityType.BROKER_SHOWWHATSAPP);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
  }

  navigateToBroker(id: any) {
    this.registerUserActivity(id, UserActivityType.SPECIALIST_DETAIL);
    this.router.navigateByUrl(`/listing-user/${id}`, { state: { listing: this.listing } });
  }

  getMediumImg(id: any) {
    return this.imgService.mediumThumbnail(id);
  }

  registerUserActivity(premiumAgentId: number, userActivityType: UserActivityType) {
    const userActivity: UserActivity = {
      module: userActivityType,
      brokerId: premiumAgentId,
      listingId: this.listing.id
    };
    this.userActivityService.saveUserActivity(userActivity);
  }
}
