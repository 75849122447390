import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatStepper } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { I18nService } from '@app/core/i18n.service';
import { SendNotification } from '@app/models/send-notification.model';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { SpotBuildingPhoto } from '@app/models/spot-buildings/spot-building-photo.model';
import { SpotBuildingDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { SendNotificationComponent } from '@app/shared/send-notification/send-notification.component';
import { ImageUploadService } from '@app/shared/services/image-upload.service';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'create-spot-buildings',
  templateUrl: './create-spot-buildings.component.html',
  styleUrls: ['./create-spot-buildings.component.scss']
})
export class CreateSpotBuildingsComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  currentStep: any = 0;
  uploadingImages: boolean = false;
  imgList: SpotBuildingPhoto[] = [];
  spotBuildings = new CreateSpotBuildingsRequest();
  createSpotBuildingsResponse: any;
  typeOffer: string = '';
  propertyType: number[] = [];
  buildingDetail: SpotBuildingDTO;
  entireSale: boolean = false;
  emitChanges: boolean = false;
  saveImages: boolean = true;
  isMobile: boolean = false;

  constructor(
    private createSpotBuildingsService: CreateSpotBuildingsService,
    private imageUploadService: ImageUploadService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private i18NService: I18nService,
    private alertCtrl: AlertController,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private routingHelperSrv: RoutingHelperService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.spotBuildings.enabled = false;
    this.getParamsUrl();
  }

  ngOnInit() {}

  private async getParamsUrl() {
    this.activatedRoute.params.subscribe(async paramMap => {
      try {
        let baseId = paramMap.buildingId;
        const buildingIdParam = paramMap.buildingId;
        const listingTypeParam = paramMap.buildingType;

        if (buildingIdParam) {
          this.createSpotBuildingsService
            .getSpotBuildingsFromBuilding(buildingIdParam, listingTypeParam, this.i18NService.getCurrentLanguage())
            .then((response: SpotBuildingDTO) => {
              this.buildingDetail = response;
              if (this.buildingDetail.buildingListingPhotos) {
                this.imgList = this.buildingDetail.buildingListingPhotos;
              }
            });
        }
      } catch (e) {}
    });
  }

  goBack() {
    this.stepper.previous();
    this.currentStep = this.currentStep - 1;
  }

  goForward() {
    this.currentStep = this.currentStep + 1;
    if (this.currentStep == 2) {
      this.typeOffer = this.spotBuildings.type;
      this.entireSale = this.spotBuildings.entireSale;
      this.emitChanges = !this.emitChanges;
    }

    this.stepper.next();
  }

  async saveBuilding() {
    this.saveImages = false;
    if (this.buildingDetail) {
      this.spotBuildings.id = this.buildingDetail.id;
      this.createSpotBuildingsResponse = await this.createSpotBuildingsService.updateSpotBuildings(this.spotBuildings);
    } else {
      this.createSpotBuildingsResponse = await this.createSpotBuildingsService.createSpotBuildings(this.spotBuildings);
      this.spotBuildings.id = this.createSpotBuildingsResponse.id;
    }

    this.saveImages = true;
    let photos = this.imageUploadService.associatePhotosToBuildingListing(
      this.createSpotBuildingsResponse.id,
      this.imgList
    );

    this.goForward();
  }

  closeBuilding() {
    /* this.sendNotification(); */
    this.openModalNotification();
  }

  async sendNotification() {
    const alert = await this.alertCtrl.create({
      subHeader: this.i18NService.get('notifications.sendNotification'),
      cssClass: 'ion-alert-listings',
      backdropDismiss: true,
      buttons: [
        {
          text: this.i18NService.get('global.revisionChanges.no'),
          role: 'cancel',
          handler: () => {
            this.routingHelperSrv.navigateToSearchPage(true);
          }
        },
        {
          text: this.i18NService.get('global.revisionChanges.yes'),
          handler: () => {
            this.openModalNotification();
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {});
    await alert.present();
  }

  openModalNotification() {
    let notify: SendNotification = {
      content: '',
      spotId: this.buildingDetail ? this.buildingDetail.id : this.spotBuildings.id,
      newSpotBuilding: this.buildingDetail ? false : true,
      predefinedNotificationId: null
    };
    let dialogRef = this.dialog.open(SendNotificationComponent, {
      width: this.isMobile ? '100%' : '600px',
      maxWidth: this.isMobile ? '100%' : '',
      height: this.isMobile ? '100%' : '',
      data: {
        notify: notify
      },
      autoFocus: true,
      restoreFocus: true
    });

    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.routingHelperSrv.navigateToSearchPage(true);
      }
    });
  }
}
