import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginFormDialogService } from '../login';
import { AuthenticationService } from '@app/core/auth';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
export class FavoriteSpotService {
    constructor(http, loginFormDialog, userActivityService, _ts, router, auth) {
        this.http = http;
        this.loginFormDialog = loginFormDialog;
        this.userActivityService = userActivityService;
        this._ts = _ts;
        this.router = router;
        this.auth = auth;
        this.favoriteListing = 'api/spot/favorites/';
        this.spotSetAPI = '/api/spotset/';
        this.favoritesCountSubject = new BehaviorSubject(0);
        this.tourBook = 'api/tourbookdashboard/';
        this.savedSearchesAPI = 'api/savedsearchesdashboard/';
        this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe((res) => {
            this.customLoginText = res;
        });
        if (this.auth) {
            this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => {
                this.isLoggedIn = val;
            });
        }
    }
    toggleFavoriteListing(listing, isFavorite) {
        listing.favorite = isFavorite;
        let currentFavoritesCount = this.favoritesCountSubject.getValue();
        if (isFavorite) {
            return this.http.delete(`${this.favoriteListing}${listing.id}/unmark`).pipe(tap(() => {
                listing.favorite = false;
                if (this.isActiveListing(listing)) {
                    this.favoritesCountSubject.next(currentFavoritesCount - 1);
                }
            }));
        }
        else {
            this.favoriteActivityLog(listing);
            return this.http.put(`${this.favoriteListing}${listing.id}/mark`, {}).pipe(tap(() => {
                listing.favorite = true;
                if (this.isActiveListing(listing)) {
                    this.favoritesCountSubject.next(currentFavoritesCount + 1);
                }
            }));
        }
    }
    getFavoriteListings() {
        return this.http.get(this.favoriteListing);
    }
    isActiveListing(listing) {
        return listing;
    }
    favoriteActivityLog(listing) {
        this.userActivityService.trackSpotBuildingFavorite(listing.id);
    }
    updateFavoritesCount() {
        this.getFavoriteListings().subscribe((val) => {
            this.favoritesCountSubject.next(val.filter(l => l.listingStatus.id == 4).length);
        });
    }
    openLoginDialog() {
        const currentRoute = this.router.url;
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: this.customLoginText
        });
    }
    setFavoriteSpot(event, spot) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            event.stopPropagation();
            let promise = new Promise((resolve, reject) => {
                if (!this.isLoggedIn) {
                    this.openLoginDialog()
                        .afterClosed()
                        .subscribe(() => {
                        if (this.isLoggedIn) {
                            this.toggleFavoriteListing(spot, spot.favorite || false).subscribe((res) => {
                                resolve(res);
                            });
                        }
                    });
                }
                else {
                    this.toggleFavoriteListing(spot, spot.favorite || false).subscribe((res) => {
                        resolve(res);
                    });
                }
            });
            return promise;
        });
    }
    getSpotSetFavorite(page, lang, bodySpotSet) {
        return this.http.post(`${this.spotSetAPI}search?page=${page}&per_page=10&lang=${lang}`, bodySpotSet).toPromise();
    }
    deleteAction(id, apiName) {
        return this.http.delete(`${apiName}${id}`).toPromise();
    }
    getTourBook(page, lang, body) {
        return this.http.post(`${this.tourBook}search?page=${page}&per_page=10&lang=${lang}`, body).toPromise();
    }
    sendEmail(id, email, lang) {
        return this.http.post(`${this.tourBook}share/${id}/${lang}`, email).toPromise();
    }
    editTitleTourBook(editTitle) {
        return this.http
            .patch(this.tourBook, editTitle, {
            observe: 'response'
        })
            .pipe(map(val => {
            return val.status;
        }))
            .toPromise()
            .catch(val => {
            return val.status;
        });
    }
    getSavedSearches(page, lang, body) {
        return this.http.post(`${this.savedSearchesAPI}search?page=${page}&per_page=10&lang=${lang}`, body).toPromise();
    }
}
