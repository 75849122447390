<div class="container" style="padding: 22px;">
  <section>
    <span>{{ data.message }}</span>
  </section>
  <footer style="text-align: end;margin-top: 10px;">
    <button (click)="closeModal()" style="margin-top: 12px;" class="btn-cancel-set btn-mobile">
      {{ 'close' | translate }}
    </button>
    <button (click)="closeModalConfirm()" style="margin-top: 12px;" class="btn-cancel-set btn-mobile">
      {{ 'favorites.confirm' | translate }}
    </button>
  </footer>
</div>
