<ion-grid
  class="height-small"
  *ngIf="!loading"
  [ngClass]="{
    'height-tall': selectedPropertyType === '1001' || selectedPropertyType === '2001' || selectedPropertyType === 'C'
  }"
>
  <ion-list>
    <ion-radio-group [(ngModel)]="selectedPropertyType" class="top-search-btn" style="font-size: 1.3em;">
      <ion-item>
        <ion-label>{{ 'global.all' | translate }}</ion-label>
        <ion-radio slot="end" value="null" checked (ionSelect)="propertyTypeChanged($event)"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{ 'global.list-your-property.coworking' | translate }}</ion-label>
        <ion-radio slot="end" value="C" (ionSelect)="propertyTypeChanged($event)"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{ 'global.list-your-property.industrial' | translate }}</ion-label>
        <ion-radio slot="end" value="1001" (ionSelect)="propertyTypeChanged($event)"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{ 'global.list-your-property.office' | translate }}</ion-label>
        <ion-radio slot="end" value="2001" (ionSelect)="propertyTypeChanged($event)"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>

  <ion-list
    *ngIf="propertySubTypes && propertySubTypes.length > 0"
    class="top-search-btn"
    [ngClass]="{ 'list-addition': selectedPropertyType === '1001' || selectedPropertyType === '2001' }"
    style="font-size: 1.3em;"
  >
    <ion-item *ngFor="let entry of propertySubTypes">
      <ion-label>{{ entry.name }}</ion-label>
      <ion-checkbox slot="start" [(ngModel)]="entry.isChecked"></ion-checkbox>
    </ion-item>
  </ion-list>
</ion-grid>

<ion-row class="fixed-ion-btnrow" *ngIf="!loading">
  <ion-col size="12">
    <ion-button class="float-right" color="light" fill="clear" (click)="update()" translate="Update"></ion-button>
    <ion-button class="float-right" color="light" fill="clear" (click)="cancel()" translate="Cancel"></ion-button>
  </ion-col>
</ion-row>
