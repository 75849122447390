import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatTabGroup } from '@angular/material';
import { CommonGenericService } from '@app/core/common.service';
import { I18nService } from '@app/core/i18n.service';
import { SpotBuildingSearchRequest } from '../../models/spot-buildings/spot-building-search.model';
import { SpotBuildingsSearchService } from '../../spot-buildings/services/spot-buildings-search.service';
import { LocationService } from '../services/location.service';
import { EventEmitter } from '@angular/core';
import { AppSpotCardSlider } from '../spot-card-slider/spot-card-slider';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { RoutingHelperService } from '../../core/helpers/routing-helper.service';
import { TRANSACTION_TYPES } from '@app/models/transaction-type.model';
export class SpotCardTabsComponent {
    constructor(spotBuildingSearchService, commonService, locationService, i18NService, breakpointObserver, routingHelperService, cdr) {
        this.spotBuildingSearchService = spotBuildingSearchService;
        this.commonService = commonService;
        this.locationService = locationService;
        this.i18NService = i18NService;
        this.breakpointObserver = breakpointObserver;
        this.routingHelperService = routingHelperService;
        this.cdr = cdr;
        this.isFavorite = false;
        this.totalCountEvent = new EventEmitter();
        this.totalSpotBuildingsCount = 0;
        this.allSpotBuildings = [];
        this.currentPage = 1;
        this.pageSize = 12;
        this.currentCityFilterId = null;
        this.currentSpotBuildingType = 'L';
        this.tabIndex = 0;
        this.searching = false;
        this.isMobile = false;
        this.searchingMobile = false;
        this.currentPhotoIndex = 0;
        this.firstTime = false;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    ngAfterViewInit() {
        setTimeout(() => {
            this.updateInkBar();
        }, 500);
    }
    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }
    updateInkBar() {
        if (this.tabGroup) {
            this.tabGroup.realignInkBar();
        }
    }
    ngOnInit() {
        this.faHeart = faHeart;
        this.setSearching(true);
        this.countSpotsByType();
        this.allStateFilter = {
            id: null,
            name: this.i18NService.get('All')
        };
        this.cityFilters = [];
        this.forLeaseLbl = this.i18NService.get(TRANSACTION_TYPES[0].name);
        this.forSaleLbl = this.i18NService.get(TRANSACTION_TYPES[2].name);
        this.forCoworkingLbl = this.i18NService.get(TRANSACTION_TYPES[1].name);
    }
    countSpotsByType() {
        this.spotBuildingSearchService.countSpotsByType(this.spotBuildingSearchRequest).then((spotByTypes) => {
            if (spotByTypes) {
                this.showForLeaseTab = spotByTypes['L'] ? spotByTypes['L'] > 0 : false;
                this.showForSaleTab = spotByTypes['S'] ? spotByTypes['S'] > 0 : false;
                this.showForCoworkingTab = spotByTypes['C'] ? spotByTypes['C'] > 0 : false;
                this.currentSpotBuildingType = this.showForLeaseTab
                    ? 'L'
                    : this.showForSaleTab
                        ? 'S'
                        : this.showForCoworkingTab
                            ? 'C'
                            : null;
                if (!this.currentSpotBuildingType) {
                    this.setSearching(false);
                    return;
                }
                this.firstTime = true;
                if (!this.commonService.isMobileWidth()) {
                    this.fetchSpots(this.currentSpotBuildingType, this.currentPage);
                }
                else {
                    this.updatePayload(this.currentSpotBuildingType, null);
                }
            }
        });
    }
    tabChanged(tabChangeEvent) {
        this.setSearching(true);
        this.tabIndex = tabChangeEvent.index;
        this.currentSpotBuildingType =
            tabChangeEvent.tab.textLabel == this.forLeaseLbl
                ? 'L'
                : tabChangeEvent.tab.textLabel == this.forSaleLbl
                    ? 'S'
                    : tabChangeEvent.tab.textLabel == this.forCoworkingLbl
                        ? 'C'
                        : null;
        this.cityFilters = [];
        this.currentCityFilterId = this.allStateFilter.id;
        this.clearSearch();
        if (this.isMobile) {
            this.updatePayload(this.currentSpotBuildingType, null);
            this.findStatesByRequestFilter(this.currentSpotBuildingType);
        }
        else {
            this.fetchSpots(this.currentSpotBuildingType, this.currentPage);
        }
    }
    changeSpotType(type) {
        this.currentSpotBuildingType = type;
        this.clearSearch();
        if (this.isMobile) {
            this.updatePayload(this.currentSpotBuildingType, null);
            this.findStatesByRequestFilter(this.currentSpotBuildingType);
        }
        else {
            this.fetchSpots(this.currentSpotBuildingType, this.currentPage);
        }
    }
    clearSearch() {
        this.currentPage = 1;
        this.allSpotIds = [];
        this.allSpotBuildings = [];
    }
    applyCityFilter(stateId) {
        this.currentCityFilterId = stateId;
        this.setSearching(true);
        this.findAllSpotBuildingIds(this.currentSpotBuildingType, stateId).then((result) => {
            this.allSpotIds = result.body;
            this.totalSpotBuildingsCount = this.allSpotIds ? this.allSpotIds.length : 0;
            this.getSpotBuildingsPaginated(this.allSpotIds, this.currentPage, false);
        });
    }
    applyStateFilterOnMobile(event, stateId, sliderIndex) {
        this.currentCityFilterId = stateId;
        this.setSearching(true);
        if (sliderIndex >= 0) {
            this.currentPhotoIndex = sliderIndex;
            this.finishSearch();
        }
        this.updatePayload(this.currentSpotBuildingType, stateId);
    }
    updatePayload(spotBuildingType, stateId) {
        this.spotBuildingSearchRequest = Object.assign({}, this.spotBuildingSearchRequest, { stateId: stateId, listingLeaseOrSale: [...spotBuildingType] });
    }
    showMoreResults() {
        return this.totalSpotBuildingsCount - this.currentPage * this.pageSize > 0;
    }
    showMoreResultsAction() {
        this.currentPage++;
        this.getSpotBuildingsPaginated(this.allSpotIds, this.currentPage, true);
    }
    updateCounts(total) {
        if (total) {
            this.totalSpotBuildingsCount = total;
        }
        else {
            this.totalSpotBuildingsCount = this.allSpotIds ? this.allSpotIds.length : 0;
        }
        this.totalCountEvent.emit(this.totalSpotBuildingsCount);
    }
    spotsCountSlider(total) {
        this.finishSearch(total);
    }
    fetchSpots(spotBuildingType, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.setSearching(true);
            yield this.findAllSpotBuildingIds(spotBuildingType).then((result) => {
                this.allSpotIds = result.body;
                this.getSpotBuildingsPaginated(this.allSpotIds, page, false);
            });
            this.findStatesByRequestFilter(spotBuildingType);
        });
    }
    findStatesByRequestFilter(spotBuildingType) {
        this.getStatesByRequestFilter(spotBuildingType).then((states) => {
            this.cityFilters = [];
            if (states && states.length > 0) {
                states = states.map(state => {
                    const stateName = this.getDefaultTranslation(state.name);
                    state.name = stateName;
                    return state;
                });
                this.cityFilters.push(this.allStateFilter);
                states = this.locationService.putRioAndSaoPauloOnTop(states);
                if (states.length > 5) {
                    states = states.slice(0, 5);
                }
                this.cityFilters = [...states];
                this.cityFilters.unshift(this.allStateFilter);
                this.currentPhotoIndex = 0;
            }
        });
    }
    getStatesByRequestFilter(spotBuildingType) {
        if (this.portfolioFor == 'BROKER_DETAIL') {
            return this.locationService.getStatesByBrokerIdAndSpotBuildingType(this.spotBuildingSearchRequest.brokerId, spotBuildingType);
        }
        if (this.portfolioFor == 'COMPANY_DETAIL') {
            return this.locationService.getStatesByCompanyAndSpotBuildingType(this.spotBuildingSearchRequest.companyId, spotBuildingType);
        }
        return new Promise(() => []);
    }
    getDefaultTranslation(translationArtifact) {
        try {
            return this.i18NService.getTranslation(translationArtifact);
        }
        catch (e) {
            console.error(e);
        }
    }
    findAllSpotBuildingIds(spotBuildingType, stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (spotBuildingType && spotBuildingType == 'L' && this.isFavoriteSearch()) {
                this.spotBuildingSearchRequest.listingLeaseOrSale = [...spotBuildingType, 'C'];
            }
            else {
                this.spotBuildingSearchRequest.listingLeaseOrSale = [...spotBuildingType];
            }
            this.spotBuildingSearchRequest.stateId = stateId;
            this.clearSearch();
            return yield this.spotBuildingSearchService
                .searchSpotBuildingIds(this.spotBuildingSearchRequest, 1)
                .then((spotBulidingIdsResponse) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return spotBulidingIdsResponse;
            }));
        });
    }
    getSpotBuildingsPaginated(spotIds, page, concatNewResult) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!spotIds || spotIds.length == 0) {
                this.allSpotBuildings = [];
                this.finishSearch();
                return;
            }
            yield this.spotBuildingSearchService.getSpotBuildingDetails(page, this.pageSize, spotIds).then(result => {
                if (concatNewResult) {
                    let newListings = result;
                    if (newListings && newListings.length) {
                        this.allSpotBuildings = this.allSpotBuildings.concat(newListings);
                    }
                }
                else {
                    this.allSpotBuildings = result;
                }
                this.sortSpots();
            });
        });
    }
    finishSearch(total) {
        this.updateCounts(total);
        setTimeout(() => {
            this.setSearching(false);
            this.firstTime = false;
        }, 100);
    }
    sortSpots() {
        if (!this.getCurrentSort()) {
            this.allSpotBuildings = this.allSpotBuildings.sort((first, second) => Number(first.premium) - Number(second.premium));
        }
        this.finishSearch();
    }
    isMobileDevice() {
        return this.isMobile && this.spotCardSlider;
    }
    areThereSpotBuildings() {
        if (this.isMobileDevice()) {
            return this.spotCardSlider.allSpotIds && this.spotCardSlider.allSpotIds.length > 0;
        }
        return this.allSpotBuildings && this.allSpotBuildings.length > 0;
    }
    showNoFoundSection() {
        return !this.areThereSpotBuildings() && this.isFavoriteSearch();
    }
    isFavoriteSearch() {
        return this.portfolioFor && this.portfolioFor == 'FAVORITE_SEARCH';
    }
    isSearching() {
        if (this.isMobile) {
            return this.searchingMobile;
        }
        return this.searching;
    }
    goToSearch() {
        this.routingHelperService.navigateToSearchPage(false);
    }
    applySortBy(type, direction) {
        if (this.allSpotIds && this.allSpotIds.length > 0) {
            const sortDTO = {
                sortBy: type,
                direction: direction,
                spotBuildingType: this.currentSpotBuildingType,
                spotIds: [...this.allSpotIds]
            };
            this.setCurrentSort(sortDTO);
            if (sortDTO.spotIds) {
                this.spotBuildingSearchService.sortCurrentSpotBuildingIds(sortDTO).then((result) => {
                    const sortedIds = result.body;
                    this.getSpotBuildingsPaginated(sortedIds, 1, false);
                });
            }
        }
    }
    isActiveSort(type, direction) {
        const currentSortFilter = this.getCurrentSort();
        if (currentSortFilter) {
            return currentSortFilter.sortBy == type && currentSortFilter.direction == direction;
        }
        return false;
    }
    clearSortSelection() {
        this.setCurrentSort(undefined);
        this.getSpotBuildingsPaginated(this.allSpotIds, 1, false);
    }
    setCurrentSort(sortBy) {
        this.spotBuildingSearchRequest.sortSpotsBy = sortBy;
    }
    getCurrentSort() {
        return this.spotBuildingSearchRequest.sortSpotsBy;
    }
    getHeaderResults() {
        return `${this.totalSpotBuildingsCount} ${this.i18NService.get('global.savedAsFav')}`;
    }
    hideFavoriteIcon() {
        return false;
    }
    favoriteChange(changedFavorite) {
        if (this.onlyFavorites) {
            this.keepFavoriteSpots(changedFavorite);
            this.updateCounts();
        }
    }
    keepFavoriteSpots(updatedFavorite) {
        this.allSpotBuildings = this.allSpotBuildings.filter(spot => spot.favorite);
        if (updatedFavorite && updatedFavorite.id) {
            this.allSpotIds = this.allSpotIds.filter(spotId => spotId != updatedFavorite.id);
        }
    }
    setSearching(value) {
        if (this.isMobile) {
            this.searchingMobile = value;
        }
        else {
            this.searching = value;
        }
    }
}
