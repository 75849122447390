import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { I18nService } from '../../core/i18n.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HomePageAppService {
  private allSolutions: any;
  foundSolutions = new EventEmitter<any>();

  constructor(private i18n: I18nService, private http: HttpClient) {
    this.fetchSolutions();
  }

  fetchSolutions() {
    if (!this.allSolutions) {
      this.http
        .get(`${environment.apiURL}/content/solution?countryCode=BR`)
        .toPromise()
        .then((solutions: any) => {
          this.allSolutions = solutions;
          this.foundSolutions.emit(this.allSolutions);
          return this.allSolutions;
        });
    }
    return this.allSolutions;
  }

  getAllSolutions() {
    return this.allSolutions;
  }

  private getHomePageAppURL() {
    return environment.serverUrl;
  }

  getMarketAnalyticsURL() {
    return this.i18n.addCurrentLanguageToPath(`${this.getHomePageAppURL()}/marketanalytics/lang`);
  }

  getIndicesURL() {
    return this.i18n.addCurrentLanguageToPath(`${this.getHomePageAppURL()}/products/102/siila-brasil-index/lang`);
  }

  getFiiDataProURL() {
    return this.i18n.addCurrentLanguageToPath(`${this.getHomePageAppURL()}/fii-data-pro/lang`);
  }

  getSpotURL() {
    return this.i18n.addCurrentLanguageToPath(`${this.getHomePageAppURL()}/SPOT/lang`);
  }

  getProductsURL() {
    return this.i18n.addCurrentLanguageToPath(`${this.getHomePageAppURL()}/products/lang`);
  }

  getAcademyURL() {
    return this.i18n.addCurrentLanguageToPath(`${this.getHomePageAppURL()}/academy/lang`);
  }

  getGrocsURL() {
    return this.i18n.addCurrentLanguageToPath(`${this.getHomePageAppURL()}/products/101/grocs/lang`);
  }

  getInvestorSurveyURL() {
    return this.i18n.addCurrentLanguageToPath(`${this.getHomePageAppURL()}/products/103/investor-survey/lang`);
  }

  private adjustURL(url: string) {
    url = url.startsWith('/') ? url : '/' + url;
    url = url.toLocaleLowerCase();
    url = url.replace(/\s+/g, '-');
    return `${this.getHomePageAppURL()}${url}` + this.getHomepageLangURL();
  }

  // Solutions
  private getSolutionURL(solId: number) {
    try {
      if (this.allSolutions) {
        const solution = this.fetchSolutions().filter((sol: any) => sol.id == solId)[0];
        return this.getSolutionURLByObject(solution);
      }
    } catch (error) {
      console.error('No solution found ', solId);
    }
  }

  getSolutionURLByObject(solution: any) {
    if (solution) {
      const URL = this.adjustURL(`solutions/${solution.id}/${this.i18n.getTranslation(solution.solutionName)}`);
      return URL;
    }
  }

  private getHomepageLangURL() {
    return '/lang/' + this.i18n.getCurrentLanguage();
  }

  getInstitutionalInvestorsURL() {
    return this.getSolutionURL(101);
  }

  getOwnersDevelopersURL() {
    return this.getSolutionURL(103);
  }

  getTenantsOccupiersURL() {
    return this.getSolutionURL(104);
  }

  getBrokerageFirmsURL() {
    return this.getSolutionURL(102);
  }

  getValuationAdvisorsURL() {
    return this.getSolutionURL(105);
  }

  getServiceProvidersURL() {
    return this.getSolutionURL(106);
  }

  getReitInvestorsURL() {
    return this.getSolutionURL(107);
  }
}
