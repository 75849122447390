import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { FavoriteSpotService } from '@app/shared/services/favorite-spot.service';
export class FavoritesComponent {
    constructor(breakpointObserver, favoriteService) {
        this.breakpointObserver = breakpointObserver;
        this.favoriteService = favoriteService;
        this.favoritesCount = 0;
        this.displayScrollToTop = false;
        this.isMobile = false;
        this.spotBuildingSearchRequest = {};
        this.isFavorite = true;
        this.listSpotSet = [];
        this.selectedIndex = 0;
        this.countSpotSet = 0;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.spotBuildingSearchRequest.listingClasses = ['FEATURED', 'PREMIUM', 'PROMOTED'];
            this.spotBuildingSearchRequest.favoriteSearch = true;
        });
    }
    updateCounts(total) {
        this.favoritesCount = total;
    }
    getHeaderResults() {
        return `${this.favoritesCount} SPOTs Saved As Favorites`;
    }
    isFooterVisible(event) {
        this.displayScrollToTop = event.visible;
    }
    changeStep(step) {
        this.selectedIndex = step;
    }
    eventSpotSet(event) {
        this.countSpotSet = event;
    }
}
