<form [formGroup]="detailsForm" class="full-width rel-pos" id="detailStep" name="detailsForm" autocomplete="off">
  <ion-row *ngIf="!detailsForm.value.listed" class="full-width margin-top-5">
    <ion-label class="full-width">{{ 'global.list-your-property.propertyName' | translate }} </ion-label>
    <ion-row class="search-row">
      <ion-searchbar
        style="padding-left: unset;"
        type="text"
        formControlName="property"
        (ionChange)="changeFuzzySearch()"
        debounce="500"
        showCancelButton="never"
        spellcheck="false"
        placeholder="{{ 'global.menu.search' | translate }}"
        #searchbar
      >
      </ion-searchbar>
      <ion-icon class="close-icon" (click)="clearInput()" name="close"></ion-icon>
    </ion-row>
    <ion-row class="full-width">
      <ion-list class="list-flow full-width" *ngIf="buildingNames && buildingNames.length">
        <ion-item
          *ngFor="let building of buildingNames; let index = index"
          (click)="setProperty(building, 1)"
          localize
          inset="true"
          lines="full"
        >
          <div style="text-transform: none;" [innerHTML]="building.name | highlight: detailsForm.value.property"></div>
        </ion-item>
      </ion-list>
    </ion-row>
  </ion-row>

  <ion-row *ngIf="!detailsForm.value.listed" class="full-width margin-top-5 margin-bottom-10">
    <ion-row class="full-width listing-type-select">
      <multi-language-elem
        *ngIf="detailsForm.controls.property.value && !this.detailsForm.controls.property.enabled"
        style="width: 100%"
        (onValueChange)="propertyNameChange($event)"
        [formArrayValue]="propertyName"
        #title
        [options]="propertyNameFieldOptions"
      >
      </multi-language-elem>
    </ion-row>
  </ion-row>

  <ion-row style="margin-top: 10px; margin-bottom: 20px;">
    <ion-checkbox (ionChange)="setValidators()" formControlName="listed"></ion-checkbox>
    <span style="margin-left: 10px; margin-top: -2px;">{{
      'global.my-list-menu.mypropertyNotListed' | translate
    }}</span>
  </ion-row>

  <ion-row *ngIf="detailsForm.value.listed" style="margin-bottom: 10px;" class="full-width">
    <div id="googleMapForPropertyCreation" style="width: 100%;margin-bottom: 10px;">
      <app-map (newLocationEvent)="setBuildingGeoLocation($event)"></app-map>
    </div>

    <multi-language-elem
      style="width: 100%"
      (onValueChange)="nameChange($event)"
      [formArrayValue]="name"
      #title
      [options]="nameFieldOptions"
    >
    </multi-language-elem>
  </ion-row>

  <ion-row *ngIf="detailsForm.value.listed" style="margin-bottom: 10px;">
    <app-address style="width: 100%" [otherForm]="detailsForm" [form]="addressForm"></app-address>
  </ion-row>

  <ion-row *ngIf="detailsForm.value.propertySet && !detailsForm.value.listed && actualBuilding">
    <app-duplomap
      class="map-size"
      [building]="actualBuilding"
      [showInCard]="true"
      [markers]="marker"
      [options]="mapOptions"
    ></app-duplomap>
  </ion-row>
</form>
