import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-type-page',
  templateUrl: './listing-type-popover.component.html',
  styleUrls: ['./listing-type-popover.component.scss']
})
export class ListingTypePopoverComponent implements OnInit {
  listingType: any;
  selectedPropertyType: any;
  constructor(private navParams: NavParams, private popoverCtrl: PopoverController) {}

  ngOnInit(): void {
    this.getPopData();
  }

  // Get Data passed from component opening popover
  getPopData() {
    this.listingType = this.navParams.data.listType;
    if (!this.listingType) {
      this.selectedPropertyType = 'null';
    } else {
      this.selectedPropertyType = this.listingType;
    }
  }

  propertyTypeChanged(event: CustomEvent) {
    this.update();
  }

  update() {
    this.popoverCtrl.dismiss(this.selectedPropertyType);
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.cancel();
    }
  }

  // Dismissing popover with data passed back to main component
}
