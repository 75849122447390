import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { I18nService } from '../i18n.service';
import { ListingOfferType } from '@app/models/transaction-type.model';
export class ListingService {
    constructor(http, i18n) {
        this.http = http;
        this.i18n = i18n;
        this.marketSpotEndpoint = `/api/lookUpTables/market/spot/`;
        this.featuredListingIdsEndpoint = `/api/_search/listings/featured/fast/all`;
        this.listingByGeopositionEndpoint = `api/listings/by-coordinates`;
        this.activeListingIdsEndpoint = `/api/_search/listings/active/fast/all`;
        this.listingDetailsEndpoint = `/api/_search/listings/fast/detail`;
        this.activeListingDetailsEndpoint = `/api/_search/listings/active/fast/detail`;
        this.searchDtoFromUrlEndpoint = `/api/listings/search-dto-by-url/`;
        this.urlFromSearchDtoEndpoint = `/api/listings/url-by-search-dto`;
        this.countryStatesEndpoint = `/api/states/with-listings`;
        this.citiesEndpoint = `/api/cities/with-listings`;
        this.allCitiesByStateEndpoint = `/api/citysByState`;
        this.citiesByStateEndpoint = `/api/cities-by-state/with-listings`;
        this.citiesWithListingsEndpoint = `/api/cities-with-listings`;
        this.listingSearchEndpoint = `/api/listing/searchListing/fast`;
        this.listingIdsSearchEndpoint = `/api/listing/searchListingIds/fast`;
        this.cityDetailEndpoint = `/api/citys/`;
        this.stateDetailEndpoint = `/api/countryStates/`;
        this.buildingsEndpoint = 'api/buildings';
        this.nearbySpotListingEndpoint = `/api/listing/searchListing/buildings/map`;
        this.searchNearByListingEndpoint = `api/listing/searchListing/fast/map`;
        this.listingAnalytics = '/api/listing/activities-tracking/all';
        this.setNewExpirationDateAPI = '/api/listings/renew-and-extend';
        this.isValidToRenewAndExtendListingsAPI = '/api/listings/renew-and-extend/isValid';
        this.countActiveAndExpiredListingsAPI = '/api/listings/renew-and-extend/count';
        this.checkDuplicateBuildingLocationAPI = 'api/building/check/duplicate/location';
        this.modelSource = new BehaviorSubject(null);
        this.currentModel = this.modelSource.asObservable();
        this.spotNearMeFilter = new BehaviorSubject(null);
        this.currentPage = 1;
        this.pageSize = 5;
        this.listings = [];
        this.activeListingIds = [];
    }
    get getCurrentPage() {
        return this.currentPage;
    }
    updateModel(model) {
        this.modelSource.next(model);
    }
    setSearchModel(model) {
        this.searchModel = model;
    }
    getSearchModel() {
        return this.searchModel;
    }
    getListingAnalytics(listingIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(`${this.listingAnalytics}`, listingIds).toPromise();
        });
    }
    deleteDraft(id) {
        return this.http.delete(`api/listings/${id}/delete-listing`).pipe(map(val => val));
    }
    getBuilding(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(`${this.buildingsEndpoint}/${id}`).toPromise();
        });
    }
    getBuildingToRevise(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(`${this.buildingsEndpoint}/revise/${id}`).toPromise();
        });
    }
    getFeaturedListingIds(cached) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (cached && this.activeListingIds.length) {
                return this.activeListingIds;
            }
            else {
                const res = yield this.http.get(this.featuredListingIdsEndpoint).toPromise();
                this.activeListingIds = res;
                return res;
            }
        });
    }
    getActiveListingIds() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const res = yield this.http.get(this.activeListingIdsEndpoint).toPromise();
            this.activeListingIds = res;
            return res;
        });
    }
    getDefaultActiveListings(pageSize) {
        return this.http
            .get(this.activeListingDetailsEndpoint + `?page=1&per_page=` + (pageSize || this.pageSize), {
            observe: 'response'
        })
            .pipe(map(val => {
            return {
                headers: val.headers,
                body: val.body
            };
        }))
            .toPromise()
            .then((response) => {
            const listings = response.body;
            this.appendListings(listings);
            return listings;
        });
    }
    getListingDetails(pageSize, activeIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append('per_page', `${pageSize || this.pageSize}`);
            return yield this.http
                .post(`${this.listingDetailsEndpoint}?${urlSearchParams}`, this.activeListingIds)
                .toPromise()
                .then((listings) => {
                this.appendListings(listings);
                return listings;
            });
        });
    }
    getActiveListingDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append('per_page', '100');
            return yield this.http.get(this.activeListingDetailsEndpoint + urlSearchParams.toString()).toPromise();
        });
    }
    getListingsSearchDto(searchUrl, keyword, buildingName) {
        let urlSearchParams = new URLSearchParams();
        if (keyword) {
            urlSearchParams.append('keyword', keyword);
            searchUrl += '?' + urlSearchParams.toString();
        }
        if (buildingName) {
            urlSearchParams.append('buildingName', buildingName);
            searchUrl += '?' + urlSearchParams.toString();
        }
        return this.http.get(this.searchDtoFromUrlEndpoint + searchUrl).toPromise();
    }
    getUrlFromListingsSearchDto(searchDto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http
                .post(this.urlFromSearchDtoEndpoint, this.handlingCoworkingPropertyType(searchDto))
                .toPromise();
        });
    }
    searchMyListings(searchDto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http
                .post(this.listingSearchEndpoint, searchDto, { observe: 'response' })
                .pipe(map(val => {
                return {
                    headers: val.headers,
                    body: val.body
                };
            }))
                .toPromise();
        });
    }
    searchListings(searchDto, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append('page', page.toString());
            urlSearchParams.append('per_page', '10');
            urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
            return yield this.http
                .post(this.listingSearchEndpoint + '?' + urlSearchParams, searchDto, { observe: 'response' })
                .pipe(map(val => {
                return {
                    headers: val.headers,
                    body: val.body
                };
            }))
                .toPromise();
        });
    }
    searchListingIds(searchDto, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append('page', page.toString());
            urlSearchParams.append('per_page', '10');
            urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
            return yield this.http
                .post(this.listingIdsSearchEndpoint + '?' + urlSearchParams, this.handlingCoworkingPropertyType(searchDto), {
                observe: 'response'
            })
                .pipe(map(val => {
                return {
                    headers: val.headers,
                    body: val.body
                };
            }))
                .toPromise();
        });
    }
    getStates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.countryStatesEndpoint).toPromise();
        });
    }
    getState(stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.stateDetailEndpoint + stateId).toPromise();
        });
    }
    getCity(cityId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.cityDetailEndpoint + cityId).toPromise();
        });
    }
    /**
     * Get All cities with buildings
     * @param stateId
     */
    getCitiesByState(stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(`${this.citiesByStateEndpoint}?stateId=${stateId}`).toPromise();
        });
    }
    getAllCitiesByState(stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(`${this.allCitiesByStateEndpoint}?stateId=${stateId}`).toPromise();
        });
    }
    getCities() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(`${this.citiesEndpoint}`).toPromise();
        });
    }
    removeListingCredits(listingPurchaseId, credits) {
        return this.http
            .post('api/listing-purchase/' + listingPurchaseId + '/remove-credits?credits=' + credits, {})
            .pipe(map(val => val));
    }
    queryByPage(page, pageSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http
                .post(`${this.listingDetailsEndpoint}?page=${page}&per_page=${pageSize || this.pageSize}`, this.activeListingIds)
                .toPromise()
                .then((response) => {
                const listings = response;
                this.appendListings(listings);
                return listings;
            });
        });
    }
    getListingsByPage(page, pageSize, listingIds, coworkingPositions, coworkingType, searchId, myAnalytics) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let params = '';
            if (coworkingPositions) {
                params += '&coworkingPositions=' + coworkingPositions;
            }
            if (coworkingType) {
                params += '&coworkingType=' + coworkingType;
            }
            let detailsURL = `${this.listingDetailsEndpoint}?page=${page}&per_page=${pageSize}${params}&includeMetrics=${myAnalytics}`;
            if (searchId) {
                detailsURL = detailsURL.concat(`&searchTrackingId=${searchId}`);
            }
            return yield this.http
                .post(detailsURL, listingIds)
                .toPromise()
                .then((response) => {
                const listings = response;
                return listings;
            });
        });
    }
    appendListings(listings) {
        this.listings = [...this.listings, ...listings];
    }
    fetchPage(page) {
        this.currentPage = page;
        return {
            pageQuery$: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return yield this.queryByPage(page);
            }),
            nextPage: page + 1
        };
    }
    getListingsByGeoposition(coordinates, radius) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const searchRadius = radius || 10; //km
            return yield this.http
                .get(`${this.listingByGeopositionEndpoint}?latitude=${coordinates.lat}&longitude=${coordinates.lng}&radius=${searchRadius}`)
                .toPromise();
        });
    }
    searchNearByListing(searchDto, pageNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append('page', pageNumber.toString());
            urlSearchParams.append('per_page', '2000');
            urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
            return yield this.http
                .post(this.nearbySpotListingEndpoint + '?' + urlSearchParams, this.handlingCoworkingPropertyType(searchDto), {
                observe: 'response'
            })
                .pipe(map(val => {
                return {
                    headers: val.headers,
                    body: val.body
                };
            }))
                .toPromise();
        });
    }
    getAllNearByPropertyListing(searchDTO) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http
                .post(this.searchNearByListingEndpoint, searchDTO, { observe: 'response' })
                .pipe(map(value => {
                return {
                    headers: value.headers,
                    body: value.body
                };
            }))
                .toPromise();
        });
    }
    defaultCityState() {
        return ['pernambuco', 'são paulo', 'rio de janeiro', 'minas gerais', 'belo horizonte'];
    }
    getDefaultCities(cities, defaultCities) {
        return cities.filter((item) => {
            const itemName = this.i18n.getTranslation(item.name).toLowerCase();
            return defaultCities.includes(itemName);
        });
    }
    getDefaultStates(cities, states, defaultLocations) {
        return states.filter((item) => {
            const itemName = this.i18n.getTranslation(item.name).toLowerCase();
            const isExist = this.isCityExist(cities, itemName);
            return !isExist && defaultLocations.includes(itemName);
        });
    }
    isCityExist(cities, city) {
        return (cities.findIndex((item) => {
            const itemName = this.i18n.getTranslation(item.name).toLowerCase();
            return itemName === city.toLowerCase();
        }) !== -1);
    }
    indexOfLocation(locations, locationName) {
        return locations.findIndex((item) => this.i18n.getTranslation(item.name).toLowerCase() === locationName.toLowerCase());
    }
    sortLocation(locations) {
        const locationsCopy = locations.slice();
        const indexBeloHorizonte = this.indexOfLocation(locationsCopy, 'belo horizonte');
        const indexSaoPaulo = this.indexOfLocation(locationsCopy, 'são paulo');
        const beloHorizonte = Object.assign({}, locationsCopy[indexBeloHorizonte]);
        const saoPaulo = Object.assign({}, locationsCopy[indexSaoPaulo]);
        locationsCopy[indexBeloHorizonte] = saoPaulo;
        locationsCopy[indexSaoPaulo] = beloHorizonte;
        return locationsCopy;
    }
    filterCityState(cities, states) {
        const defaultLocation = this.defaultCityState();
        const defaultCities = this.getDefaultCities(cities, defaultLocation);
        const defaultStates = this.getDefaultStates(defaultCities, states, defaultLocation);
        const sortedCityState = this.sortLocation([...defaultCities, ...defaultStates]);
        return sortedCityState;
    }
    setLocationFilter(location) {
        let props = {};
        if (location.hasOwnProperty('countryState')) {
            props = {
                cityId: location.id,
                stateId: location.countryState.id
            };
        }
        else if (location.hasOwnProperty('country')) {
            props = {
                cityId: null,
                stateId: location.id
            };
        }
        else {
            props = {
                cityId: null,
                stateId: null
            };
        }
        return props;
    }
    getCurrentLocation(currentLocation, locations) {
        const locationId = currentLocation.cityId ? currentLocation.cityId : currentLocation.stateId;
        const selectedLocation = locations.filter((item) => item.id === locationId);
        return selectedLocation.length ? selectedLocation[0] : 'other';
    }
    getSelectedCompany(companies, companyId) {
        return companies.filter((company) => company.id === companyId);
    }
    getSelectedAgent(agents, offeredByUserId) {
        return agents.filter((agent) => agent.id === offeredByUserId);
    }
    setNewExpirationDateFor(listingIds, newExpirationDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.setNewExpirationDateAPI}/${newExpirationDate}`, listingIds, { observe: 'response' })
                .pipe(map(value => {
                return {
                    headers: value.headers,
                    body: value.body
                };
            }))
                .toPromise();
        });
    }
    countActiveAndExpiredListings(listingIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.countActiveAndExpiredListingsAPI}`, listingIds, { observe: 'response' })
                .pipe(map(value => {
                return {
                    headers: value.headers,
                    body: value.body
                };
            }))
                .toPromise();
        });
    }
    isValidToRenewAndExtendListings(listingIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.isValidToRenewAndExtendListingsAPI}`, listingIds, { observe: 'response' })
                .pipe(map(value => {
                return {
                    headers: value.headers,
                    body: value.body
                };
            }))
                .toPromise();
        });
    }
    checkDuplicateBuildingLocation(buildingLocation) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.checkDuplicateBuildingLocationAPI}`, {
                address: buildingLocation.address,
                cityId: buildingLocation.cityId,
                latitude: buildingLocation.marker.lat,
                longitude: buildingLocation.marker.lng
            }, { observe: 'response' })
                .pipe(map(value => {
                return {
                    headers: value.headers,
                    body: value.body
                };
            }))
                .toPromise();
        });
    }
    handlingCoworkingPropertyType(listingSearchDto) {
        let copySearchDTOForURL = Object.assign({}, listingSearchDto);
        if (listingSearchDto.buildingTypes && listingSearchDto.buildingTypes.includes(ListingOfferType.Coworking)) {
            copySearchDTOForURL.buildingTypes = null;
        }
        return copySearchDTOForURL;
    }
    getMarket(marketId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.marketSpotEndpoint + marketId + '/' + this.i18n.getCurrentLanguage()).toPromise();
        });
    }
}
