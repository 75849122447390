<app-breadcrumb
  *ngIf="!previewListing"
  inViewport
  class="header-fixed"
  [inViewportOptions]="{ thredhold: [0] }"
  (inViewportAction)="isHeaderVisible($event)"
  [options]="breadcrumbNavOptions"
>
  <ion-buttons class="padding-right-30" slot="end">
    <a [routerLink]="routeToSearchPage()" class="remove-underline">
      <ion-button ion-button icon-only fill="clear" class="top-search-btns" [disabled]="loading">
        <span *ngIf="screenWidth < 700" [translate]="toolbarText.search"></span>
        <span *ngIf="screenWidth >= 700" [translate]="getSearchLabel()"></span>
      </ion-button>
    </a>
    <ion-button
      ion-button
      icon-only
      class="top-search-btns"
      [disabled]="loading"
      (click)="navigateToPrev()"
      *ngIf="currentPosition && currentPosition.hasPrevious"
    >
      <span *ngIf="screenWidth < 700" [translate]="toolbarText.prev"></span>
      <span *ngIf="screenWidth >= 700" [translate]="toolbarText.prevListing"></span>
    </ion-button>
    <ion-button
      ion-button
      icon-only
      class="top-search-btns"
      [disabled]="loading"
      (click)="navigateToNext()"
      *ngIf="currentPosition && currentPosition.hasNext"
    >
      <span *ngIf="screenWidth < 700" [translate]="toolbarText.next"></span>
      <span *ngIf="screenWidth >= 700" [translate]="toolbarText.nextListing"></span>
    </ion-button>
  </ion-buttons>
</app-breadcrumb>

<div #contentBlock class="detail-block-background">
  <button
    class="next-btn float-right"
    (click)="approveListing()"
    style="background: #ff9900; width:50%; height:50px; position: fixed; right:0; bottom:0; z-index: 9999; color: white;"
    *ngIf="listing?.listingStatus?.id === 1 && spotAdmin"
  >
    Approve
  </button>

  <button
    class="next-btn float-right"
    (click)="rejectListing()"
    style="background: red; width:50%; height:50px; position: fixed; left: 0; bottom:0; z-index: 9999; color: white;"
    *ngIf="listing?.listingStatus?.id === 1 && spotAdmin"
  >
    Reject
  </button>
  <ion-row class="max-width-1200">
    <ion-col style="padding: unset;">
      <ion-row
        class="max-width-1200"
        [ngClass]="{ 'margin-top-55': !previewListing }"
        *ngIf="
          listing?.premiumAgents &&
          (listing?.listingType.toLowerCase() === 'basic' || listing?.listingType.toLowerCase() === 'promoted')
        "
      >
        <app-premium-agents style=" width: 100%;" [listing]="listing"> </app-premium-agents>
      </ion-row>

      <div
        class="max-width-1200 region-bar region-bar-default"
        [ngClass]="{
          'margin-top-44': !listing?.premiumAgents && !previewListing && !isDesktop && !isAndroid,
          'margin-top-55': (isDesktop || isAndroid) && !previewListing && !listing?.premiumAgents,
          'margin-top-10': (isDesktop || isAndroid) && !previewListing && listing?.premiumAgents,
          'margin-desktop': screenWidth > 500 && listing?.premiumAgents
        }"
      >
        <span class="region-bar__listing-title">
          {{ listing?.neighborhood }}
        </span>
        <span
          *ngIf="listing?.listingType === 'FEATURED' || listing?.listingType === 'PREMIUM'"
          class="premium-label premium-label-outline"
          id="premium"
        >
          <ion-chip class="premium-chip">
            <fa-icon class="listing-icon" [icon]="listingFaIcon" size="md"></fa-icon>
            <ion-label
              *ngIf="listing?.listingType === 'FEATURED'"
              color="light"
              [translate]="'global.list-property.featuredUpperCase'"
            >
            </ion-label>
            <ion-label
              *ngIf="listing?.listingType === 'PREMIUM'"
              color="light"
              [translate]="'global.list-property.premiumUpperCase'"
            >
            </ion-label>
          </ion-chip>
        </span>
        <ion-button
          [ngClass]="{ 'is-favorite': isFavoriteListing() }"
          class="region-bar__favorite-button"
          expand="full"
          size="small"
          fill="clear"
          (click)="setFavoriteLisitng()"
        >
          <fa-icon [icon]="faHeart" size="lg"></fa-icon>
        </ion-button>
      </div>

      <div
        class="detail-flex max-width-1200 position-relative"
        [ngClass]="{
          'height-desktop': !isMobile,
          'margin-top-30':
            isDesktop &&
            (listing?.listingType.toLowerCase() === 'basic' || listing?.listingType.toLowerCase() === 'promoted')
        }"
        *ngIf="listing && !loading"
      >
        <div *ngIf="hasPointer && listingPhotos && listingPhotos.length > 1 && checkArrows()">
          <button
            (click)="getPreviousPhoto()"
            class="stdBackBtn"
            *ngIf="hasPointer && listingPhotos && listingPhotos.length > 1 && checkArrows()"
          >
            <ion-icon name="arrow-back"></ion-icon>
          </button>

          <button
            (click)="getNextPhoto()"
            class="stdNextBtn"
            *ngIf="hasPointer && listingPhotos && listingPhotos.length > 1"
          >
            <ion-icon name="arrow-forward"> </ion-icon>
          </button>
        </div>
        <ion-slides
          [options]="checkScreenWidth()"
          (ionSlideDidChange)="updatePhotoIndex()"
          *ngIf="listingPhotos && listingPhotos.length && !loading"
          #photoSlider
          id="photoSlider"
          style="width: 100% !important"
        >
          <ion-slide
            [class]="getSlideHeightClass(photo)"
            [ngClass]="{
              'one-photo-gallery': listingPhotos.length === 1,
              'more-photo-gallery': screenWidth > 700 && listingPhotos.length > 2,
              'two-photo-gallery':
                (screenWidth > 700 && listingPhotos.length === 2) ||
                (screenWidth > 500 && screenWidth < 700 && listingPhotos.length > 1)
            }"
            *ngFor="let photo of listingPhotos; let i = index"
          >
            <div class="img-respons">
              <img
                *ngIf="i <= 2"
                alt="listing"
                default="assets/placeholder-image.png"
                [src]="getGoogleMapImageOrCroppedOrOriginalImage(photo)"
              />
              <img
                *ngIf="i > 2"
                alt="listing"
                default="assets/placeholder-image.png"
                [lazyLoad]="getGoogleMapImageOrCroppedOrOriginalImage(photo)"
              />
              <!-- <img *ngIf="previewListing" [ngClass]="{ 'img-pad': isMobile }" alt="listing" [src]="sanitize(photo.img)" /> -->
            </div>
          </ion-slide>
        </ion-slides>
      </div>
      <ion-card
        *ngIf="listing"
        [ngClass]="{ 'card-details-modal': showDetails, 'left-0': previewListing }"
        class="ion-listing-card max-width-1200 detail-ion-card standard-shadow card-position"
      >
        <ion-card-content class="card-content-default">
          <div class="image-dots" *ngIf="listingPhotos && listingPhotos.length > 1">
            <span
              class="dot"
              [ngClass]="{
                active: i == currentPhotoIndex,
                hide: hideImageDots(i)
              }"
              *ngFor="let photo of listingPhotos; let i = index"
              (click)="photoSlider.slideTo(i)"
            ></span>
          </div>

          <div class="detail-info custom-skeleton" *ngIf="loading">
            <div class="ion-padding custom-skeleton">
              <div style="display:flex;justify-content:space-between;">
                <ion-skeleton-text slot="end" animated style="width:30%;height:30px;"></ion-skeleton-text>
                <ion-skeleton-text animated style="width:30%;height:30px;"></ion-skeleton-text>
              </div>
              <ion-skeleton-text animated style="width:88%;height:30px;"></ion-skeleton-text>
              <ion-skeleton-text animated style="width:70%"></ion-skeleton-text>
            </div>
          </div>

          <div class="listing-detail-data">
            <div>
              <ion-row *ngIf="!loading" class="row-border-bottom">
                <ion-col class="flex-col-nowrap" size="12">
                  <div class="flex-col-nowrap">
                    <p
                      class="property-title"
                      [ngClass]="{ 'height-60': screenWidth >= 1200 }"
                      localize
                      [textValue]="listing?.title"
                    ></p>
                  </div>
                </ion-col>
              </ion-row>

              <div class="detail-info" *ngIf="!loading" id="detailListing">
                <ion-row>
                  <ion-col class="flex-row-nowrap" size="9">
                    <div class="prop-sub-type">
                      <h1 class="h1-seo-title">
                        <span
                          class="property-color"
                          *ngIf="
                            listing?.isForLeaseSale === 'S' ||
                            listing?.isForLeaseSale === 'L' ||
                            listing?.isForLeaseSale === 'C'
                          "
                        >
                          {{ getPropSubTypeText() }}
                        </span>
                        <p class="property-title" [ngClass]="{ 'padding-top-20': screenWidth >= 1200 }">
                          {{ getBuildingTitle() }}
                        </p>
                      </h1>
                    </div>
                  </ion-col>
                  <ion-col size="3">
                    <span class="share-inline" id="shareLink">
                      <!-- <p color="secondary" class="view-map-label" [translate]="'global.search.share'"></p> -->
                      <fa-icon (click)="shareLink()" class="img-icon" [icon]="faShareAltSquare" size="lg"></fa-icon>
                    </span>
                  </ion-col>
                </ion-row>
              </div>

              <div class="content-height pricing-prop-info">
                <ion-row *ngIf="!loading">
                  <ion-col class="flex-col-nowrap" [ngClass]="{ 'width-600': screenWidth >= 1200 }" size="12">
                    <div class="flex-justify-between">
                      <div class="flex-col-wrap">
                        <p class="listing-details" localize [textValue]="listing?.building.address"></p>
                        <p class="listing-details" localize [textValue]="listing?.building.city.name"></p>
                      </div>
                    </div>
                    <div class="pricing-flex" *ngIf="listing.listingType.toLowerCase() != 'basic'">
                      <p
                        *ngIf="listing.isForLeaseSale === 'S' && !listing.coworkingMaxPositions"
                        class="pricing-header"
                      >
                        {{ getMeasurementValue() }}
                      </p>
                      <p
                        *ngIf="listing.isForLeaseSale === 'S' && !listing.coworkingMaxPositions"
                        class="pricing-header margin-right-15"
                        translate="global.listingPricing.offeringPrice"
                      ></p>
                      <p
                        *ngIf="listing.isForLeaseSale === 'L' && !listing.coworkingMaxPositions"
                        class="pricing-header"
                      >
                        {{ getMeasurementValue() }}
                      </p>
                      <p
                        *ngIf="listing.isForLeaseSale === 'L' && !listing.coworkingMaxPositions"
                        class="pricing-header margin-right-15"
                        translate="global.coworking.pricePerMonth"
                      ></p>
                      <p
                        *ngIf="listing.coworkingMaxPositions"
                        class="pricing-header"
                        translate="global.coworking.positionsAvail"
                      ></p>
                      <p
                        *ngIf="listing.coworkingMaxPositions"
                        class="pricing-header margin-right-15"
                        translate="global.coworking.pricing"
                      ></p>
                    </div>
                    <div
                      class="flex-col-nowrap"
                      *ngIf="listing.coworkingMaxPositions && listing.listingType.toLowerCase() != 'basic'"
                      style="color: #01334d;"
                    >
                      <div>
                        <div class="flex-justify-left ext-details-coworking-2" style="float: left;">
                          <span *ngIf="listing.coworkingMaxPositions > 1">
                            {{ 'global.coworking.upTo' | translate }}
                            {{ listing.coworkingMaxPositions | localNumber: '1.0-0' }}
                            {{ 'global.positions' | translate }}</span
                          >
                          <span *ngIf="listing.coworkingMaxPositions == 1">
                            {{ 'global.coworking.upTo' | translate }}
                            {{ listing.coworkingMaxPositions | localNumber: '1.0-0' }}
                            {{ 'global.position' | translate }}</span
                          >
                        </div>
                        <div class="flex-justify-right" style="float: right;">
                          <span *ngIf="listing.coworkingPositions" class="ext-details-coworking-2">
                            <b>{{ listing.coworkingPositions | localNumber: '1.0-0' }}</b>
                            {{
                              listing.sharedPrivatePositionsText && listing.sharedPrivatePositionsText.length
                                ? listing.sharedPrivatePositionsText
                                : sharedPrivatePositionsText
                            }}
                          </span>
                        </div>
                      </div>

                      <div class="flex-justify-right">
                        <span
                          class="ext-details-coworking-2"
                          *ngIf="listing.coworkingDailyPrice && listing.coworkingShared && !showCoworkingPrivate"
                          >{{ 'global.coworking.dailyFrom' | translate }}
                          R$
                          {{
                            listing.coworkingDP
                              ? (listing.coworkingDP | localNumber: '1.0-0')
                              : (listing.coworkingDailyPrice | localNumber: '1.0-0')
                          }}</span
                        >
                        <span
                          class="ext-details-coworking-2"
                          *ngIf="
                            listing.coworkingPrivateDailyPrice && (!listing.coworkingShared || showCoworkingPrivate)
                          "
                          >{{ 'global.coworking.dailyFrom' | translate }} R$
                          {{
                            listing.coworkingDP
                              ? (listing.coworkingDP | localNumber: '1.0-0')
                              : (listing.coworkingPrivateDailyPrice | localNumber: '1.0-0')
                          }}</span
                        >
                        <span
                          class="ext-details-coworking-2"
                          *ngIf="
                            listing.coworkingType == 'BOTH_NEGOTIABLES' ||
                            (showCoworkingPrivate && !listing.coworkingPrivateDailyPrice)
                          "
                          >{{ 'global.coworking.dailyNegotiable' | translate }}</span
                        >
                      </div>

                      <div class="flex-justify-right">
                        <span
                          class="ext-details-coworking-2"
                          *ngIf="listing.coworkingMonthlyPrice && listing.coworkingShared && !showCoworkingPrivate"
                          >{{ 'global.coworking.monthlyFrom' | translate }}
                          R$
                          {{
                            listing.coworkingMP
                              ? (listing.coworkingMP | localNumber: '1.0-0')
                              : (listing.coworkingMonthlyPrice | localNumber: '1.0-0')
                          }}</span
                        >
                        <span
                          class="ext-details-coworking-2"
                          *ngIf="
                            listing.coworkingPrivateMonthlyPrice && (!listing.coworkingShared || showCoworkingPrivate)
                          "
                          >{{ 'global.coworking.monthlyFrom' | translate }} R$
                          {{
                            listing.coworkingMP
                              ? (listing.coworkingMP | localNumber: '1.0-0')
                              : (listing.coworkingPrivateMonthlyPrice | localNumber: '1.0-0')
                          }}</span
                        >
                        <span
                          class="ext-details-coworking-2"
                          *ngIf="
                            listing.coworkingType == 'BOTH_NEGOTIABLES' ||
                            (showCoworkingPrivate && !listing.coworkingPrivateMonthlyPrice)
                          "
                          >{{ 'global.coworking.monthlyNegotiable' | translate }}</span
                        >
                      </div>
                    </div>

                    <div
                      *ngIf="
                        listing?.isForLeaseSale === 'L' &&
                        listing?.monthlyRent &&
                        listing?.monthlyRent != 0 &&
                        !listing.coworkingMaxPositions &&
                        listing.listingType.toLowerCase() != 'basic'
                      "
                      class="listing-price pricing-info mr-0"
                    >
                      {{ listing?.leaseableArea | localNumber: '1.0-2' }} m<sup>2</sup>
                      <span *ngIf="listing.leaseableAreaTotal && listing.leaseableArea !== listing.leaseableAreaTotal"
                        >- {{ listing.leaseableAreaTotal | localNumber: '1.0-2' }} m<sup>2</sup>
                      </span>
                      <span class="detail-sub">
                        R$ {{ listing.monthlyRent | localNumber: '1.0-0' }}
                        <span class="helper-text" *ngIf="listing && listing.monthlyRent && listing.leaseableArea">
                          ({{ listing.monthlyRent / listing.leaseableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                        </span>
                      </span>
                    </div>

                    <div
                      *ngIf="
                        listing?.isForLeaseSale === 'L' &&
                        (!listing?.monthlyRent || listing?.monthlyRent == 0) &&
                        !listing.coworkingMaxPositions &&
                        listing.listingType.toLowerCase() != 'basic'
                      "
                      class="listing-price  pricing-info"
                    >
                      {{ listing?.leaseableArea | localNumber: '1.0-2' }} m<sup>2</sup>
                      <span *ngIf="listing.leaseableAreaTotal && listing.leaseableArea !== listing.leaseableAreaTotal"
                        >- {{ listing.leaseableAreaTotal | localNumber: '1.0-2' }} m<sup>2</sup>
                      </span>
                      <span style="float:right;" class="detail-sub">
                        {{ 'global.list-property.negotiable' | translate }}
                      </span>
                    </div>

                    <div
                      *ngIf="
                        listing?.isForLeaseSale === 'S' &&
                        listing?.offeringPrice &&
                        listing?.offeringPrice != 0 &&
                        !listing.coworkingMaxPositions &&
                        listing.listingType.toLowerCase() != 'basic'
                      "
                      class="listing-price  pricing-info"
                    >
                      {{ listing?.rentableArea | localNumber: '1.2-2' }} m<sup>2</sup>
                      <span class="detail-sub">
                        R$ {{ listing.offeringPrice | localNumber: '1.0-0' }}
                        <span class="helper-text" *ngIf="listing && listing.offeringPrice && listing.rentableArea">
                          ({{ listing.offeringPrice / listing.rentableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                        </span>
                      </span>
                    </div>

                    <div
                      *ngIf="
                        listing?.isForLeaseSale === 'S' &&
                        (!listing?.offeringPrice || listing?.offeringPrice == 0) &&
                        !listing.coworkingMaxPositions &&
                        listing.listingType.toLowerCase() != 'basic'
                      "
                      class="listing-price  pricing-info"
                    >
                      {{ listing?.rentableArea | localNumber: '1.2-2' }} m<sup>2</sup>
                      <span class="detail-sub">
                        {{ 'global.list-property.negotiable' | translate }}
                      </span>
                    </div>

                    <ng-container *ngIf="!hasCoworkingSubType(listing || {})">
                      <div *ngIf="listing?.isForLeaseSale === 'L' && listing.listingType.toLowerCase() != 'basic'">
                        <div style="float: right;" *ngIf="listing?.monthlyCondoFee">
                          <span class="ext-details" style="padding-right: 15px;">
                            Condo: R$ {{ listing?.monthlyCondoFee | localNumber: '1.0-0' }}
                          </span>
                          <span class="helper-text" *ngIf="listing && listing.monthlyCondoFee">
                            ({{ listing.monthlyCondoFeePerArea | localNumber: '1.0-2' }}/m<sup>2</sup>)
                          </span>
                        </div>

                        <div style="float: right;" *ngIf="!listing?.monthlyCondoFee && !isRentCondoIPTUNegotiables()">
                          <span class="ext-details" style="padding-right: 15px;"
                            >Condo: {{ 'global.list-property.negotiable' | translate }}</span
                          >
                        </div>
                      </div>

                      <div *ngIf="listing?.isForLeaseSale === 'L' && listing.listingType.toLowerCase() != 'basic'">
                        <div *ngIf="listing?.monthlyIptu" style="float: right;">
                          <span class="ext-details" style="padding-right: 15px;">
                            IPTU: R$ {{ listing?.monthlyIptu | localNumber: '1.0-0' }}
                          </span>
                          <span class="helper-text" *ngIf="listing && listing.monthlyIptu">
                            ({{ listing.monthlyIptuPerArea | localNumber: '1.0-2' }}/m<sup>2</sup>)
                          </span>
                        </div>

                        <div *ngIf="!listing?.monthlyIptu && !isRentCondoIPTUNegotiables()" style="float: right;">
                          <span class="ext-details" style="padding-right: 15px;"
                            >IPTU: {{ 'global.list-property.negotiable' | translate }}</span
                          >
                        </div>
                      </div>
                    </ng-container>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="listing.listingType.toLowerCase() === 'basic'">
                  <ion-col class="center detail-info">
                    <a (click)="openListYourSpotDialogue()" class="point">
                      {{ 'global.listing-detail.basic.claimYour' | translate }}
                      <span> SP<fa-icon class="img-spot" [icon]="faMapMarkerAlt" size="md"></fa-icon>T </span>
                    </a>
                  </ion-col>
                  <ion-card class="broker-profile">
                    <ion-row>
                      <ion-col class="default-contact-left" size="12">
                        <p style="font-size: 15px; font-weight: 500; color: #424242;">
                          {{ 'global.listing-detail.basic.spaceAvailable' | translate }}
                        </p>
                      </ion-col>
                      <ion-col class="default-contact-left" size="12">
                        <p style="font-size: 15px; font-weight: 500; color: #424242;">
                          {{ 'global.listing-detail.basic.question' | translate }}
                        </p>
                      </ion-col>
                    </ion-row>
                  </ion-card>
                </ion-row>
              </div>
            </div>

            <div class="contact-info">
              <ion-row class="row-border-bottom broker-title-container">
                <ion-col size="12">
                  <div class="flex-col-nowrap">
                    <p
                      *ngIf="listing.listingType.toLowerCase() === 'basic'"
                      class="contact-title"
                      [ngClass]="{ 'height-60': screenWidth >= 1200 }"
                      translate="global.listing-detail.propertyManager"
                    ></p>
                    <p
                      *ngIf="listing.listingType.toLowerCase() !== 'basic'"
                      class="contact-title"
                      [ngClass]="{ 'height-60': screenWidth >= 1200 }"
                      translate="global.listing-detail.contactSeller"
                    ></p>
                  </div>
                </ion-col>
              </ion-row>

              <ion-row style="margin-top:5px;" *ngIf="listing.listingType.toLowerCase() !== 'basic'">
                <ion-card class="broker-profile mt-1">
                  <ion-col size="4" class="col-styling padding-2">
                    <img
                      class="avatar-size"
                      *ngIf="listing.offeredByUser && listing.offeredByUser.profilePhotoImageId"
                      [src]="getMediumImg(listing.offeredByUser.profilePhotoImageId)"
                      alt="avatar"
                    />
                    <p (click)="navigateToBroker(listing.offeredByUser.id)" class="contact-name center broker-name">
                      {{ listing.offeredByUser ? listing.offeredByUser.firstNameAndLastName : '' }}
                    </p>
                    <p
                      style="padding-top: 10px; cursor:pointer;"
                      (click)="navigateToBroker(listing.offeredByUser.id)"
                      class="contact-name center"
                    >
                      <a>{{ 'global.listing-detail.seeMyListings' | translate }}</a>
                    </p>
                  </ion-col>
                  <ion-col
                    style="padding-left:unset;"
                    [ngClass]="{
                      'margin-tp-unset': listing.offeredByUser && listing.offeredByUser.isPremiumAgent,
                      'margin-top-30': listing.offeredByUser && !listing.offeredByUser.isPremiumAgent
                    }"
                    size="8"
                  >
                    <p style="text-align: right;" *ngIf="listing.offeredByUser && listing.offeredByUser.isPremiumAgent">
                      <ion-chip class="prem-chip">Premium</ion-chip>
                    </p>
                    <div
                      [ngClass]="{
                        'margin-top-n-15':
                          listing.offeredByUser &&
                          !listing.offeredByUser.isPremiumAgent &&
                          listing.offeredByUser.whatsappNumber
                      }"
                    >
                      <p class="contact-list  overflow-contact">
                        <ion-icon class="ion-icon-color" name="business"></ion-icon>
                        <span class="default-contact-left" *ngIf="listing.offeredByUser">
                          <span class="add-padding default-company-color">{{
                            listing.offeredByUser.company.name
                          }}</span>
                        </span>
                      </p>

                      <p
                        class="contact-list point overflow-contact"
                        *ngIf="listing.offeredByUser && listing.offeredByUser.phoneNumber"
                      >
                        <a class="add-padding">
                          <ion-icon class="ion-icon-color" name="call"></ion-icon>
                          <span class="default-contact-left">
                            <span
                              class="list-show listing-agent-font-size"
                              *ngIf="!contactPhone"
                              (click)="showContactPhone()"
                            >
                              {{ 'global.listing-detail.showPhoneNumber' | translate }}
                            </span>
                          </span>
                          <span class="border-bottom-unset" *ngIf="contactPhone">
                            <a
                              href="tel:{{ listing.offeredByUser.phoneNumber }}"
                              class="add-padding listing-agent-font-size"
                              >{{ listing.offeredByUser.phoneNumber }}</a
                            >
                          </span>
                        </a>
                      </p>

                      <p
                        class="contact-list overflow-contact"
                        *ngIf="listing.offeredByUser && listing.offeredByUser.whatsappNumber"
                      >
                        <a class="add-padding">
                          <ion-icon class="wa-icon-color" name="logo-whatsapp"></ion-icon>
                          <span class="default-contact-left point">
                            <span class="list-show-whatsapp" *ngIf="!whatsappPhone" (click)="showBrokerWhatsapp()">
                              {{ 'global.listing-detail.showWhataspp' | translate }}
                            </span>
                          </span>
                          <span class="border-bottom-unset" *ngIf="whatsappPhone">
                            <span class="add-padding whats-app">{{ listing.offeredByUser.whatsappNumber }}</span>
                          </span>
                        </a>
                      </p>

                      <p class="contact-list point overflow-contact" *ngIf="whatsappPhone">
                        <a class="add-padding-left">
                          <span></span>
                          <span class="default-contact-left">
                            <span class="click-to-reveal border-bottom-unset">
                              <a
                                role="button"
                                (click)="clickToChat()"
                                translate="global.listing-detail.clickToChat.name"
                                class="add-padding"
                              >
                              </a>
                            </span>
                          </span>
                        </a>
                      </p>

                      <ion-row class="listing-agent-font-size">
                        <span class="row-left-content-card">
                          <ion-icon class="ion-icon-color" name="mail-open"></ion-icon>
                        </span>
                        <ion-col class="row-right-content-card">
                          <span *ngIf="!contactEmail" class="point" (click)="showContactEmail()">
                            {{ 'global.listing-detail.showEmail' | translate }}
                          </span>
                          <span *ngIf="contactEmail" class="ow-break-word default-cursor">{{
                            listing.offeredByUser?.email
                          }}</span>
                        </ion-col>
                      </ion-row>

                      <p class="contact-list point overflow-contact" *ngIf="contactEmail">
                        <a class="add-padding-left">
                          <span></span>
                          <span class="default-contact-left">
                            <span class="click-to-reveal border-bottom-unset">
                              <a
                                role="button"
                                (click)="clickToEmail($event)"
                                translate="global.listing-detail.clickToEmail.name"
                                class="add-padding"
                              >
                              </a>
                            </span>
                          </span>
                        </a>
                      </p>
                    </div>
                    <!-- <div class="listing-company-logo">
                      <img
                        style="height:50px; padding-left: 3%; width: auto;"
                        *ngIf="listing.createdByUser.companyLogoImageId || listing.createdByUser.companyLogoId"
                        [src]="
                          getMediumImg(
                            listing.createdByUser.companyLogoImageId
                              ? listing.createdByUser.companyLogoImageId
                              : listing.createdByUser.companyLogoId
                          )
                        "
                        alt="company logo"
                        default="assets/default_image.jpg"
                      />
                    </div> -->
                  </ion-col>
                </ion-card>
              </ion-row>

              <ion-row style="margin-top:21px;" *ngIf="listing.listingType.toLowerCase() === 'basic'">
                <ion-card class="broker-profile">
                  <ion-col style="padding-left:unset; margin-top: 10px" size="12">
                    <div>
                      <p class="contact-list  overflow-contact">
                        <ion-icon class="ion-icon-color" name="business"></ion-icon>
                        <span class="default-contact-left">
                          <span class="add-padding default-company-color">{{ listing.propertyManagementCompany }}</span>
                        </span>
                      </p>
                      <p class="contact-list  overflow-contact">
                        <ion-icon class="ion-icon-color" name="person"></ion-icon>
                        <span class="default-contact-left">
                          <span class="add-padding">{{ listing.managerName }}</span>
                        </span>
                      </p>
                      <p class="contact-list point overflow-contact">
                        <a class="add-padding">
                          <ion-icon class="ion-icon-color" name="call"></ion-icon>
                          <span class="default-contact-left">
                            <span class="list-show" *ngIf="!contactPhone" (click)="showContactPhone()">
                              {{ 'global.listing-detail.showPhoneNumber' | translate }}
                            </span>
                          </span>
                          <span class="list-show-reveal border-bottom-unset" *ngIf="contactPhone">
                            <a href="tel:{{ listing.managerTelephone }}" class="add-padding">{{
                              listing.managerTelephone
                            }}</a>
                          </span>
                        </a>
                      </p>
                      <p class="contact-list point overflow-contact">
                        <ion-icon class="ion-icon-color" name="mail-open"></ion-icon>
                        <a class="add-padding">
                          <span class="default-contact-left">
                            <span *ngIf="!contactEmail" class="list-show" (click)="showContactEmail()">
                              {{ 'global.listing-detail.showEmail' | translate }}
                            </span>
                            <span *ngIf="contactEmail" class="list-show-reveal border-bottom-unset">
                              <a href="mailto:{{ listing.managerEmail }}" class="add-padding">{{
                                listing.managerEmail
                              }}</a>
                            </span>
                          </span>
                        </a>
                      </p>
                    </div>
                  </ion-col>
                </ion-card>
              </ion-row>
            </div>

            <span *ngIf="listing" class="listing-detail-data__details">
              <app-listing-detail-page
                [previewListing]="previewListing ? true : false"
                [listing]="listing"
                (scrollToView)="scrollToView($event)"
                (removedTagsFromNearBy)="removedTagsFromNearBy($event)"
              >
              </app-listing-detail-page>
            </span>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
  <app-scroll-to-top [showComponent]="displayScrollToTop" (scroll)="scrollToTop(contentBlock)" scroller>
  </app-scroll-to-top>
</div>
