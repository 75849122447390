import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { ListingService } from '@app/core/listings';
import * as Fuse from 'fuse.js';
import { TranslateService } from '@ngx-translate/core';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { I18nService } from '@app/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-modal-page',
  templateUrl: './listing-location-popover.component.html',
  styleUrls: ['./listing-location-popover.component.scss']
})
export class ListingLocationPopoverComponent implements OnInit {
  @ViewChild('searchbar', { static: false }) searchbar: any;

  public selectedCityState: string = '';
  public faMapMarkerAlt: any;
  public locationData: any;
  public cities: any;
  public inputCity: string = '';
  public inputState: string = '';
  public cityOptionSelected: boolean = false;
  public stateOptionSelected: boolean = false;
  public cityOptions: any = [];
  public stateOptions: any = [];
  public defaultCityStateOptions: any[] = [];
  public otherOptions: any[] = [];
  public allCities: any[] = [];
  public fuse: Fuse<any, any>;
  public fuseState: Fuse<any, any>;
  public preSelectedCityIndex: number = -1;
  public selectOptions: any = {
    cssClass: 'remove-ok'
  };
  public spotNearMe: any = {
    selected: false
  };
  public isOther: boolean = false;
  public selected: string = '';
  isMobile: boolean = false;
  onlyStateCity: boolean = false;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private listingService: ListingService,
    private _ts: TranslateService,
    private i18n: I18nService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.faMapMarkerAlt = faMapMarkerAlt;
    this.getPopData();
  }

  private setSearchBarFocus() {
    const isStateOther: boolean = this.isOtherState();
    if (isStateOther) {
      setTimeout(() => {
        this.searchbar.setFocus();
      }, 1000);
    }
  }

  private setSearchModel() {
    this.allCities = this.locationData.allCities;
    this.defaultCityStateOptions = this.listingService.filterCityState(this.allCities, this.locationData.states);

    if (this.locationData.stateId || this.locationData.cityId) {
      this.selectedCityState = this.listingService.getCurrentLocation(this.locationData, this.defaultCityStateOptions);
      if (this.selectedCityState == 'other') {
        let state = this.locationData.states.filter((item: any) => item.id === this.locationData.stateId);
        this.inputState = this.getI18nValue(state.length > 0 ? state[0].name : '');

        /*  this.SelectOther(); */
        this.stateOptionSelected = true;
      }
      this.getCities();
      this.setSearchBarFocus();
    } else {
      this.getCities();
    }
  }

  private getFullCityName() {
    return this.cities
      .filter((city: any) => {
        return city.id === this.locationData.cityId;
      })
      .map((city: any) => city.name);
  }

  // Get Data passed from component opening popover
  getPopData() {
    this.locationData = this.navParams.data;
    this.spotNearMe.selected = this.locationData.isSpotNearMeSearch;
    this.setSearchModel();
    this.unsetCityAndState();
  }

  // Dismissing popover with data passed back to main component
  dismiss(fromCancel: boolean) {
    this.locationData.fromCancel = fromCancel;
    this.popoverCtrl.dismiss(this.locationData);
  }

  getFuzzyOptions() {
    if (this.cityOptionSelected) {
      this.onlyStateCity = false;
      this.cityOptionSelected = false;
      return;
    }

    if (!this.inputCity.trim().length) {
      this.cityOptions = [];
      this.locationData.cityId = null;
      this.onlyStateCity = false;
      return;
    }

    this.onlyStateCity = true;

    //Commented line is used for exact-match autosuggest. Second line is used for fuzzy search.
    this.cityOptions = this.fuse.search(this.inputCity);
  }

  getFuzzyOptionsState() {
    if (this.stateOptionSelected) {
      this.onlyStateCity = false;
      this.stateOptionSelected = false;
      return;
    }

    if (!this.inputState.trim().length) {
      this.onlyStateCity = false;
      this.stateOptions = [];
      this.locationData.stateId = null;
      return;
    }

    this.onlyStateCity = true;

    const options: Fuse.FuseOptions<any> = {
      keys: ['urlName']
    };
    this.fuseState = new Fuse(this.locationData.states, options);
    //Commented line is used for exact-match autosuggest. Second line is used for fuzzy search.
    this.stateOptions = this.fuseState.search(this.inputState);
  }

  unsetCityAndGetCityOptions() {
    this.locationData.cityId = null;
    this.inputCity = '';
    this.spotNearMe.selected = false;
    this.locationData.isSpotNearMeSearch = this.spotNearMe.selected;
    this.getCities();
  }

  async getCities() {
    this.cities = this.locationData.stateId
      ? await this.listingService.getCitiesByState(this.locationData.stateId)
      : this.allCities;

    if (this.locationData.cityId && this.selectedCityState == 'other') {
      const cityNameFull = this.getFullCityName();
      this.inputCity = this.getI18nValue(cityNameFull);
      this.cityOptionSelected = true;
    }

    const options: Fuse.FuseOptions<any> = {
      keys: ['name']
    };
    this.fuse = new Fuse(this.cities, options);
  }

  selectCity(city: any) {
    this.locationData.cityId = city.id;
    this.inputCity = this.getI18nValue(city.name);
    this.cityOptionSelected = true;
    if (!this.locationData.stateId) {
      this.locationData.stateId = city.countryState.id;
    }
    this.cityOptions = [];
    this.preSelectedCityIndex = -1;
    this.spotNearMe.selected = false;
    this.locationData.isSpotNearMeSearch = this.spotNearMe.selected;
  }

  selectState(state: any) {
    this.locationData.stateId = state.id;
    this.inputState = this.getI18nValue(state.name);
    this.stateOptionSelected = true;
    this.cityOptionSelected = false;
    this.stateOptions = [];
    this.unsetCityAndGetCityOptions();
  }

  clearSearch() {
    this.locationData.stateId = null;
    this.locationData.cityId = null;
    this.inputCity = '';
    this.inputState = '';
    this.selectedCityState = '';
    this.dismiss(false);
  }

  getI18nValue(textValue: string) {
    try {
      return this.i18n.getTranslation(textValue);
    } catch (e) {
      console.error('failed to parse locale string -> listings search');
    }
  }

  unsetCityAndState() {
    this.locationData.isSpotNearMeSearch = this.spotNearMe.selected;
    this.listingService.spotNearMeFilter.next(this.locationData.isSpotNearMeSearch);
    if (this.spotNearMe.selected) {
      this.locationData.cityId = null;
      this.inputCity = '';
      this.locationData.stateId = null;
      this.selectedCityState = null;
      this.getCities();
    }
  }

  onSpotNearMeChange() {
    this.spotNearMe.selected = !this.spotNearMe.selected;
    this.unsetCityAndState();
    this.dismiss(false);
  }

  public selectCityState(state: any, param = 0) {
    this.selectedCityState = state.detail ? state.detail.value : state;
    this.spotNearMe.selected = false;
    this.locationData.isSpotNearMeSearch = this.spotNearMe.selected;

    if (this.selectedCityState !== 'other') {
      const locationFilter: any = this.listingService.setLocationFilter(this.selectedCityState);
      Object.keys(locationFilter).forEach(key => {
        this.locationData[key] = locationFilter[key];
      });
    }
    this.setSearchBarFocus();
  }

  public isOtherState(): boolean {
    let element: any = document.getElementsByClassName('popover-content');
    if (this.selectedCityState && this.selectedCityState === 'other') {
      element[0].style.height = '660px';
      return true;
    } else {
      if (element && element.length > 0) {
        element[0].style.height = '600px';
      }
      return false;
    }
    return this.selectedCityState && this.selectedCityState === 'other';
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.dismiss(false);
    }
    if (this.cityOptions && this.cityOptions.length) {
      if (
        (event.key === 'ArrowDown' || event.key === 'Tab') &&
        this.preSelectedCityIndex < Math.max(2, this.cityOptions.length - 1)
      ) {
        this.preSelectedCityIndex++;
      } else if (event.key === 'ArrowUp' && this.preSelectedCityIndex > -1) {
        this.preSelectedCityIndex--;
      }

      if (event.key === 'Enter' && this.preSelectedCityIndex > -1) {
        this.selectCity(this.cityOptions[this.preSelectedCityIndex]);
      }
    } else if (event.key === 'Enter') {
      this.dismiss(false);
    }
  }

  SelectOther() {
    let element: any;

    if (this.isOther) {
      element = document.getElementsByClassName('state-flex-col-other');
      this.selectedCityState = this.selected == 'other' ? '' : this.selected;
      this.isOther = false;
      element[0].style.background = '#fff';
      element[0].style.border = '1px solid #043650';
      element[0].style.color = ' #043650';
    } else {
      let element: any = document.getElementsByClassName('state-flex-col-other');
      this.selected = this.selectedCityState;
      this.selectedCityState = 'other';
      this.stateOptionSelected = this.inputState == '' ? false : true;
      this.cityOptionSelected = this.inputCity == '' ? false : true;
      this.isOther = true;
      element[0].style.background = 'rgb(169,169,169)';
      element[0].style.border = '1px solid rgb(169,169,169)';
      element[0].style.color = 'rgb(84,77,77)';
    }
  }

  SelectCityState() {
    this.dismiss(false);
  }
}
