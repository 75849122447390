import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18nService, PlatformHelperService, ImageService } from '@app/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { BrokersService } from '../services/brokers.service';
import { RequestInformationType } from '@app/models/request-information.model';
import { RoutingHelperService } from '../../core/helpers/routing-helper.service';
import { Location } from '@angular/common';
import { environment } from '@env/environment';
import { MetaService } from '@app/core/seo';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
export class BrokerDetailsComponent {
    constructor(activatedRoute, i18NService, breakpointObserver, brokersService, platformConfigHelper, imageService, popOverCtrlHelper, routingHelper, metaService, analyticsService, userActivityService, deviceDetector, _location) {
        this.activatedRoute = activatedRoute;
        this.i18NService = i18NService;
        this.breakpointObserver = breakpointObserver;
        this.brokersService = brokersService;
        this.platformConfigHelper = platformConfigHelper;
        this.imageService = imageService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.routingHelper = routingHelper;
        this.metaService = metaService;
        this.analyticsService = analyticsService;
        this.userActivityService = userActivityService;
        this.deviceDetector = deviceDetector;
        this._location = _location;
        this.displayScrollToTop = false;
        this.searching = false;
        this.showEmail = true;
        this.showWhatsapp = true;
        this.showSrollTop = false;
        this.cookieAcceptanceName = 'SIILA-cookie-usage-acceptance';
    }
    ngAfterViewInit() {
        this.showSrollTop = true;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
            this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
            this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
            if (this.isDesktop.length) {
                this.currentDevice = this.isDesktop;
            }
            else if (this.isMobilePhone.length) {
                this.currentDevice = this.isMobilePhone;
            }
            else if (this.isTablet.length) {
                this.currentDevice = this.isTablet;
            }
            this.screenWidth = window.innerWidth;
            this.sharedIcon = faShareAlt;
            this.findTotalAgentCards();
            this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
                this.isMobile = result.matches;
            });
            this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
            this.getParamsUrl();
        });
    }
    getParamsUrl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.activatedRoute.params.subscribe((paramMap) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    this.brokerId = paramMap.brokerId;
                    this.brokersService.getMetadataById(this.brokerId).then((brokerMetadata) => {
                        this.metaService.setBrokerMetaTagsLight(brokerMetadata);
                        window.prerenderReady = true;
                    });
                    this.registerUserActivity(UserActivityType.PAGEVIEW_BROKER, this.brokerId);
                    this.spotBuildingSearchRequest = { brokerId: this.brokerId };
                    this.spotBuildingSearchRequest.listingClasses = ['FEATURED', 'PREMIUM'];
                    this.brokerDetailsURL = yield this.brokersService.getBrokerURL(this.brokerId);
                    if (this.brokerDetailsURL) {
                        if (this.i18NService.currentUrlContainsCurrentLanguage()) {
                            this.brokerDetailsURL.detailsURL = this.i18NService.addCurrentLanguageToPath(this.brokerDetailsURL.detailsURL);
                        }
                        this._location.go(this.brokerDetailsURL.detailsURL);
                    }
                    this.brokersService.getById(this.brokerId).then((broker) => {
                        this.brokerDTO = broker;
                        this.brokerBIO = this.brokerDTO.bio ? this.i18NService.getTranslation(this.brokerDTO.bio) : null;
                        this.brokersService.getSpotTitlesByBrokerId(this.brokerId).then((result) => {
                            this.spotBuildingNames = result;
                            //this.metaService.setBrokerMetaTags(this.brokerDTO, this.brokerBIO, this.spotBuildingNames);
                        });
                        let brokerHeader = 'Header_0.png';
                        if (this.brokerDTO.brokerHeader) {
                            brokerHeader = this.brokerDTO.brokerHeader;
                        }
                        this.brokerHeaderURL = `../../../assets/images/broker-headers/${brokerHeader}`;
                    });
                }
                catch (error) {
                    console.error('Error : ', error);
                }
            }));
        });
    }
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
        this.findTotalAgentCards();
    }
    navigateToCompany() {
        if (this.brokerDTO.companyProfile) {
            this.routingHelper.navigateToCompanyDetailsPage(this.brokerDTO.companyId);
        }
    }
    isFooterVisible(event) {
        this.displayScrollToTop = event.visible;
    }
    findTotalAgentCards() {
        this.visibleAgentCards = 3;
        if (this.screenWidth < 1024) {
            this.visibleAgentCards = 1;
        }
        else if (this.screenWidth < 1200) {
            this.visibleAgentCards = 2;
        }
        //this.updateDots();
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    buildTitleMetatag(broker) {
        let metaTagTitle = '';
        const brokerTitleTag = broker.firstNameAndLastName;
        metaTagTitle = `${brokerTitleTag} | SiiLA SPOT`;
        // KeyWord Metatag
        let keywordsMetaTag = '';
        const keywordsMetaTags = [];
        keywordsMetaTags.push(brokerTitleTag);
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle, keywordsMetaTag };
    }
    setBrokerMetaTags() {
        const detailsMetaTags = this.metaService.buildBrokerMetatag(this.brokerDTO, this.spotBuildingNames);
        const fullTitleText = detailsMetaTags.metaTagTitle;
        const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        const descText = this.brokerBIO ? this.removeHTMLCode(this.i18NService.getTranslation(this.brokerBIO)) : '';
        const locale = this.i18NService.getCurrentLocale();
        const brokerTitle = this.i18NService.getTranslation(this.brokerDTO.firstNameAndLastName);
        const fullDetailsURL = window.location.href;
        const tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [brokerTitle] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
            {
                name: 'og:image',
                property: 'og:image',
                content: this.brokerDTO.photoId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.brokerDTO.photoId)}`)
                    : null
            },
            {
                name: 'image',
                content: this.brokerDTO.photoId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.brokerDTO.photoId)}`)
                    : null
            },
            { name: 'twitter:card', content: `summary_large_image` },
            { name: 'twitter:title', content: [brokerTitle] },
            {
                name: 'twitter:image',
                content: this.brokerDTO.photoId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.brokerDTO.photoId)}`)
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.metaService.setDynamicTags(tags, fullTitleText);
    }
    removeHTMLCode(htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    }
    sendMessage(event) {
        let sentEmailTo = [];
        sentEmailTo.push(this.brokerDTO.userEmail);
        let reqInfoInput = {
            requestTitle: this.brokerDTO.firstNameAndLastName,
            brokerRequest: true,
            sentEmailTo: sentEmailTo,
            requestType: RequestInformationType.REQ_BROKER
        };
        this.popOverCtrlHelper.openRequestInformationPopup(event, reqInfoInput);
    }
    shareCompany(event) {
        const url = window.location.href;
        const sharedTitle = this.brokerDTO.firstNameAndLastName;
        const modalTitle = `Share Brokers's Profile`;
        const input = { url, sharedTitle, modalTitle };
        this.userActivityService.trackBrokerShare(this.brokerDTO.brokerId);
        this.popOverCtrlHelper.openSocialMediaPopup(event, input);
    }
    getDefaultTranslation(translationArtifact) {
        try {
            return this.i18NService.getTranslation(translationArtifact);
        }
        catch (e) {
            console.error(e);
        }
    }
    openEmail(event) {
        event.stopImmediatePropagation();
        if (!this.showEmail) {
            window.location.href = `mailto:${this.brokerDTO.userEmail}`;
        }
        this.showEmail = false;
        this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL, this.brokerId);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
        return false;
    }
    openWhatsapp(event) {
        event.stopImmediatePropagation();
        this.showWhatsapp = false;
        this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP, this.brokerId);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
        return false;
    }
    registerUserActivity(userActivityType, brokerId) {
        const userActivity = {
            module: userActivityType,
            brokerId: brokerId
        };
        this.userActivityService.saveUserActivity(userActivity);
    }
    checkCookiesUsageAcceptance() {
        let cookieAcceptance = false;
        cookieAcceptance = !!window.localStorage.getItem(this.cookieAcceptanceName);
        return cookieAcceptance;
    }
}
