import { Component, OnInit, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import * as Fuse from 'fuse.js';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonGenericService } from '@app/core/common.service';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { EventCheckerService } from '@app/core/listings';
import { I18nService } from '@app/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListingOfferType } from '../../../../../models/transaction-type.model';

@Component({
  selector: 'app-list-type',
  templateUrl: './list-type.component.html',
  styleUrls: ['./list-type.component.scss']
})
export class ListTypeComponent implements OnInit {
  @Input('form') listingTypeForm: FormGroup;
  @Input('addressForm') addressForm: FormGroup;
  @Input('detailsForm') detailsForm: FormGroup;
  @Input('listingPriceForm') listingPriceForm: FormGroup;
  @Input('stepper') stepper: MatStepper;
  @Input('subTypes') subTypes: any;
  @Input('listing') listing: any;
  @Input('buildingNames') buildingNames: any;
  @Input('buildings') buildings: any;
  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  stepOne: boolean = false;
  fuse: Fuse<any, any>;
  fuzzyName: any;
  searchTerm: any;
  industrialBuildings: any;
  isMobile: boolean;
  officeBuildings: any;
  languages: any = this.commonService.getLanguageOption();
  constructor(
    private registerService: RegisterService,
    private purchaseService: PurchaseCreditsService,
    private _ts: TranslateService,
    private commonService: CommonGenericService,
    private _eventChecker: EventCheckerService,
    private i18NService: I18nService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.getBuildingT();
    if (!this.listing) {
      this.unsetSaleValidators();
    } else {
      this.listing.isForLeaseSale === 'SALE' || this.listing.isForLeaseSale === 'S'
        ? this.unsetLeaseValidators()
        : this.listing.isForLeaseSale === 'LEASE' || this.listing.isForLeaseSale === 'L'
        ? this.unsetSaleValidators()
        : '';
      if (this.listing.isForLeaseSale === ListingOfferType.Coworking) {
        this.unsetLeaseValidators();
        this.unsetSaleValidators();
      }
    }
  }

  disableForm() {}

  async changeSaleType() {
    if (this.listingTypeForm.controls.listingType.value === 'forSale') {
      // unsetting validators
      this.unsetLeaseValidators();
    } else if (this.listingTypeForm.controls.listingType.value === 'forRent') {
      // listing for setting validations
      this.unsetSaleValidators();
    } else if (this.listingTypeForm.controls.listingType.value === 'forCoworking') {
      this.unsetSaleValidators();
      this.unsetLeaseValidators();
    }
  }

  unsetLeaseValidators() {
    this.listingPriceForm.controls['monthlyRent'].setValidators([]);
    this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
    this.listingPriceForm.controls['monthlyCondoFee'].setValidators([]);
    this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
    this.listingPriceForm.controls['monthlyIptu'].setValidators([]);
    this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
    this.listingPriceForm.controls['leaseableArea'].setValidators([]);
    this.listingPriceForm.controls['leaseableArea'].updateValueAndValidity();
    this.listingPriceForm.controls['rentableArea'].setValidators([Validators.required]);
    this.listingPriceForm.controls['rentableArea'].updateValueAndValidity();
    if (!this.listingPriceForm.value.pricePer) {
      this.listingPriceForm.controls['totalAskingPrice'].setValidators([Validators.required]);
      this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
    }
  }

  unsetSaleValidators() {
    this.listingPriceForm.controls['rentableArea'].setValidators([]);
    this.listingPriceForm.controls['rentableArea'].updateValueAndValidity();
    this.listingPriceForm.controls['totalAskingPrice'].setValidators([]);
    this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
    this.listingPriceForm.controls['leaseableArea'].setValidators([Validators.required]);
    this.listingPriceForm.controls['leaseableArea'].updateValueAndValidity();
    if (this.listingPriceForm.value.negRentableArea) {
      this.listingPriceForm.controls['monthlyRent'].setValidators([Validators.required]);
      this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
    }
  }

  setSubTypes(index: any) {
    if (this.subTypes[index].selected) {
      this.subTypes[index].selected = false;
      Object.keys(this.subTypes).forEach((type: any) => {
        this.subTypes[type].disabled = false;
      });
    } else {
      this.subTypes[index].selected = true;
      Object.keys(this.subTypes).forEach((type: any) => {
        this.subTypes[type].disabled = false;
      });
    }
    this.listingTypeForm.controls['subType'].setValue(this.subTypes);
  }

  async checkBuildingType() {
    this.clearInput();
    this.fuse = null;
    this.subTypes = await this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value);
    this.subTypes = await this.getSpaceTranslations(this.subTypes);

    if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
      this.buildingNames = this.industrialBuildings;
      this.buildings = this.industrialBuildings.values;
      this._eventChecker.typeBuildings$.next('changed');
    } else {
      this.buildingNames = this.officeBuildings;
      this.buildings = this.officeBuildings.values;
      this._eventChecker.typeBuildings$.next('changed');
    }
  }

  clearInput() {
    this.detailsForm.controls['propertySet'].setValue(false);
    this.detailsForm.controls.actualProperty.setValue(null);
    this.detailsForm.controls.propertyName.setValue('');
    this.detailsForm.controls.propertyName.enable();
  }

  async getBuildingT() {
    this.subTypes = await this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value);
    this.subTypes = await this.getSpaceTranslations(this.subTypes);

    this.listing && this.listing.propertySubTypes
      ? await this.initSubTypes(this.listing.propertySubTypes, this.subTypes)
      : null;
    this.setDisabledFields(this.subTypes);
    this.industrialBuildings = await this.purchaseService.getBuildingNamesByPropertyType(1001);
    this.officeBuildings = await this.purchaseService.getBuildingNamesByPropertyType(2001);
    if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
      this.buildingNames = this.industrialBuildings;
      this.buildings = this.industrialBuildings.values;
    } else {
      this.buildingNames = this.officeBuildings;
      this.buildings = this.officeBuildings.values;
    }
  }

  initSubTypes(subType: any, subTypes: any) {
    Object.keys(subType).forEach(key => {
      let result = subTypes.find((filter: any) => filter.id === subType[key].id);
      if (result) {
        result.selected = true;
      }
    });
  }

  setDisabledFields(subTypes: any) {}

  async getSpaceTypes(id: any) {
    return this.registerService.getBuildingSubTypes(id).toPromise();
  }

  async getSpaceTranslations(subTypes: any) {
    Object.keys(subTypes).forEach(async (key, idx) => {
      subTypes[key].name = this.getI18nValue(subTypes[key].name);
      subTypes[key].selected = false;
      subTypes[key].disabled = false;
    });
    return subTypes;
  }

  getI18nValue(textValue: string) {
    return this.i18NService.getTranslation(textValue);
  }
}
