import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Shell } from '@app/shell/shell.service';
import { extract } from '@app/core';
import { UserHomeComponent } from './user-home.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyMessageComponent } from './my-message/my-message.component';
import { ReviewMyListingComponent } from './my-listing/my-listing.component';
import { ReviewMyPaymentComponent } from './my-payment/my-payment.component';
import { ListingAnalyticsComponent } from './listing-analytics/listing-analytics.component';
import { AuthGuard } from '@app/guards/auth.guard';
import { CreateListingComponent } from './my-listing/create-listing';
import { CurrentPlansComponent } from './my-listing/current-plans/current-plans.component';
import { CanDeactivateGuard } from '@app/guards/can-deactivate.guard';

const routes: Routes = [
  Shell.childRoutesRunningGuardsResolversAlways([
    { path: '', redirectTo: '/', pathMatch: 'full' },
    {
      path: 'user-home',
      component: UserHomeComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('User Home')
      }
    },
    {
      path: 'user-home/my-account',
      component: MyAccountComponent,
      canDeactivate: [CanDeactivateGuard],
      data: {
        title: extract('User Account')
      }
    },
    {
      path: 'user-home/my-account/:language',
      component: MyAccountComponent,
      canDeactivate: [CanDeactivateGuard],
      data: {
        title: extract('User Account')
      }
    },
    {
      path: 'user-home/my-messages',
      component: MyMessageComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('My Messages')
      }
    },
    {
      path: 'user-home/my-messages/:language',
      component: MyMessageComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('My Messages')
      }
    },
    {
      path: 'user-home/my-listings',
      component: ReviewMyListingComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Review My Listings')
      },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'user-home/my-listings/:language',
      component: ReviewMyListingComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Review My Listings')
      },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'user-home/my-payments',
      component: ReviewMyPaymentComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Review My Payments')
      }
    },
    {
      path: 'user-home/my-payments/:language',
      component: ReviewMyPaymentComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Review My Payments')
      }
    },
    {
      path: 'user-home/listing-analytics',
      component: ListingAnalyticsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Listing Analytics')
      },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'user-home/listing-analytics/:language',
      component: ListingAnalyticsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Listing Analytics')
      },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'user-home/current-plans',
      component: CurrentPlansComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Current Plans')
      }
    },
    {
      path: 'user-home/current-plans/:language',
      component: CurrentPlansComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Current Plans')
      }
    },
    {
      path: 'user-home/my-listings/create-property',
      component: CreateListingComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard],
      runGuardsAndResolvers: 'always',
      data: { title: extract('Create Property') }
    },
    {
      path: 'user-home/my-listings/create-property/:language',
      component: CreateListingComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard],
      runGuardsAndResolvers: 'always',
      data: { title: extract('Create Property') }
    },
    {
      path: 'user-home/my-listings/create-property/:listingId',
      component: CreateListingComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard],
      runGuardsAndResolvers: 'always',
      data: { title: extract('Create Property') }
    },
    {
      path: 'user-home/my-listings/create-property/:listingId/:language',
      component: CreateListingComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard],
      runGuardsAndResolvers: 'always',
      data: { title: extract('Create Property') }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class UserHomeRoutingModule {}
