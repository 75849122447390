<!--COWORKING-->
<ion-row *ngIf="coworkingInfo">
  <ion-row class="full-width">
    <ion-col size="6">
      <ion-label class="full-width"
        >{{ 'global.coworking.maximumPositions' | translate }}<span class="blue">*</span>
      </ion-label>
      <ion-input
        (ionBlur)="checkMaxNumber(maximumPositions[0].maximumPositions)"
        [(ngModel)]="maximumPositions[0].maximumPositions"
        type="text"
        class="input-border"
        autofocus="true"
      >
      </ion-input>
    </ion-col>
  </ion-row>

  <ion-label class="full-width">{{ 'global.coworking.pricingTiers' | translate }}</ion-label>
  <ion-row
    class="full-width lightgray-border padding-10"
    style="margin-bottom:10px;"
    *ngFor="let info of coworkingInfo; let index = index"
  >
    <ion-col class="padding-unset" size="12">
      <ion-icon class="trash-icon right" *ngIf="index !== 0" (click)="removeTier(index)" name="trash"></ion-icon>
    </ion-col>

    <ion-col class="flex-center" size="6">
      <ion-label class="full-width"
        >{{ 'global.coworking.minimumPositions' | translate }} <span class="blue">*</span>
      </ion-label>
    </ion-col>
    <ion-col class="flex-center" size="6">
      <ion-input
        (ionBlur)="checkIni(info.positionIni, index)"
        [(ngModel)]="info.positionIni"
        type="text"
        class="input-border margin-bottom-unset"
        [readonly]="info.readOnly"
        [disabled]="info.readOnly"
      >
      </ion-input>
    </ion-col>

    <ion-col class="flex-center" size="2">
      <div style="display: flex; align-items: center;">
        <ion-label class="full-width">{{ 'global.coworking.sharedSpaces' | translate }} </ion-label>
        <ion-checkbox name="sharedSpacesCheck" [(ngModel)]="info.sharedCoworking"></ion-checkbox>
      </div>
    </ion-col>
    <ion-col class="flex-center" size="10"> </ion-col>
    <ion-col size="1"></ion-col>
    <ion-col class="flex-center" size="6">
      <ion-label class="full-width">{{ 'global.coworking.dailyPerPosition' | translate }} </ion-label>
    </ion-col>
    <ion-col class="flex-center" size="5">
      <ion-input
        [(ngModel)]="info.sharedDailyByPosition"
        placeholder="{{ 'global.coworking.negotiable' | translate }}"
        (ionBlur)="checkDailyPosition(info.sharedDailyByPosition, index)"
        type="text"
        class="input-border margin-bottom-unset"
        [hidden]="!info.sharedCoworking"
      >
      </ion-input>
    </ion-col>
    <ion-col size="1"></ion-col>
    <ion-col class="flex-center" size="6">
      <ion-label class="full-width">{{ 'global.coworking.monthlyPerPosition' | translate }} </ion-label>
    </ion-col>
    <ion-col class="flex-center" size="5">
      <ion-input
        [(ngModel)]="info.sharedMonthlyByPosition"
        [hidden]="!info.sharedCoworking"
        placeholder="{{ 'global.coworking.negotiable' | translate }}"
        (ionBlur)="checkMonthlyPosition(info.sharedMonthlyByPosition, index)"
        type="text"
        class="input-border margin-bottom-unset"
      >
      </ion-input>
    </ion-col>

    <ion-col class="flex-center" size="2">
      <div style="display: flex; align-items: center;">
        <ion-label class="full-width">{{ 'global.coworking.privateSpaces' | translate }} </ion-label>
        <ion-checkbox name="privateSpacesCheck" [(ngModel)]="info.privateCoworking"></ion-checkbox>
      </div>
    </ion-col>
    <ion-col class="flex-center" size="10"> </ion-col>
    <ion-col size="1"></ion-col>
    <ion-col class="flex-center" size="6">
      <ion-label class="full-width">{{ 'global.coworking.dailyPerPosition' | translate }} </ion-label>
    </ion-col>
    <ion-col class="flex-center" size="5">
      <ion-input
        [(ngModel)]="info.nonSharedDailyByPosition"
        [hidden]="!info.privateCoworking"
        placeholder="{{ 'global.coworking.negotiable' | translate }}"
        (ionBlur)="checkPrivateDailyPosition(info.nonSharedDailyByPosition, index)"
        type="text"
        class="input-border margin-bottom-unset"
      >
      </ion-input>
    </ion-col>
    <ion-col size="1"></ion-col>
    <ion-col class="flex-center" size="6">
      <ion-label class="full-width">{{ 'global.coworking.monthlyPerPosition' | translate }} </ion-label>
    </ion-col>
    <ion-col class="flex-center" size="5">
      <ion-input
        [(ngModel)]="info.nonSharedMonthlyByPosition"
        [hidden]="!info.privateCoworking"
        placeholder="{{ 'global.coworking.negotiable' | translate }}"
        (ionBlur)="checkPrivateMonthlyPosition(info.nonSharedMonthlyByPosition, index)"
        type="text"
        class="input-border margin-bottom-unset"
      >
      </ion-input>
    </ion-col>
  </ion-row>

  <ion-label class="pointer" (click)="addExtraCoworking()" style="margin-top: 10px;">
    {{ 'global.coworking.addAnotherTier' | translate }}
  </ion-label>
</ion-row>
