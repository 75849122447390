import { Injectable, OnInit } from '@angular/core';
import { I18nService } from '../i18n.service';
import { Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ReixLangCulture, ReixSpotLangs } from '../reixspotlanguages.service';
import { log } from 'console';

export enum CustomSpotSearchType {
  MARKET = 'MARKET',
  STATE = 'STATE',
  CITY = 'CITY'
}

export class AppRouteNames {
  public static readonly SEARCH = 'search';
  public static readonly SEARCH_COUNTRYLANG = 'resultado';
  public static readonly LEASE_URL_PARAM = 'lease';
  public static readonly LEASE_URL_PARAM_CL = 'locacao';
  public static readonly OFFICE_URL_PARAM = 'office';
  public static readonly OFFICE_URL_PARAM_CL = 'escritorio';
  public static readonly SAVED_SEARCH_URL_PARAM = 'saved-search';
  public static readonly SAVED_SEARCH_URL_PARAM_CL = 'salvar-busca';
  public static readonly FILTER_URL_PARAM = 'filter';
  public static readonly FILTER_URL_PARAM_CL = 'filtro';
  public static readonly SPOTSET_URL_PARAM = 'spotset';
  public static readonly SPOTSET_URL_PARAM_CL = 'buscas';
  public static readonly COMPANY_ROUTE = 'company';
  public static readonly OLD_USR_PROFILE_ROUTE = 'leasing-contact/detail';
  public static readonly COMPANY_ROUTE_CL = 'empresa';
  public static readonly USR_PROFILE_ROUTE = 'profile';
  public static readonly USR_PROFILE_ROUTE_CL = 'perfil';
  public static readonly TERMS_ROUTE = 'terms-of-use';
  public static readonly TERMS_ROUTE_CL = 'termos-de-uso';
  public static readonly PRIVACY_POLICY_ROUTE = 'privacy-policy';
  public static readonly PRIVACY_POLICY_ROUTE_CL = 'politica-de-privacidade';
  public static readonly MY_SPOTS_ROUTE = 'my-spots';
  public static readonly MY_SPOTS_ROUTE_CL = 'meus-spots';
  public static readonly REGION_URL_PARAM_CL = 'mercado';
  public static readonly REGION_URL_PARAM = 'market';
}

@Injectable({
  providedIn: 'root'
})
export class AppRouteNamesService {
  private routeNamesDictionary: Map<string, string>;
  private routeNamesDictionaryCL: Map<string, string>;
  constructor(private i18n: I18nService) {
    this.routeNamesDictionary = new Map();
    this.routeNamesDictionaryCL = new Map();
    this.routeNamesDictionary.set(AppRouteNames.MY_SPOTS_ROUTE, AppRouteNames.MY_SPOTS_ROUTE_CL);
    this.routeNamesDictionary.set(AppRouteNames.USR_PROFILE_ROUTE, AppRouteNames.USR_PROFILE_ROUTE_CL);
    this.routeNamesDictionary.set(AppRouteNames.SEARCH, AppRouteNames.SEARCH_COUNTRYLANG);
    this.routeNamesDictionary.set(AppRouteNames.REGION_URL_PARAM, AppRouteNames.REGION_URL_PARAM_CL);
    this.routeNamesDictionary.set(AppRouteNames.LEASE_URL_PARAM, AppRouteNames.LEASE_URL_PARAM_CL);
    this.routeNamesDictionary.set(AppRouteNames.OFFICE_URL_PARAM, AppRouteNames.OFFICE_URL_PARAM_CL);
    this.routeNamesDictionary.set(AppRouteNames.SAVED_SEARCH_URL_PARAM, AppRouteNames.SAVED_SEARCH_URL_PARAM_CL);
    this.routeNamesDictionary.set(AppRouteNames.FILTER_URL_PARAM, AppRouteNames.FILTER_URL_PARAM_CL);
    this.routeNamesDictionary.set(AppRouteNames.SPOTSET_URL_PARAM, AppRouteNames.SPOTSET_URL_PARAM_CL);
    this.routeNamesDictionary.set(AppRouteNames.COMPANY_ROUTE, AppRouteNames.COMPANY_ROUTE_CL);
    this.routeNamesDictionary.set(AppRouteNames.TERMS_ROUTE, AppRouteNames.TERMS_ROUTE_CL);
    this.routeNamesDictionary.set(AppRouteNames.PRIVACY_POLICY_ROUTE, AppRouteNames.PRIVACY_POLICY_ROUTE_CL);

    for (const [english, cl] of this.routeNamesDictionary.entries()) {
      this.routeNamesDictionaryCL.set(cl, english);
    }
  }

  translateSentence(translationDictionary: Map<string, string>, sentence: string): string {
    const joinCh = '/';
    const words = sentence.split(joinCh);

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (translationDictionary.has(word)) {
        words[i] = translationDictionary.get(word) as string;
      }
    }

    return words.join(joinCh);
  }

  private removeLeadingAndTrailingSlashes(route: string): string {
    return route.replace(/^\/|\/$/g, '');
  }

  findRouteAlternative(routeName: string) {
    let tmpRouteName = this.i18n.removeLanguageFromURL(routeName);
    routeName = this.removeLeadingAndTrailingSlashes(tmpRouteName);
    let newRoute = null;
    if (this.i18n.isLangEN()) {
      newRoute = this.translateSentence(this.routeNamesDictionaryCL, routeName);
    } else {
      newRoute = this.translateSentence(this.routeNamesDictionary, routeName);
    }
    if (!newRoute) {
      newRoute = tmpRouteName;
    }
    return this.i18n.addCurrentLanguageToPath(newRoute);
  }

  getSearchRouteName = () => {
    let searchRouteName = '';
    if (this.i18n.isLangEN()) {
      searchRouteName = AppRouteNames.SEARCH;
    } else {
      searchRouteName = AppRouteNames.SEARCH_COUNTRYLANG;
    }
    return searchRouteName;
  };

  getDefaultInitSearchRouteName = () => {
    let lease = `${AppRouteNames.LEASE_URL_PARAM_CL}`;
    let office = `${AppRouteNames.OFFICE_URL_PARAM_CL}`;

    if (this.i18n.isLangEN()) {
      lease = `${AppRouteNames.LEASE_URL_PARAM}`;
      office = `${AppRouteNames.OFFICE_URL_PARAM}`;
    }

    return `/${lease}/${office}`;
  };

  getSavedSearchRouteName = () =>
    this.i18n.isLangEN() ? AppRouteNames.SAVED_SEARCH_URL_PARAM : AppRouteNames.SAVED_SEARCH_URL_PARAM_CL;
  getFilterRouteName = () =>
    this.i18n.isLangEN() ? AppRouteNames.FILTER_URL_PARAM : AppRouteNames.FILTER_URL_PARAM_CL;
  getSpotSetRouteName = () =>
    this.i18n.isLangEN() ? AppRouteNames.SPOTSET_URL_PARAM : AppRouteNames.SPOTSET_URL_PARAM_CL;
  getCompanyRouteName = () => (this.i18n.isLangEN() ? AppRouteNames.COMPANY_ROUTE : AppRouteNames.COMPANY_ROUTE_CL);
  getTermsRouteName = () => (this.i18n.isLangEN() ? AppRouteNames.TERMS_ROUTE : AppRouteNames.TERMS_ROUTE_CL);
  getPrivacyPolyRouteName = () =>
    this.i18n.isLangEN() ? AppRouteNames.PRIVACY_POLICY_ROUTE : AppRouteNames.PRIVACY_POLICY_ROUTE_CL;
  getMySpotsRouteName = () => (this.i18n.isLangEN() ? AppRouteNames.MY_SPOTS_ROUTE : AppRouteNames.MY_SPOTS_ROUTE_CL);
  getMarketRoutePathName = () =>
    this.i18n.isLangEN() ? AppRouteNames.REGION_URL_PARAM : AppRouteNames.REGION_URL_PARAM_CL;
  findCustomSearchType(url: string): CustomSpotSearchType {
    let result = undefined;
    const urlPathParams = url.split('/');
    if (url.startsWith(`/${this.getSearchRouteName()}`)) {
      if (urlPathParams.length > 5) {
        if (url.includes(this.getMarketRoutePathName())) {
          result = CustomSpotSearchType.MARKET;
        } else if (urlPathParams.length == 7) {
          result = CustomSpotSearchType.CITY;
        } else if (urlPathParams.length == 6) {
          result = CustomSpotSearchType.STATE;
        }
      }
    }
    return result;
  }
}
