import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, AuthenticationService } from '@app/core/auth';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { CommonGenericService } from '@app/core/common.service';
import { FormGroup, FormControl } from '@angular/forms';
import { UpdateEmailModalComponent } from '../update-email/update-email-modal.component';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
import { UpdatePasswordModalComponent } from '../update-password/update-password-modal.component';
import { EditImageModalComponent } from '../edit-image/edit-image-modal.component';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { ImageService } from '@app/core/image.service';
import { I18nService } from '@app/core';
export class MyAccountComponent {
    constructor(auth, commonService, router, userAccountService, userHomeService, userActivityService, imageService, i18nService) {
        this.auth = auth;
        this.commonService = commonService;
        this.router = router;
        this.userAccountService = userAccountService;
        this.userActivityService = userActivityService;
        this.imageService = imageService;
        this.i18nService = i18nService;
        this.isMobile = false;
        this.advancedSettingsClass = 'collapse';
        this.expandAdvancedSettings = false;
        this.imagePlaceholder = 'assets/images/default-profile-photo.png';
        this.intlTelephoneOptions = this.commonService.getDefaultIntlPhoneProp();
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.noValidate = false;
        this.logoId = null;
        this.userHomeService = userHomeService;
        this.userProfileForm = this.userHomeService.createUserProfileForm();
    }
    ngOnInit() {
        this.profileLogoSubscription = this.imageService.getLogoId().subscribe(val => {
            this.logoId = val;
            if (this.logoId != null && this.auth.$isLoginSubject.value) {
                this.saveUserAccount(this.logoId);
            }
        });
        this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_USER_PROFILE_PAGE);
        this.profileImageSubscription = this.userHomeService.profileImage$.subscribe((image) => {
            this.profileImage = image;
        });
        this.getUserProfile();
        this.getLanguage = this.commonService.getLanguage;
        this.multiLangOptions = this.userHomeService.getMultiLangOption();
    }
    ngOnDestroy() {
        this.profileImageSubscription.unsubscribe();
        this.profileLogoSubscription.unsubscribe();
    }
    initUserProfileForm() {
        this.userBiographyValue = this.userAccountInfo.biography ? JSON.parse(this.userAccountInfo.biography) : undefined;
        this.userBiographyValueInitial = this.userAccountInfo.biography
            ? JSON.parse(this.userAccountInfo.biography)
            : undefined;
        this.setUserProfileFormValue('profileInfo', 'jobTitle', this.userAccountInfo.jobTitle);
        this.setUserProfileFormValue('userName', 'firstName', this.userAccountInfo.firstName);
        this.setUserProfileFormValue('userName', 'lastName', this.userAccountInfo.lastName);
        this.setUserProfileFormValue('location', 'address', this.userAccountInfo.address);
        this.setUserProfileFormValue('location', 'city', this.userAccountInfo.city);
        this.setUserProfileFormValue('location', 'state', this.userAccountInfo.state);
        this.setUserProfileFormValue('location', 'postalCode', this.userAccountInfo.postalCode);
        this.setUserProfileFormValue('location', 'country', this.userAccountInfo.country);
        this.setUserProfileFormValue('location', 'company', this.userAccountInfo.company.name);
        this.setUserProfileFormValue('advancedSettings', 'excludeFromCommunity', this.userAccountInfo.excludeFromCommunity);
        this.setUserProfileFormValue('advancedSettings', 'emailNotification', this.userAccountInfo.emailNotification);
        this.setUserProfileFormValue('advancedSettings', 'excludeFromCommunityEmails', this.userAccountInfo.excludeFromCommunityEmails);
        this.setUserProfileFormValue('contact', 'primaryPhone', this.userHomeService.getContactNumber(this.userAccountInfo, 'PHONE_NUMBER'));
        this.setUserProfileFormValue('contact', 'whatsApp', this.userHomeService.getContactNumber(this.userAccountInfo, 'WHATSAPP'));
    }
    setUserProfileFormValue(groupName, fieldName, fieldValue) {
        let formFieldValue = fieldValue !== null || fieldName !== 'undefined' ? fieldValue : '';
        this.userProfileForm
            .get(groupName)
            .get(fieldName)
            .setValue(formFieldValue);
    }
    getCurrentImageProfile(prop) {
        const userAccountInfo = this.userHomeService.setUserAccountProp(this.userAccountInfo, prop);
        const newProfileImage = this.userHomeService.setProfileImageProp(prop);
        this.userHomeService.profileImage$.next(newProfileImage);
        // this.userAccountService.currentUser$.next(userAccountInfo);
    }
    getUserProfile() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const userPhotos = yield this.userAccountService.getUserPhotoList().toPromise();
            const image = this.userAccountService.getUploadedImageId(userPhotos);
            this.userAccountInfo = yield this.userAccountService.getAccount().toPromise();
            this.userHomeService.getContactCountriesISO(this.userAccountInfo.phones);
            this.profileImage = this.userHomeService.setProfileImageProp(image);
            this.initUserProfileForm();
        });
    }
    getMediumImg(logoId = null) {
        return this.imageService.mediumThumbnail(logoId);
    }
    revomeLogo() {
        this.saveUserAccount('');
        this.imageService.setLogoId(null);
    }
    openInfoModal(headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modalProps = {
                backdropDismiss: false,
                showBackdrop: true,
                cssClass: 'generic-info-modal',
                component: InfoModalComponent,
                componentProps: {
                    headerTitle: headerTitle,
                    bodyMessage: bodyMessage,
                    data: data,
                    extraData: extraData
                }
            };
            const infoModal = yield this.commonService.createModal(modalProps);
            infoModal.onWillDismiss().then((result) => {
                if (result.data.action === 'changeEmailSuccess' || result.data.action === 'changePasswordSuccess') {
                    this.auth.logout();
                }
                else if (result.data.action === 'updateUserProfile') {
                    this.getMostCurrentProfile();
                }
                else if (result.data.action === 'updateProfileImageSuccess') {
                    this.getCurrentImageProfile(result.data.extraData);
                }
            });
            return infoModal.present();
        });
    }
    getMostCurrentProfile() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const userAccount = yield this.userAccountService.getAccount().toPromise();
            const userAccountInfo = {
                profile: userAccount,
                profileImage: this.profileImage.croppedImage,
                originalImage: this.profileImage.originalImage
            };
            // this.commonService.updateLocalStorageData('userProfile', userAccount);
            // this.userAccountService.currentUser$.next(userAccountInfo);
            this.userProfileForm.reset();
            this.goBackToUserHome();
        });
    }
    openUpdateEmailModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                auth: this.auth,
                commonService: this.commonService,
                currentEmail: this.userAccountInfo.login,
                openInfoModal: this.openInfoModal,
                userAccountService: this.userAccountService,
                userHomeService: this.userHomeService
            };
            const cssClass = 'change-email-modal';
            const modalProps = this.userHomeService.getModalProp(UpdateEmailModalComponent, componentProps, cssClass);
            const updateEmailModal = yield this.commonService.createModal(modalProps);
            return yield updateEmailModal.present();
        });
    }
    openUpdatePasswordModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                auth: this.auth,
                commonService: this.commonService,
                login: this.userAccountInfo.login,
                openInfoModal: this.openInfoModal,
                userHomeService: this.userHomeService,
                userAccountService: this.userAccountService
            };
            const cssClass = 'change-password-modal';
            const modalProps = this.userHomeService.getModalProp(UpdatePasswordModalComponent, componentProps, cssClass);
            const updatePasswordModal = yield this.commonService.createModal(modalProps);
            return yield updatePasswordModal.present();
        });
    }
    openEditImageModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                commonService: this.commonService,
                getCurrentImageProfile: this.getCurrentImageProfile,
                login: this.userAccountInfo.login,
                profileImage: this.profileImage,
                openInfoModal: this.openInfoModal,
                profileImageUrl: this.profileImage.originalImage,
                userAccountService: this.userAccountService,
                userAccountInfo: this.userAccountInfo,
                userHomeService: this.userHomeService
            };
            const cssClass = 'edit-image-modal';
            const modalProps = this.userHomeService.getModalProp(EditImageModalComponent, componentProps, cssClass);
            const editImageModal = yield this.commonService.createModal(modalProps);
            return yield editImageModal.present();
        });
    }
    openEditLogoModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let userlogo = [];
            if (this.logoId) {
                userlogo.push(this.logoId);
            }
            else {
                userlogo.push(this.userAccountInfo.companyLogoImageId != undefined ? this.userAccountInfo.companyLogoImageId : null);
            }
            const imageLogo = this.userAccountService.getUploadedImageId(userlogo);
            this.imageLogos = this.userHomeService.setProfileImageProp(imageLogo);
            const componentProps = {
                commonService: this.commonService,
                getCurrentImageProfile: this.getCurrentImageProfile,
                login: this.userAccountInfo.login,
                profileImage: this.imageLogos,
                openInfoModal: this.openInfoModal,
                profileImageUrl: this.imageLogos.originalImage,
                userAccountService: this.userAccountService,
                userAccountInfo: this.userAccountInfo,
                userHomeService: this.userHomeService,
                editLogo: true
            };
            const cssClass = 'edit-image-modal';
            const modalProps = this.userHomeService.getModalProp(EditImageModalComponent, componentProps, cssClass);
            const editImageModal = yield this.commonService.createModal(modalProps);
            return yield editImageModal.present();
        });
    }
    get biographyFormArray() {
        return this.userProfileForm.get('profileInfo').get('biography');
    }
    canDeactivate() {
        if (this.noValidate) {
            // only when saving
            this.noValidate = false;
            return true;
        }
        const biographyList = this.userProfileForm.value.profileInfo.biography;
        const biography = biographyList.reduce((accumulator, item) => {
            accumulator = Object.assign(accumulator, item);
            return accumulator;
        }, {});
        let bio = JSON.stringify(biography);
        return !this.userProfileForm.touched && (bio == this.userAccountInfo.biography || !this.userAccountInfo.biography);
    }
    validateWhatsAppNumber() {
        const whatsAppControl = this.userProfileForm.get('contact').get('whatsApp');
        if (whatsAppControl.value == null) {
            return true;
        }
        if (whatsAppControl.errors || whatsAppControl.value.internationalNumber.length === 0) {
            const headerLabel = 'global.user.editUserProfile';
            let bodyMessage = 'global.user.profileForm.invalidNumber';
            if ((whatsAppControl.errors && whatsAppControl.errors.invalidNumberForRegion) ||
                whatsAppControl.value.internationalNumber.length === 0) {
                bodyMessage = 'global.user.profileForm.invalidNumberForRegion';
            }
            this.openInfoModal(headerLabel, bodyMessage);
            return false;
        }
        return true;
    }
    isFormInvalid() {
        const primaryPhoneControl = this.userProfileForm.get('contact').get('primaryPhone');
        const jobTitle = this.userProfileForm.get('profileInfo').get('jobTitle');
        const userNameControl = this.userProfileForm.get('userName');
        const jobTitleErrors = jobTitle.errors ? jobTitle.errors.required : false;
        const userNameErrors = userNameControl.invalid;
        return primaryPhoneControl.value == null || jobTitleErrors || userNameErrors;
    }
    saveUserAccount(logoId = null) {
        if (logoId || logoId == '') {
            this.noValidate = true;
            this.userAccountInfo.companyLogoImageId = logoId == '' ? null : logoId;
            const santizedRawFormData = this.userHomeService.santizeUserProfileForm(this.userProfileForm);
            const userProfilePayload = this.userHomeService.sanitizeUserProfile(this.userAccountInfo, santizedRawFormData);
            this.userAccountService.saveUserProfile(userProfilePayload).subscribe(() => {
                this.getUserProfile();
            });
        }
        else {
            const isFormInvalid = this.isFormInvalid();
            if (!isFormInvalid && this.validateWhatsAppNumber()) {
                this.noValidate = true;
                this.userAccountInfo.companyLogoImageId = this.logoId;
                const santizedRawFormData = this.userHomeService.santizeUserProfileForm(this.userProfileForm);
                const userProfilePayload = this.userHomeService.sanitizeUserProfile(this.userAccountInfo, santizedRawFormData);
                this.userAccountService.saveUserProfile(userProfilePayload).subscribe(() => {
                    const headerLabel = 'global.user.editUserProfile';
                    const bodyMessage = 'global.user.message.userProfileUpdateSuccessfully';
                    const action = 'updateUserProfile';
                    this.openInfoModal(headerLabel, bodyMessage, action);
                });
            }
        }
    }
    goBackToUserHome() {
        this.router.navigateByUrl(this.i18nService.getHomeSPOTURL());
    }
    expandCollapseAdvSettings() {
        this.expandAdvancedSettings = !this.expandAdvancedSettings;
        this.advancedSettingsClass = this.expandAdvancedSettings ? 'expand' : 'collapse';
    }
    setSubmitButtonClass() {
        const isInvalidForm = this.isFormInvalid();
        return isInvalidForm ? 'non-mobile-btn-disabled mobile-btn-disabled' : 'none-mobile-btn mobile-btn';
    }
}
