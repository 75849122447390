import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { CommonGenericService } from '@app/core/common.service';
export class MoreDetailsComponent {
    constructor(registerService, commonService) {
        this.registerService = registerService;
        this.commonService = commonService;
        this.offeredByUserApi = 'api/users/allOfSameCompany';
        this.selectedDescLanguage = [];
        this.selectedTitleLanguage = [];
        this.basicListingType = false;
    }
    ngOnInit() {
        this.multiLangInit();
        this.listing ? this.initExistingValues() : null;
        this.nonAdminInit();
    }
    nonAdminInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.spotAdmin = yield this.checkRole('ROLE_SPOT_ADMIN');
            this.listingAdmin = yield this.checkRole('ROLE_LISTING_ADMIN');
            this.languages = yield this.commonService.getLanguageOption();
            //this.allCompanies = await this.getCompanies();
            this.user = JSON.parse(localStorage.getItem('user'));
            if (this.listing && this.listing.listingType && this.listing.listingType === 'BASIC') {
                this.basicListingType = true;
                this.moreDetailsForm.controls['propertyManagementCompany'].setValidators([Validators.required]);
            }
            if (this.selectCompany) {
                this.users = yield this.getUsersByCompany(this.selectCompany.id);
            }
            if (this.offeredByUserId) {
                let userIndex = yield this.users.findIndex((v) => {
                    return v.id === this.offeredByUserId.id;
                });
                this.moreDetailsForm.controls['offeredByUserId'].setValue(this.users[userIndex]);
            }
        });
    }
    initExistingValues() {
        if (this.listing.title) {
            if (this.isJsonString(this.listing.title)) {
                this.existingTitle = JSON.parse(this.listing.title);
            }
            else {
                this.existingTitle = { en: this.listing.title };
            }
        }
        if (this.listing.comments) {
            if (this.isJsonString(this.listing.comments)) {
                this.existingComments = JSON.parse(this.listing.comments);
            }
            else {
                this.existingComments = { en: this.listing.comments };
            }
        }
    }
    isJsonString(str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    }
    titleChange(value) {
        if (value.title.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.title).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.title[key];
                if (obj['en'] === '' || obj['pt-br'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues
                ? this.moreDetailsForm.controls['title'].setValue(null)
                : this.moreDetailsForm.controls['title'].setValue(JSON.stringify(mergedObject));
        }
        else {
            this.moreDetailsForm.controls['title'].setValue(null);
        }
    }
    descriptionChange(value) {
        if (value.description.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.description).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.description[key];
                if (obj['en'] === '' || obj['pt-br'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues
                ? this.moreDetailsForm.controls['description'].setValue(null)
                : this.moreDetailsForm.controls['description'].setValue(JSON.stringify(mergedObject));
        }
        else {
            this.moreDetailsForm.controls['description'].setValue(null);
        }
    }
    checkRole(role) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    }
    multiLangInit() {
        this.titleFieldOptions = {
            columnLabel: 'global.registerSpot.title',
            element: { input: true },
            formArrayName: 'title',
            required: true
        };
        this.descriptionFieldOptions = {
            columnLabel: 'global.registerSpot.description',
            element: { richtext: true },
            formArrayName: 'description',
            required: true
        };
    }
    nameChange($event) {
        this.moreDetailsForm.controls['offeredByUserId'].setValue($event.value);
    }
    portChange($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.users = yield this.getUsersByCompany($event.value.id);
            this.agentComponent.clear();
        });
    }
    getUsersByCompany(companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getUsersByCompany(companyId).toPromise();
        });
    }
    getCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getListingCompanies().toPromise();
        });
    }
    onSelectChange(selectedValue) {
        this.tagValues = selectedValue.detail.value;
        this.moreDetailsForm.controls.tags.setValue(this.tagValues);
    }
}
