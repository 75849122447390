<app-breadcrumb
  inViewport
  [inViewportOptions]="{ thredhold: [0] }"
  [options]="breadcrumbOptions"
  [standardBackButton]="standardBackButton"
></app-breadcrumb>
<ion-row style="margin-top: 15px;">
  <div class="logo-wrapper">
    <h4 class="create-heading">
      {{ titleTranslated }}
      <img class="spot-img" src="assets/SPOT-Full-Logo.png" />
    </h4>
  </div>
</ion-row>
