<ion-footer mode="ios" class="ion-footer-display" style="background: #00334D;">
  <div class="container-fluid text-center">
    <div style="width: 100%!important;display: flex;justify-content: space-around;flex-wrap: wrap;color: #FFFFFF;">
      <div class="width-sections" style="margin-left: 12px;">
        <h5 class="text-uppercase">
          <a id="home-image" [routerLink]="goToHomePage()">
            <img
              style="height: 24px;width: 236px;"
              class="brand-img"
              src="assets/images/imageSpot.png"
              alt="SiiLA SPOT"
            />
          </a>
        </h5>
      </div>

      <div class="width-sections">
        <h5 class="text-uppercase font-h5" (click)="presentContactDialog()" style="cursor: pointer;">
          {{ 'footer.contact' | translate }}
        </h5>

        <ul class="list-unstyled">
          <li>
            <label for="">
              <img style="margin-right: 12px;" src="assets/footer/phone.png" alt="" />
              <a style="color: #FFFFFF;" href="tel:{{ globalPhoneNumber1 }}">
                {{ globalPhoneNumber1 }}
              </a></label
            >
          </li>
          <li>
            <label for="">
              <img style="margin-right: 12px;" src="assets/footer/email.png" alt="" />
              <a style="color: #FFFFFF;" (click)="openContactEmail()">spot@siila.com.br </a></label
            >
          </li>
          <li>
            <label for="">
              <img style="margin-right: 12px;" src="assets/footer/site.png" alt="" />
              <a [href]="siilaSpotHomePageURL()" style="color: #FFFFFF;">www.siilaspot.com.br </a></label
            >
          </li>
          <li>
            <label for="">
              <img style="margin-right: 14px;margin-left: 4px;" src="assets/footer/pin.png" alt="" />
              <a
                href="https://www.google.com/maps/place/SiiLA/@-23.5980393,-46.6836632,15z/data=!4m2!3m1!1[…]9d7fa8?sa=X&ved=2ahUKEwi1uMqfh6b3AhUBlGoFHc3WCHcQ_BJ6BQiBARAF"
                target="black"
                style="color: #FFFFFF;"
              >
                SiiLA
              </a></label
            >
          </li>
        </ul>
      </div>

      <div class="width-sections">
        <h5 class="text-uppercase font-h5">
          <a style="color: #FFFFFF;" [href]="goToAllProducts()" target="black">{{ 'footer.products' | translate }}</a>
        </h5>

        <ul class="list-unstyled text-uppercase">
          <li>
            <a style="color: #FFFFFF;" [href]="goToMarketAnalytics()" target="black">{{
              'footer.marketAnalitics' | translate
            }}</a>
          </li>
          <li>
            <a style="color: #FFFFFF;" [href]="goToGrocs()" target="black">{{ 'footer.grocs' | translate }}</a>
          </li>
          <li>
            <a style="color: #FFFFFF;" [href]="goToIndices()" target="black">{{ 'footer.indices' | translate }}</a>
          </li>
          <li>
            <a style="color: #FFFFFF;" [href]="goToInvestorSurvey()" target="black">{{
              'footer.investorsurvey' | translate
            }}</a>
          </li>
          <li>
            <a style="color: #FFFFFF;" [href]="goToSpot()" target="black">{{ 'footer.spot' | translate }}</a>
          </li>
          <li>
            <a style="color: #FFFFFF;" [href]="goToAcademy()" target="black">{{ 'footer.academy' | translate }}</a>
          </li>
          <li>
            <a style="color: #FFFFFF;" [href]="goToFiiDataPRO()" target="black">{{
              'footer.fiidatapro' | translate
            }}</a>
          </li>
        </ul>
      </div>

      <div class="width-sections">
        <h5 class="text-uppercase font-h5">
          <label style="color: #FFFFFF;">{{ 'footer.solutions' | translate }}</label>
        </h5>

        <ul class="list-unstyled">
          <li *ngFor="let solution of solutions">
            <a style="color: #FFFFFF;" [href]="solution.urlLink" target="black">{{ solution.nameLink }}</a>
          </li>
        </ul>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div style="width: 246px;font-size: 16px;font-weight: 400;">
        <!-- Links -->
        <h5 class="text-uppercase font-h5" style="margin-right: 10px;">{{ 'footer.connect' | translate }}</h5>

        <div>
          <a href="https://www.linkedin.com/company/siila-spot" target="_blank" class="remove-underline margin-icon">
            <img src="" alt="" src="assets/icons/VectorIn.png" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCX6FF_MFgZobMQlVKWhhc7g"
            target="_blank"
            class="remove-underline margin-icon"
          >
            <img src="" alt="" src="assets/footer/youtube_logo.svg" />
          </a>
          <a
            style="margin: 0px 7px 0px 0px;"
            href="https://www.facebook.com/SiiLASPOT.BR/"
            target="_blank"
            class="remove-underline"
          >
            <img src="" alt="" src="assets/icons/facebook.svg" />
          </a>
          <a href="https://www.instagram.com/siilaspot.br/" target="_blank" class="remove-underline margin-icon">
            <img src="" alt="" src="assets/icons/instagram.png" />
          </a>
          <a href="https://www.tiktok.com/@siilaspot.br" target="_blank" class="remove-underline margin-icon">
            <img src="" alt="" src="assets/icons/Vectortiktok.png" />
          </a>
        </div>
      </div>

      <div style="width: 100%;font-size: 14px;font-weight: 600; margin-top: 30px;margin-bottom: 10px;">
        <label for=""
          >{{ 'footer.reserved' | translate }}
          <a style="color: #FFFFFF;cursor: pointer; text-decoration: underline;" (click)="goToSiilaTermsPage()">{{
            'global.menu.options.terms' | translate
          }}</a>
          |
          <a
            style="color: #FFFFFF;cursor: pointer;text-decoration: underline;"
            (click)="goToSiilaPrivacyPolicyPage()"
            >{{ 'global.menu.options.privacyPolicy' | translate }}</a
          ></label
        >
      </div>
    </div>
  </div>
</ion-footer>
