import { NgModule } from '@angular/core';
import { UserHomeRoutingModule } from './user-home.routing.module';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { QuillModule } from 'ngx-quill';
import { InViewportModule } from 'ng-in-viewport';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule, ImageService, PurchaseCreditsService } from '@app/core';
import { ShellModule } from '@app/shell/shell.module';
import { MetaModule } from '@ngx-meta/core';
import { SearchNavigationModule } from '@app/search-navigation/search-navigation.module';
import { UserHomeComponent } from './user-home.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyMessageComponent } from './my-message/my-message.component';
import { ReviewMyListingComponent } from './my-listing/my-listing.component';
import { ReviewMyPaymentComponent } from './my-payment/my-payment.component';
import { ListingAnalyticsComponent } from './listing-analytics/listing-analytics.component';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { UpdateEmailModalComponent } from './update-email/update-email-modal.component';
import { UpdatePasswordModalComponent } from './update-password/update-password-modal.component';
import { EditImageModalComponent } from './edit-image/edit-image-modal.component';
import { CurrentPlansComponent } from './my-listing/current-plans/current-plans.component';
import { RenewModalComponent } from './my-listing/renew-modal/renew-modal.component';
import {
  ListTypeComponent,
  AddressComponent,
  CreateDetailsComponent,
  MoreDetailsComponent,
  ImgUploadComponent,
  ListingPriceComponent,
  ListingFeaturesComponent,
  CoworkingComponent
} from '@app/user-home/my-listing/create-listing/components';
import { HighLightPipe } from '@app/core/highlight-pipe';
import { CreateListingComponent } from '@app/user-home/my-listing/create-listing';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EventCheckerService } from '@app/core/listings/event-checker.service';
import { MyMessageService } from './my-message/my-message.service';
import { CanDeactivateGuard } from '@app/guards/can-deactivate.guard';
import { SiilaChatModule } from '@app/chat';
import { AdminUpdatesComponent } from './my-listing/admin-updates/admin-updates.component';
import { environment } from '@env/environment';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
// TODO REMOVE
import { RxStompConfig } from '@stomp/rx-stomp';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NotFoundModule } from '@app/not-found/not-found.module';
import { RenewExtendModalComponent } from './my-listing/renew-extend-modal/renew-extend-modal.component';

const config: SocketIoConfig = { url: environment.tdmessageUrl, options: {} };

// export function SockFactory() {
//   return new (window as any).SockJS(environment.sockJs);
// }

@NgModule({
  declarations: [
    EditImageModalComponent,
    MyAccountComponent,
    MyMessageComponent,
    ReviewMyListingComponent,
    ReviewMyPaymentComponent,
    ListingAnalyticsComponent,
    UpdateEmailModalComponent,
    UserHomeComponent,
    UpdatePasswordModalComponent,
    ListTypeComponent,
    AddressComponent,
    CreateDetailsComponent,
    MoreDetailsComponent,
    ImgUploadComponent,
    CoworkingComponent,
    ListingPriceComponent,
    ListingFeaturesComponent,
    HighLightPipe,
    CreateListingComponent,
    CurrentPlansComponent,
    RenewModalComponent,
    AdminUpdatesComponent,
    RenewExtendModalComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    InViewportModule,
    IonicModule,
    MetaModule,
    SocketIoModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    SharedModule,
    ShellModule,
    TranslateModule,
    UserHomeRoutingModule,
    SearchNavigationModule,
    NotFoundModule,
    FontAwesomeModule,
    SiilaChatModule
  ],
  exports: [SiilaChatModule],
  entryComponents: [
    EditImageModalComponent,
    UpdateEmailModalComponent,
    UpdatePasswordModalComponent,
    RenewModalComponent,
    AdminUpdatesComponent,
    RenewExtendModalComponent
  ],
  providers: [
    ImageService,
    UserHomeService,
    PurchaseCreditsService,
    EventCheckerService,
    CanDeactivateGuard,
    MyMessageService,
    {
      provide: InjectableRxStompConfig,
      useValue: RxStompConfig
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig]
    }
  ]
})
export class UserHomeModule {}
