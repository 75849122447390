<ion-row style="width: 100%;" *ngIf="!isMobile">
  <div class="col-md-12 text-center">
    <h2>
      <label
        ><b style="font-size: 25px;color: #00334d;">{{ 'buildinsDetail.servicesTitle' | translate }}</b></label
      >
    </h2>
    <br />
    <label
      ><b style="color: #00334d;">{{ 'buildinsDetail.servicesText' | translate }}</b></label
    >
  </div>

  <div class="col-md-12">
    <ion-slides [options]="" pager="true" #photoSlider id="photoSlider">
      <ion-slide style="width: 100%;" *ngFor="let service of services; let i = index">
        <div class="row serviceBox-hadow">
          <div class="col-md-8 p-0">
            <img
              alt="listing"
              [defaultImage]="defaultImage"
              class="img-services-card"
              [lazyLoad]="getMediumImg(getCroppedOrOriginalImage(service.photos[0]))"
            />
          </div>
          <div class="col-md-4 p-3 text-center" style="width: 249px;">
            <div class="position-relative h-100">
              <img
                style="width: 136px;height: 57px;display: initial;"
                [src]="getMediumImg(service.logoImageId)"
                default="assets/images/default-profile-photo.png"
              />
              <br />
              <label class="label-service">{{ service.serviceCategory }}</label>
              <br />
              <div style="height: 104px;">
                <label class="label-text-service">{{
                  service.services.length > 250 ? (service.services | slice: 0:250) + ' ...' : service.services
                }}</label>
              </div>

              <br />
              <ion-button class="see-more btn-color-2" (click)="goToService(service)">
                <span>{{ 'buildinsDetail.saibaMais' | translate }}</span>
              </ion-button>
            </div>
          </div>
        </div>
      </ion-slide>
    </ion-slides>

    <fa-icon
      (click)="getPreviousPhoto()"
      class="img-icon"
      [icon]="faChevronLeft"
      size="lg"
      style="color: #495057;position: absolute;top: 160px;cursor: pointer;z-index: 999;left: 124px;"
    >
    </fa-icon>

    <fa-icon
      (click)="getNextPhoto()"
      class="img-icon"
      [icon]="faChevronRight"
      size="lg"
      style=" color:#495057;position: absolute;top: 160px;cursor: pointer;z-index: 999;right: 124px;"
    >
    </fa-icon>
  </div>
</ion-row>

<ion-row *ngIf="isMobile">
  <div class="col-md-12 text-center">
    <h2>
      <label
        ><b style="font-size: 25px;color: #00334d;">{{ 'buildinsDetail.servicesTitle' | translate }}</b></label
      >
    </h2>
    <br />
    <label
      ><b style="color: #00334d;">{{ 'buildinsDetail.servicesText' | translate }}</b></label
    >
  </div>

  <div class="col-md-12">
    <ion-slides [options]="" pager="true" #photoSlider id="photoSlider">
      <ion-slide style="display: block;" *ngFor="let service of services; let i = index">
        <ion-row class="justify-content-center">
          <img
            alt="listing"
            style="border-radius: 4px 0px 0px 4px"
            [defaultImage]="defaultImage"
            [ngStyle]="cssImgServiceMobile()"
            [lazyLoad]="getMediumImg(getCroppedOrOriginalImage(service.photos[0]))"
          />
        </ion-row>
        <ion-row>
          <div class="col-md-12 padding-0 text-center">
            <img
              style="width: 136px;height: 57px;display: initial;margin-top: 20px;"
              [src]="getMediumImg(service.logoImageId)"
              default="assets/images/default-profile-photo.png"
            />
            <br />
            <label class="label-service">{{ service.serviceCategory }}</label>
            <br />
            <div style="height: 104px;">
              <label class="label-text-service">{{
                service.services.length > 250 ? (service.services | slice: 0:250) + ' ...' : service.services
              }}</label>
            </div>

            <br />
            <ion-button
              style="width: 96%;--background: #ff9900; color: #331F00;--color-hover: white;font-weight: 700;"
              (click)="goToService(service)"
            >
              <span>{{ 'buildinsDetail.seeMore' | translate }}</span>
            </ion-button>
          </div>
        </ion-row>
      </ion-slide>
    </ion-slides>
  </div>
</ion-row>
