import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { I18nService } from '@app/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { MatStepper, MatAutocompleteSelectedEvent, MatDialog, MatDialogRef } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageService } from '@app/core/image.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingController } from '@ionic/angular';
import { CommonGenericService } from '@app/core/common.service';
import { AccountService, AuthenticationService } from '@app/core/auth';
import { CompleteProfileComponent } from './complete-profile/complete-profile-modal.component';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { EditImageModalComponent } from '@app/user-home/edit-image/edit-image-modal.component';

@Component({
  selector: 'app-register',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  registerForm: FormGroup;
  registerFormDetails: FormGroup;
  passwordLength: boolean = false;
  passwordMatch: boolean = false;
  isCompleted: boolean = false;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  lastStep: boolean = false;
  companySelected: any;
  filteredCompanies: Observable<any[]>;
  selectedCompany: any = [];
  allCompanies: any = [];
  stepIndex: number = 0;
  emailName: any;
  isLoaded: boolean = false;
  fileName: any;
  ios: boolean = false;
  android: boolean = false;
  intlTelephoneOptions: any = this.commonService.getDefaultIntlPhoneProp();
  primaryPhoneCountryISO: 'br';
  userHomeService: any;
  logoId: any = null;
  currentDevice: string;
  isDesktop: string;
  isMobilePhone: string;
  isTablet: string;
  image: any;
  imageId: any;

  hide: boolean = true;
  hide1: boolean = true;
  uniqueEmailError: boolean = false;
  inactiveAccountExists: boolean = false;
  phoneError: boolean = false;
  companySelectedError: boolean = false;

  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @ViewChild('companyInput', { static: false }) companyInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
    private router: Router,
    private commonService: CommonGenericService,
    private deviceDetectorService: DeviceDetectorService,
    private loadingController: LoadingController,
    private i18nService: I18nService,
    private commonSvc: CommonGenericService,
    private auth: AuthenticationService,
    private dialog: MatDialog,
    private analyticsService: AnalyticsService,
    userHomeService: UserHomeService,
    private userAccountService: AccountService,
    private imageService: ImageService,
    private registerDialog: MatDialogRef<RegisterFormComponent>
  ) {
    this.userHomeService = userHomeService;
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceDetectorService.isDesktop() ? 'desktop' : '';
    this.isMobilePhone = this.deviceDetectorService.isMobile() ? 'mobile' : '';
    this.isTablet = this.deviceDetectorService.isTablet() ? 'tablet' : '';
    if (this.isDesktop.length) {
      this.currentDevice = this.isDesktop;
    } else if (this.isMobilePhone.length) {
      this.currentDevice = this.isMobilePhone;
    } else if (this.isTablet.length) {
      this.currentDevice = this.isTablet;
    }
    this.imageService.getLogoId().subscribe(val => {
      this.logoId = val;
    });
    this.createForm();
    this.detectDevice();
    this.getCompanies().then(result => {
      this.allCompanies = result;
      let autoIndex = this.allCompanies.findIndex((v: any) => {
        return v.id === 25217;
      });
      let autoCompany = this.allCompanies[autoIndex];
      const notListedText = this.i18nService.get('global.register.notListed');
      this.allCompanies.splice(autoIndex, 1);
      this.allCompanies.unshift(autoCompany);
      this.allCompanies.unshift({ name: [notListedText], id: 0, value: null });
      /* this.isLoaded = true; */
    });
  }

  portChange(event: { component: IonicSelectableComponent; value: any }) {
    this.selectedCompany = event /* .value */;
    /*  if (event.value.companyPhotoImageId) {
       this.image = this.imageService.mediumThumbnail(event.value.companyPhotoImageId);
       this.imageId = event.value.companyPhotoImageId;
       this.logoId = null;
     } else {
       this.image = null;
       this.imageId = null;
     } */
  }

  async getCompanies() {
    return this.registerService.getListingCompanies().toPromise();
  }

  detectDevice() {
    if (this.deviceDetectorService.os.toLowerCase() === 'ios') {
      this.ios = true;
    } else if (this.deviceDetectorService.os.toLowerCase() === 'android') {
      this.android = true;
    }
  }

  async presentToast() {
    this.registerService.presentToast();
  }

  async showWelcomeAlert(onDismiss: () => void = () => {}) {
    const title = this.i18nService.get('global.form.registrationComplete');
    const msg = this.i18nService.get('global.form.welcomeNewUser');
    this.registerService.showAlert(title, msg, onDismiss.bind(this));
  }

  checkEmail() {
    if (this.registerForm.controls.emailGroup.value.email != '') {
      this.resetErrors();

      this.registerService
        .getEmailUnique(this.registerForm.controls.emailGroup.value.email)
        .toPromise()
        .then(resp => {
          let emailAccount: any = resp;
          if (emailAccount && emailAccount.accountExists && emailAccount.inactiveAccountExists) {
            this.inactiveAccountExists = true;
            return false;
          } else if (emailAccount && emailAccount.accountExists) {
            this.uniqueEmailError = true;
            return false;
          }

          return true;
        });
    }

    /*   if (emailAccount && emailAccount.accountExists && emailAccount.inactiveAccountExists) {
        this.uniqueEmailError = true;
        this.isCompleted = false;
        this.emailName = this.registerForm.controls.emailGroup.value.email;
        this.presentToast(); //TODO:: change this message to the one listed in ticket and should be popup not a toast
      } else if (emailAccount && emailAccount.accountExists) {
        this.uniqueEmailError = true;
        this.isCompleted = false;
        this.emailName = this.registerForm.controls.emailGroup.value.email;
        this.presentToast();
      } else {
        this.isCompleted = true;
        this.uniqueEmailError = false;
        setTimeout(() => {
          this.goForward();
        }, 0);
      } */
  }

  resetErrors() {
    this.uniqueEmailError = false;
    this.inactiveAccountExists = false;
    this.phoneError = false;
    this.companySelectedError = false;
  }

  openCompleteProfileModal() {
    if (this.auth.$isLoginSubject.value) {
      this.dialog.open(CompleteProfileComponent, {
        closeOnNavigation: true,
        disableClose: false,
        width: '300px'
      });
    }
  }

  removeLogo() {
    this.imageService.setLogoId(null);
  }

  async submitBasicAccount() {
    if (!this.validateFieldsRequired()) {
      return;
    }

    this.isLoaded = true;
    /* this.lastStep = true;*/
    const phoneNumber = this.sanitizePhoneNumbers();
    /* this.presentLoadingWithOptions(); */
    let mergedForm = {
      ...this.registerForm.value.emailGroup
    };
    mergedForm.phoneNumber = { ...phoneNumber };
    let userObj = this.registerService.formatUserDetails(mergedForm);
    /*  if (this.logoId || this.imageId) {
       userObj.companyLogoImageId = this.image ? this.imageId : this.logoId;
     } */

    /*   mergedForm.companyId = this.registerForm.controls.emailGroup.value.dropdown.id
        ? this.registerForm.controls.emailGroup.value.dropdown.id
        : null;
      mergedForm.companyName = this.registerForm.controls.emailGroup.value.dropdown.name
        ? this.registerForm.controls.emailGroup.value.dropdown.name
        : null;
  
      delete mergedForm['confirmPass'];
      delete mergedForm['dropdown']; */

    await this.registerService
      .register(userObj)
      .toPromise()
      .then(() => {
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_GET_STARTED, this.currentDevice);
        this.isCompleted = true;
        this.isLoaded = false;
        /* this.loadingController.dismiss();
        window.history.go(-1); */
        /* this.showWelcomeAlert(this.openCompleteProfileModal); */
        /*this.auth.submitLoginCredentials(userObj.email, userObj.password).then(() => {
          this.router.navigate([`/`]);
          this.showWelcomeAlert(this.openCompleteProfileModal);
        });*/
      })
      .catch(() => {
        /* this.loadingController.dismiss(); */
        this.dismissAsDialog(true);
        this.isLoaded = false;
        const title = this.i18nService.get('global.register.registerErrorTitle');
        const msg = this.i18nService.get('global.register.registerErrorMessage');
        this.registerService.showAlert(title, msg);
      });
  }

  validateFieldsRequired() {
    this.resetErrors();

    /*  if (!this.validatePrimaryPhoneNumber(true)) {
       this.phoneError = true;
       return false;
     }
  */
    if (this.selectedCompany.id === 0 && this.registerForm.controls.emailGroup.value.companySelected.toString() == '') {
      this.companySelectedError = true;
      return false;
    }

    return true;
  }

  /*  goForward() {
     this.stepper.next();
   } */

  /*  goBack() {
     this.lastStep = false;
     this.stepper.previous();
   } */

  /*  backButton() {
     this.uniqueEmailError = false;
     this.isCompleted = false;
     this.stepper.previous();
   } */

  /*  checkFormErrors(formGroup: any) {
     let password = formGroup.controls.emailGroup.controls['password'].value;
     let confirmPass = formGroup.controls.emailGroup.controls['confirmPass'].value;
     if (password.length < 6) {
       return password.length < 6 ? { passwordLength: true } : null;
     } else if (password !== confirmPass) {
       return password !== confirmPass ? { passwordMatch: true } : null;
     }
   } */

  trimValue(formControl: any) {
    this.registerForm.get('emailGroup.email').setValue(formControl.trim());
  }

  async presentLoadingWithOptions() {
    const loading = await this.loadingController.create({
      spinner: 'lines',
      translucent: true,
      showBackdrop: false,
      cssClass: 'spin-loading'
    });
    return await loading.present();
  }

  createForm() {
    this.registerForm = this.fb.group({
      emailGroup: this.fb.group({
        email: ['', Validators.compose([Validators.required, Validators.email])],
        password: ['', Validators.compose([Validators.required])],
        confirmPass: ['', Validators.compose([Validators.required])],
        firstName: ['', Validators.compose([Validators.required])],
        lastName: ['', Validators.compose([Validators.required])],
        dropdown: [null, Validators.compose([Validators.required])],
        primaryPhone: ['', Validators.compose([Validators.required])],
        companySelected: ['', Validators.compose([])]
      })
    });

    /*  this.registerForm = this.fb.group(
       {
         detailGroup: this.fb.group({
           email: ['', Validators.compose([Validators.required, Validators.email])],
           password: ['', Validators.compose([Validators.required])],
           confirmPass: ['', Validators.compose([Validators.required])],
           firstName: ['', Validators.compose([Validators.required])],
           lastName: ['', Validators.compose([Validators.required])] */
    /*  companySelected: ['', Validators.compose([])],
     dropdown: ['', Validators.compose([Validators.required])],
     primaryPhone: ['', Validators.compose([Validators.required])] */
    /*    })
     },
     { validator: this.checkFormErrors }
   ); */
  }

  public validatePrimaryPhoneNumber(showAlerts: boolean) {
    const primaryPhone = this.registerForm.value.emailGroup.primaryPhone;

    if (primaryPhone == null) {
      /* if (showAlerts) {
        let bodyMessage: string = 'global.user.profileForm.invalidNumber';
        this.openInfoModal('', bodyMessage);
      } */

      return false;
    }

    if (primaryPhone.errors || primaryPhone.internationalNumber.length === 0) {
      /*  const headerLabel: string = '';
       let bodyMessage: string = 'global.user.profileForm.invalidNumber'; */
      /*  if (
         (primaryPhone.errors && primaryPhone.errors.invalidNumberForRegion) ||
         primaryPhone.internationalNumber.length === 0
       ) {
         bodyMessage = 'global.user.profileForm.invalidNumberForRegion';
       }
       if (showAlerts) {
         this.openInfoModal(headerLabel, bodyMessage);
       } */
      return false;
    }
    return true;
  }

  private async openInfoModal(headerTitle: string, bodyMessage: string, data?: any, extraData?: any) {
    const modalProps: any = {
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: 'generic-info-modal',
      component: InfoModalComponent,
      componentProps: {
        headerTitle: headerTitle,
        bodyMessage: bodyMessage,
        data: data,
        extraData: extraData
      }
    };
    const infoModal = await this.commonService.createModal(modalProps);
    infoModal.onWillDismiss().then((result: any) => {});
    return infoModal.present();
  }

  private sanitizePhoneNumbers() {
    const formPrimaryPhoneNumber = this.registerForm.value.emailGroup.primaryPhone;

    let userPhones: any = {};
    if (formPrimaryPhoneNumber) {
      let obj = {
        phoneType: 'PHONE_NUMBER',
        phoneNumber:
          formPrimaryPhoneNumber.internationalNumber && formPrimaryPhoneNumber.length > 0
            ? formPrimaryPhoneNumber.internationalNumber
            : formPrimaryPhoneNumber.number,
        isoCode: formPrimaryPhoneNumber.countryCode,
        nationalNumber: '',
        countryCode: ''
      };
      obj.nationalNumber = obj.phoneNumber;
      obj.countryCode = obj.isoCode;
      userPhones = { ...obj };
    }

    return userPhones;
  }

  public getMediumImg() {
    this.image = null;
    this.imageId = null;
    return this.imageService.mediumThumbnail(this.logoId);
  }

  public async openEditLogoModal() {
    let imageLogos;

    let userlogo = [];
    userlogo.push(this.logoId);
    const imageLogo: any = this.userAccountService.getUploadedImageId(userlogo);
    imageLogos = this.userHomeService.setProfileImageProp(imageLogo);

    const componentProps: any = {
      commonService: this.commonService,
      openInfoModal: this.openInfoModal,
      userHomeService: this.userHomeService,
      profileImage: imageLogos,
      editLogo: true
    };
    const cssClass: string = 'edit-image-modal';
    const modalProps: any = this.userHomeService.getModalProp(EditImageModalComponent, componentProps, cssClass);
    const editImageModal = await this.commonService.createModal(modalProps);
    return await editImageModal.present();
  }

  dismissAsDialog(error?: boolean) {
    this.registerDialog.close(error);
  }
}
