import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonGenericService } from '@app/core/common.service';
import * as Fuse from 'fuse.js';
import { PurchaseCreditsService, I18nService, LanguageService } from '@app/core';
import { ListingService, EventCheckerService } from '@app/core/listings';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
import { Marker, BuildingLocation } from '@app/shared/maps/map/map.component';

@Component({
  selector: 'app-create-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class CreateDetailsComponent implements OnInit {
  @Input('form') detailsForm: FormGroup;
  @Input('typeForm') listingTypeForm: FormGroup;
  @Input('addressForm') addressForm: FormGroup;
  @Input('listingFeaturesForm') listingFeaturesForm: FormGroup;
  @Input('stepper') stepper: MatStepper;
  @Input('imgList') imgList: any;
  @Input('listing') listing: any;
  @Input('industrialFilters') industrialFilters: any;
  @Input('officeFilters') officeFilters: any;
  @Input('actualBuilding') actualBuilding?: any;
  buildingNames: any;
  buildings: any;
  measurementStandards: any;
  selectedTitleLanguage: any = [];
  selectedDescLanguage: any = [];
  fuse: Fuse<any, any>;
  fuzzyName: any;
  searchTerm: any;
  industrialBuildings: any;
  officeBuildings: any;
  languages: any = this.commonService.getLanguageOption();
  mapOptions: any = {
    singleMarker: true,
    ignoreZoom: false,
    zoom: 15
  };
  marker: any;
  nameFieldOptions: any;
  initialBuilding: any;
  changedBuilding: boolean;
  private bluePin: string = `\/assets/maps/pin-blue.png`;
  propertyNameFieldOptions: any;
  propertyName: any;

  constructor(
    private registerService: RegisterService,
    private _ts: TranslateService,
    private commonService: CommonGenericService,
    private purchaseService: PurchaseCreditsService,
    private listingService: ListingService,
    private _messageService: EventCheckerService,
    private i18nService: I18nService,
    private langService: LanguageService
  ) {}

  ngOnInit(): void {
    this.initValues();
    this.multiLangInit();
    this.setValidators();
    this.listing ? this.setProperty(this.detailsForm.value.actualProperty, 0) : null;
    this.initialBuilding = this.listing && this.listing.building ? { ...this.listing.building } : null;
    this.detectBuilding();
  }

  detectBuilding() {
    this._messageService.typeBuildings$.subscribe((buildings: any) => {
      if (buildings === 'changed') {
        this.checkBuildingType();
      }
    });
  }

  async fuzzySearch() {
    const options: Fuse.FuseOptions<any> = {
      keys: ['name'],
      shouldSort: true,
      caseSensitive: false,
      threshold: 0.5,
      findAllMatches: true
    };
    this.fuse = new Fuse(this.buildings, options);
  }

  async getSpaceTypes(id: any) {
    return this.registerService.getBuildingSubTypes(id).toPromise();
  }

  async getSpaceTranslations(subTypes: any) {
    Object.keys(subTypes).forEach(async (key, idx) => {
      subTypes[key].name = this.getI18nValue(subTypes[key].name);
      subTypes[key].selected = false;
    });
    return subTypes;
  }

  setValidators() {
    if (this.detailsForm.controls.listed.value) {
      this.clearInput();
      this.fuzzySearch();
      this.listedValidators();
    } else {
      this.notListedValidators();
    }
  }

  async checkBuildingType() {
    // if (this.detailsForm.controls.propertyName.value !== '') {
    //   this.clearInput();
    // }
    if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
      if (!this.industrialBuildings) {
        this.industrialBuildings = await this.purchaseService.getBuildingNamesByPropertyType(1001);
        this.buildingNames = this.industrialBuildings;
        this.buildings = this.industrialBuildings.values;
      } else {
        this.buildingNames = this.industrialBuildings;
        this.buildings = this.industrialBuildings.values;
      }
    } else {
      if (!this.officeBuildings) {
        this.officeBuildings = await this.purchaseService.getBuildingNamesByPropertyType(2001);
        this.buildingNames = this.officeBuildings;
        this.buildings = this.officeBuildings.values;
      } else {
        this.buildingNames = this.officeBuildings;
        this.buildings = this.officeBuildings.values;
      }
    }
    const options: Fuse.FuseOptions<any> = {
      keys: ['name'],
      shouldSort: true,
      caseSensitive: false,
      threshold: 0.5,
      findAllMatches: true
    };
    this.fuse = new Fuse(this.buildings, options);
  }

  listedValidators() {
    this.detailsForm.controls['propertySet'].setValue(false);
    this.detailsForm.controls['propertyName'].setValue(null);
    this.detailsForm.controls['propertyName'].clearValidators();
    this.detailsForm.controls['property'].setValidators([Validators.required]);
    this.detailsForm.controls['propertyName'].updateValueAndValidity();
    this.detailsForm.controls['property'].updateValueAndValidity();
  }

  notListedValidators() {
    this.detailsForm.controls['property'].setValue(null);
    this.detailsForm.controls['propertyName'].setValue(null);
    this.detailsForm.controls['property'].clearValidators();
    this.detailsForm.controls['propertyName'].setValidators([Validators.required]);
    this.detailsForm.controls['property'].updateValueAndValidity();
    this.detailsForm.controls['propertyName'].updateValueAndValidity();
  }

  clearInput() {
    this.detailsForm.controls['propertySet'].setValue(false);
    this.detailsForm.controls.actualProperty.setValue(null);
    this.detailsForm.controls.property.setValue('');
    this.detailsForm.controls.property.enable();
  }

  suggest_property(term: any) {
    if (term) {
      var q = term.toLowerCase().trim();
      return this.fuse
        .search(q)
        .slice(0, 10)
        .map((element: any) => {
          return element;
        });
    }
  }

  async changeFuzzySearch() {
    if (this.detailsForm.controls.property.enabled) {
      this.searchTerm = this.detailsForm.value.property;
      this.buildingNames = this.suggest_property(this.detailsForm.value.property);
    }
  }

  async initValues() {
    this.industrialBuildings = await this.purchaseService.getBuildingNamesByPropertyType(1001);
    this.officeBuildings = await this.purchaseService.getBuildingNamesByPropertyType(2001);
    if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
      this.buildingNames = this.industrialBuildings;
      this.buildings = this.industrialBuildings.values;
    } else {
      this.buildingNames = this.officeBuildings;
      this.buildings = this.officeBuildings.values;
    }
    this.fuzzySearch();
  }

  async setProperty(building: any, value: number) {
    this.detailsForm.controls.property.setValue(building.name);
    this.detailsForm.controls.property.disable();

    if (value == 0 && this.listing.propertyName) {
      this.propertyName = JSON.parse(this.listing.propertyName);
    } else {
      this.propertyName = `{"en":"${building.name}","pt-br":"${building.name}"}`;
    }
    this.buildingNames = null;
    this.actualBuilding = await this.listingService.getBuilding(building.id);
    this.marker = { lat: this.actualBuilding.latitude, lng: this.actualBuilding.longitude };
    this.detailsForm.controls.actualProperty.setValue(building);
    this.detailsForm.controls['propertySet'].setValue(true);
    const buildingTypeId = this.actualBuilding.buildingType.id;
    if (building && this.listing && this.listing.building && this.listing.building.id) {
      this.changedBuilding = this.listing.building.id != building.id;
      if (this.changedBuilding) {
        if (this.initialBuilding) {
          if (this.initialBuilding.id == building.id) {
            this.setExistingFeatures(this.listing, buildingTypeId);
          } else {
            this.openInfoModal('', 'global.list-your-property.buildingHasChanged');
            this.setExistingFeatures(this.actualBuilding, buildingTypeId);
          }
        } else {
          this.openInfoModal('', 'global.list-your-property.buildingHasChanged');
          this.setExistingFeatures(this.actualBuilding, buildingTypeId);
        }
      }
    } else if (!this.listing) {
      this.setExistingFeatures(this.actualBuilding, buildingTypeId);
    }
  }

  async setExistingFeatures(pListing: any, buildingTypeId: number) {
    if (buildingTypeId === 1001) {
      this.industrialFilters = await this.registerService.getAmenities('industrial');
      if (Object.keys(pListing).length > 0) {
        this.industrialFilters = await this.registerService.selectExistingFeatures(
          pListing,
          null,
          this.industrialFilters
        );
      }
      this.listingFeaturesForm.controls['tags'].setValue(this.industrialFilters);
      let res = await this.updateListingFeatures('industrial', pListing);
      this._messageService.updateIndustrialFilters$.next(this.industrialFilters);
    } else {
      this.officeFilters = await this.registerService.getAmenities('office');
      if (Object.keys(pListing).length > 0) {
        this.officeFilters = await this.registerService.selectExistingFeatures(pListing, this.officeFilters, null);
      }
      this.listingFeaturesForm.controls['tags'].setValue(this.officeFilters);
      let res = await this.updateListingFeatures('office', pListing);
      this._messageService.updateOfficeFilters$.next(this.officeFilters);
    }
    this.listingFeaturesForm.updateValueAndValidity();
  }

  async updateListingFeatures(type: any, building: any) {
    //Office
    this.listingFeaturesForm.controls['parkingSpaces'].setValue(undefined);
    this.listingFeaturesForm.controls['ceilingHeight'].setValue(undefined);
    //Industrial
    this.listingFeaturesForm.controls['baySize'].setValue(undefined);
    this.listingFeaturesForm.controls['dockRatio'].setValue(undefined);
    this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(undefined);
    this.listingFeaturesForm.controls['loadCapacity'].setValue(undefined);
    this.listingFeaturesForm.controls['ceilingHeight'].setValue(undefined);
    this.listingFeaturesForm.controls['carParkingSpaces'].setValue(undefined);

    Object.keys(building).forEach(key => {
      if (type === 'industrial') {
        if (key === 'baySize') {
          this.listingFeaturesForm.controls['baySize'].setValue(
            this.langService.locale === 'en-US'
              ? building[key].toFixed(2)
              : building[key].toFixed(2).replace(/\./g, ',')
          );
        }
        if (key === 'dockRatio') {
          this.listingFeaturesForm.controls['dockRatio'].setValue(
            this.langService.locale === 'en-US'
              ? building[key].toFixed(2)
              : building[key].toFixed(2).replace(/\./g, ',')
          );
        }
        if (key === 'truckParkingSpaces' && typeof building[key] !== 'boolean') {
          this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(building[key].toFixed(2));
        }
        if (key === 'loadCapacity') {
          this.listingFeaturesForm.controls['loadCapacity'].setValue(building[key].toFixed(2));
        }
        if (key === 'ceilingHeight') {
          this.listingFeaturesForm.controls['ceilingHeight'].setValue(
            this.langService.locale === 'en-US'
              ? building[key].toFixed(2)
              : building[key].toFixed(2).replace(/\./g, ',')
          );
        }
        if (key === 'carParkingSpaces' && typeof building[key] !== 'boolean') {
          this.listingFeaturesForm.controls['carParkingSpaces'].setValue(building[key].toFixed(2));
        }
      } else if (type === 'office') {
        this.listingFeaturesForm.controls['parkingSpaces'].setValue(undefined);
        this.listingFeaturesForm.controls['ceilingHeight'].setValue(undefined);

        if (key === 'parkingSpaces' && typeof building[key] !== 'boolean') {
          this.listingFeaturesForm.controls['parkingSpaces'].setValue(building[key].toFixed(2));
        }
        if (key === 'ceilingHeight') {
          this.listingFeaturesForm.controls['ceilingHeight'].setValue(
            this.langService.locale === 'en-US'
              ? building[key].toFixed(2)
              : building[key].toFixed(2).replace(/\./g, ',')
          );
        }
      }
    });
  }

  async setPropertyFeatures() {
    if (this.actualBuilding.buildingType.id === 1001) {
      this.industrialFilters = await this.registerService.selectExistingFeaturesFromBuilding(
        this.actualBuilding,
        null,
        this.industrialFilters
      );
      //let res = await this.setFormFeatures('industrial', this.actualBuilding);
    } else {
      this.officeFilters = await this.registerService.selectExistingFeaturesFromBuilding(
        this.actualBuilding,
        this.officeFilters,
        null
      );
      //let res = await this.setFormFeatures('office', this.actualBuilding);
    }
  }

  async setFormFeatures(type: any, building: any) {
    Object.keys(building).forEach(key => {
      if (type === 'industrial') {
        if (key === 'baySize') {
          this.listingFeaturesForm.controls['baySize'].setValue(building[key].toFixed(2));
        }
        if (key === 'dockRatio') {
          this.listingFeaturesForm.controls['dockRatio'].setValue(building[key].toFixed(2));
        }
        if (key === 'truckParkingSpaces' && typeof building[key] !== 'boolean') {
          this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(building[key]);
        }
        if (key === 'loadCapacity') {
          this.listingFeaturesForm.controls['loadCapacity'].setValue(building[key]);
        }
        if (key === 'ceilingHeight') {
          this.listingFeaturesForm.controls['ceilingHeight'].setValue(building[key].toFixed(2));
        }
        if (key === 'carParkingSpaces' && typeof building[key] !== 'boolean') {
          this.listingFeaturesForm.controls['carParkingSpaces'].setValue(building[key]);
        }
      } else if (type === 'office') {
        if (key === 'parkingSpaces' && typeof building[key] !== 'boolean') {
          this.listingFeaturesForm.controls['parkingSpaces'].setValue(building[key]);
        }
        /*if (key === 'restrooms') {
          this.listingFeaturesForm.controls['restrooms'].setValue(building[key]);
        }*/
      }
    });
  }

  async getBuildingTypes() {
    this.industrialBuildings = await this.purchaseService.getBuildingNamesByPropertyType(1001);
    this.officeBuildings = await this.purchaseService.getBuildingNamesByPropertyType(2001);
    this.buildingNames = this.industrialBuildings;
    this.buildings = this.buildingNames.values;
    const options: Fuse.FuseOptions<any> = {
      keys: ['name'],
      shouldSort: true,
      caseSensitive: false,
      threshold: 0.5,
      findAllMatches: true
    };
    this.fuse = new Fuse(this.buildings, options);
  }

  getI18nValue(textValue: string) {
    try {
      if (textValue && JSON.parse(textValue)) {
        return this.i18nService.getTranslation(textValue);
      } else {
        return '';
      }
    } catch (e) {
      console.error('failed to parse locale string -> listings search');
    }
  }

  nameChange(value: any) {
    if (value.name.length > 0) {
      let mergedObject: any = {};
      let nullValues: boolean = false;
      Object.keys(value.name).forEach(async (key, idx) => {
        let obj: any = value.name[key];
        if (obj['en'] === '' || obj['pt-br'] === '') {
          nullValues = true;
        } else {
          mergedObject = Object.assign(mergedObject, obj);
        }
      });
      nullValues
        ? this.detailsForm.controls['property'].setValue(null)
        : this.detailsForm.controls['property'].setValue(JSON.stringify(mergedObject));
      nullValues
        ? this.detailsForm.controls['propertyName'].setValue(null)
        : this.detailsForm.controls['propertyName'].setValue(JSON.stringify(mergedObject));
    } else {
      this.addressForm.controls['description'].setValue(null);
    }
  }

  propertyNameChange(value: any) {
    if (value.property.length > 0) {
      let mergedObject: any = {};
      let nullValues: boolean = false;
      Object.keys(value.property).forEach(async (key, idx) => {
        let obj: any = value.property[key];
        if (obj['en'] === '' || obj['pt-br'] === '') {
          nullValues = true;
        } else {
          mergedObject = Object.assign(mergedObject, obj);
        }
      });
      nullValues
        ? this.detailsForm.controls['propertyName'].setValue(null)
        : this.detailsForm.controls['propertyName'].setValue(JSON.stringify(mergedObject));
    } else {
      this.addressForm.controls['description'].setValue(null);
    }
  }

  multiLangInit() {
    this.nameFieldOptions = {
      columnLabel: 'global.list-your-property.propertyName',
      element: { input: true },
      formArrayName: 'name',
      required: true
    };

    this.propertyNameFieldOptions = {
      columnLabel: 'global.list-your-property.propertyNameDisplay',
      element: { input: true },
      formArrayName: 'property',
      required: true
    };
  }

  private async openInfoModal(headerTitle: string, bodyMessage: string, data?: any, extraData?: any) {
    const modalProps: any = {
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: 'generic-info-modal',
      component: InfoModalComponent,
      componentProps: {
        headerTitle: headerTitle,
        bodyMessage: bodyMessage,
        data: data,
        extraData: extraData
      }
    };
    const infoModal = await this.commonService.createModal(modalProps);
    infoModal.onWillDismiss().then((result: any) => {});
    return infoModal.present();
  }

  public setBuildingGeoLocation(buildingLocation: BuildingLocation) {
    if (
      !this.addressForm.controls['buildingLocation'] ||
      this.addressForm.controls['buildingLocation'].value != buildingLocation
    ) {
      this._messageService.updateBuildingLocation$.next(buildingLocation);
    }
  }
}
