import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonGenericService } from '@app/core/common.service';
import * as Fuse from 'fuse.js';
import { PurchaseCreditsService, I18nService, LanguageService } from '@app/core';
import { ListingService, EventCheckerService } from '@app/core/listings';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
import { BuildingLocation } from '@app/shared/maps/map/map.component';
export class CreateDetailsComponent {
    constructor(registerService, _ts, commonService, purchaseService, listingService, _messageService, i18nService, langService) {
        this.registerService = registerService;
        this._ts = _ts;
        this.commonService = commonService;
        this.purchaseService = purchaseService;
        this.listingService = listingService;
        this._messageService = _messageService;
        this.i18nService = i18nService;
        this.langService = langService;
        this.selectedTitleLanguage = [];
        this.selectedDescLanguage = [];
        this.languages = this.commonService.getLanguageOption();
        this.mapOptions = {
            singleMarker: true,
            ignoreZoom: false,
            zoom: 15
        };
        this.bluePin = `\/assets/maps/pin-blue.png`;
    }
    ngOnInit() {
        this.initValues();
        this.multiLangInit();
        this.setValidators();
        this.listing ? this.setProperty(this.detailsForm.value.actualProperty, 0) : null;
        this.initialBuilding = this.listing && this.listing.building ? Object.assign({}, this.listing.building) : null;
        this.detectBuilding();
    }
    detectBuilding() {
        this._messageService.typeBuildings$.subscribe((buildings) => {
            if (buildings === 'changed') {
                this.checkBuildingType();
            }
        });
    }
    fuzzySearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const options = {
                keys: ['name'],
                shouldSort: true,
                caseSensitive: false,
                threshold: 0.5,
                findAllMatches: true
            };
            this.fuse = new Fuse(this.buildings, options);
        });
    }
    getSpaceTypes(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getBuildingSubTypes(id).toPromise();
        });
    }
    getSpaceTranslations(subTypes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Object.keys(subTypes).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                subTypes[key].name = this.getI18nValue(subTypes[key].name);
                subTypes[key].selected = false;
            }));
            return subTypes;
        });
    }
    setValidators() {
        if (this.detailsForm.controls.listed.value) {
            this.clearInput();
            this.fuzzySearch();
            this.listedValidators();
        }
        else {
            this.notListedValidators();
        }
    }
    checkBuildingType() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // if (this.detailsForm.controls.propertyName.value !== '') {
            //   this.clearInput();
            // }
            if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
                if (!this.industrialBuildings) {
                    this.industrialBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(1001);
                    this.buildingNames = this.industrialBuildings;
                    this.buildings = this.industrialBuildings.values;
                }
                else {
                    this.buildingNames = this.industrialBuildings;
                    this.buildings = this.industrialBuildings.values;
                }
            }
            else {
                if (!this.officeBuildings) {
                    this.officeBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(2001);
                    this.buildingNames = this.officeBuildings;
                    this.buildings = this.officeBuildings.values;
                }
                else {
                    this.buildingNames = this.officeBuildings;
                    this.buildings = this.officeBuildings.values;
                }
            }
            const options = {
                keys: ['name'],
                shouldSort: true,
                caseSensitive: false,
                threshold: 0.5,
                findAllMatches: true
            };
            this.fuse = new Fuse(this.buildings, options);
        });
    }
    listedValidators() {
        this.detailsForm.controls['propertySet'].setValue(false);
        this.detailsForm.controls['propertyName'].setValue(null);
        this.detailsForm.controls['propertyName'].clearValidators();
        this.detailsForm.controls['property'].setValidators([Validators.required]);
        this.detailsForm.controls['propertyName'].updateValueAndValidity();
        this.detailsForm.controls['property'].updateValueAndValidity();
    }
    notListedValidators() {
        this.detailsForm.controls['property'].setValue(null);
        this.detailsForm.controls['propertyName'].setValue(null);
        this.detailsForm.controls['property'].clearValidators();
        this.detailsForm.controls['propertyName'].setValidators([Validators.required]);
        this.detailsForm.controls['property'].updateValueAndValidity();
        this.detailsForm.controls['propertyName'].updateValueAndValidity();
    }
    clearInput() {
        this.detailsForm.controls['propertySet'].setValue(false);
        this.detailsForm.controls.actualProperty.setValue(null);
        this.detailsForm.controls.property.setValue('');
        this.detailsForm.controls.property.enable();
    }
    suggest_property(term) {
        if (term) {
            var q = term.toLowerCase().trim();
            return this.fuse
                .search(q)
                .slice(0, 10)
                .map((element) => {
                return element;
            });
        }
    }
    changeFuzzySearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.detailsForm.controls.property.enabled) {
                this.searchTerm = this.detailsForm.value.property;
                this.buildingNames = this.suggest_property(this.detailsForm.value.property);
            }
        });
    }
    initValues() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.industrialBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(1001);
            this.officeBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(2001);
            if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
                this.buildingNames = this.industrialBuildings;
                this.buildings = this.industrialBuildings.values;
            }
            else {
                this.buildingNames = this.officeBuildings;
                this.buildings = this.officeBuildings.values;
            }
            this.fuzzySearch();
        });
    }
    setProperty(building, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.detailsForm.controls.property.setValue(building.name);
            this.detailsForm.controls.property.disable();
            if (value == 0 && this.listing.propertyName) {
                this.propertyName = JSON.parse(this.listing.propertyName);
            }
            else {
                this.propertyName = `{"en":"${building.name}","pt-br":"${building.name}"}`;
            }
            this.buildingNames = null;
            this.actualBuilding = yield this.listingService.getBuilding(building.id);
            this.marker = { lat: this.actualBuilding.latitude, lng: this.actualBuilding.longitude };
            this.detailsForm.controls.actualProperty.setValue(building);
            this.detailsForm.controls['propertySet'].setValue(true);
            const buildingTypeId = this.actualBuilding.buildingType.id;
            if (building && this.listing && this.listing.building && this.listing.building.id) {
                this.changedBuilding = this.listing.building.id != building.id;
                if (this.changedBuilding) {
                    if (this.initialBuilding) {
                        if (this.initialBuilding.id == building.id) {
                            this.setExistingFeatures(this.listing, buildingTypeId);
                        }
                        else {
                            this.openInfoModal('', 'global.list-your-property.buildingHasChanged');
                            this.setExistingFeatures(this.actualBuilding, buildingTypeId);
                        }
                    }
                    else {
                        this.openInfoModal('', 'global.list-your-property.buildingHasChanged');
                        this.setExistingFeatures(this.actualBuilding, buildingTypeId);
                    }
                }
            }
            else if (!this.listing) {
                this.setExistingFeatures(this.actualBuilding, buildingTypeId);
            }
        });
    }
    setExistingFeatures(pListing, buildingTypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (buildingTypeId === 1001) {
                this.industrialFilters = yield this.registerService.getAmenities('industrial');
                if (Object.keys(pListing).length > 0) {
                    this.industrialFilters = yield this.registerService.selectExistingFeatures(pListing, null, this.industrialFilters);
                }
                this.listingFeaturesForm.controls['tags'].setValue(this.industrialFilters);
                let res = yield this.updateListingFeatures('industrial', pListing);
                this._messageService.updateIndustrialFilters$.next(this.industrialFilters);
            }
            else {
                this.officeFilters = yield this.registerService.getAmenities('office');
                if (Object.keys(pListing).length > 0) {
                    this.officeFilters = yield this.registerService.selectExistingFeatures(pListing, this.officeFilters, null);
                }
                this.listingFeaturesForm.controls['tags'].setValue(this.officeFilters);
                let res = yield this.updateListingFeatures('office', pListing);
                this._messageService.updateOfficeFilters$.next(this.officeFilters);
            }
            this.listingFeaturesForm.updateValueAndValidity();
        });
    }
    updateListingFeatures(type, building) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //Office
            this.listingFeaturesForm.controls['parkingSpaces'].setValue(undefined);
            this.listingFeaturesForm.controls['ceilingHeight'].setValue(undefined);
            //Industrial
            this.listingFeaturesForm.controls['baySize'].setValue(undefined);
            this.listingFeaturesForm.controls['dockRatio'].setValue(undefined);
            this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(undefined);
            this.listingFeaturesForm.controls['loadCapacity'].setValue(undefined);
            this.listingFeaturesForm.controls['ceilingHeight'].setValue(undefined);
            this.listingFeaturesForm.controls['carParkingSpaces'].setValue(undefined);
            Object.keys(building).forEach(key => {
                if (type === 'industrial') {
                    if (key === 'baySize') {
                        this.listingFeaturesForm.controls['baySize'].setValue(this.langService.locale === 'en-US'
                            ? building[key].toFixed(2)
                            : building[key].toFixed(2).replace(/\./g, ','));
                    }
                    if (key === 'dockRatio') {
                        this.listingFeaturesForm.controls['dockRatio'].setValue(this.langService.locale === 'en-US'
                            ? building[key].toFixed(2)
                            : building[key].toFixed(2).replace(/\./g, ','));
                    }
                    if (key === 'truckParkingSpaces' && typeof building[key] !== 'boolean') {
                        this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(building[key].toFixed(2));
                    }
                    if (key === 'loadCapacity') {
                        this.listingFeaturesForm.controls['loadCapacity'].setValue(building[key].toFixed(2));
                    }
                    if (key === 'ceilingHeight') {
                        this.listingFeaturesForm.controls['ceilingHeight'].setValue(this.langService.locale === 'en-US'
                            ? building[key].toFixed(2)
                            : building[key].toFixed(2).replace(/\./g, ','));
                    }
                    if (key === 'carParkingSpaces' && typeof building[key] !== 'boolean') {
                        this.listingFeaturesForm.controls['carParkingSpaces'].setValue(building[key].toFixed(2));
                    }
                }
                else if (type === 'office') {
                    this.listingFeaturesForm.controls['parkingSpaces'].setValue(undefined);
                    this.listingFeaturesForm.controls['ceilingHeight'].setValue(undefined);
                    if (key === 'parkingSpaces' && typeof building[key] !== 'boolean') {
                        this.listingFeaturesForm.controls['parkingSpaces'].setValue(building[key].toFixed(2));
                    }
                    if (key === 'ceilingHeight') {
                        this.listingFeaturesForm.controls['ceilingHeight'].setValue(this.langService.locale === 'en-US'
                            ? building[key].toFixed(2)
                            : building[key].toFixed(2).replace(/\./g, ','));
                    }
                }
            });
        });
    }
    setPropertyFeatures() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.actualBuilding.buildingType.id === 1001) {
                this.industrialFilters = yield this.registerService.selectExistingFeaturesFromBuilding(this.actualBuilding, null, this.industrialFilters);
                //let res = await this.setFormFeatures('industrial', this.actualBuilding);
            }
            else {
                this.officeFilters = yield this.registerService.selectExistingFeaturesFromBuilding(this.actualBuilding, this.officeFilters, null);
                //let res = await this.setFormFeatures('office', this.actualBuilding);
            }
        });
    }
    setFormFeatures(type, building) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Object.keys(building).forEach(key => {
                if (type === 'industrial') {
                    if (key === 'baySize') {
                        this.listingFeaturesForm.controls['baySize'].setValue(building[key].toFixed(2));
                    }
                    if (key === 'dockRatio') {
                        this.listingFeaturesForm.controls['dockRatio'].setValue(building[key].toFixed(2));
                    }
                    if (key === 'truckParkingSpaces' && typeof building[key] !== 'boolean') {
                        this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(building[key]);
                    }
                    if (key === 'loadCapacity') {
                        this.listingFeaturesForm.controls['loadCapacity'].setValue(building[key]);
                    }
                    if (key === 'ceilingHeight') {
                        this.listingFeaturesForm.controls['ceilingHeight'].setValue(building[key].toFixed(2));
                    }
                    if (key === 'carParkingSpaces' && typeof building[key] !== 'boolean') {
                        this.listingFeaturesForm.controls['carParkingSpaces'].setValue(building[key]);
                    }
                }
                else if (type === 'office') {
                    if (key === 'parkingSpaces' && typeof building[key] !== 'boolean') {
                        this.listingFeaturesForm.controls['parkingSpaces'].setValue(building[key]);
                    }
                    /*if (key === 'restrooms') {
                      this.listingFeaturesForm.controls['restrooms'].setValue(building[key]);
                    }*/
                }
            });
        });
    }
    getBuildingTypes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.industrialBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(1001);
            this.officeBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(2001);
            this.buildingNames = this.industrialBuildings;
            this.buildings = this.buildingNames.values;
            const options = {
                keys: ['name'],
                shouldSort: true,
                caseSensitive: false,
                threshold: 0.5,
                findAllMatches: true
            };
            this.fuse = new Fuse(this.buildings, options);
        });
    }
    getI18nValue(textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18nService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    }
    nameChange(value) {
        if (value.name.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.name).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.name[key];
                if (obj['en'] === '' || obj['pt-br'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues
                ? this.detailsForm.controls['property'].setValue(null)
                : this.detailsForm.controls['property'].setValue(JSON.stringify(mergedObject));
            nullValues
                ? this.detailsForm.controls['propertyName'].setValue(null)
                : this.detailsForm.controls['propertyName'].setValue(JSON.stringify(mergedObject));
        }
        else {
            this.addressForm.controls['description'].setValue(null);
        }
    }
    propertyNameChange(value) {
        if (value.property.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.property).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.property[key];
                if (obj['en'] === '' || obj['pt-br'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues
                ? this.detailsForm.controls['propertyName'].setValue(null)
                : this.detailsForm.controls['propertyName'].setValue(JSON.stringify(mergedObject));
        }
        else {
            this.addressForm.controls['description'].setValue(null);
        }
    }
    multiLangInit() {
        this.nameFieldOptions = {
            columnLabel: 'global.list-your-property.propertyName',
            element: { input: true },
            formArrayName: 'name',
            required: true
        };
        this.propertyNameFieldOptions = {
            columnLabel: 'global.list-your-property.propertyNameDisplay',
            element: { input: true },
            formArrayName: 'property',
            required: true
        };
    }
    openInfoModal(headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modalProps = {
                backdropDismiss: false,
                showBackdrop: true,
                cssClass: 'generic-info-modal',
                component: InfoModalComponent,
                componentProps: {
                    headerTitle: headerTitle,
                    bodyMessage: bodyMessage,
                    data: data,
                    extraData: extraData
                }
            };
            const infoModal = yield this.commonService.createModal(modalProps);
            infoModal.onWillDismiss().then((result) => { });
            return infoModal.present();
        });
    }
    setBuildingGeoLocation(buildingLocation) {
        if (!this.addressForm.controls['buildingLocation'] ||
            this.addressForm.controls['buildingLocation'].value != buildingLocation) {
            this._messageService.updateBuildingLocation$.next(buildingLocation);
        }
    }
}
