import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { RegisterService } from '@app/core/register.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { RemoveCreditsPopoverComponent } from '@app/search-navigation/components/remove-credits-popover';
import { PopoverController } from '@ionic/angular';
export class ReviewMyPaymentComponent {
    constructor(router, popoverCtrl, registerService, purchaseCreditsService, userActivityService) {
        this.router = router;
        this.popoverCtrl = popoverCtrl;
        this.registerService = registerService;
        this.purchaseCreditsService = purchaseCreditsService;
        this.userActivityService = userActivityService;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
    }
    ngOnInit() {
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
        this.nonAdminInit();
        this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_MYPAYMENTS_PAGE);
    }
    nameChange(event) {
        if (event.value && event.value.id) {
            this.offeredByUserId = event.value;
            //this.getPayments();
            this.getLisitngPaymentActivity();
        }
    }
    checkAdmin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.user = JSON.parse(localStorage.getItem('user'));
            if (this.user.roles.includes('ROLE_ADMIN')) {
                return true;
            }
            else {
                return false;
            }
        });
    }
    checkSpotAdmin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.user = JSON.parse(localStorage.getItem('user'));
            if (this.user.roles.includes('SPOT_ADMIN')) {
                return true;
            }
            else {
                return false;
            }
        });
    }
    initUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.admin = yield this.checkAdmin();
        });
    }
    nonAdminInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.allCompanies = yield this.getCompanies();
            this.admin = yield this.checkAdmin();
            this.user = JSON.parse(localStorage.getItem('user'));
            this.users = yield this.getUsersByCompany(this.user.company.id);
            let index = this.allCompanies.findIndex((v) => {
                return v.id === this.user.company.id;
            });
            let userIndex = this.users.findIndex((v) => {
                return v.id === this.user.id;
            });
            this.selectCompany = this.allCompanies[index];
            this.offeredByUserId = this.users[userIndex];
            //this.getPayments();
            this.getLisitngPaymentActivity();
        });
    }
    portChange($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.users = yield this.getUsersByCompany($event.value.id);
            this.agentComponent.clear();
            this.offeredByUserId = null;
            this.userCredits = [];
            this.purchases = [];
            this.listingActivities = [];
        });
    }
    getUsersByCompany(companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getUsersByCompany(companyId).toPromise();
        });
    }
    getCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getListingCompanies().toPromise();
        });
    }
    getPayments() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.purchases = yield this.purchaseCreditsService.getUserPayments(this.offeredByUserId.id);
        });
    }
    getLisitngPaymentActivity() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.listingActivities = yield this.purchaseCreditsService.getUserListingPaymentActivity(this.offeredByUserId.id);
        });
    }
    navigateToPurchase() {
        this.router.navigateByUrl('/list-property');
    }
    navigateToMyListing() {
        this.router.navigateByUrl('/user-home/my-listings');
    }
    removeCredits(listingPurchaseId, availableCredits) {
        this.openRemoveCreditsPopover(listingPurchaseId, availableCredits);
    }
    openRemoveCreditsPopover(listingPurchaseId, availableCredits) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: RemoveCreditsPopoverComponent,
                event: null,
                cssClass: 'popover-remove-credits',
                componentProps: { listingPurchaseId: listingPurchaseId, availableCredits: availableCredits },
                backdropDismiss: false
            });
            popover.onDidDismiss().then(popoverData => {
                if (popoverData.data) {
                    this.getLisitngPaymentActivity();
                }
            });
            return yield popover.present();
        });
    }
    navigateToCreate(id, premium) {
        if (this.selectCompany && this.offeredByUserId) {
            this.router.navigateByUrl('/user-home/my-listings/create-property', {
                state: {
                    orderId: id,
                    premium: premium,
                    company: this.selectCompany,
                    offeredByUserId: this.offeredByUserId,
                    date: new Date()
                },
                replaceUrl: true
            });
        }
    }
    checkRole(role) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    }
}
