export class SetReport {
  content: Content;
  includePropertyDetails: includePropertyDetails;
  includeSpotDetails: includeSpotDetails;
  formatting: Formatting;
  spaceIds: number[];
  excludeSpotDashborad: boolean;
  reportName: string;
  lang: string;

  constructor() {
    this.content = new Content();
    this.includePropertyDetails = new includePropertyDetails();
    this.includeSpotDetails = new includeSpotDetails();
    this.formatting = new Formatting();
    this.spaceIds = [];
    this.reportName = '';
  }
}

export class Content {
  tourMap: boolean = true;
  buildingPage: BuildingPage = new BuildingPage();
  spotPage: SpotPage = new SpotPage();
}

export class BuildingPage {
  allSpots: boolean;
  selectedSpotsOnly: boolean = true;
  buildingPage: boolean = true;
}

export class SpotPage {
  spotPage: boolean = true;
  allSpots: boolean;
  selectedSpotsOnly: boolean = true;
  IncludeFloorPlan: boolean;
}

export class includePropertyDetails {
  propertySubtype: boolean = false;
  yearBuilt: boolean = false;
  yearRenovated: boolean = false;
  constructionStatus: boolean = false;
  propertySize: boolean = false;
  numberOfFloors: boolean = false;
  averageFloorSize: boolean = false;
  privateOutdoorSpace: boolean = false;
  sharedOutdoorSpace: boolean = false;
  parkingAvailable: boolean = false;
  parkingCostPerMonth: boolean = false;
  printsAndCopiesPerMonth: boolean = false;
  mailHanding: boolean = false;
  numberOfCarParkingSpaces: boolean = false;
  numberOfTruckParkingSpaces: boolean = false;
  numberOfBuildings: boolean = false;
  landArea: boolean = false;
  sustainabilityCertification: boolean = false;
  twentyFourHourSecurity: boolean = false;
  numberOfParkingSpaces: boolean = false;
}

export class includeSpotDetails {
  numberOfDocks: boolean = false;
  numberOfTruckParkingSpaces: boolean = false;
  loadCapacity: boolean = false;
  numberOfParkingSpaces: boolean = false;
  skyLights: boolean = false;
  cellingHeight: boolean = false;
  includesOffice: boolean = false;
  includesRetail: boolean = false;
  coldStorage: boolean = false;
  fullBuildOut: boolean = false;
  directSublease: boolean = false;
  maxContiguous: boolean = false;
  conferenceTimeAllowance: boolean = false;
  privateOutdoorSpace: boolean = false;
  sharedOutdoorSpace: boolean = false;
  parkingAvailable: boolean = false;
  parkingCostPerMonth: boolean = false;
  printsAndCopiesPerMonth: boolean = false;
  mailHandling: boolean = false;
}

export class Formatting {
  tourBook: boolean = true;
  applyBrandcolors: boolean = true;
  excludeProfilePicture: boolean;
  excludeCompanyLogo: boolean;
  excludeSiilaLogo: boolean;
  excludeCoverPage: boolean;
  excludeExcludeBackPage: boolean;
}
