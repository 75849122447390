import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as CartActions from '@app/store/actions';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-listing-add',
  templateUrl: './list-property-add.component.html',
  styleUrls: ['./list-property-add.component.scss']
})
export class ListPropertyAddComponent implements OnInit {
  isMobile: boolean = false;
  listingOptions: any;
  selection: any;
  canNavigate: any;
  faMapMarkerAlt: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private _location: Location,
    private store: Store<any>
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.getSelection();
  }

  private getPurchaseSelection() {
    this.store.select(CartActions.GetItem).subscribe((store: any) => {
      this.selection = store.cart.purchaseOption ? Object.assign({}, store.cart.purchaseOption) : null;
    });
  }

  getSelection() {
    this.faMapMarkerAlt = faMapMarkerAlt;
    this.getPurchaseSelection();
    if (!this.selection) {
      this.router.navigateByUrl('/list-property');
    }
  }

  navigateToCart(upgrade: boolean) {
    if (upgrade) {
      this.selection.upgrade = true;
      this.selection.listingType = 'featured';
      this.store.dispatch(CartActions.AddItem({ payload: { items: [this.selection] } }));
      this.router.navigateByUrl('/cart');
    } else {
      this.selection.upgrade = false;
      this.selection.listingType = 'promoted';
      this.store.dispatch(CartActions.AddItem({ payload: { items: [this.selection] } }));
      this.router.navigateByUrl('/cart');
    }
  }

  goBack() {
    this._location.back();
  }
}
