import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonGenericService } from '@app/core/common.service';
import { EventCheckerService } from '@app/core/listings';
import { I18nService } from '@app/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListingOfferType } from '../../../../../models/transaction-type.model';
export class ListTypeComponent {
    constructor(registerService, purchaseService, _ts, commonService, _eventChecker, i18NService, breakpointObserver) {
        this.registerService = registerService;
        this.purchaseService = purchaseService;
        this._ts = _ts;
        this.commonService = commonService;
        this._eventChecker = _eventChecker;
        this.i18NService = i18NService;
        this.breakpointObserver = breakpointObserver;
        this.onFilter = new EventEmitter();
        this.stepOne = false;
        this.languages = this.commonService.getLanguageOption();
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    ngOnInit() {
        this.getBuildingT();
        if (!this.listing) {
            this.unsetSaleValidators();
        }
        else {
            this.listing.isForLeaseSale === 'SALE' || this.listing.isForLeaseSale === 'S'
                ? this.unsetLeaseValidators()
                : this.listing.isForLeaseSale === 'LEASE' || this.listing.isForLeaseSale === 'L'
                    ? this.unsetSaleValidators()
                    : '';
            if (this.listing.isForLeaseSale === ListingOfferType.Coworking) {
                this.unsetLeaseValidators();
                this.unsetSaleValidators();
            }
        }
    }
    disableForm() { }
    changeSaleType() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.listingTypeForm.controls.listingType.value === 'forSale') {
                // unsetting validators
                this.unsetLeaseValidators();
            }
            else if (this.listingTypeForm.controls.listingType.value === 'forRent') {
                // listing for setting validations
                this.unsetSaleValidators();
            }
            else if (this.listingTypeForm.controls.listingType.value === 'forCoworking') {
                this.unsetSaleValidators();
                this.unsetLeaseValidators();
            }
        });
    }
    unsetLeaseValidators() {
        this.listingPriceForm.controls['monthlyRent'].setValidators([]);
        this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
        this.listingPriceForm.controls['monthlyCondoFee'].setValidators([]);
        this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
        this.listingPriceForm.controls['monthlyIptu'].setValidators([]);
        this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
        this.listingPriceForm.controls['leaseableArea'].setValidators([]);
        this.listingPriceForm.controls['leaseableArea'].updateValueAndValidity();
        this.listingPriceForm.controls['rentableArea'].setValidators([Validators.required]);
        this.listingPriceForm.controls['rentableArea'].updateValueAndValidity();
        if (!this.listingPriceForm.value.pricePer) {
            this.listingPriceForm.controls['totalAskingPrice'].setValidators([Validators.required]);
            this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
        }
    }
    unsetSaleValidators() {
        this.listingPriceForm.controls['rentableArea'].setValidators([]);
        this.listingPriceForm.controls['rentableArea'].updateValueAndValidity();
        this.listingPriceForm.controls['totalAskingPrice'].setValidators([]);
        this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
        this.listingPriceForm.controls['leaseableArea'].setValidators([Validators.required]);
        this.listingPriceForm.controls['leaseableArea'].updateValueAndValidity();
        if (this.listingPriceForm.value.negRentableArea) {
            this.listingPriceForm.controls['monthlyRent'].setValidators([Validators.required]);
            this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
        }
    }
    setSubTypes(index) {
        if (this.subTypes[index].selected) {
            this.subTypes[index].selected = false;
            Object.keys(this.subTypes).forEach((type) => {
                this.subTypes[type].disabled = false;
            });
        }
        else {
            this.subTypes[index].selected = true;
            Object.keys(this.subTypes).forEach((type) => {
                this.subTypes[type].disabled = false;
            });
        }
        this.listingTypeForm.controls['subType'].setValue(this.subTypes);
    }
    checkBuildingType() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.clearInput();
            this.fuse = null;
            this.subTypes = yield this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value);
            this.subTypes = yield this.getSpaceTranslations(this.subTypes);
            if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
                this.buildingNames = this.industrialBuildings;
                this.buildings = this.industrialBuildings.values;
                this._eventChecker.typeBuildings$.next('changed');
            }
            else {
                this.buildingNames = this.officeBuildings;
                this.buildings = this.officeBuildings.values;
                this._eventChecker.typeBuildings$.next('changed');
            }
        });
    }
    clearInput() {
        this.detailsForm.controls['propertySet'].setValue(false);
        this.detailsForm.controls.actualProperty.setValue(null);
        this.detailsForm.controls.propertyName.setValue('');
        this.detailsForm.controls.propertyName.enable();
    }
    getBuildingT() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.subTypes = yield this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value);
            this.subTypes = yield this.getSpaceTranslations(this.subTypes);
            this.listing && this.listing.propertySubTypes
                ? yield this.initSubTypes(this.listing.propertySubTypes, this.subTypes)
                : null;
            this.setDisabledFields(this.subTypes);
            this.industrialBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(1001);
            this.officeBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(2001);
            if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
                this.buildingNames = this.industrialBuildings;
                this.buildings = this.industrialBuildings.values;
            }
            else {
                this.buildingNames = this.officeBuildings;
                this.buildings = this.officeBuildings.values;
            }
        });
    }
    initSubTypes(subType, subTypes) {
        Object.keys(subType).forEach(key => {
            let result = subTypes.find((filter) => filter.id === subType[key].id);
            if (result) {
                result.selected = true;
            }
        });
    }
    setDisabledFields(subTypes) { }
    getSpaceTypes(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getBuildingSubTypes(id).toPromise();
        });
    }
    getSpaceTranslations(subTypes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Object.keys(subTypes).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                subTypes[key].name = this.getI18nValue(subTypes[key].name);
                subTypes[key].selected = false;
                subTypes[key].disabled = false;
            }));
            return subTypes;
        });
    }
    getI18nValue(textValue) {
        return this.i18NService.getTranslation(textValue);
    }
}
