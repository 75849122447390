<ion-content>
  <ion-row class="title-main">
    <h3 class="no-margin" translate="global.privacy-policy.title"></h3>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p1"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s1"></h4>
  </ion-row>
  <ion-row class="text">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.second.sec1"></h6>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p2"></p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.second.sec2"></h6>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p3"></p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.second.sec3"></h6>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p4"></p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.second.sec4"></h6>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p5"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s2"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p6"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s3"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p7"></p>
  </ion-row>
  <ion-row class="text">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.second.sec5"></h6>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p8"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p9"></p>
  </ion-row>
  <ion-row class="text">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.second.sec6"></h6>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p10"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p11"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p12"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p13"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s4"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p14"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s5"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p15"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s6"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p16"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s7"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p17"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s8"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p18"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s9"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p19"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s10"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p20"></p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.privacy-policy.subtitles.s11"></h4>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p21"></p>
  </ion-row>

  <ion-row class="title">
    <p translate="global.privacy-policy.footer.date"></p>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.footer.reserved"></p>
  </ion-row>
</ion-content>
