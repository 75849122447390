<ion-grid class="overflow" *ngIf="!loading">
  <app-range-filters
    [checkboxValues]="checkboxValues"
    [staticMinMax]="staticMinMax"
    [rangeModelValues]="rangeModelValues"
  ></app-range-filters>

  <!-- Boolean Filters -->
  <ion-row class="padding-10">
    <ion-button
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.twentyFourHourSecurity }"
      fill="outline"
      (click)="searchDto.twentyFourHourSecurity = !searchDto.twentyFourHourSecurity"
    >
      <span
        [ngClass]="{ white: searchDto.twentyFourHourSecurity }"
        translate="global.addtSearchFilters.twentyFourHourSecurity"
      ></span>
    </ion-button>

    <ion-button
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.electricGenerator }"
      fill="outline"
      (click)="searchDto.electricGenerator = !searchDto.electricGenerator"
    >
      <span
        [ngClass]="{ white: searchDto.electricGenerator }"
        translate="global.addtSearchFilters.electricGenerator"
      ></span>
    </ion-button>

    <ion-button
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.heliport }"
      fill="outline"
      (click)="searchDto.heliport = !searchDto.heliport"
    >
      <span [ngClass]="{ white: searchDto.heliport }" translate="global.addtSearchFilters.heliport"></span>
    </ion-button>

    <ion-button
      color="secondary"
      [ngClass]="{ selectedBtn: searchDto.sprinklers }"
      fill="outline"
      (click)="searchDto.sprinklers = !searchDto.sprinklers"
    >
      <span [ngClass]="{ white: searchDto.sprinklers }" translate="global.addtSearchFilters.sprinklers"></span>
    </ion-button>
  </ion-row>

  <!-- Office Filters -->

  <app-office-filters [searchDto]="searchDto" [propertyType]="propertyType"></app-office-filters>

  <!-- Industrial Filters -->

  <app-industrial-filters [searchDto]="searchDto" [propertyType]="propertyType"></app-industrial-filters>
</ion-grid>

<ion-row class="fixed-ion-btnrow" *ngIf="!loading">
  <ion-col size="4">
    <ion-button color="light" fill="clear" (click)="clearSearch()" translate="ClearFilters"></ion-button>
  </ion-col>

  <ion-col size="8">
    <ion-button class="float-right" color="light" fill="clear" (click)="dismiss()" translate="Update"></ion-button>
    <ion-button class="float-right" color="light" fill="clear" (click)="cancel()" translate="Cancel"></ion-button>
  </ion-col>
</ion-row>
