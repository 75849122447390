/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./my-listing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../search-navigation/pages/listing-search/listing-search.component.ngfactory";
import * as i3 from "../../search-navigation/pages/listing-search/listing-search.component";
import * as i4 from "@angular/router";
import * as i5 from "../../core/listings/listing.service";
import * as i6 from "@angular/common";
import * as i7 from "../../core/i18n.service";
import * as i8 from "@angular/cdk/layout";
import * as i9 from "../../core/listings/create-listing.service";
import * as i10 from "../../core/common.service";
import * as i11 from "../../core/search-tags.service";
import * as i12 from "../../core/register.service";
import * as i13 from "../../core/listings/event-checker.service";
import * as i14 from "../../core/listings/nearby-listing.service";
import * as i15 from "../../search-navigation/services/listing-search.service";
import * as i16 from "../../core/helpers/user.service";
import * as i17 from "../../core/helpers/status-helper.service";
import * as i18 from "../../core/helpers/classes-helper.service";
import * as i19 from "../../core/helpers/popover-ctrl-helper.service";
import * as i20 from "../../core/listings/listing-carousel.service";
import * as i21 from "../../core/seo/meta.service";
import * as i22 from "../../core/user-activity/user-activity.service";
import * as i23 from "../../shell/shell.component";
import * as i24 from "./my-listing.component";
var styles_ReviewMyListingComponent = [i0.styles];
var RenderType_ReviewMyListingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReviewMyListingComponent, data: {} });
export { RenderType_ReviewMyListingComponent as RenderType_ReviewMyListingComponent };
export function View_ReviewMyListingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "listing-search", [], null, [["window", "beforeunload"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ListingSearchComponent_0, i2.RenderType_ListingSearchComponent)), i1.ɵdid(1, 770048, null, 0, i3.ListingSearchComponent, [i4.Router, i5.ListingService, i4.ActivatedRoute, i6.Location, i7.I18nService, i8.BreakpointObserver, i9.CreateListingService, i10.CommonGenericService, i11.SearchTagsService, i12.RegisterService, i13.EventCheckerService, i14.NearbyListingSvc, i15.ListingSearchService, i16.UserService, i17.StatusHelperService, i18.ClassHelperService, i19.PopoverCtrlHelper, i20.ListingCarouselService, i21.MetaService, i22.UserActivityService, i23.ShellComponent], { myListingPage: [0, "myListingPage"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ReviewMyListingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "review-my-listings", [], null, null, null, View_ReviewMyListingComponent_0, RenderType_ReviewMyListingComponent)), i1.ɵdid(1, 114688, null, 0, i24.ReviewMyListingComponent, [i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReviewMyListingComponentNgFactory = i1.ɵccf("review-my-listings", i24.ReviewMyListingComponent, View_ReviewMyListingComponent_Host_0, {}, {}, []);
export { ReviewMyListingComponentNgFactory as ReviewMyListingComponentNgFactory };
