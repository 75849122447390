<header class="col-md-12 title">
  <div style="padding-top: 20px;padding-left: 10px;">
    <span style="font-size: 18px;font-weight: 800;">{{ 'setReport.spotReport' | translate }}</span>
    <img *ngIf="isMobile" class="close-icon" (click)="close()" src="assets/images/icons/closeWhite.png" alt="" />
    <img *ngIf="!isMobile" class="close-icon" (click)="close()" src="assets/images/icons/close.png" alt="" />
  </div>
</header>
<mat-tab-group mat-align-tabs="start" class="mat-group" [(selectedIndex)]="step">
  <mat-tab [label]="'setReport.buildingSelection' | translate" (click)="changeStep(0)">
    <div class="row" style="padding: 0px 24px 0px 24px;">
      <div class="col-md-12" style="margin-top: 14px;">
        <span
          ><b>{{ 'setReport.spotSelection' | translate }}</b></span
        >
      </div>
      <div class="col-md-12" style="padding: 18px;">
        <select name="" id="" [(ngModel)]="setSpotSpace" (change)="changeSetSPot($event)" class="form-control">
          <option [value]="undefined" disabled>{{ 'global.my-list-menu.select' | translate }}</option>
          <option *ngFor="let spotSet of listSpotSet" [ngValue]="spotSet" [selected]="setSpotSpace === spotSet">{{
            spotSet.name
          }}</option>
        </select>
      </div>
    </div>
    <div *ngIf="!isMobile" class="row" style="padding:0px 24px 0px 24px;">
      <div class="col-md-6">
        <table class="table" style="border: 1px #dee2e6 solid;">
          <thead class="thead-light" style="font-size: 12px;text-align: center;white-space: nowrap;">
            <tr>
              <th scope="col"></th>
              <th scope="col">
                <input
                  (click)="selectAll()"
                  [(ngModel)]="selectallSpaces"
                  type="checkbox"
                  style="margin-top: 2px;
                             position: absolute;"
                />
                <span style="margin-left:18px ;">{{ 'setReport.included' | translate }}</span>
              </th>
              <th scope="col">
                {{ 'setReport.order' | translate }}
              </th>
              <th scope="col">
                {{ 'setReport.propertyName' | translate }}
              </th>
              <th scope="col">
                {{ 'setReport.photo' | translate }}
              </th>
            </tr>
          </thead>
          <tbody
            *ngIf="buildingDetails.length > 0"
            class="text-center"
            style="font-weight: bold;font-size: 12px;white-space: nowrap;"
          >
            <tr style="font-weight: 700;" *ngFor="let spotBuilding of buildingDetails; let i = index">
              <td class="vertical-center ">
                <div>
                  <fa-icon
                    (click)="sendUp(spotBuilding)"
                    style="cursor: pointer;"
                    class="arrow-icon"
                    [icon]="chevronUp"
                    size="lg"
                  >
                  </fa-icon>
                </div>
                <div>
                  <fa-icon
                    (click)="sendDown(spotBuilding)"
                    style="cursor: pointer;"
                    class="arrow-icon"
                    [icon]="chevronDown"
                    size="lg"
                  >
                  </fa-icon>
                </div>
              </td>
              <td class="vertical-center ">
                <input type="checkbox" id="selected" [(ngModel)]="spotBuilding.selected" />
              </td>
              <td class="vertical-center" [(ngModel)]="spotBuilding.order">
                {{ spotBuilding.orderId }}
              </td>
              <td class="vertical-center " style="white-space: initial;">
                {{ i18nService.getTranslation(spotBuilding.propertyName) }}
              </td>
              <td style="padding-top: 9px;text-align: end; display: inherit;">
                <img
                  style="width: 70px;height: 52.5px;margin-top: 2px;padding-right: 2px;"
                  class="img-fluid"
                  [src]="
                    getMediumImg(
                      spotBuilding.buildingListingPhotos ? spotBuilding.buildingListingPhotos[0].image.id : null
                    )
                  "
                  alt=""
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6" style="height: 48vh;">
        <app-full-screen-map-wrapper>
          <app-duplomap [markers]="markers" [options]="mapOptions"> </app-duplomap>
        </app-full-screen-map-wrapper>
      </div>
    </div>
    <div *ngIf="isMobile" class="row" style="padding:0px 24px 0px 24px;">
      <div class="col-md-6" style="height: 48vh;">
        <app-full-screen-map-wrapper>
          <app-duplomap [markers]="markers" [options]="mapOptions"> </app-duplomap>
        </app-full-screen-map-wrapper>
      </div>
      <div class="col-md-6" style="margin-bottom: 130px;">
        <div *ngFor="let spotBuilding of buildingDetails; let i = index">
          <div class="wrapper">
            <div class="one color-colum">
              <input type="checkbox" id="selected" [(ngModel)]="spotBuilding.selected" />
              <span style="margin-left:18px ;">{{ 'setReport.included' | translate }}</span>
            </div>
            <div class="text-center color-colum">
              <span> {{ 'setReport.order' | translate }}</span>
            </div>
            <div class="text-center color-font">
              <span>{{ spotBuilding.orderId }}</span>
            </div>
            <div class="text-center color-colum">
              <span> {{ 'setReport.propertyName' | translate }}</span>
            </div>
            <div class="text-center color-font">
              <span>{{ i18nService.getTranslation(spotBuilding.propertyName) }}</span>
            </div>
            <div class="text-center color-colum">
              <span> {{ 'setReport.photo' | translate }}</span>
            </div>
            <div class="text-center" style=" border-bottom: 1px solid #CED4DA;">
              <img
                style="width: 70px; height: 52.5px;margin: 6px;"
                [src]="
                  getMediumImg(
                    spotBuilding.buildingListingPhotos ? spotBuilding.buildingListingPhotos[0].image.id : null
                  )
                "
                alt=""
              />
            </div>
          </div>
          <div style="margin-top: 10px;width: 50%;float: left;">
            <button (click)="sendUp(spotBuilding)" style="width: 100%;border: 1px solid #00334D;border-radius: 4px;">
              <fa-icon style="cursor: pointer;" class="arrow-icon" [icon]="chevronUp" size="lg"> </fa-icon>
            </button>
          </div>
          <div style="margin-top: 10px;width: 50%;float: right;">
            <button (click)="sendDown(spotBuilding)" style="width: 100%;border: 1px solid #00334D;border-radius: 4px;">
              <fa-icon style="cursor: pointer;" class="arrow-icon" [icon]="chevronDown" size="lg"> </fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab [label]="'setReport.reportSelection' | translate" [disabled]="step == 0">
    <div class="row" style="padding: 0px 24px 0px 24px;">
      <div class="col-md-12">
        <h5 style="margin-top: 20px;">
          <b>{{ 'setReport.saveReport' | translate }}</b>
        </h5>
      </div>
      <div class="col-md-12 title-set">
        <input
          #titleInput
          id="titleInput"
          name="titleInput"
          type="text"
          [ngClass]="setReport.reportName === '' ? 'input-error' : 'input-line'"
          [(ngModel)]="setReport.reportName"
          maxlength="100"
          class="form-control"
          [placeholder]="'setReport.title' | translate"
          focus
        />
        <p class="count-title">{{ setReport.reportName.length }}/100</p>
      </div>
    </div>

    <div class="row" style="padding: 0px 44px 0px 44px;margin-bottom: 130px">
      <!-- content -->
      <mat-accordion style="width: 100%;">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title">
              {{ 'setReport.content' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row panel-content">
            <div class="row form-check form-check-inline checkbox-content">
              <input class="form-check-input" type="checkbox" id="tourMap" [(ngModel)]="setReport.content.tourMap" />
              <label class="form-check-label" for="tourMap">{{ 'setReport.tourMap' | translate }}</label>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;">
              <input
                class="form-check-input"
                type="checkbox"
                id="buildingPage"
                [(ngModel)]="setReport.content.buildingPage.buildingPage"
                (click)="changeBuildingPage(1)"
              />
              <label class="form-check-label" for="buildingPage">{{ 'setReport.buildingPage' | translate }}</label>
              <ul>
                <li class="row">
                  <input
                    class="form-check-input"
                    [(ngModel)]="setReport.content.buildingPage.allSpots"
                    type="checkbox"
                    id="allSpots"
                    (click)="changeBuildingPage()"
                  />
                  <label class="form-check-label" for="allSpots">{{ 'setReport.allSports' | translate }}</label>
                </li>
                <li class="row">
                  <input
                    class="form-check-input"
                    [(ngModel)]="setReport.content.buildingPage.selectedSpotsOnly"
                    type="checkbox"
                    id="selectedSpotsOnly"
                    (click)="changeBuildingPage()"
                  />
                  <label class="form-check-label" for="selectedSpotsOnly">{{
                    'setReport.selectedSpotsOnly' | translate
                  }}</label>
                </li>
              </ul>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;" id="inputSpotPage">
              <input
                class="form-check-input"
                type="checkbox"
                id="spotPage"
                [(ngModel)]="setReport.content.spotPage.spotPage"
                (click)="changeSpotPage(1)"
              />
              <label class=" form-check-label" for="spotPage">{{ 'setReport.spotPage' | translate }}</label>
              <ul>
                <li class="row">
                  <input
                    class="form-check-input"
                    [(ngModel)]="setReport.content.spotPage.allSpots"
                    type="checkbox"
                    id="allSpotsPage"
                    (click)="changeSpotPage()"
                  />
                  <label class="form-check-label" for="allSpotsPage">{{ 'setReport.allSports' | translate }}</label>
                </li>
                <li class="row">
                  <input
                    class="form-check-input"
                    [(ngModel)]="setReport.content.spotPage.selectedSpotsOnly"
                    type="checkbox"
                    id="selectedSpotsOnlyPage"
                    (click)="changeSpotPage()"
                  />
                  <label class="form-check-label" for="selectedSpotsOnlyPage">{{
                    'setReport.selectedSpotsOnly' | translate
                  }}</label>
                </li>
              </ul>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- include property -->
      <mat-accordion *ngIf="setReport.content.buildingPage.buildingPage && contProperty > 0" style="width: 100%;">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title">
              {{ 'setReport.includePropertyDetails' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row panel-content">
            <div
              *ngIf="
                spotBuilding?.building.buildingType.id == 1001 && !excludeOptions.excludePropertyDetails.propertySubType
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="propertySubtype"
                [(ngModel)]="setReport.includePropertyDetails.propertySubtype"
              />
              <label class="form-check-label" for="propertySubtype">{{
                'buildinsDetail.propertySub' | translate
              }}</label>
            </div>
            <div
              *ngIf="!excludeOptions.excludePropertyDetails.yearBuilt"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="yearBuilt"
                [(ngModel)]="setReport.includePropertyDetails.yearBuilt"
              />
              <label class="form-check-label" for="yearBuilt">{{
                'global.list-your-property.yearBuilt' | translate
              }}</label>
            </div>
            <div
              *ngIf="!excludeOptions.excludePropertyDetails.yearRenovated"
              lass="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="yearRenovated"
                [(ngModel)]="setReport.includePropertyDetails.yearRenovated"
              />
              <label class="form-check-label" for="yearRenovated">{{
                'global.list-your-property.yearRenovated' | translate
              }}</label>
            </div>
            <div
              *ngIf="!excludeOptions.excludePropertyDetails.constructionStatus"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="constructionStatus"
                [(ngModel)]="setReport.includePropertyDetails.constructionStatus"
              />
              <label class="form-check-label" for="constructionStatus">{{
                'global.list-your-property.constructionStatus' | translate
              }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.type == 'S' &&
                spotBuilding?.building.buildingType.id == 2001 &&
                !excludeOptions.excludePropertyDetails.numberOfParkingSpaces
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="numberOfParkingSpaces"
                [(ngModel)]="setReport.includePropertyDetails.numberOfParkingSpaces"
              />
              <label class="form-check-label" for="numberOfParkingSpaces">{{
                'global.list-your-property.NumberParkingSpaces' | translate
              }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.type == 'S' &&
                spotBuilding?.type != 'C' &&
                spotBuilding?.building.buildingType.id == 2001 &&
                !excludeOptions.excludePropertyDetails.propertySize
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="propertySize"
                [(ngModel)]="setReport.includePropertyDetails.propertySize"
              />
              <label class="form-check-label" for="propertySize">{{ 'setReport.propertySize' | translate }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.building.buildingType.id == 2001 && !excludeOptions.excludePropertyDetails.numberOfFloors
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="numberOfFloors"
                [(ngModel)]="setReport.includePropertyDetails.numberOfFloors"
              />
              <label class="form-check-label" for="numberOfFloors">{{
                'global.list-your-property.floors' | translate
              }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.building.buildingType.id == 2001 &&
                !excludeOptions.excludePropertyDetails.averageFloorSize
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="numberOfFloors"
                [(ngModel)]="setReport.includePropertyDetails.averageFloorSize"
              />
              <label class="form-check-label" for="numberOfFloors">{{ 'setReport.floorSize' | translate }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.type == 'S' &&
                spotBuilding?.building.buildingType.id == 1001 &&
                !excludeOptions.excludePropertyDetails.numberOfTruckParkingSpaces
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="numberOfTruckParkingSpaces"
                [(ngModel)]="setReport.includePropertyDetails.numberOfTruckParkingSpaces"
              />
              <label class="form-check-label" for="numberOfTruckParkingSpaces">{{
                'global.list-your-property.numberTruckParking' | translate
              }}</label>
            </div>
            <!--  <div *ngIf="spotBuilding?.type == 'C'" class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;">
              <input class="form-check-input" type="checkbox" id="privateOutdoorSpace"
                [(ngModel)]="setReport.includePropertyDetails.privateOutdoorSpace" />
              <label class="form-check-label" for="privateOutdoorSpace">{{
                'spaceDetail.privateOutdoor' | translate
                }}</label>
            </div>
            <div *ngIf="spotBuilding?.type == 'C'" class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;">
              <input class="form-check-input" type="checkbox" id="sharedOutdoorSpace"
                [(ngModel)]="setReport.includePropertyDetails.sharedOutdoorSpace" />
              <label class="form-check-label" for="sharedOutdoorSpace">{{
                'spaceDetail.sharedOutdoor' | translate
                }}</label>
            </div>
            <div *ngIf="spotBuilding?.type == 'C'" class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;">
              <input class="form-check-input" type="checkbox" id="parkingAvailable"
                [(ngModel)]="setReport.includePropertyDetails.parkingAvailable" />
              <label class="form-check-label" for="parkingAvailable">{{
                'spaceDetail.parkingAvailable' | translate
                }}</label>
            </div>
            <div *ngIf="spotBuilding?.type == 'C'" class=" row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;">
              <input class="form-check-input" type="checkbox" id="parkingCostPerMonth"
                [(ngModel)]="setReport.includePropertyDetails.parkingCostPerMonth" />
              <label class="form-check-label" for="parkingCostPerMonth">{{
                'spaceDetail.parkingCost' | translate
                }}</label>
            </div>
            <div *ngIf="spotBuilding?.type == 'C'" class=" row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;">
              <input class="form-check-input" type="checkbox" id="printsAndCopiesPerMonth"
                [(ngModel)]="setReport.includePropertyDetails.printsAndCopiesPerMonth" />
              <label class="form-check-label" for="printsAndCopiesPerMonth">{{
                'spaceDetail.printCopies' | translate
                }}</label>
            </div>
            <div *ngIf="spotBuilding?.type == 'C'" class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;">
              <input class="form-check-input" type="checkbox" id="mailHanding"
                [(ngModel)]="setReport.includePropertyDetails.mailHanding" />
              <label class="form-check-label" for="mailHanding">{{ 'spaceDetail.mainHandling' | translate }}</label>
            </div> -->
            <div
              *ngIf="
                spotBuilding?.type == 'S' &&
                spotBuilding?.type != 'C' &&
                spotBuilding?.building.buildingType.id == 1001 &&
                !excludeOptions.excludePropertyDetails.numberOfCarParkingSpaces
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="numberOfCarParkingSpaces"
                [(ngModel)]="setReport.includePropertyDetails.numberOfCarParkingSpaces"
              />
              <label class="form-check-label" for="numberOfCarParkingSpaces">{{
                'global.list-your-property.NumberCarParkingSpaces' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type == 'S' && !excludeOptions.excludePropertyDetails.numberOfBuildings"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="numberOfBuildings"
                [(ngModel)]="setReport.includePropertyDetails.numberOfBuildings"
              />
              <label class="form-check-label" for="numberOfBuildings">{{
                'setReport.numberBuildings' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type == 'S' && !excludeOptions.excludePropertyDetails.landArea"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="landArea"
                [(ngModel)]="setReport.includePropertyDetails.landArea"
              />
              <label class="form-check-label" for="landArea">{{ 'setReport.landArea' | translate }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.building.buildingType.id == 2001 &&
                !excludeOptions.excludePropertyDetails.sustainabilityCertification
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="sustainabilityCertification"
                [(ngModel)]="setReport.includePropertyDetails.sustainabilityCertification"
              />
              <label class="form-check-label" for="sustainabilityCertification">{{
                'global.addtSearchFilters.sustentability' | translate
              }}</label>
            </div>
            <div
              *ngIf="!excludeOptions.excludePropertyDetails.twentyFourHourSecurity"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="twentyFourHourSecurity"
                [(ngModel)]="setReport.includePropertyDetails.twentyFourHourSecurity"
              />
              <label class="form-check-label" for="twentyFourHourSecurity">{{
                'global.addtSearchFilters.twentyFourHourSecurity' | translate
              }}</label>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- include SPOT -->
      <mat-accordion *ngIf="setReport.content.spotPage.spotPage && contSpot > 0" style="width: 100%;">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title">
              {{ 'setReport.includeSpotDetails' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row panel-content">
            <div
              *ngIf="spotBuilding?.building.buildingType.id == 1001 && !excludeOptions.excludeSpotDetails.numberOfDocks"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="numberOfDocks"
                [(ngModel)]="setReport.includeSpotDetails.numberOfDocks"
              />
              <label class="form-check-label" for="numberOfDocks">{{
                'global.list-your-property.docks' | translate
              }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.building.buildingType.id == 1001 &&
                !excludeOptions.excludeSpotDetails.numberOfTruckParkingSpaces
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="numberOfTruckParkingSpaces"
                [(ngModel)]="setReport.includeSpotDetails.numberOfTruckParkingSpaces"
              />
              <label class="form-check-label" for="numberOfTruckParkingSpaces">{{
                'global.list-your-property.numberTruckParking' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.building.buildingType.id == 1001 && !excludeOptions.excludeSpotDetails.loadCapacity"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="loadCapacity"
                [(ngModel)]="setReport.includeSpotDetails.loadCapacity"
              />
              <label class="form-check-label" for="loadCapacity">{{ 'spaceDetail.loadCapacity' | translate }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type != 'C' && !excludeOptions.excludeSpotDetails.numberOfParkingSpaces"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="numberOfParkingSpaces"
                [(ngModel)]="setReport.includeSpotDetails.numberOfParkingSpaces"
              />
              <label class="form-check-label" for="numberOfParkingSpaces">{{
                'global.list-your-property.NumberParkingSpaces' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.building.buildingType.id == 1001 && !excludeOptions.excludeSpotDetails.skyLights"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="skyLights"
                [(ngModel)]="setReport.includeSpotDetails.skyLights"
              />
              <label class="form-check-label" for="skyLights">{{ 'spaceDetail.skyLights' | translate }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.building.buildingType.id == 1001 && !excludeOptions.excludeSpotDetails.includesOffice
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="includesOffice"
                [(ngModel)]="setReport.includeSpotDetails.includesOffice"
              />
              <label class="form-check-label" for="includesOffice">{{
                'global.addtSearchFilters.includesOffice' | translate
              }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.building.buildingType.id == 1001 && !excludeOptions.excludeSpotDetails.includesRetail
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="includesRetail"
                [(ngModel)]="setReport.includeSpotDetails.includesRetail"
              />
              <label class="form-check-label" for="includesRetail">{{
                'global.addtSearchFilters.includesRetail' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.building.buildingType.id == 1001 && !excludeOptions.excludeSpotDetails.coldStorage"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="coldStorage"
                [(ngModel)]="setReport.includeSpotDetails.coldStorage"
              />
              <label class="form-check-label" for="coldStorage">{{ 'spaceDetail.coldStorage' | translate }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.building.buildingType.id == 1001 && !excludeOptions.excludeSpotDetails.fullBuildOut"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="fullBuildOut"
                [(ngModel)]="setReport.includeSpotDetails.fullBuildOut"
              />
              <label class="form-check-label" for="fullBuildOut">{{ 'spaceDetail.fullBuildOut' | translate }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.building.buildingType.id == 1001 && !excludeOptions.excludeSpotDetails.directSublease
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="directSublease"
                [(ngModel)]="setReport.includeSpotDetails.directSublease"
              />
              <label class="form-check-label" for="directSublease">{{
                'spaceDetail.directSublease' | translate
              }}</label>
            </div>
            <div
              *ngIf="
                spotBuilding?.building.buildingType.id == 2001 &&
                spotBuilding.type != 'C' &&
                !excludeOptions.excludeSpotDetails.maxContiguous
              "
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="maxContiguous"
                [(ngModel)]="setReport.includeSpotDetails.maxContiguous"
              />
              <label class="form-check-label" for="maxContiguous">{{ 'setReport.maxContiguous' | translate }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type != 'C' && !excludeOptions.excludeSpotDetails.cellingHeight"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="cellingHeight"
                [(ngModel)]="setReport.includeSpotDetails.cellingHeight"
              />
              <label class="form-check-label" for="cellingHeight">{{ 'setReport.cellinHeight' | translate }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type == 'C' && !excludeOptions.excludeSpotDetails.conferenceTimeAllowance"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="conferenceTimeAllowance"
                [(ngModel)]="setReport.includeSpotDetails.conferenceTimeAllowance"
              />
              <label class="form-check-label" for="conferenceTimeAllowance">{{
                'spaceDetail.conferenceTimeHours' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type == 'C' && !excludeOptions.excludeSpotDetails.privateOutdoorSpace"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="privateOutdoorSpace"
                [(ngModel)]="setReport.includeSpotDetails.privateOutdoorSpace"
              />
              <label class="form-check-label" for="privateOutdoorSpace">{{
                'spaceDetail.privateOutdoor' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type == 'C' && !excludeOptions.excludeSpotDetails.sharedOutdoorSpace"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="sharedOutdoorSpace"
                [(ngModel)]="setReport.includeSpotDetails.sharedOutdoorSpace"
              />
              <label class="form-check-label" for="sharedOutdoorSpace">{{
                'spaceDetail.sharedOutdoor' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type == 'C' && !excludeOptions.excludeSpotDetails.parkingAvailable"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="parkingAvailable"
                [(ngModel)]="setReport.includeSpotDetails.parkingAvailable"
              />
              <label class="form-check-label" for="parkingAvailable">{{
                'spaceDetail.parkingAvailable' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type == 'C' && !excludeOptions.excludeSpotDetails.parkingCostPerMonth"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="parkingCostPerMonth"
                [(ngModel)]="setReport.includeSpotDetails.parkingCostPerMonth"
              />
              <label class="form-check-label" for="parkingCostPerMonth">{{
                'spaceDetail.parkingCostPer' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type == 'C' && !excludeOptions.excludeSpotDetails.printsAndCopiesPerMonth"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="printsAndCopiesPerMonth"
                [(ngModel)]="setReport.includeSpotDetails.printsAndCopiesPerMonth"
              />
              <label class="form-check-label" for="printsAndCopiesPerMonth">{{
                'spaceDetail.printCopies' | translate
              }}</label>
            </div>
            <div
              *ngIf="spotBuilding?.type == 'C' && !excludeOptions.excludeSpotDetails.mailHandling"
              class="row form-check form-check-inline checkbox-content"
              style="margin-top: 10px;"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="mailHandling"
                [(ngModel)]="setReport.includeSpotDetails.mailHandling"
              />
              <label class="form-check-label" for="mailHandling">{{ 'spaceDetail.mainHandling' | translate }}</label>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- formatting -->
      <mat-accordion style="width: 100%;">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title">
              {{ 'setReport.formatting' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row panel-content">
            <div class="row form-check form-check-inline checkbox-content">
              <strong>
                <label for="">{{ 'setReport.orientation' | translate }}</label>
              </strong>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;">
              <input
                class="form-check-input"
                type="checkbox"
                id="tourBook"
                [(ngModel)]="setReport.formatting.tourBook"
                disabled
                (click)="changeOrientation()"
              />
              <label class="form-check-label" for="tourBook">{{ 'setReport.tourBook' | translate }}</label>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;">
              <strong>
                <label for="">{{ 'setReport.otherformatting' | translate }}</label>
              </strong>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;">
              <input
                class="form-check-input"
                type="checkbox"
                id="applyBrandcolors"
                [(ngModel)]="setReport.formatting.applyBrandcolors"
              />
              <label class="form-check-label" for="applyBrandcolors">{{ 'setReport.applyBrand' | translate }}</label>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;">
              <input
                class="form-check-input"
                type="checkbox"
                id="excludeProfilePicture"
                [(ngModel)]="setReport.formatting.excludeProfilePicture"
              />
              <label class="form-check-label" for="excludeProfilePicture">{{
                'setReport.excludeProfile' | translate
              }}</label>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;">
              <input
                class="form-check-input"
                type="checkbox"
                id="excludeCompanyLogo"
                [(ngModel)]="setReport.formatting.excludeCompanyLogo"
              />
              <label class="form-check-label" for="excludeCompanyLogo">{{
                'setReport.excludeCompany' | translate
              }}</label>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;">
              <input
                class="form-check-input"
                type="checkbox"
                id="excludeSiilaLogo"
                [(ngModel)]="setReport.formatting.excludeSiilaLogo"
              />
              <label class="form-check-label" for="excludeSiilaLogo">{{ 'setReport.excludeSilla' | translate }}</label>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;">
              <input
                class="form-check-input"
                type="checkbox"
                id="excludeCoverPage"
                [(ngModel)]="setReport.formatting.excludeCoverPage"
              />
              <label class="form-check-label" for="excludeCoverPage">{{ 'setReport.excludeCover' | translate }}</label>
            </div>
            <div class="row form-check form-check-inline checkbox-content" style="margin-top: 10px;">
              <input
                class="form-check-input"
                type="checkbox"
                id="excludeExcludeBackPage"
                [(ngModel)]="setReport.formatting.excludeExcludeBackPage"
              />
              <label class="form-check-label" for="excludeExcludeBackPage">{{
                'setReport.excludeBack' | translate
              }}</label>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div style="width: 100%;text-align: center;" class="tags-section" *ngIf="searching">
        <ion-spinner id="mainSpinner" name="dots" color="primary"></ion-spinner>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<div class="row" style="margin: 10px 28px 0px 28px;" *ngIf="errorReport">
  <div class="col-md-12 error-report">
    <label for="" style="margin-top:10px ;">{{ 'setReport.errorReport' | translate }}</label>
  </div>
</div>
<div class="col-md-12 mobile-btn btn-set ">
  <ion-button
    *ngIf="step == 0 && !isMobile"
    [disabled]="getBuildingSelected()"
    class="btn-color-2 btn-mobile"
    (click)="reportSelection()"
  >
    {{ 'global.menu.next' | translate }}
  </ion-button>
  <ion-button
    *ngIf="step == 1 && !isMobile"
    [disabled]="setReport.reportName == '' || searching"
    class="btn-color-2 btn-mobile"
    (click)="createReport()"
  >
    {{ 'setReport.download' | translate }}
  </ion-button>
  <button *ngIf="step == 0 && !isMobile" (click)="close()" class="btn-cancel-set btn-mobile" style="margin-top: 5px;">
    {{ 'Cancel' | translate }}
  </button>
  <button
    *ngIf="step == 0 && isMobile"
    (click)="close()"
    class="btn-color-1 btn-border btn-mobile"
    style="margin-top: 5px;"
  >
    {{ 'Cancel' | translate }}
  </button>
  <ion-button
    *ngIf="step == 0 && isMobile"
    [disabled]="getBuildingSelected()"
    class="btn-color-2 btn-mobile"
    (click)="reportSelection()"
  >
    {{ 'global.menu.next' | translate }}
  </ion-button>
  <button *ngIf="step == 1 && !isMobile" (click)="close()" class="btn-cancel-set btn-mobile" style="margin-top: 5px;">
    {{ 'Cancel' | translate }}
  </button>
  <button
    *ngIf="step == 1 && isMobile"
    (click)="close()"
    class="btn-color-1 btn-border btn-mobile"
    style="margin-top: 5px;"
  >
    {{ 'Cancel' | translate }}
  </button>
  <ion-button
    *ngIf="step == 1 && isMobile"
    [disabled]="setReport.reportName == '' || searching"
    class="btn-color-2 btn-mobile"
    (click)="createReport()"
  >
    {{ 'setReport.downloadMobile' | translate }}
  </ion-button>
</div>
