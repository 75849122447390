<ion-row *ngIf="spotBuildings.type == 'L'">
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.propertySize' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="propertySize"
      name="propertySize"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.rentableArea"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 2001">
    <ion-label class="full-width">{{ 'global.list-your-property.averageFloorPrivateArea' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="averageFloorPrivateArea"
      name="averageFloorPrivateArea"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.averageFloorPrivateArea"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.ceilingHeightAvg' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="ceilingHeightAvg"
      name="ceilingHeightAvg"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.ceilingHeight"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.yearBuilt' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="yearBuilt"
      name="yearBuilt"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      maxlength="4"
      [(ngModel)]="spotBuildings.yearBuilt"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.yearRenovated' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="yearRenovated"
      name="yearRenovated"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      maxlength="4"
      [(ngModel)]="spotBuildings.yearRenovated"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.constructionStatus' | translate }} </ion-label>
    <span class="width-full my-listing-agent">
      <ionic-selectable
        style="border: 1px solid lightgrey;"
        [items]="allConstructionStatus"
        itemValueField="id"
        [disabled]="false"
        itemTextField="name"
        [(ngModel)]="selectContructionStatus"
        [hasVirtualScroll]="true"
        placeholder="Construction Status"
        [canSearch]="true"
        (onChange)="constructionChange($event)"
        searchPlaceholder="{{ 'global.menu.search' | translate }}"
        closeButtonText="{{ 'Cancel' | translate }}"
        #constructionComponent
      >
      </ionic-selectable>
    </span>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 2001">
    <ion-label class="full-width">{{ 'global.list-your-property.floors' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="floors"
      name="floors"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.numberStories"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 1001">
    <ion-label class="full-width">{{ 'global.list-your-property.NumberCarParkingSpaces' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="NumberCarParkingSpaces"
      name="NumberCarParkingSpaces"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.carParkingSpaces"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 1001">
    <ion-label class="full-width">{{ 'global.list-your-property.numberTruckParking' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="numberTruckParking"
      name="numberTruckParking"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.truckParkingSpaces"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 2001">
    <ion-label class="full-width">{{ 'global.list-your-property.NumberParkingSpaces' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="NumberParkingSpaces"
      name="NumberParkingSpaces"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.parkingSpaces"
    >
    </ion-input>
  </ion-col>
</ion-row>
<ion-row *ngIf="spotBuildings.type == 'S'">
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.propertySize' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="propertySize"
      name="propertySize"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.rentableArea"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 2001">
    <ion-label class="full-width">{{ 'global.list-your-property.floors' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="floors"
      name="floors"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.numberStories"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 2001">
    <ion-label class="full-width">{{ 'global.list-your-property.averageFloorPrivateArea' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="averageFloorPrivateArea"
      name="averageFloorPrivateArea"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.averageFloorPrivateArea"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.ceilingHeightAvg' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="ceilingHeightAvg"
      name="ceilingHeightAvg"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.ceilingHeight"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.totalLanArea' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="totalLanArea"
      name="totalLanArea"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.landArea"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.constructionStatus' | translate }} </ion-label>
    <span class="width-full my-listing-agent">
      <ionic-selectable
        style="border: 1px solid lightgrey;"
        [items]="allConstructionStatus"
        itemValueField="id"
        [disabled]="false"
        itemTextField="name"
        [(ngModel)]="selectContructionStatus"
        [hasVirtualScroll]="true"
        placeholder="Construction Status"
        [canSearch]="true"
        (onChange)="constructionChange($event)"
        searchPlaceholder="{{ 'global.menu.search' | translate }}"
        closeButtonText="{{ 'Cancel' | translate }}"
        #constructionComponent
      >
      </ionic-selectable>
    </span>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.yearBuilt' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="yearBuilt"
      name="yearBuilt"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      maxlength="4"
      [(ngModel)]="spotBuildings.yearBuilt"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.yearRenovated' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="yearRenovated"
      name="yearRenovated"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      maxlength="4"
      [(ngModel)]="spotBuildings.yearRenovated"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 1001">
    <ion-label class="full-width">{{ 'global.list-your-property.NumberCarParkingSpaces' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="NumberCarParkingSpaces"
      name="NumberCarParkingSpaces"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.carParkingSpaces"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 2001">
    <ion-label class="full-width">{{ 'global.list-your-property.NumberParkingSpaces' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="NumberParkingSpaces"
      name="NumberParkingSpaces"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.parkingSpaces"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 1001">
    <ion-label class="full-width">{{ 'global.list-your-property.numberTruckParking' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="numberTruckParking"
      name="numberTruckParking"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.truckParkingSpaces"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="propertyType == 1001">
    <ion-label class="full-width">{{ 'global.list-your-property.numberBuildings' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="numberBuildings"
      name="numberBuildings"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.numberOfBuildings"
    >
    </ion-input>
  </ion-col>
  <!-- <ion-col size="6" *ngIf="spotBuildings.entireSale">
    <ion-label class="full-width">{{ 'global.list-your-property.investmentOpportunity' | translate }} </ion-label>
    <ion-input class="input-border" id="investmentOpportunity" name="investmentOpportunity" type="tel" inputmode="text"
      [(ngModel)]="spotBuildings.investmentOpportunity">
    </ion-input>
  </ion-col> -->
  <ion-col size="6" *ngIf="spotBuildings.entireSale">
    <ion-label class="full-width">{{ 'global.list-your-property.majorTenants' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="majorTenants"
      name="majorTenants"
      type="tel"
      inputmode="text"
      [(ngModel)]="spotBuildings.majorTenants"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="spotBuildings.entireSale">
    <ion-label class="full-width">{{ 'global.list-your-property.capRate' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="capRate"
      name="capRate"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.capRate"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="spotBuildings.entireSale">
    <ion-label class="full-width">{{ 'global.list-your-property.percentLeased' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="percentageLeased"
      name="percentageLeased"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.percentageLeased"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="spotBuildings.entireSale">
    <ion-label class="full-width">{{ 'global.list-your-property.areaforSale' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="area"
      name="area"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.area"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="spotBuildings.entireSale">
    <ion-label class="full-width">{{ 'global.list-your-property.askingPrice' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="askingPrice"
      name="askingPrice"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.askingPrice"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="spotBuildings.entireSale && propertyType == 1001">
    <ion-label class="full-width">{{ 'global.list-your-property.docks' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="docks"
      name="docks"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.docks"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6" *ngIf="spotBuildings.entireSale && propertyType == 1001">
    <ion-label class="full-width">{{ 'global.list-your-property.loadCapacity' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="loadCapacity"
      name="loadCapacity"
      type="tel"
      inputmode="text"
      [(ngModel)]="spotBuildings.loadCapacity"
    >
    </ion-input>
  </ion-col>
</ion-row>
<ion-row *ngIf="spotBuildings.type == 'C'">
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.propertySize' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="propertySize"
      name="propertySize"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.rentableArea"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.floors' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="floors"
      name="floors"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      [(ngModel)]="spotBuildings.numberStories"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.yearBuilt' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="yearBuilt"
      name="yearBuilt"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      maxlength="4"
      [(ngModel)]="spotBuildings.yearBuilt"
    >
    </ion-input>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.yearRenovated' | translate }} </ion-label>
    <ion-input
      class="input-border"
      id="yearRenovated"
      name="yearRenovated"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, false)"
      maxlength="4"
      [(ngModel)]="spotBuildings.yearRenovated"
    >
    </ion-input>
  </ion-col>
</ion-row>
<ion-row>
  <ion-col size="3">
    <ion-label class="full-width">{{ 'global.list-your-property.startDate' | translate }}: </ion-label>
    <div>
      <span class="width-full date-picker" style="position: relative;">
        <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
        <input
          class="new-expiration-date-input"
          matInput
          [matDatepicker]="picker"
          cdkFocusInitial
          [(ngModel)]="spotBuildings.startDate"
          (ngModelChange)="checkDate($event)"
          placeholder="{{ 'global.adminUpdates.chooseDate' | translate }}"
          style="height: 42.5px"
        />
        <mat-datepicker #picker></mat-datepicker>
      </span>
    </div>
  </ion-col>
  <ion-col size="3">
    <ion-label class="full-width">{{ 'global.list-your-property.endDate' | translate }}: </ion-label>
    <div>
      <span class="width-full date-picker" style="position: relative;">
        <mat-datepicker-toggle matSuffix [for]="picker2"> </mat-datepicker-toggle>
        <input
          class="new-expiration-date-input"
          matInput
          [matDatepicker]="picker2"
          cdkFocusInitial
          [(ngModel)]="spotBuildings.endDate"
          (ngModelChange)="checkDate($event)"
          placeholder="{{ 'global.adminUpdates.chooseDate' | translate }}"
          style="height: 42.5px"
        />
        <mat-datepicker #picker2></mat-datepicker>
      </span>
    </div>
  </ion-col>
  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.occupancy' | translate }}</ion-label>
    <ion-input
      class="input-border"
      id="occupancy"
      name="occupancy"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.currentOccupancy"
    >
    </ion-input>
  </ion-col>

  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.revenue' | translate }}</ion-label>
    <ion-input
      class="input-border"
      id="revenue"
      name="revenue"
      type="tel"
      inputmode="decimal"
      (ionChange)="checkCharacters($event, true)"
      [(ngModel)]="spotBuildings.revenue"
    >
    </ion-input>
  </ion-col>

  <ion-col size="6">
    <ion-label class="full-width">{{ 'global.list-your-property.lastMonthlyMeeting' | translate }}: </ion-label>
    <div>
      <span class="width-full date-picker" style="position: relative;">
        <mat-datepicker-toggle matSuffix [for]="lastMeetingPicker"> </mat-datepicker-toggle>
        <input
          class="new-expiration-date-input"
          matInput
          [matDatepicker]="lastMeetingPicker"
          cdkFocusInitial
          [(ngModel)]="spotBuildings.lastMonthlyMeeting"
          (ngModelChange)="checkDate($event)"
          placeholder="{{ 'global.adminUpdates.chooseDate' | translate }}"
          style="height: 42.5px"
        />
        <mat-datepicker #lastMeetingPicker></mat-datepicker>
      </span>
    </div>
  </ion-col>
</ion-row>
<ion-row>
  <ion-col size="12">
    <ion-row class="full-width">
      <ion-col size="6" *ngFor="let tag of buildingsOfficeIndustrialFilters; let i = index">
        <button [ngClass]="{ 'sub-type': tag.selected }" class="selected-white" (click)="setOfficeIndustrialTypes(i)">
          {{ tag.name | translate }}
        </button>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
<ion-row class="full-width margin-top-5 margin-top-60">
  <ion-col size="12">
    <ion-label style="margin-top: 20px;" class="full-width">
      <h5 class="title">{{ 'global.virtualTourURL' | translate }}</h5>
      <br />
      {{ 'global.virtualTourContent' | translate }} https://www.youtube.com/embed/...
      {{ 'global.virtualTourContent2' | translate }}
    </ion-label>
    <ion-textarea
      [ngClass]="{ 'red-border': videoURLInvalid }"
      class="input-border"
      (ionChange)="validateCustomURL()"
      type="text"
      [(ngModel)]="vrTourUrl"
    ></ion-textarea>
    <ion-label class="form-error" *ngIf="videoURLInvalid">
      {{ 'global.my-list-menu.invalidURL' | translate }}
    </ion-label>
  </ion-col>
</ion-row>
