<form [formGroup]="listingPriceForm" class="full-width" name="listingPriceForm" autocomplete="off">
  <ion-row class="full-width" *ngIf="listingTypeForm?.controls.listingType.value === 'forSale'">
    <ion-row class="full-width">
      <ion-col size="6">
        <ion-label>{{ 'global.listingPricing.rentableArea' | translate }} <span class="blue">*</span></ion-label>
        <ion-input
          type="tel"
          placeholder="{{ 'global.listingPricing.lowRange' | translate }}"
          (ionChange)="checkCharacters($event)"
          (ionBlur)="rentableAreaBlur()"
          formControlName="rentableArea"
          class="input-border"
          inputmode="decimal"
        >
        </ion-input>
      </ion-col>

      <ion-col size="6" style="margin-bottom: 10px;">
        <ion-label style="white-space: nowrap;"
          >{{ 'global.listingPricing.measurement' | translate }} <span class="blue">*</span></ion-label
        >
        <span class="width-full my-listing-company">
          <ionic-selectable
            style="border: 1px solid lightgrey; height: 44px;"
            (onChange)="standardChange($event)"
            formControlName="measurementStandards"
            [items]="measurementStandards"
            itemValueField="id"
            itemTextField="measurement"
            [hasVirtualScroll]="true"
            closeButtonText="{{ 'Cancel' | translate }}"
            placeholder=""
            closeButtonText="{{ 'Cancel' | translate }}"
            #measurementComponent
          >
          </ionic-selectable>
        </span>
      </ion-col>
    </ion-row>

    <ion-row class="full-width margin-bottom-15 border">
      <ion-col class="padding-unset" size="4" class="align-checkbox">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.pricePer }">
          {{ 'global.listingPricing.offeringPrice' | translate }}
        </ion-label>
      </ion-col>
      <ion-col size="8" class="checkbox-flex-end">
        <ion-checkbox class="checkbox-styling" (click)="checkTotal()" formControlName="pricePer"></ion-checkbox>
        <ion-label translate="global.list-your-property.negotiable"></ion-label>
      </ion-col>

      <ion-col size="6">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.pricePer }" class="full-width">
          {{ 'global.listingPricing.amount' | translate }} <span class="blue">*</span></ion-label
        >
        <ion-input
          [ngClass]="{ 'disabled-box': this.listingPriceForm.value.pricePer }"
          (ionChange)="checkCharacters($event)"
          (ionBlur)="totalPriceBlur()"
          formControlName="totalAskingPrice"
          type="tel"
          inputmode="decimal"
          class="input-border"
        >
        </ion-input>
      </ion-col>

      <ion-col size="6">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.pricePer }" class="nowrap">
          {{ 'global.listingPricing.amountPerM' | translate }}<sup>2</sup>
        </ion-label>
        <ion-input
          type="tel"
          inputmode="decimal"
          [ngClass]="{ 'disabled-box': this.listingPriceForm.value.pricePer }"
          (ionBlur)="updateAmountPer()"
          (ionChange)="checkCharacters($event)"
          formControlName="pricePerSquare"
          class="input-border"
        >
        </ion-input>
      </ion-col>
    </ion-row>
  </ion-row>

  <ion-row class="full-width" *ngIf="listingTypeForm?.controls.listingType.value === 'forRent'">
    <ion-row class="full-width">
      <ion-col size="6">
        <ion-label [ngClass]="{ 'promoted-text': listingClass === 'PROMOTED' && screenWidth < 850 }">
          {{ 'global.listingPricing.leaseableArea' | translate }}<span class="blue">*</span></ion-label
        >

        <ion-input
          type="tel"
          placeholder="{{ 'global.listingPricing.lowRange' | translate }}"
          (ionBlur)="leaseableAreaBlur($event)"
          (ionChange)="checkCharacters($event)"
          formControlName="leaseableArea"
          inputmode="decimal"
          class="input-border"
        >
        </ion-input>
      </ion-col>

      <ion-col size="6">
        <ion-label
          [ngClass]="{ 'promoted-text': listingClass === 'PROMOTED' && screenWidth < 850 }"
          *ngIf="listingClass === 'PROMOTED'"
          >{{ 'global.listingPricing.rangePromoted' | translate }}
        </ion-label>

        <ion-input
          type="tel"
          [ngClass]="{ 'disabled-box': listingClass === 'PROMOTED', 'margin-top-21': listingClass !== 'PROMOTED' }"
          placeholder="{{ 'global.listingPricing.highRange' | translate }}"
          (ionChange)="checkCharacters($event)"
          (ionBlur)="leaseableAreatTotalBlur($event)"
          formControlName="leaseableAreaTotal"
          class="input-border"
          inputmode="decimal"
        >
        </ion-input>
      </ion-col>
      <ion-col size="6" style="margin-bottom: 10px;">
        <ion-label style="white-space: nowrap;"
          >{{ 'global.listingPricing.measurement' | translate }} <span class="blue">*</span></ion-label
        >
        <span class="width-full my-listing-company">
          <ionic-selectable
            style="border: 1px solid lightgrey;"
            (onChange)="standardChange($event)"
            formControlName="measurementStandards"
            [items]="measurementStandards"
            itemValueField="id"
            itemTextField="measurement"
            [hasVirtualScroll]="true"
            closeButtonText="{{ 'Cancel' | translate }}"
            placeholder=""
            closeButtonText="{{ 'Cancel' | translate }}"
            #measurementComponent
          >
          </ionic-selectable>
        </span>
      </ion-col>
    </ion-row>

    <ion-row class="full-width margin-bottom-15 border">
      <ion-col class="padding-unset" size="4" class="align-checkbox">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.negRentableArea }">
          {{ 'global.listingPricing.rent' | translate }}
        </ion-label>
      </ion-col>
      <ion-col size="8" class="checkbox-flex-end">
        <ion-checkbox class="checkbox-styling" (click)="checkMonthly()" formControlName="negRentableArea">
        </ion-checkbox>
        <ion-label translate="global.list-your-property.negotiable"></ion-label>
      </ion-col>

      <ion-col size="6">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.negRentableArea }" class="full-width">
          {{ 'global.listingPricing.monthlyAmount' | translate }} <span class="blue">*</span></ion-label
        >
        <ion-input
          (ionBlur)="monthlyRentBlur()"
          (ionChange)="checkCharacters($event)"
          formControlName="monthlyRent"
          type="tel"
          inputmode="decimal"
          [ngClass]="{ 'disabled-box': this.listingPriceForm.value.negRentableArea }"
          class="input-border"
        >
        </ion-input>
      </ion-col>

      <ion-col size="6">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.negRentableArea }" class="nowrap">
          {{ 'global.listingPricing.monthlyAmountPerM' | translate }}<sup>2</sup>
        </ion-label>
        <ion-input
          [ngClass]="{ 'disabled-box': this.listingPriceForm.value.negRentableArea }"
          (ionBlur)="updateMonthlyFee()"
          (ionChange)="checkCharacters($event)"
          formControlName="monthlyRentPerArea"
          type="tel"
          inputmode="decimal"
          class="input-border"
        >
        </ion-input>
      </ion-col>
    </ion-row>

    <ion-row class="full-width margin-bottom-15 border">
      <ion-col class="padding-unset" size="4" class="align-checkbox">
        <ion-label
          [ngClass]="{ disabled: this.listingPriceForm.value.negCondoFeeArea }"
          class="width-50"
          style="white-space: nowrap;"
          >{{ 'global.listingPricing.condoFee' | translate }}
        </ion-label>
      </ion-col>
      <ion-col size="8" class="checkbox-flex-end">
        <ion-checkbox class="checkbox-styling" (click)="checkMonthlyCondo()" formControlName="negCondoFeeArea">
        </ion-checkbox>
        <ion-label translate="global.list-your-property.negotiable"></ion-label>
      </ion-col>

      <ion-col size="6">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.negCondoFeeArea }" class="full-width">
          {{ 'global.listingPricing.monthlyAmount' | translate }} <span class="blue">*</span>
        </ion-label>
        <ion-input
          formControlName="monthlyCondoFee"
          (ionBlur)="condoFeeBlur()"
          (ionChange)="checkCharacters($event)"
          type="tel"
          inputmode="decimal"
          class="input-border"
          [ngClass]="{ 'disabled-box': this.listingPriceForm.value.negCondoFeeArea }"
        >
        </ion-input>
      </ion-col>

      <ion-col size="6">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.negCondoFeeArea }" class="nowrap">
          {{ 'global.listingPricing.monthlyAmountPerM' | translate }}<sup>2</sup></ion-label
        >
        <ion-input
          (ionBlur)="updateMonthlyCondoFee()"
          (ionChange)="checkCharacters($event)"
          formControlName="monthlyCondoFeePerArea"
          type="tel"
          inputmode="decimal"
          class="input-border"
          [ngClass]="{ 'disabled-box': this.listingPriceForm.value.negCondoFeeArea }"
        >
        </ion-input>
      </ion-col>
    </ion-row>

    <ion-row class="full-width margin-bottom-15 border">
      <ion-col class="padding-unset" size="4" class="align-checkbox">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.negIPTUArea }">IPTU </ion-label>
      </ion-col>
      <ion-col size="8" class="checkbox-flex-end">
        <ion-checkbox class="checkbox-styling" (click)="checkMonthlyIPTU()" formControlName="negIPTUArea">
        </ion-checkbox>
        <ion-label translate="global.list-your-property.negotiable"></ion-label>
      </ion-col>

      <ion-col size="6">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.negIPTUArea }" class="full-width">
          {{ 'global.listingPricing.monthlyAmount' | translate }} <span class="blue">*</span>
        </ion-label>
        <ion-input
          type="tel"
          inputmode="decimal"
          [ngClass]="{ 'disabled-box': this.listingPriceForm.value.negIPTUArea }"
          (ionBlur)="iptuFeeBlur()"
          (ionChange)="checkCharacters($event)"
          formControlName="monthlyIptu"
          class="input-border"
        >
        </ion-input>
      </ion-col>

      <ion-col size="6">
        <ion-label [ngClass]="{ disabled: this.listingPriceForm.value.negIPTUArea }" class="nowrap">
          {{ 'global.listingPricing.monthlyAmountPerM' | translate }}<sup>2</sup>
        </ion-label>
        <ion-input
          type="tel"
          inputmode="decimal"
          [ngClass]="{ 'disabled-box': this.listingPriceForm.value.negIPTUArea }"
          (ionBlur)="updateIptuFee()"
          (ionChange)="checkCharacters($event)"
          formControlName="monthlyIptuPerArea"
          class="input-border"
        >
        </ion-input>
      </ion-col>
    </ion-row>
  </ion-row>
</form>
