import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { BackwardGuard } from '@app/guards';
import { Shell } from '@app/shell/shell.service';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';
const ɵ0 = { title: extract('Company Detail'), shouldReuse: false }, ɵ1 = { title: extract('Company Detail'), shouldReuse: false }, ɵ2 = { title: extract('Company Detail'), shouldReuse: false }, ɵ3 = { title: extract('Company Detail'), shouldReuse: false }, ɵ4 = { title: extract('Company Detail'), shouldReuse: false }, ɵ5 = { title: extract('Company Detail'), shouldReuse: false }, ɵ6 = { title: extract('Company Detail'), shouldReuse: false };
const routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    Shell.childRoutesRunningGuardsResolversAlways([
        {
            path: `${AppRouteNames.COMPANY_ROUTE}/:name/:companyId`,
            component: CompanyDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ0
        },
        {
            path: `${AppRouteNames.COMPANY_ROUTE}/:name/:companyId/en/:language`,
            component: CompanyDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ1
        },
        {
            path: `${AppRouteNames.COMPANY_ROUTE}/:name/:companyId/pt-br/:language`,
            component: CompanyDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ2
        },
        {
            path: `${AppRouteNames.COMPANY_ROUTE}/:name/:companyId/lang/:language`,
            component: CompanyDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ3
        },
        {
            path: `${AppRouteNames.COMPANY_ROUTE}/:name/:companyId/:language`,
            component: CompanyDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ4
        },
        {
            path: `${AppRouteNames.COMPANY_ROUTE_CL}/:name/:companyId`,
            component: CompanyDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ5
        },
        {
            path: `${AppRouteNames.COMPANY_ROUTE_CL}/:name/:companyId/:language`,
            component: CompanyDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ6
        }
    ])
];
export class CompanyRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
