<ion-content>
  <div class="detail-block-background" style="height: 100%;">
    <div style="max-width: 800px; background: white; margin: 0 auto; height: 100%;">
      <app-navigation-bar title="global.purchase-options.buyYour" [standardBackButton]="true"></app-navigation-bar>
      <!--
  <ion-row class="padding-top-10">
    <ion-col size="2">
      <ion-button (click)="goBack()" fill="clear">
        <ion-icon class="signup-icon font-size-30" color="primary" name="arrow-round-back"></ion-icon>
      </ion-button>
    </ion-col>
    <span class="market-list">
      {{ 'global.purchase-options.list' | translate }}
      <span> SP<fa-icon class="img-spot" [icon]="faMapMarkerAlt" size="md"></fa-icon>T </span>
    </span>
  </ion-row> -->

      <ion-row class="padding-10" style="padding-top: 40px;">
        <ion-text class="review-cart" translate="global.purchase-options.reviewOrder"></ion-text>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-row class="background">
            <ion-col class="left" size="3">
              <p class="margin-bottom-u" translate="global.purchase-options.items"></p>
            </ion-col>

            <ion-col class="center" size="3">
              <p class="margin-bottom-u" translate="global.purchase-options.premium"></p>
            </ion-col>

            <ion-col class="right" size="4">
              <p class="margin-bottom-u" translate="global.purchase-options.value"></p>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-row *ngFor="let cartItem of cart; index as i" class="width-100">
          <ion-col>
            <ion-row class="flex-columns">
              <!-- repeat over added cart items -->
              <ion-col class="left" style="bottom: -7px;" size="3">
                <p class="nowrap">
                  {{ cartItem.numOfListings }} <span translate="global.purchase-options.listings"></span>
                </p>
              </ion-col>

              <ion-col class="center" size="3">
                <ion-checkbox [(ngModel)]="upgrade[i]" (click)="updateListing(upgrade[i], i)"> </ion-checkbox>
              </ion-col>

              <ion-col class="left" style="bottom: -7px;" size="4">
                <p style="text-align: right" *ngIf="!cartItem.upgrade">
                  <span>R$ {{ cartItem.listingPricePerMonth | localNumber: '1.2-2' }}</span>
                </p>
                <p style="text-align: right" *ngIf="cartItem.upgrade">
                  <span>R$ {{ cartItem.premiumPrice | localNumber: '1.2-2' }}</span>
                </p>
              </ion-col>

              <ion-col class="trash" size="2">
                <ion-icon
                  class="font-size-20"
                  style="float: right; cursor: pointer;"
                  (click)="removeFromCart(cartItem.id, i)"
                  name="trash"
                >
                </ion-icon>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row class="pointer" (click)="addListing()">
          <ion-col size="2">
            <ion-icon color="secondary" class="font-size-20" name="add-circle-outline"></ion-icon>
          </ion-col>
          <ion-col size="10" class="nowrap">
            <p class="ion-color-secondary" translate="global.purchase-options.listAnother"></p>
          </ion-col>
        </ion-row>
      </ion-row>

      <p
        style="text-align: center; margin-top: 10px;"
        translate="global.purchase-options.pickCompanyAndUser"
        *ngIf="spotAdmin"
      ></p>

      <ion-row *ngIf="spotAdmin" class="full-width margin-top-5" style="margin-top: 10px;">
        <ion-col size="12">
          <ion-label>{{ 'global.purchase-options.company' | translate }}<span class="blue">*</span></ion-label>
          <span class="width-full my-listing-company">
            <ionic-selectable
              style="border: 1px solid lightgrey;"
              [items]="allCompanies"
              itemValueField="id"
              itemTextField="name"
              [disabled]="!spotAdmin"
              [(ngModel)]="selectCompany"
              [hasVirtualScroll]="true"
              searchPlaceholder="{{ 'global.menu.search' | translate }}"
              closeButtonText="{{ 'Cancel' | translate }}"
              placeholder="Company"
              [canSearch]="true"
              (onChange)="portChange($event)"
              #portComponent
            >
            </ionic-selectable>
          </span>
        </ion-col>
      </ion-row>

      <ion-row
        *ngIf="admin || spotAdmin"
        class="full-width margin-top-5"
        style="margin-top: 10px;
    margin-bottom: 10px;"
      >
        <ion-col size="12">
          <ion-label>{{ 'global.my-list-menu.listingAgent' | translate }}</ion-label>
          <span class="width-full my-listing-company">
            <ionic-selectable
              style="border: 1px solid lightgrey;"
              [(ngModel)]="offeredByUserId"
              [items]="users"
              itemValueField="id"
              [disabled]="!selectCompany || !spotAdmin"
              itemTextField="firstNameAndLastName"
              [hasVirtualScroll]="true"
              placeholder="Agent"
              [canSearch]="true"
              searchPlaceholder="{{ 'global.menu.search' | translate }}"
              closeButtonText="{{ 'Cancel' | translate }}"
              clearButtonText="{{ 'Clear' | translate }}"
              [canClear]="true"
              (onChange)="nameChange($event)"
              #agentComponent
            >
            </ionic-selectable>
          </span>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="totalPrice" class="background marg-both">
        <ion-col size="3"></ion-col>
        <ion-col size="3" style="text-align: right"> </ion-col>
        <ion-col size="4">
          <p class="total-value">
            <span style="text-align: left;"><span>Total:</span> </span>
            <span>R$ {{ totalPrice | localNumber: '1.2-2' }}</span>
          </p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6" class="margin-t-40 left">
          <ion-button color="secondary" (click)="back()">{{ 'global.purchase-options.back' | translate }} </ion-button>
        </ion-col>
        <ion-col size="6" class="margin-t-40 right">
          <ion-button
            [disabled]="!cart.length || (spotAdmin && !offeredByUserId && !selectCompany)"
            color="primary"
            (click)="skipPayment()"
            *ngIf="spotAdmin"
            >{{ 'global.purchase-options.issueCredits' | translate }}
          </ion-button>
          <ion-button
            [disabled]="!cart.length"
            color="primary"
            translate="global.purchase-options.proceed"
            (click)="navigateToPaymentPage()"
          ></ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
  <!-- <ion-row class="margin-lr" [ngClass]="{ 'cart-description': !isMobile }">
    <h6 translate="global.purchase-options.upgradeHeader"></h6>
    <p translate="global.purchase-options.upgradeOne"></p>
  </ion-row> -->
</ion-content>
