import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { SearchViewsType, SpotBuildingSearchRequest } from '@app/models/spot-buildings/spot-building-search.model';
import { MoreFilterCheckboxes, SpotBuildingMainSearchOptions } from '@app/models/spot-buildings/spot-buildings-types';
import { BreadcrumbOptions } from '@app/shared/breadcrumb/breadcrumb.component';
import { SpotBuildingsSearchService } from '@app/spot-buildings/services/spot-buildings-search.service';
import {
  faRandom,
  faSearch,
  faSearchLocation,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faSave,
  faMap,
  faList,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { SearchTagsService } from '../../../core/search-tags.service';
import { SpotBuildingDetailDTO } from '../../../models/spot-buildings/spot-buildings.model';
import { I18nService } from '../../../core/i18n.service';
import { DuploMapOptions, PropertyTypeMeta, PROPERTY_TYPES } from '@app/models';
import { ListingOfferType } from '@app/models/transaction-type.model';
import { IonContent } from '@ionic/angular';
import { PaginationComponent } from 'ngx-bootstrap';
import { SpotBuildingsSortDTO } from '../../../models/spot-buildings/spot-buildings-sort.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SearchQueryParams } from '@app/models/spot-buildings/home-search.model';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
import { Subscription } from 'rxjs';
import { ListingType } from '@app/models/transaction-type.model';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { SpotSearchService } from '../../services/spot-search.service';
import { SpotSearchDTO } from '../../../models/spot-search.model';
import { CommonGenericService } from '../../../core/common.service';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
import { SaveChangeModalComponent } from '@app/shared/save-change-prompt-modal/save-change-modal.component';
import { SaveModalActions, SaveModalTypes } from '@app/models/shared/shared-interfaces.model';
import { LoginFormDialogService } from '@app/shared/login';
import { AuthenticationService } from '@app/core/auth';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from '@app/core/seo';
import { environment } from '@env/environment';
import { Location } from '@angular/common';
import { SavedSearchDTO } from '../../../models/saved-search-dashboard.model';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { AppRouteNamesService } from '@app/core/helpers/app-route-names.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('Search-Spots');
@Component({
  selector: 'spot-buildings-search',
  templateUrl: './spot-buildings-search.component.html',
  styleUrls: ['./spot-buildings-search.component.scss']
})
export class SpotBuildingsSearchComponent implements OnInit, OnDestroy {
  @ViewChild('spotBuildingCards', { static: false }) spotBulidingCardsContainer: IonContent;
  @ViewChild('contentBlock', { static: false }) contentBlock: IonContent;
  @ViewChild('spotBuildingPagination', { static: false }) paginator: PaginationComponent;

  views: SearchViewsType = { map: 'global.search.viewMap', list: 'global.search.viewList' };
  currentView: SearchViewsType[keyof SearchViewsType] = this.views.list;
  topMap = 0;

  // HEADER
  screenWidth: number;
  outerScreenHeight: number;
  outerScreenWidth: number;
  spotBuildingsResultsHeight: number;
  searchModel: SpotBuildingMainSearchOptions;
  faSearch: any;
  faSortAmountUp: any;
  faSortAmountDown: any;
  displayScrollToTop: boolean = false;
  breadcrumbNavOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };
  locationIcon: any;
  buildingTypeTranslated: string;
  propertyTypeText: string;
  propertyTypes: PropertyTypeMeta[] = [...PROPERTY_TYPES];
  //Map
  mapOptions: DuploMapOptions = {
    zoom: 15,
    singleMarker: false,
    ignoreZoom: false,
    geopositionControl: true
  };
  hasGeoLocationError: boolean = false;
  clientPosition: any;
  geoLocationErrorCode: string;
  // Location
  locationFilters: any;
  states: any;
  cities: any;
  allCities: any;
  isSpotNearMeSearch: boolean;
  spotBuildingSearchRequest: SpotBuildingSearchRequest;
  selectedStateName: string;
  selectedCityName: string;
  checkBoxValues: MoreFilterCheckboxes;
  // Search
  currentPage: number = 1;
  searching: boolean = false;
  pageSize: number = 10;
  maxSizePag: number = 4;
  firstLbl: string;
  lastLbl: string;
  // Results
  totalSpotBuildingsCount: number = 0;
  allSpotBuildings: SpotBuildingDetailDTO[] = [];
  featuredSpotBuildings: SpotBuildingDetailDTO[];
  regularSpotBuildings: SpotBuildingDetailDTO[];
  selectedMarkerBuilding: SpotBuildingDetailDTO;
  allListingIds: number[];
  spotMarkers: any;
  noListingsFoundMessage: string;
  stepValue: number = 20;
  previousScrollPost: number = this.stepValue;
  paginationHeader: string = '';
  justLoaded: boolean = true;
  faSortIcon: any;
  faCompactDisc: IconDefinition;
  faMap: IconDefinition;
  faList: IconDefinition;
  faSave: IconDefinition;
  selectionSubscription: Subscription;
  topPropertiesCount: number = 0;
  otherPropertiesCount: number = 0;
  totalPropertiesCount: number = 0;
  loginSubscription: Subscription;
  customLoginText: Array<string>;
  translateSubscription: Subscription;
  isLoggedIn: boolean;

  constructor(
    private popOverCtrlHelper: PopoverCtrlHelper,
    private spotBuildingSearchService: SpotBuildingsSearchService,
    private searchTagService: SearchTagsService,
    private i18nService: I18nService,
    private activatedRoute: ActivatedRoute,
    private nearbyListingSvc: NearbyListingSvc,
    private spotSearchSrv: SpotSearchService,
    private commonService: CommonGenericService,
    private loginFormDialog: LoginFormDialogService,
    private router: Router,
    private auth: AuthenticationService,
    private _ts: TranslateService,
    private metaService: MetaService,
    private _location: Location,
    private routingHelperSrv: RoutingHelperService,
    private appRouteNames: AppRouteNamesService
  ) {
    this.screenWidth = window.innerWidth;
    this.outerScreenWidth = window.outerWidth;

    if (this.screenWidth < 500) {
      this.maxSizePag = 3;
    }

    this.outerScreenHeight = window.outerHeight;
    this.resetSearchModel();
    this.faSearch = faSearch;
    this.locationIcon = faSearchLocation;
    this.spotBuildingsResultsHeight = parseInt(
      window.getComputedStyle(document.documentElement).getPropertyValue('--spot-building-results-height')
    );
    this.faSortAmountDown = faSortAmountDownAlt;
    this.faSortAmountUp = faSortAmountUpAlt;
    this.faSortIcon = faRandom;
    this.faMap = faMap;
    this.faList = faList;
    this.faSave = faSave;
  }

  @HostListener('window:beforeunload')
  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    if (event) {
      this.screenWidth = event.target.innerWidth;
      this.outerScreenWidth = event.target.outerWidth;
    }
    if (this.screenWidth < 500) {
      this.maxSizePag = 3;
    }
  }

  private resetSearchModel() {
    this.searchModel = {
      keywordText: '',
      propertyType: '',
      listingType: '',
      stateId: null,
      cityId: null,
      propertySubTypes: null,
      spotSetName: null,
      buildingName: null
    };
  }

  public async ngOnInit() {
    this.metaService.setHomeMetatags();
    const onlyMetaTags = await this.metaService.onlyMetatags();
    if (onlyMetaTags) {
      return;
    }
    this.firstLbl = this.i18nService.get('global.first');
    this.lastLbl = this.i18nService.get('global.last');
    this.initializeSpotSearchRequest();
    this.subscribeToMarkerSelection();
    window.addEventListener('scroll', this.scrollEvent, true);
    this.buildingTypeTranslated = this.i18nService.getTranslation('global.menu.entities.buildingType');
    this.initAsync();
    this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => {
      this.isLoggedIn = val;
    });
    this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe((res: Array<string>) => {
      this.customLoginText = res;
    });
    this.justLoaded = true;
  }

  private subscribeToMarkerSelection() {
    this.selectionSubscription = this.spotBuildingSearchService.selectMarker$.subscribe(async listing => {
      let selectedSpotBuilding = this.allSpotBuildings.find(spotBuilding => spotBuilding.id === listing.listingIds[0]);

      if (selectedSpotBuilding) {
        this.selectedMarkerBuilding = selectedSpotBuilding;
        return;
      }

      [selectedSpotBuilding] = await this.spotBuildingSearchService.getSpotBuildingDetails(1, 1, [
        listing.listingIds[0]
      ]);

      if (selectedSpotBuilding) {
        this.selectedMarkerBuilding = selectedSpotBuilding;
      }
    });
  }

  private unsubscribeFromMarkerSelection() {
    if (this.selectionSubscription) {
      this.selectionSubscription.unsubscribe();
    }
  }

  private async updateURLBasedOnDTO() {
    //const currentUrlObject = this.activatedRoute.snapshot.url;
    var response: any = await this.spotBuildingSearchService.getUrlFromListingsSearchDto(
      this.spotBuildingSearchRequest
    );
    let lang = this.i18nService.getCurrentLanguage();
    response.url = JSON.parse(response.url);
    let newUrl = response.url[lang];

    if (response.keyword) newUrl = `${response.url[lang]}${response.keyword ? '?keyword=' + response.keyword : ''}`;

    if (response.spotSet)
      newUrl =
        `${response.url[lang]}/${this.appRouteNames.getFilterRouteName()}/${lang}` +
        '?' +
        this.appRouteNames.getSpotSetRouteName() +
        '=' +
        response.spotSet;

    if (response.building) newUrl = `${response.url[lang]}${response.building ? '?building=' + response.building : ''}`;

    if (this.i18nService.currentUrlContainsCurrentLanguage()) {
      this._location.go(
        this.i18nService.addCurrentLanguageToPath(`${this.routingHelperSrv.getSpotSearchURL()}${newUrl}`)
      );
    } else {
      this._location.go(`${this.routingHelperSrv.getSpotSearchURL()}${newUrl}`);
    }
  }

  private setSpotRequest(dto: SpotBuildingSearchRequest) {
    this.spotBuildingSearchRequest = dto;
  }

  private async buildSearchDTOBasedOnURL() {
    // GET DTO from URL
    let searchURL = null;
    try {
      const homeFilters = this.spotBuildingSearchService.readSearchDTOFromStorage(
        SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS,
        null
      );
      if (this.activatedRoute.snapshot.url.length > 1) {
        searchURL = this.activatedRoute.snapshot.url.slice(1).join('/');
        searchURL = this.i18nService.removeLanguageFromURL(searchURL);
        if (!searchURL || searchURL.length == 0) {
          return;
        }
        const buildingNameFilter = this.activatedRoute.snapshot.queryParamMap.get('building');
        await this.spotBuildingSearchService
          .findSearchDTOFromURL(
            searchURL,
            this.activatedRoute.snapshot.queryParamMap.get('keyword'),
            buildingNameFilter,
            this.activatedRoute.snapshot.queryParamMap.get(this.appRouteNames.getSpotSetRouteName())
          )
          .then((dtoFromURL: any) => {
            if (dtoFromURL) {
              this.setSpotRequest(dtoFromURL);
              if (buildingNameFilter) {
                this.spotBuildingSearchRequest.buildingName = buildingNameFilter;
              }
              if (homeFilters) {
                const tempModel: SpotBuildingMainSearchOptions = this.setQueryParamsFilters(homeFilters);
                if (tempModel) {
                  this.spotBuildingSearchRequest.neighborhood = tempModel.neighborhood || null;
                  this.spotBuildingSearchRequest.keyword = tempModel.neighborhood
                    ? null
                    : this.spotBuildingSearchRequest.keyword;
                  this.spotBuildingSearchRequest.stateId = tempModel.stateId ? tempModel.stateId : null;
                  this.spotBuildingSearchRequest.cityId = tempModel.cityId ? tempModel.cityId : null;
                }
              }
              this.spotBuildingSearchService.removeFilterPreferencesFrom(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS);
              this.updateSearchModelFromSearchDTO();
              this.searchFilterAction();
            }
          });
      }
    } catch (error) {
      const state: any = {
        badURL: `${environment.spotServerUrl}${this.routingHelperSrv.getSpotSearchURL()}/${searchURL}`
      };
      console.error('Error search page', error, searchURL);
      this.router.navigateByUrl('/not-found', { state: state });
    }
  }

  private async initializeSpotSearchRequest() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const savedFilters = this.spotBuildingSearchService.readSearchDTOFromStorage(
      SelectedFilterKey.SPOT_BUILDING_SEARCH,
      null
    );

    this.spotBuildingSearchRequest = new SpotBuildingSearchRequest();
    this.setDefaultFilterValues();

    const params = this.activatedRoute.snapshot.params;

    if (params && params['listingType'] && params['propertyType']) {
      this.buildSearchDTOBasedOnURL();
    } else {
      let keepRunning = true;
      if (params && params['savedSearchId'] && params['userId']) {
        const savedSearchId = params['savedSearchId'];
        const userId = params['userId'];

        let savedSearch: SavedSearchDTO = await this.spotBuildingSearchService.findSavedSearchBy(userId, savedSearchId);
        if (savedSearch && savedSearch.json) {
          this.loadSavedSearch(savedSearch.json);
          keepRunning = false;
        }
      }

      if (!keepRunning) {
        return;
      }

      if (queryParams.keyword) {
        this.setSpotRequest(savedFilters == null ? {} : savedFilters);
        this.searchModel.keywordText = queryParams.keyword;
      } else if (queryParams && queryParams.filters) {
        this.setQueryParamsFilters(queryParams);
      } else if (queryParams && queryParams.spotset) {
        this.resetSearchModel();
        this.searchModel.spotSetName = queryParams.spotset;
      } else if (queryParams && queryParams.building) {
        this.resetSearchModel();
        this.searchModel.buildingName = queryParams.building;
      } else if (savedFilters) {
        this.setSpotRequest(savedFilters);
        this.updateSearchModelFromSearchDTO();
      }

      this.searchFilterAction();
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
    this.unsubscribeFromMarkerSelection();
  }

  setDefaultFilterValues() {
    if (this.searchModel) {
      this.searchModel.listingType = 'L';
      this.setPropertyType(2001);
    }
  }

  getQueryParamsFilters(filters: any): { filters: SpotBuildingMainSearchOptions; advanced?: boolean } {
    if (!filters) return { filters: this.searchModel };

    const { listingType, propertyType, keyword, advanced } = filters;
    let stateId = null;
    if (keyword && (keyword.type === 'city' || keyword.type === 'state')) {
      stateId = keyword.type === 'city' ? keyword.stateId : keyword.id;
    }
    return {
      filters: {
        ...this.searchModel,
        listingType: listingType,
        propertyType: String(propertyType),
        cityId: keyword && keyword.type === 'city' ? keyword.id : null,
        neighborhood: keyword && keyword.type === 'neighborhood' ? keyword.name : null,
        stateId
      },
      advanced: advanced
    };
  }

  setQueryParamsFilters(homeFilters: any) {
    const { filters, advanced } = this.getQueryParamsFilters(homeFilters);
    //this.searchModel = filters;
    if (advanced) {
      this.openMorePopover();
    }
    return filters;
  }

  scrollEvent = (event: any): void => {
    event.stopPropagation();

    event.preventDefault();
    event.stopImmediatePropagation();

    const scrollPosition = event.srcElement.scrollTop;
    const delta = (scrollPosition / this.outerScreenHeight) * 100;

    this.displayScrollToTop = delta > 50;

    /*
    let pos = 0;
    pos = (this.outerScreenHeight * 3 * delta) / 100;

    if (scrollPosition > 70) {
      if (delta > 50) {
        this.topMap = this.topMap - 50;
      } else {
        this.topMap = scrollPosition - 70;
      }
    }

    if (this.topMap < 0 || this.topMap >= 300) {
      this.topMap = 0;
    }

   // console.log(`Inner ${window.innerHeight}`);
    //console.log(`Scroll Position: ${scrollPosition} - % Map Top: ${this.topMap} - Delta: ${delta}`);

   // this.spotBulidingCardsContainer.scrollToPoint(0, pos, 0);

    //this.mapResultsContainer.scrollToTop();
    */
  };

  private async initAsync() {
    this.allCities = await this.spotBuildingSearchService.getCities();
    this.states = await this.spotBuildingSearchService.getStates();
    this.checkBoxValues = await this.spotBuildingSearchService.initCheckboxFields();
  }

  /* APPLY FILTERS */
  searchFilterAction() {
    this.currentPage = 1;
    this.scrollToTop();
    this.searching = true;
    this.searchSpotBuildingsChangeListener();
  }

  clearSearchAction(avoidAutoSearch?: boolean) {
    this.clearSearchModel();

    this.setGeoPositionValuesToRequest(null);
    this.searchTagService.clearSearchTags();
    this.clearSpotBuildingRequest();
    this.noListingsFoundMessage = undefined;
    this.clearMapAndResults();
    this.justLoaded = true;

    if (!avoidAutoSearch) this.searchFilterAction();
  }

  private clearMapAndResults() {
    this.allSpotBuildings = [];
    this.spotMarkers = undefined;
    this.searching = false;
    this.totalSpotBuildingsCount = 0;
    this.allListingIds = [];

    this.currentPage = 1;
    this.topPropertiesCount = 0;
    this.otherPropertiesCount = 0;
    this.totalPropertiesCount = 0;
  }

  private clearSearchModel() {
    this.searchModel = {
      cityId: null,
      stateId: null,
      propertySubTypeIds: null,
      propertyType: '2001',
      propertySubTypes: null,
      listingType: 'L',
      keywordText: null,
      spotSetName: null,
      buildingName: null
    };
  }

  private clearSpotBuildingRequest() {
    this.allSpotBuildings = [];
    this.setSpotRequest(this.searchTagService.clearSearchTagsFromDTO(this.spotBuildingSearchRequest));
    if (this.spotBuildingSearchRequest) {
      this.spotBuildingSearchRequest.buildingTypes = null;
      this.spotBuildingSearchRequest.listingLeaseOrSale = null;
      this.spotBuildingSearchRequest.stateId = null;
      this.spotBuildingSearchRequest.cityId = null;
      this.spotBuildingSearchRequest.keyword = null;
      this.spotBuildingSearchRequest.spotTitlesOrAddresses = null;

      this.clearPropertySubtypes();
    }
  }

  private clearPropertySubtypes() {
    this.searchModel.propertySubTypes = null;
    this.spotBuildingSearchRequest.propertySubTypes = null;
    this.spotBuildingSearchRequest.propertySubtypeIds = null;
  }

  /* APPLY FILTERS*/

  searchSpotBuildingsChangeListener() {
    this.updateSearchDTOFromModal();
    this.searchBuildingListings();
  }

  private updateSearchDTOFromModal() {
    if (this.spotBuildingSearchRequest && this.searchModel) {
      this.spotBuildingSearchRequest.stateId = this.searchModel.stateId ? this.searchModel.stateId : null;
      this.spotBuildingSearchRequest.cityId = this.searchModel.cityId ? this.searchModel.cityId : null;
      this.spotBuildingSearchRequest.neighborhood = this.searchModel.neighborhood || null;
      this.spotBuildingSearchRequest.keyword = this.searchModel.keywordText ? this.searchModel.keywordText : null;
      this.spotBuildingSearchRequest.listingLeaseOrSale = this.searchModel.listingType
        ? [this.searchModel.listingType]
        : null;

      this.spotBuildingSearchRequest.buildingTypes = this.searchModel.propertyType
        ? [this.searchModel.propertyType]
        : null;

      if (this.searchModel.propertySubTypes) {
        this.spotBuildingSearchRequest.propertySubTypes = this.searchModel.propertySubTypes;
        this.spotBuildingSearchRequest.propertySubtypeIds = this.searchModel.propertySubTypes.map((t: any) => t.id);
      } else {
        this.spotBuildingSearchRequest.propertySubtypeIds = null;
      }

      this.spotBuildingSearchRequest.spotSetName = this.searchModel.spotSetName;
      this.spotBuildingSearchRequest.buildingName = this.searchModel.buildingName;
      this.spotBuildingSearchRequest.spotSetId = this.searchModel.spotSetId;
    }
  }

  private updateSearchModelFromSearchDTO() {
    this.clearSearchModel();

    if (this.spotBuildingSearchRequest && this.searchModel) {
      this.searchModel.stateId = this.spotBuildingSearchRequest.stateId ? this.spotBuildingSearchRequest.stateId : null;
      this.searchModel.cityId = this.spotBuildingSearchRequest.cityId ? this.spotBuildingSearchRequest.cityId : null;
      this.searchModel.neighborhood = this.spotBuildingSearchRequest.neighborhood || null;
      this.searchModel.keywordText = this.spotBuildingSearchRequest.keyword
        ? this.spotBuildingSearchRequest.keyword
        : null;
      this.searchModel.listingType = this.spotBuildingSearchRequest.listingLeaseOrSale
        ? this.spotBuildingSearchRequest.listingLeaseOrSale[0]
        : null;

      this.searchModel.propertyType = this.spotBuildingSearchRequest.buildingTypes
        ? this.spotBuildingSearchRequest.buildingTypes[0]
        : null;

      if (this.spotBuildingSearchRequest.propertySubTypes) {
        this.searchModel.propertySubTypes = this.spotBuildingSearchRequest.propertySubTypes;
      }
      this.searchModel.spotSetName = this.spotBuildingSearchRequest.spotSetName;
      this.searchModel.buildingName = this.spotBuildingSearchRequest.buildingName;
      this.searchModel.spotSetId = this.spotBuildingSearchRequest.spotSetId;
    }
  }

  applySortBy(type: string, direction: string) {
    if (this.allListingIds && this.allListingIds.length > 0) {
      const sortDTO: SpotBuildingsSortDTO = {
        sortBy: type,
        direction: direction,
        spotBuildingType: this.searchModel.listingType,
        spotIds: [...this.allListingIds]
      };
      if (direction === 'ASC') {
        this.faSortIcon = this.faSortAmountUp;
      } else {
        this.faSortIcon = this.faSortAmountDown;
      }
      this.setCurrentSort(sortDTO);

      if (sortDTO.spotIds) {
        this.spotBuildingSearchService.sortCurrentSpotBuildingIds(sortDTO).then((result: any) => {
          const sortedIds = result.body;
          this.currentPage = 1;
          this.findAllDetailsAndMapPins(sortedIds);
        });
      }
    }
  }

  clearSortSelection() {
    this.faSortIcon = faRandom;
    this.setCurrentSort(undefined);
    this.findAllDetailsAndMapPins(this.allListingIds);
  }

  setCurrentSort(sortBy: SpotBuildingsSortDTO) {
    this.spotBuildingSearchRequest.sortSpotsBy = sortBy;
  }

  getCurrentSort() {
    return this.spotBuildingSearchRequest ? this.spotBuildingSearchRequest.sortSpotsBy : '';
  }

  isActiveSort(type: string, direction: string) {
    const currentSortFilter = this.getCurrentSort();
    if (currentSortFilter) {
      return currentSortFilter.sortBy == type && currentSortFilter.direction == direction;
    }
    return false;
  }

  private async searchBuildingListings() {
    this.justLoaded = false;
    this.updateURLBasedOnDTO();
    this.printListingSearchAndSearchModel();
    this.clearSelectedMarker();
    await this.updateTags();

    await this.findAllSpotBuildingIds().then((result: any) => {
      this.allListingIds = result.body;
      this.findAllDetailsAndMapPins(this.allListingIds);
    });

    this.countSpotsByListingClass();
  }

  async findAllDetailsAndMapPins(spotIds: number[]) {
    this.totalSpotBuildingsCount = spotIds ? spotIds.length : 0;
    if (this.totalSpotBuildingsCount > 0) {
      this.getSpotBuildingsPaginated(spotIds);
      this.getMapPinsFromCurrentSearch();
    } else {
      this.clearMapAndResults();
      this.noListingsFoundMessage = 'Not found';
    }
  }

  async getSpotBuildingsPaginated(spotIds: number[], concatNewResult?: boolean) {
    await this.spotBuildingSearchService
      .getSpotBuildingDetails(this.currentPage, this.pageSize, spotIds)
      .then(result => {
        this.allSpotBuildings = [];
        if (concatNewResult) {
          let newListings: any = result;
          if (newListings && newListings.length) {
            this.allSpotBuildings = this.allSpotBuildings.concat(newListings);
          }
        } else {
          this.allSpotBuildings = result;
        }

        this.updateTags();
        this.paginationHeader = this.getPaginationText();
        this.splitSpotBuildingsByListingType();
      });
  }

  pageChanged(event: any): void {
    if (!event) {
      return;
    }
    this.currentPage = event.page;
    this.getSpotBuildingsPaginated(this.allListingIds);
    this.scrollToTop();
  }

  scrollToTop() {
    this.contentBlock ? this.contentBlock.scrollToTop() : null;
  }

  setCustomAttributes() {
    if (this.areThereSpotBuildings()) {
      const featuredCopy = { ...this.allSpotBuildings[0] };
      featuredCopy.listingType = 'FEATURED';
      featuredCopy.featured = true;
      this.allSpotBuildings.unshift(featuredCopy);
    }
  }

  splitSpotBuildingsByListingType() {
    this.featuredSpotBuildings = [];
    this.regularSpotBuildings = [];

    if (this.areThereSpotBuildings()) {
      this.featuredSpotBuildings = this.allSpotBuildings.filter(spot => spot.featured || spot.premium);
      if (!this.getCurrentSort()) {
        this.featuredSpotBuildings = this.sortPremiumAndGold();
      }
      this.regularSpotBuildings = this.allSpotBuildings.filter(spot => !spot.featured && !spot.premium);
    }
  }

  sortPremiumAndGold() {
    let platinumSpots = [...this.featuredSpotBuildings.filter(spot => spot.featured)];
    let goldSpots = [...this.featuredSpotBuildings.filter(spot => spot.premium)];
    this.featuredSpotBuildings = [];

    platinumSpots = this.sortSpotsByModifiedDate(platinumSpots);
    goldSpots = this.sortSpotsByModifiedDate(goldSpots);

    this.featuredSpotBuildings = [...platinumSpots, ...goldSpots];

    return this.featuredSpotBuildings;
  }

  sortSpotsByModifiedDate(spots: SpotBuildingDetailDTO[]) {
    return spots.sort((first, second) => second.modifiedDate.localeCompare(first.modifiedDate));
  }

  isThereFeaturedSpots() {
    return this.featuredSpotBuildings && this.featuredSpotBuildings.length > 0;
  }

  isThereRegularSpots() {
    return this.regularSpotBuildings && this.regularSpotBuildings.length > 0;
  }

  isFeaturedSpot(spotBuilding: SpotBuildingDetailDTO) {
    return spotBuilding.listingType && spotBuilding.listingType == 'FEATURED';
  }

  getMapPinsFromCurrentSearch() {
    const pageNumber: number = 1;
    this.hasGeoLocationError = false;
    this.spotBuildingSearchService
      .getSpotBulidingMapPins(this.spotBuildingSearchRequest, pageNumber)
      .then((result: any) => {
        const totalListingsCount = parseInt(result.headers.get('x-total-count'));
        this.noListingsFoundMessage = this.getNoResultMessage(totalListingsCount);
        //TODO Move everything to this component?
        this.spotMarkers = this.spotBuildingSearchService.createNearbyListingMarker(
          result.body,
          this.spotBuildingSearchRequest,
          this.isLoggedIn
        );
        this.searching = false;
      });
  }

  public getNoResultMessage(resultCount: number) {
    return resultCount > 0 ? '' : this.i18nService.get('global.search.noListingsFound');
  }

  async updateTags() {
    return await this.searchTagService.updateSearchTags(this.spotBuildingSearchRequest, this.isSpotNearMeSearch);
  }

  private async findAllSpotBuildingIds() {
    this.spotBuildingSearchService.saveSearchDtoOnStorage(
      SelectedFilterKey.SPOT_BUILDING_SEARCH,
      this.spotBuildingSearchRequest
    );
    return await this.spotBuildingSearchService
      .searchSpotBuildingIds(this.spotBuildingSearchRequest, 1)
      .then(async spotBulidingIdsResponse => {
        return spotBulidingIdsResponse;
      });
  }

  isFooterVisible(event: any) {
    this.displayScrollToTop = event.visible;
  }

  public showNoResultMessage(): boolean {
    const result = [this.noListingsFoundMessage && this.noListingsFoundMessage.length].every(
      (condition: any) => condition
    );
    return result;
  }

  public setButtonColor(width: number): string {
    return this.screenWidth >= width ? 'light' : 'secondary';
  }

  //Popovers
  async openLocationPopover(ev: Event) {
    this.locationFilters = {
      cities: this.cities,
      states: this.states,
      cityId: this.searchModel.cityId,
      stateId: this.searchModel.stateId,
      allCities: this.allCities,
      isSpotNearMeSearch: this.isSpotNearMeSearch
    };

    this.popOverCtrlHelper.openLocationPopover(ev, this.locationFilters, (popoverData: any) => {
      if (
        !popoverData.fromCancel &&
        (this.searchModel.stateId != popoverData.stateId ||
          this.searchModel.cityId != popoverData.cityId ||
          this.isSpotNearMeSearch !== popoverData.isSpotNearMeSearch)
      ) {
        this.searchModel.stateId = popoverData.stateId;
        this.searchModel.cityId = popoverData.cityId;
        this.selectedStateName = popoverData.stateName;
        this.selectedCityName = popoverData.cityName;
        this.isSpotNearMeSearch = popoverData.isSpotNearMeSearch;
        this.spotBuildingSearchRequest.stateId = this.searchModel.stateId;
        this.spotBuildingSearchRequest.cityId = this.searchModel.cityId;

        this.searchBuildingListings();
      }
    });
  }

  async openMorePopover(ev?: Event) {
    await this.popOverCtrlHelper.openMorePopoverForBuildingListing(
      ev,
      this.checkBoxValues,
      this.searchModel,
      this.spotBuildingSearchRequest,
      (data: any) => {
        this.setSpotRequest(data);
        this.searchModel.listingType = this.spotBuildingSearchRequest.listingLeaseOrSale[0];
        this.searchModel.propertyType = this.spotBuildingSearchRequest.buildingTypes[0];
        this.searchBuildingListings();
      }
    );
  }

  private setSelectedPropertyTypeToModel(newPropertyType: any, newPropertySubTypes: any) {
    const oldPropertyType = this.searchModel.propertyType;

    this.searchModel.propertyType = newPropertyType;

    const searchListings = this.haveSubTypesChanged(oldPropertyType, this.searchModel.propertyType);

    this.searchModel.propertySubTypes = newPropertySubTypes;

    this.populatePropertyTypeName();

    if (this.searchModel.propertyType === ListingOfferType.Coworking) {
      this.spotBuildingSearchRequest.listingLeaseOrSale = [ListingOfferType.Coworking];
      this.searchModel.listingType = ListingOfferType.Coworking;
    }
    if (
      oldPropertyType === ListingOfferType.Coworking &&
      (!this.searchModel.propertyType || this.searchModel.propertyType !== ListingOfferType.Coworking)
    ) {
      this.searchModel.listingType = null;
      this.spotBuildingSearchRequest.listingLeaseOrSale = null;
    }
  }

  async openNewPropertyTypePopover(ev: Event) {
    this.popOverCtrlHelper.openNewPropertyTypePopover(ev, this.spotBuildingSearchRequest, (popoverData: any) => {
      if (popoverData) {
        this.setSpotRequest(popoverData);

        if (this.spotBuildingSearchRequest.buildingTypes && this.spotBuildingSearchRequest.buildingTypes.length > 0) {
          this.setSelectedPropertyTypeToModel(popoverData.buildingTypes[0], popoverData.propertySubTypes);
          //this.searchModel.propertyType = this.spotBuildingSearchRequest.buildingTypes[0];
        }
      }
    });
  }

  populatePropertyTypeName() {
    if (this.searchModel.propertyType) {
      if (this.searchModel.propertyType === ListingOfferType.Coworking) {
        this.propertyTypeText = this.i18nService.get('global.list-your-property.coworking');
      } else {
        this.propertyTypes.forEach((element: any) => {
          if (this.searchModel.propertyType == element.id) {
            this.propertyTypeText = this.i18nService.get(element.name);
          }
        });
        if (this.searchModel.propertySubTypes && this.searchModel.propertySubTypes.length > 0) {
          this.propertyTypeText += ': ';
          let subtypeNames = this.searchModel.propertySubTypes.map((t: any) => t.name);
          this.propertyTypeText += subtypeNames.join(', ');
        }
      }
    } else {
      this.propertyTypeText = '';
    }
  }

  private haveSubTypesChanged(oldPropertyType: any, newPropertyType: any) {
    if (oldPropertyType == newPropertyType) {
      const newSubTypes = this.spotBuildingSearchRequest.propertySubTypes;
      const oldSubTypes = this.searchModel.propertySubTypes;

      if (!newSubTypes && !oldSubTypes) {
        return false;
      }

      return JSON.stringify(newSubTypes) !== JSON.stringify(oldSubTypes);
    }
    return false;
  }

  private printListingSearchAndSearchModel() {
    let listingsearchcopy = JSON.parse(JSON.stringify(this.spotBuildingSearchRequest));
    this.removeNullAttributes(listingsearchcopy);
    this.printLog(`Listing Search:  ${listingsearchcopy}`);

    let searchModelCopy = JSON.parse(JSON.stringify(this.searchModel));
    this.removeNullAttributes(searchModelCopy);
    this.printLog(`Search Model:  ${searchModelCopy}`);
  }

  private printLog(msg: string) {
    log.debug(msg);
  }

  private removeNullAttributes(obj: any) {
    return Object.keys(obj).forEach(k => obj[k] == null && delete obj[k]);
  }

  showResults() {
    return this.areThereSpotBuildings();
  }

  areThereSpotBuildings() {
    return this.allSpotBuildings && this.allSpotBuildings.length > 0;
  }

  /* TAGS */
  showTags() {
    const allTags = this.getAllCurrentTags();
    return allTags && allTags.length > 0;
  }

  getAllCurrentTags() {
    return this.searchTagService.getSearchTags();
  }

  checkTagNames(tag: any) {
    // console.log('Tag: ', tag);
    return this.searchTagService.checkTagNames(tag);
  }

  removeTag(fieldName: string, index: number) {
    if (fieldName && fieldName == 'spotsNearMe') {
      this.isSpotNearMeSearch = false;
      this.setGeoPositionValuesToRequest(null);
    }
    if (fieldName && fieldName == 'buildingName') {
      this.setDefaultFilterValues();
      this.updateSearchDTOFromModal();
    }

    this.searchTagService.removeTagFromModels(
      fieldName,
      index,
      this.searchModel,
      this.spotBuildingSearchRequest,
      this.checkBoxValues
    );

    this.searchBuildingListings();
  }
  /* END TAGS*/

  /* MAP */
  async searchByGeoposition(clientMarker: any) {
    if (clientMarker.position && clientMarker.map) {
      clientMarker.clientCoordinates = true;
      const clientCoordinates = {
        lat: -23.548322, //clientMarker.position.lng(),
        lng: -46.631155, //clientMarker.position.lat()
        clientCoordinates: true
      };
      //this.refreshSearchData(clientCoordinates);
      // console.log('Refresh Search Data', clientCoordinates);
    }
  }
  /* END MAP */

  getPaginationText() {
    if (this.areThereSpotBuildings()) {
      const totalPages = Math.ceil(this.totalSpotBuildingsCount / this.pageSize);
      return (
        this._ts.instant('global.search.viewingPage') +
        ` ${this.currentPage} of ${totalPages} (${this.allListingIds.length} ` +
        this._ts.instant('global.search.found') +
        ')'
      );
    }

    return '';
  }

  setListingLeaseOrSale(event: any) {
    this.searchModel.listingType = event;
    this.spotBuildingSearchRequest.listingLeaseOrSale = [this.searchModel.listingType];
  }

  setPropertyType(event: any, type?: string) {
    if (type) {
      this.setListingLeaseOrSale(type);
    } else {
      if (this.searchModel.listingType == 'C') {
        this.setListingLeaseOrSale('L');
      }
    }
    this.searchModel.propertyType = event;

    this.spotBuildingSearchRequest.buildingTypes = [this.searchModel.propertyType];
  }

  searchByListingType(event: any) {
    this.clearSpotBuildingRequest();
    this.setListingLeaseOrSale(event);
    this.searchFilterAction();
  }

  searchByPropertyType(event: any, type?: string) {
    this.clearSpotBuildingRequest();
    this.setPropertyType(event, type);
    if (this.isSpotNearMeSearch) {
      this.spotBuildingSearchRequest.radius = this.nearbyListingSvc.getSpotRadius(this.searchModel.propertyType);
    }
    this.searchFilterAction();
  }

  switchView() {
    this.clearSelectedMarker();
    if (this.currentView === this.views.list) {
      this.currentView = this.views.map;
      return;
    }
    this.currentView = this.views.list;
  }

  clearSelectedMarker() {
    this.selectedMarkerBuilding = null;
  }

  countSpotsByListingClass() {
    this.topPropertiesCount = 0;
    this.otherPropertiesCount = 0;
    this.totalPropertiesCount = 0;
    this.spotBuildingSearchService
      .countSpotsByListingClass(this.spotBuildingSearchRequest)
      .then((spotByTypes: Map<ListingType, number>) => {
        if (spotByTypes) {
          const basicCount = spotByTypes[ListingType.Basic] ? spotByTypes[ListingType.Basic] : 0;
          const featuredCount = spotByTypes[ListingType.Featured] ? spotByTypes[ListingType.Featured] : 0;
          const premiumCount = spotByTypes[ListingType.Premium] ? spotByTypes[ListingType.Premium] : 0;
          const promotedCount = spotByTypes[ListingType.Promoted] ? spotByTypes[ListingType.Promoted] : 0;

          this.topPropertiesCount = featuredCount + premiumCount + promotedCount + basicCount;
          this.otherPropertiesCount = promotedCount + basicCount;
        }
      });
  }

  initSpotsNearmeSearch() {
    this.isSpotNearMeSearch = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.searchByGeoLocation, this.showGeoLocationError);
    } else {
      const errorCode: any = { code: { POSITION_UNAVAILABLE: 'POSITION_UNAVAILABLE' } };
      this.showGeoLocationError(errorCode);
    }
  }

  private showGeoLocationError = (error: any) => {
    this.hasGeoLocationError = true;
    this.noListingsFoundMessage = this.nearbyListingSvc.getNoResultMessage(0);
    this.geoLocationErrorCode = this.nearbyListingSvc.getGeoLocationErrorCode(error);
  };

  private searchByGeoLocation = (position: any) => {
    this.hasGeoLocationError = false;
    this.clientPosition = this.nearbyListingSvc.getUserLocationCoordinate(
      position.coords,
      this.searchModel.propertyType
    );
    this.setGeoPositionValuesToRequest(this.clientPosition);
    this.searchBuildingListings();
  };

  private setGeoPositionValuesToRequest(clientPosition: any) {
    if (!clientPosition) {
      this.spotBuildingSearchRequest.latitude = undefined;
      this.spotBuildingSearchRequest.longitude = undefined;
      this.spotBuildingSearchRequest.radius = undefined;
      this.isSpotNearMeSearch = false;
      return;
    }

    this.spotBuildingSearchRequest.latitude = clientPosition.latitude;
    this.spotBuildingSearchRequest.longitude = clientPosition.longitude;
    this.spotBuildingSearchRequest.radius = clientPosition.radius;
    this.isSpotNearMeSearch = true;
  }

  async saveSearchAction(event: any) {
    event.stopPropagation();
    if (!this.isLoggedIn) {
      this.validateUserIsAuthenticated();
      return;
    }

    const actions: SaveModalActions = {
      leftButton: 'Cancel',
      rightButton: 'OK'
    };
    const modalProps: any = {
      backdropDismiss: true,
      showBackdrop: true,
      cssClass: 'save-info-modal',
      component: SaveChangeModalComponent,
      componentProps: {
        headerTitle: 'SaveSearch',
        bodyMessage: 'Guarde su busqueda',
        leftButtonLabel: 'Cancel',
        rightButtonLabel: 'Ok',
        action: actions,
        modalType: SaveModalTypes.SAVE_SEARCH
      }
    };
    const infoModal = await this.commonService.createModal(modalProps);
    infoModal.onWillDismiss().then((result: any) => {
      if (result && result.data) {
        const data = result.data;
        if (data.action == 'OK' && data.customInput) {
          this.updateCurrentSearch(data.customInput);
        }
      }
    });
    return infoModal.present();
  }

  async loadSearchAction(event: any) {
    event.stopPropagation();
    if (!this.isLoggedIn) {
      this.validateUserIsAuthenticated();
      return;
    }

    const savedSearches = await this.spotSearchSrv.getAll();

    const modalProps: any = {
      backdropDismiss: true,
      showBackdrop: true,
      cssClass: 'save-info-modal',
      component: SaveChangeModalComponent,
      componentProps: {
        headerTitle: 'LoadSearchTitle',
        leftButtonLabel: 'Cancel',
        rightButtonLabel: 'Ok',
        modalType: SaveModalTypes.LOAD_SEARCH,
        dropdownItems: savedSearches
      }
    };
    const infoModal = await this.commonService.createModal(modalProps);
    infoModal.onWillDismiss().then((result: any) => {
      if (result && result.data) {
        const data = result.data;
        if (data.action == 'OK' && data.selectedItem) {
          this.loadSavedSearch(data.selectedItem.json);
        }
      }
    });
    return infoModal.present();
  }

  public loadSavedSearch(jsonObject: string) {
    const searchRequestDTO = JSON.parse(jsonObject);
    this.searchTagService.clearSearchTags();
    this.setSpotRequest(searchRequestDTO);
    this.updateSearchModelFromSearchDTO();
    this.searchFilterAction();
  }

  private updateCurrentSearch(searchName: string) {
    this.commonService.deleteNullAttributesFrom(this.spotBuildingSearchRequest);
    let dto: SpotSearchDTO = {
      name: searchName,
      json: JSON.stringify(this.spotBuildingSearchRequest)
    };
    this.spotSearchSrv.update(dto);
  }

  validateUserIsAuthenticated(callback?: any) {
    if (!this.isLoggedIn) {
      this.openLoginDialog()
        .afterClosed()
        .subscribe(() => {
          if (this.isLoggedIn) {
            if (callback) {
              callback();
            }
          }
        });
    }
  }

  openLoginDialog() {
    const currentRoute: string = this.router.url;
    return this.loginFormDialog.openDialog({
      url: currentRoute,
      customText: this.customLoginText
    });
  }

  showPreviewSpotOnMobile() {
    return this.selectedMarkerBuilding && this.currentView === this.views.map;
  }
}
