import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService, I18nService } from '@app/core';
import { ListingPriceService } from '@app/core/listings';
export class ListingPriceComponent {
    constructor(registerService, _ts, langService, listingPriceService, i18NService) {
        this.registerService = registerService;
        this._ts = _ts;
        this.langService = langService;
        this.listingPriceService = listingPriceService;
        this.i18NService = i18NService;
        this.regex = '^(0*100{1,1}.?((?<=.)0*)?%?$)|(^0*d{0,2}.?((?<=.)d*)?%?)$';
        this.screenWidth = window.innerWidth;
    }
    ngOnInit() {
        this.initMeasurement();
        if (this.listing) {
            this.listing.isForLeaseSale === 'SALE'
                ? this.setValidation('S')
                : this.listing.isForLeaseSale === 'LEASE' || this.listing.isForLeaseSale === 'L'
                    ? this.setValidation('L')
                    : '';
        }
        else {
            this.setValidation('L');
        }
        this.setListingClass();
    }
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
    }
    initMeasurement() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.measurementStandards = yield this.getMeasurementStands();
            this.measurementStandards = yield this.getMeasurementTranslations(this.measurementStandards);
        });
    }
    setListingClass() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.listingClass === 'PROMOTED') {
                this.listingPriceForm.controls['leaseableAreaTotal'].disable();
            }
        });
    }
    getMeasurementStands() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getMeasurementStandard().toPromise();
        });
    }
    setValidation(type) {
        if (type === 'L') {
            // Rent
            if (this.listingPriceForm.value.negRentableArea) {
                this.listingPriceForm.controls.monthlyRent.setValue(null);
                this.listingPriceForm.controls.monthlyRentPerArea.setValue('');
                this.listingPriceForm.controls['monthlyRent'].setValidators([]);
                this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
                this.listingPriceForm.controls.monthlyRent.disable();
                this.listingPriceForm.controls['monthlyRentPerArea'].setValue(null);
                this.listingPriceForm.controls['monthlyRentPerArea'].disable();
            }
            else {
                this.listingPriceForm.controls.monthlyRent.enable();
                this.listingPriceForm.controls['monthlyRent'].setValidators([Validators.required]);
                this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
                this.listingPriceForm.controls['monthlyRentPerArea'].enable();
            }
            // Condo
            if (this.listingPriceForm.value.negCondoFeeArea) {
                this.listingPriceForm.controls.monthlyCondoFee.setValue(null);
                this.listingPriceForm.controls.monthlyCondoFeePerArea.setValue('');
                this.listingPriceForm.controls['monthlyCondoFee'].setValidators([]);
                this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
                this.listingPriceForm.controls.monthlyCondoFee.disable();
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(null);
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].disable();
            }
            else {
                this.listingPriceForm.controls.monthlyCondoFee.enable();
                this.listingPriceForm.controls['monthlyCondoFee'].setValidators([Validators.required]);
                this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].enable();
            }
            // IPTU
            if (this.listingPriceForm.value.negIPTUArea) {
                this.listingPriceForm.controls.monthlyIptu.setValue(null);
                this.listingPriceForm.controls.monthlyIptuPerArea.setValue('');
                this.listingPriceForm.controls['monthlyIptu'].setValidators([]);
                this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
                this.listingPriceForm.controls.monthlyIptu.disable();
                this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(null);
                this.listingPriceForm.controls['monthlyIptuPerArea'].disable();
            }
            else {
                this.listingPriceForm.controls.monthlyIptu.enable();
                this.listingPriceForm.controls['monthlyIptu'].setValidators([Validators.required]);
                this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
                this.listingPriceForm.controls['monthlyIptuPerArea'].enable();
            }
        }
        else if (type === 'S') {
            if (this.listingPriceForm.value.pricePer) {
                this.listingPriceForm.controls['totalAskingPrice'].setValidators([]);
                this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
                this.listingPriceForm.controls.totalAskingPrice.disable();
                this.listingPriceForm.controls['pricePerSquare'].disable();
            }
            else {
                this.listingPriceForm.controls.totalAskingPrice.enable();
                this.listingPriceForm.controls['totalAskingPrice'].setValidators([Validators.required]);
                this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
                this.listingPriceForm.controls['pricePerSquare'].enable();
            }
        }
    }
    checkTotal() {
        if (this.listingPriceForm.value.pricePer) {
            this.listingPriceForm.controls.totalAskingPrice.enable();
            this.listingPriceForm.controls['totalAskingPrice'].setValidators([Validators.required]);
            this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
            this.listingPriceForm.controls['pricePerSquare'].setValue(null);
            this.listingPriceForm.controls['pricePerSquare'].enable();
        }
        else {
            this.listingPriceForm.controls.totalAskingPrice.setValue(null);
            this.listingPriceForm.controls.pricePerSquare.setValue('');
            this.listingPriceForm.controls['totalAskingPrice'].setValidators([]);
            this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
            this.listingPriceForm.controls.totalAskingPrice.disable();
            this.listingPriceForm.controls['pricePerSquare'].disable();
        }
    }
    checkMonthlyCondo() {
        if (this.listingPriceForm.value.negCondoFeeArea) {
            this.listingPriceForm.controls.monthlyCondoFee.enable();
            this.listingPriceForm.controls['monthlyCondoFee'].setValidators([Validators.required]);
            this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
            this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyCondoFeePerArea'].enable();
        }
        else {
            this.listingPriceForm.controls.monthlyCondoFee.disable();
            this.listingPriceForm.controls.monthlyCondoFee.setValue(null);
            this.listingPriceForm.controls.monthlyCondoFeePerArea.setValue('');
            this.listingPriceForm.controls['monthlyCondoFee'].setValidators([]);
            this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
            this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyCondoFeePerArea'].disable();
        }
    }
    checkMonthlyIPTU() {
        if (this.listingPriceForm.value.negIPTUArea) {
            this.listingPriceForm.controls.monthlyIptu.enable();
            this.listingPriceForm.controls['monthlyIptu'].setValidators([Validators.required]);
            this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
            this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyIptuPerArea'].enable();
        }
        else {
            this.listingPriceForm.controls.monthlyIptu.setValue(null);
            this.listingPriceForm.controls.monthlyIptuPerArea.setValue('');
            this.listingPriceForm.controls['monthlyIptu'].setValidators([]);
            this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
            this.listingPriceForm.controls.monthlyIptu.disable();
            this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyIptuPerArea'].disable();
        }
    }
    checkMonthly() {
        if (this.listingPriceForm.value.negRentableArea) {
            this.listingPriceForm.controls.monthlyRent.enable();
            this.listingPriceForm.controls['monthlyRent'].setValidators([Validators.required]);
            this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
            this.listingPriceForm.controls['monthlyRentPerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyRentPerArea'].enable();
        }
        else {
            this.listingPriceForm.controls.monthlyRent.setValue(null);
            this.listingPriceForm.controls.monthlyRentPerArea.setValue('');
            this.listingPriceForm.controls['monthlyRent'].setValidators([]);
            this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
            this.listingPriceForm.controls.monthlyRent.disable();
            this.listingPriceForm.controls['monthlyRentPerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyRentPerArea'].disable();
        }
    }
    percentageMask() {
        let percent = this.listingPriceForm.value.currentOccupancy;
        if (percent >= 0 && percent < 100) {
            this.listingPriceForm.controls['currentOccupancy'].setValue(percent);
        }
        else {
            this.listingPriceForm.controls['currentOccupancy'].setValue(100);
        }
    }
    percentageBlur() {
        if (this.listingPriceForm.value.currentOccupancy >= 0 && this.listingPriceForm.value.currentOccupancy < 100) {
            let percent = parseFloat(this.listingPriceForm.value.currentOccupancy);
            percent = percent.toFixed(2);
            this.listingPriceForm.controls['currentOccupancy'].setValue(percent);
        }
    }
    interestMask() {
        let percentInterestOffered = this.listingPriceForm.value.percentInterestOffered;
        if (percentInterestOffered >= 0 && percentInterestOffered < 100) {
            this.listingPriceForm.controls['percentInterestOffered'].setValue(percentInterestOffered);
        }
        else {
            this.listingPriceForm.controls['percentInterestOffered'].setValue(100);
        }
    }
    getMeasurementTranslations(measurements) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Object.keys(measurements).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                measurements[key].measurement = this.getI18nValue(measurements[key].measurement);
            }));
            return measurements;
        });
    }
    checkCharacters(event) {
        let num = event.detail.value;
        let regexp;
        if (num) {
            let lastChar = num.substr(num.length - 1);
            if (this.langService.locale === 'en-US') {
                regexp = /^-?(?:\d{0,12}|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
            }
            else {
                regexp = /^-?(?:\d{0,12}|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
            }
            let tempValue = num.substring(0, num.length - 1);
            if ((!regexp.test(num) && lastChar !== '.' && lastChar !== ',') ||
                num.substr(0) === '.' ||
                num.substr(0) === ',') {
                if (tempValue.length && tempValue.length > 12) {
                    console.error('Invalid Value: ', tempValue);
                    event.srcElement.value = null;
                }
                else {
                    event.srcElement.value = tempValue;
                }
            }
        }
    }
    checkRentableArea() {
        let price = this.listingPriceForm.value.totalAskingPrice;
        let area = this.listingPriceForm.value.rentableArea;
        price = this.listingPriceService.convertForNumber(price);
        area = this.listingPriceService.convertForNumber(area);
        let pricePerSquare = price / area;
        if (isFinite(pricePerSquare) && pricePerSquare !== 0) {
            pricePerSquare = (pricePerSquare * 100) / 100;
            pricePerSquare = this.listingPriceService.convertForDisplay(pricePerSquare);
            this.listingPriceForm.controls['pricePerSquare'].setValue(pricePerSquare);
            this.listingPriceForm.controls['pricePerSquare'].updateValueAndValidity();
        }
    }
    rentableAreaBlur() {
        let area = this.listingPriceForm.value.rentableArea;
        area = this.listingPriceService.convertForNumber(area);
        if (isFinite(area) && area !== 0) {
            area = this.listingPriceService.convertForDisplay(area);
            this.listingPriceForm.controls['rentableArea'].setValue(area);
            this.checkRentableArea();
        }
    }
    totalPriceBlur($event) {
        let price = this.listingPriceForm.value.totalAskingPrice;
        price = this.listingPriceService.convertForNumber(price);
        if (isFinite(price) && price !== 0) {
            price = this.listingPriceService.convertForDisplay(price);
            this.listingPriceForm.controls['totalAskingPrice'].setValue(price);
            this.checkRentableArea();
        }
    }
    updateAmountPer() {
        if (this.listingPriceForm.value.rentableArea) {
            let price = this.listingPriceForm.value.pricePerSquare;
            let area = this.listingPriceForm.value.rentableArea;
            price = this.listingPriceService.convertForNumber(price);
            area = this.listingPriceService.convertForNumber(area);
            let totalAskingPrice = price * area;
            if (isFinite(totalAskingPrice) && totalAskingPrice !== 0) {
                totalAskingPrice = this.listingPriceService.convertForDisplay(totalAskingPrice);
                this.listingPriceForm.controls['totalAskingPrice'].setValue(totalAskingPrice);
            }
        }
    }
    interestBlur() {
        if (this.listingPriceForm.value.percentInterestOffered >= 0 &&
            this.listingPriceForm.value.percentInterestOffered < 100) {
            let percent = parseFloat(this.listingPriceForm.value.percentInterestOffered);
            percent = percent.toFixed(2);
            this.listingPriceForm.controls['percentInterestOffered'].setValue(percent);
        }
    }
    checkLeaseableArea() {
        let rent = this.listingPriceForm.value.monthlyRent;
        let area = this.listingPriceForm.value.leaseableArea;
        rent = this.listingPriceService.convertForNumber(rent);
        area = this.listingPriceService.convertForNumber(area);
        let monthlyRentPerArea = rent / area;
        if (isFinite(monthlyRentPerArea) && monthlyRentPerArea !== 0 && monthlyRentPerArea !== '') {
            let monthlyRentPerArea2 = (monthlyRentPerArea * 100) / 100;
            rent = this.listingPriceService.convertForDisplay(monthlyRentPerArea2);
            this.listingPriceForm.controls['monthlyRentPerArea'].setValue(rent);
            this.listingPriceForm.controls['monthlyRentPerArea'].updateValueAndValidity();
        }
        this.updateMonthlyCondo();
        this.updateMonthlyIptu();
    }
    leaseableAreaBlur(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let area = this.listingPriceForm.value.leaseableArea;
            area = this.listingPriceService.convertToNumber(area);
            if (isFinite(area) && area !== 0 && area !== '') {
                area = this.listingPriceService.convertForDisplay(area);
                this.listingPriceForm.controls['leaseableArea'].setValue(area);
                yield this.monthlyRentBlur(event);
                this.checkLeaseableArea();
            }
        });
    }
    leaseableAreatTotalBlur(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let area = this.listingPriceForm.value.leaseableAreaTotal;
            area = this.listingPriceService.convertToNumber(area);
            if (isFinite(area) && area !== 0 && area !== '') {
                area = this.listingPriceService.convertForDisplay(area);
                this.listingPriceForm.controls['leaseableAreaTotal'].setValue(area);
            }
        });
    }
    monthlyRentBlur($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let rent = this.listingPriceForm.value.monthlyRent;
            rent = this.listingPriceService.convertForNumber(rent);
            if (isFinite(rent) && rent !== 0 && rent !== '') {
                rent = this.listingPriceService.convertForDisplay(rent);
                this.listingPriceForm.controls['monthlyRent'].setValue(rent);
                this.checkLeaseableArea();
            }
        });
    }
    updateMonthlyFee() {
        if (this.listingPriceForm.value.leaseableArea) {
            let rent = this.listingPriceForm.value.monthlyRentPerArea;
            let area = this.listingPriceForm.value.leaseableArea;
            rent = this.listingPriceService.convertForNumber(rent);
            area = this.listingPriceService.convertForNumber(area);
            let monthlyRent = rent * area;
            if (isFinite(monthlyRent) && monthlyRent !== 0 && monthlyRent !== '') {
                monthlyRent = this.listingPriceService.convertForDisplay(monthlyRent);
                this.listingPriceForm.controls['monthlyRent'].setValue(monthlyRent);
                this.checkLeaseableArea();
            }
        }
    }
    standardChange($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    condoFeeBlur($event) {
        let condoFee = this.listingPriceForm.value.monthlyCondoFee;
        condoFee = this.listingPriceService.convertForNumber(condoFee);
        if (isFinite(condoFee) && condoFee !== 0 && condoFee !== '') {
            condoFee = this.listingPriceService.convertForDisplay(condoFee);
            this.listingPriceForm.controls['monthlyCondoFee'].setValue(condoFee);
            this.updateMonthlyCondo();
        }
    }
    updateMonthlyCondoFee() {
        if (this.listingPriceForm.value.leaseableArea) {
            let condoFeeArea = this.listingPriceForm.value.monthlyCondoFeePerArea;
            let area = this.listingPriceForm.value.leaseableArea;
            condoFeeArea = this.listingPriceService.convertForNumber(condoFeeArea);
            area = this.listingPriceService.convertForNumber(area);
            let monthlyCondoFee = condoFeeArea * area;
            monthlyCondoFee = this.listingPriceService.convertForDisplay(monthlyCondoFee);
            this.listingPriceForm.controls['monthlyCondoFee'].setValue(monthlyCondoFee);
            this.updateMonthlyCondo();
        }
    }
    updateMonthlyCondo() {
        let condoFee = this.listingPriceForm.value.monthlyCondoFee;
        let area = this.listingPriceForm.value.leaseableArea;
        condoFee = this.listingPriceService.convertForNumber(condoFee);
        area = this.listingPriceService.convertForNumber(area);
        let monthlyCondoFeePerArea = condoFee / area;
        if (isFinite(monthlyCondoFeePerArea) && monthlyCondoFeePerArea !== 0 && monthlyCondoFeePerArea !== '') {
            monthlyCondoFeePerArea = (monthlyCondoFeePerArea * 100) / 100;
            monthlyCondoFeePerArea = this.listingPriceService.convertForDisplay(monthlyCondoFeePerArea);
            if (monthlyCondoFeePerArea !== 0 && monthlyCondoFeePerArea !== '') {
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(monthlyCondoFeePerArea);
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].updateValueAndValidity();
            }
        }
    }
    //IPTU FORMATTING
    updateIptuFee() {
        if (this.listingPriceForm.value.leaseableArea) {
            let iptuArea = this.listingPriceForm.value.monthlyIptuPerArea;
            let area = this.listingPriceForm.value.leaseableArea;
            iptuArea = this.listingPriceService.convertForNumber(iptuArea);
            area = this.listingPriceService.convertForNumber(area);
            let monthlyIptu = iptuArea * area;
            monthlyIptu = this.listingPriceService.convertForDisplay(monthlyIptu);
            this.listingPriceForm.controls['monthlyIptu'].setValue(monthlyIptu);
            this.updateMonthlyIptu();
        }
    }
    updateMonthlyIptu() {
        let iptu = this.listingPriceForm.value.monthlyIptu;
        let area = this.listingPriceForm.value.leaseableArea;
        iptu = this.listingPriceService.convertForNumber(iptu);
        area = this.listingPriceService.convertForNumber(area);
        let monthlyIptuPerArea = iptu / area;
        if (isFinite(monthlyIptuPerArea) && monthlyIptuPerArea !== 0 && monthlyIptuPerArea !== '') {
            monthlyIptuPerArea = (monthlyIptuPerArea * 100) / 100;
            monthlyIptuPerArea = this.listingPriceService.convertForDisplay(monthlyIptuPerArea);
            this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(monthlyIptuPerArea);
            this.listingPriceForm.controls['monthlyIptuPerArea'].updateValueAndValidity();
        }
    }
    iptuFeeBlur($event) {
        let monthlyIptu = this.listingPriceForm.value.monthlyIptu;
        monthlyIptu = this.listingPriceService.convertForNumber(monthlyIptu);
        if (isFinite(monthlyIptu) && monthlyIptu !== 0 && monthlyIptu !== '') {
            monthlyIptu = this.listingPriceService.convertForDisplay(monthlyIptu);
            this.listingPriceForm.controls['monthlyIptu'].setValue(monthlyIptu);
            this.updateMonthlyIptu();
        }
    }
    getI18nValue(textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18NService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    }
}
