import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { RegisterService } from '@app/core/register.service';
import { RenewModalComponent } from '../renew-modal/renew-modal.component';
import { PopoverController } from '@ionic/angular';
export class CurrentPlansComponent {
    constructor(router, registerService, purchaseCreditsService, popoverCtrl, ngZone) {
        this.router = router;
        this.registerService = registerService;
        this.purchaseCreditsService = purchaseCreditsService;
        this.popoverCtrl = popoverCtrl;
        this.ngZone = ngZone;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.screenWidth = window.innerWidth;
        this.getParams();
    }
    ngOnInit() {
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
        this.nonAdminInit();
    }
    getParams() {
        const navigation = this.router.getCurrentNavigation();
        this.renew = navigation.extras.state && navigation.extras.state.revise ? navigation.extras.state.revise : null;
        this.listingId =
            navigation.extras.state && navigation.extras.state.listingId ? navigation.extras.state.listingId : null;
        this.listing = navigation.extras.state && navigation.extras.state.listing ? navigation.extras.state.listing : null;
    }
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
    }
    nameChange(event) {
        if (event.value && event.value.id) {
            this.offeredByUserId = event.value;
            this.getAllCredits();
        }
    }
    checkAdmin(role) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.user = JSON.parse(localStorage.getItem('user'));
            if (this.user.roles.includes(role)) {
                return true;
            }
            else {
                return false;
            }
        });
    }
    checkSpotAdmin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.user = JSON.parse(localStorage.getItem('user'));
            if (this.user.roles.includes('SPOT_ADMIN')) {
                return true;
            }
            else {
                return false;
            }
        });
    }
    nonAdminInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.allCompanies = yield this.getCompanies();
            this.user = JSON.parse(localStorage.getItem('user'));
            this.users = yield this.getUsersByCompany(this.user.company.id);
            let index = this.allCompanies.findIndex((v) => {
                return v.id === this.user.company.id;
            });
            let userIndex = this.users.findIndex((v) => {
                return v.id === this.user.id;
            });
            this.selectCompany = this.allCompanies[index];
            this.offeredByUserId = this.users[userIndex];
            this.getAllCredits();
        });
    }
    portChange($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.users = yield this.getUsersByCompany($event.value.id);
            this.agentComponent.clear();
            this.offeredByUserId = null;
            this.userCredits = [];
        });
    }
    getUsersByCompany(companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getUsersByCompany(companyId).toPromise();
        });
    }
    getCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getListingCompanies().toPromise();
        });
    }
    getAllCredits() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.userCredits = yield this.purchaseCreditsService.getCurrentCredits(this.offeredByUserId.id);
        });
    }
    navigateToPurchase() {
        this.router.navigateByUrl('/list-property');
    }
    navigateToMyListing() {
        this.router.navigateByUrl('/user-home/my-listings');
    }
    navigateToCreate(credit, ev) {
        if (this.renew && this.listingId) {
            this.openRenewalPopover(ev, credit, this.listingId, this.listing);
        }
        else if (this.selectCompany && this.offeredByUserId) {
            this.ngZone.run(() => {
                this.router.navigateByUrl('/user-home/my-listings/create-property', {
                    state: {
                        credit: credit,
                        company: this.selectCompany,
                        offeredByUserId: this.offeredByUserId,
                        date: new Date()
                    },
                    replaceUrl: true,
                    queryParams: { date: new Date().getTime() }
                });
            });
        }
    }
    openRenewalPopover(ev, credit, listingId, listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: RenewModalComponent,
                event: ev,
                cssClass: 'popover-renewal',
                componentProps: { credit: credit, listingId: listingId, listing: listing },
                backdropDismiss: false
            });
            popover.onDidDismiss().then((popoverData) => {
                if (popoverData.data) {
                }
            });
            return yield popover.present();
        });
    }
    checkRole(role) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    }
}
