import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbOptions } from '@app/shared';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { RegisterService } from '@app/core/register.service';
import { GaEventFormInputDirective } from 'ngx-google-analytics';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { RemoveCreditsPopoverComponent } from '@app/search-navigation/components/remove-credits-popover';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'review-my-payments',
  templateUrl: './my-payment.component.html',
  styleUrls: ['./my-payment.component.scss']
})
export class ReviewMyPaymentComponent implements OnInit {
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };
  userCredits: any;
  user: any;
  users: any;
  selectCompany: any;
  offeredByUserId: any;
  admin: any;
  allCompanies: any;
  spotAdmin: any;
  listingAdmin: any;
  purchases: any;
  listingActivities: any;
  @ViewChild('agentComponent', { static: false }) agentComponent?: IonicSelectableComponent;
  constructor(
    private router: Router,
    private popoverCtrl: PopoverController,
    private registerService: RegisterService,
    private purchaseCreditsService: PurchaseCreditsService,
    private userActivityService: UserActivityService
  ) {}

  ngOnInit() {
    this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
    this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
    this.nonAdminInit();
    this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_MYPAYMENTS_PAGE);
  }

  nameChange(event: any) {
    if (event.value && event.value.id) {
      this.offeredByUserId = event.value;
      //this.getPayments();
      this.getLisitngPaymentActivity();
    }
  }

  async checkAdmin() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user.roles.includes('ROLE_ADMIN')) {
      return true;
    } else {
      return false;
    }
  }

  async checkSpotAdmin() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user.roles.includes('SPOT_ADMIN')) {
      return true;
    } else {
      return false;
    }
  }

  async initUser() {
    this.admin = await this.checkAdmin();
  }

  async nonAdminInit() {
    this.allCompanies = await this.getCompanies();
    this.admin = await this.checkAdmin();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.users = await this.getUsersByCompany(this.user.company.id);
    let index = this.allCompanies.findIndex((v: any) => {
      return v.id === this.user.company.id;
    });
    let userIndex = this.users.findIndex((v: any) => {
      return v.id === this.user.id;
    });
    this.selectCompany = this.allCompanies[index];
    this.offeredByUserId = this.users[userIndex];
    //this.getPayments();
    this.getLisitngPaymentActivity();
  }

  async portChange($event: any) {
    this.users = await this.getUsersByCompany($event.value.id);
    this.agentComponent.clear();
    this.offeredByUserId = null;
    this.userCredits = [];
    this.purchases = [];
    this.listingActivities = [];
  }

  async getUsersByCompany(companyId: number) {
    return this.registerService.getUsersByCompany(companyId).toPromise();
  }

  async getCompanies() {
    return this.registerService.getListingCompanies().toPromise();
  }

  async getPayments() {
    this.purchases = await this.purchaseCreditsService.getUserPayments(this.offeredByUserId.id);
  }

  async getLisitngPaymentActivity() {
    this.listingActivities = await this.purchaseCreditsService.getUserListingPaymentActivity(this.offeredByUserId.id);
  }

  navigateToPurchase() {
    this.router.navigateByUrl('/list-property');
  }

  navigateToMyListing() {
    this.router.navigateByUrl('/user-home/my-listings');
  }

  removeCredits(listingPurchaseId: number, availableCredits: number) {
    this.openRemoveCreditsPopover(listingPurchaseId, availableCredits);
  }

  async openRemoveCreditsPopover(listingPurchaseId: number, availableCredits: number) {
    const popover = await this.popoverCtrl.create({
      component: RemoveCreditsPopoverComponent,
      event: null,
      cssClass: 'popover-remove-credits',
      componentProps: { listingPurchaseId: listingPurchaseId, availableCredits: availableCredits },
      backdropDismiss: false
    });

    popover.onDidDismiss().then(popoverData => {
      if (popoverData.data) {
        this.getLisitngPaymentActivity();
      }
    });
    return await popover.present();
  }

  navigateToCreate(id: any, premium: any) {
    if (this.selectCompany && this.offeredByUserId) {
      this.router.navigateByUrl('/user-home/my-listings/create-property', {
        state: {
          orderId: id,
          premium: premium,
          company: this.selectCompany,
          offeredByUserId: this.offeredByUserId,
          date: new Date()
        },
        replaceUrl: true
      });
    }
  }

  checkRole(role: string) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }
}
