<div>
  <ion-row>
    <ion-col size="12">
      <ion-button class="close-btn" fill="outline" color="primary" (click)="dismissAsDialog()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</div>

<ion-row class="pointer" (click)="navigateToPurchase()" *ngIf="spotAdmin">
  <div class="btn-styling" color="secondary">
    <ion-label class="btn-heading" translate="global.my-list-menu.buyYourSpot"></ion-label>
    <p class="btn-descript" translate="global.my-list-menu.listSpotText"></p>
  </div>
</ion-row>

<ion-row class="pointer" (click)="navigateToRegister()" *ngIf="spotAdmin">
  <div class="btn-styling" color="secondary">
    <ion-label class="btn-heading" translate="global.my-list-menu.registerSpot"></ion-label>
    <p class="btn-descript" translate="global.my-list-menu.registerSpotText"></p>
  </div>
</ion-row>

<ion-row class="pointer" (click)="navigateToListings()">
  <div class="btn-styling" color="secondary">
    <ion-label class="btn-heading" translate="global.my-list-menu.reviewYourSpots"></ion-label>
    <p class="btn-descript" translate="global.my-list-menu.reviewSpotText"></p>
  </div>
</ion-row>
