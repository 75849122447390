import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { MatDialog, MatStepper } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { I18nService } from '@app/core/i18n.service';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { SendNotificationComponent } from '@app/shared/send-notification/send-notification.component';
import { ImageUploadService } from '@app/shared/services/image-upload.service';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { AlertController } from '@ionic/angular';
export class CreateSpotBuildingsComponent {
    constructor(createSpotBuildingsService, imageUploadService, router, activatedRoute, i18NService, alertCtrl, dialog, breakpointObserver, routingHelperSrv) {
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.imageUploadService = imageUploadService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.i18NService = i18NService;
        this.alertCtrl = alertCtrl;
        this.dialog = dialog;
        this.breakpointObserver = breakpointObserver;
        this.routingHelperSrv = routingHelperSrv;
        this.currentStep = 0;
        this.uploadingImages = false;
        this.imgList = [];
        this.spotBuildings = new CreateSpotBuildingsRequest();
        this.typeOffer = '';
        this.propertyType = [];
        this.entireSale = false;
        this.emitChanges = false;
        this.saveImages = true;
        this.isMobile = false;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
        this.spotBuildings.enabled = false;
        this.getParamsUrl();
    }
    ngOnInit() { }
    getParamsUrl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.activatedRoute.params.subscribe((paramMap) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    let baseId = paramMap.buildingId;
                    const buildingIdParam = paramMap.buildingId;
                    const listingTypeParam = paramMap.buildingType;
                    if (buildingIdParam) {
                        this.createSpotBuildingsService
                            .getSpotBuildingsFromBuilding(buildingIdParam, listingTypeParam, this.i18NService.getCurrentLanguage())
                            .then((response) => {
                            this.buildingDetail = response;
                            if (this.buildingDetail.buildingListingPhotos) {
                                this.imgList = this.buildingDetail.buildingListingPhotos;
                            }
                        });
                    }
                }
                catch (e) { }
            }));
        });
    }
    goBack() {
        this.stepper.previous();
        this.currentStep = this.currentStep - 1;
    }
    goForward() {
        this.currentStep = this.currentStep + 1;
        if (this.currentStep == 2) {
            this.typeOffer = this.spotBuildings.type;
            this.entireSale = this.spotBuildings.entireSale;
            this.emitChanges = !this.emitChanges;
        }
        this.stepper.next();
    }
    saveBuilding() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.saveImages = false;
            if (this.buildingDetail) {
                this.spotBuildings.id = this.buildingDetail.id;
                this.createSpotBuildingsResponse = yield this.createSpotBuildingsService.updateSpotBuildings(this.spotBuildings);
            }
            else {
                this.createSpotBuildingsResponse = yield this.createSpotBuildingsService.createSpotBuildings(this.spotBuildings);
                this.spotBuildings.id = this.createSpotBuildingsResponse.id;
            }
            this.saveImages = true;
            let photos = this.imageUploadService.associatePhotosToBuildingListing(this.createSpotBuildingsResponse.id, this.imgList);
            this.goForward();
        });
    }
    closeBuilding() {
        /* this.sendNotification(); */
        this.openModalNotification();
    }
    sendNotification() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                subHeader: this.i18NService.get('notifications.sendNotification'),
                cssClass: 'ion-alert-listings',
                backdropDismiss: true,
                buttons: [
                    {
                        text: this.i18NService.get('global.revisionChanges.no'),
                        role: 'cancel',
                        handler: () => {
                            this.routingHelperSrv.navigateToSearchPage(true);
                        }
                    },
                    {
                        text: this.i18NService.get('global.revisionChanges.yes'),
                        handler: () => {
                            this.openModalNotification();
                        }
                    }
                ]
            });
            alert.onDidDismiss().then(() => { });
            yield alert.present();
        });
    }
    openModalNotification() {
        let notify = {
            content: '',
            spotId: this.buildingDetail ? this.buildingDetail.id : this.spotBuildings.id,
            newSpotBuilding: this.buildingDetail ? false : true,
            predefinedNotificationId: null
        };
        let dialogRef = this.dialog.open(SendNotificationComponent, {
            width: this.isMobile ? '100%' : '600px',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                notify: notify
            },
            autoFocus: true,
            restoreFocus: true
        });
        dialogRef.afterClosed().subscribe(val => {
            if (val) {
                this.routingHelperSrv.navigateToSearchPage(true);
            }
        });
    }
}
