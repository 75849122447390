import { Component, ViewChild, HostListener, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbOptions } from '@app/shared';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { RegisterService } from '@app/core/register.service';
import { GaEventFormInputDirective } from 'ngx-google-analytics';
import { RenewModalComponent } from '../renew-modal/renew-modal.component';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'current-plans',
  templateUrl: './current-plans.component.html',
  styleUrls: ['./current-plans.component.scss']
})
export class CurrentPlansComponent {
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };
  userCredits: any;
  user: any;
  users: any;
  selectCompany: any;
  offeredByUserId: any;
  admin: any;
  allCompanies: any;
  spotAdmin: any;
  listingAdmin: any;
  renew: any;
  listingCreditId: any;
  listingId: any;
  listing: any;
  screenWidth: any;
  @ViewChild('agentComponent', { static: false }) agentComponent?: IonicSelectableComponent;
  constructor(
    private router: Router,
    private registerService: RegisterService,
    private purchaseCreditsService: PurchaseCreditsService,
    private popoverCtrl: PopoverController,
    private ngZone: NgZone
  ) {
    this.screenWidth = window.innerWidth;
    this.getParams();
  }

  ngOnInit() {
    this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
    this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
    this.nonAdminInit();
  }

  getParams() {
    const navigation = this.router.getCurrentNavigation();
    this.renew = navigation.extras.state && navigation.extras.state.revise ? navigation.extras.state.revise : null;
    this.listingId =
      navigation.extras.state && navigation.extras.state.listingId ? navigation.extras.state.listingId : null;
    this.listing = navigation.extras.state && navigation.extras.state.listing ? navigation.extras.state.listing : null;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  nameChange(event: any) {
    if (event.value && event.value.id) {
      this.offeredByUserId = event.value;
      this.getAllCredits();
    }
  }

  async checkAdmin(role: any) {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  async checkSpotAdmin() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user.roles.includes('SPOT_ADMIN')) {
      return true;
    } else {
      return false;
    }
  }

  async nonAdminInit() {
    this.allCompanies = await this.getCompanies();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.users = await this.getUsersByCompany(this.user.company.id);
    let index = this.allCompanies.findIndex((v: any) => {
      return v.id === this.user.company.id;
    });
    let userIndex = this.users.findIndex((v: any) => {
      return v.id === this.user.id;
    });
    this.selectCompany = this.allCompanies[index];
    this.offeredByUserId = this.users[userIndex];
    this.getAllCredits();
  }

  async portChange($event: any) {
    this.users = await this.getUsersByCompany($event.value.id);
    this.agentComponent.clear();
    this.offeredByUserId = null;
    this.userCredits = [];
  }

  async getUsersByCompany(companyId: number) {
    return this.registerService.getUsersByCompany(companyId).toPromise();
  }

  async getCompanies() {
    return this.registerService.getListingCompanies().toPromise();
  }

  async getAllCredits() {
    this.userCredits = await this.purchaseCreditsService.getCurrentCredits(this.offeredByUserId.id);
  }

  navigateToPurchase() {
    this.router.navigateByUrl('/list-property');
  }

  navigateToMyListing() {
    this.router.navigateByUrl('/user-home/my-listings');
  }

  navigateToCreate(credit: any, ev: any) {
    if (this.renew && this.listingId) {
      this.openRenewalPopover(ev, credit, this.listingId, this.listing);
    } else if (this.selectCompany && this.offeredByUserId) {
      this.ngZone.run(() => {
        this.router.navigateByUrl('/user-home/my-listings/create-property', {
          state: {
            credit: credit,
            company: this.selectCompany,
            offeredByUserId: this.offeredByUserId,
            date: new Date()
          },
          replaceUrl: true,
          queryParams: { date: new Date().getTime() }
        });
      });
    }
  }

  async openRenewalPopover(ev: Event, credit: any, listingId: any, listing: any) {
    const popover = await this.popoverCtrl.create({
      component: RenewModalComponent,
      event: ev,
      cssClass: 'popover-renewal',
      componentProps: { credit: credit, listingId: listingId, listing: listing },
      backdropDismiss: false
    });

    popover.onDidDismiss().then((popoverData: any) => {
      if (popoverData.data) {
      }
    });
    return await popover.present();
  }

  checkRole(role: string) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }
}
