<div class="flex-btn">
  <ion-button color="primary" fill="outline" (click)="dismiss()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>
<div>
  <!--h3 translate="global.contactModal.header" class="title center"></h3-->
  <p translate="global.contactModal.infoBr" class="subtitle center"></p>
  <button translate="global.contactModal.contactEmailBr" class="contact-btn" (click)="openEmail()"></button>
  <p class="center">
    <span translate="global.contactModal.call">Or call: </span>
    <a href="tel:+55 11 3046-9598">+55 11 3046-9598</a>
  </p>
</div>
