import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { CommonGenericService } from '@app/core/common.service';

@Component({
  selector: 'app-more-details',
  templateUrl: './more-details.component.html',
  styleUrls: ['./more-details.component.scss']
})
export class MoreDetailsComponent implements OnInit {
  @Input('form') moreDetailsForm: FormGroup;
  @Input('otherForm') listingTypeForm: FormGroup;
  @Input('detailsForm') detailsForm: FormGroup;
  @Input('stepper') stepper: MatStepper;
  @Input('tagValues') tagValues: any;
  @Input('listType') listType: any;
  @Input('listing') listing: any;
  @Input('offeredByUserId') offeredByUserId: any;
  @Input('selectCompany') selectCompany: any;

  titleFieldOptions: any;
  descriptionFieldOptions: any;

  offeredByUserApi: any = 'api/users/allOfSameCompany';
  user: any;
  users: any;
  admin: boolean;
  //allCompanies: any;
  selectUser: any;
  measurementStandards: any;
  selectedDescLanguage: any = [];
  selectedTitleLanguage: any = [];
  @ViewChild('agentComponent', { static: false }) agentComponent?: IonicSelectableComponent;
  @ViewChild('title', { static: false }) title: ElementRef;
  @ViewChild('description', { static: false }) description: ElementRef;
  languages: any;
  spotAdmin: any;
  listingAdmin: any;
  existingTitle: any;
  existingComments: any;
  basicListingType: any = false;
  constructor(private registerService: RegisterService, private commonService: CommonGenericService) {}

  ngOnInit(): void {
    this.multiLangInit();
    this.listing ? this.initExistingValues() : null;
    this.nonAdminInit();
  }

  async nonAdminInit() {
    this.spotAdmin = await this.checkRole('ROLE_SPOT_ADMIN');
    this.listingAdmin = await this.checkRole('ROLE_LISTING_ADMIN');
    this.languages = await this.commonService.getLanguageOption();
    //this.allCompanies = await this.getCompanies();
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.listing && this.listing.listingType && this.listing.listingType === 'BASIC') {
      this.basicListingType = true;
      this.moreDetailsForm.controls['propertyManagementCompany'].setValidators([Validators.required]);
    }
    if (this.selectCompany) {
      this.users = await this.getUsersByCompany(this.selectCompany.id);
    }
    if (this.offeredByUserId) {
      let userIndex = await this.users.findIndex((v: any) => {
        return v.id === this.offeredByUserId.id;
      });
      this.moreDetailsForm.controls['offeredByUserId'].setValue(this.users[userIndex]);
    }
  }

  initExistingValues() {
    if (this.listing.title) {
      if (this.isJsonString(this.listing.title)) {
        this.existingTitle = JSON.parse(this.listing.title);
      } else {
        this.existingTitle = { en: this.listing.title };
      }
    }
    if (this.listing.comments) {
      if (this.isJsonString(this.listing.comments)) {
        this.existingComments = JSON.parse(this.listing.comments);
      } else {
        this.existingComments = { en: this.listing.comments };
      }
    }
  }

  isJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  titleChange(value: any) {
    if (value.title.length > 0) {
      let mergedObject: any = {};
      let nullValues: boolean = false;
      Object.keys(value.title).forEach(async (key, idx) => {
        let obj: any = value.title[key];
        if (obj['en'] === '' || obj['pt-br'] === '') {
          nullValues = true;
        } else {
          mergedObject = Object.assign(mergedObject, obj);
        }
      });
      nullValues
        ? this.moreDetailsForm.controls['title'].setValue(null)
        : this.moreDetailsForm.controls['title'].setValue(JSON.stringify(mergedObject));
    } else {
      this.moreDetailsForm.controls['title'].setValue(null);
    }
  }

  descriptionChange(value: any) {
    if (value.description.length > 0) {
      let mergedObject: any = {};
      let nullValues: boolean = false;
      Object.keys(value.description).forEach(async (key, idx) => {
        let obj: any = value.description[key];
        if (obj['en'] === '' || obj['pt-br'] === '') {
          nullValues = true;
        } else {
          mergedObject = Object.assign(mergedObject, obj);
        }
      });
      nullValues
        ? this.moreDetailsForm.controls['description'].setValue(null)
        : this.moreDetailsForm.controls['description'].setValue(JSON.stringify(mergedObject));
    } else {
      this.moreDetailsForm.controls['description'].setValue(null);
    }
  }

  checkRole(role: string) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  multiLangInit() {
    this.titleFieldOptions = {
      columnLabel: 'global.registerSpot.title',
      element: { input: true },
      formArrayName: 'title',
      required: true
    };

    this.descriptionFieldOptions = {
      columnLabel: 'global.registerSpot.description',
      element: { richtext: true },
      formArrayName: 'description',
      required: true
    };
  }

  nameChange($event: any) {
    this.moreDetailsForm.controls['offeredByUserId'].setValue($event.value);
  }

  async portChange($event: any) {
    this.users = await this.getUsersByCompany($event.value.id);
    this.agentComponent.clear();
  }

  async getUsersByCompany(companyId: number) {
    return this.registerService.getUsersByCompany(companyId).toPromise();
  }

  async getCompanies() {
    return this.registerService.getListingCompanies().toPromise();
  }

  onSelectChange(selectedValue: any) {
    this.tagValues = selectedValue.detail.value;
    this.moreDetailsForm.controls.tags.setValue(this.tagValues);
  }
}
