export const turnOff = {
  RECOMMENDATION: 'RECOMMENDATION',
  FAVORITES: 'FAVORITES',
  SPOT_SET: 'SPOT_SET'
};

export const urlPromotedRedirects = new Map<string, string>([
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238160/(.*)',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/barueri/dcb-distribution-center-barueri/1238160/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238160',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/barueri/dcb-distribution-center-barueri/1238160/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/13441',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/alexandre-dumas/13441/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238160',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/barueri/dcb-distribution-center-barueri/1238160/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500291',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-5/500291/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500291',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-5/500291/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500291',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-5/500291/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500291',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-5/500291/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238303',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-paulo/armazem-costa-aguiar-59/1238303/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238303',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/armazem-costa-aguiar-59/1238303/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238056',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/extrema/condominio-especulativo-extrema/1238056/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238056',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/extrema/condominio-especulativo-extrema/1238056/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238056',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/condominio-especulativo-extrema/1238056/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238056',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/condominio-especulativo-extrema/1238056/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235670',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/box-298/1235670/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235670',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/box-298/1235670/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238350',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/arena-tower/1238350/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238350',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/arena-tower/1238350/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238266',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sorocaba/armazem-sorocaba-2400-sp/1238266/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238266',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sorocaba/armazem-sorocaba-2400-sp/1238266/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600380',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/varginha/citlog-sul-de-minas/600380/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600380',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/varginha/citlog-sul-de-minas/600380/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238293',
    'https://siilaspot.com.br/locacao/industrial/alagoas/maceio/log-maceio/1238293/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238293',
    'https://siilaspot.com.br/lease/industrial/alagoas/maceio/log-maceio/1238293/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/435054',
    'https://siilaspot.com.br/locacao/industrial/paraiba/alhandra/cbi-log/435054/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/435054',
    'https://siilaspot.com.br/lease/industrial/paraiba/alhandra/cbi-log/435054/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/435054',
    'https://siilaspot.com.br/venda/industrial/paraiba/alhandra/cbi-log/435054/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/435054',
    'https://siilaspot.com.br/sale/industrial/paraiba/alhandra/cbi-log/435054/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/892696',
    'https://siilaspot.com.br/locacao/industrial/pernambuco/recife/loginveste/892696/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/892696',
    'https://siilaspot.com.br/lease/industrial/pernambuco/recife/loginveste/892696/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/892696',
    'https://siilaspot.com.br/venda/industrial/pernambuco/recife/loginveste/892696/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/892696',
    'https://siilaspot.com.br/sale/industrial/pernambuco/recife/loginveste/892696/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238354',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/predio-comercial-rua-da-graca-856/1238354/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238354',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-comercial-rua-da-graca-856/1238354/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600073',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/rio-de-janeiro/bmlog-rio-ex-goodman-nova-india-/600073/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600073',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/rio-de-janeiro/bmlog-rio-ex-goodman-nova-india-/600073/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600073',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/rio-de-janeiro/bmlog-rio-ex-goodman-nova-india-/600073/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600073',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/bmlog-rio-ex-goodman-nova-india-/600073/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/303589',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/almirante-barroso/303589/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/303589',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/almirante-barroso/303589/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/303589',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/almirante-barroso/303589/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/303589',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/almirante-barroso/303589/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500730',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/centro-empresarial-conceicao/500730/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500730',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/centro-empresarial-conceicao/500730/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500730',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/centro-empresarial-conceicao/500730/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500730',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/centro-empresarial-conceicao/500730/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500216',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/f-l-4300-torre-corporate/500216/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500216',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/f-l-4300-torre-corporate/500216/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500161',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/condominio-edificio-avenida-brigadeiro-faria-lima/500161/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500161',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/condominio-edificio-avenida-brigadeiro-faria-lima/500161/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238325',
    'https://siilaspot.com.br/venda/industrial/sao-paulo/sao-paulo/galpao-isolado-avenida-engenheiro-eusebio-stevaux-2496/1238325/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238325',
    'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-avenida-engenheiro-eusebio-stevaux-2496/1238325/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238390',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-paulo/galpao-comercial-liberdade-720/1238390/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237849',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/galpao-isolado-bmlog-leap-dafiti-extrema-/1237849/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237849',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/galpao-isolado-bmlog-leap-dafiti-extrema-/1237849/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238332',
    'https://siilaspot.com.br/venda/industrial/sao-paulo/sao-paulo/galpao-isolado-avenida-presidente-wilson-5786/1238332/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238332',
    'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-avenida-presidente-wilson-5786/1238332/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238373',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-joaquim-souza-campos-junior/1238373/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238373',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-joaquim-souza-campos-junior/1238373/en'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/escritorio/(.*)/(.*)/500591',
    'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/passeio-corporate-setor-2-torre-b-/500591/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/lease/escritorio/(.*)/(.*)/500591',
    'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/passeio-corporate-setor-2-torre-b-/500591/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/office/(.*)/(.*)/500591',
    'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/passeio-corporate-setor-2-torre-b-/500591/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500266',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-7/500266/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500266',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-7/500266/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500266',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-7/500266/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500266',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-7/500266/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/501003',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/borges-2233/501003/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/501003',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/borges-2233/501003/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/501003',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/borges-2233/501003/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/501003',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/borges-2233/501003/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237306',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-moncoes-da-gastao/1237306/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237306',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-moncoes-da-gastao/1237306/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238380',
    'https://siilaspot.com.br/venda/industrial/sao-paulo/guarulhos/galpao-isolado-rua-urbano-santos-n-755/1238380/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238380',
    'https://siilaspot.com.br/sale/industrial/sao-paulo/guarulhos/galpao-isolado-rua-urbano-santos-n-755/1238380/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500829',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/alphabusiness-bloco-acai/500829/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500829',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/alphabusiness-bloco-acai/500829/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500829',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/alphabusiness-bloco-acai/500829/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500829',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/alphabusiness-bloco-acai/500829/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235228',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/comercial-5-avenida/1235228/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235228',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/comercial-5-avenida/1235228/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235263',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/itc-international-trade-center-business/1235263/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235263',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/itc-international-trade-center-business/1235263/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500099',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-administrativo-cidade-nova/500099/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500099',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-administrativo-cidade-nova/500099/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500099',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-administrativo-cidade-nova/500099/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500099',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-administrativo-cidade-nova/500099/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/362545',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/net-office-empresarial/362545/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/362545',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/net-office-empresarial/362545/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/362545',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/net-office-empresarial/362545/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/362545',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/net-office-empresarial/362545/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/355923',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/cardeal-leme/355923/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/355923',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/cardeal-leme/355923/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/355923',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/cardeal-leme/355923/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/355923',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/cardeal-leme/355923/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500105',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-rio-branco-1-rb1-/500105/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500105',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-rio-branco-1-rb1-/500105/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/483035',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/ouro-para-o-bem-de-sao-paulo/483035/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/483035',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ouro-para-o-bem-de-sao-paulo/483035/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235367',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/faria-lima-plaza/1235367/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235367',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/faria-lima-plaza/1235367/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500254',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/bradesco-ex-banco-mercantil-/500254/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500254',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bradesco-ex-banco-mercantil-/500254/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600287',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/aracariguama/prologis-castelo-46/600287/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600287',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/aracariguama/prologis-castelo-46/600287/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500488',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/centro-administrativo-rio-negro-torre-padauiri/500488/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500488',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-padauiri/500488/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500488',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/centro-administrativo-rio-negro-torre-padauiri/500488/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500488',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-padauiri/500488/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238353',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/predio-monousuario-sergio-tomas-415/1238353/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238353',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-monousuario-sergio-tomas-415/1238353/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600160',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/resende/bresco-resende/600160/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600160',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/resende/bresco-resende/600160/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500091',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-4/500091/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500091',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-4/500091/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500091',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-4/500091/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500091',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-4/500091/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500988',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/1009-da-felix/500988/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500988',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/1009-da-felix/500988/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500988',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/1009-da-felix/500988/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500988',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/1009-da-felix/500988/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237538',
    'https://siilaspot.com.br/locacao/industrial/goias/hidrolandia/log-goiania-ii/1237538/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237538',
    'https://siilaspot.com.br/lease/industrial/goias/hidrolandia/log-goiania-ii/1237538/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500399',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-ii-jatoba/500399/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500399',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-ii-jatoba/500399/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500399',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-ii-jatoba/500399/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500399',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-ii-jatoba/500399/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238339',
    'https://siilaspot.com.br/locacao/industrial/distrito-federal/brasilia/galpao-isolado-lote-07-strc-trecho-2-conjunto-a-zona-industrial/1238339/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238339',
    'https://siilaspot.com.br/lease/industrial/distrito-federal/brasilia/satad-alone-lote-07-strc-trecho-2-conjunto-a-zona-industrial/1238339/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1237720',
    'https://siilaspot.com.br/venda/industrial/distrito-federal/brasilia/golgi-brasilia/1237720/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1237720',
    'https://siilaspot.com.br/sale/industrial/distrito-federal/brasilia/golgi-brasilia/1237720/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237720',
    'https://siilaspot.com.br/locacao/industrial/distrito-federal/brasilia/golgi-brasilia/1237720/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237720',
    'https://siilaspot.com.br/lease/industrial/distrito-federal/brasilia/golgi-brasilia/1237720/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238392',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/espaco-350/1238392/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238392',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/espaco-350/1238392/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500237',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/market-place-tower-ii/500237/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500237',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/market-place-tower-ii/500237/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238385',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-jose-do-rio-preto/imovel-comercial-alfredo-folchin/1238385/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238385',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-jose-do-rio-preto/imovel-comercial-alfredo-folchin/1238385/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238329',
    'https://siilaspot.com.br/venda/industrial/sao-paulo/sao-paulo/galpao-isolado-avenida-carioca-427/1238329/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238329',
    'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-avenida-carioca-427/1238329/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500466',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-brasilprev-ala-b/500466/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500466',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-brasilprev-ala-b/500466/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500286',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/san-paolo/500286/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500286',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/san-paolo/500286/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500162',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/linneo-de-paula-machado/500162/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500162',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/linneo-de-paula-machado/500162/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238341',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/edificio-no-setor-de-industrias-bernardo-sayao/1238341/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238341',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/edificio-no-setor-de-industrias-bernardo-sayao/1238341/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/55375',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/modulo-reboucas/55375/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/55375',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/modulo-reboucas/55375/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500090',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-3/500090/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500090',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-3/500090/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500090',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-3/500090/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500090',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-3/500090/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238330',
    'https://siilaspot.com.br/venda/industrial/sao-paulo/sao-paulo/galpao-isolado-avenida-nelson-palma-travassos-275/1238330/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238330',
    'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-avenida-nelson-palma-travassos-275/1238330/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238304',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-paulo/armazem-avenida-presidente-wilson-5737/1238304/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238304',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/armazem-avenida-presidente-wilson-5737/1238304/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235379',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/centro-corporativo-hamilcar-pizzatto/1235379/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235379',
    'https://siilaspot.com.br/sale/office/parana/curitiba/centro-corporativo-hamilcar-pizzatto/1235379/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235379',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/centro-corporativo-hamilcar-pizzatto/1235379/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235379',
    'https://siilaspot.com.br/lease/office/parana/curitiba/centro-corporativo-hamilcar-pizzatto/1235379/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/76785',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/vila-olimpia-corporate-plaza/76785/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/76785',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/vila-olimpia-corporate-plaza/76785/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235792',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/centro-medico-p-gomide/1235792/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235792',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/centro-medico-p-gomide/1235792/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237735',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-paulo/syslog-sao-paulo-i/1237735/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237735',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/syslog-sao-paulo-i/1237735/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238034',
    'https://siilaspot.com.br/venda/industrial/ceara/fortaleza/log-fortaleza-ii-expansao-i-inativo/1238034/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238034',
    'https://siilaspot.com.br/sale/industrial/ceara/fortaleza/log-fortaleza-ii-expansao-i-/1238034/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238034',
    'https://siilaspot.com.br/locacao/industrial/ceara/fortaleza/log-fortaleza-ii-expansao-i-inativo/1238034/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238034',
    'https://siilaspot.com.br/lease/industrial/ceara/fortaleza/log-fortaleza-ii-expansao-i-/1238034/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600221',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/louveira/hglg-louveira/600221/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600221',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/louveira/hglg-louveira/600221/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/23765',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/rio-de-janeiro/armazem-herculano-pinheiro-1001/23765/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/23765',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/armazem-herculano-pinheiro-1001/23765/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238386',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sorocaba/armazem-hollingsworth/1238386/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238386',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sorocaba/armazem-hollingsworth/1238386/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500579',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/vista-guanabara/500579/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500579',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/vista-guanabara/500579/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500262',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-2-new-york/500262/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500262',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-2-new-york/500262/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500262',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-2-new-york/500262/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500262',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-2-new-york/500262/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500044',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500044',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238302',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/rio-de-janeiro/armazem-luis-coutinho-cavalcanti-649/1238302/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238302',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/armazem-luis-coutinho-cavalcanti-649/1238302/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1237445',
    'https://siilaspot.com.br/venda/industrial/ceara/itaitinga/log-fortaleza-ii/1237445/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1237445',
    'https://siilaspot.com.br/sale/industrial/ceara/itaitinga/log-fortaleza-ii/1237445/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237445',
    'https://siilaspot.com.br/locacao/industrial/ceara/itaitinga/log-fortaleza-ii/1237445/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237445',
    'https://siilaspot.com.br/lease/industrial/ceara/itaitinga/log-fortaleza-ii/1237445/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500193',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/atrium-v/500193/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500193',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/atrium-v/500193/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/501142',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/aroeira-office-park/501142/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/501142',
    'https://siilaspot.com.br/lease/office/parana/curitiba/aroeira-office-park/501142/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/501142',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/aroeira-office-park/501142/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/501142',
    'https://siilaspot.com.br/sale/office/parana/curitiba/aroeira-office-park/501142/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/78562',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/corporate-plaza-chacara-santo-antonio/78562/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/78562',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/corporate-plaza-chacara-santo-antonio/78562/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238245',
    'https://siilaspot.com.br/locacao/industrial/pernambuco/escada/galpao-isolado-rod-br-101-sul-km-130/1238245/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238245',
    'https://siilaspot.com.br/lease/industrial/pernambuco/escada/stand-alone-rod-br-101-sul-km-130/1238245/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238328',
    'https://siilaspot.com.br/venda/industrial/sao-paulo/sao-paulo/galpao-isolado-rua-albertina-de-souza-242/1238328/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238328',
    'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-rua-albertina-de-souza-242/1238328/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238326',
    'https://siilaspot.com.br/venda/industrial/sao-paulo/sao-paulo/galpao-isolado-rua-amador-bueno-176/1238326/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238326',
    'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-rua-amador-bueno-176/1238326/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238305',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-paulo/armazem-rua-edmundo-carvalho-430/1238305/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238305',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/armazem-rua-edmundo-carvalho-430/1238305/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238331',
    'https://siilaspot.com.br/venda/industrial/sao-paulo/sao-paulo/galpao-isolado-rua-robert-bosch-600/1238331/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238331',
    'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-rua-robert-bosch-600/1238331/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1235061',
    'https://siilaspot.com.br/locacao/industrial/sergipe/aracaju/log-aracaju-expansao-ii/1235061/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1235061',
    'https://siilaspot.com.br/lease/industrial/sergipe/aracaju/log-aracaju-expansao-ii/1235061/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1235061',
    'https://siilaspot.com.br/venda/industrial/sergipe/aracaju/log-aracaju-expansao-ii/1235061/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1235061',
    'https://siilaspot.com.br/sale/industrial/sergipe/aracaju/log-aracaju-expansao-ii/1235061/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500227',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/jardim-europa/500227/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500227',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/jardim-europa/500227/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238340',
    'https://siilaspot.com.br/locacao/industrial/distrito-federal/brasilia/galpao-isolado-setor-de-postos-e-moteis-sul-df-003-1-brasilia/1238340/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238340',
    'https://siilaspot.com.br/lease/industrial/distrito-federal/brasilia/stand-alone-setor-de-postos-e-moteis-sul-df-003-1-brasilia/1238340/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500174',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/cenu-centro-empresarial-nacoes-unidas-torre-oeste/500174/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500174',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/cenu-centro-empresarial-nacoes-unidas-torre-oeste/500174/en'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/escritorio/(.*)/(.*)/25067',
    'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/tenerife-bloco-a/25067/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/office/(.*)/(.*)/25067',
    'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/tenerife-bloco-a/25067/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500089',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-2/500089/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500089',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-2/500089/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500089',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-2/500089/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500089',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-2/500089/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/175254',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-o/175254/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/175254',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-o/175254/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/175254',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-o/175254/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/175254',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-o/175254/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500786',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/arcadas/500786/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500786',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/arcadas/500786/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500786',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/arcadas/500786/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500786',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/arcadas/500786/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/109783',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/palladio/109783/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/109783',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/palladio/109783/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500570',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/bvep-nigri-plaza/500570/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500570',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/bvep-nigri-plaza/500570/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500570',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/bvep-nigri-plaza/500570/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500570',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/bvep-nigri-plaza/500570/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500021',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/complexo-jk-bloco-b-daslu-/500021/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500021',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/complexo-jk-bloco-b-daslu-/500021/en'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/escritorio/(.*)/(.*)/500021',
    'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/complexo-jk-bloco-b-daslu-/500021/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/office/(.*)/(.*)/500021',
    'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/complexo-jk-bloco-b-daslu-/500021/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500681',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/avenida-escritorios/500681/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500681',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/avenida-escritorios/500681/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500681',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/avenida-escritorios/500681/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500681',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/avenida-escritorios/500681/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/30793',
    'https://siilaspot.com.br/locacao/industrial/santa-catarina/itajai/mega-centro-logistico-itajai/30793/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30793',
    'https://siilaspot.com.br/lease/industrial/santa-catarina/itajai/mega-centro-logistico-itajai/30793/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/30793',
    'https://siilaspot.com.br/venda/industrial/santa-catarina/itajai/mega-centro-logistico-itajai/30793/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/30793',
    'https://siilaspot.com.br/sale/industrial/santa-catarina/itajai/mega-centro-logistico-itajai/30793/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237707',
    'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/gravatai/log-gravatai/1237707/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237707',
    'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/gravatai/log-gravatai/1237707/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/938036',
    'https://siilaspot.com.br/locacao/industrial/parana/sao-jose-dos-pinhais/portal-do-porto/938036/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/938036',
    'https://siilaspot.com.br/lease/industrial/parana/sao-jose-dos-pinhais/portal-do-porto/938036/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/938036',
    'https://siilaspot.com.br/venda/industrial/parana/sao-jose-dos-pinhais/portal-do-porto/938036/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/938036',
    'https://siilaspot.com.br/sale/industrial/parana/sao-jose-dos-pinhais/portal-do-porto/938036/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500486',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/cgd-corporate-towers-torre-2/500486/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500486',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/cgd-corporate-towers-torre-2/500486/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500277',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-3-sao-paulo/500277/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500277',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-3-sao-paulo/500277/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500277',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-3-sao-paulo/500277/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500277',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-3-sao-paulo/500277/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500830',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/alphabusiness-bloco-manaca/500830/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500830',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/alphabusiness-bloco-manaca/500830/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500830',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/alphabusiness-bloco-manaca/500830/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500830',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/alphabusiness-bloco-manaca/500830/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235841',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/conde-luiz-eduardo-matarazzo/1235841/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235841',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/conde-luiz-eduardo-matarazzo/1235841/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238152',
    'https://siilaspot.com.br/locacao/industrial/pernambuco/cabo-de-santo-agostinho/log-recife/1238152/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238152',
    'https://siilaspot.com.br/lease/industrial/pernambuco/cabo-de-santo-agostinho/log-recife/1238152/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500287',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/santa-catarina/500287/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500287',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/santa-catarina/500287/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1091088',
    'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/capital-financial-center-torre-a/1091088/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1091088',
    'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/capital-financial-center-torre-a/1091088/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1091088',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/capital-financial-center-torre-a/1091088/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1091088',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/capital-financial-center-torre-a/1091088/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1091092',
    'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/capital-financial-center-torre-b/1091092/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1091092',
    'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/capital-financial-center-torre-b/1091092/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1091092',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/capital-financial-center-torre-b/1091092/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1091092',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/capital-financial-center-torre-b/1091092/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/501126',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/trend-city-center-torre-corporate/501126/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/501126',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/trend-city-center-torre-corporate/501126/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238425',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/maua/urban-hub-maua/1238425/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238425',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/maua/urban-hub-maua/1238425/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1237065',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/campos-dos-goytacazes/log-campos-expansao-ii/1237065/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1237065',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/campos-dos-goytacazes/log-campos-expansion-ii/1237065/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237065',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/campos-dos-goytacazes/log-campos-expansao-ii/1237065/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237065',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/campos-dos-goytacazes/log-campos-expansion-ii/1237065/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/435214',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/duque-de-caxias/golgi-duque-de-caxias/435214/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/435214',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/golgi-duque-de-caxias/435214/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/435214',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/duque-de-caxias/golgi-duque-de-caxias/435214/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/435214',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/golgi-duque-de-caxias/435214/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500966',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/neo-corporate/500966/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500966',
    'https://siilaspot.com.br/lease/office/parana/curitiba/neo-corporate/500966/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500192',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/alvino-slaviero/500192/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500192',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/alvino-slaviero/500192/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500101',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-castelo-branco/500101/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500101',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-castelo-branco/500101/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237847',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/hglg-extrema-i/1237847/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237847',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/hglg-extrema-i/1237847/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1237847',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/extrema/hglg-extrema-i/1237847/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1237847',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/extrema/hglg-extrema-i/1237847/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500367',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/mykonos/500367/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500367',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/mykonos/500367/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/10598',
    'https://siilaspot.com.br/locacao/industrial/amazonas/manaus/distribution-park-manaus-i/10598/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/10598',
    'https://siilaspot.com.br/lease/industrial/amazonas/manaus/distribution-park-manaus-i/10598/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/10598',
    'https://siilaspot.com.br/venda/industrial/amazonas/manaus/distribution-park-manaus-i/10598/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/10598',
    'https://siilaspot.com.br/sale/industrial/amazonas/manaus/distribution-park-manaus-i/10598/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600234',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/campos-dos-goytacazes/log-campos/600234/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600234',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/campos-dos-goytacazes/log-campos/600234/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600234',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/campos-dos-goytacazes/log-campos/600234/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600234',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/campos-dos-goytacazes/log-campos/600234/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500264',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-6/500264/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500264',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-6/500264/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500264',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-6/500264/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500264',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-6/500264/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1237848',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/extrema/hglg-extrema-ii/1237848/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1237848',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/extrema/hglg-extrema-ii/1237848/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237848',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/hglg-extrema-ii/1237848/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237848',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/hglg-extrema-ii/1237848/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500240',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/morumbi-corporate-diamond-tower/500240/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500240',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/morumbi-corporate-diamond-tower/500240/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500611',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/bds-riachuelo/500611/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500611',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/bds-riachuelo/500611/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500611',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/bds-riachuelo/500611/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500611',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/bds-riachuelo/500611/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500296',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/seculum/500296/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500296',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/seculum/500296/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600238',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/itatiaia/log-itatiaia/600238/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600238',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/itatiaia/log-itatiaia/600238/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600238',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/itatiaia/log-itatiaia/600238/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600238',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/itatiaia/log-itatiaia/600238/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500489',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/centro-administrativo-rio-negro-torre-jacari/500489/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500489',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-jacari/500489/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500489',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/centro-administrativo-rio-negro-torre-jacari/500489/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500489',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-jacari/500489/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600082',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/embu-das-artes/dcr-rodoanel-centro-logistico/600082/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600082',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/embu-das-artes/dcr-rodoanel-centro-logistico/600082/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235524',
    'https://siilaspot.com.br/venda/escritorio/minas-gerais/belo-horizonte/atlantico-belo-horizonte/1235524/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235524',
    'https://siilaspot.com.br/sale/office/minas-gerais/belo-horizonte/atlantico-belo-horizonte/1235524/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235524',
    'https://siilaspot.com.br/locacao/escritorio/minas-gerais/belo-horizonte/atlantico-belo-horizonte/1235524/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235524',
    'https://siilaspot.com.br/lease/office/minas-gerais/belo-horizonte/atlantico-belo-horizonte/1235524/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500222',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/infinity-tower/500222/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500222',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/infinity-tower/500222/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235049',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/corporate-financial-center/1235049/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235049',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/corporate-financial-center/1235049/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500208',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/e-tower/500208/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500208',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/e-tower/500208/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500319',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/vera-cruz-ii/500319/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500319',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/vera-cruz-ii/500319/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600081',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/embu-das-artes/cle-embu-centro-logistico/600081/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600081',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/embu-das-artes/cle-embu-centro-logistico/600081/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500310',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/torre-matarazzo/500310/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500310',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/torre-matarazzo/500310/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1235059',
    'https://siilaspot.com.br/venda/industrial/sergipe/aracaju/log-aracaju/1235059/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1235059',
    'https://siilaspot.com.br/sale/industrial/sergipe/aracaju/log-aracaju/1235059/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1235059',
    'https://siilaspot.com.br/locacao/industrial/sergipe/aracaju/log-aracaju/1235059/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1235059',
    'https://siilaspot.com.br/lease/industrial/sergipe/aracaju/log-aracaju/1235059/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/10325',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/capital-plaza/10325/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10325',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/capital-plaza/10325/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/682566',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/monumento/682566/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/682566',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/monumento/682566/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500263',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-4/500263/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500263',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-4/500263/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500263',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-4/500263/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500263',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-4/500263/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500121',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/b32-birmann-32/500121/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500121',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/b32-birmann-32/500121/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/497161',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/conde-de-prates/497161/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/497161',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/conde-de-prates/497161/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500490',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/centro-administrativo-rio-negro-torre-jauaperi/500490/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500490',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-jauaperi/500490/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/832571',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/itau-paulista/832571/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/832571',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/itau-paulista/832571/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500911',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/centro-comercial-italia/500911/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500911',
    'https://siilaspot.com.br/lease/office/parana/curitiba/centro-comercial-italia/500911/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500911',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/centro-comercial-italia/500911/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500911',
    'https://siilaspot.com.br/sale/office/parana/curitiba/centro-comercial-italia/500911/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238294',
    'https://siilaspot.com.br/locacao/industrial/bahia/simoes-filho/log-salvador/1238294/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238294',
    'https://siilaspot.com.br/lease/industrial/bahia/simoes-filho/log-salvador/1238294/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238294',
    'https://siilaspot.com.br/venda/industrial/bahia/simoes-filho/log-salvador/1238294/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238294',
    'https://siilaspot.com.br/sale/industrial/bahia/simoes-filho/log-salvador/1238294/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500139',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-europa/500139/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500139',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-europa/500139/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500139',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-europa/500139/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500139',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-europa/500139/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500505',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/crystal-tower-alphaville/500505/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500505',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/crystal-tower-alphaville/500505/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500373',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/centro-empresarial-transatlantico/500373/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500373',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/centro-empresarial-transatlantico/500373/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/705238',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/amazonia/705238/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/705238',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/amazonia/705238/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/705238',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/amazonia/705238/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/705238',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/amazonia/705238/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600319',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias/600319/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600319',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias/600319/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600319',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias/600319/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600319',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias/600319/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235383',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/centro-empresarial-jatoba/1235383/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235383',
    'https://siilaspot.com.br/sale/office/parana/curitiba/centro-empresarial-jatoba/1235383/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235383',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/centro-empresarial-jatoba/1235383/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235383',
    'https://siilaspot.com.br/lease/office/parana/curitiba/centro-empresarial-jatoba/1235383/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500217',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/fujitsu/500217/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500217',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/fujitsu/500217/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500020',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/sao-paulo-corporate-towers-torre-sul/500020/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500020',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/sao-paulo-corporate-towers-torre-sul/500020/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/76762',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-pilar/76762/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/76762',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-pilar/76762/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238057',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/1550-batel/1238057/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238057',
    'https://siilaspot.com.br/lease/office/parana/curitiba/1550-batel/1238057/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500304',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/spazio-jk/500304/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500304',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/spazio-jk/500304/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/501007',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/carlos-gomes-center/501007/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/501007',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/carlos-gomes-center/501007/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/501007',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/carlos-gomes-center/501007/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/501007',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/carlos-gomes-center/501007/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235346',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/pontal-do-estaleiro/1235346/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235346',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/pontal-do-estaleiro/1235346/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/76044',
    'https://siilaspot.com.br/venda/escritorio/minas-gerais/belo-horizonte/boulevard-corporate-tower/76044/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/76044',
    'https://siilaspot.com.br/sale/office/minas-gerais/belo-horizonte/boulevard-corporate-tower/76044/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/472466',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/contagem/bresco-contagem/472466/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/472466',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/contagem/bresco-contagem/472466/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/472466',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/contagem/bresco-contagem/472466/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/472466',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/contagem/bresco-contagem/472466/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500160',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/bfc-brazilian-financial-center/500160/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500160',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bfc-brazilian-financial-center/500160/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238173',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/queimados/cargo-center-dutra-v-expansao-i-inativo/1238173/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238173',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/queimados/cargo-center-dutra-v-expansion-i/1238173/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238173',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/queimados/cargo-center-dutra-v-expansao-i-inativo/1238173/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238173',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/queimados/cargo-center-dutra-v-expansion-i/1238173/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500998',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/atrium-center/500998/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500998',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/atrium-center/500998/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500998',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/atrium-center/500998/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500998',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/atrium-center/500998/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238431',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/corporativo-faria-lima/1238431/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238431',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/corporativo-faria-lima/1238431/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500026',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/brookfield-place-barra-da-tijuca-pacific-tower/500026/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500026',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/brookfield-place-barra-da-tijuca-pacific-tower/500026/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500026',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/brookfield-place-barra-da-tijuca-pacific-tower/500026/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500026',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/brookfield-place-barra-da-tijuca-pacific-tower/500026/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500232',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/jk-financial-center/500232/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500232',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/jk-financial-center/500232/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600288',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/duque-de-caxias/prologis-caxias/600288/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600288',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/prologis-caxias/600288/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600288',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/duque-de-caxias/prologis-caxias/600288/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600288',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/prologis-caxias/600288/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237997',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/crespi-ii/1237997/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237997',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/crespi-ii/1237997/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500353',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/atrium-iii/500353/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500353',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/atrium-iii/500353/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/111790',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-tokio-marine-seguradora/111790/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/111790',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-tokio-marine-seguradora/111790/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500313',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/torre-sul/500313/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500313',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/torre-sul/500313/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/820723',
    'https://siilaspot.com.br/locacao/industrial/amazonas/manaus/distribution-park-manaus-ii/820723/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/820723',
    'https://siilaspot.com.br/lease/industrial/amazonas/manaus/distribution-park-manaus-ii/820723/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/820723',
    'https://siilaspot.com.br/venda/industrial/amazonas/manaus/distribution-park-manaus-ii/820723/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/820723',
    'https://siilaspot.com.br/sale/industrial/amazonas/manaus/distribution-park-manaus-ii/820723/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500283',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-7/500283/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500283',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-7/500283/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500283',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-7/500283/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500283',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-7/500283/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500137',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/bolsa-de-valores-do-rio-de-janeiro/500137/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500137',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/bolsa-de-valores-do-rio-de-janeiro/500137/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500137',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/bolsa-de-valores-do-rio-de-janeiro/500137/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500137',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/bolsa-de-valores-do-rio-de-janeiro/500137/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500909',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/ceap-centro-empresarial-augusto-prolik/500909/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500909',
    'https://siilaspot.com.br/sale/office/parana/curitiba/ceap-centro-empresarial-augusto-prolik/500909/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500909',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/ceap-centro-empresarial-augusto-prolik/500909/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500909',
    'https://siilaspot.com.br/lease/office/parana/curitiba/ceap-centro-empresarial-augusto-prolik/500909/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1237319',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/oscar-freire-design-offices/1237319/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1237319',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/oscar-freire-design-offices/1237319/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500467',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-brasilprev-ala-c/500467/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500467',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-brasilprev-ala-c/500467/en'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/escritorio/(.*)/(.*)/1237157',
    'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/sao-jose-20/1237157/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/office/(.*)/(.*)/1237157',
    'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/sao-jose-20/1237157/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500567',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/marques-dos-reis/500567/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500567',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/marques-dos-reis/500567/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500284',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-1/500284/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500284',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-1/500284/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500284',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-1/500284/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500284',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-1/500284/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238327',
    'https://siilaspot.com.br/venda/industrial/sao-paulo/sao-paulo/galpao-isolado-rua-duarte-de-azevedo-792/1238327/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238327',
    'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-rua-duarte-de-azevedo-792/1238327/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238290',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/duque-de-caxias/condominio-cl-duque-ex-bz-log-expansao-i/1238290/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238290',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/condominio-cl-duque-ex-bz-log-expansao-i/1238290/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238290',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/duque-de-caxias/condominio-cl-duque-ex-bz-log-expansao-i/1238290/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238290',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/condominio-cl-duque-ex-bz-log-expansao-i/1238290/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235368',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/forum-tower/1235368/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235368',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/forum-tower/1235368/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/186109',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-paulo/armazem/186109/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/186109',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/armazem/186109/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500943',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/eurobusiness/500943/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500943',
    'https://siilaspot.com.br/lease/office/parana/curitiba/eurobusiness/500943/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500258',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/barra-trade-iii/500258/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500258',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/barra-trade-iii/500258/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500258',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/barra-trade-iii/500258/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500258',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/barra-trade-iii/500258/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/574206',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/antonio-cintra-gordinho/574206/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/574206',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/antonio-cintra-gordinho/574206/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/574206',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/antonio-cintra-gordinho/574206/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/574206',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/antonio-cintra-gordinho/574206/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600251',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/cajamar/cajamar-centro-logistico-ccl/600251/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600251',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/cajamar-centro-logistico-ccl/600251/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500087',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/americas-avenue-business-square-central-america/500087/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500087',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/americas-avenue-business-square-central-america/500087/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500087',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/americas-avenue-business-square-central-america/500087/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500087',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/americas-avenue-business-square-central-america/500087/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238198',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/infinity-business-park/1238198/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238198',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/infinity-business-park/1238198/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238198',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/extrema/infinity-business-park/1238198/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238198',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/extrema/infinity-business-park/1238198/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1090745',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/centro-empresarial-varig-petala-b/1090745/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1090745',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/centro-empresarial-varig-petala-b/1090745/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1091095',
    'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/capital-financial-center-torre-c/1091095/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1091095',
    'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/capital-financial-center-torre-c/1091095/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1091095',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/capital-financial-center-torre-c/1091095/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1091095',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/capital-financial-center-torre-c/1091095/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238223',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/rio-de-janeiro/unilogistica-business-park-ii-rj/1238223/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238223',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/rio-de-janeiro/unilogistica-business-park-ii-rj/1238223/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238223',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/rio-de-janeiro/unilogistica-business-park-ii-rj/1238223/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238223',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/unilogistica-business-park-ii-rj/1238223/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500309',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/torre-joao-salem/500309/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500309',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/torre-joao-salem/500309/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235047',
    'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/edificio-zariffe/1235047/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235047',
    'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/edificio-zariffe/1235047/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235378',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/bbc-barigui-business-center/1235378/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235378',
    'https://siilaspot.com.br/sale/office/parana/curitiba/bbc-barigui-business-center/1235378/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235378',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/bbc-barigui-business-center/1235378/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235378',
    'https://siilaspot.com.br/lease/office/parana/curitiba/bbc-barigui-business-center/1235378/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237802',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/edificio-barao-geraldo-de-resende/1237802/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237802',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/edificio-barao-geraldo-de-resende/1237802/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500652',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/square-955/500652/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500652',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/square-955/500652/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500059',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/wtorre-morumbi-ala-b/500059/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500059',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/wtorre-morumbi-ala-b/500059/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238355',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/patio-reboucas/1238355/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238355',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/patio-reboucas/1238355/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237218',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/union-tower/1237218/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237218',
    'https://siilaspot.com.br/lease/office/parana/curitiba/union-tower/1237218/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500465',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-brasilprev-ala-a/500465/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500465',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-brasilprev-ala-a/500465/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500049',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/rochavera-corporate-towers-torre-b-marble-tower-/500049/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500049',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/rochavera-corporate-towers-torre-b-marble-tower-/500049/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/501013',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/centro-empresarial-mostardeiro/501013/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/501013',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/centro-empresarial-mostardeiro/501013/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/501013',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/centro-empresarial-mostardeiro/501013/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/501013',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/centro-empresarial-mostardeiro/501013/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500009',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/atrium-ix/500009/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500009',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/atrium-ix/500009/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500379',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/gt-plaza/500379/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500379',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/gt-plaza/500379/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500017',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/sao-paulo-corporate-towers-torre-norte/500017/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500017',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/sao-paulo-corporate-towers-torre-norte/500017/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500170',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/rio-metropolitan/500170/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500170',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/rio-metropolitan/500170/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238202',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/e2-viracopos/1238202/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238202',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/e2-viracopos/1238202/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238447',
    'https://siilaspot.com.br/locacao/escritorio/minas-gerais/belo-horizonte/edificio-statement/1238447/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238447',
    'https://siilaspot.com.br/lease/office/minas-gerais/belo-horizonte/edificio-statement/1238447/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/954527',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/villa-offices/954527/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/954527',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/villa-offices/954527/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500915',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/centro-empresarial-jose-joaquim/500915/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500915',
    'https://siilaspot.com.br/lease/office/parana/curitiba/centro-empresarial-jose-joaquim/500915/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500915',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/centro-empresarial-jose-joaquim/500915/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500915',
    'https://siilaspot.com.br/sale/office/parana/curitiba/centro-empresarial-jose-joaquim/500915/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500231',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/jk-180/500231/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500231',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/jk-180/500231/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500013',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/auri-plaza-faria-lima/500013/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500013',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/auri-plaza-faria-lima/500013/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500831',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/alphabusiness-bloco-ipe/500831/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500831',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/alphabusiness-bloco-ipe/500831/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500831',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/alphabusiness-bloco-ipe/500831/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500831',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/alphabusiness-bloco-ipe/500831/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500559',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-arcos-da-lapa/500559/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500559',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-arcos-da-lapa/500559/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500559',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-arcos-da-lapa/500559/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500559',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-arcos-da-lapa/500559/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/819030',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/candido-mendes/819030/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/819030',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/candido-mendes/819030/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/819030',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/candido-mendes/819030/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/819030',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/candido-mendes/819030/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500194',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/visconde-de-inhauma-corporate/500194/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500194',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/visconde-de-inhauma-corporate/500194/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/530070',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/575-norte-sul/530070/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/530070',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/575-norte-sul/530070/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/530070',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/575-norte-sul/530070/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500317',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/unibanco/500317/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500317',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/unibanco/500317/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235270',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/loft-office-sao-paulo/1235270/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235270',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/loft-office-sao-paulo/1235270/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238180',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/extrema/bwdiase-business-park-extrema/1238180/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238180',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/extrema/bwdiase-business-park-extrema/1238180/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238180',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/bwdiase-business-park-extrema/1238180/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238180',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/bwdiase-business-park-extrema/1238180/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500288',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-2/500288/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500288',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-2/500288/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500288',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-2/500288/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500288',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-2/500288/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500356',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-antonio-carlos/500356/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500356',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-antonio-carlos/500356/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238349',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/passeio-paulista/1238349/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238349',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/passeio-paulista/1238349/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500615',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/lead-americas-business/500615/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500615',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/lead-americas-business/500615/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/303446',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/banco-do-estado-de-sao-paulo/303446/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/303446',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/banco-do-estado-de-sao-paulo/303446/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/303446',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/banco-do-estado-de-sao-paulo/303446/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/303446',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/banco-do-estado-de-sao-paulo/303446/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235040',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/centro-empresarial-joao-gualberto/1235040/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235040',
    'https://siilaspot.com.br/lease/office/parana/curitiba/centro-empresarial-joao-gualberto/1235040/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235040',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/centro-empresarial-joao-gualberto/1235040/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235040',
    'https://siilaspot.com.br/sale/office/parana/curitiba/centro-empresarial-joao-gualberto/1235040/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235381',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/business-tower/1235381/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235381',
    'https://siilaspot.com.br/lease/office/parana/curitiba/business-tower/1235381/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235381',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/business-tower/1235381/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235381',
    'https://siilaspot.com.br/sale/office/parana/curitiba/business-tower/1235381/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/501005',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/capital-tower/501005/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/501005',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/capital-tower/501005/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/501005',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/capital-tower/501005/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/501005',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/capital-tower/501005/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500135',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/bozano-simonsen/500135/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500135',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/bozano-simonsen/500135/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500135',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/bozano-simonsen/500135/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500135',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/bozano-simonsen/500135/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500218',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/gc-corporate/500218/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500218',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/gc-corporate/500218/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500218',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/gc-corporate/500218/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500218',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/gc-corporate/500218/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/11228',
    'https://siilaspot.com.br/locacao/industrial/santa-catarina/joinville/perini-business-park/11228/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/11228',
    'https://siilaspot.com.br/lease/industrial/santa-catarina/joinville/perini-business-park/11228/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235185',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-alamedas/1235185/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235185',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-alamedas/1235185/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600358',
    'https://siilaspot.com.br/venda/industrial/parana/quatro-barras/logbras-quatro-barras/600358/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600358',
    'https://siilaspot.com.br/sale/industrial/parana/quatro-barras/logbras-quatro-barras/600358/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600358',
    'https://siilaspot.com.br/locacao/industrial/parana/quatro-barras/logbras-quatro-barras/600358/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600358',
    'https://siilaspot.com.br/lease/industrial/parana/quatro-barras/logbras-quatro-barras/600358/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500077',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/centro-administrativo-santo-amaro-bloco-b-edificio-triunfo-/500077/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500077',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/centro-administrativo-santo-amaro-bloco-b-edificio-triunfo-/500077/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1237133',
    'https://siilaspot.com.br/venda/industrial/bahia/lauro-de-freitas/bresco-bahia/1237133/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1237133',
    'https://siilaspot.com.br/sale/industrial/bahia/lauro-de-freitas/bresco-bahia/1237133/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237133',
    'https://siilaspot.com.br/locacao/industrial/bahia/lauro-de-freitas/bresco-bahia/1237133/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237133',
    'https://siilaspot.com.br/lease/industrial/bahia/lauro-de-freitas/bresco-bahia/1237133/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600355',
    'https://siilaspot.com.br/locacao/industrial/amazonas/manaus/distribution-park-manaus-iii/600355/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600355',
    'https://siilaspot.com.br/lease/industrial/amazonas/manaus/distribution-park-manaus-iii/600355/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600355',
    'https://siilaspot.com.br/venda/industrial/amazonas/manaus/distribution-park-manaus-iii/600355/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600355',
    'https://siilaspot.com.br/sale/industrial/amazonas/manaus/distribution-park-manaus-iii/600355/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500557',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/gloria-122/500557/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500557',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/gloria-122/500557/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237729',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/cajamar/brpr-cajamar/1237729/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237729',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/brpr-cajamar/1237729/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238215',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/guarulhos/parque-logistico-guarulhos-ii/1238215/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238215',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/guarulhos/parque-logistico-guarulhos-ii/1238215/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1237993',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/pouso-alegre/condominio-pouso-alegre-expansao-ii-inativo/1237993/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1237993',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/pouso-alegre/condominio-pouso-alegre-expansao-ii-/1237993/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237993',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/pouso-alegre/condominio-pouso-alegre-expansao-ii-inativo/1237993/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237993',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/pouso-alegre/condominio-pouso-alegre-expansao-ii-/1237993/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500477',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/brascan-century-plaza-green-valley-torre-corporate/500477/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500477',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/brascan-century-plaza-green-valley-torre-corporate/500477/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500477',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/brascan-century-plaza-green-valley-torre-corporate/500477/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500477',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/brascan-century-plaza-green-valley-torre-corporate/500477/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/11167',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/centro-empresarial-guaiba/11167/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11167',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/centro-empresarial-guaiba/11167/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/11167',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/centro-empresarial-guaiba/11167/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/11167',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/centro-empresarial-guaiba/11167/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/175253',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-n/175253/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/175253',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-n/175253/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/175253',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-n/175253/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/175253',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-n/175253/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/175256',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-p/175256/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/175256',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-p/175256/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/175256',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-p/175256/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/175256',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-p/175256/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500554',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/atlantica-business-center/500554/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500554',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/atlantica-business-center/500554/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500554',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/atlantica-business-center/500554/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500554',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/atlantica-business-center/500554/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600292',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/queimados/prologis-queimados/600292/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600292',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/queimados/prologis-queimados/600292/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600292',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/queimados/prologis-queimados/600292/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600292',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/queimados/prologis-queimados/600292/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/193998',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/barra-prime-offices-bloco-1/193998/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/193998',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/barra-prime-offices-bloco-1/193998/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/193998',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/barra-prime-offices-bloco-1/193998/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/193998',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/barra-prime-offices-bloco-1/193998/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500487',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/centro-administrativo-rio-negro-torre-demini/500487/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500487',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-demini/500487/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500487',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/centro-administrativo-rio-negro-torre-demini/500487/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500487',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-demini/500487/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238230',
    'https://siilaspot.com.br/locacao/industrial/santa-catarina/biguacu/gcr-business-park/1238230/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238230',
    'https://siilaspot.com.br/lease/industrial/santa-catarina/biguacu/gcr-business-park/1238230/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238230',
    'https://siilaspot.com.br/venda/industrial/santa-catarina/biguacu/gcr-business-park/1238230/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238230',
    'https://siilaspot.com.br/sale/industrial/santa-catarina/biguacu/gcr-business-park/1238230/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500314',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/torre-z/500314/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500314',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/torre-z/500314/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500069',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/birmann-21/500069/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500069',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/birmann-21/500069/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/25289',
    'https://siilaspot.com.br/venda/industrial/parana/pinhais/log-trade-pinhais/25289/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/25289',
    'https://siilaspot.com.br/sale/industrial/parana/pinhais/log-trade-pinhais/25289/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/25289',
    'https://siilaspot.com.br/locacao/industrial/parana/pinhais/log-trade-pinhais/25289/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/25289',
    'https://siilaspot.com.br/lease/industrial/parana/pinhais/log-trade-pinhais/25289/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237981',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/luna-nova/1237981/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237981',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/luna-nova/1237981/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500102',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-cidade-nova-teleporto/500102/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500102',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-cidade-nova-teleporto/500102/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235025',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/safra-frei-caneca/1235025/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235025',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/safra-frei-caneca/1235025/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500041',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/alvaro-cumplido-de-sant-anna-anm-retrofit-/500041/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500041',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/alvaro-cumplido-de-sant-anna-anm-retrofit-/500041/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500041',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/alvaro-cumplido-de-sant-anna-anm-retrofit-/500041/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500041',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/alvaro-cumplido-de-sant-anna-anm-retrofit-/500041/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500209',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/centro-administrativo-santo-amaro-bloco-d-edificio-conquista-/500209/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500209',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/centro-administrativo-santo-amaro-bloco-d-edificio-conquista-/500209/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1091111',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/carlton-tower/1091111/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1091111',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/carlton-tower/1091111/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1091111',
    'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/carlton-tower/1091111/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1091111',
    'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/carlton-tower/1091111/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235399',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/ebpark-predio-01/1235399/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235399',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ebpark-predio-01/1235399/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/904605',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/duque-de-caxias/condominio-cl-duque-ex-bz-log-/904605/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/904605',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/condominio-cl-duque-ex-bz-log-/904605/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/904605',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/duque-de-caxias/condominio-cl-duque-ex-bz-log-/904605/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/904605',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/condominio-cl-duque-ex-bz-log-/904605/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/131327',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/queimados/cargo-center-dutra-v/131327/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/131327',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/queimados/cargo-center-dutra-v/131327/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/131327',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/queimados/cargo-center-dutra-v/131327/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/131327',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/queimados/cargo-center-dutra-v/131327/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1235333',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/duque-de-caxias/golgi-duque-de-caxias-expansao/1235333/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1235333',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/golgi-duque-de-caxias-expansion/1235333/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1235333',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/duque-de-caxias/golgi-duque-de-caxias-expansao/1235333/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1235333',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/golgi-duque-de-caxias-expansion/1235333/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/44559',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/morumbi-office-tower/44559/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/44559',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/morumbi-office-tower/44559/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/30527',
    'https://siilaspot.com.br/locacao/industrial/parana/araucaria/ecopark-araucaria/30527/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30527',
    'https://siilaspot.com.br/lease/industrial/parana/araucaria/ecopark-araucaria/30527/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/30527',
    'https://siilaspot.com.br/venda/industrial/parana/araucaria/ecopark-araucaria/30527/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/30527',
    'https://siilaspot.com.br/sale/industrial/parana/araucaria/ecopark-araucaria/30527/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/382101',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/pouso-alegre/condominio-pouso-alegre/382101/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/382101',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/pouso-alegre/condominio-pouso-alegre/382101/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/382101',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/pouso-alegre/condominio-pouso-alegre/382101/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/382101',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/pouso-alegre/condominio-pouso-alegre/382101/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1225429',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/extrema-business-park-bloco-i/1225429/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1225429',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/extrema-business-park-bloco-i/1225429/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238345',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/ribeirao-das-neves/brz040-logistics-park/1238345/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238345',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/ribeirao-das-neves/brz040-logistics-park/1238345/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235166',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/yervant-kissajikian/1235166/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235166',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/yervant-kissajikian/1235166/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/368407',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/queimados/prologis-queimados-expansao-i/368407/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/368407',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/queimados/prologis-queimados-expansion-i/368407/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/368407',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/queimados/prologis-queimados-expansao-i/368407/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/368407',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/queimados/prologis-queimados-expansion-i/368407/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/19526',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/campinas/flex-viracopos/19526/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/19526',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/campinas/flex-viracopos/19526/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500522',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/ibm/500522/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500522',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ibm/500522/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600286',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/santana-de-parnaiba/prologis-castelo-41/600286/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600286',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/santana-de-parnaiba/prologis-castelo-41/600286/en'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/escritorio/(.*)/(.*)/443414',
    'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/bsp-consolacao-prime-boulevard/443414/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/office/(.*)/(.*)/443414',
    'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/bsp-consolacao-prime-boulevard/443414/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1236364',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/contagem/condominio-logistico-sindi-investimentos-ii/1236364/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1236364',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/contagem/condominio-logistico-sindi-investimentos-ii/1236364/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1236364',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/contagem/condominio-logistico-sindi-investimentos-ii/1236364/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1236364',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/contagem/condominio-logistico-sindi-investimentos-ii/1236364/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238381',
    'https://siilaspot.com.br/locacao/industrial/distrito-federal/brasilia/log-brasilia/1238381/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238381',
    'https://siilaspot.com.br/lease/industrial/distrito-federal/brasilia/log-brasilia/1238381/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500100',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-botafogo/500100/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500100',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-botafogo/500100/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500100',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-botafogo/500100/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500100',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-botafogo/500100/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/531549',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/arthur-1212/531549/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/531549',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/arthur-1212/531549/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500008',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/360-jk/500008/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500008',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/360-jk/500008/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/360994',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias-expansao-i/360994/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/360994',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias-expansion-i/360994/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/360994',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias-expansao-i/360994/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/360994',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias-expansion-i/360994/en'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/escritorio/(.*)/(.*)/834052',
    'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/capitao-antonio-rosa/834052/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/office/(.*)/(.*)/834052',
    'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/capitao-antonio-rosa/834052/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600070',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/cajamar/dcc-distribution-center-cajamar/600070/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600070',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/dcc-distribution-center-cajamar/600070/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/34540',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/cajamar/condominio-centeranel-cajamar/34540/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34540',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/condominio-centeranel-cajamar/34540/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238228',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/betim/betim-business-park/1238228/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238228',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/betim/betim-business-park/1238228/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238228',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/betim/betim-business-park/1238228/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238228',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/betim/betim-business-park/1238228/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500042',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/parque-da-cidade-torre-corporativa-jequitiba/500042/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500042',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/parque-da-cidade-torre-corporativa-jequitiba/500042/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1090162',
    'https://siilaspot.com.br/venda/industrial/distrito-federal/brasilia/df-business-park/1090162/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1090162',
    'https://siilaspot.com.br/sale/industrial/distrito-federal/brasilia/df-business-park/1090162/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1090162',
    'https://siilaspot.com.br/locacao/industrial/distrito-federal/brasilia/df-business-park/1090162/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1090162',
    'https://siilaspot.com.br/lease/industrial/distrito-federal/brasilia/df-business-park/1090162/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600214',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/seropedica/golgi-seropedica/600214/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600214',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/seropedica/golgi-seropedica/600214/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600214',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/seropedica/golgi-seropedica/600214/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600214',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/seropedica/golgi-seropedica/600214/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1099293',
    'https://siilaspot.com.br/venda/industrial/parana/curitiba/ecopark-curitiba-expansao/1099293/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1099293',
    'https://siilaspot.com.br/sale/industrial/parana/curitiba/ecopark-curitiba-expansion/1099293/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1099293',
    'https://siilaspot.com.br/locacao/industrial/parana/curitiba/ecopark-curitiba-expansao/1099293/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1099293',
    'https://siilaspot.com.br/lease/industrial/parana/curitiba/ecopark-curitiba-expansion/1099293/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237216',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/osasco/bresco-osasco/1237216/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237216',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/osasco/bresco-osasco/1237216/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600084',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/itapevi/cli-itapevi-centro-logistico/600084/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600084',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/itapevi/cli-itapevi-centro-logistico/600084/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/23452',
    'https://siilaspot.com.br/venda/industrial/rio-grande-do-sul/gravatai/glp-gravatai/23452/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/23452',
    'https://siilaspot.com.br/sale/industrial/rio-grande-do-sul/gravatai/glp-gravatai/23452/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500503',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-funchal/500503/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500503',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-funchal/500503/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500503',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/edificio-funchal/500503/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500503',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/edificio-funchal/500503/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1121775',
    'https://siilaspot.com.br/venda/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1121775',
    'https://siilaspot.com.br/sale/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237713',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/guarulhos/glp-guarulhos-ii/1237713/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237713',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/guarulhos/glp-guarulhos-ii/1237713/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238229',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/betim/betim-business-park-expansao-i/1238229/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238229',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/betim/betim-business-park-expansao-i/1238229/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238229',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/betim/betim-business-park-expansao-i/1238229/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238229',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/betim/betim-business-park-expansao-i/1238229/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238292',
    'https://siilaspot.com.br/locacao/industrial/ceara/itaitinga/log-fortaleza-iii/1238292/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238292',
    'https://siilaspot.com.br/lease/industrial/ceara/itaitinga/log-fortaleza-iii/1238292/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238292',
    'https://siilaspot.com.br/venda/industrial/ceara/itaitinga/log-fortaleza-iii/1238292/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238292',
    'https://siilaspot.com.br/sale/industrial/ceara/itaitinga/log-fortaleza-iii/1238292/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/34240',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/hicham-i-campinas/34240/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/34240',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/hicham-i-campinas/34240/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237719',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/ed-ana-carolina-corporate/1237719/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237719',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/ed-ana-carolina-corporate/1237719/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238300',
    'https://siilaspot.com.br/venda/industrial/ceara/itaitinga/bwdiase-business-park-fortaleza/1238300/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238300',
    'https://siilaspot.com.br/sale/industrial/ceara/itaitinga/bwdiase-business-park-fortaleza/1238300/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1235058',
    'https://siilaspot.com.br/locacao/industrial/paraiba/alhandra/cbi-log-expansao-i/1235058/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1235058',
    'https://siilaspot.com.br/lease/industrial/paraiba/alhandra/cbi-log-expansion-i/1235058/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1235058',
    'https://siilaspot.com.br/venda/industrial/paraiba/alhandra/cbi-log-expansao-i/1235058/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1235058',
    'https://siilaspot.com.br/sale/industrial/paraiba/alhandra/cbi-log-expansion-i/1235058/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1090204',
    'https://siilaspot.com.br/locacao/industrial/distrito-federal/brasilia/sys-taguatinga-business-park/1090204/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1090204',
    'https://siilaspot.com.br/lease/industrial/distrito-federal/brasilia/sys-taguatinga-business-park/1090204/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600105',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/itupeva/hglg-itupeva-bloco-i/600105/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600105',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/itupeva/hglg-itupeva-bloco-i/600105/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600027',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/duque-de-caxias/cargo-park/600027/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600027',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/cargo-park/600027/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600027',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/duque-de-caxias/cargo-park/600027/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600027',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/cargo-park/600027/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1237961',
    'https://siilaspot.com.br/venda/industrial/santa-catarina/itajai/mega-centro-logistico-itajai-expansao-i-/1237961/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1237961',
    'https://siilaspot.com.br/sale/industrial/santa-catarina/itajai/mega-centro-logistico-itajai-expansao-i-/1237961/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237961',
    'https://siilaspot.com.br/locacao/industrial/santa-catarina/itajai/mega-centro-logistico-itajai-expansao-i-/1237961/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237961',
    'https://siilaspot.com.br/lease/industrial/santa-catarina/itajai/mega-centro-logistico-itajai-expansao-i-/1237961/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1217991',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/martinelli-sp/1217991/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1217991',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/martinelli-sp/1217991/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235099',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/aeroporto-i/1235099/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235099',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/aeroporto-i/1235099/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600340',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/rio-de-janeiro/rb-log-santa-cruz/600340/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600340',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/rb-log-santa-cruz/600340/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238421',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/guarulhos/wt-element-guarulhos/1238421/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238421',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/guarulhos/wt-element-guarulhos/1238421/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237459',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/cajamar/centro-logistico-cajamar-bandeirantes/1237459/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237459',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/centro-logistico-cajamar-bandeirantes/1237459/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1086998',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/rio-de-janeiro/glp-iraja-expansao-i/1086998/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1086998',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/glp-iraja-expansao-i/1086998/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1086998',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/rio-de-janeiro/glp-iraja-expansao-i/1086998/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1086998',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/rio-de-janeiro/glp-iraja-expansao-i/1086998/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237705',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-roque/castelo-57-business-park/1237705/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237705',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-roque/castelo-57-business-park/1237705/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500484',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/canopus-corporate-alphaville-torre-a-sul-/500484/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500484',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/canopus-corporate-alphaville-torre-a-sul-/500484/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500484',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/canopus-corporate-alphaville-torre-a-sul-/500484/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500484',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/canopus-corporate-alphaville-torre-a-sul-/500484/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/31477',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/parkshopping-corporate-torre-norte/31477/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/31477',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/parkshopping-corporate-torre-norte/31477/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238295',
    'https://siilaspot.com.br/venda/industrial/santa-catarina/joinville/log-joinville/1238295/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238295',
    'https://siilaspot.com.br/sale/industrial/santa-catarina/joinville/log-joinville/1238295/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238295',
    'https://siilaspot.com.br/locacao/industrial/santa-catarina/joinville/log-joinville/1238295/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238295',
    'https://siilaspot.com.br/lease/industrial/santa-catarina/joinville/log-joinville/1238295/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1236717',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/predio-de-uso-exclusivo-no-itaim-bibi/1236717/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1236717',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-de-uso-exclusivo-no-itaim-bibi/1236717/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235152',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/jorge-salomao/1235152/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235152',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/jorge-salomao/1235152/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1207211',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/atento-republica/1207211/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1207211',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/atento-republica/1207211/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1236736',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/predio-monousuario-alvares-penteado/1236736/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1236736',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-monousuario-alvares-penteado/1236736/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/530319',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/dom-gerardo/530319/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/530319',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/dom-gerardo/530319/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/501006',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/carlos-gomes-222-business-center/501006/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/501006',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/carlos-gomes-222-business-center/501006/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/501006',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/carlos-gomes-222-business-center/501006/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/501006',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/carlos-gomes-222-business-center/501006/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500155',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/buenos-aires-corporate/500155/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500155',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/buenos-aires-corporate/500155/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500155',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/buenos-aires-corporate/500155/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500155',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/buenos-aires-corporate/500155/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500052',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/17007-nacoes-torre-sigma/500052/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500052',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/17007-nacoes-torre-sigma/500052/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1236745',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/the-office-frei-caneca-558/1236745/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1236745',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/the-office-frei-caneca-558/1236745/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1236745',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/the-office-frei-caneca-558/1236745/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1236745',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/the-office-frei-caneca-558/1236745/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500154',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/berrini-one/500154/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500154',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/berrini-one/500154/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500012',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/ez-towers-tower-a/500012/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500012',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ez-towers-tower-a/500012/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500650',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/bacaetava-plaza/500650/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500650',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bacaetava-plaza/500650/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500718',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/arcel/500718/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500718',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/arcel/500718/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500718',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/arcel/500718/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500718',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/arcel/500718/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500297',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/sky-corporate/500297/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500297',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/sky-corporate/500297/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238184',
    'https://siilaspot.com.br/locacao/industrial/bahia/feira-de-santana/grid-fsa-expansao-iii/1238184/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238184',
    'https://siilaspot.com.br/lease/industrial/bahia/feira-de-santana/grid-fsa-expansao-iii/1238184/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238184',
    'https://siilaspot.com.br/venda/industrial/bahia/feira-de-santana/grid-fsa-expansao-iii/1238184/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238184',
    'https://siilaspot.com.br/sale/industrial/bahia/feira-de-santana/grid-fsa-expansao-iii/1238184/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1236759',
    'https://siilaspot.com.br/venda/industrial/bahia/feira-de-santana/grid-fsa/1236759/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1236759',
    'https://siilaspot.com.br/sale/industrial/bahia/feira-de-santana/grid-fsa/1236759/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1236759',
    'https://siilaspot.com.br/locacao/industrial/bahia/feira-de-santana/grid-fsa/1236759/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1236759',
    'https://siilaspot.com.br/lease/industrial/bahia/feira-de-santana/grid-fsa/1236759/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238299',
    'https://siilaspot.com.br/locacao/industrial/bahia/feira-de-santana/grid-fsa-expansao-iv/1238299/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238299',
    'https://siilaspot.com.br/lease/industrial/bahia/feira-de-santana/grid-fsa-expansao-iv/1238299/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238299',
    'https://siilaspot.com.br/venda/industrial/bahia/feira-de-santana/grid-fsa-expansao-iv/1238299/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238299',
    'https://siilaspot.com.br/sale/industrial/bahia/feira-de-santana/grid-fsa-expansao-iv/1238299/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500483',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/canopus-corporate-alphaville-torre-b-norte-/500483/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500483',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/canopus-corporate-alphaville-torre-b-norte-/500483/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500483',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/canopus-corporate-alphaville-torre-b-norte-/500483/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500483',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/canopus-corporate-alphaville-torre-b-norte-/500483/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/501058',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/centro-empresarial-la-defense/501058/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/501058',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/centro-empresarial-la-defense/501058/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/501058',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/centro-empresarial-la-defense/501058/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/501058',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/centro-empresarial-la-defense/501058/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500772',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/edificio-palacio-5-avenida/500772/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500772',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/edificio-palacio-5-avenida/500772/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500006',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/barra-business-center-bloco-3-corporate-/500006/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500006',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/barra-business-center-bloco-3-corporate-/500006/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500006',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/barra-business-center-bloco-3-corporate-/500006/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500006',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/barra-business-center-bloco-3-corporate-/500006/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235945',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/ebpark-predio-22/1235945/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235945',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ebpark-predio-22/1235945/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235954',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/bravo-paulista/1235954/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235954',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bravo-paulista/1235954/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1236304',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/manoel-tabacow-hidal/1236304/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1236304',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/manoel-tabacow-hidal/1236304/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1236304',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/manoel-tabacow-hidal/1236304/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1236304',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/manoel-tabacow-hidal/1236304/en'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/escritorio/(.*)/(.*)/500668',
    'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/double-premium/500668/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/office/(.*)/(.*)/500668',
    'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/double-premium/500668/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500555',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-candelaria-62/500555/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500555',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-candelaria-62/500555/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500555',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-candelaria-62/500555/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500555',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-candelaria-62/500555/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1211598',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/quilombo/1211598/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1211598',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/quilombo/1211598/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1234543',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/kyoei-ibirapuera-bloco-ii/1234543/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1234543',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/kyoei-ibirapuera-bloco-ii/1234543/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237793',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/rio-de-janeiro/armazem-rua-farinha-960-rj/1237793/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237793',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/armazem-rua-farinha-960-rj/1237793/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238322',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/alto-das-nacoes-torre-corporate/1238322/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238322',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/alto-das-nacoes-torre-corporate/1238322/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237744',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/edificio-sia-6/1237744/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237744',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/edificio-sia-6/1237744/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500229',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/jk-1455/500229/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500229',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/jk-1455/500229/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237885',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/o-parque/1237885/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237885',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/o-parque/1237885/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500175',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-14/500175/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500175',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-14/500175/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500175',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-14/500175/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500175',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-14/500175/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600120',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/campinas/condominio-multitech/600120/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600120',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/campinas/condominio-multitech/600120/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1207531',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/alphaville/1207531/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1207531',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/alphaville/1207531/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1207531',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/alphaville/1207531/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1207531',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/alphaville/1207531/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1236186',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/near-offices-perdizes/1236186/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1236186',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/near-offices-perdizes/1236186/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500168',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/centro-administrativo-santo-amaro-bloco-c-edificio-santa-salete-/500168/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500168',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/centro-administrativo-santo-amaro-bloco-c-edificio-santa-salete-/500168/en'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/escritorio/(.*)/(.*)/500398',
    'https://siilaspot.com.br/coworking/escritorio/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-i-jacaranda/500398/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/office/(.*)/(.*)/500398',
    'https://siilaspot.com.br/coworking/office/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-i-jacaranda/500398/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500398',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-i-jacaranda/500398/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500398',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-i-jacaranda/500398/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500398',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-i-jacaranda/500398/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500398',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-i-jacaranda/500398/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/283368',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/led-barra-funda-comercial/283368/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/283368',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/led-barra-funda-comercial/283368/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1238181',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/extrema/condominio-logistico-extrema-blackwall-expansao-i-/1238181/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1238181',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/extrema/condominio-logistico-extrema-blackwall-expansion-i-/1238181/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1238181',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/condominio-logistico-extrema-blackwall-expansao-i-/1238181/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1238181',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/condominio-logistico-extrema-blackwall-expansion-i-/1238181/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500960',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/mariano-torres-729/500960/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500960',
    'https://siilaspot.com.br/lease/office/parana/curitiba/mariano-torres-729/500960/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500913',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/centro-empresarial-antonio-peretti/500913/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500913',
    'https://siilaspot.com.br/lease/office/parana/curitiba/centro-empresarial-antonio-peretti/500913/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500917',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/centro-empresarial-lais-peretti/500917/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500917',
    'https://siilaspot.com.br/sale/office/parana/curitiba/centro-empresarial-lais-peretti/500917/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500917',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/centro-empresarial-lais-peretti/500917/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500917',
    'https://siilaspot.com.br/lease/office/parana/curitiba/centro-empresarial-lais-peretti/500917/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/501141',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/ar-3000-cabral-corporate-amp-office/501141/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/501141',
    'https://siilaspot.com.br/sale/office/parana/curitiba/ar-3000-cabral-corporate-amp-office/501141/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/501141',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/ar-3000-cabral-corporate-amp-office/501141/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/501141',
    'https://siilaspot.com.br/lease/office/parana/curitiba/ar-3000-cabral-corporate-amp-office/501141/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500640',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/brasilia-square-offices-torre-1/500640/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500640',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/brasilia-square-offices-torre-1/500640/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500556',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-dr-sylvio-fraga/500556/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500556',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-dr-sylvio-fraga/500556/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500556',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-dr-sylvio-fraga/500556/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500556',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-dr-sylvio-fraga/500556/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500914',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/centro-empresarial-glaser/500914/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500914',
    'https://siilaspot.com.br/lease/office/parana/curitiba/centro-empresarial-glaser/500914/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500914',
    'https://siilaspot.com.br/venda/escritorio/parana/curitiba/centro-empresarial-glaser/500914/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500914',
    'https://siilaspot.com.br/sale/office/parana/curitiba/centro-empresarial-glaser/500914/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1090991',
    'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/complexo-brasil-21-business-center-tower-bloco-e/1090991/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1090991',
    'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/complexo-brasil-21-business-center-tower-bloco-e/1090991/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235048',
    'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/edificio-oscar-alvarenga/1235048/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235048',
    'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/edificio-oscar-alvarenga/1235048/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/1237452',
    'https://siilaspot.com.br/venda/industrial/minas-gerais/extrema/unique-business-park/1237452/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/1237452',
    'https://siilaspot.com.br/sale/industrial/minas-gerais/extrema/unique-business-park/1237452/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237452',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/unique-business-park/1237452/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237452',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/unique-business-park/1237452/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500515',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/evolution-corporate-alphaville/500515/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500515',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/evolution-corporate-alphaville/500515/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500003',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/patio-victor-malzoni-torre-norte/500003/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500003',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/patio-victor-malzoni-torre-norte/500003/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500533',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/olimpiadas-242/500533/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500533',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/olimpiadas-242/500533/en'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/escritorio/(.*)/(.*)/500625',
    'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/edificio-acarai/500625/pt-br'
  ],
  [
    '(.*)/spot-property/detail/promoted/coworking/office/(.*)/(.*)/500625',
    'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/edificio-acarai/500625/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235138',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/alameda-santos-2477/1235138/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235138',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/alameda-santos-2477/1235138/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235490',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/delta-one/1235490/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235490',
    'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/delta-one/1235490/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500195',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/atrium-vii/500195/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500195',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/atrium-vii/500195/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235130',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/dedic-paulista-7/1235130/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235130',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/dedic-paulista-7/1235130/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1238159',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/hy-pinheiros/1238159/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1238159',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/hy-pinheiros/1238159/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/175255',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-m/175255/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/175255',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-m/175255/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/175255',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-m/175255/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/175255',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-m/175255/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/210237',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/aqwa-corporate/210237/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/210237',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/aqwa-corporate/210237/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/210237',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/aqwa-corporate-fase-1/210237/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/210237',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/aqwa-corporate-fase-1/210237/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/44124',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/citta-america-bloco-5/44124/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/44124',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/citta-america-bloco-5/44124/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500027',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/eldorado-business-tower/500027/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500027',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/eldorado-business-tower/500027/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/490099',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/gabriel-goncalves/490099/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/490099',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/gabriel-goncalves/490099/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500075',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/centro-administrativo-santo-amaro-bloco-a-edificio-inga-/500075/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500075',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/centro-administrativo-santo-amaro-bloco-a-edificio-inga-/500075/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500096',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/botafogo-trade-center/500096/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500096',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/botafogo-trade-center/500096/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500096',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/botafogo-trade-center/500096/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500096',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/botafogo-trade-center/500096/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500111',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/internacional-rio/500111/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500111',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/internacional-rio/500111/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500147',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/sedan/500147/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500147',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/sedan/500147/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500104',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500104',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235394',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/centro-de-apoio-i/1235394/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235394',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/centro-de-apoio-i/1235394/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1235394',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/centro-de-apoio-i/1235394/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1235394',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/centro-de-apoio-i/1235394/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500614',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/alfa-rio-prime-business/500614/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500614',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/alfa-rio-prime-business/500614/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500614',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/alfa-rio-prime-business/500614/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500614',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/alfa-rio-prime-business/500614/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500989',
    'https://siilaspot.com.br/locacao/escritorio/rio-grande-do-sul/porto-alegre/24-corporate/500989/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500989',
    'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/24-corporate/500989/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500989',
    'https://siilaspot.com.br/venda/escritorio/rio-grande-do-sul/porto-alegre/24-corporate/500989/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500989',
    'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/24-corporate/500989/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235035',
    'https://siilaspot.com.br/locacao/escritorio/parana/curitiba/corporate-jardim-botanico-torre-a/1235035/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235035',
    'https://siilaspot.com.br/lease/office/parana/curitiba/corporate-jardim-botanico-torre-a/1235035/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/11299',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/i-tower/11299/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11299',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/i-tower/11299/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/531563',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-visconde-de-ouro-preto/531563/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/531563',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-visconde-de-ouro-preto/531563/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500285',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/ronaldo-sampaio-ferreira/500285/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500285',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ronaldo-sampaio-ferreira/500285/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1234842',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/timbauba/1234842/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1234842',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/timbauba/1234842/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237987',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/itau-jabaquara/1237987/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237987',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/itau-jabaquara/1237987/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237788',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/ribeirao-das-neves/armazem-ribeirao-das-neves-mg/1237788/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237788',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/ribeirao-das-neves/armazem-ribeirao-das-neves-mg/1237788/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500827',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/alphabusiness-bloco-geriva/500827/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500827',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/alphabusiness-bloco-geriva/500827/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500827',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/alphabusiness-bloco-geriva/500827/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500827',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/alphabusiness-bloco-geriva/500827/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500164',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/passeio-56/500164/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500164',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/passeio-56/500164/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500451',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/alice-maria-sampaio-ferreira/500451/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500451',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/alice-maria-sampaio-ferreira/500451/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1090727',
    'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/centro-empresarial-cnc-torre-d/1090727/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1090727',
    'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/centro-empresarial-cnc-torre-d/1090727/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1090727',
    'https://siilaspot.com.br/locacao/escritorio/distrito-federal/brasilia/centro-empresarial-cnc-torre-d/1090727/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1090727',
    'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/centro-empresarial-cnc-torre-d/1090727/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500644',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/cea-corporate-torre-i/500644/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500644',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/cea-corporate-torre-i/500644/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500644',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/barueri/cea-corporate-torre-i/500644/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500644',
    'https://siilaspot.com.br/sale/office/sao-paulo/barueri/cea-corporate-torre-i/500644/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500183',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/cyk-comendador-yerchanik-kissajikian/500183/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500183',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/cyk-comendador-yerchanik-kissajikian/500183/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500186',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/continental-square-faria-lima/500186/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500186',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/continental-square-faria-lima/500186/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500088',
    'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-1/500088/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500088',
    'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-1/500088/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500088',
    'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-1/500088/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500088',
    'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-1/500088/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1235404',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/engevix-ii-jackson-tower/1235404/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1235404',
    'https://siilaspot.com.br/lease/office/sao-paulo/barueri/engevix-ii-jackson-tower/1235404/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500213',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/faria-lima-financial-center/500213/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500213',
    'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/faria-lima-financial-center/500213/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/1237233',
    'https://siilaspot.com.br/venda/escritorio/minas-gerais/belo-horizonte/cassio-resende-i/1237233/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/1237233',
    'https://siilaspot.com.br/sale/office/minas-gerais/belo-horizonte/cassio-resende-i/1237233/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/1237233',
    'https://siilaspot.com.br/locacao/escritorio/minas-gerais/belo-horizonte/cassio-resende-i/1237233/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/1237233',
    'https://siilaspot.com.br/lease/office/minas-gerais/belo-horizonte/cassio-resende-i/1237233/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/escritorio/(.*)/(.*)/500828',
    'https://siilaspot.com.br/locacao/escritorio/sao-paulo/campinas/alphabusiness-bloco-jacaranda/500828/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/500828',
    'https://siilaspot.com.br/lease/office/sao-paulo/campinas/alphabusiness-bloco-jacaranda/500828/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/escritorio/(.*)/(.*)/500828',
    'https://siilaspot.com.br/venda/escritorio/sao-paulo/campinas/alphabusiness-bloco-jacaranda/500828/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/office/(.*)/(.*)/500828',
    'https://siilaspot.com.br/sale/office/sao-paulo/campinas/alphabusiness-bloco-jacaranda/500828/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600311',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/sorocaba/armazem-avenida-jerome-case-3000-tecsis-/600311/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600311',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/sorocaba/armazem-avenida-jerome-case-3000-tecsis-/600311/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/24633',
    'https://siilaspot.com.br/venda/industrial/parana/curitiba/ecopark-curitiba/24633/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/24633',
    'https://siilaspot.com.br/sale/industrial/parana/curitiba/ecopark-curitiba/24633/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/24633',
    'https://siilaspot.com.br/locacao/industrial/parana/curitiba/ecopark-curitiba/24633/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/24633',
    'https://siilaspot.com.br/lease/industrial/parana/curitiba/ecopark-curitiba/24633/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1236986',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/pouso-alegre/galpao-isolado-avenida-das-quaresmeiras-200-cremer-/1236986/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1236986',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/pouso-alegre/galpao-isolado-avenida-das-quaresmeiras-200-cremer-/1236986/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600190',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/rio-de-janeiro/glp-iraja/600190/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600190',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/glp-iraja/600190/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/600190',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/rio-de-janeiro/glp-iraja/600190/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/600190',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/rio-de-janeiro/glp-iraja/600190/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/683445',
    'https://siilaspot.com.br/locacao/industrial/rio-de-janeiro/seropedica/golgi-seropedica-expansao/683445/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/683445',
    'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/seropedica/golgi-seropedica-expansao/683445/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venda/industrial/(.*)/(.*)/683445',
    'https://siilaspot.com.br/venda/industrial/rio-de-janeiro/seropedica/golgi-seropedica-expansao/683445/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/683445',
    'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/seropedica/golgi-seropedica-expansao/683445/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1237789',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/jundiai/galpao-isolado-jundiai-sp/1237789/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1237789',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/jundiai/stand-alone-jundiai-sp/1237789/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/1223703',
    'https://siilaspot.com.br/locacao/industrial/minas-gerais/itapeva/galpao-isolado-municipal-do-mandu-250/1223703/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/1223703',
    'https://siilaspot.com.br/lease/industrial/minas-gerais/itapeva/galpao-isolado-municipal-do-mandu-250/1223703/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)locacao/industrial/(.*)/(.*)/600051',
    'https://siilaspot.com.br/locacao/industrial/sao-paulo/barueri/armazem-marcos-penteado-de-ulhoa-rodrigues/600051/pt-br'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/600051',
    'https://siilaspot.com.br/lease/industrial/sao-paulo/barueri/armazem-marcos-penteado-de-ulhoa-rodrigues/600051/en'
  ]

  // Add more entries as needed
]);

export const urlRedirects = new Map();
urlRedirects.set('https://siilaspot.com.br/lang/pt-br', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/lang/en', 'https://siilaspot.com.br/en');
urlRedirects.set('https://siilaspot.com.br/home', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/home/lang/pt-br', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/home/lang/en', 'https://siilaspot.com.br/en');
urlRedirects.set('https://siilaspot.com.br/spot-search', 'https://siilaspot.com.br/en');
urlRedirects.set('https://siilaspot.com.br/spot-search/lang/pt-br', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/spot-search/lang/en', 'https://siilaspot.com.br/en');

urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/condominio-logistico-e-industrial-rio-claro/rio-claro-patrimonial-e-assessoria-ltda/48758/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/rio-claro/centro-logistico-industrial-rio-claro/48758/en'
);

urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/retiro-morumbi/abp-miami-bloco-c/fundacao-valia/500011',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/stand-alone-gaiolli/gaiolli-empreendimento-imobiliarrio/1238182/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/guarulhos/stand-alone-gaiolli/1238182/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/vila-olimpia/milan-head-office/siila-teste/195240/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/milan-head-office/195240/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-cidade-nova-teleporto/500102/en/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-cidade-nova-teleporto/500102/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-rua-dos-coqueiros/mbigucci-comercio-e-empreendimentos-imobiliarios-ltda/1238016/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/santo-andre/galpao-isolado-rua-dos-coqueiros/1238016/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/centro/bay-view/siila/529710/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/bay-view/529710/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/galpao-isolado-br-116-marques-de-montalvao/galpoes-rs/1238404/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/sapucaia-do-sul/galpao-isolado-br-116-marques-de-montalvao/1238404/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/berrini/bolsa-de-imoveis-do-estado-de-sao-paulo/itacare-imoveis-e-participacoes-s-c-ltda-/500158',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/passarelli/1059140/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/passarelli/1059140/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/centro-rj/rio-office-tower/fundacao-valia/500010/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/berrini/edificio-acarai/siila/500625/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/edificio-acarai/500625/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/berrini/centenario/siila/1235355/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-florida-1970/1235355/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/rrrr/advanced-tower/siila/40570/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/advanced-tower/40570/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/stand-alone-df-290-santa-maria/jose-martins-solucoes-em-desenvolvimento-imobiliario/1237733/lang/en',
  'https://siilaspot.com.br/lease/industrial/distrito-federal/brasilia/stand-alone-df-290-santa-maria/1237733/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-olimpia/tenerife-bloco-a/coworking-offices/25067',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/tenerife-bloco-a/25067/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/mega-intermodal-esteio/galpoes-rs/1121532/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/esteio/mega-intermodal-esteio/1121532/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/minas-gerais/belo-horizonte/wework-shopping-boulevard/76044/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/minas-gerais/belo-horizonte/wework-shopping-boulevard/76044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/franco-da-rocha/wt-rbr-log-cajamar-franco-da-rocha/1237881/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/franco-da-rocha/wt-rbr-log/1237881/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/centro/bay-view/siila/529710',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/bay-view/529710/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/pantheon/premiumcorp/529883',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/pantheon/529883/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-faria-lima-4055/500647/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-faria-lima-4055/500647/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-jardim-das-perdizes/500648/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-jardim-das-perdizes/500648/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-paulista-2537/500663/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-paulista-2537/500663/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-oscar-freire/1236998/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-oscar-freire/1236998/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/edificio-sao-marcus/1238226/en/en',
  'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/edificio-sao-marcus/1238226/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/wework-bossa-nova-mall/500612/en/en',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/wework-bossa-nova-mall/500612/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/cidade-jardim-corporate-center-continental-tower/500187/en/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/cidade-jardim-corporate-center-continental-tower/500187/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/en/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/cl-sp-dutra/600071/en/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/cl-sp-dutra/600071/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/condominio-logistico-e-industrial-rio-claro/centro-logistico-industrial-rio-claro/48758/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/rio-claro/centro-logistico-industrial-rio-claro/48758/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-butanta-194/98416/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-butanta-194/98416/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-a/500058/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-a/500058/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-b/500059/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-b/500059/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/pt-br/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/en/en',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/wtc-world-trade-center/500207/pt-br/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/wtc-world-trade-center/500207/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/delta-plaza/10832/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/delta-plaza/10832/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/barao-de-maua/256466/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/barao-de-maua/256466/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/guarulhos/cy-log-guarulhos/1238282/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/guarulhos/cy-log-guarulhos/1238282/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/galpao-isolado-severo-dullius-1995/1238405/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/galpao-isolado-severo-dullius-1995/1238405/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/3sb-parque-logistico/galpoes-rs/1121610/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/nova-santa-rita/3sb-parque-logistico/1121610/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-regiao-portuaria-ex-venezuela-43-/500575/en/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/ed-venezuela-43/500575/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/stand-alone-estrada-de-pernambuco-guaruja-827/mbigucci-comercio-e-empreendimentos-imobiliarios-ltda/1238015/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/guaruja/stand-alone-estrada-de-pernambuco-guaruja-827/1238015/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-bernardo-do-campo/wework-pereira-barreto/1236997/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-bernardo-do-campo/wework-pereira-barreto/1236997/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/galpao-isolado-avenida-do-estado-7717/sergio-bola-imoveis/1238388/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/galpao-isolado-avenida-do-estado-7717/1238388/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/pinheiros-corporate/500521/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/pinheiros-corporate/500521/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-amauri-305/1235000/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-amauri-305/1235000/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/republica/ibirapuera/mega-leiloes/1236144/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/edificio-ibirapuera/1236144/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/thera-corporate/500307/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/thera-corporate/500307/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/centro-logistico-araucaria-rs/1121662/en/en',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/centro-logistico-araucaria-rs/1121662/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/rio-grande-do-sul/porto-alegre/wework-carlos-gomes-1672/501093/en/en',
  'https://siilaspot.com.br/coworking/office/rio-grande-do-sul/porto-alegre/wework-carlos-gomes-1672/501093/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/faria-lima/centenario/siila/1235355/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/centenario/1235355/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/pinheiros-one/500203/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/pinheiros-one/500203/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-berrini/500173/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-berrini/500173/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-sompo/500510/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-sompo/500510/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-market-place/500236/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-market-place/500236/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/nova-santa-rita/3sb-parque-logistico/1121610/en/en',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/nova-santa-rita/3sb-parque-logistico/1121610/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/ceara/fortaleza/blue-corporate/1238383/en/en',
  'https://siilaspot.com.br/lease/office/ceara/fortaleza/blue-corporate/1238383/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/pt-br/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-sompo/500510/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-sompo/500510/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/cajamar/syslog-cajamar/1238179/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/syslog-cajamar/1238179/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/pt-br/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/osasco/wework-osasco/1238450/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/osasco/wework-osasco/1238450/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/pt-br/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/birmann-11/500471/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/birmann-11/500471/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/minas-gerais/belo-horizonte/wework-shopping-boulevard/76044/en/en',
  'https://siilaspot.com.br/coworking/office/minas-gerais/belo-horizonte/wework-shopping-boulevard/76044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/sao-paulo/franco-da-rocha/wt-rbr-log-cajamar-franco-da-rocha/1237881/en/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/franco-da-rocha/wt-rbr-log/1237881/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/centro/centro-empresarial-senado-torre-a/siila/500140/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-senado-torre-a/500140/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/syslog-cajamar/1238179/en/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/syslog-cajamar/1238179/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/condominio-ponto-a-ponto/1238004/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/condominio-ponto-a-ponto/1238004/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-rio-claro/1234564/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-rio-claro/1234564/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-jardim-das-perdizes/500648/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-jardim-das-perdizes/500648/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-pao-de-acucar/13356/pt-br/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-pao-de-acucar/13356/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-a/500058/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-a/500058/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-paulista-1374/500160/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-paulista-1374/500160/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/en/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro-rio-de-janeiro/passeio-corporate-setor-2-torre-b-/siila/500591/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/passeio-corporate-setor-2-torre-b-/500591/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/berrini-500/500153/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/b500/500153/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/faria-lima-business-center/500516/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/faria-lima-business-center/500516/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/pt-br/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/sao-paulo/guarulhos/cy-log-guarulhos/1238282/en/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/guarulhos/cy-log-guarulhos/1238282/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/hortolandia/condominio-logistico-rbr-hortolandia-ii-ex-fca-expansao-i-/1238008/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/hortolandia/condominio-logistico-rbr-hortolandia-ii-expansao-i-/1238008/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/berrini/edificio-acarai/siila/500625',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/edificio-acarai/500625/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/chacara-santo-antonio/alexandre-dumas/siila/13441/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/alexandre-dumas/13441/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/vila-olimpia/vila-olimpia-corporate-torre-b/siila/500321/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/vila-olimpia-corporate-torre-b/500321/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-paulista-2537/500663/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-paulista-2537/500663/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-faria-lima-4055/500647/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-faria-lima-4055/500647/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/pinheiros-one/500203/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/pinheiros-one/500203/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-butanta-336/516152/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-butanta-336/516152/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/sapucaia-do-sul/galpao-isolado-br-116-marques-de-montalvao/1238404/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/sapucaia-do-sul/galpao-isolado-br-116-marques-de-montalvao/1238404/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-jk-iguatemi/500023/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-jk-iguatemi/500023/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-cerro-cora-2175/1236834/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-cerro-cora-2175/1236834/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-castelo-branco/500101/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/ed-castello-branco/500101/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/osasco/wework-osasco/1238450/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/osasco/wework-osasco/1238450/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/en/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/delta-plaza/10832/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/delta-plaza/10832/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/hortolandia/condominio-logistico-rbr-hortolandia-ii-ex-fca-/1237450/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/hortolandia/galpao-hortolandia-ii/1237450/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/esteio/mega-intermodal-esteio/1121532/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/esteio/mega-intermodal-esteio/1121532/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-atilio-innocenti-165/500141/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-atilio-innocenti-165/500141/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-butanta-336/516152/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-butanta-336/516152/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/embu-das-artes/cy-log-embu/600275/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/embu-das-artes/cy-log-embu/600275/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/mykonos/500367/pt-br/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/mykonos/500367/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/ceara/maracanau/aurum-100/1238306/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/ceara/maracanau/aurum-100/1238306/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/sao-paulo/osasco/condominio-logistico-sao-bento/23945/en/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/osasco/condominio-logistico-sao-bento/23945/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-12995/500271/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-12995/500271/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/en/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-purpurina-400/1237000/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-purpurina-400/1237000/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-alianca/500509/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-alianca/500509/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/wework-torre-almirante-barroso-81/500076/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/wework-torre-almirante-barroso-81/500076/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/sao-luiz-bloco-i/500036/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/sao-luiz-bloco-i/500036/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/sapucaia-do-sul/eco-parque-empresarial-lourenco-amp-souza/1238398/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/sapucaia-do-sul/eco-parque-empresarial-lourenco-amp-souza/1238398/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/sao-paulo/rio-claro/centro-logistico-rio-claro/48758/en/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/rio-claro/centro-logistico-industrial-rio-claro/48758/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/rio-grande-do-sul/porto-alegre/wework-carlos-gomes-1672/501093/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/rio-grande-do-sul/porto-alegre/wework-carlos-gomes-1672/501093/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-berrini/500173/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-berrini/500173/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/ceara/juazeiro-do-norte/condominio-aurum-300/1238382/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/ceara/juazeiro-do-norte/condominio-aurum-300/1238382/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/passarelli/1059140/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/passarelli/1059140/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/condominio-logistico-ksm-log-extrema/1235044/en/en',
  'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/galpao-extrema-ii/1235044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-market-place/500236/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-market-place/500236/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/cea-centro-empresarial-do-aco-torre-a/11482/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/cea-centro-empresarial-do-aco-torre-a/11482/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-atilio-innocenti-165/500141/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-atilio-innocenti-165/500141/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/centro-logistico-araucaria-rs/1121662/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/centro-logistico-araucaria-rs/1121662/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-girassol-555/1235574/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-girassol-555/1235574/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/nova-santa-rita/3sb-parque-logistico/1121610/pt-br/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/nova-santa-rita/3sb-parque-logistico/1121610/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/esteio/mega-intermodal-esteio/1121532/en/en',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/esteio/mega-intermodal-esteio/1121532/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/barueri/wework-alphaville/705238/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/barueri/wework-alphaville/705238/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/sale/industrial/ceara/maracanau/stand-alone-aurum-200/1238307/en/en',
  'https://siilaspot.com.br/sale/industrial/ceara/maracanau/stand-alone-aurum-200/1238307/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/cea-centro-empresarial-do-aco-torre-a/11482/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/cea-centro-empresarial-do-aco-torre-a/11482/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-b/500059/en/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-b/500059/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/wework-helios-seelinger-155/529572/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/wework-helios-seelinger-155/529572/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/mykonos/500367/en/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/mykonos/500367/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-olimpia/double-premium/siila/500668/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/double-premium/500668/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/campinas/575-norte-sul/siila/530070',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/575-norte-sul/530070/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/aclimacao/predio-comercial-muniz-de-sousa-280/sergio-bola-imoveis/1238387',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-comercial-muniz-de-sousa-280/1238387/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/ipiranga/predio-comercial-leais-paulistanos-543/sergio-bola-imoveis/1238389',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-comercial-leais-paulistanos-543/1238389/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/d-pedro/sky-galleria/siila/1237684/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/sky-galleria/1237684/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/vila-olimpia/business-center/siila/500481',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/business-center/500481/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/faria-lima/praca-faria-lima/siila/500276/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/praca-faria-lima/500276/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/cachoeirinha-business-park/galpoes-rs/1121775/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/retiro-morumbi/abp-dallas-bloco-d/fundacao-valia/500001',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-olimpia/milan-head-office/regus/195240/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/milan-head-office/195240/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/faria-lima/comary/rbr-asset-management/1235000',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-amauri-305/1235000/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/hsi-syslog-cajamar/erea-eminence-real-estate-advisory/1238179/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/syslog-cajamar/1238179/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/maceio/mbigucci-comercio-e-empreendimentos-imobiliarios-ltda/1238018/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-caetano-do-sul/galpao-isolado-rua-maceio/1238018/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/faria-lima/the-one/siila/500306/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/the-one/500306/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/republica/edificio-ibirapuera/mega-leiloes/1236144/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/edificio-ibirapuera/1236144/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/setor-de-habitacoes-individuai/santos-dumont-bloco-a/siila/1091125',
  'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/santos-dumont-bloco-a/1091125/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/eco-parque-empresarial-lourenco-amp-souza/galpoes-rs/1238398/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/sapucaia-do-sul/eco-parque-empresarial-lourenco-amp-souza/1238398/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/d-pedro/sky-galleria/twimob/1237684/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/sky-galleria/1237684/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-estrada-de-pernambuco-guaruja-827/mbigucci-comercio-e-empreendimentos-imobiliarios-ltda/1238015/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/guaruja/stand-alone-estrada-de-pernambuco-guaruja-827/1238015/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/centro-rio-de-janeiro/passeio-corporate-setor-2-torre-b-/siila/500591/lang/en',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/passeio-corporate-setor-2-torre-b-/500591/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/belo-horizonte/boulevard-corporate-tower/siila-/76044/lang/pt-br',
  'https://siilaspot.com.br/lease/office/minas-gerais/belo-horizonte/boulevard-corporate-tower/76044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-rio-claro/1234564/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-rio-claro/1234564/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/predio-comercial-rua-conde-de-sarzedas-208/lotti-lotti-empreendimentos-imobiliarios-ltda/1238203/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-comercial-rua-conde-de-sarzedas-208/1238203/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/nacoes-unidas-brooklin-novo/wtc-world-trade-center/banco-brasil-lance-no-leilao/500207/lang/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/wtc-world-trade-center/500207/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/industrial/cy-log-embu-ex-patria-embu-/siila/600275',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/embu-das-artes/cy-log-embu/600275/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/centro-logistico-rio-claro/centro-logistico-industrial-rio-claro/48758/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/rio-claro/centro-logistico-industrial-rio-claro/48758/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/berrini/wtc-world-trade-center/banco-brasil-lance-no-leilao/500207/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/wtc-world-trade-center/500207/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/cy-log-embu-ex-patria-embu-/siila/600275',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/embu-das-artes/cy-log-embu/600275/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/vila-olimpia/gc-corporate/premiumcorp/500218/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/gc-corporate/500218/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/sale/industrial/glp-duque-de-caxias-expansion-i/siila/360994',
  'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias-expansion-i/360994/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/alfa-rio-prime-business/siila-/500614',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/alfa-rio-prime-business/500614/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/eixo-monumental/capital-financial-center-torre-b/siila/1091092/lang/pt-br',
  'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/capital-financial-center-torre-b/1091092/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/campinas/alphabusiness-bloco-ipe/siila/500831/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/campinas/alphabusiness-bloco-ipe/500831/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro-curitiba/centro-empresarial-jose-joaquim/siila/500915',
  'https://siilaspot.com.br/lease/office/parana/curitiba/centro-empresarial-jose-joaquim/500915/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/santo-amaro/gt-plaza/siila/500379/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/gt-plaza/500379/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/centro-rio-de-janeiro/passeio-corporate-setor-2-torre-b-/siila/500591/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/passeio-corporate-setor-2-torre-b-/500591/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/merces/bbc-barigui-business-center/siila/1235378/lang/en',
  'https://siilaspot.com.br/lease/office/parana/curitiba/bbc-barigui-business-center/1235378/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/faria-lima/hbr-lead-corporate/siila/500647/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-faria-lima-4055/500647/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/bwdiase-fortaleza/aurum-imobiliaria-corporativa/1238300/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/ceara/itaitinga/bwdiase-business-park-fortaleza/1238300/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/prologis-caxias/siila/600288',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/prologis-caxias/600288/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/armazem-marcos-penteado-de-ulhoa-rodrigues/siila/600051/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/barueri/armazem-marcos-penteado-de-ulhoa-rodrigues/600051/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/centro-rj/francisco-serrador/siila/500109/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/francisco-serrador/500109/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro-civico-curitiba/centro-empresarial-lais-peretti/siila/500917',
  'https://siilaspot.com.br/sale/office/parana/curitiba/centro-empresarial-lais-peretti/500917/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/curitiba/1550-batel/siila/1238057/lang/pt-br',
  'https://siilaspot.com.br/lease/office/parana/curitiba/1550-batel/1238057/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/stand-alone-jundiai-sp/siila/1237789',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/jundiai/stand-alone-jundiai-sp/1237789/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/bom-retiro/predio-monousuario-sergio-tomas-415/multinvest-adm-de-bens-ltda-/1238353',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-monousuario-sergio-tomas-415/1238353/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/centro/ventura-corporate-towers-oeste/siila/500189/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/ventura-corporate-towers-oeste/500189/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/alfa-rio-prime-business/siila/500614',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/alfa-rio-prime-business/500614/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/venda/industrial/log-fortaleza-ii/siila/1237445',
  'https://siilaspot.com.br/sale/industrial/ceara/itaitinga/log-fortaleza-ii/1237445/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/sale/industrial/glp-gravatai/siila/23452',
  'https://siilaspot.com.br/sale/industrial/rio-grande-do-sul/gravatai/glp-gravatai/23452/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/barra-da-tijuca/centro-empresarial-mario-henrique-simonsen-bloco-7/siila-/500266',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-7/500266/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/kyoei-ibirapuera-bloco-ii/savoy-imobiliaria-construtora-ltda-/1234543/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/kyoei-ibirapuera-bloco-ii/1234543/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/cassio-resende-i/siila/1237233',
  'https://siilaspot.com.br/lease/office/minas-gerais/belo-horizonte/cassio-resende-i/1237233/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/reboucas/aroeira-office-park/siila/501142/lang/pt-br',
  'https://siilaspot.com.br/lease/office/parana/curitiba/aroeira-office-park/501142/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/bmlog-rio-ex-goodman-nova-india-/siila/600073/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/bmlog-rio-ex-goodman-nova-india-/600073/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cachoeirinha-business-park/siila/1121775/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/glp-iraja/siila/600190/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/glp-iraja/600190/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/rio-de-janeiro/casa-corporate-bloco-o/siila/175254/lang/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-o/175254/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/vila-olimpia/loft-office-sao-paulo/premiumcorp/1235270',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/loft-office-sao-paulo/1235270/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/brookfield-place-barra-da-tijuca-pacific-tower/siila-/500026',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/brookfield-place-barra-da-tijuca-pacific-tower/500026/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/casa-corporate-bloco-n/siila/175253/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-n/175253/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/alphaville/centro-administrativo-rio-negro-torre-jacari/siila-/500489/lang/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-jacari/500489/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/pravda/1237355/pt-br/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/barueri/pravda/1237355/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/rio-de-janeiro/centro-empresarial-dr-sylvio-fraga/siila/500556/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-dr-sylvio-fraga/500556/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/barra-da-tijuca/centro-empresarial-downtown-bloco-7/siila/500283/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-7/500283/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/itaim-bibi/spazio-jk/siila/500304/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/spazio-jk/500304/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/prologis-queimados-expansao-i/siila/368407/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/queimados/prologis-queimados-expansion-i/368407/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/alphaville/canopus-corporate-alphaville-torre-b-norte-/siila/500483',
  'https://siilaspot.com.br/sale/office/sao-paulo/barueri/canopus-corporate-alphaville-torre-b-norte-/500483/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/armazem-rua-farinha-960-rj/siila/1237793/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/armazem-rua-farinha-960-rj/1237793/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro-campinas/centro-empresarial-conceicao/siila/500730/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/centro-empresarial-conceicao/500730/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/industrial/log-gravatai/siila/1237707',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/gravatai/log-gravatai/1237707/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/berrini/bolsa-de-imoveis-do-estado-de-sao-paulo/itacare-imoveis-e-participacoes-s-c-ltda-/500158/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/nucleo-bandeirante-df/edificio-no-setor-de-industrias-bernardo-sayao/jose-martins-solucoes-em-desenvolvimento-imobiliario/1238341/lang/pt-br',
  'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/edificio-no-setor-de-industrias-bernardo-sayao/1238341/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cbi-log/siila/435054/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/paraiba/alhandra/cbi-log/435054/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/modulo-reboucas/siila/55375/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/modulo-reboucas/55375/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/d-pedro/sky-galleria/siila/1237684/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/sky-galleria/1237684/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/armazem-luis-coutinho-cavalcanti-649/siila/1238302/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/armazem-luis-coutinho-cavalcanti-649/1238302/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/cargo-center-dutra-v-expansion-i/siila/1238173/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/queimados/cargo-center-dutra-v-expansion-i/1238173/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/stand-alone-jundiai-sp/siila/1237789/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/jundiai/stand-alone-jundiai-sp/1237789/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/vila-olimpia/itc-international-trade-center-business/siila/1235263/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/itc-international-trade-center-business/1235263/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/chacara-santo-antonio/centro-administrativo-santo-amaro-bloco-c-edificio-santa-salete-/sao-carlos-empreendimentos-e-participacoes-s-a/500168/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/centro-administrativo-santo-amaro-bloco-c-edificio-santa-salete-/500168/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/bom-retiro/predio-monousuario-sergio-tomas-415/multinvest-adm-de-bens-ltda-/1238353/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-monousuario-sergio-tomas-415/1238353/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/faria-lima-business-center/500516/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/faria-lima-business-center/500516/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/centro/cassio-resende-i/siila/1237233/lang/en',
  'https://siilaspot.com.br/sale/office/minas-gerais/belo-horizonte/cassio-resende-i/1237233/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/golgi-seropedica-expansao/siila/683445/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/seropedica/golgi-seropedica-expansao/683445/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/kyoei-ibirapuera-bloco-ii/savoy-imobiliaria-construtora-ltda-/1234543/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/kyoei-ibirapuera-bloco-ii/1234543/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/vila-olimpia/atrium-ix/siila/500009',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/atrium-ix/500009/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/barra-da-tijuca/barra-trade-iii/siila-/500258/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/barra-trade-iii/500258/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/centro-rio-de-janeiro/sao-jose-20/siila/1237157',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/sao-jose-20/1237157/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/casa-corporate-bloco-p/siila/175256',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-p/175256/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/vila-madalena/box-298/siila/1235670',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/box-298/1235670/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/campinas/575-norte-sul/siila/530070/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/575-norte-sul/530070/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/campinas/575-norte-sul/siila/530070/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/575-norte-sul/530070/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/centro/ventura-corporate-towers-oeste/siila/500189',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/ventura-corporate-towers-oeste/500189/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro-largo-sao-francisco/predio-onde-funcionou-a-antiga-papelaria-casa-cruz-fundada-em-1893-/autonomo/1238192',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/predio-onde-funcionou-a-antiga-papelaria-casa-cruz-fundada-em-1893-/1238192/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/juscelino-kubitschek/complexo-jk-torre-d/wework/500023/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-jk-iguatemi/500023/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/barra-da-tijuca/barra-trade-iii/siila-/500258',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/barra-trade-iii/500258/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/bolsa-de-valores-do-rio-de-janeiro/siila-/500137/lang/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/bolsa-de-valores-do-rio-de-janeiro/500137/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/en/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/industrial/hglg-ribeirao-preto/siila/600208',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/ribeirao-preto/hglg-ribeirao-preto/600208/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/bozano-simonsen/siila/500135',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/bozano-simonsen/500135/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/mega-intermodal-esteio/galpoes-rs/1121532/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/esteio/mega-intermodal-esteio/1121532/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/capital-plaza/siila/10325/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/capital-plaza/10325/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro/cardeal-leme/siila/355923/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/cardeal-leme/355923/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/armazem-hollingsworth/siila/1238386/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sorocaba/armazem-hollingsworth/1238386/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/belo-horizonte/boulevard-corporate-tower/siila/76044/lang/pt-br',
  'https://siilaspot.com.br/lease/office/minas-gerais/belo-horizonte/boulevard-corporate-tower/76044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/belo-horizonte/boulevard-corporate-tower/wework/76044/lang/pt-br',
  'https://siilaspot.com.br/lease/office/minas-gerais/belo-horizonte/boulevard-corporate-tower/76044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/centro-empresarial-europa/siila-/500139',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-europa/500139/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/cassio-resende-i/siila/1237233/lang/pt-br',
  'https://siilaspot.com.br/sale/office/minas-gerais/belo-horizonte/cassio-resende-i/1237233/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/stand-alone-avenida-nelson-palma-travassos-275/siila/1238330/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-avenida-nelson-palma-travassos-275/1238330/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/loginveste/siila/892696/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/pernambuco/recife/loginveste/892696/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/galpao-isolado-avenida-nelson-palma-travassos-275/siila/1238330/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-avenida-nelson-palma-travassos-275/1238330/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/juscelino-kubitscheck/complexo-jk-bloco-b-daslu-/siila/500021/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/complexo-jk-bloco-b-daslu-/500021/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/rrrr/advanced-tower/siila/40570/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/advanced-tower/40570/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/cachoeirinha-business-park/siila/1121775/lang/en',
  'https://siilaspot.com.br/sale/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/juscelino-kubitschek/complexo-jk-torre-d/wework/500023',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-jk-iguatemi/500023/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/bresco-resende/bresco-investimentos/600160/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/resende/bresco-resende/600160/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/faria-lima/international-plaza-i/siila/500223/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/international-plaza-i/500223/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/barra-da-tijuca/barra-trade-iii/siila/500258',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/barra-trade-iii/500258/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/stand-alone-herculano-pinheiro-1001/savoy-imobiliaria-construtora-ltda-/23765/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/armazem-herculano-pinheiro-1001/23765/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro/edificio-antonio-carlos/savoy-imobiliaria-construtora-ltda-/500356/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-antonio-carlos/500356/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/barueri/wework-alphaville/705238/pt-br/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/barueri/wework-alphaville/705238/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/chucri-zaidan/morumbi-office-tower/sao-carlos-empreendimentos-e-participacoes-s-a/44559/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/morumbi-office-tower/44559/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-cl-duque-ex-bz-log-/siila/904605/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/condominio-cl-duque-ex-bz-log-/904605/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-df-290-km-1-2-santa-maria/jose-martins-solucoes-em-desenvolvimento-imobiliario/1237733/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/distrito-federal/brasilia/stand-alone-df-290-santa-maria/1237733/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro-largo-sao-francisco/predio-onde-funcionou-a-antiga-papelaria-casa-cruz-fundada-em-1893-/autonomo/1238192/lang/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/predio-onde-funcionou-a-antiga-papelaria-casa-cruz-fundada-em-1893-/1238192/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/glp-duque-de-caxias/siila/600319/lang/en',
  'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/duque-de-caxias/glp-duque-de-caxias/600319/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/bresco-bahia/siila/1237133/lang/en',
  'https://siilaspot.com.br/lease/industrial/bahia/lauro-de-freitas/bresco-bahia/1237133/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/pinheiros/of-oscar-freire-office/siila/1238158',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/of-oscar-freire-office/1238158/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/coworking/escritorio/faria-lima/hbr-lead-corporate/siila/500647',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-faria-lima-4055/500647/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/vila-olimpia/business-center/premiumcorp/500481/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/business-center/500481/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/armazem-jundiai/savoy-imobiliaria-construtora-ltda-/1237789/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/jundiai/stand-alone-jundiai-sp/1237789/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/barra-da-tijuca/brookfield-place-barra-da-tijuca-pacific-tower/siila/500026/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/brookfield-place-barra-da-tijuca-pacific-tower/500026/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/centro-empresarial-downtown-bloco-14/siila/500175',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-14/500175/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro/candelaria-corporate/siila/500044/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/lapa-de-baixo/ebpark-predio-22/ebpark-empresarial-spe-s-a/1235945/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ebpark-predio-22/1235945/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/condominio-logistico-extrema-blackwall-/siila/1238180/lang/en',
  'https://siilaspot.com.br/sale/industrial/minas-gerais/extrema/bwdiase-business-park-extrema/1238180/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/lapa-de-baixo/ebpark-predio-22/ebpark-empresarial/1235945/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ebpark-predio-22/1235945/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/bwdiase-business-park-extrema/siila/1238180/lang/en',
  'https://siilaspot.com.br/sale/industrial/minas-gerais/extrema/bwdiase-business-park-extrema/1238180/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/dcr-rodoanel-centro-logistico/siila/600082/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/embu-das-artes/dcr-rodoanel-centro-logistico/600082/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/gcr-business-park/siila-/1238230/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/santa-catarina/biguacu/gcr-business-park/1238230/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/campinas/575-norte-sul/siila/530070/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/575-norte-sul/530070/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/gcr-business-park/siila/1238230/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/santa-catarina/biguacu/gcr-business-park/1238230/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro-curitiba/centro-comercial-italia/siila/500911/lang/pt-br',
  'https://siilaspot.com.br/lease/office/parana/curitiba/centro-comercial-italia/500911/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/alphaville/amazonia/siila/705238/lang/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/barueri/amazonia/705238/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/alvaro-cumplido-de-sant-anna-anm-retrofit-/siila/500041',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/alvaro-cumplido-de-sant-anna-anm-retrofit-/500041/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/stand-alone-rua-albertina-de-souza-242/siila/1238328/lang/en',
  'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-rua-albertina-de-souza-242/1238328/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/cidade-nova/centro-administrativo-cidade-nova/siila-/500099/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-administrativo-cidade-nova/500099/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/santo-amaro/galpao-isolado-alfredo-folchin/savoy-imobiliaria-construtora-ltda-/1238385/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-jose-do-rio-preto/imovel-comercial-alfredo-folchin/1238385/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/armazem-avenida-jerome-case-3000-tecsis-/siila/600311/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sorocaba/armazem-avenida-jerome-case-3000-tecsis-/600311/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/cbi-log-expansion-i/siila/1235058/lang/en',
  'https://siilaspot.com.br/sale/industrial/paraiba/alhandra/cbi-log-expansion-i/1235058/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro-civico/ar-3000-cabral-corporate-amp-office/siila/501141/lang/en',
  'https://siilaspot.com.br/lease/office/parana/curitiba/ar-3000-cabral-corporate-amp-office/501141/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/en/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/itaim-bibi/square-955/siila/500652',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/square-955/500652/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/vila-olimpia/edificio-funchal/siila/500503/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-funchal/500503/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/banco-do-estado-de-sao-paulo/siila-/303446',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/banco-do-estado-de-sao-paulo/303446/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/centro-empresarial-castelo-branco/siila/500101/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/ed-castello-branco/500101/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/alphaville/centro-administrativo-rio-negro-torre-jacari/siila-/500489/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-jacari/500489/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/distribution-park-manaus-iii/siila/600355/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/amazonas/manaus/distribution-park-manaus-iii/600355/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/porto-alegre-rs/mr-shan-business-offices/wework/501093/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/rio-grande-do-sul/porto-alegre/wework-carlos-gomes-1672/501093/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/vila-olimpia/edificio-funchal/siila/500503/lang/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/edificio-funchal/500503/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/alphaville/brascan-century-plaza-green-valley-torre-corporate/siila-/500477/lang/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/barueri/brascan-century-plaza-green-valley-torre-corporate/500477/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/unilogistica-business-park-ii-rj/siila/1238223/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/unilogistica-business-park-ii-rj/1238223/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/centro-empresarial-mario-henrique-simonsen-bloco-6/siila-/500264/lang/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-6/500264/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/juscelino-kubitschek/complexo-jk-torre-d/wework/500023/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-jk-iguatemi/500023/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/mega-centro-logistico-itajai/siila/30793/lang/en',
  'https://siilaspot.com.br/sale/industrial/santa-catarina/itajai/mega-centro-logistico-itajai/30793/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/faria-lima/centenario/siila-/1235355',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/centenario/1235355/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/log-campos/siila/600234/lang/en',
  'https://siilaspot.com.br/sale/industrial/rio-de-janeiro/campos-dos-goytacazes/log-campos/600234/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/chucri-zaidan/parque-da-cidade-torre-corporativa-jequitiba/siila-/500042/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/parque-da-cidade-torre-corporativa-jequitiba/500042/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/d-pedro/sky-galleria/siila/1237684',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/sky-galleria/1237684/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/pantheon/premiumcorp/529883/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/pantheon/529883/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/cidade-nova/centro-empresarial-cidade-nova-teleporto/siila-/500102/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-cidade-nova-teleporto/500102/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/distribution-park-manaus-ii/siila/820723/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/amazonas/manaus/distribution-park-manaus-ii/820723/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/chacara-santo-antonio/edificio-brasilprev-ala-c/sao-carlos-empreendimentos-e-participacoes-s-a/500467',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-brasilprev-ala-c/500467/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/eco-parque-empresarial-lourenco-amp-souza/galpoes-rs/1238397/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/sapucaia-do-sul/eco-parque-empresarial-lourenco-amp-souza/1238397/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/praia-de-belas/borges-2233/siila/501003/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/borges-2233/501003/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/cidade-nova/centro-administrativo-cidade-nova/siila/500099/lang/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-administrativo-cidade-nova/500099/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/armazem-rua-edmundo-carvalho-430/siila/1238305',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/armazem-rua-edmundo-carvalho-430/1238305/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-ponto-a-ponto/galpoes-rs/1238004/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/condominio-ponto-a-ponto/1238004/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/alphaville/canopus-corporate-alphaville-torre-a-sul-/siila-/500484',
  'https://siilaspot.com.br/lease/office/sao-paulo/barueri/canopus-corporate-alphaville-torre-a-sul-/500484/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/faria-lima/faria-lima-3500/siila/500210',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/faria-lima-3500/500210/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/perdizes/near-offices-perdizes/susy-godoy-consultoria-imobiliaria/1236186/lang/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/near-offices-perdizes/1236186/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/berrini/ronaldo-sampaio-ferreira/brick-capital/500285',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ronaldo-sampaio-ferreira/500285/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/porto-rio-de-janeiro/port-corporate-tower/siila/500202',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/port-corporate-tower/500202/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/alphaville/cbop-castelo-branco-office-park-torre-i-jacaranda/siila/500398',
  'https://siilaspot.com.br/sale/office/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-i-jacaranda/500398/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/centro-empresarial-downtown-bloco-14/siila-/500175',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-14/500175/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/cle-embu-centro-logistico/siila/600081',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/embu-das-artes/cle-embu-centro-logistico/600081/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/alphaville/centro-administrativo-rio-negro-torre-padauiri/siila-/500488/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-padauiri/500488/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/dcb-distribution-center-barueri/siila/1238160/lang/en',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/barueri/dcb-distribution-center-barueri/1238160/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/chucri-zaidan/parque-da-cidade-torre-corporativa-jequitiba/siila/500042/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/parque-da-cidade-torre-corporativa-jequitiba/500042/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/brookfield-place-barra-da-tijuca-pacific-tower/siila-/500026/lang/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/brookfield-place-barra-da-tijuca-pacific-tower/500026/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/barra-da-tijuca/centro-empresarial-barra-shopping-bloco-2-new-york/siila-/500262/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-2-new-york/500262/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/vila-olimpia/quilombo/siila/1211598/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/quilombo/1211598/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/alphaville/engevix-ii-jackson-tower/siila/1235404/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/barueri/engevix-ii-jackson-tower/1235404/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/stand-alone-rod-br-101-sul-km-130/siila/1238245',
  'https://siilaspot.com.br/lease/industrial/pernambuco/escada/stand-alone-rod-br-101-sul-km-130/1238245/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/unilogistica-business-park-ii-rj/siila-/1238223',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/unilogistica-business-park-ii-rj/1238223/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/birmann-11/500471/en/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/birmann-11/500471/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/log-salvador/siila/1238294/lang/en',
  'https://siilaspot.com.br/lease/industrial/bahia/simoes-filho/log-salvador/1238294/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro/mariano-torres-729/siila/500960/lang/en',
  'https://siilaspot.com.br/lease/office/parana/curitiba/mariano-torres-729/500960/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/sale/industrial/stand-alone-avenida-carioca-427/siila/1238329',
  'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-avenida-carioca-427/1238329/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/faria-lima/faria-lima-4440-acqua/siila/500211/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/faria-lima-4440-acqua/500211/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/paulista/santa-catarina/siila/500287',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/santa-catarina/500287/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/paulista/edificio-palacio-5-avenida/siila/500772/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/edificio-palacio-5-avenida/500772/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/ecopark-araucaria/siila/30527/lang/en',
  'https://siilaspot.com.br/sale/industrial/parana/araucaria/ecopark-araucaria/30527/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/casa-corporate-bloco-m/siila-/175255',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-m/175255/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/manoel-tabacow-hidal/susy-godoy-consultoria-imobiliaria/1236304',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/manoel-tabacow-hidal/1236304/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-gaiolli/gaiolli-empreendimento-imobiliarrio/1238182/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/guarulhos/stand-alone-gaiolli/1238182/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cachoeirinha-business-park/galpoes-rs/1121775/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/golgi-duque-de-caxias-expansao/siila/1235333/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/duque-de-caxias/golgi-duque-de-caxias-expansion/1235333/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/sale/industrial/ecopark-araucaria/siila/30527',
  'https://siilaspot.com.br/sale/industrial/parana/araucaria/ecopark-araucaria/30527/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/moinhos-de-vento/centro-empresarial-la-defense/siila/501058',
  'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/centro-empresarial-la-defense/501058/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-logistico-sindi-investimentos-ii/siila/1236364/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/minas-gerais/contagem/condominio-logistico-sindi-investimentos-ii/1236364/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/rudge-ramos/wynn-tower/mbigucci-comercio-e-empreendimentos-imobiliarios-ltda/1238031',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-bernardo-do-campo/wynn-tower/1238031/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/saude/boulevard-office/mbigucci-comercio-e-empreendimentos-imobiliarios-ltda/530988',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/boulevard-office/530988/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/praia-de-belas/borges-2233/siila/501003',
  'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/borges-2233/501003/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/distribution-park-manaus-ii/siila/820723/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/amazonas/manaus/distribution-park-manaus-ii/820723/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/rio-grande-do-sul/centro-empresarial-mostardeiro/siila/501013/lang/en',
  'https://siilaspot.com.br/sale/office/rio-grande-do-sul/porto-alegre/centro-empresarial-mostardeiro/501013/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/barra-da-tijuca/centro-empresarial-mario-henrique-simonsen-bloco-7/siila-/500266/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-7/500266/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/praia-de-belas/borges-2233/siila/501003/lang/en',
  'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/borges-2233/501003/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/bmlog-rio-ex-goodman-nova-india-/siila/600073/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/bmlog-rio-ex-goodman-nova-india-/600073/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/rio-de-janeiro/casa-corporate-bloco-o/siila-/175254/lang/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-o/175254/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/unilogistica-business-park-ii-rj/siila-/1238223/lang/en',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/unilogistica-business-park-ii-rj/1238223/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/lapa-de-baixo/ebpark-predio-01/ebpark-empresarial/1235399/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/ebpark-predio-01/1235399/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro-vitoria/predio-comercial-avenida-princesa-isabel-571/mega-leiloes/1238395/lang/pt-br',
  'https://siilaspot.com.br/sale/office/espirito-santo/vitoria/predio-comercial-avenida-princesa-isabel-571/1238395/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/galpao-isolado-bmlog-leap-dafiti-extrema-/bts-properties/1237849',
  'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/galpao-isolado-bmlog-leap-dafiti-extrema-/1237849/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/paulista/bravo-paulista/siila/1235954',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bravo-paulista/1235954/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/sys-taguatinga-business-park/jose-martins-solucoes-em-desenvolvimento-imobiliario/1090204',
  'https://siilaspot.com.br/lease/industrial/distrito-federal/brasilia/sys-taguatinga-business-park/1090204/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/alphaville/canopus-corporate-alphaville-torre-a-sul-/siila/500484/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/barueri/canopus-corporate-alphaville-torre-a-sul-/500484/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/pinheiros/metro-office-ii/wework/98416',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-butanta-194/98416/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/industrial/distribution-park-manaus-iii/siila/600355',
  'https://siilaspot.com.br/lease/industrial/amazonas/manaus/distribution-park-manaus-iii/600355/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/orla/centro-empresarial-arcos-da-lapa/siila/500559/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-arcos-da-lapa/500559/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/alphaville/centro-administrativo-rio-negro-torre-demini/siila/500487/lang/pt-br',
  'https://siilaspot.com.br/sale/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-demini/500487/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro-campinas/arcel/siila/500718/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/arcel/500718/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/rio-grande-do-sul/capital-tower/siila/501005/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/capital-tower/501005/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro-joao-pessoa/predio-comercial-avenida-general-osorio-415/mega-leiloes/1238393/lang/pt-br',
  'https://siilaspot.com.br/sale/office/paraiba/joao-pessoa/predio-comercial-avenida-general-osorio-415/1238393/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/vila-olimpia/milan-head-office/regus/195240/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/milan-head-office/195240/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/business-park-bandeirantes/2f-brasil-imoveis/600018/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/rio-de-janeiro/rio-de-janeiro/business-park-bandeirantes/600018/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/bresco-bahia/siila/1237133/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/bahia/lauro-de-freitas/bresco-bahia/1237133/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/agua-verde/centro-corporativo-hamilcar-pizzatto/siila/1235379/lang/pt-br',
  'https://siilaspot.com.br/sale/office/parana/curitiba/centro-corporativo-hamilcar-pizzatto/1235379/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/barra-da-tijuca/americas-corporate-bloco-3/siila/500090/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-3/500090/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/sedan/banco-brasil-lance-no-leilao/500147',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/sedan/500147/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/centro/candido-mendes/siila/819030',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/candido-mendes/819030/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro/rio-metropolitan/siila/500170/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/rio-metropolitan/500170/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/stand-alone-rua-duarte-de-azevedo-792/siila/1238327/lang/pt-br',
  'https://siilaspot.com.br/sale/industrial/sao-paulo/sao-paulo/stand-alone-rua-duarte-de-azevedo-792/1238327/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/rio-de-janeiro/atlantica-business-center/siila-/500554/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/atlantica-business-center/500554/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/vila-olimpia/atrium-ix/siila/500009/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/atrium-ix/500009/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/industrial/castelo-57-business-park/siila/1237705',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-roque/castelo-57-business-park/1237705/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/barra-da-tijuca/centro-empresarial-barra-shopping-bloco-2-new-york/siila/500262/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-2-new-york/500262/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/sale/office/barra-da-tijuca/centro-empresarial-barra-shopping-bloco-2-new-york/siila-/500262/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-2-new-york/500262/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/centro-empresarial-barra-shopping-bloco-2-new-york/siila-/500262',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-barra-shopping-bloco-2-new-york/500262/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-olimpia/double-premium/siila/500668',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/double-premium/500668/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/centro-largo-sao-francisco/predio-onde-funcionou-a-antiga-papelaria-casa-cruz-fundada-em-1893-/autonomo/1238192/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/predio-onde-funcionou-a-antiga-papelaria-casa-cruz-fundada-em-1893-/1238192/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/sale/office/centro/buenos-aires-corporate/siila-/500155/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/buenos-aires-corporate/500155/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/barra-da-tijuca/corporate-seelinger/wework/529572/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/wework-helios-seelinger-155/529572/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/agua-verde/centro-corporativo-hamilcar-pizzatto/siila/1235379/lang/en',
  'https://siilaspot.com.br/sale/office/parana/curitiba/centro-corporativo-hamilcar-pizzatto/1235379/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/agua-verde/centro-corporativo-hamilcar-pizzatto/siila/1235379',
  'https://siilaspot.com.br/sale/office/parana/curitiba/centro-corporativo-hamilcar-pizzatto/1235379/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/alphaville/cbop-castelo-branco-office-park-torre-i-jacaranda/siila/500398/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/barueri/cbop-castelo-branco-office-park-torre-i-jacaranda/500398/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/barra-da-tijuca/americas-corporate-bloco-3/siila/500090',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/americas-corporate-bloco-3/500090/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro-curitiba/centro-empresarial-glaser/siila/500914/lang/en',
  'https://siilaspot.com.br/lease/office/parana/curitiba/centro-empresarial-glaser/500914/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/centro/bozano-simonsen/siila-/500135/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/bozano-simonsen/500135/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/chacara-santo-antonio/edificio-brasilprev-ala-a/sao-carlos-empreendimentos-e-participacoes-s-a/500465/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-brasilprev-ala-a/500465/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro-joao-pessoa/predio-comercial-avenida-general-osorio-415/mega-leiloes/1238393',
  'https://siilaspot.com.br/lease/office/paraiba/joao-pessoa/predio-comercial-avenida-general-osorio-415/1238393/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/sale/office/brasilia/edificio-zariffe/jose-martins-solucoes-em-desenvolvimento-imobiliario/1235047',
  'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/edificio-zariffe/1235047/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/centro/alvaro-cumplido-de-sant-anna-anm-retrofit-/siila-/500041',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/alvaro-cumplido-de-sant-anna-anm-retrofit-/500041/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/rio-grande-do-sul/carlos-gomes-center/siila/501007/lang/en',
  'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/carlos-gomes-center/501007/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/rio-de-janeiro/casa-corporate-bloco-o/siila/175254/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/casa-corporate-bloco-o/175254/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/alphaville/centro-de-apoio-i/siila/1235394/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/barueri/centro-de-apoio-i/1235394/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/d-pedro/sky-galleria/twimob/1237684/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/campinas/sky-galleria/1237684/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/porto/aqwa-corporate-fase-1/siila/210237',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/aqwa-corporate-fase-1/210237/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/porto/aqwa-corporate-fase-1/siila/210237/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/aqwa-corporate-fase-1/210237/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/centro-civico-curitiba/centro-empresarial-lais-peretti/siila/500917/lang/en',
  'https://siilaspot.com.br/sale/office/parana/curitiba/centro-empresarial-lais-peretti/500917/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/sao-luiz-bloco-i/barzel-properties/500036/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/sao-luiz-bloco-i/500036/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/bom-retiro/predio-comercial-rua-da-graca-856/multinvest-adm-de-bens-ltda-/1238354/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/predio-comercial-rua-da-graca-856/1238354/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/lease/office/alphaville/canopus-corporate-alphaville-torre-a-sul-/siila-/500484',
  'https://siilaspot.com.br/lease/office/sao-paulo/barueri/canopus-corporate-alphaville-torre-a-sul-/500484/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-especulativo-extrema/siila-/1238056/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/condominio-especulativo-extrema/1238056/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/agua-verde/union-tower/siila/1237218',
  'https://siilaspot.com.br/lease/office/parana/curitiba/union-tower/1237218/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro-sp/edificio-gabriel-goncalves/savoy-imobiliaria-construtora-ltda-/490099/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/gabriel-goncalves/490099/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/asa-norte/centro-empresarial-cnc-torre-d/siila/1090727/lang/pt-br',
  'https://siilaspot.com.br/lease/office/distrito-federal/brasilia/centro-empresarial-cnc-torre-d/1090727/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro-sp/gabriel-goncalves/siila/490099/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/gabriel-goncalves/490099/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/campinas/alphabusiness-bloco-acai/siila/500829',
  'https://siilaspot.com.br/sale/office/sao-paulo/campinas/alphabusiness-bloco-acai/500829/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/centro-sp/gabriel-goncalves/siila/490099/lang/pt-br',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/gabriel-goncalves/490099/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/promoted/locacao/escritorio/moinhos-de-vento/centro-empresarial-la-defense/siila/501058/lang/pt-br',
  'https://siilaspot.com.br/lease/office/rio-grande-do-sul/porto-alegre/centro-empresarial-la-defense/501058/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/centro-empresarial-mario-henrique-simonsen-bloco-1/siila/500284/lang/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mario-henrique-simonsen-bloco-1/500284/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/bela-vista/delta-plaza/rbr-asset-management/10832/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/delta-plaza/10832/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/campinas/alphabusiness-bloco-acai/siila/500829/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/campinas/alphabusiness-bloco-acai/500829/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-especulativo-extrema/siila/1238056/lang/pt-br',
  'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/condominio-especulativo-extrema/1238056/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/agua-verde/centro-empresarial-jatoba/siila/1235383/lang/pt-br',
  'https://siilaspot.com.br/sale/office/parana/curitiba/centro-empresarial-jatoba/1235383/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/vila-olimpia/net-office-empresarial/premiumcorp/362545/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/net-office-empresarial/362545/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/buenos-aires-corporate/siila-/500155',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/buenos-aires-corporate/500155/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/centro-rio-de-janeiro/passeio-corporate-setor-2-torre-b-/siila/500591/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/passeio-corporate-setor-2-torre-b-/500591/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/pinheiros/capitao-antonio-rosa/siila/834052/lang/pt-br',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/capitao-antonio-rosa/834052/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/cardeal-leme/siila-/355923',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/cardeal-leme/355923/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/cardeal-leme/siila/355923',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/cardeal-leme/355923/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/barra-da-tijuca/barra-business-center-bloco-3-corporate-/siila/500006/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/barra-business-center-bloco-3-corporate-/500006/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/barra-business-center-bloco-3-corporate-/siila-/500006',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/barra-business-center-bloco-3-corporate-/500006/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/pinheiros/metro-office-ii/wework/98416/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-butanta-194/98416/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/alphaville/centro-administrativo-rio-negro-torre-jacari/siila-/500489',
  'https://siilaspot.com.br/lease/office/sao-paulo/barueri/centro-administrativo-rio-negro-torre-jacari/500489/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/bolsa-de-valores-do-rio-de-janeiro/siila/500137/lang/pt-br',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/bolsa-de-valores-do-rio-de-janeiro/500137/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/barra-da-tijuca/centro-empresarial-downtown-bloco-7/siila-/500283',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-downtown-bloco-7/500283/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/gerai-/718279',
  'https://siilaspot.com.br/profile/gerai-/718279/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/vl-ol/advanced-tower/siila-brasil-ltda/40570/lang/en',
  'https://siilaspot.com.br/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/samanta-barbosa/29074124',
  'https://siilaspot.com.br/profile/samanta-barbosa/29074124/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/galpao-isolado-aurum-200/aurum-imobiliaria-corporativa/1238307/1000',
  'https://siilaspot.com.br/sale/industrial/ceara/maracanau/stand-alone-aurum-200/1238307/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/aldeota/blue-corporate/aurum-imobiliaria-corporativa/1238383/1000',
  'https://siilaspot.com.br/lease/office/ceara/fortaleza/blue-corporate/1238383/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/departamento-comercial/10006274',
  'https://siilaspot.com.br/profile/departamento-comercial/10006274/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/as-brasil/27548261/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/as-brasil/27548261/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/condominio-sao-bento/31652762/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/condominio-sao-bento/31652762/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/paulo-mauro-construtora/29873741/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/paulo-mauro-construtora/29873741/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/marc-negocios-imobiliarios/32511413/en/en',
  'https://siilaspot.com.br/company/marc-negocios-imobiliarios/32511413/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/as-brasil/27548261/en/en',
  'https://siilaspot.com.br/company/as-brasil/27548261/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/barzel-properties/385374/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/barzel-properties/385374/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/ebpark-empresarial/31352198/en/en',
  'https://siilaspot.com.br/company/ebpark-empresarial/31352198/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/ak-realty-grupo-induscred/2725309/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/ak-realty-grupo-induscred/2725309/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/rbr-asset-management/238238/en/en',
  'https://siilaspot.com.br/company/rbr-asset-management/238238/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/o-sucesso-imobiliario/26345767/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/o-sucesso-imobiliario/26345767/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/bresco-investimentos/14032/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/bresco-investimentos/14032/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/rbr-asset-management/238238/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/rbr-asset-management/238238/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/wework/851772/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/wework/851772/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/jose-martins-solucoes-em-desenvolvimento-imobiliario/1418381/en/en',
  'https://siilaspot.com.br/company/jose-martins-solucoes-em-desenvolvimento-imobiliario/1418381/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/marc-negocios-imobiliarios/32511413/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/marc-negocios-imobiliarios/32511413/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/stand-alone-aurum-200/aurum-imobiliaria-corporativa/1238307/lang/en',
  'https://siilaspot.com.br/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/385374/lang/en',
  'https://siilaspot.com.br/company/cy-capital/32436959/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/cy-capital/32436959/en/en',
  'https://siilaspot.com.br/company/cy-capital/32436959/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/fundacao-valia/170835/en/en',
  'https://siilaspot.com.br/company/fundacao-valia/170835/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/itacare-imoveis-e-participacoes/1443766/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/itacare-imoveis-e-participacoes/1443766/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/erea-eminence-real-estate-advisory/453537/pt-br/pt-br',
  'https://siilaspot.com.br/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/condominio-sao-bento/31652762/en/en',
  'https://siilaspot.com.br/company/condominio-sao-bento/31652762/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danilo-araujo/6977',
  'https://siilaspot.com.br/profile/danilo-araujo/6977/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/fundacao-valia/170835/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/fundacao-valia/170835/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rene-araujo-santos-junior/30691575',
  'https://siilaspot.com.br/profile/rene-araujo-santos-junior/30691575/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/empresa/luiz-augusto-de-souza-queiroz-ferraz/30097634/pt-br/pt-br',
  'https://siilaspot.com.br/empresa/luiz-augusto-de-souza-queiroz-ferraz/30097634/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/roberto-padilha/28534344',
  'https://siilaspot.com.br/profile/roberto-padilha/28534344/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806/en/en',
  'https://siilaspot.com.br/company/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/consultora-imobiliaria/26301791',
  'https://siilaspot.com.br/profile/consultora-imobiliaria/26301791/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/paulo-mauro-construtora/29873741/en/en',
  'https://siilaspot.com.br/company/paulo-mauro-construtora/29873741/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/claudia-singer/1741066',
  'https://siilaspot.com.br/profile/claudia-singer/1741066/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/brigadeiro/advanced-office-brigadeiro/7-bridges-latin-america-llc/1206532',
  'https://siilaspot.com.br/pt-br'
);
urlRedirects.set('https://siilaspot.com.br/spot-/pt-br', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/coworking/escritorio/rio-de-', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/locacao/escritorio/sao-', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/lease/industrial/sao-', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/locacao/escritorio/rio', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/home/en', 'https://siilaspot.com.br/pt-br');
urlRedirects.set('https://siilaspot.com.br/home/pt-br', 'https://siilaspot.com.br/pt-br');

urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/paulista/delta-plaza/rbr-asset-management/10832',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/delta-plaza/10832/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/paulista/delta-plaza/rbr-asset-management/10832/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/delta-plaza/10832/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/paulista/delta-plaza/rbr-asset-management/10832/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/delta-plaza/10832/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/jabaquara/cea-centro-empresarial-do-aco-torre-a/barzel-properties/11482',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/cea-centro-empresarial-do-aco-torre-a/11482/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/jabaquara/cea-centro-empresarial-do-aco-torre-a/barzel-properties/11482/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/cea-centro-empresarial-do-aco-torre-a/11482/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/jabaquara/cea-centro-empresarial-do-aco-torre-a/barzel-properties/11482/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/cea-centro-empresarial-do-aco-torre-a/11482/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/glp-gravatai/o-sucesso-imobiliario/23452/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/gravatai/glp-gravatai/23452/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/glp-gravatai/o-sucesso-imobiliario/23452/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/gravatai/glp-gravatai/23452/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/glp-gravatai/o-sucesso-imobiliario/23452',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/gravatai/glp-gravatai/23452/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-logistico-sao-bento/condominio-sao-bento/23945/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/osasco/condominio-logistico-sao-bento/23945/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/condominio-logistico-sao-bento/condominio-sao-bento/23945/lang/en',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/osasco/condominio-logistico-sao-bento/23945/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-logistico-sao-bento/condominio-sao-bento/23945',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/osasco/condominio-logistico-sao-bento/23945/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/centro-logistico-rio-claro/centro-logistico-e-industrial-rio-claro/48758/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/rio-claro/centro-logistico-rio-claro/48758/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/centro-logistico-rio-claro/centro-logistico-e-industrial-rio-claro/48758/lang/en',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/rio-claro/centro-logistico-rio-claro/48758/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/centro-logistico-rio-claro/centro-logistico-e-industrial-rio-claro/48758',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/rio-claro/centro-logistico-rio-claro/48758/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/santa-efigenia/wework-shopping-boulevard/wework/76044',
  'https://siilaspot.com.br/coworking/escritorio/minas-gerais/belo-horizonte/wework-shopping-boulevard/76044/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/santa-efigenia/wework-shopping-boulevard/wework/76044/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/minas-gerais/belo-horizonte/wework-shopping-boulevard/76044/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/santa-efigenia/wework-shopping-boulevard/wework/76044/lang/en',
  'https://siilaspot.com.br/coworking/office/minas-gerais/belo-horizonte/wework-shopping-boulevard/76044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/pinheiros/wework-butanta-194/wework/98416',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-butanta-194/98416/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/pinheiros/wework-butanta-194/wework/98416/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-butanta-194/98416/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/pinheiros/wework-butanta-194/wework/98416/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-butanta-194/98416/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/vila-olimpia/celebration-i/rbr-asset-management/111632',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/celebration-i/111632/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/vila-olimpia/celebration-i/rbr-asset-management/111632/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/celebration-i/111632/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/vila-olimpia/celebration-i/rbr-asset-management/111632/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/celebration-i/111632/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/rio-de-janeiro/barao-de-maua/fundacao-valia/256466',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/barao-de-maua/256466/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/rio-de-janeiro/barao-de-maua/fundacao-valia/256466/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/barao-de-maua/256466/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/rio-de-janeiro/barao-de-maua/fundacao-valia/256466/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/barao-de-maua/256466/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/marginal-pinheiros/abp-dallas-bloco-d/fundacao-valia/500001',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/marginal-pinheiros/abp-dallas-bloco-d/fundacao-valia/500001/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/marginal-pinheiros/abp-dallas-bloco-d/fundacao-valia/500001/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/abp-dallas-bloco-d/500001/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/rio-office-tower/fundacao-valia/500010',
  'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/rio-office-tower/fundacao-valia/500010/lang/pt-br',
  'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/centro/rio-office-tower/fundacao-valia/500010/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/rio-office-tower/fundacao-valia/500010',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/rio-office-tower/fundacao-valia/500010/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro/rio-office-tower/fundacao-valia/500010/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/rio-office-tower/500010/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/retiro-morumbi/abp-miami-bloco-c/fundacao-valia/500011',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/retiro-morumbi/abp-miami-bloco-c/fundacao-valia/500011/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/retiro-morumbi/abp-miami-bloco-c/fundacao-valia/500011/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/abp-miami-bloco-c/500011/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-olimpia/wework-jk-iguatemi/wework/500023',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-jk-iguatemi/500023/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-olimpia/wework-jk-iguatemi/wework/500023/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-jk-iguatemi/500023/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/vila-olimpia/wework-jk-iguatemi/wework/500023/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-jk-iguatemi/500023/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/juscelino-kubitschek/sao-luiz-bloco-i/barzel-properties/500036',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/sao-luiz-bloco-i/500036/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/juscelino-kubitschek/sao-luiz-bloco-i/barzel-properties/500036/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/sao-luiz-bloco-i/500036/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/juscelino-kubitschek/sao-luiz-bloco-i/barzel-properties/500036/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/sao-luiz-bloco-i/500036/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/candelaria-corporate/fundacao-valia/500044',
  'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/centro/candelaria-corporate/fundacao-valia/500044/lang/pt-br',
  'https://siilaspot.com.br/venda/escritorio/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/centro/candelaria-corporate/fundacao-valia/500044/lang/en',
  'https://siilaspot.com.br/sale/office/rio-de-janeiro/rio-de-janeiro/candelaria-corporate/500044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/brooklin/wework-nacoes-unidas-14261-ala-a/wework/500058',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-a/500058/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/brooklin/wework-nacoes-unidas-14261-ala-a/wework/500058/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-a/500058/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/brooklin/wework-nacoes-unidas-14261-ala-a/wework/500058/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-a/500058/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/brooklin/wework-nacoes-unidas-14261-ala-b/wework/500059',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-b/500059/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/brooklin/wework-nacoes-unidas-14261-ala-b/wework/500059/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-b/500059/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/brooklin/wework-nacoes-unidas-14261-ala-b/wework/500059/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-14261-ala-b/500059/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/centro/wework-torre-almirante-barroso-81/wework/500076',
  'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/wework-torre-almirante-barroso-81/500076/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/centro/wework-torre-almirante-barroso-81/wework/500076/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/wework-torre-almirante-barroso-81/500076/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/centro/wework-torre-almirante-barroso-81/wework/500076/lang/en',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/wework-torre-almirante-barroso-81/500076/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/centro-empresarial-castelo-branco/rbr-asset-management/500101',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-castelo-branco/500101/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/centro/centro-empresarial-castelo-branco/rbr-asset-management/500101/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-castelo-branco/500101/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/centro/centro-empresarial-castelo-branco/rbr-asset-management/500101/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-castelo-branco/500101/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/cidade-nova/centro-empresarial-cidade-nova-teleporto/fundacao-valia/500102',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-cidade-nova-teleporto/500102/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/cidade-nova/centro-empresarial-cidade-nova-teleporto/fundacao-valia/500102/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-cidade-nova-teleporto/500102/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/cidade-nova/centro-empresarial-cidade-nova-teleporto/fundacao-valia/500102/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-cidade-nova-teleporto/500102/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/botafogo/centro-empresarial-mourisco-torre-corcovado/fundacao-valia/500104',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/botafogo/centro-empresarial-mourisco-torre-corcovado/fundacao-valia/500104/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/botafogo/centro-empresarial-mourisco-torre-corcovado/fundacao-valia/500104/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-mourisco-torre-corcovado/500104/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/faria-lima/wework-atilio-innocenti-165/wework/500141',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-atilio-innocenti-165/500141/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/faria-lima/wework-atilio-innocenti-165/wework/500141/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-atilio-innocenti-165/500141/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/faria-lima/wework-atilio-innocenti-165/wework/500141/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-atilio-innocenti-165/500141/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/berrini/berrini-500/barzel-properties/500153',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/berrini-500/500153/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/berrini/berrini-500/barzel-properties/500153/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/berrini-500/500153/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/berrini/berrini-500/barzel-properties/500153/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/berrini-500/500153/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/berrini/bolsa-de-imoveis-do-estado-de-sao-paulo/itacare-imoveis-e-participacoes/500158',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/berrini/bolsa-de-imoveis-do-estado-de-sao-paulo/itacare-imoveis-e-participacoes/500158/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/berrini/bolsa-de-imoveis-do-estado-de-sao-paulo/itacare-imoveis-e-participacoes/500158/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/paulista/wework-paulista-1374/wework/500160',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-paulista-1374/500160/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/paulista/wework-paulista-1374/wework/500160/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-paulista-1374/500160/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/paulista/wework-paulista-1374/wework/500160/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-paulista-1374/500160/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/berrini/wework-berrini/wework/500173',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-berrini/500173/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/berrini/wework-berrini/wework/500173/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-berrini/500173/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/berrini/wework-berrini/wework/500173/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-berrini/500173/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/cidade-jardim/cidade-jardim-corporate-center-continental-tower/fundacao-valia/500187',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/cidade-jardim-corporate-center-continental-tower/500187/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/cidade-jardim/cidade-jardim-corporate-center-continental-tower/fundacao-valia/500187/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/cidade-jardim-corporate-center-continental-tower/500187/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/cidade-jardim/cidade-jardim-corporate-center-continental-tower/fundacao-valia/500187/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/cidade-jardim-corporate-center-continental-tower/500187/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/pinheiros-one/barzel-properties/500203',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/pinheiros-one/500203/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/pinheiros-one/barzel-properties/500203/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/pinheiros-one/500203/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/pinheiros/pinheiros-one/barzel-properties/500203/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/pinheiros-one/500203/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/berrini/wtc-world-trade-center/bb-previdencia/500207',
  'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/wtc-world-trade-center/500207/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/berrini/wtc-world-trade-center/bb-previdencia/500207/lang/pt-br',
  'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/wtc-world-trade-center/500207/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/berrini/wtc-world-trade-center/bb-previdencia/500207/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/wtc-world-trade-center/500207/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/chucri-zaidan/wework-market-place/wework/500236',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-market-place/500236/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/chucri-zaidan/wework-market-place/wework/500236/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-market-place/500236/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/chucri-zaidan/wework-market-place/wework/500236/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-market-place/500236/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/berrini/wework-nacoes-unidas-12995/wework/500271',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-nacoes-unidas-12995/500271/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/berrini/wework-nacoes-unidas-12995/wework/500271/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-nacoes-unidas-12995/500271/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/berrini/wework-nacoes-unidas-12995/wework/500271/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-nacoes-unidas-12995/500271/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/berrini/thera-corporate/barzel-properties/500307',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/thera-corporate/500307/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/berrini/thera-corporate/barzel-properties/500307/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/thera-corporate/500307/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/berrini/thera-corporate/barzel-properties/500307/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/thera-corporate/500307/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/vila-olimpia/mykonos/rbr-asset-management/500367',
  'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/mykonos/500367/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/vila-olimpia/mykonos/rbr-asset-management/500367/lang/pt-br',
  'https://siilaspot.com.br/venda/escritorio/sao-paulo/sao-paulo/mykonos/500367/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/vila-olimpia/mykonos/rbr-asset-management/500367/lang/en',
  'https://siilaspot.com.br/sale/office/sao-paulo/sao-paulo/mykonos/500367/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/chacara-santo-antonio/edificio-alianca/barzel-properties/500509',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-alianca/500509/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/chacara-santo-antonio/edificio-alianca/barzel-properties/500509/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-alianca/500509/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/chacara-santo-antonio/edificio-alianca/barzel-properties/500509/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-alianca/500509/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/jardins/edificio-sompo/barzel-properties/500510',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-sompo/500510/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/jardins/edificio-sompo/barzel-properties/500510/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-sompo/500510/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/jardins/edificio-sompo/barzel-properties/500510/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-sompo/500510/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/faria-lima/faria-lima-business-center/barzel-properties/500516',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/faria-lima-business-center/500516/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/faria-lima/faria-lima-business-center/barzel-properties/500516/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/faria-lima-business-center/500516/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/faria-lima/faria-lima-business-center/barzel-properties/500516/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/faria-lima-business-center/500516/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/pinheiros-corporate/barzel-properties/500521',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/pinheiros-corporate/500521/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/pinheiros-corporate/barzel-properties/500521/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/pinheiros-corporate/500521/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/pinheiros/pinheiros-corporate/barzel-properties/500521/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/pinheiros-corporate/500521/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/porto/centro-empresarial-regiao-portuaria-ex-venezuela-43-/rbr-asset-management/500575',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-regiao-portuaria-ex-venezuela-43-/500575/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/porto/centro-empresarial-regiao-portuaria-ex-venezuela-43-/rbr-asset-management/500575/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/rio-de-janeiro/rio-de-janeiro/centro-empresarial-regiao-portuaria-ex-venezuela-43-/500575/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/porto/centro-empresarial-regiao-portuaria-ex-venezuela-43-/rbr-asset-management/500575/lang/en',
  'https://siilaspot.com.br/lease/office/rio-de-janeiro/rio-de-janeiro/centro-empresarial-regiao-portuaria-ex-venezuela-43-/500575/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/centro/wework-bossa-nova-mall/wework/500612',
  'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/wework-bossa-nova-mall/500612/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/centro/wework-bossa-nova-mall/wework/500612/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/wework-bossa-nova-mall/500612/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/centro/wework-bossa-nova-mall/wework/500612/lang/en',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/wework-bossa-nova-mall/500612/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/faria-lima/wework-faria-lima-4055/wework/500647',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-faria-lima-4055/500647/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/faria-lima/wework-faria-lima-4055/wework/500647/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-faria-lima-4055/500647/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/faria-lima/wework-faria-lima-4055/wework/500647/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-faria-lima-4055/500647/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/barra-funda/wework-jardim-das-perdizes/wework/500648',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-jardim-das-perdizes/500648/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/barra-funda/wework-jardim-das-perdizes/wework/500648/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-jardim-das-perdizes/500648/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/barra-funda/wework-jardim-das-perdizes/wework/500648/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-jardim-das-perdizes/500648/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/paulista/wework-paulista-2537/wework/500663',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-paulista-2537/500663/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/paulista/wework-paulista-2537/wework/500663/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-paulista-2537/500663/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/paulista/wework-paulista-2537/wework/500663/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-paulista-2537/500663/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/carlos-gomes/wework-carlos-gomes-1672/wework/501093',
  'https://siilaspot.com.br/coworking/escritorio/rio-grande-do-sul/porto-alegre/wework-carlos-gomes-1672/501093/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/carlos-gomes/wework-carlos-gomes-1672/wework/501093/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/rio-grande-do-sul/porto-alegre/wework-carlos-gomes-1672/501093/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/carlos-gomes/wework-carlos-gomes-1672/wework/501093/lang/en',
  'https://siilaspot.com.br/coworking/office/rio-grande-do-sul/porto-alegre/wework-carlos-gomes-1672/501093/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/pinheiros/wework-butanta-336/wework/516152',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-butanta-336/516152/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/pinheiros/wework-butanta-336/wework/516152/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-butanta-336/516152/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/pinheiros/wework-butanta-336/wework/516152/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-butanta-336/516152/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/barra-da-tijuca/wework-helios-seelinger-155/wework/529572',
  'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/wework-helios-seelinger-155/529572/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/barra-da-tijuca/wework-helios-seelinger-155/wework/529572/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/rio-de-janeiro/rio-de-janeiro/wework-helios-seelinger-155/529572/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/barra-da-tijuca/wework-helios-seelinger-155/wework/529572/lang/en',
  'https://siilaspot.com.br/coworking/office/rio-de-janeiro/rio-de-janeiro/wework-helios-seelinger-155/529572/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/pantheon/barzel-properties/529883',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/pantheon/529883/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/pinheiros/pantheon/barzel-properties/529883/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/pantheon/529883/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/pinheiros/pantheon/barzel-properties/529883/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/pantheon/529883/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cl-sp-dutra/erea-eminence-real-estate-advisory/600071/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-paulo/cl-sp-dutra/600071/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/cl-sp-dutra/erea-eminence-real-estate-advisory/600071/lang/en',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/sao-paulo/cl-sp-dutra/600071/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cl-sp-dutra/erea-eminence-real-estate-advisory/600071',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/sao-paulo/cl-sp-dutra/600071/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cy-log-embu/cy-capital/600275/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/embu-das-artes/cy-log-embu/600275/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/cy-log-embu/cy-capital/600275/lang/en',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/embu-das-artes/cy-log-embu/600275/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cy-log-embu/cy-capital/600275',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/embu-das-artes/cy-log-embu/600275/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/alphaville/wework-alphaville/wework/705238',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/barueri/wework-alphaville/705238/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/alphaville/wework-alphaville/wework/705238/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/barueri/wework-alphaville/705238/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/alphaville/wework-alphaville/wework/705238/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/barueri/wework-alphaville/705238/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/faria-lima/passarelli/luiz-augusto-de-souza-queiroz-ferraz/1059140',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/passarelli/1059140/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/faria-lima/passarelli/luiz-augusto-de-souza-queiroz-ferraz/1059140/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/passarelli/1059140/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/faria-lima/passarelli/luiz-augusto-de-souza-queiroz-ferraz/1059140/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/passarelli/1059140/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/mega-intermodal-esteio/o-sucesso-imobiliario/1121532/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/esteio/mega-intermodal-esteio/1121532/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/mega-intermodal-esteio/o-sucesso-imobiliario/1121532/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/esteio/mega-intermodal-esteio/1121532/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/mega-intermodal-esteio/o-sucesso-imobiliario/1121532',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/esteio/mega-intermodal-esteio/1121532/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/3sb-parque-logistico/o-sucesso-imobiliario/1121610/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/nova-santa-rita/3sb-parque-logistico/1121610/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/3sb-parque-logistico/o-sucesso-imobiliario/1121610/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/nova-santa-rita/3sb-parque-logistico/1121610/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/3sb-parque-logistico/o-sucesso-imobiliario/1121610',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/nova-santa-rita/3sb-parque-logistico/1121610/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/centro-logistico-araucaria-rs/o-sucesso-imobiliario/1121662/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/centro-logistico-araucaria-rs/1121662/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/centro-logistico-araucaria-rs/o-sucesso-imobiliario/1121662/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/centro-logistico-araucaria-rs/1121662/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/centro-logistico-araucaria-rs/o-sucesso-imobiliario/1121662',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/centro-logistico-araucaria-rs/1121662/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cachoeirinha-business-park/o-sucesso-imobiliario/1121775/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/cachoeirinha-business-park/o-sucesso-imobiliario/1121775/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cachoeirinha-business-park/o-sucesso-imobiliario/1121775',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/cachoeirinha/cachoeirinha-business-park/1121775/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/extrema-business-park-bloco-ii/rbr-asset-management/1225520/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/extrema-business-park-bloco-ii/1225520/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/extrema-business-park-bloco-ii/rbr-asset-management/1225520/lang/en',
  'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/extrema-business-park-bloco-ii/1225520/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/extrema-business-park-bloco-ii/rbr-asset-management/1225520',
  'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/extrema-business-park-bloco-ii/1225520/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/paulista/wework-rio-claro/wework/1234564',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-rio-claro/1234564/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/paulista/wework-rio-claro/wework/1234564/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-rio-claro/1234564/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/paulista/wework-rio-claro/wework/1234564/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-rio-claro/1234564/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/faria-lima/edificio-amauri-305/rbr-asset-management/1235000',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-amauri-305/1235000/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/faria-lima/edificio-amauri-305/rbr-asset-management/1235000/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-amauri-305/1235000/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/faria-lima/edificio-amauri-305/rbr-asset-management/1235000/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-amauri-305/1235000/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/savassi/wework-sergipe-1440/wework/1235015',
  'https://siilaspot.com.br/coworking/escritorio/minas-gerais/belo-horizonte/wework-sergipe-1440/1235015/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/savassi/wework-sergipe-1440/wework/1235015/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/minas-gerais/belo-horizonte/wework-sergipe-1440/1235015/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/savassi/wework-sergipe-1440/wework/1235015/lang/en',
  'https://siilaspot.com.br/coworking/office/minas-gerais/belo-horizonte/wework-sergipe-1440/1235015/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/marginal-pinheiros/river-one/rbr-asset-management/1235026',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/river-one/1235026/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/marginal-pinheiros/river-one/rbr-asset-management/1235026/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/river-one/1235026/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/marginal-pinheiros/river-one/rbr-asset-management/1235026/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/river-one/1235026/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-logistico-ksm-log-extrema/rbr-asset-management/1235044/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/condominio-logistico-ksm-log-extrema/1235044/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/condominio-logistico-ksm-log-extrema/rbr-asset-management/1235044/lang/en',
  'https://siilaspot.com.br/locacao/industrial/minas-gerais/extrema/condominio-logistico-ksm-log-extrema/1235044/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-logistico-ksm-log-extrema/rbr-asset-management/1235044',
  'https://siilaspot.com.br/lease/industrial/minas-gerais/extrema/condominio-logistico-ksm-log-extrema/1235044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/berrini/wework-florida-1970/wework/1235355',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-florida-1970/1235355/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/berrini/wework-florida-1970/wework/1235355/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-florida-1970/1235355/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/berrini/wework-florida-1970/wework/1235355/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-florida-1970/1235355/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-madalena/wework-girassol-555/wework/1235574',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-girassol-555/1235574/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-madalena/wework-girassol-555/wework/1235574/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-girassol-555/1235574/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/vila-madalena/wework-girassol-555/wework/1235574/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-girassol-555/1235574/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/pinheiros/wework-cerro-cora-2175/wework/1236834',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-cerro-cora-2175/1236834/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/pinheiros/wework-cerro-cora-2175/wework/1236834/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-cerro-cora-2175/1236834/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/pinheiros/wework-cerro-cora-2175/wework/1236834/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-cerro-cora-2175/1236834/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/sao-jose-dos-campos/wework-sjc/wework/1236993',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-jose-dos-campos/wework-sjc/1236993/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/sao-jose-dos-campos/wework-sjc/wework/1236993/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-jose-dos-campos/wework-sjc/1236993/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/sao-jose-dos-campos/wework-sjc/wework/1236993/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-jose-dos-campos/wework-sjc/1236993/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/sao-bernardo-do-campo/wework-pereira-barreto/wework/1236997',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-bernardo-do-campo/wework-pereira-barreto/1236997/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/sao-bernardo-do-campo/wework-pereira-barreto/wework/1236997/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-bernardo-do-campo/wework-pereira-barreto/1236997/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/sao-bernardo-do-campo/wework-pereira-barreto/wework/1236997/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-bernardo-do-campo/wework-pereira-barreto/1236997/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/jardim-paulista/wework-oscar-freire/wework/1236998',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-oscar-freire/1236998/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/jardim-paulista/wework-oscar-freire/wework/1236998/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-oscar-freire/1236998/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/jardim-paulista/wework-oscar-freire/wework/1236998/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-oscar-freire/1236998/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-madalena/wework-purpurina-400/wework/1237000',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-purpurina-400/1237000/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/vila-madalena/wework-purpurina-400/wework/1237000/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/sao-paulo/wework-purpurina-400/1237000/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/vila-madalena/wework-purpurina-400/wework/1237000/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/sao-paulo/wework-purpurina-400/1237000/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/alphaville/pravda/rbr-asset-management/1237355',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/pravda/1237355/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/alphaville/pravda/rbr-asset-management/1237355/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/barueri/pravda/1237355/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/alphaville/pravda/rbr-asset-management/1237355/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/barueri/pravda/1237355/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-logistico-rbr-hortolandia-ii-ex-fca-/rbr-asset-management/1237450/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/hortolandia/condominio-logistico-rbr-hortolandia-ii-ex-fca-/1237450/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/condominio-logistico-rbr-hortolandia-ii-ex-fca-/rbr-asset-management/1237450/lang/en',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/hortolandia/condominio-logistico-rbr-hortolandia-ii-ex-fca-/1237450/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-logistico-rbr-hortolandia-ii-ex-fca-/rbr-asset-management/1237450',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/hortolandia/condominio-logistico-rbr-hortolandia-ii-ex-fca-/1237450/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/wt-rbr-log-cajamar-franco-da-rocha/rbr-asset-management/1237881/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/franco-da-rocha/wt-rbr-log-cajamar-franco-da-rocha/1237881/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/wt-rbr-log-cajamar-franco-da-rocha/rbr-asset-management/1237881/lang/en',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/franco-da-rocha/wt-rbr-log-cajamar-franco-da-rocha/1237881/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/wt-rbr-log-cajamar-franco-da-rocha/rbr-asset-management/1237881',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/franco-da-rocha/wt-rbr-log-cajamar-franco-da-rocha/1237881/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-ponto-a-ponto/o-sucesso-imobiliario/1238004/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/condominio-ponto-a-ponto/1238004/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/condominio-ponto-a-ponto/o-sucesso-imobiliario/1238004/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/condominio-ponto-a-ponto/1238004/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-ponto-a-ponto/o-sucesso-imobiliario/1238004',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/condominio-ponto-a-ponto/1238004/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-logistico-rbr-hortolandia-ii-ex-fca-expansao-i-/rbr-asset-management/1238008/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/hortolandia/condominio-logistico-rbr-hortolandia-ii-ex-fca-expansao-i-/1238008/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/condominio-logistico-rbr-hortolandia-ii-ex-fca-expansao-i-/rbr-asset-management/1238008/lang/en',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/hortolandia/condominio-logistico-rbr-hortolandia-ii-ex-fca-expansao-i-/1238008/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-logistico-rbr-hortolandia-ii-ex-fca-expansao-i-/rbr-asset-management/1238008',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/hortolandia/condominio-logistico-rbr-hortolandia-ii-ex-fca-expansao-i-/1238008/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/syslog-cajamar/erea-eminence-real-estate-advisory/1238179/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/cajamar/syslog-cajamar/1238179/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/syslog-cajamar/erea-eminence-real-estate-advisory/1238179/lang/en',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/cajamar/syslog-cajamar/1238179/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/syslog-cajamar/erea-eminence-real-estate-advisory/1238179',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/cajamar/syslog-cajamar/1238179/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/setor-bancario-sul-asa-sul/edificio-sao-marcus/bb-previdencia/1238226',
  'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/edificio-sao-marcus/1238226/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/escritorio/setor-bancario-sul-asa-sul/edificio-sao-marcus/bb-previdencia/1238226/lang/pt-br',
  'https://siilaspot.com.br/venda/escritorio/distrito-federal/brasilia/edificio-sao-marcus/1238226/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/office/setor-bancario-sul-asa-sul/edificio-sao-marcus/bb-previdencia/1238226/lang/en',
  'https://siilaspot.com.br/sale/office/distrito-federal/brasilia/edificio-sao-marcus/1238226/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cy-log-guarulhos/cy-capital/1238282/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/guarulhos/cy-log-guarulhos/1238282/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/cy-log-guarulhos/cy-capital/1238282/lang/en',
  'https://siilaspot.com.br/locacao/industrial/sao-paulo/guarulhos/cy-log-guarulhos/1238282/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/cy-log-guarulhos/cy-capital/1238282',
  'https://siilaspot.com.br/lease/industrial/sao-paulo/guarulhos/cy-log-guarulhos/1238282/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/bwdiase-business-park-fortaleza/aurum-imobiliaria-corporativa/1238300/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/ceara/itaitinga/bwdiase-business-park-fortaleza/1238300/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/bwdiase-business-park-fortaleza/aurum-imobiliaria-corporativa/1238300/lang/en',
  'https://siilaspot.com.br/locacao/industrial/ceara/itaitinga/bwdiase-business-park-fortaleza/1238300/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/bwdiase-business-park-fortaleza/aurum-imobiliaria-corporativa/1238300',
  'https://siilaspot.com.br/lease/industrial/ceara/itaitinga/bwdiase-business-park-fortaleza/1238300/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/aurum-100/aurum-imobiliaria-corporativa/1238306/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/ceara/maracanau/aurum-100/1238306/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/aurum-100/aurum-imobiliaria-corporativa/1238306/lang/en',
  'https://siilaspot.com.br/locacao/industrial/ceara/maracanau/aurum-100/1238306/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/aurum-100/aurum-imobiliaria-corporativa/1238306',
  'https://siilaspot.com.br/lease/industrial/ceara/maracanau/aurum-100/1238306/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/galpao-isolado-aurum-200/aurum-imobiliaria-corporativa/1238307/lang/pt-br',
  'https://siilaspot.com.br/venda/industrial/ceara/maracanau/galpao-isolado-aurum-200/1238307/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/sale/industrial/stand-alone-aurum-200/aurum-imobiliaria-corporativa/1238307/lang/en',
  'https://siilaspot.com.br/venda/industrial/ceara/maracanau/galpao-isolado-aurum-200/1238307/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/venda/industrial/galpao-isolado-aurum-200/aurum-imobiliaria-corporativa/1238307',
  'https://siilaspot.com.br/sale/industrial/ceara/maracanau/stand-alone-aurum-200/1238307/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-aurum-300/aurum-imobiliaria-corporativa/1238382/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/ceara/juazeiro-do-norte/condominio-aurum-300/1238382/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/condominio-aurum-300/aurum-imobiliaria-corporativa/1238382/lang/en',
  'https://siilaspot.com.br/locacao/industrial/ceara/juazeiro-do-norte/condominio-aurum-300/1238382/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/condominio-aurum-300/aurum-imobiliaria-corporativa/1238382',
  'https://siilaspot.com.br/lease/industrial/ceara/juazeiro-do-norte/condominio-aurum-300/1238382/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/aldeota/blue-corporate/aurum-imobiliaria-corporativa/1238383',
  'https://siilaspot.com.br/locacao/escritorio/ceara/fortaleza/blue-corporate/1238383/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/aldeota/blue-corporate/aurum-imobiliaria-corporativa/1238383/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/ceara/fortaleza/blue-corporate/1238383/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/aldeota/blue-corporate/aurum-imobiliaria-corporativa/1238383/lang/en',
  'https://siilaspot.com.br/lease/office/ceara/fortaleza/blue-corporate/1238383/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/eco-parque-empresarial-lourenco-amp-souza/o-sucesso-imobiliario/1238398/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/sapucaia-do-sul/eco-parque-empresarial-lourenco-amp-souza/1238398/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/eco-parque-empresarial-lourenco-amp-souza/o-sucesso-imobiliario/1238398/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/sapucaia-do-sul/eco-parque-empresarial-lourenco-amp-souza/1238398/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/eco-parque-empresarial-lourenco-amp-souza/o-sucesso-imobiliario/1238398',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/sapucaia-do-sul/eco-parque-empresarial-lourenco-amp-souza/1238398/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-avenida-das-industrias-1320/o-sucesso-imobiliario/1238401/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/galpao-isolado-avenida-das-industrias-1320/1238401/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/galpao-isolado-avenida-das-industrias-1320/o-sucesso-imobiliario/1238401/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/galpao-isolado-avenida-das-industrias-1320/1238401/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-avenida-das-industrias-1320/o-sucesso-imobiliario/1238401',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/galpao-isolado-avenida-das-industrias-1320/1238401/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-br-116-marques-de-montalvao/o-sucesso-imobiliario/1238404/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/sapucaia-do-sul/galpao-isolado-br-116-marques-de-montalvao/1238404/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/galpao-isolado-br-116-marques-de-montalvao/o-sucesso-imobiliario/1238404/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/sapucaia-do-sul/galpao-isolado-br-116-marques-de-montalvao/1238404/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-br-116-marques-de-montalvao/o-sucesso-imobiliario/1238404',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/sapucaia-do-sul/galpao-isolado-br-116-marques-de-montalvao/1238404/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/joao-victor-martins-l-lacerda/1418384',
  'https://siilaspot.com.br/perfil/joao-victor-martins-l-lacerda/1418384'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/joao-victor-martins-l-lacerda/1418384/lang/pt-br',
  'https://siilaspot.com.br/perfil/joao-victor-martins-l-lacerda/1418384/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/joao-victor-martins-l-lacerda/1418384/lang/en',
  'https://siilaspot.com.br/profile/joao-victor-martins-l-lacerda/1418384/en'
);

urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-severo-dullius-1995/o-sucesso-imobiliario/1238405/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/galpao-isolado-severo-dullius-1995/1238405/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/galpao-isolado-severo-dullius-1995/o-sucesso-imobiliario/1238405/lang/en',
  'https://siilaspot.com.br/locacao/industrial/rio-grande-do-sul/porto-alegre/galpao-isolado-severo-dullius-1995/1238405/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/galpao-isolado-severo-dullius-1995/o-sucesso-imobiliario/1238405',
  'https://siilaspot.com.br/lease/industrial/rio-grande-do-sul/porto-alegre/galpao-isolado-severo-dullius-1995/1238405/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/hubmall-quixada/aurum-imobiliaria-corporativa/1238437/lang/pt-br',
  'https://siilaspot.com.br/locacao/industrial/ceara/quixada/hubmall-quixada/1238437/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/industrial/hubmall-quixada/aurum-imobiliaria-corporativa/1238437/lang/en',
  'https://siilaspot.com.br/locacao/industrial/ceara/quixada/hubmall-quixada/1238437/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/industrial/hubmall-quixada/aurum-imobiliaria-corporativa/1238437',
  'https://siilaspot.com.br/lease/industrial/ceara/quixada/hubmall-quixada/1238437/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/osasco/wework-osasco/wework/1238450',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/osasco/wework-osasco/1238450/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/escritorio/osasco/wework-osasco/wework/1238450/lang/pt-br',
  'https://siilaspot.com.br/coworking/escritorio/sao-paulo/osasco/wework-osasco/1238450/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/coworking/office/osasco/wework-osasco/wework/1238450/lang/en',
  'https://siilaspot.com.br/coworking/office/sao-paulo/osasco/wework-osasco/1238450/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/aclimacao/edificio-marc/marc-negocios-imobiliarios/1238454',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-marc/1238454/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/aclimacao/edificio-marc/marc-negocios-imobiliarios/1238454/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/edificio-marc/1238454/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/lease/office/aclimacao/edificio-marc/marc-negocios-imobiliarios/1238454/lang/en',
  'https://siilaspot.com.br/lease/office/sao-paulo/sao-paulo/edificio-marc/1238454/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/bresco-investimentos/14032',
  'https://siilaspot.com.br/empresa/bresco-investimentos/14032/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/bresco-investimentos/14032/lang/pt-br',
  'https://siilaspot.com.br/empresa/bresco-investimentos/14032/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/bresco-investimentos/14032/lang/en',
  'https://siilaspot.com.br/company/bresco-investimentos/14032/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rodolfo-nicastro/18677',
  'https://siilaspot.com.br/perfil/rodolfo-nicastro/18677'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rodolfo-nicastro/18677/lang/pt-br',
  'https://siilaspot.com.br/perfil/rodolfo-nicastro/18677/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rodolfo-nicastro/18677/lang/en',
  'https://siilaspot.com.br/profile/rodolfo-nicastro/18677/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/bernardo-sampaio/154131',
  'https://siilaspot.com.br/perfil/bernardo-sampaio/154131'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/bernardo-sampaio/154131/lang/pt-br',
  'https://siilaspot.com.br/perfil/bernardo-sampaio/154131/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/bernardo-sampaio/154131/lang/en',
  'https://siilaspot.com.br/profile/bernardo-sampaio/154131/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/fundacao-valia/170835',
  'https://siilaspot.com.br/empresa/fundacao-valia/170835/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/fundacao-valia/170835/lang/pt-br',
  'https://siilaspot.com.br/empresa/fundacao-valia/170835/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/fundacao-valia/170835/lang/en',
  'https://siilaspot.com.br/company/fundacao-valia/170835/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danielle-vieitez/170898',
  'https://siilaspot.com.br/perfil/danielle-vieitez/170898'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danielle-vieitez/170898/lang/pt-br',
  'https://siilaspot.com.br/perfil/danielle-vieitez/170898/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danielle-vieitez/170898/lang/en',
  'https://siilaspot.com.br/profile/danielle-vieitez/170898/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/hugo-rodrigues/170916',
  'https://siilaspot.com.br/perfil/hugo-rodrigues/170916'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/hugo-rodrigues/170916/lang/pt-br',
  'https://siilaspot.com.br/perfil/hugo-rodrigues/170916/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/hugo-rodrigues/170916/lang/en',
  'https://siilaspot.com.br/profile/hugo-rodrigues/170916/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/rbr-asset-management/238238',
  'https://siilaspot.com.br/empresa/rbr-asset-management/238238/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/rbr-asset-management/238238/lang/pt-br',
  'https://siilaspot.com.br/empresa/rbr-asset-management/238238/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/rbr-asset-management/238238/lang/en',
  'https://siilaspot.com.br/company/rbr-asset-management/238238/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/barzel-properties/385374',
  'https://siilaspot.com.br/empresa/barzel-properties/385374/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/barzel-properties/385374/lang/pt-br',
  'https://siilaspot.com.br/empresa/barzel-properties/385374/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/barzel-properties/385374/lang/en',
  'https://siilaspot.com.br/company/barzel-properties/385374/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/erea-eminence-real-estate-advisory/453537',
  'https://siilaspot.com.br/empresa/erea-eminence-real-estate-advisory/453537/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/erea-eminence-real-estate-advisory/453537/lang/pt-br',
  'https://siilaspot.com.br/empresa/erea-eminence-real-estate-advisory/453537/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/erea-eminence-real-estate-advisory/453537/lang/en',
  'https://siilaspot.com.br/company/erea-eminence-real-estate-advisory/453537/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danilo-biajoli/525044',
  'https://siilaspot.com.br/perfil/danilo-biajoli/525044'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danilo-biajoli/525044/lang/pt-br',
  'https://siilaspot.com.br/perfil/danilo-biajoli/525044/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danilo-biajoli/525044/lang/en',
  'https://siilaspot.com.br/profile/danilo-biajoli/525044/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/wework/851772',
  'https://siilaspot.com.br/empresa/wework/851772/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/wework/851772/lang/pt-br',
  'https://siilaspot.com.br/empresa/wework/851772/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/wework/851772/lang/en',
  'https://siilaspot.com.br/company/wework/851772/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/susy-godoy/948423',
  'https://siilaspot.com.br/perfil/susy-godoy/948423'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/susy-godoy/948423/lang/pt-br',
  'https://siilaspot.com.br/perfil/susy-godoy/948423/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/susy-godoy/948423/lang/en',
  'https://siilaspot.com.br/profile/susy-godoy/948423/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/sao-carlos-empreendimentos-e-participacoes-s-a/1030131',
  'https://siilaspot.com.br/empresa/sao-carlos-empreendimentos-e-participacoes-s-a/1030131/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/sao-carlos-empreendimentos-e-participacoes-s-a/1030131/lang/pt-br',
  'https://siilaspot.com.br/empresa/sao-carlos-empreendimentos-e-participacoes-s-a/1030131/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/sao-carlos-empreendimentos-e-participacoes-s-a/1030131/lang/en',
  'https://siilaspot.com.br/company/sao-carlos-empreendimentos-e-participacoes-s-a/1030131/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/lucyana-afonso-leao/1041019',
  'https://siilaspot.com.br/perfil/lucyana-afonso-leao/1041019'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/lucyana-afonso-leao/1041019/lang/pt-br',
  'https://siilaspot.com.br/perfil/lucyana-afonso-leao/1041019/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/lucyana-afonso-leao/1041019/lang/en',
  'https://siilaspot.com.br/profile/lucyana-afonso-leao/1041019/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/kim-house/1183430',
  'https://siilaspot.com.br/perfil/kim-house/1183430'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/kim-house/1183430/lang/pt-br',
  'https://siilaspot.com.br/perfil/kim-house/1183430/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/kim-house/1183430/lang/en',
  'https://siilaspot.com.br/profile/kim-house/1183430/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/jose-martins-solucoes-em-desenvolvimento-imobiliario/1418381',
  'https://siilaspot.com.br/empresa/jose-martins-solucoes-em-desenvolvimento-imobiliario/1418381/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/jose-martins-solucoes-em-desenvolvimento-imobiliario/1418381/lang/pt-br',
  'https://siilaspot.com.br/empresa/jose-martins-solucoes-em-desenvolvimento-imobiliario/1418381/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/jose-martins-solucoes-em-desenvolvimento-imobiliario/1418381/lang/en',
  'https://siilaspot.com.br/company/jose-martins-solucoes-em-desenvolvimento-imobiliario/1418381/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/itacare-imoveis-e-participacoes/1443766',
  'https://siilaspot.com.br/empresa/itacare-imoveis-e-participacoes/1443766/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/itacare-imoveis-e-participacoes/1443766/lang/pt-br',
  'https://siilaspot.com.br/empresa/itacare-imoveis-e-participacoes/1443766/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/itacare-imoveis-e-participacoes/1443766/lang/en',
  'https://siilaspot.com.br/company/itacare-imoveis-e-participacoes/1443766/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/luiz-augusto-de-souza-queiroz-ferraz/1443782',
  'https://siilaspot.com.br/perfil/luiz-augusto-de-souza-queiroz-ferraz/1443782'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/luiz-augusto-de-souza-queiroz-ferraz/1443782/lang/pt-br',
  'https://siilaspot.com.br/perfil/luiz-augusto-de-souza-queiroz-ferraz/1443782/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/luiz-augusto-de-souza-queiroz-ferraz/1443782/lang/en',
  'https://siilaspot.com.br/profile/luiz-augusto-de-souza-queiroz-ferraz/1443782/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/vanessa-malta/1472251',
  'https://siilaspot.com.br/perfil/vanessa-malta/1472251'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/vanessa-malta/1472251/lang/pt-br',
  'https://siilaspot.com.br/perfil/vanessa-malta/1472251/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/vanessa-malta/1472251/lang/en',
  'https://siilaspot.com.br/profile/vanessa-malta/1472251/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/carlos-duarte/1489877',
  'https://siilaspot.com.br/perfil/carlos-duarte/1489877'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/carlos-duarte/1489877/lang/pt-br',
  'https://siilaspot.com.br/perfil/carlos-duarte/1489877/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/carlos-duarte/1489877/lang/en',
  'https://siilaspot.com.br/profile/carlos-duarte/1489877/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/siila-notificacoes/1615432',
  'https://siilaspot.com.br/perfil/siila-notificacoes/1615432'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/siila-notificacoes/1615432/lang/pt-br',
  'https://siilaspot.com.br/perfil/siila-notificacoes/1615432/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/siila-notificacoes/1615432/lang/en',
  'https://siilaspot.com.br/profile/siila-notificacoes/1615432/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/bernar-braga/1776503',
  'https://siilaspot.com.br/perfil/bernar-braga/1776503'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/bernar-braga/1776503/lang/pt-br',
  'https://siilaspot.com.br/perfil/bernar-braga/1776503/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/bernar-braga/1776503/lang/en',
  'https://siilaspot.com.br/profile/bernar-braga/1776503/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/marcello-yudi-okuyama/1903205',
  'https://siilaspot.com.br/perfil/marcello-yudi-okuyama/1903205'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/marcello-yudi-okuyama/1903205/lang/pt-br',
  'https://siilaspot.com.br/perfil/marcello-yudi-okuyama/1903205/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/marcello-yudi-okuyama/1903205/lang/en',
  'https://siilaspot.com.br/profile/marcello-yudi-okuyama/1903205/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/especialistas-em-coworking/1923690',
  'https://siilaspot.com.br/perfil/especialistas-em-coworking/1923690'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/especialistas-em-coworking/1923690/lang/pt-br',
  'https://siilaspot.com.br/perfil/especialistas-em-coworking/1923690/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/especialistas-em-coworking/1923690/lang/en',
  'https://siilaspot.com.br/profile/especialistas-em-coworking/1923690/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danielle-santos/2343618',
  'https://siilaspot.com.br/perfil/danielle-santos/2343618'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danielle-santos/2343618/lang/pt-br',
  'https://siilaspot.com.br/perfil/danielle-santos/2343618/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/danielle-santos/2343618/lang/en',
  'https://siilaspot.com.br/profile/danielle-santos/2343618/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/julio-grings/2588720',
  'https://siilaspot.com.br/perfil/julio-grings/2588720'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/julio-grings/2588720/lang/pt-br',
  'https://siilaspot.com.br/perfil/julio-grings/2588720/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/julio-grings/2588720/lang/en',
  'https://siilaspot.com.br/profile/julio-grings/2588720/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/ak-realty-grupo-induscred/2725309',
  'https://siilaspot.com.br/empresa/ak-realty-grupo-induscred/2725309/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/ak-realty-grupo-induscred/2725309/lang/pt-br',
  'https://siilaspot.com.br/empresa/ak-realty-grupo-induscred/2725309/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/ak-realty-grupo-induscred/2725309/lang/en',
  'https://siilaspot.com.br/company/ak-realty-grupo-induscred/2725309/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/siila-spot/3091688',
  'https://siilaspot.com.br/perfil/siila-spot/3091688'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/siila-spot/3091688/lang/pt-br',
  'https://siilaspot.com.br/perfil/siila-spot/3091688/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/siila-spot/3091688/lang/en',
  'https://siilaspot.com.br/profile/siila-spot/3091688/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/fabio-baptista/3251093',
  'https://siilaspot.com.br/perfil/fabio-baptista/3251093'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/fabio-baptista/3251093/lang/pt-br',
  'https://siilaspot.com.br/perfil/fabio-baptista/3251093/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/fabio-baptista/3251093/lang/en',
  'https://siilaspot.com.br/profile/fabio-baptista/3251093/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/marilda-angelotti/4624671',
  'https://siilaspot.com.br/perfil/marilda-angelotti/4624671'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/marilda-angelotti/4624671/lang/pt-br',
  'https://siilaspot.com.br/perfil/marilda-angelotti/4624671/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/marilda-angelotti/4624671/lang/en',
  'https://siilaspot.com.br/profile/marilda-angelotti/4624671/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/mateus-lima/7041188',
  'https://siilaspot.com.br/perfil/mateus-lima/7041188'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/mateus-lima/7041188/lang/pt-br',
  'https://siilaspot.com.br/perfil/mateus-lima/7041188/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/mateus-lima/7041188/lang/en',
  'https://siilaspot.com.br/profile/mateus-lima/7041188/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/sergio-bola/7497737',
  'https://siilaspot.com.br/perfil/sergio-bola/7497737'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/sergio-bola/7497737/lang/pt-br',
  'https://siilaspot.com.br/perfil/sergio-bola/7497737/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/sergio-bola/7497737/lang/en',
  'https://siilaspot.com.br/profile/sergio-bola/7497737/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/manuela-alvarez/7615046',
  'https://siilaspot.com.br/perfil/manuela-alvarez/7615046'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/manuela-alvarez/7615046/lang/pt-br',
  'https://siilaspot.com.br/perfil/manuela-alvarez/7615046/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/manuela-alvarez/7615046/lang/en',
  'https://siilaspot.com.br/profile/manuela-alvarez/7615046/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/centro-logistico-e-industrial-rio-claro/9111345',
  'https://siilaspot.com.br/empresa/centro-logistico-e-industrial-rio-claro/9111345/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/centro-logistico-e-industrial-rio-claro/9111345/lang/pt-br',
  'https://siilaspot.com.br/empresa/centro-logistico-e-industrial-rio-claro/9111345/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/centro-logistico-e-industrial-rio-claro/9111345/lang/en',
  'https://siilaspot.com.br/company/centro-logistico-e-industrial-rio-claro/9111345/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/belmir-menegatti/9111358',
  'https://siilaspot.com.br/perfil/belmir-menegatti/9111358'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/belmir-menegatti/9111358/lang/pt-br',
  'https://siilaspot.com.br/perfil/belmir-menegatti/9111358/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/belmir-menegatti/9111358/lang/en',
  'https://siilaspot.com.br/profile/belmir-menegatti/9111358/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/aurum-imobiliaria-corporativa/19473160',
  'https://siilaspot.com.br/empresa/aurum-imobiliaria-corporativa/19473160/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/aurum-imobiliaria-corporativa/19473160/lang/pt-br',
  'https://siilaspot.com.br/empresa/aurum-imobiliaria-corporativa/19473160/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/aurum-imobiliaria-corporativa/19473160/lang/en',
  'https://siilaspot.com.br/company/aurum-imobiliaria-corporativa/19473160/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/nertan-rabelo/19473196',
  'https://siilaspot.com.br/perfil/nertan-rabelo/19473196'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/nertan-rabelo/19473196/lang/pt-br',
  'https://siilaspot.com.br/perfil/nertan-rabelo/19473196/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/nertan-rabelo/19473196/lang/en',
  'https://siilaspot.com.br/profile/nertan-rabelo/19473196/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/esthela-moraes/21895915',
  'https://siilaspot.com.br/perfil/esthela-moraes/21895915'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/esthela-moraes/21895915/lang/pt-br',
  'https://siilaspot.com.br/perfil/esthela-moraes/21895915/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/esthela-moraes/21895915/lang/en',
  'https://siilaspot.com.br/profile/esthela-moraes/21895915/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/manuela-bellodi-canho/22031419',
  'https://siilaspot.com.br/perfil/manuela-bellodi-canho/22031419'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/manuela-bellodi-canho/22031419/lang/pt-br',
  'https://siilaspot.com.br/perfil/manuela-bellodi-canho/22031419/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/manuela-bellodi-canho/22031419/lang/en',
  'https://siilaspot.com.br/profile/manuela-bellodi-canho/22031419/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rafael-melo/22789085',
  'https://siilaspot.com.br/perfil/rafael-melo/22789085'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rafael-melo/22789085/lang/pt-br',
  'https://siilaspot.com.br/perfil/rafael-melo/22789085/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rafael-melo/22789085/lang/en',
  'https://siilaspot.com.br/profile/rafael-melo/22789085/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/alessandro-oliveira/25889462',
  'https://siilaspot.com.br/perfil/alessandro-oliveira/25889462'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/alessandro-oliveira/25889462/lang/pt-br',
  'https://siilaspot.com.br/perfil/alessandro-oliveira/25889462/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/alessandro-oliveira/25889462/lang/en',
  'https://siilaspot.com.br/profile/alessandro-oliveira/25889462/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rodrigo-morais/26195401',
  'https://siilaspot.com.br/perfil/rodrigo-morais/26195401'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rodrigo-morais/26195401/lang/pt-br',
  'https://siilaspot.com.br/perfil/rodrigo-morais/26195401/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/rodrigo-morais/26195401/lang/en',
  'https://siilaspot.com.br/profile/rodrigo-morais/26195401/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/marcelo-willian/26207915',
  'https://siilaspot.com.br/perfil/marcelo-willian/26207915'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/marcelo-willian/26207915/lang/pt-br',
  'https://siilaspot.com.br/perfil/marcelo-willian/26207915/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/marcelo-willian/26207915/lang/en',
  'https://siilaspot.com.br/profile/marcelo-willian/26207915/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/viviane-costa/26301791',
  'https://siilaspot.com.br/perfil/viviane-costa/26301791'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/viviane-costa/26301791/lang/pt-br',
  'https://siilaspot.com.br/perfil/viviane-costa/26301791/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/viviane-costa/26301791/lang/en',
  'https://siilaspot.com.br/profile/viviane-costa/26301791/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/o-sucesso-imobiliario/26345767',
  'https://siilaspot.com.br/empresa/o-sucesso-imobiliario/26345767/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/o-sucesso-imobiliario/26345767/lang/pt-br',
  'https://siilaspot.com.br/empresa/o-sucesso-imobiliario/26345767/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/o-sucesso-imobiliario/26345767/lang/en',
  'https://siilaspot.com.br/company/o-sucesso-imobiliario/26345767/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/caroline-g-l-fonoff/27472417',
  'https://siilaspot.com.br/perfil/caroline-g-l-fonoff/27472417'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/caroline-g-l-fonoff/27472417/lang/pt-br',
  'https://siilaspot.com.br/perfil/caroline-g-l-fonoff/27472417/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/caroline-g-l-fonoff/27472417/lang/en',
  'https://siilaspot.com.br/profile/caroline-g-l-fonoff/27472417/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/as-brasil/27548261',
  'https://siilaspot.com.br/empresa/as-brasil/27548261/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/as-brasil/27548261/lang/pt-br',
  'https://siilaspot.com.br/empresa/as-brasil/27548261/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/as-brasil/27548261/lang/en',
  'https://siilaspot.com.br/company/as-brasil/27548261/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/mariana-crisafulli/27871800',
  'https://siilaspot.com.br/perfil/mariana-crisafulli/27871800'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/mariana-crisafulli/27871800/lang/pt-br',
  'https://siilaspot.com.br/perfil/mariana-crisafulli/27871800/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/mariana-crisafulli/27871800/lang/en',
  'https://siilaspot.com.br/profile/mariana-crisafulli/27871800/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/carla-umino/28219492',
  'https://siilaspot.com.br/perfil/carla-umino/28219492'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/carla-umino/28219492/lang/pt-br',
  'https://siilaspot.com.br/perfil/carla-umino/28219492/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/carla-umino/28219492/lang/en',
  'https://siilaspot.com.br/profile/carla-umino/28219492/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/carla-sobreira-umino/28299900',
  'https://siilaspot.com.br/perfil/carla-sobreira-umino/28299900'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/carla-sobreira-umino/28299900/lang/pt-br',
  'https://siilaspot.com.br/perfil/carla-sobreira-umino/28299900/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/carla-sobreira-umino/28299900/lang/en',
  'https://siilaspot.com.br/profile/carla-sobreira-umino/28299900/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/spot-/29315130',
  'https://siilaspot.com.br/perfil/spot-/29315130'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/spot-/29315130/lang/pt-br',
  'https://siilaspot.com.br/perfil/spot-/29315130/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/spot-/29315130/lang/en',
  'https://siilaspot.com.br/profile/spot-/29315130/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/giorgio-nicoli/29348965',
  'https://siilaspot.com.br/perfil/giorgio-nicoli/29348965'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/giorgio-nicoli/29348965/lang/pt-br',
  'https://siilaspot.com.br/perfil/giorgio-nicoli/29348965/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/giorgio-nicoli/29348965/lang/en',
  'https://siilaspot.com.br/profile/giorgio-nicoli/29348965/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/ana-laura-lima/29570127',
  'https://siilaspot.com.br/perfil/ana-laura-lima/29570127'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/ana-laura-lima/29570127/lang/pt-br',
  'https://siilaspot.com.br/perfil/ana-laura-lima/29570127/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/ana-laura-lima/29570127/lang/en',
  'https://siilaspot.com.br/profile/ana-laura-lima/29570127/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/paulo-mauro-construtora/29873741',
  'https://siilaspot.com.br/empresa/paulo-mauro-construtora/29873741/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/paulo-mauro-construtora/29873741/lang/pt-br',
  'https://siilaspot.com.br/empresa/paulo-mauro-construtora/29873741/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/paulo-mauro-construtora/29873741/lang/en',
  'https://siilaspot.com.br/company/paulo-mauro-construtora/29873741/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/lucas-rojo/29873882',
  'https://siilaspot.com.br/perfil/lucas-rojo/29873882'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/lucas-rojo/29873882/lang/pt-br',
  'https://siilaspot.com.br/perfil/lucas-rojo/29873882/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/lucas-rojo/29873882/lang/en',
  'https://siilaspot.com.br/profile/lucas-rojo/29873882/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/oscar-dayan/29915621',
  'https://siilaspot.com.br/perfil/oscar-dayan/29915621'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/oscar-dayan/29915621/lang/pt-br',
  'https://siilaspot.com.br/perfil/oscar-dayan/29915621/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/oscar-dayan/29915621/lang/en',
  'https://siilaspot.com.br/profile/oscar-dayan/29915621/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/luiz-augusto-de-souza-queiroz-ferraz/30097634',
  'https://siilaspot.com.br/empresa/luiz-augusto-de-souza-queiroz-ferraz/30097634/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/luiz-augusto-de-souza-queiroz-ferraz/30097634/lang/pt-br',
  'https://siilaspot.com.br/empresa/luiz-augusto-de-souza-queiroz-ferraz/30097634/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/luiz-augusto-de-souza-queiroz-ferraz/30097634/lang/en',
  'https://siilaspot.com.br/company/luiz-augusto-de-souza-queiroz-ferraz/30097634/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806',
  'https://siilaspot.com.br/empresa/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806/lang/pt-br',
  'https://siilaspot.com.br/empresa/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806/lang/en',
  'https://siilaspot.com.br/company/irmandade-da-santa-casa-de-misericordia-de-sao-paulo/30686806/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/benjamin-tayar/31277749',
  'https://siilaspot.com.br/perfil/benjamin-tayar/31277749'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/benjamin-tayar/31277749/lang/pt-br',
  'https://siilaspot.com.br/perfil/benjamin-tayar/31277749/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/benjamin-tayar/31277749/lang/en',
  'https://siilaspot.com.br/profile/benjamin-tayar/31277749/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/ebpark-empresarial/31352198',
  'https://siilaspot.com.br/empresa/ebpark-empresarial/31352198/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/ebpark-empresarial/31352198/lang/pt-br',
  'https://siilaspot.com.br/empresa/ebpark-empresarial/31352198/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/ebpark-empresarial/31352198/lang/en',
  'https://siilaspot.com.br/company/ebpark-empresarial/31352198/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/renata-hirata/31352199',
  'https://siilaspot.com.br/perfil/renata-hirata/31352199'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/renata-hirata/31352199/lang/pt-br',
  'https://siilaspot.com.br/perfil/renata-hirata/31352199/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/renata-hirata/31352199/lang/en',
  'https://siilaspot.com.br/profile/renata-hirata/31352199/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/joao-maria-ribeiro-machado-junior/31404528',
  'https://siilaspot.com.br/perfil/joao-maria-ribeiro-machado-junior/31404528'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/joao-maria-ribeiro-machado-junior/31404528/lang/pt-br',
  'https://siilaspot.com.br/perfil/joao-maria-ribeiro-machado-junior/31404528/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/joao-maria-ribeiro-machado-junior/31404528/lang/en',
  'https://siilaspot.com.br/profile/joao-maria-ribeiro-machado-junior/31404528/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/david-nogueira/31530472',
  'https://siilaspot.com.br/perfil/david-nogueira/31530472'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/david-nogueira/31530472/lang/pt-br',
  'https://siilaspot.com.br/perfil/david-nogueira/31530472/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/david-nogueira/31530472/lang/en',
  'https://siilaspot.com.br/profile/david-nogueira/31530472/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/matheus-vilela/31530525',
  'https://siilaspot.com.br/perfil/matheus-vilela/31530525'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/matheus-vilela/31530525/lang/pt-br',
  'https://siilaspot.com.br/perfil/matheus-vilela/31530525/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/matheus-vilela/31530525/lang/en',
  'https://siilaspot.com.br/profile/matheus-vilela/31530525/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/condominio-sao-bento/31652762',
  'https://siilaspot.com.br/perfil/vicente-siciliano-jr/31652763'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/condominio-sao-bento/31652762/lang/pt-br',
  'https://siilaspot.com.br/perfil/vicente-siciliano-jr/31652763/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/condominio-sao-bento/31652762/lang/en',
  'https://siilaspot.com.br/profile/vicente-siciliano-jr/31652763/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/vicente-siciliano-jr/31652763',
  'https://siilaspot.com.br/perfil/vicente-siciliano-jr/31652763'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/vicente-siciliano-jr/31652763/lang/pt-br',
  'https://siilaspot.com.br/perfil/vicente-siciliano-jr/31652763/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/vicente-siciliano-jr/31652763/lang/en',
  'https://siilaspot.com.br/profile/vicente-siciliano-jr/31652763/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/cy-capital/32436959',
  'https://siilaspot.com.br/empresa/cy-capital/32436959/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/cy-capital/32436959/lang/pt-br',
  'https://siilaspot.com.br/empresa/cy-capital/32436959/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/cy-capital/32436959/lang/en',
  'https://siilaspot.com.br/company/cy-capital/32436959/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/marc-negocios-imobiliarios/32511413',
  'https://siilaspot.com.br/empresa/marc-negocios-imobiliarios/32511413/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/marc-negocios-imobiliarios/32511413/lang/pt-br',
  'https://siilaspot.com.br/empresa/marc-negocios-imobiliarios/32511413/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/company/detail/marc-negocios-imobiliarios/32511413/lang/en',
  'https://siilaspot.com.br/company/marc-negocios-imobiliarios/32511413/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/ricardo-iconatus/32511512',
  'https://siilaspot.com.br/perfil/ricardo-iconatus/32511512'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/ricardo-iconatus/32511512/lang/pt-br',
  'https://siilaspot.com.br/perfil/ricardo-iconatus/32511512/pt-br'
);
urlRedirects.set(
  'https://siilaspot.com.br/leasing-contact/detail/ricardo-iconatus/32511512/lang/en',
  'https://siilaspot.com.br/profile/ricardo-iconatus/32511512/en'
);
urlRedirects.set(
  'https://siilaspot.com.br/spot-property/detail/locacao/escritorio/berrini/bolsa-de-imoveis-do-estado-de-sao-paulo/itacare-imoveis-e-participacoes-s-c-ltda-/500158/lang/pt-br',
  'https://siilaspot.com.br/locacao/escritorio/sao-paulo/sao-paulo/bolsa-de-imoveis-do-estado-de-sao-paulo/500158/pt-br'
);
