import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ImageService } from '@app/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { SpotBuildingPhoto } from '@app/models/spot-buildings/spot-building-photo.model';
import { SpotBuildingDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { SpotImageDTO } from '@app/models/spot-buildings/spot-image.model';
import { PreviewImagesComponent } from '../preview-images/preview-images.component';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-show-images',
  templateUrl: './show-images.component.html',
  styleUrls: ['./show-images.component.scss']
})
export class ShowImagesComponent implements OnInit {
  @ViewChild('photoSlider', { static: false }) photoSlider: IonSlides;
  @Input('listingType') listingType: string;
  @Input('SpotPhotos') SpotPhotos: SpotImageDTO[];
  @Input('entireSale') entireSale: boolean;
  @Input('propertyName') propertyName: string;
  @Input('description') description: string = null;
  @Input('widthTemplate') widthTemplate: number;
  @Input('hasVacancy') hasVacancy: boolean;
  @Input('topImage') topImage?: string;
  @Input('offMarket') offMarket?: boolean;
  @Input('pageName') pageName?: string;
  @Input('type') type?: string;
  dialogRef: any;
  isMobile: boolean = false;

  constructor(
    private imgService: ImageService,
    private popOverCtrlHelper: PopoverCtrlHelper,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    /*  this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
       this.isMobile = result.matches;
     }); */

    if (window.screen.width < 1000) {
      this.isMobile = true;
    }
  }

  ngOnInit() {}

  getMediumImg(id: number) {
    return this.imgService.mediumThumbnail(id);
  }

  getCroppedOrOriginalImage(SpotPhotos: any) {
    if (SpotPhotos) {
      if (SpotPhotos.croppedImageId) {
        return SpotPhotos.croppedImageId;
      }
      return SpotPhotos.imageId;
    }
  }

  openCard(): void {
    this.dialogRef = this.dialog.open(PreviewImagesComponent, {
      height: '78%',
      width: '60%',
      data: this.SpotPhotos,
      panelClass: 'custom-modalbox'
    });
  }

  openGalleryImg(event: Event): void {
    this.popOverCtrlHelper.openGalleryImageComponent(
      event,
      this.SpotPhotos,
      this.propertyName,
      this.listingType,
      this.description,
      this.topImage
    );
  }

  cssImgMobile() {
    return {
      width: window.screen.width + 'px',
      height: (window.screen.width / 4) * 3 + 'px'
    };
  }

  cssComponent() {
    let img = Number(document.getElementById('imgTemplate').clientWidth) - 140;

    return {
      width: img + 'px !important',
      height: (((img / 3) * 2) / 4) * 3 + 'px'
    };
  }

  cssImg1() {
    let img = Number(document.getElementById('imgTemplate').clientWidth) - 140;

    return {
      width: (img / 3) * 2 + 'px !important',
      height: (((img / 3) * 2) / 4) * 3 + 'px'
    };
  }

  cssImg2() {
    let img = Number(document.getElementById('imgTemplate').clientWidth) - 140;

    return {
      width: img / 3 + 'px !important',
      height: (img / 3 / 4) * 3 - 14 + 'px'
    };
  }

  cssImg3() {
    let img = Number(document.getElementById('imgTemplate').clientWidth) - 140;

    return {
      width: img / 3 + 'px !important',
      height: (img / 3 / 4) * 3 - 14 + 'px'
    };
  }
}
