<div class="listing-filter-modal">
  <div [ngClass]="setModalContainerWidth()">
    <ion-row class="filter-row" *ngIf="isListingAdmin || isSpotAdmin">
      <ion-col class="filter-label" [ngClass]="setFieldWidth()" [size]="setLabelColSize()">
        <ion-label translate="global.menu.entities.company"></ion-label>
      </ion-col>

      <ion-col [size]="setFilterColSize()">
        <ionic-selectable
          #companyComponent
          [(ngModel)]="selectCompany"
          [items]="listingCompanies"
          itemValueField="id"
          itemTextField="name"
          [disabled]="!isSpotAdmin"
          [hasVirtualScroll]="true"
          placeholder="Company"
          searchPlaceholder="{{ 'global.menu.search' | translate }}"
          closeButtonText="{{ 'Cancel' | translate }}"
          [canSearch]="true"
          (onChange)="changeCompany($event)"
        >
        </ionic-selectable>
      </ion-col>
    </ion-row>

    <ion-row class="filter-row" *ngIf="isListingAdmin || isSpotAdmin">
      <ion-col class="filter-label" [ngClass]="setFieldWidth()" [size]="setLabelColSize()">
        <ion-label translate="global.menu.entities.agent"></ion-label>
      </ion-col>

      <ion-col [size]="setFilterColSize()">
        <ionic-selectable
          #agentComponent
          [(ngModel)]="selectAgent"
          [items]="agents"
          itemValueField="id"
          itemTextField="firstNameAndLastName"
          [hasVirtualScroll]="true"
          [disabled]="!isListingAdmin && !isSpotAdmin"
          placeholder="Agent"
          searchPlaceholder="{{ 'global.menu.search' | translate }}"
          closeButtonText="{{ 'Cancel' | translate }}"
          [canSearch]="true"
          [canClear]="true"
          clearButtonText="{{ 'Clear' | translate }}"
        >
        </ionic-selectable>
      </ion-col>
    </ion-row>

    <ion-row class="filter-row">
      <ion-col class="filter-label" [ngClass]="setFieldWidth()" [size]="setLabelColSize()">
        <ion-label translate="global.menu.entities.status"></ion-label>
      </ion-col>

      <ion-col [size]="setFilterColSize()">
        <ionic-selectable
          #statusComponent
          [isMultiple]="true"
          [(ngModel)]="selectStatus"
          [items]="statusHelperSvc.statuses"
          itemValueField="id"
          itemTextField="status"
          [hasVirtualScroll]="true"
          closeButtonText="{{ 'Cancel' | translate }}"
          placeholder="Status"
          (onChange)="changeStatus($event)"
        >
        </ionic-selectable>
      </ion-col>
    </ion-row>

    <ion-row class="filter-row">
      <ion-col class="filter-label" [ngClass]="setFieldWidth()" [size]="setLabelColSize()">
        <ion-label translate="global.menu.entities.class"></ion-label>
      </ion-col>

      <ion-col [size]="setFilterColSize()">
        <ionic-selectable
          #classComponent
          [isMultiple]="true"
          [(ngModel)]="selectClass"
          [items]="classHelperSvc.classes"
          itemValueField="id"
          itemTextField="name"
          [hasVirtualScroll]="true"
          placeholder="{{ 'global.menu.entities.placeholderClass' | translate }}"
          closeButtonText="{{ 'Cancel' | translate }}"
          (onChange)="changeClass($event)"
        >
        </ionic-selectable>
      </ion-col>
    </ion-row>
  </div>

  <ion-row>
    <ion-col class="control-btn" size="12">
      <div class="shift-right">
        <ion-button translate="Cancel" color="light" fill="clear" (click)="cancel()"></ion-button>
        <ion-button translate="Update" color="light" fill="clear" (click)="updateMyListingFilter()"></ion-button>
      </div>
    </ion-col>
  </ion-row>
</div>
