<div class="our-partners justify">
  <div
    *ngFor="let one of advertisements; index as index"
    class="card-block"
    style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-around;"
  >
    <ion-card
      class="card-alignment bg-white sp-card-container"
      [ngClass]="{ 'listing-detail-border': listingDetail }"
      (click)="openWebsite(one)"
    >
      <ion-card-header class="partners-card-header" style="padding: unset;">
        <div class="region-block-glass" localize [textValue]="one.serviceCategory"></div>
        <div class="partners-card-block" style="width: 100%; height: 300px;">
          <div class="image-height-card" *ngIf="one.photos">
            <ion-slides (ionSlideDidChange)="updatePhotoIndex(index)" #photoSlider id="photoSlider">
              <ion-slide
                class="slide-height"
                style="width: 100% !important"
                *ngFor="let photo of one.photos; let i = index"
              >
                <div class="img-block">
                  <img
                    *ngIf="i > 0"
                    alt="listing"
                    style="object-fit: initial !important;height: auto !important;"
                    class="img-listing-card"
                    default="assets/placeholder-image.png"
                    [lazyLoad]="getMediumImg(photo.image.id)"
                  />
                  <img
                    *ngIf="i == 0"
                    alt="listing"
                    style="object-fit: initial !important;height: auto !important;"
                    class="img-listing-card"
                    default="assets/placeholder-image.png"
                    [src]="getMediumImg(photo.image.id)"
                  />
                </div>
              </ion-slide>
            </ion-slides>
            <div class="image-dots" *ngIf="one.photos && one.photos.length > 1">
              <span
                class="dot"
                [ngClass]="{ active: i == currentPhotoIndex[index] }"
                *ngFor="let photo of one.photos; let i = index"
              ></span>
            </div>
          </div>
          <!-- <div style="overflow-y: auto;" [@openClose]="showCardBody[index] ? 'open' : 'closed'">
            <div class="ad-logo-block">
              <img
                alt="medium image"
                *ngIf="!showCardBody[index]"
                class="img-flexxe"
                [src]="getMediumImg(one.logoImageId)"
              />
            </div> -->

          <!-- <div class="hidden-content">
              <p style="margin: 20px; text-align: left;" [innerHTML]="one.services"></p>
              <div style="margin-left: 15px;">
                <div style="text-align: left" class="list-show-reveal border-bottom-unset">
                  <a class="add-padding">
                    <ion-icon
                      name="globe"
                      style="color: #043650; margin-bottom: -6px; font-size: 22px; margin-right: 10px;"
                    >
                    </ion-icon>
                    <a class="website-open" (click)="openWebsite(one.website)">{{ one.website }}</a>
                  </a>
                </div>

                <div style="text-align: left; margin-top: 10px;" class="list-show-reveal border-bottom-unset">
                  <a class="add-padding">
                    <ion-icon
                      style="color: #043650; margin-bottom: -6px; font-size: 22px; margin-right: 10px;"
                      class="whatsapp-icon"
                      name="call"
                    ></ion-icon>
                    <a href="tel:{{ one.phone }}" style="display: inline;">{{ one.phone }}</a>
                  </a>
                </div>

                <div
                  *ngIf="one.whatsapp"
                  style="text-align: left; margin-top: 10px;"
                  class="list-show-reveal border-bottom-unset"
                >
                  <a class="add-padding">
                    <ion-icon
                      style="  margin-bottom: -6px; font-size: 22px;
                                          margin-right: 10px;"
                      class="whatsapp-icon-color"
                      name="logo-whatsapp"
                    >
                    </ion-icon>
                    <a href="tel:{{ one.whatsappNumber }}" style="display: inline;">{{ one.whatsappNumber }}</a>
                  </a>
                </div>
              </div>
            </div> -->
          <!-- <ion-button class="padding-bottom-5" color="secondary" expand="block" fill="solid"
            *ngIf="!showCardBody[index]" (click)="showDetails(index)">Less Details
          </ion-button>
        </div> -->
        </div>
      </ion-card-header>
      <ion-card-content class="partners-content">
        <img alt="medium image" *ngIf="showCardBody[index]" class="img-flexxe" [src]="getMediumImg(one.logoImageId)" />
        <!-- <ion-button
          class="padding-bottom-5"
          color="secondary"
          expand="block"
          fill="solid"
          *ngIf="showCardBody[index]"
          (click)="showDetails(index)"
        >
          Show Details
        </ion-button> -->
      </ion-card-content>

      <button
        (click)="getPreviousPhoto($event, one, index)"
        class="stdBackBtn"
        *ngIf="hasPointer && one.photos && one.photos.length > 1"
      >
        <ion-icon name="arrow-back"></ion-icon>
      </button>

      <button
        (click)="getNextPhoto($event, one, index)"
        class="stdNextBtn"
        *ngIf="hasPointer && one.photos && one.photos.length > 1"
      >
        <ion-icon name="arrow-forward"> </ion-icon>
      </button>
    </ion-card>
  </div>
</div>
