import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18nService, PlatformHelperService } from '@app/core';
import { ToastController } from '@ionic/angular';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { MessageModalService } from '../services/message-modal.service';
import { faTrash, faEllipsisV, faEdit, faSearch, faFilter, faArrowCircleDown, faFileDownload, faShare } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { DownloadFileService } from '../services/downloadFile.service';
import { ShareEmailComponent } from '../share-email/share-email.component';
import { environment } from '@env/environment';
import { EditTourBookComponent } from '../edit-tour-book/edit-tour-book.component';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
export class TourBooksComponent {
    constructor(favoriteServices, i18nService, dialog, toastCtrl, messageFormDialog, breakpointObserver, downloadFileService, platformHelpService, routingHelperSrv) {
        this.favoriteServices = favoriteServices;
        this.i18nService = i18nService;
        this.dialog = dialog;
        this.toastCtrl = toastCtrl;
        this.messageFormDialog = messageFormDialog;
        this.breakpointObserver = breakpointObserver;
        this.downloadFileService = downloadFileService;
        this.platformHelpService = platformHelpService;
        this.routingHelperSrv = routingHelperSrv;
        this.changeStepOutput = new EventEmitter();
        this.pageSize = 10;
        this.page = 0;
        this.collection = 0;
        this.seeSearch = false;
        this.isMobile = false;
        this.filterCount = 0;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    ngOnInit() {
        this.trash = faTrash;
        this.ellipsisV = faEllipsisV;
        this.edit = faEdit;
        this.search = faSearch;
        this.filter = faFilter;
        this.arrowDown = faArrowCircleDown;
        this.fileDownload = faFileDownload;
        this.share = faShare;
        this.getTourBooks(0);
    }
    getTourBooks(page) {
        this.page = page;
        const lang = this.i18nService.getCurrentLanguage();
        const bodySpotSet = {
            sortBy: this.param,
            direction: this.direction,
            titleFilter: this.filterTourBook
        };
        this.favoriteServices.getTourBook(page, lang, bodySpotSet).then((value) => {
            this.tourBookList = value;
            this.collection = this.tourBookList.totalElements;
            this.pageSize = this.tourBookList.size;
        });
    }
    changePage(page) {
        this.getTourBooks(page - 1);
    }
    searchProperty() {
        this.seeSearch = !this.seeSearch;
    }
    searchFilter() {
        if (this.filterCount == 0) {
            this.filterCount = 1;
            setTimeout(() => {
                this.getTourBooks(0);
                this.filterCount = 0;
            }, 2000);
        }
    }
    nameAscending() {
        this.param = 'name';
        this.direction = 'ASC';
        this.getTourBooks(0);
    }
    nameDescending() {
        this.param = 'name';
        this.direction = 'DESC';
        this.getTourBooks(0);
    }
    oldestFirst() {
        this.param = 'date';
        this.direction = 'ASC';
        this.getTourBooks(0);
    }
    newestFirst() {
        this.param = 'date';
        this.direction = 'DESC';
        this.getTourBooks(0);
    }
    presentToast(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastCtrl.create({
                message: message,
                duration: 3500,
                animated: true,
                keyboardClose: true,
                cssClass: 'toast-alert-detail'
            });
            toast.onDidDismiss().then(() => { });
            return yield toast.present();
        });
    }
    propertiesText(message) {
        this.messageFormDialog.openDialogMessage(message);
    }
    shareTourBook(item) {
        let dialogRef = this.dialog.open(ShareEmailComponent, {
            width: this.isMobile ? '100%' : '600px',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                id: item.id,
                url: `${environment.spotServerUrl}/api/tourbookdashboard/download/${item.id}/${item.fileName}`
            },
            autoFocus: true,
            restoreFocus: true
        });
        dialogRef.afterClosed().subscribe(val => {
            if (val) {
                this.presentToast(this.i18nService.get('favorites.sendEmailSuccess'));
            }
        });
    }
    downloadTourBook(item) {
        this.downloadFileService.downloadPDF(`/api/tourbookdashboard/download/${item.id}/`, item.fileName, this.platformHelpService.isIosSafari());
    }
    deleteTourBook(id) {
        let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: {
                message: this.i18nService.get('favorites.tourBooks.deleteTour'),
                id: id,
                apiName: '/api/tourbookdashboard/'
            },
            autoFocus: true,
            restoreFocus: true
        });
        dialogRef.afterClosed().subscribe(val => {
            if (val) {
                this.presentToast(this.i18nService.get('favorites.deleteTourMessage'));
                this.getTourBooks(0);
            }
        });
    }
    goToSpotSet() {
        this.routingHelperSrv.navigateToDefaultListingPropertySearchURL();
    }
    editTourBook(item) {
        let dialogRef = this.dialog.open(EditTourBookComponent, {
            width: this.isMobile ? '100%' : '600px',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                title: true,
                id: item.id,
                name: item.reportTitle
            }
        });
        dialogRef.afterClosed().subscribe(val => {
            if (val) {
                this.getTourBooks(0);
                this.presentToast(val);
            }
        });
    }
}
