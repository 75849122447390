<ion-grid class="height-small">
  <ion-list>
    <ion-radio-group
      [(ngModel)]="selectedPropertyType"
      class="top-search-btn"
      style="font-size: 1.3em;"
      (ionChange)="propertyTypeChanged($event)"
    >
      <ion-item>
        <ion-label>{{ 'global.all' | translate }}</ion-label>
        <ion-radio slot="end" value="null" checked></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{ 'global.list-your-property.spotsForLease' | translate }}</ion-label>
        <ion-radio slot="end" value="L"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{ 'global.list-your-property.spotsForCoworking' | translate }}</ion-label>
        <ion-radio slot="end" value="C"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{ 'global.list-your-property.spotsForSale' | translate }}</ion-label>
        <ion-radio slot="end" value="S"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-grid>

<ion-row class="fixed-ion-btnrow">
  <ion-col size="12">
    <ion-button class="float-right" color="light" fill="clear" (click)="update()" translate="Update"></ion-button>
    <ion-button class="float-right" color="light" fill="clear" (click)="cancel()" translate="Cancel"></ion-button>
  </ion-col>
</ion-row>
