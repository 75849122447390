<ion-row class="row-border-bottom">
  <span
    class="full-prem"
    *ngIf="
      listing.premiumAgents &&
      (listing.listingType.toLowerCase() === 'basic' || listing.listingType.toLowerCase() === 'promoted')
    "
  >
    <ion-row *ngIf="listing?.premiumAgents.length === 1" class=" row-display margin-bottom-30 unset-bottom-border">
      <ion-card class="broker-profile-0">
        <ion-row class="full-prem">
          <p class="market-spec">
            <span translate="global.listing-detail.premiumAgent"></span>
          </p>
          <ion-row class="full-prem">
            <ion-col
              class="col-styling-prem padding-left-unset"
              (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
              size="4"
            >
              <img
                class="img-broker margin-top-10"
                *ngIf="(listing?.premiumAgents)[0].profilePhotoImageId"
                [src]="getMediumImg((listing?.premiumAgents)[0].profilePhotoImageId)"
                alt="avatar"
              />
              <img
                class="img-broker"
                *ngIf="!(listing?.premiumAgents)[0].profilePhotoImageId"
                src="assets/images/default-profile-photo.png"
                alt="avatar"
              />
              <p *ngIf="(listing?.premiumAgents)[0]" class="contact-name center point">
                {{ (listing?.premiumAgents)[0].firstNameAndLastName }}
              </p>
              <p class="contact-name center point">
                {{ (listing?.premiumAgents)[0].company.name }}
              </p>

              <p class="contact-name center point" [translate]="'global.contactDetails'"></p>
            </ion-col>

            <ion-col class="broker-info spec-row" size="8">
              <div
                *ngIf="(listing?.premiumAgents)[0].company.companyPhotoImageId"
                (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
                class="company-dim margin-top-10"
              >
                <img
                  height="57"
                  width="136"
                  [src]="getMediumImg((listing?.premiumAgents)[0].company.companyPhotoImageId)"
                  alt="avatar"
                />
              </div>

              <ion-row class="bio-padding" *ngIf="!loading">
                <p
                  id="#bio"
                  class="init-overflow left contact-name"
                  (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
                  [ngClass]="{ 'unset-overflow': unsetBioOverflow }"
                  localize
                  [textValue]="(listing?.premiumAgents)[0].biography"
                ></p>
                <p *ngIf="checkOverflow()" class="block-flex block-flex-attr contact-name" (click)="unsetOverflow()">
                  <span class="margin-right-5 padding-top-2">{{ 'global.listing-detail.seeMore' | translate }}</span>
                  <ion-icon class="icon-size" name="arrow-dropdown"></ion-icon>
                </p>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-row>
      </ion-card>
    </ion-row>

    <ion-row *ngIf="listing?.premiumAgents.length === 2" class=" row-display margin-bottom-30 unset-bottom-border">
      <ion-card class="broker-profile-2">
        <ion-row class="multiple-specs">
          <p class="market-banner">
            <span translate="global.listing-detail.premiumAgents"></span>
          </p>
          <ion-col class="col-styling-prem padding-left-unset" size="4">
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
              *ngIf="(listing?.premiumAgents)[0].profilePhotoImageId"
              [src]="getMediumImg((listing?.premiumAgents)[0].profilePhotoImageId)"
              alt="avatar"
            />
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
              *ngIf="!(listing?.premiumAgents)[0].profilePhotoImageId"
              src="assets/images/default-profile-photo.png"
              alt="avatar"
            />
            <div class="height-45">
              <p
                (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
                *ngIf="(listing?.premiumAgents)[0]"
                class="contact-name center point"
              >
                {{ (listing?.premiumAgents)[0].firstNameAndLastName }}
              </p>
            </div>

            <div class="height-45">
              <p (click)="navigateToBroker((listing?.premiumAgents)[0].id)" class="contact-name center point company-p">
                {{ (listing?.premiumAgents)[0].company.name }}
              </p>
            </div>

            <div class="height-45">
              <p
                class="contact-name center point"
                [translate]="'global.contactDetails'"
                (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
              ></p>
            </div>
          </ion-col>

          <ion-col class="col-styling-prem padding-left-unset" size="4">
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[1].id)"
              *ngIf="(listing?.premiumAgents)[1].profilePhotoImageId"
              [src]="getMediumImg((listing?.premiumAgents)[1].profilePhotoImageId)"
              alt="avatar"
            />
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[1].id)"
              *ngIf="!(listing?.premiumAgents)[1].profilePhotoImageId"
              src="assets/images/default-profile-photo.png"
              alt="avatar"
            />
            <div class="height-45">
              <p
                (click)="navigateToBroker((listing?.premiumAgents)[1].id)"
                *ngIf="(listing?.premiumAgents)[1]"
                class="contact-name center point"
              >
                {{ (listing?.premiumAgents)[1].firstNameAndLastName }}
              </p>
            </div>

            <div class="height-45">
              <p (click)="navigateToBroker((listing?.premiumAgents)[1].id)" class="contact-name center point company-p">
                {{ (listing?.premiumAgents)[1].company.name }}
              </p>
            </div>

            <div class="height-45">
              <p
                class="contact-name center point"
                [translate]="'global.contactDetails'"
                (click)="navigateToBroker((listing?.premiumAgents)[1].id)"
              ></p>
            </div>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-row>

    <ion-row *ngIf="listing?.premiumAgents.length === 3" class=" row-display margin-bottom-30 unset-bottom-border">
      <ion-card class="broker-profile-2">
        <ion-row class="multiple-specs">
          <p class="market-banner">
            <span translate="global.listing-detail.premiumAgents"></span>
          </p>
          <ion-col class="col-styling-prem padding-left-unset" size="4">
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
              *ngIf="(listing?.premiumAgents)[0].profilePhotoImageId"
              [src]="getMediumImg((listing?.premiumAgents)[0].profilePhotoImageId)"
              alt="avatar"
            />
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
              *ngIf="!(listing?.premiumAgents)[0].profilePhotoImageId"
              src="assets/images/default-profile-photo.png"
              alt="avatar"
            />

            <div class="height-45">
              <p
                (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
                *ngIf="(listing?.premiumAgents)[0]"
                class="contact-name center point"
              >
                {{ (listing?.premiumAgents)[0].firstNameAndLastName }}
              </p>
            </div>

            <div class="height-45">
              <p (click)="navigateToBroker((listing?.premiumAgents)[0].id)" class="contact-name center point company-p">
                {{ (listing?.premiumAgents)[0].company.name }}
              </p>
            </div>

            <div class="height-45">
              <p
                (click)="navigateToBroker((listing?.premiumAgents)[0].id)"
                class="contact-name center point"
                [translate]="'global.contactDetails'"
              ></p>
            </div>
          </ion-col>

          <ion-col class="col-styling-prem padding-left-unset" size="4">
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[1].id)"
              *ngIf="(listing?.premiumAgents)[1].profilePhotoImageId"
              [src]="getMediumImg((listing?.premiumAgents)[1].profilePhotoImageId)"
              alt="avatar"
            />
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[1].id)"
              *ngIf="!(listing?.premiumAgents)[1].profilePhotoImageId"
              src="assets/images/default-profile-photo.png"
              alt="avatar"
            />

            <div class="height-45" (click)="navigateToBroker((listing?.premiumAgents)[1].id)">
              <p *ngIf="(listing?.premiumAgents)[1]" class="contact-name center point">
                {{ (listing?.premiumAgents)[1].firstNameAndLastName }}
              </p>
            </div>

            <div class="height-45">
              <p (click)="navigateToBroker((listing?.premiumAgents)[1].id)" class="contact-name center point company-p">
                {{ (listing?.premiumAgents)[1].company.name }}
              </p>
            </div>

            <div class="height-45">
              <p
                class="contact-name center point"
                [translate]="'global.contactDetails'"
                (click)="navigateToBroker((listing?.premiumAgents)[1].id)"
              ></p>
            </div>
          </ion-col>

          <ion-col class="col-styling-prem padding-left-unset" size="4">
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[2].id)"
              *ngIf="(listing?.premiumAgents)[2].profilePhotoImageId"
              [src]="getMediumImg((listing?.premiumAgents)[2].profilePhotoImageId)"
              alt="avatar"
            />
            <img
              class="img-broker"
              (click)="navigateToBroker((listing?.premiumAgents)[2].id)"
              *ngIf="!(listing?.premiumAgents)[2].profilePhotoImageId"
              src="assets/images/default-profile-photo.png"
              alt="avatar"
            />
            <div class="height-45" (click)="navigateToBroker((listing?.premiumAgents)[2].id)">
              <p *ngIf="(listing?.premiumAgents)[2]" class="contact-name center point">
                {{ (listing?.premiumAgents)[2].firstNameAndLastName }}
              </p>
            </div>

            <div class="height-45">
              <p (click)="navigateToBroker((listing?.premiumAgents)[2].id)" class="contact-name center point company-p">
                {{ (listing?.premiumAgents)[2].company.name }}
              </p>
            </div>

            <div class="height-45">
              <p
                class="contact-name center point"
                [translate]="'global.contactDetails'"
                (click)="navigateToBroker((listing?.premiumAgents)[2].id)"
              ></p>
            </div>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-row>
  </span>
</ion-row>
