<ion-content *ngIf="!isMobile" #contentBlock class="main__container">
  <div class="header">
    <img class="header__img" [src]="brokerHeaderURL" />
  </div>

  <div class="main__info" *ngIf="brokerDTO">
    <div class="broker__info__logo" *ngIf="brokerDTO.photoId">
      <img
        class="broker__info__logo__dimensions"
        [src]="brokerDTO && brokerDTO.photoId ? getMediumImg(brokerDTO.photoId) : defaultImage"
      />
    </div>

    <div class="agent__info__section">
      <div class="agent_name">{{ brokerDTO.firstNameAndLastName }}</div>
      <div id="userEmail" class="agent_link" *ngIf="!showEmail">
        <img src="../../../assets/icons/email.svg" />
        <a class="pointer-cursor" (click)="openEmail($event)">{{ brokerDTO.userEmail }}</a>
      </div>
      <div class="agent_link" *ngIf="showEmail">
        <img src="../../../assets/icons/email.svg" />
        <a class="pointer-cursor" (click)="openEmail($event)">{{ 'global.listing-detail.showEmail' | translate }}</a>
      </div>

      <div class="agent_jobcompany">
        <span *ngIf="brokerDTO.jobTitle" class="jobtitle">{{ brokerDTO.jobTitle }}, </span>
        <span class="companyName" (click)="navigateToCompany()"> {{ brokerDTO.companyName }}</span>
      </div>
      <div class="agent_link" *ngIf="brokerDTO.phoneNumber">
        <img src="../../../assets/icons/whatsapp.png" />
        <a
          class="pointer-cursor"
          href="tel:{{ brokerDTO.phoneNumber }}"
          *ngIf="!showWhatsapp"
          (click)="openWhatsapp($event)"
          >{{ brokerDTO.phoneNumber }}</a
        >
        <a class="pointer-cursor" *ngIf="showWhatsapp" (click)="openWhatsapp($event)">{{
          'global.listing-detail.showWhataspp' | translate
        }}</a>
      </div>
      <div *ngIf="!brokerDTO.phoneNumber"></div>

      <div class="agent_state" *ngIf="brokerDTO.stateName">{{ brokerDTO.stateName }}, Brasil</div>
      <div class="agent_state" *ngIf="!brokerDTO.stateName">Brasil</div>
      <div class="agent_link" *ngIf="brokerDTO.linkedinUrl">
        <img src="../../../assets/icons/in.png" />
        <a href="{{ brokerDTO.linkedinUrl }} ">Visit Profile</a>
      </div>
    </div>

    <div class="broker__actions">
      <button class="action-btn btn-white shared-btn" (click)="shareCompany($event)">
        <fa-icon class="icon" [icon]="sharedIcon" size="md"></fa-icon>
        <span>{{ 'brokerProfile.shareProfile' | translate }}</span>
      </button>

      <ion-button class="action-btn send-btn" (click)="sendMessage($event)">
        <img class="icon" src="../../../assets/icons/message.png" />
        <span>{{ 'brokerProfile.sendMsg' | translate }}</span>
      </ion-button>
    </div>
  </div>

  <div class="bio__section" *ngIf="brokerBIO">
    <div class="bio__section__title">Professional Bio</div>
    <div class="bio__section__content" [innerHTML]="brokerBIO"></div>
  </div>

  <div class="row-separator">
    <div class="line"></div>
  </div>

  <div class="margin__page">
    <app-spot-card-tabs
      title="{{ 'PortFolio' | translate }}"
      [spotBuildingSearchRequest]="spotBuildingSearchRequest"
      portfolioFor="BROKER_DETAIL"
    >
    </app-spot-card-tabs>
  </div>

  <div class="scrollToTop">
    <app-scroll-to-top
      [showComponent]="displayScrollToTop"
      (scroll)="contentBlock.scrollToTop()"
      [target]="contentBlock"
      scroller
    >
    </app-scroll-to-top>
  </div>
  <app-footer inViewport (inViewportAction)="isFooterVisible($event)"></app-footer>
</ion-content>

<!--Mobile -->
<ion-content
  *ngIf="isMobile"
  #contentBlock
  class="main__container__mobile"
  [ngStyle]="{ height: !checkCookiesUsageAcceptance() ? '66vh' : '' }"
>
  <div class="header__mobile">
    <img class="header__mobile__img" [src]="brokerHeaderURL" />
  </div>

  <div class="">
    <div class="broker__info__logo__mobile" *ngIf="brokerDTO.photoId">
      <img
        class="broker__info__logo__mobile__dimensions"
        [src]="brokerDTO && brokerDTO.photoId ? getMediumImg(brokerDTO.photoId) : defaultImage"
      />
    </div>
  </div>

  <div class="main__container__mobile__section">
    <div class="agent_name">{{ brokerDTO.firstNameAndLastName }}</div>

    <div class="agent_jobcompany">
      <span *ngIf="brokerDTO.jobTitle" class="jobtitle">{{ brokerDTO.jobTitle }}, </span>
      <span class="companyName" (click)="navigateToCompany()"> {{ brokerDTO.companyName }}</span>
    </div>

    <div class="agent_state" *ngIf="brokerDTO.stateName">{{ brokerDTO.stateName }}, Brasil</div>
    <div class="agent_state" *ngIf="!brokerDTO.stateName">Brasil</div>

    <div id="userEmail" class="agent_link" *ngIf="!showEmail">
      <img src="../../../assets/icons/email.svg" />
      <a class="pointer-cursor" (click)="openEmail($event)">{{ brokerDTO.userEmail }}</a>
    </div>
    <div class="agent_link" *ngIf="showEmail">
      <img src="../../../assets/icons/email.svg" />
      <a class="pointer-cursor" (click)="openEmail($event)">{{ 'global.listing-detail.showEmail' | translate }}</a>
    </div>

    <div class="agent_link" *ngIf="brokerDTO.phoneNumber">
      <img src="../../../assets/icons/whatsapp.png" />
      <a
        class="pointer-cursor"
        href="tel:{{ brokerDTO.phoneNumber }}"
        *ngIf="!showWhatsapp"
        (click)="openWhatsapp($event)"
        >{{ brokerDTO.phoneNumber }}</a
      >
      <a class="pointer-cursor" *ngIf="showWhatsapp" (click)="openWhatsapp($event)">{{
        'global.listing-detail.showWhataspp' | translate
      }}</a>
    </div>

    <div class="agent_link" *ngIf="brokerDTO.linkedinUrl">
      <img src="../../../assets/icons/in.png" />
      <a href="{{ brokerDTO.linkedinUrl }} ">Visit Profile</a>
    </div>

    <div class="broker__actions__mobile">
      <button class="action-btn btn-white shared-btn w-100" (click)="shareCompany($event)">
        <fa-icon class="icon" [icon]="sharedIcon" size="md"></fa-icon>
        <span>{{ 'brokerProfile.shareProfile' | translate }}</span>
      </button>

      <button class="action-btn send-btn w-100" (click)="sendMessage($event)">
        <img class="icon" src="../../../assets/icons/message.png" />
        <span>{{ 'brokerProfile.sendMsg' | translate }}</span>
      </button>
    </div>

    <div class="row-separator-mobile"></div>

    <div class="bio__section__mobile" *ngIf="brokerBIO">
      <div class="bio__section__mobile__title">Professional Bio</div>
      <div class="bio__section__mobile__content" [innerHTML]="brokerBIO"></div>
    </div>

    <div class="row-separator-mobile"></div>

    <div class="">
      <app-spot-card-tabs
        title="{{ 'PortFolio' | translate }}"
        [spotBuildingSearchRequest]="spotBuildingSearchRequest"
        portfolioFor="BROKER_DETAIL"
      >
      </app-spot-card-tabs>
    </div>
  </div>

  <div class="scrollToTop">
    <app-scroll-to-top
      [showComponent]="displayScrollToTop"
      (scroll)="contentBlock.scrollToTop()"
      [target]="contentBlock"
      scroller
    >
    </app-scroll-to-top>
  </div>

  <div class="send__msg__bottom" inViewport (inViewportAction)="isFooterVisible($event)">
    <button class="action-btn send-btn w-100" (click)="sendMessage($event)">
      <img class="icon" src="../../../assets/icons/message.png" />
      <span>{{ 'brokerProfile.sendMsg' | translate }}</span>
    </button>
  </div>
</ion-content>
