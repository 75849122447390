/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../breadcrumb/breadcrumb.component.ngfactory";
import * as i3 from "ng-in-viewport";
import * as i4 from "../breadcrumb/breadcrumb.component";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "../../core/helpers/platform-helper.service";
import * as i8 from "../../core/listings/listing.service";
import * as i9 from "../../search-navigation/services/listing-search.service";
import * as i10 from "../../core/i18n.service";
import * as i11 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i12 from "@ionic/angular";
import * as i13 from "./navigation-bar.component";
var styles_NavigationBarComponent = [i0.styles];
var RenderType_NavigationBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationBarComponent, data: {} });
export { RenderType_NavigationBarComponent as RenderType_NavigationBarComponent };
export function View_NavigationBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-breadcrumb", [["inViewport", ""]], null, null, null, i2.View_BreadcrumbComponent_0, i2.RenderType_BreadcrumbComponent)), i1.ɵdid(1, 4341760, null, 0, i3.InViewportDirective, [i1.PLATFORM_ID, i1.ElementRef, i3.InViewportService], { options: [0, "options"] }, null), i1.ɵpad(2, 1), i1.ɵpod(3, { thredhold: 0 }), i1.ɵdid(4, 114688, null, 0, i4.BreadcrumbComponent, [i5.Location, i6.Router, i7.PlatformHelperService, i8.ListingService, i9.ListingSearchService, i10.I18nService], { options: [0, "options"], standardBackButton: [1, "standardBackButton"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 10, "ion-row", [["style", "margin-top: 15px;"]], null, null, null, i11.View_IonRow_0, i11.RenderType_IonRow)), i1.ɵdid(7, 49152, null, 0, i12.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(9, 0, null, 0, 6, "div", [["class", "logo-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "h4", [["class", "create-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["\n      ", "\n      "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["class", "spot-img"], ["src", "assets/SPOT-Full-Logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _ck(_v, 2, 0, 0)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.breadcrumbOptions; var currVal_2 = _co.standardBackButton; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.titleTranslated; _ck(_v, 12, 0, currVal_3); }); }
export function View_NavigationBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-bar", [], null, null, null, View_NavigationBarComponent_0, RenderType_NavigationBarComponent)), i1.ɵdid(1, 114688, null, 0, i13.NavigationBarComponent, [i10.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationBarComponentNgFactory = i1.ɵccf("app-navigation-bar", i13.NavigationBarComponent, View_NavigationBarComponent_Host_0, { title: "title", standardBackButton: "standardBackButton" }, {}, []);
export { NavigationBarComponentNgFactory as NavigationBarComponentNgFactory };
