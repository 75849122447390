import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListPropertyService } from '@app/core/listings/list-property.service';
import * as CartActions from '@app/store/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { I18nService } from '@app/core';
import { BreadcrumbOptions } from '@app/shared';

@Component({
  selector: 'app-listing-detail',
  templateUrl: './list-property.component.html',
  styleUrls: ['./list-property.component.scss']
})
export class ListPropertyComponent implements OnInit {
  isMobile: boolean = false;
  listingOptions: Array<any>;
  cart: any;
  faMapMarkerAlt: any;

  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    private listPropertyService: ListPropertyService,
    private router: Router,
    private store: Store<any>,
    private location: Location,
    private i18nService: I18nService
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.listPropertyService
      .getListOptions()
      .toPromise()
      .then(result => {
        this.getListPropertyOptions();
        this.faMapMarkerAlt = faMapMarkerAlt;
      });
    this.store.select(CartActions.GetItem).subscribe((store: any) => {
      this.cart = store.cart.items;
    });
  }

  getListPropertyOptions() {
    this.listPropertyService
      .getListOptions()
      .toPromise()
      .then(result => {
        this.listingOptions = <Array<any>>result;
        this.listingOptions.forEach(
          purchaseOption => (purchaseOption.description = this.i18nService.getTranslation(purchaseOption.description))
        );
      });
  }

  navigateToCart() {
    this.router.navigateByUrl('/cart');
  }

  navigateToHome() {
    this.location.replaceState('/');
    this.router.navigateByUrl('/');
  }

  navigateToUpgrade(selection: any) {
    this.store.dispatch(CartActions.AddSelection({ payload: { item: selection } }));
    this.router.navigateByUrl('/list-property/add');
  }
}
