/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./my-listing-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./my-listing-menu.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/router";
import * as i9 from "../../core/listings/listing.service";
import * as i10 from "../../core/user-activity/user-activity.service";
var styles_MyListingMenuComponent = [i0.styles];
var RenderType_MyListingMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyListingMenuComponent, data: {} });
export { RenderType_MyListingMenuComponent as RenderType_MyListingMenuComponent };
function View_MyListingMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-row", [["class", "pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToPurchase() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, 0, 8, "div", [["class", "btn-styling"], ["color", "secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ion-label", [["class", "btn-heading"], ["translate", "global.my-list-menu.buyYourSpot"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(6, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(7, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "btn-descript"], ["translate", "global.my-list-menu.listSpotText"]], null, null, null, null, null)), i1.ɵdid(10, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n"]))], function (_ck, _v) { var currVal_0 = "global.my-list-menu.buyYourSpot"; _ck(_v, 7, 0, currVal_0); var currVal_1 = "global.my-list-menu.listSpotText"; _ck(_v, 10, 0, currVal_1); }, null); }
function View_MyListingMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-row", [["class", "pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToRegister() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, 0, 8, "div", [["class", "btn-styling"], ["color", "secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ion-label", [["class", "btn-heading"], ["translate", "global.my-list-menu.registerSpot"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(6, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(7, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "btn-descript"], ["translate", "global.my-list-menu.registerSpotText"]], null, null, null, null, null)), i1.ɵdid(10, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n"]))], function (_ck, _v) { var currVal_0 = "global.my-list-menu.registerSpot"; _ck(_v, 7, 0, currVal_0); var currVal_1 = "global.my-list-menu.registerSpotText"; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_MyListingMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(3, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, 0, 9, "ion-col", [["size", "12"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(6, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(8, 0, null, 0, 5, "ion-button", [["class", "close-btn"], ["color", "primary"], ["fill", "outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissAsDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(9, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], fill: [1, "fill"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(12, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MyListingMenuComponent_1)), i1.ɵdid(19, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MyListingMenuComponent_2)), i1.ɵdid(22, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(24, 0, null, null, 12, "ion-row", [["class", "pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToListings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(25, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(27, 0, null, 0, 8, "div", [["class", "btn-styling"], ["color", "secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(29, 0, null, null, 2, "ion-label", [["class", "btn-heading"], ["translate", "global.my-list-menu.reviewYourSpots"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(30, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(31, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "p", [["class", "btn-descript"], ["translate", "global.my-list-menu.reviewSpotText"]], null, null, null, null, null)), i1.ɵdid(34, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "12"; _ck(_v, 6, 0, currVal_0); var currVal_1 = "primary"; var currVal_2 = "outline"; _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_3 = "close"; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.spotAdmin; _ck(_v, 19, 0, currVal_4); var currVal_5 = _co.spotAdmin; _ck(_v, 22, 0, currVal_5); var currVal_6 = "global.my-list-menu.reviewYourSpots"; _ck(_v, 31, 0, currVal_6); var currVal_7 = "global.my-list-menu.reviewSpotText"; _ck(_v, 34, 0, currVal_7); }, null); }
export function View_MyListingMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-my-listing-menu", [], null, null, null, View_MyListingMenuComponent_0, RenderType_MyListingMenuComponent)), i1.ɵdid(1, 114688, null, 0, i6.MyListingMenuComponent, [i7.MatDialogRef, i7.MatDialog, i8.Router, i9.ListingService, i10.UserActivityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MyListingMenuComponentNgFactory = i1.ɵccf("app-my-listing-menu", i6.MyListingMenuComponent, View_MyListingMenuComponent_Host_0, {}, {}, []);
export { MyListingMenuComponentNgFactory as MyListingMenuComponentNgFactory };
