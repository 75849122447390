<section class="search-container d-flex justify-content-center align-items-center">
  <app-home-search-control class="search-control-container"></app-home-search-control>
</section>

<section class="carrousel-container mt-5">
  <h3 class="text-center font-weight-bold mt-5">{{ 'homepage.listingSlider.title' | translate }}</h3>
  <h5 class="text-center mt-1 text-muted">{{ 'homepage.listingSlider.subtitle' | translate }}</h5>
  <app-spot-card-slider [querySize]="3"></app-spot-card-slider>
</section>

<section class="services-container mt-5 w-100">
  <industry-news></industry-news>
</section>
<section class="services-container mt-5 w-100">
  <app-services [isMobile]="isMobile"></app-services>
</section>

<app-footer></app-footer>
