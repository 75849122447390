import { Component, OnInit, HostListener } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { RangeSearchFiltersMeta, MinMaxSearchFiltersMeta } from '../../../models/office-search-filters.model';
import { ListPropertyService } from '@app/core/listings/list-property.service';
import { I18nService } from '@app/core';
import { ListingOfferType } from '../../../models/transaction-type.model';

@Component({
  selector: 'property-type-page',
  templateUrl: './property-type-popover.component.html',
  styleUrls: ['./property-type-popover.component.scss']
})
export class PropertyTypePopoverComponent implements OnInit {
  propertyType: any;
  expandFilters: any;
  rangeFilters: any;
  btnFilter: boolean = false;
  minMaxvalues: any;
  checkboxValues: any;
  rangeModelValues: any;
  staticMinMax: any;
  searchDto: any;
  loading: boolean = true;
  propertySubTypes: any = [];
  buildingType: any;
  selectedPropertyType: any;
  popDataCalled: boolean = false;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private listingService: ListPropertyService,
    private i18nService: I18nService
  ) {}

  ngOnInit(): void {
    this.getPopData();
    //this.setMinOptions(this.rangeFilters, this.searchDto);
  }

  propertyTypeChanged(event: CustomEvent) {
    if (this.popDataCalled) {
      this.popDataCalled = false;
      return;
    }
    this.buildingType = event.detail.value;
    this.propertySubTypes = [];
    if (this.buildingType == '1001' || this.buildingType == '2001') {
      this.listingService
        .getPropertySubtypes(this.buildingType)
        .toPromise()
        .then((result: any) => {
          if (result) {
            result.forEach((element: any) => {
              this.propertySubTypes.push({
                name: this.i18nService.getTranslation(element.name),
                id: element.id,
                translationNames: element.name
              });
            });
          }
        });
    }
  }

  getPopData() {
    this.popDataCalled = true;
    if (this.navParams.data.propType == undefined || this.navParams.data.propType === '') {
      this.buildingType = null;
      this.selectedPropertyType = 'null';
    } else {
      this.buildingType = this.navParams.data.propType;
      this.selectedPropertyType = '' + this.buildingType;
    }

    if (this.buildingType && this.buildingType !== ListingOfferType.Coworking) {
      this.listingService
        .getPropertySubtypes(this.buildingType)
        .toPromise()
        .then((result: any) => {
          if (result) {
            result.forEach((element: any) => {
              this.propertySubTypes.push({
                name: this.i18nService.getTranslation(element.name),
                id: element.id,
                translationNames: element.name
              });
            });
            if (
              this.navParams.data.searchDto &&
              this.navParams.data.searchDto.propertySubTypes &&
              this.navParams.data.searchDto.propertySubTypes.length > 0
            ) {
              for (let i = 0; i < this.navParams.data.searchDto.propertySubTypes.length; i++) {
                for (let j = 0; j < this.propertySubTypes.length; j++) {
                  if (this.propertySubTypes[j].id == this.navParams.data.searchDto.propertySubTypes[i].id) {
                    this.propertySubTypes[j].isChecked = true;
                  }
                }
              }
            }
          }
        });
    } else {
      this.popDataCalled = false;
    }

    this.searchDto = this.navParams.data.searchDto;
    this.loading = false;
  }

  setMinOptions(filter: any, searchDto: any) {
    this.listingService
      .getMinMaxOptions(searchDto)
      .toPromise()
      .then((result: any) => {
        localStorage.setItem('minMax', JSON.stringify(result));
        this.staticMinMax = this.listingService.setStaticMinMaxValues(result);
        this.rangeModelValues = this.listingService.mapMinMaxOptions(this.searchDto, this.staticMinMax);
        // if (Object.entries(this.searchDto).length === 0 && this.searchDto.constructor === Object!) {

        // } else {
        //   if (this.listingService.isRangeFilters(this.searchDto)) {
        //     this.rangeModelValues = this.listingService.mapMinMaxOptions(this.searchDto, this.staticMinMax);
        //   } else {
        //     this.rangeModelValues = this.listingService.mapMinMaxOptions(this.rangeFilters, this.staticMinMax);
        //   }
        // }
        this.loading = false;
      });
  }

  update() {
    let searchCopyDto = { ...this.searchDto };
    if (
      this.buildingType == '1001' ||
      this.buildingType == '2001' ||
      this.buildingType === ListingOfferType.Coworking
    ) {
      searchCopyDto.buildingTypes = [this.buildingType];
    } else {
      searchCopyDto.buildingTypes = null;
    }

    if (this.buildingType === ListingOfferType.Coworking) {
      searchCopyDto.listingType = ListingOfferType.Coworking;
      searchCopyDto.propertyType = null;
    } else {
      searchCopyDto.listingType = null;
      searchCopyDto.coworkingPositions = null;
      searchCopyDto.coworkingType = null;
    }

    let selectedPropertySubtypes = this.propertySubTypes.filter((e: any) => e.isChecked);
    searchCopyDto.propertySubTypes = selectedPropertySubtypes;
    if (searchCopyDto.propertySubTypes) {
      searchCopyDto.propertySubtypeIds = searchCopyDto.propertySubTypes.map((t: any) => t.id);
    } else {
      searchCopyDto.propertySubtypeIds = null;
    }
    this.popoverCtrl.dismiss(searchCopyDto);
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }

  clearSearch() {
    this.searchDto = this.listingService.clearFilters(this.searchDto, this.checkboxValues);
    this.rangeModelValues = this.listingService.mapMinMaxOptions(null, this.staticMinMax);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.cancel();
    }
  }
}
