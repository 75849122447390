import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { MatStepper } from '@angular/material';
import { ImageService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { validateURL } from '@app/shared/util/url-validator';
import { EditCropImageModalComponent } from '@app/shared/edit-crop-image-modal/edit-crop-image-modal.component';
import { HttpClient } from '@angular/common/http';
import { MultipleFilesProgressBarComponent } from '@app/shared/multiple-files-progress-bar/multiple-files-progress-bar.component';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
export class ImgUploadComponent {
    constructor(http, imageService, commonService, sanitizer) {
        this.http = http;
        this.imageService = imageService;
        this.commonService = commonService;
        this.sanitizer = sanitizer;
        this.videoURLInvalid = false;
        this.vrTourSelected = new EventEmitter();
        this.imageFiles = [];
    }
    ngOnInit() { }
    convertToDataURL(imageUrl, callback) {
        let xhr = new XMLHttpRequest();
        this.filename = this.commonService.getFileNameFromPath(imageUrl);
        xhr.onload = () => {
            let fileReader = new FileReader();
            fileReader.onloadend = () => {
                callback(fileReader.result);
            };
            fileReader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', imageUrl);
        xhr.responseType = 'blob';
        xhr.send();
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    fileChangeEvent(event, remove) {
        const files = event.currentTarget.files;
        if (files.length > 20) {
            const infoModalParams = {
                headerTitle: 'global.uploadImage',
                bodyMessage: 'global.limitImagesSize'
            };
            this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
        }
        else {
            this.imageFiles = [...files];
            this.openMultipleFilesModal().then((r) => {
                this.saveAllFilesToImages();
            });
        }
    }
    openInfoModal(headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modalProps = {
                backdropDismiss: false,
                showBackdrop: true,
                cssClass: 'generic-info-modal',
                component: InfoModalComponent,
                componentProps: {
                    headerTitle: headerTitle,
                    bodyMessage: bodyMessage,
                    data: data,
                    extraData: extraData
                }
            };
            const infoModal = yield this.commonService.createModal(modalProps);
            infoModal.onWillDismiss().then((result) => { });
            return infoModal.present();
        });
    }
    saveAllFilesToImages() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const previousSizeImgList = this.imgList.length;
            Object.keys(this.imageFiles).forEach(i => {
                const file = this.imageFiles[i];
                let mimeType = file.type;
                if (mimeType.match(/image\/*/) === null) {
                    let message = 'Only images are supported.';
                    this.imageFiles[i].errorMsg = message;
                    this.imageFiles[i].uploaded = true;
                }
                else {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = _event => {
                        const uploadedImg = {
                            img: reader.result,
                            content: file.name,
                            caption: '',
                            photoOrder: parseInt(i) + previousSizeImgList + 1
                        };
                        this.saveImgToDatabase(uploadedImg).then(listingPhoto => {
                            this.imgList.push(listingPhoto);
                            this.imageFiles[i].uploaded = true;
                            this.imgList = this.sortListingPhotosByOrderId(this.imgList);
                        });
                    };
                }
            });
            this.imgList = this.sortListingPhotosByOrderId(this.imgList);
        });
    }
    sortListingPhotosByOrderId(listingPhotos) {
        if (listingPhotos == null) {
            return null;
        }
        return listingPhotos.sort((photo1, photo2) => (photo1.orderId > photo2.orderId ? 1 : -1));
    }
    openMultipleFilesModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                allImgFiles: this.imageFiles,
                imgList: this.imgList
            };
            const cssClass = 'multiple-images-modal';
            const modalProps = this.getModalProp(MultipleFilesProgressBarComponent, componentProps, cssClass);
            const editImageModal = yield this.commonService.createModal(modalProps);
            editImageModal.onDidDismiss().then((popoverData) => {
                this.imageFiles = [];
                this.clearFileInput(document.getElementById('inputFile'));
                if (popoverData.data) {
                }
            });
            return editImageModal.present();
        });
    }
    saveImgToDatabase(imgUploaded, listingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let listingPhoto = yield this.uploadPhotoWithoutListing(imgUploaded, listingPhotoId);
            return listingPhoto;
        });
    }
    removeImage(ev, idx) {
        if (this.saveImgImmediately) {
            this.deleteListingPhoto(this.imgList[idx]);
            if (this.listing && this.listing.id) {
                const deletedListingPhoto = {
                    imageId: this.imgList[idx].image.id,
                    listingId: this.listing.id
                };
                try {
                    this.logListingPhotonDeletion(deletedListingPhoto);
                }
                catch (error) {
                    console.error(error);
                }
            }
        }
        this.imgList.splice(idx, 1);
    }
    logListingPhotonDeletion(deletedListingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post('api/deletedphotolog/', deletedListingPhoto)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    deleteListingPhoto(listingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .delete(`api/listingPhotos/${listingPhoto.id}`)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    sanitize(url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        }
        catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    doReorder(ev) {
        if (this.imgList.length === 1) {
            ev.detail.complete();
            return;
        }
        if (this.imgList.length <= ev.detail.to) {
            const itemMove = this.imgList.splice(ev.detail.from, 1)[0];
            this.imgList.splice(ev.detail.to, 0, itemMove);
            ev.detail.complete();
            return;
        }
        Object.keys(this.imgList).forEach(key => {
            if (typeof this.imgList[key] === 'undefined') {
                delete this.imgList[key];
            }
        });
        const itemMove = this.imgList.splice(ev.detail.from, 1)[0];
        this.imgList.splice(ev.detail.to, 0, itemMove);
        this.imgList.forEach((listingPhoto, index) => {
            const newOrderId = index + 1;
            if (listingPhoto.orderId != newOrderId) {
                this.updateOrderOrCaptionFrom(listingPhoto.id, undefined, newOrderId);
                this.imgList[index].orderId = newOrderId;
            }
        });
        ev.detail.complete();
    }
    // tslint:disable-next-line: member-ordering
    validateCustomURL() {
        this.videoURLInvalid = false;
        if (this.vrTourUrl) {
            var vrTourUrl2 = this.vrTourUrl.split('\n');
            if (vrTourUrl2.length > 2) {
                this.videoURLInvalid = true;
            }
            else {
                vrTourUrl2.forEach((item) => {
                    if (!validateURL(item.trim())) {
                        this.videoURLInvalid = true;
                    }
                });
            }
        }
        const urlValidation = {
            videoURLInvalid: this.videoURLInvalid,
            vrTourSelected: this.vrTourUrl
        };
        this.vrTourSelected.emit(urlValidation);
    }
    /** New images are handled with base 64. They are not still saved into db */
    isNewImage(imgToEdit) {
        return imgToEdit.img && !imgToEdit.image;
    }
    getImage(img) {
        if (this.isNewImage(img)) {
            if (img.croppedImage) {
                return img.croppedImage;
            }
            else {
                return img.img;
            }
        }
        else {
            if (img.croppedImage) {
                if (img.croppedImage.id) {
                    return this.getMediumImg(img.croppedImage.id);
                }
                else {
                    return img.croppedImage;
                }
            }
            return this.getMediumImg(img.image.id);
        }
    }
    getImageContent(img) {
        if (!img) {
            return '';
        }
        return this.isNewImage(img) ? img.content : img.image.content;
    }
    getModalProp(component, componentProps, cssClass) {
        return {
            backdropDismiss: false,
            showBackdrop: true,
            cssClass: cssClass,
            component: component,
            componentProps: componentProps
        };
    }
    captionChangeListener(imgIndex, event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            event.stopImmediatePropagation();
            const newCaption = event.target.value;
            if (newCaption && this.imgList[imgIndex].caption != newCaption) {
                this.imgList[imgIndex].caption = newCaption;
                this.updateOrderOrCaptionFrom(this.imgList[imgIndex].id, newCaption);
            }
        });
    }
    openEditImageModal(imgIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.uploadingImages = true;
            const imgToEdit = this.imgList[imgIndex];
            let newImageBased64 = null;
            let originalImageId = null;
            let imageUrlToEdit = null;
            let fileName = null;
            const isNewImage = this.isNewImage(imgToEdit);
            if (!isNewImage) {
                const imgId = imgToEdit.image.id;
                if (imgToEdit.originalImage) {
                    originalImageId = imgToEdit.originalImage.id;
                }
                fileName = imgToEdit.image.content;
                imageUrlToEdit = `/images/${imgId}/thumbnail/ml`;
                if (imgToEdit.croppedImage) {
                    imageUrlToEdit = null;
                    if (imgToEdit.croppedImage.id) {
                        const croppedImgId = imgToEdit.croppedImage.id;
                        imageUrlToEdit = `/images/${croppedImgId}/thumbnail/ml`;
                    }
                    else {
                        newImageBased64 = imgToEdit.croppedImage;
                    }
                }
            }
            else {
                newImageBased64 = imgToEdit.croppedImage ? imgToEdit.croppedImage : imgToEdit.img;
                fileName = imgToEdit.content;
                if (imgToEdit.originalImageId) {
                    originalImageId = imgToEdit.originalImageId;
                }
            }
            const componentProps = {
                newImageBased64: newImageBased64,
                originalImageId: originalImageId,
                imageUrlToEdit: imageUrlToEdit,
                showCroppingToggle: true,
                fileName: fileName
            };
            const cssClass = 'edit-crop-image-modal';
            const modalProps = this.getModalProp(EditCropImageModalComponent, componentProps, cssClass);
            const editImageModal = yield this.commonService.createModal(modalProps);
            editImageModal.onDidDismiss().then((popoverData) => {
                if (popoverData.data) {
                    const wasImageCropped = popoverData.data.wasImageCropped;
                    const newCroppedImage = popoverData.data.croppedImage;
                    const newOriginalImage = popoverData.data.originalImage;
                    const wasOriginalImgReplaced = popoverData.data.wasOriginalImgReplaced;
                    const wasRestoreToOriginalImage = popoverData.data.wasRestoreToOriginalImage;
                    const originalImageId = popoverData.data.originalImageId;
                    if (newOriginalImage) {
                        const content = newOriginalImage.file.name && newOriginalImage.file.name != 'null'
                            ? newOriginalImage.file.name
                            : 'New Image';
                        if (isNewImage) {
                            this.imgList[imgIndex].img = newOriginalImage.imgBase64;
                            this.imgList[imgIndex].content = content;
                            this.imgList[imgIndex].croppedImage = newCroppedImage.imgBase64;
                            this.imgList[imgIndex].originalImageId = originalImageId;
                        }
                        else {
                            const caption = imgToEdit.image.caption;
                            const originalImgId = imgToEdit.originalImage ? imgToEdit.originalImage.id : null;
                            if (wasOriginalImgReplaced) {
                                let imgToUpload = {
                                    img: newOriginalImage.imgBase64,
                                    content: content,
                                    caption: caption,
                                    croppedImage: newCroppedImage.imgBase64,
                                    originalImageId: originalImgId,
                                    photoOrder: this.imgList[imgIndex].orderId
                                };
                                this.saveImgToDatabase(imgToUpload, this.imgList[imgIndex].id).then(listingPhoto => {
                                    this.uploadingImages = false;
                                    this.imgList[imgIndex] = listingPhoto;
                                });
                                //this.imgList[imgIndex]
                            }
                            else {
                                // Save Cropped Image
                                this.imgList[imgIndex].croppedImage = newCroppedImage.imgBase64;
                                this.addCroppedImgtoListingPhoto(this.imgList[imgIndex]).then(savedListingPhoto => {
                                    this.uploadingImages = false;
                                    if (savedListingPhoto) {
                                        this.imgList[imgIndex] = savedListingPhoto;
                                    }
                                });
                                if (wasRestoreToOriginalImage && this.imgList[imgIndex].originalImage) {
                                    this.imgList[imgIndex].image = this.imgList[imgIndex].originalImage;
                                }
                            }
                        }
                    }
                }
            });
            return yield editImageModal.present();
        });
    }
    uploadPhotoWithoutListing(imgToUpload, listingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            if (!imgToUpload.caption) {
                imgToUpload.caption = '';
            }
            let imgToFile = yield this.commonService.convertImageBase64ToFile(imgToUpload.img, imgToUpload.content);
            let croppedFile = null;
            if (imgToUpload.croppedImage) {
                croppedFile = yield this.commonService.convertImageBase64ToFile(imgToUpload.croppedImage, imgToUpload.content);
            }
            formData.append('caption', imgToUpload.caption);
            formData.append('photoOrder', imgToUpload.photoOrder);
            formData.append('file', imgToFile);
            formData.append('content', imgToUpload.content);
            formData.append('croppedFile', croppedFile);
            if (listingPhotoId) {
                formData.append('listingPhotoId', listingPhotoId);
            }
            if (imgToUpload.originalImageId && imgToUpload.originalImageId != null) {
                formData.append('originalImageId', imgToUpload.originalImageId);
            }
            return this.http
                .post(`api/listingPhotos/no-listing/upload`, formData)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    addCroppedImgtoListingPhoto(listingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const croppedImage = listingPhoto.croppedImage;
            if (croppedImage == null) {
                return null;
            }
            if (!croppedImage.id) {
                const croppedFile = yield this.commonService.convertImageBase64ToFile(croppedImage, listingPhoto.image.content);
                const caption = listingPhoto.image.caption && listingPhoto.image.caption.length > 0
                    ? listingPhoto.image.caption
                    : listingPhoto.image.content;
                return yield this.uploadCroppedImage(croppedFile, `${caption}_cropped`, listingPhoto.id).then((createdImages) => {
                    return createdImages;
                });
            }
            else {
                return croppedImage;
            }
        });
    }
    uploadCroppedImage(file, caption, listingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('listingPhotoId', listingPhotoId);
            formData.append('caption', caption);
            formData.append('file', file);
            return this.http
                .post(`api/listingPhotos/cropped/`, formData)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    updateOrderOrCaptionFrom(listingPhotoId, caption, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .patch(`api/listingPhotos/patch/${listingPhotoId}`, { orderId: orderId, caption: caption })
                .pipe(map(val => val))
                .toPromise();
        });
    }
}
