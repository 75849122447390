<mat-card
  class="listing-card spot-building-cards"
  [ngClass]="{
    'mobile-height': isMobile && !fromCarousel,
    'mobile-carousel-height': fromCarousel && (isMobile || screenWidth < 500),
    'mt-3': !reduceCardHeight
  }"
  style="cursor: pointer;"
>
  <a class="router-link-seo listing-card-link d-flex flex-column" (click)="openCard(listing.id, $event, false)">
    <!-- Sliders Imgs -->
    <div class="image-container">
      <div id="listing-card-slides" class="image-height-card p-relative">
        <div id="favoriteFloating" class="favorite__floating">
          <div class="favorite__floating__section">
            <app-favorite-spot [spot]="listing" [size]="favSize"></app-favorite-spot>
          </div>
        </div>

        <ion-slides
          class="slider-width-card"
          (ionSlideTransitionEnd)="getNextPhoto(listing.id, $event)"
          #photoSlider
          id="mobilePhotosSlider"
        >
          <ion-slide *ngFor="let photo of listing.buildingListingPhotos; let i = index">
            <img
              *ngIf="i > 0"
              (click)="openCard(listing.id, $event, false)"
              [alt]="spotName"
              [title]="spotName"
              [defaultImage]="defaultImage"
              class="img-listing-card"
              [lazyLoad]="photo && photo.image ? getLargeImg(getCroppedOrOriginalImage(photo)) : photo"
            />

            <img
              *ngIf="i == 0"
              (click)="openCard(listing.id, $event, false)"
              [alt]="spotName"
              [title]="spotName"
              class="img-listing-card"
              [default]="defaultImage"
              [src]="photo && photo.image ? getLargeImg(getCroppedOrOriginalImage(photo)) : photo"
            />
          </ion-slide>
        </ion-slides>

        <div *ngIf="!noPhotos && listing.premium" id="floatingPagination" class="premium__floating">
          <div class="premium__floating__section">
            <img style="width: 22px;" src="assets/images/icons/stars.svg" alt="" />
            <label class="premium__floating__text">{{ 'global.gold' | translate }}</label>
          </div>
        </div>
        <div *ngIf="!noPhotos && listing.featured" id="floatingPagination" class="premium__floating">
          <div class="premium__floating__section">
            <img style="width: 22px;" src="assets/images/icons/auto_awesome.svg" alt="" />
            <label class="premium__floating__text">{{ 'global.platinum' | translate }}</label>
          </div>
        </div>

        <div
          class="image-dots"
          *ngIf="listing.buildingListingPhotos && listing.buildingListingPhotos.length > 1 && isMobile"
        >
          <span
            class="dot"
            [ngClass]="{ active: i == currentPhotoIndex }"
            *ngFor="let photo of listing.buildingListingPhotos; let i = index"
            (click)="slideToIndex($event, i)"
          >
          </span>
        </div>
      </div>
    </div>

    <div class="flex-col-nowrap flex-grow-1" (click)="openCard(listing.id, $event)">
      <ion-row>
        <div class="grid-card-container full-width" [ngClass]="{ 'py-2': reduceCardHeight }">
          <!-- Header-->
          <div id="header" style="padding-bottom: 0px" class="flex flex-col-wrap">
            <div class="building-title-new" localize [textValue]="listing.buildingTitle"></div>
            <div class="flex">
              <span class="card-subheader">
                {{ getSubHeaderCardInfo() }}
              </span>
            </div>
          </div>

          <div class="flex-align-content-end align-items-center"></div>

          <div class="card-info-separator"></div>

          <div class="flex-col-wrap spot-building-main-info">
            <div class="flex" *ngIf="listing.type != 'C'">
              <span class="info-title">{{ 'global.availableArea' | translate }}: </span>
              <span class="main-info-gap" *ngIf="getAvailableAreaText()">{{
                getAvailableAreaText() != 'N/A' ? getAvailableAreaText() : getAvailableAreaText()
              }}</span>
            </div>

            <div class="flex" *ngIf="listing.type == 'C'">
              <span class="info-title"> {{ 'spaceDetail.availablePositions' | translate }}: </span>
              <span class="main-info-gap" *ngIf="getPositions()">{{ getPositions() != 0 ? getPositions() : 1 }}</span>
            </div>
            <div class="flex">
              <span class="info-title" *ngIf="listing.type != 'S'"
                >{{ 'buildinsDetail.askingRentCard' | translate }}:
              </span>
              <span class="info-title" *ngIf="listing.type == 'S'"
                >{{ 'buildinsDetail.askingPriceCard' | translate }}:
              </span>
              <span class="main-info-gap" *ngIf="getAvgAskingRent()">{{ getAvgAskingRent() }}</span>
            </div>
          </div>

          <div class="flex-align-content-end" style="width: 100%;">
            <img
              style="height:42px; object-fit: cover;"
              [src]="getCompanyLogoImg(listing.companyLogoImageId)"
              default="assets/no-company-logo.png"
            />
          </div>
        </div>
      </ion-row>
    </div>
  </a>

  <!-- Images Buttons Navigation -->
  <button (click)="getPreviousPhoto()" class="stdBackBtn" *ngIf="showPrevNextNav() && isMobile">
    <ion-icon name="arrow-back"></ion-icon>
  </button>

  <button (click)="getNextPhoto(listing.id, $event)" class="stdNextBtn" *ngIf="showPrevNextNav() && isMobile">
    <ion-icon name="arrow-forward"> </ion-icon>
  </button>
</mat-card>
