import { Component, Injectable, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { I18nService } from '@app/core';
import {
  HomeSearchParams,
  KeywordResult,
  KeywordResultItem,
  PropertyTypes
} from '@app/models/spot-buildings/home-search.model';
import { HomeSearchService } from '@app/home/services/home-search.service';
import { TranslateService } from '@ngx-translate/core';
import { KeywordType } from '../../../models/spot-buildings/home-search.model';

@Injectable()
@Component({
  selector: 'app-custom-keyword-search',
  templateUrl: './custom-keyword-search.component.html',
  styleUrls: ['./custom-keyword-search.component.scss']
})
export class CustomKeywordSearchComponent implements OnChanges {
  @Input() propertyTypeId: number;
  @Input() spotBuildingType: string;
  @Input() selected: KeywordResultItem;
  @Input() propertyTypes: PropertyTypes;
  @Output() onSelect: EventEmitter<KeywordResultItem> = new EventEmitter(null);

  stateTxt: string;
  cityTxt: string;
  allTxt: string;
  marketTxt: string;
  neighborhoodTxt: string;

  tabNames: { [k: number]: KeywordType | 'all' } = {
    0: 'all',
    1: 'market',
    2: 'state',
    3: 'city',
    4: 'neighborhood'
  };
  locations = ['market', 'state', 'city', 'neighborhood'] as const;
  orderedSections: KeywordType[] = ['market', 'state', 'city', 'neighborhood'];
  loading: boolean = false;
  searchFailed: boolean = false;
  missingParams: boolean = false;
  showOptions: boolean = false;
  noData: boolean = false;
  selectedIndex: number = 0;
  data: KeywordResult | undefined;
  keyword: string = '';

  constructor(private homeSearchSVC: HomeSearchService, private i18nService: I18nService) {
    this.stateTxt = this.i18nService.get('global.state');
    this.neighborhoodTxt = this.i18nService.get('global.neighborhood');
    this.cityTxt = this.i18nService.get('global.city');
    this.allTxt = this.i18nService.get('All');
    this.marketTxt = this.i18nService.get('buildinsDetail.market');
  }

  getSectionName(section: KeywordType) {
    if (section == 'state') {
      return this.stateTxt;
    }

    if (section == 'city') {
      return this.cityTxt;
    }

    if (section == 'neighborhood') {
      return this.neighborhoodTxt;
    }

    if (section == 'market') {
      return this.marketTxt;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('propertyTypeId' in changes || 'spotBuildingType' in changes) {
      this.keyword = '';
      this.data = undefined;
      this.onSelect.emit(null);
    }
  }

  getData(): Promise<KeywordResult> {
    if (this.propertyTypeId && this.spotBuildingType && this.keyword) {
      const lang = this.i18nService.getCurrentLanguage();
      const searchParams: HomeSearchParams = {
        propTypeId: this.propertyTypeId,
        spotBuildingType: this.spotBuildingType,
        lang: lang
      };
      const cacheKey = `${this.propertyTypeId}_${this.spotBuildingType}_${lang}`;
      this.loading = true;
      return this.homeSearchSVC.getData(searchParams, cacheKey).finally(() => (this.loading = false));
    }
    return Promise.reject('missing params');
  }

  setDropdownVisibility(visibility: boolean) {
    this.showOptions = visibility;
  }

  onTabChange() {
    this.getSearchValue();
  }

  onFocus() {
    if (this.keyword) {
      this.setDropdownVisibility(true);
      this.getSearchValue();
    }
  }

  onKeywordChange() {
    this.searchFailed = false;
    this.missingParams = false;
    this.noData = false;
    this.setDropdownVisibility(Boolean(this.keyword));
    this.clearSelection();
    this.getSearchValue();
  }

  getSearchValue() {
    this.getData()
      .then((data: KeywordResult) => this.filterData(data))
      .then((data: KeywordResult) => this.setBoundValues(data))
      .catch(e => {
        if (e === 'missing params') {
          this.missingParams = true;
          return;
        }
        console.error(e);
        this.searchFailed = true;
      });
  }

  filterData(data: KeywordResult) {
    const currentTabName = this.tabNames[this.selectedIndex];
    const filteredData: KeywordResult = this.homeSearchSVC.getFirstItems(data, currentTabName, this.keyword);
    if (this.propertyTypeId === this.propertyTypes.industrial) {
      delete filteredData.neighborhood;
    }
    return filteredData;
  }

  setBoundValues(data: KeywordResult) {
    this.data = data;
    this.noData = Object.values(this.data).every(v => !v.length);
    const locationsWithData = Object.keys(this.data);
    this.orderedSections = this.locations.filter((l: KeywordType) => locationsWithData.includes(l));
  }

  clearSelection() {
    if (this.selected && this.selected.name !== this.keyword) {
      this.onSelect.emit(null);
    }
  }

  onItemClick(item: KeywordResultItem) {
    this.keyword = item.name;
    this.setDropdownVisibility(false);
    this.onSelect.emit(item);
  }
}
