import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  SearchModel,
  SpotBuildingType,
  PropertyTypes,
  KeywordResultItem,
  SearchQueryParams
} from '@app/models/spot-buildings/home-search.model';
import { faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SpotBuildingsSearchService } from '../../../spot-buildings/services/spot-buildings-search.service';
import { I18nService } from '../../../core/i18n.service';
import { SpotBuildingSearchRequest } from '../../../models/spot-buildings/spot-building-search.model';
import { query } from '@angular/animations';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
  selector: 'app-home-search-control',
  templateUrl: './home-search-control.component.html',
  styleUrls: ['./home-search-control.component.scss']
})
export class HomeSearchControlComponent {
  spotBuildingTypes: SpotBuildingType = {
    lease: 'L',
    sale: 'S',
    coworking: 'C',
    company: 'CPY'
  };
  propertyTypes: PropertyTypes = {
    industrial: 1001,
    office: 2001
  };
  faChevronRight: IconDefinition = faChevronRight;
  advertisements: any = [];
  active: number = 1;
  leaseLabel: string;
  saleLabel: string;
  companyLabel: string;
  selectCompany: string;
  searchModel: SearchModel = {
    propertyTypeId: this.propertyTypes.office,
    spotBuildingType: this.spotBuildingTypes.lease,
    keyword: null,
    companyId: null
  };
  allCompaniesWithSpots: any = [];
  filteredCompanies: Observable<any[]>;
  companyCtrl = new FormControl();

  constructor(
    private router: Router,
    private routinHelperSrv: RoutingHelperService,
    private i18nService: I18nService,
    private spotBuildingSearchService: SpotBuildingsSearchService,
    private _ts: TranslateService
  ) {
    this.leaseLabel = _ts.instant('global.list-your-property.forLeaseHome');
    this.saleLabel = _ts.instant('global.list-your-property.forSaleHome');
    this.companyLabel = _ts.instant('global.list-your-property.forCompanyHome');
    this.selectCompany = _ts.instant('global.list-your-property.selectCompany');
    this.spotBuildingSearchService.findAllSpotCompanies().then((res: any) => {
      this.allCompaniesWithSpots = res;
      this.companyCtrl.setValue('');
      this.filteredCompanies = this.companyCtrl.valueChanges.pipe(
        startWith(''),
        map(state => this._filterStates(state))
      );
    });
  }

  selectedState(event: MatAutocompleteSelectedEvent) {
    this.searchModel.companyId = null;
    const selectedCompanyName = event.option.value;
    const selectedCompany = this.allCompaniesWithSpots.filter((company: any) => company.name == selectedCompanyName);
    if (selectedCompany && selectedCompany.length > 0) {
      this.searchModel.companyId = selectedCompany[0].id;
    }
  }

  private _filterStates(value: string): any[] {
    const filterValue = this._normalizeValue(value.toLowerCase());
    if (value == '') {
      return [];
    }

    const filteredOptions = this.allCompaniesWithSpots
      .filter((state: any) => this._normalizeValue(state.name.toLowerCase()).includes(filterValue))
      .sort((a: any, b: any) => a.name.localeCompare(b.name));

    return filteredOptions;
  }

  highlightOption(option: string): string {
    const filterValue = this._normalizeValue(this.companyCtrl.value.toLowerCase());
    const startIndex = this._normalizeValue(option.toLowerCase()).indexOf(filterValue);
    if (startIndex >= 0) {
      const endIndex = startIndex + filterValue.length;
      return (
        option.substring(0, startIndex) +
        '<span class="highlight-match" style="color: blue; font-weight: bold;">' +
        option.substring(startIndex, endIndex) +
        '</span>' +
        option.substring(endIndex)
      );
    }
    return option;
  }

  getPropertyTypeKey(propertyTypeId: number) {
    switch (propertyTypeId) {
      case this.propertyTypes.industrial:
        return 'global.industrial';
      case this.propertyTypes.office:
        return 'global.office';
      default:
        return;
    }
  }

  portChange(event: { component: IonicSelectableComponent; value: any }) {
    //console.log('Event: ', event);
  }

  isNotCompanySearch() {
    return this.searchModel.spotBuildingType != this.spotBuildingTypes.company;
  }

  isCompanySearch() {
    return this.searchModel.spotBuildingType == this.spotBuildingTypes.company;
  }

  setPropertyTypeId(type: number, listingType?: SpotBuildingType[keyof SpotBuildingType]) {
    if (listingType) {
      this.setSpotBuildingType(listingType);
    } else {
      if (this.searchModel.spotBuildingType == this.spotBuildingTypes.coworking) {
        this.setSpotBuildingType(this.spotBuildingTypes.lease);
      }
    }
    this.searchModel = { ...this.searchModel, propertyTypeId: type, keyword: null, companyId: null };
  }

  setSpotBuildingType(tab: SpotBuildingType[keyof SpotBuildingType]) {
    this.searchModel = { ...this.searchModel, spotBuildingType: tab, keyword: null, companyId: null };
  }

  selectKeywordItem(item: KeywordResultItem) {
    this.searchModel.keyword = item;
  }

  selectCompanySpot(id: number) {
    this.searchModel = { ...this.searchModel, companyId: id, keyword: null };
  }

  disableSearchBtn() {
    var disabled = false;
    if (this.isCompanyTabActive()) {
      if (!this.searchModel.companyId) {
        disabled = true;
      }
    }

    return disabled;
  }

  private isCompanyTabActive() {
    return this.searchModel.spotBuildingType === this.spotBuildingTypes.company;
  }

  async onSearch(advanced: boolean = false) {
    if (this.isCompanyTabActive()) {
      if (!this.searchModel.companyId) {
        return;
      }
      this.routinHelperSrv.navigateToCompanyDetailsPage(this.searchModel.companyId);
      return;
    }

    const queryParams: SearchQueryParams = {
      propertyType: this.searchModel.propertyTypeId,
      listingType: this.searchModel.spotBuildingType,
      keyword: this.searchModel.keyword,
      advanced
    };
    const filters = JSON.stringify(queryParams);

    if (filters) {
      this.spotBuildingSearchService.saveFilterPreferencesFrom(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS, filters);
    }
    let req = new SpotBuildingSearchRequest();
    req.listingLeaseOrSale = [...this.searchModel.spotBuildingType];
    req.buildingTypes = [`${this.searchModel.propertyTypeId}`];
    req.keyword =
      this.searchModel.keyword && this.searchModel.keyword.type == 'neighborhood'
        ? this.searchModel.keyword.name
        : null;
    req.stateId = this.searchModel.keyword && this.searchModel.keyword.id ? this.searchModel.keyword.id : null;

    req.stateId = null;
    req.cityId = null;
    req.marketId = null;

    if (this.searchModel.keyword && this.searchModel.keyword.id) {
      const isStateType = this.searchModel.keyword.type == 'state';
      const isCityType = this.searchModel.keyword.type == 'city';
      const isMarketType = this.searchModel.keyword.type == 'market';
      if (isStateType) {
        req.stateId = this.searchModel.keyword.id;
      }
      if (isCityType) {
        req.stateId = this.searchModel.keyword.stateId;
        req.cityId = this.searchModel.keyword.id;
      }
      if (isMarketType) {
        req.marketId = this.searchModel.keyword.id;
      }
    }

    var response: any = await this.spotBuildingSearchService.getUrlFromListingsSearchDto(req);
    let lang = this.i18nService.getCurrentLanguage();
    response.url = JSON.parse(response.url);
    const newUrl = `${response.url[lang]}${response.keyword ? '?keyword=' + response.keyword : ''}`;

    if (this.i18nService.currentUrlContainsCurrentLanguage()) {
      this.router.navigateByUrl(
        this.i18nService.addCurrentLanguageToPath(`${this.routinHelperSrv.getSpotSearchURL()}${newUrl}`)
      );
    } else {
      this.router.navigateByUrl(`${this.routinHelperSrv.getSpotSearchURL()}${newUrl}`);
    }
  }

  private _normalizeValue(value: string): string {
    const accentsMap: { [key: string]: string } = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      à: 'a',
      è: 'e',
      ì: 'i',
      ò: 'o',
      ù: 'u',
      ä: 'a',
      ë: 'e',
      ï: 'i',
      ö: 'o',
      ü: 'u',
      â: 'a',
      ê: 'e',
      î: 'i',
      ô: 'o',
      û: 'u',
      ã: 'a',
      õ: 'o',
      ñ: 'n',
      ç: 'c',
      ā: 'a',
      ē: 'e',
      ī: 'i',
      ō: 'o',
      ū: 'u',
      ă: 'a',
      ĕ: 'e',
      ĭ: 'i',
      ŏ: 'o',
      ŭ: 'u',
      ą: 'a',
      ę: 'e',
      į: 'i',
      ų: 'u',
      å: 'a',
      ø: 'o',
      ș: 's',
      ț: 't',
      Ă: 'A',
      Ș: 'S',
      Ț: 'T'
    };

    return value.replace(/./g, c => accentsMap[c] || c);
  }
}
