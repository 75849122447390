import { Component, OnInit, ViewChildren, AfterViewInit, QueryList, OnDestroy } from '@angular/core';
import { SiilaChatSupportService } from '@app/chat/siila-chat-support.service';
import { AccountService } from '@app/core/auth';
import { SiilaChatService } from '@app/chat/siila-chat.service';
import { Socket } from 'ngx-socket-io';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { IonTextarea } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CommonGenericService } from '@app/core/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface SiilaInstantMessageData {
  messages: any;
  conversationId: any;
  kind: any;
  name: any;
  parentId: any;
  userIds: any;
}

@Component({
  selector: 'app-siila-chat',
  templateUrl: './siila-chat.component.html',
  styleUrls: ['./siila-chat.component.scss']
})
export class SiilaChatSupportComponent implements OnInit, AfterViewInit, OnDestroy {
  messageData: any;
  currentUser: any;
  messageInput: string = '';
  spotAccountImage: any;
  userProfileImage: any;
  userFullname: any;
  spotAccountName: any;
  spotAccountFullname: any;
  ionTextAreaSubscription: Subscription;
  messageDataSubscription: Subscription;
  @ViewChildren('inputMessage') inputMessageComponent: QueryList<IonTextarea>;

  constructor(
    private chatSupportService: SiilaChatSupportService,
    private siilaChatService: SiilaChatService,
    private account: AccountService,
    private socket: Socket,
    private myMessagesServices: MyMessageService,
    private accountService: AccountService,
    private socketIO: SocketIOService,
    private commonSvc: CommonGenericService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.getCurrentUser();

    window.addEventListener('keydown', evt => {
      this.enterPress(evt);
    });
  }

  async ngOnInit() {
    this.messageDataSubscription = this.chatSupportService.messageData.subscribe(messageData => {
      this.messageData = messageData;
    });
    await this.chatSupportService.populateMessageData();
    this.loadPhotos();
    //this.socketIO.updateUnreadMessages();
  }

  ngAfterViewInit() {
    this.ionTextAreaSubscription = this.inputMessageComponent.changes.subscribe((textArea: QueryList<IonTextarea>) => {
      textArea.last.getInputElement().then(result => {
        result.focus();
      });
    });
  }

  ngOnDestroy() {
    this.ionTextAreaSubscription.unsubscribe();
    this.messageDataSubscription.unsubscribe();
  }

  enterPress(e: any) {
    switch (e.keyCode) {
      case 13:
        e.preventDefault();
        this.sendMessage();
        break;
    }
  }

  async loadPhotos() {
    let photos: any = await this.account.getProfilePicture('spot@siila.com.br').toPromise();
    this.spotAccountName = await this.chatSupportService.getUsernameInfo('spot@siila.com.br');
    if (this.spotAccountName) {
      this.spotAccountFullname = `${this.spotAccountName.firstName} ${this.spotAccountName.lastName}`;
    }
    if (photos) {
      this.spotAccountImage = '/images/' + photos[photos.length - 1] + '/thumbnail/m';
    }
  }

  sendMessage() {
    if (this.messageInput && this.messageInput.trim().length) {
      const messageEvent: any = {
        conversationId: this.messageData.conversationId,
        content: this.messageInput,
        userId: this.currentUser.login,
        createdAt: new Date(),
        filename: null,
        type: 'text',
        notifyList: this.messageData.userIds
      };
      this.siilaChatService.sendMessage(messageEvent);
      this.messageInput = null;
    }
  }

  public getCurrentUser() {
    this.userProfileImage = localStorage.getItem('profileImage');
    this.currentUser = this.account.currentUser$.getValue();
    if (this.currentUser) {
      this.userFullname = `${this.currentUser.firstName} ${this.currentUser.lastName}`;
    }
  }

  public scrollOnFocus(element: any) {
    const os: string = this.deviceDetectorService.os;
    const isAndriodIos: boolean = os.toLowerCase() === 'android' || os.toLowerCase() === 'ios';
    if (isAndriodIos) {
      element.el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
